/* @flow */
import * as types from './actiontypes';
import type { PainGuideTypeCategory, PainGuideTypeContent } from './types';

type State = {
  categories: Array<PainGuideTypeCategory>,
  featured: Array<PainGuideTypeContent>,
  bookmarked: Array<PainGuideTypeContent>,
};

const MOCK: State = {
  categories: null,
  featured: null,
  bookmarked: null,
};

const initialState = MOCK;

// const initialState = {
//
// };

function PainGuideReducer(state: State = { ...initialState }, action) {
  const payload = action.payload;
  switch (action.type) {
    case types.PAIN_GUIDE_GET_RECOMMENDED_SUCCESS: {
      return {
        ...state,
        featured: action.payload,
      };
    }
    case types.PAIN_GUIDE_GET_BOOKMARKED_SUCCESS: {
      return {
        ...state,
        bookmarked: action.payload,
      };
    }
    case types.PAIN_GUIDE_LIST_CONTENT_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: state.categories?.map((category) => {
          if (category.id == action.payload.category_id) {
            return { ...category, content: action.payload.content };
          }
          return category;
        }),
      };
    }
    case types.PAIN_GUIDE_GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case types.PAIN_GUIDE_UPDATE_PROGRAM_PROGRESS_SUCCESS: {
      return {
        ...state,
        programs: state.programs?.map((program) => {
          if (program.program_id === action.payload.program_id) {
            return {
              ...program,
              progress: Math.max(
                action.payload.progress,
                program.progress || 0,
              ),
            };
          }
          return program;
        }),
      };
    }

    case types.PAIN_GUIDE_GET_PROGRAMS_SUCCESS: {
      return {
        ...state,
        programs: action.payload,
      };
    }
    default:
      return state;
  }
}
export { PainGuideReducer };
