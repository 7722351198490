import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = ['filter_screen_records_count', 'daterange_records_count'];
const STRINGS = new LocalizedStrings({
  en: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'en'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'en',
    ),

    filter_timing_section_duration_header: shared('Duration', 'en') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'en') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'en') + ':',
    filter_timing_section_type_constant: shared('Constant', 'en'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'en'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'en'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'en'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'en'),
    filter_timing_section_duration_Hours: shared('Hours', 'en'),
    filter_timing_section_duration_input_label: shared('Duration', 'en'),
    filter_timing_section_duration_units_label: shared('Units', 'en'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'en'),
    filter_timing_section_criteria_all: 'All',
    'filter_timing_section_criteria_=': 'Equal To',
    'filter_timing_section_criteria_<': 'Less Than',
    'filter_timing_section_criteria_>': 'Greater Than',
    filter_screen_records_count_one: '{0}/{1} record shown',
    filter_screen_records_count_many: '{0}/{1} records shown',
    filter_screen_records_count_other: '{0}/{1} records shown',
    filter_screen_reset_button: 'Reset Filters',
    filter_screen_close_button: shared('Close', 'en'),
    filter_screen_records_types_header:
      'Include the following types of Records:',
    filter_screen_pain_records_button: shared('Pain Records', 'en'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'en'),
    filter_screen_records_criteria_start: shared('Apply', 'en'),
    filter_screen_records_criteria_end: ' of the following criteria:',

    filter_screen_criteria_all: 'all',
    filter_screen_criteria_any: 'any',
    filter_screen_criteria_none: 'none',
    'filter_screen_criteria_=': 'Equal To',
    'filter_screen_criteria_<': 'Less Than',
    'filter_screen_criteria_>': 'Greater Than',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'en'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1} records shown',
    daterange_records_count_one: '{0}/{1} record shown',
    daterange_records_count_many: '{0}/{1} records shown',
    daterange_records_count_other: '{0}/{1} records shown',
    daterange_7_days: shared('Last 7 days', 'en'),
    daterange_30_days: shared('Last 30 days', 'en'),
    daterange_all_time: shared('All Time', 'en'),
    daterange_custom: shared('Custom Range', 'en'),
    daterange_apply_button: shared('Apply', 'en'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'en'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'en'),
    filter_bottom_bar_all_time: shared('All Time', 'en'),
    filter_bottom_bar_custom: shared('Custom Range', 'en'),
    filter_bottom_bar_apply_button: shared('Apply', 'en'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'en'),
    filter_summary_descending_label: shared('Descending', 'en'),
    filter_summary_pain_records_label: shared('Pain Records', 'en'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'en'),
    filter_summary_sort_by_label: shared('Sort By', 'en') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'en') + ':',
    filter_summary_back_button_text: shared('Back', 'en'),
    filter_summary_duration_label: shared('Duration', 'en'),
  },
  de: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'de'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'de',
    ),

    filter_timing_section_duration_header: shared('Duration', 'de') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'de') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'de') + ':',
    filter_timing_section_type_constant: shared('Constant', 'de'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'de'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'de'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'de'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'de'),
    filter_timing_section_duration_Hours: shared('Hours', 'de'),
    filter_timing_section_duration_input_label: shared('Duration', 'de'),
    filter_timing_section_duration_units_label: shared('Units', 'de'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'de'),
    filter_timing_section_criteria_all: 'Alle',
    'filter_timing_section_criteria_=': 'Gleich',
    'filter_timing_section_criteria_<': 'Kleiner als',
    'filter_timing_section_criteria_>': 'Größer als',
    filter_screen_records_count_one: '{0}/{1} Eintrag angezeigt',
    filter_screen_records_count_many: '{0}/{1} Einträge angezeigt',
    filter_screen_records_count_other: '{0}/{1} Einträge angezeigt',
    filter_screen_reset_button: 'Filter zurücksetzen',
    filter_screen_close_button: shared('Close', 'de'),
    filter_screen_records_types_header: 'Folgende Eintragarten miteinbeziehen:',
    filter_screen_pain_records_button: shared('Pain Records', 'de'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'de'),
    filter_screen_records_criteria_start: shared('Apply ', 'de'),
    filter_screen_records_criteria_end: 'der folgenden Kriterien:',

    filter_screen_criteria_all: 'aller',
    filter_screen_criteria_any: 'mind. eines',
    filter_screen_criteria_none: 'keiner',
    'filter_screen_criteria_=': 'Gleich',
    'filter_screen_criteria_<': 'Kleiner als',
    'filter_screen_criteria_>': 'Größer als',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'de'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1} Einträge angezeigt',
    daterange_records_count_one: '{0}/{1} Eintrag angezeigt',
    daterange_records_count_many: '{0}/{1} Einträge angezeigt',
    daterange_records_count_other: '{0}/{1} Einträge angezeigt',
    daterange_7_days: shared('Last 7 days', 'de'),
    daterange_30_days: shared('Last 30 days', 'de'),
    daterange_all_time: shared('All Time', 'de'),
    daterange_custom: shared('Custom Range', 'de'),
    daterange_apply_button: shared('Apply', 'de'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'de'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'de'),
    filter_bottom_bar_all_time: shared('All Time', 'de'),
    filter_bottom_bar_custom: shared('Custom Range', 'de'),
    filter_bottom_bar_apply_button: shared('Apply', 'de'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'de'),
    filter_summary_descending_label: shared('Descending', 'de'),
    filter_summary_pain_records_label: shared('Pain Records', 'de'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'de'),
    filter_summary_sort_by_label: shared('Sort By', 'de') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'de') + ':',
    filter_summary_back_button_text: shared('Back', 'de'),
    filter_summary_duration_label: shared('Duration', 'de'),
  },

  es: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'es'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'es',
    ),

    filter_timing_section_duration_header: shared('Duration', 'es') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'es') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'es') + ':',
    filter_timing_section_type_constant: shared('Constant', 'es'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'es'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'es'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'es'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'es'),
    filter_timing_section_duration_Hours: shared('Hours', 'es'),
    filter_timing_section_duration_input_label: shared('Duration', 'es'),
    filter_timing_section_duration_units_label: shared('Units', 'es'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'es'),
    filter_timing_section_criteria_all: 'Todos',
    'filter_timing_section_criteria_=': 'Igual a',
    'filter_timing_section_criteria_<': 'Menor que',
    'filter_timing_section_criteria_>': 'Mayor que',
    filter_screen_records_count_one: '{0}/{1} registro',
    filter_screen_records_count_many: '{0}/{1} registros',
    filter_screen_records_count_other: '{0}/{1} registros',
    filter_screen_reset_button: 'Restablecer filtros',
    filter_screen_close_button: shared('Close', 'es'),
    filter_screen_records_types_header:
      'Incluya los siguientes tipos de registros:',
    filter_screen_pain_records_button: shared('Pain Records', 'es'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'es'),
    filter_screen_records_criteria_start: shared('Apply', 'es'),
    filter_screen_records_criteria_end: 'de los siguientes criterios:',

    filter_screen_criteria_all: 'todos',
    filter_screen_criteria_any: 'cualquiera',
    filter_screen_criteria_none: 'ninguno',
    'filter_screen_criteria_=': 'Igual a',
    'filter_screen_criteria_<': 'Menor que',
    'filter_screen_criteria_>': 'Mayor que',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'es'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1} registros',
    daterange_records_count_one: '{0}/{1} registro',
    daterange_records_count_many: '{0}/{1} registros',
    daterange_records_count_other: '{0}/{1} registros',
    daterange_7_days: shared('Last 7 days', 'es'),
    daterange_30_days: shared('Last 30 days', 'es'),
    daterange_all_time: shared('All Time', 'es'),
    daterange_custom: shared('Custom Range', 'es'),
    daterange_apply_button: shared('Apply', 'es'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'es'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'es'),
    filter_bottom_bar_all_time: shared('All Time', 'es'),
    filter_bottom_bar_custom: shared('Custom Range', 'es'),
    filter_bottom_bar_apply_button: shared('Apply', 'es'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'es'),
    filter_summary_descending_label: shared('Descending', 'es'),
    filter_summary_pain_records_label: shared('Pain Records', 'es'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'es'),
    filter_summary_sort_by_label: shared('Sort By', 'es') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'es') + ':',
    filter_summary_back_button_text: shared('Back', 'es'),
    filter_summary_duration_label: shared('Duration', 'es'),
  },

  fr: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'fr'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'fr',
    ),

    filter_timing_section_duration_header: shared('Duration', 'fr') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'fr') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'fr') + ':',
    filter_timing_section_type_constant: shared('Constant', 'fr'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'fr'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'fr'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'fr'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'fr'),
    filter_timing_section_duration_Hours: shared('Hours', 'fr'),
    filter_timing_section_duration_input_label: shared('Duration', 'fr'),
    filter_timing_section_duration_units_label: shared('Units', 'fr'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'fr'),
    filter_timing_section_criteria_all: 'Tous',
    'filter_timing_section_criteria_=': 'Égal à',
    'filter_timing_section_criteria_<': 'Moins que',
    'filter_timing_section_criteria_>': 'Supérieur que',
    filter_screen_records_count_one: '{0}/{1} enregistrement',
    filter_screen_records_count_many: '{0}/{1} enregistrements',
    filter_screen_records_count_other: '{0}/{1} enregistrements',
    filter_screen_reset_button: 'Rétablir les filtres',
    filter_screen_close_button: shared('Close', 'fr'),
    filter_screen_records_types_header:
      "Ajouter les types d'enregistrements suivants:",
    filter_screen_pain_records_button: shared('Pain Records', 'fr'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'fr'),
    filter_screen_records_criteria_start: 'Application des critères suivants :',
    filter_screen_records_criteria_end: '',

    filter_screen_criteria_all: 'tous',
    filter_screen_criteria_any: "n'importe lequel",
    filter_screen_criteria_none: 'aucun',
    'filter_screen_criteria_=': 'Égal à',
    'filter_screen_criteria_<': 'Moins que',
    'filter_screen_criteria_>': 'Supérieur que',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'fr'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1} enregistrements',
    daterange_records_count_one: '{0}/{1} enregistrement',
    daterange_records_count_many: '{0}/{1} enregistrements',
    daterange_records_count_other: '{0}/{1} enregistrements',
    daterange_7_days: shared('Last 7 days', 'fr'),
    daterange_30_days: shared('Last 30 days', 'fr'),
    daterange_all_time: shared('All Time', 'fr'),
    daterange_custom: shared('Custom Range', 'fr'),
    daterange_apply_button: shared('Apply', 'fr'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'fr'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'fr'),
    filter_bottom_bar_all_time: shared('All Time', 'fr'),
    filter_bottom_bar_custom: shared('Custom Range', 'fr'),
    filter_bottom_bar_apply_button: shared('Apply', 'fr'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'fr'),
    filter_summary_descending_label: shared('Descending', 'fr'),
    filter_summary_pain_records_label: shared('Pain Records', 'fr'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'fr'),
    filter_summary_sort_by_label: shared('Sort By', 'fr') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'fr') + ':',
    filter_summary_back_button_text: shared('Back', 'fr'),
    filter_summary_duration_label: shared('Duration', 'fr'),
  },

  ko: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'ko'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'ko',
    ),

    filter_timing_section_duration_header: shared('Duration', 'ko') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'ko') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'ko') + ':',
    filter_timing_section_type_constant: shared('Constant', 'ko'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'ko'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'ko'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'ko'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'ko'),
    filter_timing_section_duration_Hours: shared('Hours', 'ko'),
    filter_timing_section_duration_input_label: shared('Duration', 'ko'),
    filter_timing_section_duration_units_label: shared('Units', 'ko'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'ko'),
    filter_timing_section_criteria_all: '모든',
    'filter_timing_section_criteria_=': '같음',
    'filter_timing_section_criteria_<': '미만',
    'filter_timing_section_criteria_>': '보다 큼',
    filter_screen_records_count_one: '{0}/{1}개의 기록이 표시됩니다',
    filter_screen_records_count_many: '{0}/{1}개의 기록이 표시됩니다',
    filter_screen_records_count_other: '{0}/{1}개의 기록이 표시됩니다',
    filter_screen_reset_button: '필터 재설정',
    filter_screen_close_button: shared('Close', 'ko'),
    filter_screen_records_types_header: '다음 유형의 레코드를 포함합니다:',
    filter_screen_pain_records_button: shared('Pain Records', 'ko'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'ko'),
    filter_screen_records_criteria_start: shared('Apply', 'ko'),
    filter_screen_records_criteria_end: ' 다음의 기준:',

    filter_screen_criteria_all: '모든',
    filter_screen_criteria_any: '일부',
    filter_screen_criteria_none: '해당 사항이 없는',
    'filter_screen_criteria_=': '같음',
    'filter_screen_criteria_<': '미만',
    'filter_screen_criteria_>': '보다 큼',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'ko'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1}개의 기록이 표시됩니다',
    daterange_records_count_one: '{0}/{1}개의 기록이 표시됩니다',
    daterange_records_count_many: '{0}/{1}개의 기록이 표시됩니다',
    daterange_records_count_other: '{0}/{1}개의 기록이 표시됩니다',
    daterange_7_days: shared('Last 7 days', 'ko'),
    daterange_30_days: shared('Last 30 days', 'ko'),
    daterange_all_time: shared('All Time', 'ko'),
    daterange_custom: shared('Custom Range', 'ko'),
    daterange_apply_button: shared('Apply', 'ko'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'ko'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'ko'),
    filter_bottom_bar_all_time: shared('All Time', 'ko'),
    filter_bottom_bar_custom: shared('Custom Range', 'ko'),
    filter_bottom_bar_apply_button: shared('Apply', 'ko'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'ko'),
    filter_summary_descending_label: shared('Descending', 'ko'),
    filter_summary_pain_records_label: shared('Pain Records', 'ko'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'ko'),
    filter_summary_sort_by_label: shared('Sort By', 'ko') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'ko') + ':',
    filter_summary_back_button_text: shared('Back', 'ko'),
    filter_summary_duration_label: shared('Duration', 'ko'),
  },

  ru: {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'ru'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'ru',
    ),

    filter_timing_section_duration_header: shared('Duration', 'ru') + ':',
    filter_timing_section_start_time_header: shared('Start Time', 'ru') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'ru') + ':',
    filter_timing_section_type_constant: shared('Constant', 'ru'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'ru'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'ru'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'ru'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'ru'),
    filter_timing_section_duration_Hours: shared('Hours', 'ru'),
    filter_timing_section_duration_input_label: shared('Duration', 'ru'),
    filter_timing_section_duration_units_label: shared('Units', 'ru'),
    filter_timing_section_start_time_input_label: shared('Start Time', 'ru'),
    filter_timing_section_criteria_all: 'Все',
    'filter_timing_section_criteria_=': 'Равно',
    'filter_timing_section_criteria_<': 'Меньше чем',
    'filter_timing_section_criteria_>': 'Больше чем',
    filter_screen_records_count_one: '{0}/{1} запись показана',
    filter_screen_records_count_many: '{0}/{1} записей показано',
    filter_screen_records_count_other: '{0}/{1} записей показано',
    filter_screen_reset_button: 'Сбросить фильтры',
    filter_screen_close_button: shared('Close', 'ru'),
    filter_screen_records_types_header: 'Включить следующие типы записей:',
    filter_screen_pain_records_button: shared('Pain Records', 'ru'),
    filter_screen_daily_reflections_button: shared('Daily Reflections', 'ru'),
    filter_screen_records_criteria_start: shared('Apply', 'ru'),
    filter_screen_records_criteria_end: ' из следующих критериев:',

    filter_screen_criteria_all: 'все',
    filter_screen_criteria_any: 'любые',
    filter_screen_criteria_none: 'ничего',
    'filter_screen_criteria_=': 'Равно',
    'filter_screen_criteria_<': 'Меньше чем',
    'filter_screen_criteria_>': 'Больше чем',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared('Filter Records', 'ru'),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '{0}/{1} записей показано',
    daterange_records_count_one: '{0}/{1} запись показана',
    daterange_records_count_many: '{0}/{1} записей показано',
    daterange_records_count_other: '{0}/{1} записей показано',
    daterange_7_days: shared('Last 7 days', 'ru'),
    daterange_30_days: shared('Last 30 days', 'ru'),
    daterange_all_time: shared('All Time', 'ru'),
    daterange_custom: shared('Custom Range', 'ru'),
    daterange_apply_button: shared('Apply', 'ru'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'ru'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'ru'),
    filter_bottom_bar_all_time: shared('All Time', 'ru'),
    filter_bottom_bar_custom: shared('Custom Range', 'ru'),
    filter_bottom_bar_apply_button: shared('Apply', 'ru'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'ru'),
    filter_summary_descending_label: shared('Descending', 'ru'),
    filter_summary_pain_records_label: shared('Pain Records', 'ru'),
    filter_summary_daily_reflections_label: shared('Daily Reflections', 'ru'),
    filter_summary_sort_by_label: shared('Sort By', 'ru') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'ru') + ':',
    filter_summary_back_button_text: shared('Back', 'ru'),
    filter_summary_duration_label: shared('Duration', 'ru'),
  },

  'zh-rCN': {
    //Filter drawer
    filter_drawer_medication_section_header: shared('Medications', 'zh-rCN'),
    filter_drawer_medication_section_secondary: shared(
      'medications_question',
      'zh-rCN',
    ),

    filter_timing_section_duration_header: shared('Duration', 'zh-rCN') + ':',
    filter_timing_section_start_time_header:
      shared('Start Time', 'zh-rCN') + ':',
    filter_timing_section_type_header: shared('Type of Pain', 'zh-rCN') + ':',
    filter_timing_section_type_constant: shared('Constant', 'zh-rCN'),
    filter_timing_section_type_intermittent: shared('Intermittent', 'zh-rCN'),
    filter_timing_section_type_breakthrough: shared('Breakthrough', 'zh-rCN'),
    filter_timing_section_duration_Seconds: shared('Seconds', 'zh-rCN'),
    filter_timing_section_duration_Minutes: shared('Minutes', 'zh-rCN'),
    filter_timing_section_duration_Hours: shared('Hours', 'zh-rCN'),
    filter_timing_section_duration_input_label: shared('Duration', 'zh-rCN'),
    filter_timing_section_duration_units_label: shared('Units', 'zh-rCN'),
    filter_timing_section_start_time_input_label: shared(
      'Start Time',
      'zh-rCN',
    ),
    filter_timing_section_criteria_all: '全部',
    'filter_timing_section_criteria_=': '等于',
    'filter_timing_section_criteria_<': '早于',
    'filter_timing_section_criteria_>': '晚于',
    filter_screen_records_count_one: '显示{1}条记录中的{0}条',
    filter_screen_records_count_many: '显示{1}条记录中的{0}条',
    filter_screen_records_count_other: '显示{1}条记录中的{0}条',
    filter_screen_reset_button: '重置过滤条件',
    filter_screen_close_button: shared('Close', 'zh-rCN'),
    filter_screen_records_types_header: '包括以下类型的记录：',
    filter_screen_pain_records_button: shared('Pain Records', 'zh-rCN'),
    filter_screen_daily_reflections_button: shared(
      'Daily Reflections',
      'zh-rCN',
    ),
    filter_screen_records_criteria_start: shared('Apply', 'zh-rCN'),
    filter_screen_records_criteria_end: '满足以下标准：',

    filter_screen_criteria_all: '全部',
    filter_screen_criteria_any: '任何',
    filter_screen_criteria_none: '没有',
    'filter_screen_criteria_=': '等于',
    'filter_screen_criteria_<': '早于',
    'filter_screen_criteria_>': '晚于',

    // scenes/EditFilterContainer
    edit_filter_filter_records_screen_header: shared(
      'Filter Records',
      'zh-rCN',
    ),

    // Date Range bar
    // components/DateRangeBar.js
    daterange_records_count: '显示{1}条记录中的{0}条',
    daterange_records_count_one: '显示{1}条记录中的{0}条',
    daterange_records_count_many: '显示{1}条记录中的{0}条',
    daterange_records_count_other: '显示{1}条记录中的{0}条',
    daterange_7_days: shared('Last 7 days', 'zh-rCN'),
    daterange_30_days: shared('Last 30 days', 'zh-rCN'),
    daterange_all_time: shared('All Time', 'zh-rCN'),
    daterange_custom: shared('Custom Range', 'zh-rCN'),
    daterange_apply_button: shared('Apply', 'zh-rCN'),

    // containers/FilterBottomBar
    filter_bottom_bar_last_7_days: shared('Last 7 days', 'zh-rCN'),
    filter_bottom_bar_last_30_days: shared('Last 30 days', 'zh-rCN'),
    filter_bottom_bar_all_time: shared('All Time', 'zh-rCN'),
    filter_bottom_bar_custom: shared('Custom Range', 'zh-rCN'),
    filter_bottom_bar_apply_button: shared('Apply', 'zh-rCN'),

    // components/FilterSummarySectionContent
    filter_summary_ascending_label: shared('Ascending', 'zh-rCN'),
    filter_summary_descending_label: shared('Descending', 'zh-rCN'),
    filter_summary_pain_records_label: shared('Pain Records', 'zh-rCN'),
    filter_summary_daily_reflections_label: shared(
      'Daily Reflections',
      'zh-rCN',
    ),
    filter_summary_sort_by_label: shared('Sort By', 'zh-rCN') + ':',
    filter_summary_sort_order_label: shared('Sort Order', 'zh-rCN') + ':',
    filter_summary_back_button_text: shared('Back', 'zh-rCN'),
    filter_summary_duration_label: shared('Duration', 'zh-rCN'),
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
