import LocalizedStrings from 'react-native-localization';
import shared from '../../strings/shared';

export default new LocalizedStrings({
  en: {
    Abdomen: 'Abdomen',
    'Arm (Left)': 'Arm (Left)',
    'Arm (Right)': 'Arm (Right)',
    Arms: 'Arms',
    Back: 'Back',
    'Back (Lower)': 'Back (Lower)',
    'Back (Mid)': 'Back (Mid)',
    'Back (Upper)': 'Back (Upper)',
    Chest: 'Chest',
    Feet: 'Feet',
    'Foot (Left)': 'Foot (Left)',
    'Foot (Right)': 'Foot (Right)',
    'Hand (Left)': 'Hand (Left)',
    'Hand (Right)': 'Hand (Right)',
    Hands: 'Hands',
    Head: 'Head',
    'Head (Left)': 'Head (Left)',
    'Head (Right)': 'Head (Right)',
    Hips: 'Hips',
    'Hip (Left)': 'Hip (Left)',
    'Hip (Right)': 'Hip (Right)',
    Joints: 'Joints',
    Knees: 'Knees',
    'Knee (Left)': 'Knee (Left)',
    'Knee (Right)': 'Knee (Right)',
    Legs: 'Legs',
    'Leg (Left)': 'Leg (Left)',
    'Leg (Right)': 'Leg (Right)',
    Neck: 'Neck',
    Shoulders: 'Shoulders',
    'Shoulder (Left)': 'Shoulder (Left)',
    'Shoulder (Right)': 'Shoulder (Right)',
    Anxiety: 'Anxiety',
    Bleeding: 'Bleeding',
    Constipation: 'Constipation',
    Dehydration: 'Dehydration',
    Depression: 'Depression',
    Diarrhea: 'Diarrhea',
    Dizziness: 'Dizziness',
    Drowsiness: 'Drowsiness',
    Fainting: 'Fainting',
    Fever: 'Fever',
    Hallucination: 'Hallucination',
    Insomnia: 'Insomnia',
    'Irregular Breathing': 'Irregular Breathing',
    'Irregular Pulse': 'Irregular Pulse',
    Itchiness: 'Itchiness',
    Lethargy: 'Lethargy',
    'Mental Fogginess': 'Mental Fogginess',
    Nausea: 'Nausea',
    Unconsciousness: 'Unconsciousness',
    Vomiting: 'Vomiting',
    Aching: 'Aching',
    Burning: 'Burning',
    Cold: 'Cold',
    Cramping: 'Cramping',
    Dull: 'Dull',
    'Electric Shocks': 'Electric Shocks',
    Freezing: 'Freezing',
    Heavy: 'Heavy',
    Hot: 'Hot',
    Nothing: 'Nothing',
    Numbness: 'Numbness',
    Pinching: 'Pinching',
    'Pins and Needles': 'Pins and Needles',
    Pounding: 'Pounding',
    Pressure: 'Pressure',
    Sharp: 'Sharp',
    Shooting: 'Shooting',
    Stabbing: 'Stabbing',
    Stiff: 'Stiff',
    Throbbing: 'Throbbing',
    Tingling: 'Tingling',
    'Bowel Movement': 'Bowel Movement',
    Exercise: 'Exercise',
    Food: 'Food',
    Inactivity: 'Inactivity',
    'Lack of Sleep': 'Lack of Sleep',
    'Light Touch / Clothing': 'Light Touch / Clothing',
    'Medication (Prescription)': 'Medication (Prescription)',
    'Medication (Non-Prescription)': 'Medication (Non-Prescription)',
    'Negative mood': 'Negative mood',
    Sitting: 'Sitting',
    Sleep: 'Sleep',
    Smoking: 'Smoking',
    Standing: 'Standing',
    Stress: 'Stress',
    Urination: 'Urination',
    Walking: 'Walking',
    Weather: 'Weather',
    Distraction: 'Distraction',
    Heat: 'Heat',
    Ice: 'Ice',
    Massage: 'Massage',
    Repositioning: 'Repositioning',
    Rest: 'Rest',
    Stretching: 'Stretching',
    'Talking to Someone': 'Talking to Someone',
    Bed: 'Bed',
    Car: 'Car',
    Home: 'Home',
    School: 'School',
    Shopping: 'Shopping',
    Outdoors: 'Outdoors',
    Work: 'Work',
    'Household chores': 'Household chores',
    Exercised: 'Exercised',
    'Errands outside the home': 'Errands outside the home',
    'Bathed / Showered': 'Bathed / Showered',
    'Prepared a meal': 'Prepared a meal',
    'Enjoyed the outdoors': 'Enjoyed the outdoors',
    'Got in and out of bed on my own': 'Got in and out of bed on my own',
    'Slept restfully': 'Slept restfully',
    'Spent time with friends or family': 'Spent time with friends or family',
    'Did an activity that I enjoyed': 'Did an activity that I enjoyed',
    'Connected with supportive people online or through text':
      'Connected with supportive people online or through text',
    'Attended a community event / support group':
      'Attended a community event / support group',
    'Worked / studied / volunteered': 'Worked / studied / volunteered',
    'Connected with my spirituality': 'Connected with my spirituality',
    'Learned something new': 'Learned something new',
    'Rest and relaxation': 'Rest and relaxation',
    'Spent time with my pet(s)': 'Spent time with my pet(s)',
    Breakthrough: shared('Breakthrough', 'en'),
    Constant: shared('Constant', 'en'),
    Intermittent: shared('Intermittent', 'en'),
  },

  de: {
    Abdomen: 'Bauch',
    'Arm (Left)': 'Arm (links)',
    'Arm (Right)': 'Arm (rechts)',
    Arms: 'Arme',
    Back: 'Rücken',
    'Back (Lower)': 'Rücken (unten)',
    'Back (Mid)': 'Rücken (mitte)',
    'Back (Upper)': 'Rücken (oben)',
    Chest: 'Brust',
    Feet: 'Füße',
    'Foot (Left)': 'Fuß (links)',
    'Foot (Right)': 'Fuß (rechts)',
    'Hand (Left)': 'Hand (links)',
    'Hand (Right)': 'Hand (rechts)',
    Hands: 'Hände',
    Head: 'Kopf',
    'Head (Left)': 'Kopf (links)',
    'Head (Right)': 'Kopf (rechts)',
    Hips: 'Hüften',
    'Hip (Left)': 'Hüfte (links)',
    'Hip (Right)': 'Hüfte (rechts)',
    Joints: 'Gelenke',
    Knees: 'Knie',
    'Knee (Left)': 'Knie (links)',
    'Knee (Right)': 'Knie (rechts)',
    Legs: 'Beine',
    'Leg (Left)': 'Bein (links)',
    'Leg (Right)': 'Bein (rechts)',
    Neck: 'Hals',
    Shoulders: 'Schultern',
    'Shoulder (Left)': 'Schulter (links)',
    'Shoulder (Right)': 'Schulter (rechts)',
    Anxiety: 'Angstgefühl',
    Bleeding: 'Blutung',
    Constipation: 'Verstopfung',
    Dehydration: 'Dehydratation',
    Depression: 'Depression',
    Diarrhea: 'Durchfall',
    Dizziness: 'Schwindelgefühl',
    Drowsiness: 'Schläfrigkeit',
    Fainting: 'Ohnmacht',
    Fever: 'Fieber',
    Hallucination: 'Halluzinationen',
    Insomnia: 'Schlaflosigkeit',
    'Irregular Breathing': 'Unregelmäßige Atmung',
    'Irregular Pulse': 'Unregelmäßiger Puls',
    Itchiness: 'Juckreiz',
    Lethargy: 'Trägheit',
    'Mental Fogginess': 'Geistige Benebelung',
    Nausea: 'Übelkeit',
    Unconsciousness: 'Bewusstlosigkeit',
    Vomiting: 'Erbrechen',
    Aching: 'Ziehend',
    Burning: 'Brennend',
    Cold: 'Kalt',
    Cramping: 'Krampfend',
    Dull: 'Dumpf',
    'Electric Shocks': 'Stromschlagartig',
    Freezing: 'Eiskalt',
    Heavy: 'Schwer',
    Hot: 'Heiß',
    Nothing: 'Nichts',
    Numbness: 'Taub',
    Pinching: 'Kneifend',
    'Pins and Needles': 'Pieksend',
    Pounding: 'Hämmernd',
    Pressure: 'Drückend',
    Sharp: 'Spitz',
    Shooting: 'Einschießend',
    Stabbing: 'Stechend',
    Stiff: 'Steif',
    Throbbing: 'Pochend',
    Tingling: 'Kribbelnd',
    'Bowel Movement': 'Stuhlgang',
    Exercise: 'Sport',
    Food: 'Lebensmittel',
    Inactivity: 'Untätigkeit',
    'Lack of Sleep': 'Schlafmangel',
    'Light Touch / Clothing': 'Leichte Berührung / Kleidung',
    'Medication (Prescription)': 'Medikamente (Rezept)',
    'Medication (Non-Prescription)': 'Medikamente (ohne Rezept)',
    'Negative mood': 'Negative Stimmung',
    Sitting: 'Sitzen',
    Sleep: 'Schlafen',
    Smoking: 'Rauchen',
    Standing: 'Stehen',
    Stress: 'Stress',
    Urination: 'Urinieren',
    Walking: 'Gehen',
    Weather: 'Wetter',
    Distraction: 'Ablenkung',
    Heat: 'Hitze',
    Ice: 'Eis',
    Massage: 'Massage',
    Repositioning: 'Neupositionierung',
    Rest: 'Ausruhen',
    Stretching: 'Dehnen',
    'Talking to Someone': 'Mit jemandem sprechen',
    Bed: 'Bett',
    Car: 'Auto',
    Home: 'Zuhause',
    School: 'Schule',
    Shopping: 'Einkaufen',
    Outdoors: 'Draußen',
    Work: 'Arbeit',
    'Household chores': 'Hausarbeiten',
    Exercised: 'Sport getrieben',
    'Errands outside the home': 'Erledigungen außerhalb der Wohnung',
    'Bathed / Showered': 'Gebadet / Geduscht',
    'Prepared a meal': 'Eine Mahlzeit zubereitet',
    'Enjoyed the outdoors': 'Zeit draußen verbracht',
    'Got in and out of bed on my own':
      'Ohne Hilfe ins Bett gegangen und aufgestanden',
    'Slept restfully': 'Erholsam geschlafen',
    'Spent time with friends or family':
      'Zeit mit Familie oder Freunden verbracht',
    'Did an activity that I enjoyed': 'Etwas getan, das mir Spaß gemacht hat',
    'Connected with supportive people online or through text':
      'Im Internet oder über Textnachrichten mit unterstützenden Menschen Kontakt gehabt',
    'Attended a community event / support group':
      'An einem gesellschaftlichen Ereignis oder einer Selbsthilfegruppe teilgenommen',
    'Worked / studied / volunteered':
      'Gearbeitet / gelernt / ehrenamtliche Tätigkeit ausgeübt',
    'Connected with my spirituality': 'Meine Spiritualität erforscht',
    'Learned something new': 'Etwas Neues gelernt',
    'Rest and relaxation': 'Ruhe und Entspannung',
    'Spent time with my pet(s)': 'Zeit mit meinem/n Haustier/en verbracht',
    Breakthrough: shared('Breakthrough', 'de'),
    Constant: shared('Constant', 'de'),
    Intermittent: shared('Intermittent', 'de'),
  },

  es: {
    Abdomen: 'Abdomen',
    'Arm (Left)': 'Brazo (izquierdo)',
    'Arm (Right)': 'Brazo (derecho)',
    Arms: 'Brazos',
    Back: 'Espalda',
    'Back (Lower)': 'Espalda (baja)',
    'Back (Mid)': 'Espalda (media)',
    'Back (Upper)': 'Espalda (alta)',
    Chest: 'Pecho',
    Feet: 'Pies',
    'Foot (Left)': 'Pie (izquierdo)',
    'Foot (Right)': 'Pie (derecho)',
    'Hand (Left)': 'Mano (izquierda)',
    'Hand (Right)': 'Mano (derecha)',
    Hands: 'Manos',
    Head: 'Cabeza',
    'Head (Left)': 'Cabeza (izquierdo)',
    'Head (Right)': 'Cabeza (derecho)',
    Hips: 'Caderas',
    'Hip (Left)': 'Cadera (izquierda)',
    'Hip (Right)': 'Cadera (derecha)',
    Joints: 'Articulaciones',
    Knees: 'Rodillas',
    'Knee (Left)': 'Rodilla (izquierda)',
    'Knee (Right)': 'Rodilla (derecha)',
    Legs: 'Piernas',
    'Leg (Left)': 'Pierna (izquierda)',
    'Leg (Right)': 'Pierna (derecha)',
    Neck: 'Cuello',
    Shoulders: 'Hombros',
    'Shoulder (Left)': 'Hombro (izquierdo)',
    'Shoulder (Right)': 'Hombro (derecho)',
    Anxiety: 'Ansiedad',
    Bleeding: 'Sangrado',
    Constipation: 'Constipación',
    Dehydration: 'Deshidratación',
    Depression: 'Depresión',
    Diarrhea: 'Diarrea',
    Dizziness: 'Vértigo',
    Drowsiness: 'Somnolencia',
    Fainting: 'Desmayo',
    Fever: 'Fiebre',
    Hallucination: 'Alucinación',
    Insomnia: 'Insomnio',
    'Irregular Breathing': 'Respiración Irregular',
    'Irregular Pulse': 'Pulso Irregular',
    Itchiness: 'Comezón',
    Lethargy: 'Letargo',
    'Mental Fogginess': 'Confusión mental',
    Nausea: 'Náusea',
    Unconsciousness: 'Pérdida de Conciencia',
    Vomiting: 'Vómito',
    Aching: 'Doloroso',
    Burning: 'Quemante',
    Cold: 'Frío',
    Cramping: 'Cόlico',
    Dull: 'Sordo',
    'Electric Shocks': 'Descargas eléctricas',
    Freezing: 'Frío intenso',
    Heavy: 'Pesadez',
    Hot: 'Ardor',
    Nothing: 'Nada',
    Numbness: 'Adormecimiento',
    Pinching: 'Pellizco',
    'Pins and Needles': 'Hormigueo',
    Pounding: 'Martilleo',
    Pressure: 'Opresivo',
    Sharp: 'Agudo',
    Shooting: 'Lacerante',
    Stabbing: 'Punzante',
    Stiff: 'Rigidez',
    Throbbing: 'Pulsátil',
    Tingling: 'Cosquilleo',
    'Bowel Movement': 'Deposición',
    Exercise: 'Ejercicio',
    Food: 'Comida',
    Inactivity: 'Inactividad',
    'Lack of Sleep': 'Falta de sueño',
    'Light Touch / Clothing': 'Toque ligero / roce de tela',
    'Medication (Prescription)': 'Medicamento (con Prescripción)',
    'Medication (Non-Prescription)': 'Medicamento (Sin Prescripción)',
    'Negative mood': 'Mal humor',
    Sitting: 'Estar Sentado',
    Sleep: 'Sueño',
    Smoking: 'Fumar',
    Standing: 'Estar de pie',
    Stress: 'Estrés',
    Urination: 'Orinar',
    Walking: 'Caminar',
    Weather: 'Clima',
    Distraction: 'Distracción',
    Heat: 'Calor',
    Ice: 'Hielo',
    Massage: 'Masaje',
    Repositioning: 'Recolocación',
    Rest: 'Descansar',
    Stretching: 'Estiramiento',
    'Talking to Someone': 'Hablar con alguien',
    Bed: 'Cama',
    Car: 'Auto',
    Home: 'Casa',
    School: 'Escuela',
    Shopping: 'De compras',
    Outdoors: 'Aire Libre',
    Work: 'Trabajo',
    'Household chores': 'Tareas domésticas',
    Exercised: 'Ejercicio',
    'Errands outside the home': 'Mandados',
    'Bathed / Showered': 'Tomé un baño / una ducha',
    'Prepared a meal': 'Preparé una comida',
    'Enjoyed the outdoors': 'Disfruté al aire libre',
    'Got in and out of bed on my own':
      'Acostarme y levantarme de la cama por mi cuenta',
    'Slept restfully': 'Dormí placenteramente',
    'Spent time with friends or family': 'Pasé tiempo con amigos o familia',
    'Did an activity that I enjoyed': 'Hice una actividad que me gustó',
    'Connected with supportive people online or through text':
      'Me conecté con personas motivadoras en línea o por mensaje de texto',
    'Attended a community event / support group':
      'Asistí a un evento comunitario/grupo de apoyo',
    'Worked / studied / volunteered': 'Trabajé / estudié / hice voluntariado',
    'Connected with my spirituality': 'Me conecté con mi espiritualidad',
    'Learned something new': 'Aprendí algo nuevo',
    'Rest and relaxation': 'Descansé y me relajé',
    'Spent time with my pet(s)': 'Pasé tiempo con mi mascota(s)',
    Breakthrough: shared('Breakthrough', 'es'),
    Constant: shared('Constant', 'es'),
    Intermittent: shared('Intermittent', 'es'),
  },

  fr: {
    Abdomen: 'Abdomen',
    'Arm (Left)': 'Bras (gauche)',
    'Arm (Right)': 'Bras (droit)',
    Arms: 'Bras',
    Back: 'Dos',
    'Back (Lower)': 'Dos (basse)',
    'Back (Mid)': 'Dos (moyenne)',
    'Back (Upper)': 'Dos (haute)',
    Chest: 'Poitrine',
    Feet: 'Pieds',
    'Foot (Left)': 'Pied (gauche)',
    'Foot (Right)': 'Pied (droit)',
    'Hand (Left)': 'Main (gauche)',
    'Hand (Right)': 'Main (droite)',
    Hands: 'Mains',
    Head: 'Tête',
    'Head (Left)': 'Tête (gauche)',
    'Head (Right)': 'Tête (droite)',
    Hips: 'Hanches',
    'Hip (Left)': 'Hanche (gauche)',
    'Hip (Right)': 'Hanche (droit)',
    Joints: 'Articulations',
    Knees: 'Genoux',
    'Knee (Left)': 'Genou (gauche)',
    'Knee (Right)': 'Genou (droit)',
    Legs: 'Jambes',
    'Leg (Left)': 'Jambe (gauche)',
    'Leg (Right)': 'Jambe (droite)',
    Neck: 'Cou',
    Shoulders: 'Épaules',
    'Shoulder (Left)': 'Épaule (gauche)',
    'Shoulder (Right)': 'Épaule (droite)',
    Anxiety: 'Anxiété',
    Bleeding: 'Saignement',
    Constipation: 'Constipation',
    Dehydration: 'Déshydratation',
    Depression: 'Dépression',
    Diarrhea: 'Diarrhée',
    Dizziness: 'Vertiges',
    Drowsiness: 'Somnolence',
    Fainting: 'Évanouissement',
    Fever: 'Fièvre',
    Hallucination: 'Hallucination',
    Insomnia: 'Insomnie',
    'Irregular Breathing': 'Respiration Irrégulière',
    'Irregular Pulse': 'Pouls Irrégulier',
    Itchiness: 'Démangeaison',
    Lethargy: 'Léthargie',
    'Mental Fogginess': 'Confusion mentale',
    Nausea: 'Nausée',
    Unconsciousness: 'Perte de Conscience',
    Vomiting: 'Vomissement',
    Aching: 'Douloureuse',
    Burning: 'Brûlure',
    Cold: 'Froid intérieur',
    Cramping: 'Crampes',
    Dull: 'Sourde',
    'Electric Shocks': 'Décharges Électriques',
    Freezing: 'Froid intense',
    Heavy: 'Lourdeur',
    Hot: 'Ardeur',
    Nothing: 'Rien',
    Numbness: 'Engourdissementa',
    Pinching: 'Pincement',
    'Pins and Needles': 'Picotements',
    Pounding: 'Martèlement ',
    Pressure: 'Pression',
    Sharp: 'Tranchante',
    Shooting: 'Tirante',
    Stabbing: 'Élancement',
    Stiff: 'Rigidité',
    Throbbing: 'Lancinante',
    Tingling: 'Fourmillements',
    'Bowel Movement': 'Défécation',
    Exercise: 'Exercice',
    Food: 'Nourriture',
    Inactivity: 'Inactivité',
    'Lack of Sleep': 'Manque de sommeil',
    'Light Touch / Clothing': 'Toucher léger / Contact avec du tissu',
    'Medication (Prescription)': 'Médicaments (ordonnance)',
    'Medication (Non-Prescription)': 'Médicaments (sans ordonnance)',
    'Negative mood': 'Humeur négative',
    Sitting: 'Être assis',
    Sleep: 'Sommeil',
    Smoking: 'Fumer',
    Standing: 'Être debout',
    Stress: 'Stress',
    Urination: 'Uriner',
    Walking: 'Marcher',
    Weather: 'Climat',
    Distraction: 'Distraction',
    Heat: 'Chaleur',
    Ice: 'Glace',
    Massage: 'Massage',
    Repositioning: 'Repositionnement',
    Rest: 'Se reposer',
    Stretching: 'Étirage',
    'Talking to Someone': "Parler avec quelqu'un",
    Bed: 'Lit',
    Car: 'Voiture',
    Home: 'Maison',
    School: 'École',
    Shopping: 'Faire du shopping',
    Outdoors: 'Être Dehors',
    Work: 'Travail',
    'Household chores': 'Tâches ménagères',
    Exercised: "J'ai fait de l'exercice",
    'Errands outside the home': 'Des commissions',
    'Bathed / Showered': "J'ai pris un bain / une douche",
    'Prepared a meal': "J'ai preparé un repas",
    'Enjoyed the outdoors': "Je me suis amusé(e) à l'extérieur",
    'Got in and out of bed on my own': 'Aller au lit et en sortir par moi-même',
    'Slept restfully': "J'ai dormi paisiblement",
    'Spent time with friends or family':
      "J'ai passé du temps avec mes amis ou ma famille",
    'Did an activity that I enjoyed': "J'ai fait une activité agréable",
    'Connected with supportive people online or through text':
      "J'ai communiqué avec des personnes encourageantes en ligne ou par texto",
    'Attended a community event / support group':
      "J'ai assisté à un événement communautaire / groupe de soutien",
    'Worked / studied / volunteered':
      "J'ai travaillé / étudié / fait du bénévolat",
    'Connected with my spirituality': "J'ai approfondi ma vie spirituelle",
    'Learned something new': "J'ai appris quelque chose de nouveau",
    'Rest and relaxation': 'Je me suis reposé(e) et détendu(e)',
    'Spent time with my pet(s)':
      "J'ai passé du temps avec mon animal de compagnie",
    Breakthrough: shared('Breakthrough', 'fr'),
    Constant: shared('Constant', 'fr'),
    Intermittent: shared('Intermittent', 'fr'),
  },

  ko: {
    Abdomen: '복부',
    'Arm (Left)': '팔 (왼쪽)',
    'Arm (Right)': '팔 (오른쪽)',
    Arms: '팔',
    Back: '등',
    'Back (Lower)': '등 (아랫부분)',
    'Back (Mid)': '등 (중간부분)',
    'Back (Upper)': '등 (윗부분)',
    Chest: '가슴',
    Feet: '발',
    'Foot (Left)': '발 (왼쪽)',
    'Foot (Right)': '발 (오른쪽)',
    'Hand (Left)': '손 (왼쪽)',
    'Hand (Right)': '손 (오른쪽)',
    Hands: '손',
    Head: '머리',
    'Head (Left)': '머리 (왼쪽)',
    'Head (Right)': '머리 (오른쪽)',
    Hips: '엉덩이',
    'Hip (Left)': '엉덩이 (왼쪽)',
    'Hip (Right)': '엉덩이 (오른쪽)',
    Joints: '관절',
    Knees: '무릎',
    'Knee (Left)': '무릎 (왼쪽)',
    'Knee (Right)': '무릎 (오른쪽)',
    Legs: '다리',
    'Leg (Left)': '다리 (왼쪽)',
    'Leg (Right)': '다리 (오른쪽)',
    Neck: '목',
    Shoulders: '어깨',
    'Shoulder (Left)': '어깨 (왼쪽)',
    'Shoulder (Right)': '어깨 (오른쪽)',
    Anxiety: '불안감',
    Bleeding: '출혈',
    Constipation: '변비',
    Dehydration: '탈수',
    Depression: '우울증',
    Diarrhea: '설사',
    Dizziness: '현기증',
    Drowsiness: '졸림',
    Fainting: '기절',
    Fever: '발열',
    Hallucination: '환각',
    Insomnia: '불면증',
    'Irregular Breathing': '불규칙한 호흡',
    'Irregular Pulse': '불규칙한 맥박',
    Itchiness: '가려움',
    Lethargy: '무기력',
    'Mental Fogginess': '흐리멍텅',
    Nausea: '구역질',
    Unconsciousness: '혼수 상태',
    Vomiting: '구토',
    Aching: '쑤심',
    Burning: '타는듯한',
    Cold: '으슬으슬한',
    Cramping: '경련이 일어나는',
    Dull: '둔해진',
    'Electric Shocks': '찌릿찌릿함',
    Freezing: '추운',
    Heavy: '나른한',
    Hot: '뜨거움',
    Nothing: '없음',
    Numbness: '저림',
    Pinching: '격통',
    'Pins and Needles': '마비감',
    Pounding: '지끈거리는',
    Pressure: '압박하는',
    Sharp: '격렬한',
    Shooting: '찌르르한',
    Stabbing: '칼로 찌르는 듯한',
    Stiff: '뻐근한',
    Throbbing: '욱신거리는',
    Tingling: '따끔거림',
    'Bowel Movement': '배변',
    Exercise: '운동',
    Food: '음식',
    Inactivity: '비활동',
    'Lack of Sleep': '수면 부족',
    'Light Touch / Clothing': '살짝 스치는 접촉/옷깃',
    'Medication (Prescription)': '약물 치료 (처방)',
    'Medication (Non-Prescription)': '약물 치료 (비 처방)',
    'Negative mood': '부정적인 기분',
    Sitting: '앉을 때',
    Sleep: '수면',
    Smoking: '흡연',
    Standing: '기립 자세',
    Stress: '스트레스',
    Urination: '배뇨',
    Walking: '걷기',
    Weather: '날씨',
    Distraction: '기분 전환',
    Heat: '열',
    Ice: '얼음',
    Massage: '마사지',
    Repositioning: '위치 조정',
    Rest: '휴식',
    Stretching: '스트레칭',
    'Talking to Someone': '다른 사람과의 대화',
    Bed: '침대',
    Car: '자동차',
    Home: '집',
    School: '학교',
    Shopping: '쇼핑',
    Outdoors: '옥외',
    Work: '직장',
    'Household chores': '집안일',
    Exercised: '운동',
    'Errands outside the home': '집 밖으로 심부름',
    'Bathed / Showered': '목욕/샤워',
    'Prepared a meal': '식사 준비',
    'Enjoyed the outdoors': '실외활동 즐기기',
    'Got in and out of bed on my own': '침대에서 뒹굴기',
    'Slept restfully': '편안하게 잠자기',
    'Spent time with friends or family': '친구 또는 가족과 시간 보내기',
    'Did an activity that I enjoyed': '내가 즐기는 활동 했음',
    'Connected with supportive people online or through text':
      '온라인 또는 문자로 지지적인 사람과 연락했음',
    'Attended a community event / support group':
      '커뮤니티 이벤트/지원 그룹 참여함',
    'Worked / studied / volunteered': '일/공부/봉사활동',
    'Connected with my spirituality': '내 영성과 연결함',
    'Learned something new': '새로운 것을 배움',
    'Rest and relaxation': '안식 및 휴식',
    'Spent time with my pet(s)': '반려동물과 시간 보내기',
    Breakthrough: shared('Breakthrough', 'ko'),
    Constant: shared('Constant', 'ko'),
    Intermittent: shared('Intermittent', 'ko'),
  },

  ru: {
    Abdomen: 'Живот',
    'Arm (Left)': 'Рука (левая)',
    'Arm (Right)': 'Рука (правая)',
    Arms: 'Руки',
    Back: 'Спина',
    'Back (Lower)': 'Спина (ниже)',
    'Back (Mid)': 'Спина (посередине)',
    'Back (Upper)': 'Спина (выше)',
    Chest: 'Грудь',
    Feet: 'Стопы',
    'Foot (Left)': 'Стопа (левая)',
    'Foot (Right)': 'Стопа (правая)',
    'Hand (Left)': 'Кисть (левая)',
    'Hand (Right)': 'Кисть (правая)',
    Hands: 'Кисти',
    Head: 'Голова',
    'Head (Left)': 'Голова (слева)',
    'Head (Right)': 'Голова (справа)',
    Hips: 'Бедра',
    'Hip (Left)': 'Бедро (левое)',
    'Hip (Right)': 'Бедро (правое)',
    Joints: 'Суставы',
    Knees: 'Колени',
    'Knee (Left)': 'Колено (левое)',
    'Knee (Right)': 'Колено (правое)',
    Legs: 'Ноги',
    'Leg (Left)': 'Нога (левая)',
    'Leg (Right)': 'Нога (правая)',
    Neck: 'Шея',
    Shoulders: 'Плечи',
    'Shoulder (Left)': 'Плечо (левое)',
    'Shoulder (Right)': 'Плечо (правое)',
    Anxiety: 'Тревога',
    Bleeding: 'Кровотечение',
    Constipation: 'Запор',
    Dehydration: 'Обезвоживание',
    Depression: 'Депрессия',
    Diarrhea: 'Понос',
    Dizziness: 'Головокружение',
    Drowsiness: 'Сонливость',
    Fainting: 'Обморок',
    Fever: 'Лихорадка',
    Hallucination: 'Галлюцинации',
    Insomnia: 'Бессонница',
    'Irregular Breathing': 'Затруднённое дыхание',
    'Irregular Pulse': 'Нарушение пульса',
    Itchiness: 'Зуд',
    Lethargy: 'Вялость',
    'Mental Fogginess': 'Затуманенный разум',
    Nausea: 'Тошнота',
    Unconsciousness: 'Потеря сознания',
    Vomiting: 'Рвота',
    Aching: 'Ноющая',
    Burning: 'Жгучая',
    Cold: 'Холодящая',
    Cramping: 'Спазмы',
    Dull: 'Тупая',
    'Electric Shocks': 'Как удар током',
    Freezing: 'Морозящая',
    Heavy: 'Сильная',
    Hot: 'Жар',
    Nothing: 'Ничего',
    Numbness: 'Онемение',
    Pinching: 'Щемящая',
    'Pins and Needles': 'Затекла часть тела',
    Pounding: 'Бьющая',
    Pressure: 'Давящая',
    Sharp: 'Резкая',
    Shooting: 'Стреляющая',
    Stabbing: 'Режущая',
    Stiff: 'Невозможно пошевелить',
    Throbbing: 'Пульсирующая',
    Tingling: 'Покалывание',
    'Bowel Movement': 'Дефекация',
    Exercise: 'Тренировка',
    Food: 'Еда',
    Inactivity: 'Покой',
    'Lack of Sleep': 'Бессонница',
    'Light Touch / Clothing': 'Лёгкое прикосновение',
    'Medication (Prescription)': 'Лекарства (назначенные)',
    'Medication (Non-Prescription)': 'Лекарства (не назначенные)',
    'Negative mood': 'Негативное настроение',
    Sitting: 'Сидение',
    Sleep: 'Сон',
    Smoking: 'Курение',
    Standing: 'Стояние',
    Stress: 'Стресс',
    Urination: 'Мочеиспускание',
    Walking: 'Гуляние',
    Weather: 'Погода',
    Distraction: 'Отвлечение',
    Heat: 'Тепло',
    Ice: 'Холод',
    Massage: 'Массаж',
    Repositioning: 'Смена обстановки',
    Rest: 'Отдых',
    Stretching: 'Гимнастика',
    'Talking to Someone': 'Поговорить с кем-либо',
    Bed: 'В кровати',
    Car: 'В машине',
    Home: 'Дома',
    School: 'На учёбе',
    Shopping: 'Шоппинг',
    Outdoors: 'На улице',
    Work: 'На работе',
    'Household chores': 'Сделал(а) домашние дела',
    Exercised: 'Потренировался(-ась)',
    'Errands outside the home': 'Сделал(а) дела вне дома',
    'Bathed / Showered': 'Принял(а) ванну / душ',
    'Prepared a meal': 'Приготовил(а) еду',
    'Enjoyed the outdoors': 'Провел(а) время на открытом воздухе',
    'Got in and out of bed on my own': 'Быстро и легко проснулся(-ась) ',
    'Slept restfully': 'Выспался(ась)',
    'Spent time with friends or family':
      'Провел(а) время с друзьями или семьей',
    'Did an activity that I enjoyed': 'Получил(а) удовольствие от чего-либо',
    'Connected with supportive people online or through text':
      'Связался(-ась) с поддерживающими меня людьми онлайн или через СМС',
    'Attended a community event / support group':
      'Принял(а) участие в общественном деле / группе поддержки',
    'Worked / studied / volunteered':
      'Работал(а) / учился(-ась) / занимался(-ась) волонтерством',
    'Connected with my spirituality': 'Делал(а) духовные дела',
    'Learned something new': 'Изучил(а) что-то новое',
    'Rest and relaxation': 'Отдохнул(а) и расслабился(-ась)',
    'Spent time with my pet(s)':
      'Провел(а) время со своими домашними животными',
    Breakthrough: shared('Breakthrough', 'ru'),
    Constant: shared('Constant', 'ru'),
    Intermittent: shared('Intermittent', 'ru'),
  },

  'zh-rCN': {
    Abdomen: '腹部',
    'Arm (Left)': '手臂（左）',
    'Arm (Right)': '手臂（右）',
    Arms: '双臂',
    Back: '背部',
    'Back (Lower)': '背部（下部）',
    'Back (Mid)': '背部（中部）',
    'Back (Upper)': '背部（上部）',
    Chest: '胸部',
    Feet: '脚部',
    'Foot (Left)': '左脚',
    'Foot (Right)': '右脚',
    'Hand (Left)': '手（左）',
    'Hand (Right)': '手（右）',
    Hands: '双手',
    Head: '头部',
    'Head (Left)': '头部（左侧）',
    'Head (Right)': '头部（右侧）',
    Hips: '胯骨',
    'Hip (Left)': '胯骨（左侧）',
    'Hip (Right)': '胯骨（右侧）',
    Joints: '关节',
    Knees: '膝盖',
    'Knee (Left)': '膝盖（左侧）',
    'Knee (Right)': '膝盖（右侧）',
    Legs: '腿部',
    'Leg (Left)': '腿部（左腿）',
    'Leg (Right)': '腿部（右腿）',
    Neck: '颈部',
    Shoulders: '肩部',
    'Shoulder (Left)': '肩部（左侧）',
    'Shoulder (Right)': '肩部（右侧）',
    Anxiety: '焦虑',
    Bleeding: '出血',
    Constipation: '便秘',
    Dehydration: '脱水',
    Depression: '抑郁',
    Diarrhea: '腹泻',
    Dizziness: '眩晕',
    Drowsiness: '困倦',
    Fainting: '晕',
    Fever: '发烧',
    Hallucination: '幻觉',
    Insomnia: '失眠',
    'Irregular Breathing': '呼吸不匀',
    'Irregular Pulse': '脉搏不匀',
    Itchiness: '瘙痒',
    Lethargy: '嗜睡',
    'Mental Fogginess': '头脑不清',
    Nausea: '恶心',
    Unconsciousness: '失去意识',
    Vomiting: '呕吐',
    Aching: '疼',
    Burning: '烧灼感',
    Cold: '冷',
    Cramping: '抽筋',
    Dull: '钝痛',
    'Electric Shocks': '电击感',
    Freezing: '冻',
    Heavy: '沉重',
    Hot: '发热',
    Nothing: '没有',
    Numbness: '麻木',
    Pinching: '掐',
    'Pins and Needles': '针刺感',
    Pounding: '锤击',
    Pressure: '压力',
    Sharp: '尖锐',
    Shooting: '射击',
    Stabbing: '刀刺',
    Stiff: '僵硬',
    Throbbing: '跳动',
    Tingling: '麻刺感',
    'Bowel Movement': '排便',
    Exercise: '锻炼',
    Food: '食物',
    Inactivity: '静止',
    'Lack of Sleep': '缺乏睡眠',
    'Light Touch / Clothing': '轻触 / 穿衣服',
    'Medication (Prescription)': '药物（处方药）',
    'Medication (Non-Prescription)': '药物（非处方药）',
    'Negative mood': '负面情绪',
    Sitting: '坐',
    Sleep: '睡眠',
    Smoking: '吸烟',
    Standing: '站立',
    Stress: '压力',
    Urination: '排尿',
    Walking: '行走',
    Weather: '天气',
    Distraction: '注意力分散',
    Heat: '加热',
    Ice: '冰块',
    Massage: '按摩',
    Repositioning: '改变姿势',
    Rest: '休息',
    Stretching: '伸展',
    'Talking to Someone': '与他人交谈',
    Bed: '在床上',
    Car: '在汽车中',
    Home: '在家里',
    School: '在学校',
    Shopping: '在购物',
    Outdoors: '在户外',
    Work: '在工作',
    'Household chores': '做家务',
    Exercised: '锻炼',
    'Errands outside the home': '离开家办事',
    'Bathed / Showered': '泡澡 / 淋浴',
    'Prepared a meal': '做饭',
    'Enjoyed the outdoors': '享受户外时光',
    'Got in and out of bed on my own': '自己上下床',
    'Slept restfully': '平静的睡眠',
    'Spent time with friends or family': '与亲友共度时光',
    'Did an activity that I enjoyed': '进行了我喜欢的活动',
    'Connected with supportive people online or through text':
      '通过网上或短信与支持者联系',
    'Attended a community event / support group': '参加社区活动 / 支持小组',
    'Worked / studied / volunteered': '工作 / 学习 / 志愿服务',
    'Connected with my spirituality': '与我的精神导师联系',
    'Learned something new': '学习了新东西',
    'Rest and relaxation': '休息放松',
    'Spent time with my pet(s)': '和我的宠物共度时光',
    Breakthrough: shared('Breakthrough', 'zh-rCN'),
    Constant: shared('Constant', 'zh-rCN'),
    Intermittent: shared('Intermittent', 'zh-rCN'),
  },
});
