import LocalizedStrings from 'react-native-localization';
import shared from '../../strings/shared';

export default new LocalizedStrings({
  en: {
    pain_guide_access_denied_message: "You don't have access to Pain Guide",

    pain_guide_bottom_bar_button: 'Guide',

    pain_guide_card_title: 'Pain Guide',
    pain_guide_card_retry_button: shared('Retry', 'en'),
    pain_guide_card_featured_articles_title: 'Recommended Content',
    pain_guide_card_featured_articles_view_all_button: 'View all',
    pain_guide_card_programs_title: 'Programs',

    pain_guide_home_screen_title: 'Pain Guide',
    pain_guide_home_featured_content_title: 'Recommended Content',
    pain_guide_home_content_by_category_title: 'Content by Category',
    pain_guide_home_coping_strategy_category: 'Coping Strategies',
    pain_guide_home_about_pain_category: 'Learn About Pain',
    pain_guide_home_treatments_category: 'Treatments',
    pain_guide_home_user_stories_category: 'User Stories',

    pain_guide_not_logged_in_error: shared('Login', 'en'),
    pain_guide_home_bookmarked_content_title: 'Recommendations and bookmarks',
    pain_guide_home_recommended_content_category_title: 'Recommended content',
    pain_guide_home_recommended_content_category_subtitle: 'by {0} at the {1}',
    pain_guide_home_bookmarked_content_category_title: 'Bookmarked content',
    pain_guide_home_bookmarked_content_category_subtitle:
      'See all of the content you have bookmarked.',
    pain_guide_home_bookmarked_content_empty:
      'There is no bookmarked content yet',

    pain_guide_program_title: 'Program',
    pain_guide_program_retrieve_error_button: 'Failed to retrieve program',
    pain_guide_program_retry_button: shared('Retry', 'en'),
    pain_guide_program_locked_text: 'Locked',

    pain_guide_content_feedback_title: 'Was this content helpful?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'en'),
    pain_guide_content_feedback_no_button: shared('No', 'en'),
    pain_guide_content_feedback_additional_field_text: 'Additional feedback',
    pain_guide_content_feedback_send_button: 'Send Feedback',
    pain_guide_content_feedback_update_button: 'Update Feedback',
    pain_guide_content_feedback_success_message: 'Feedback updated',
    pain_guide_content_add_bookmark_header_button: 'Bookmark Content',
    pain_guide_content_remove_bookmark_header_button: 'Remove Bookmark',
    pain_guide_content_feedback_disclaimer_text:
      'Anything you write here as feedback will NOT be shared publicly. It will be only used for improving the user experience. To know more about how we protect your data please visit our {0} and {1}.',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'en'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'en',
    ),

    pain_guide_recommended_disclaimer_title:
      'This content has been recommended for you by {0} at {1}',
  },
  de: {
    pain_guide_bottom_bar_button: 'Leitfaden',

    pain_guide_card_title: 'Schmerz-Leitfaden',
    pain_guide_card_retry_button: shared('Retry', 'de'),
    pain_guide_card_featured_articles_title: 'Empfohlene Inhalte',
    pain_guide_card_featured_articles_view_all_button: 'Alle ansehen',

    pain_guide_home_screen_title: 'Schmerz-Leitfaden',
    pain_guide_home_featured_content_title: 'Empfohlene Inhalte',
    pain_guide_home_content_by_category_title: 'Empfohlene Inhalte',
    pain_guide_home_coping_strategy_category: 'Bewältigungsstrategien',
    pain_guide_home_about_pain_category: 'Mehr über Schmerzen lernen',
    pain_guide_home_treatments_category: 'Behandlungen',
    pain_guide_home_user_stories_category: 'Erfahrungsberichte',

    pain_guide_home_bookmarked_content_title: 'Empfehlungen und Lesezeichen',
    pain_guide_home_recommended_content_category_title: 'Empfohlene Inhalte',
    pain_guide_home_recommended_content_category_subtitle:
      'der {1} von der {1}',
    pain_guide_home_bookmarked_content_category_title:
      'Inhalte mit Lesezeichen',
    pain_guide_home_bookmarked_content_category_subtitle:
      'Sehen Sie alle Inhalte, die Sie mit einem Lesezeichen versehen haben.',
    pain_guide_home_bookmarked_content_empty:
      'Es sind noch keine Inhalte mit Lesezeichen versehen',

    pain_guide_content_feedback_title: 'War dieser Inhalt hilfreich?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'de'),
    pain_guide_content_feedback_no_button: shared('No', 'de'),
    pain_guide_content_feedback_additional_field_text: 'Zusätzliches Feedback',
    pain_guide_content_feedback_send_button: 'Feedback senden',
    pain_guide_content_feedback_update_button: 'Feedback aktualisieren',
    pain_guide_content_feedback_success_message: 'Feedback aktualisiert',
    pain_guide_content_add_bookmark_header_button: 'Inhalt merken',
    pain_guide_content_remove_bookmark_header_button: 'Lesezeichen entfernen',
    pain_guide_content_feedback_disclaimer_text:
      'Alles, was Sie hier als Feedback schreiben, wird NICHT öffentlich geteilt. Es wird ausschließlich zur Verbesserung der Benutzerfreundlichkeit verwendet. Um mehr darüber zu erfahren, wie wir Ihre Daten schützen, besuchen Sie bitte unsere {0} und {1}.',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'de'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'de',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'de'),
  },
  es: {
    pain_guide_bottom_bar_button: 'Guía',

    pain_guide_card_title: 'Guía de dolor',
    pain_guide_card_retry_button: shared('Retry', 'es'),
    pain_guide_card_featured_articles_title: 'Contenido recomendado',
    pain_guide_card_featured_articles_view_all_button: 'Ver todos',

    pain_guide_home_screen_title: 'Guía de dolor',
    pain_guide_home_featured_content_title: 'Contenido recomendado',
    pain_guide_home_content_by_category_title: 'Contenido por categoría',
    pain_guide_home_coping_strategy_category: 'Estrategias de adaptación',
    pain_guide_home_about_pain_category: 'Aprenda sobre dolor',
    pain_guide_home_treatments_category: 'Tratamientos',
    pain_guide_home_user_stories_category: 'Relatos de usuarios',

    pain_guide_home_bookmarked_content_title: 'Recomendaciones y favoritos',
    pain_guide_home_recommended_content_category_title: 'Contenido recomendado',
    pain_guide_home_recommended_content_category_subtitle: 'por {0} en {1}',
    pain_guide_home_bookmarked_content_category_title: 'Contenido señalado',
    pain_guide_home_bookmarked_content_category_subtitle: 'Contenido señalado',
    pain_guide_home_bookmarked_content_empty:
      'Todavía no hay contenido favorito',

    pain_guide_content_feedback_title: '¿Fue útil este contenido?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'es'),
    pain_guide_content_feedback_no_button: shared('No', 'es'),
    pain_guide_content_feedback_additional_field_text:
      'Comentarios adicionales',
    pain_guide_content_feedback_send_button: 'Enviar comentarios',
    pain_guide_content_feedback_update_button: 'Actualizar devolución',
    pain_guide_content_feedback_success_message: 'Devolución actualizada',
    pain_guide_content_add_bookmark_header_button:
      'Agregar contenido a favoritos',
    pain_guide_content_remove_bookmark_header_button: 'Eliminar de favoritos',
    pain_guide_content_feedback_disclaimer_text:
      'Lo que se escriba aquí como comentario NO se compartirá públicamente. Solo se usará para mejorar la experiencia del usuario. Para saber más sobre cómo protegemos sus datos, visite nuestro {0} y {1}.',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'es'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'es',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'es'),
  },
  fr: {
    pain_guide_bottom_bar_button: 'Guide',

    pain_guide_card_title: 'Guide sur la douleur',
    pain_guide_card_retry_button: shared('Retry', 'fr'),
    pain_guide_card_featured_articles_title: 'Guide sur la douleur',
    pain_guide_card_featured_articles_view_all_button: 'Tout afficher',

    pain_guide_home_screen_title: 'Guide sur la douleur',
    pain_guide_home_featured_content_title: 'Guide sur la douleur',
    pain_guide_home_content_by_category_title: 'Guide sur la douleur',
    pain_guide_home_coping_strategy_category: "Stratégies d'adaptation",
    pain_guide_home_about_pain_category:
      'En apprendre davantage sur la douleur',
    pain_guide_home_treatments_category: 'Traitements',
    pain_guide_home_user_stories_category: "Témoignages d'utilisateurs",

    pain_guide_home_bookmarked_content_title: 'Recommandations et signets',
    pain_guide_home_recommended_content_category_title: 'Contenu recommandé',
    pain_guide_home_recommended_content_category_subtitle: 'par {0} - {1}',
    pain_guide_home_bookmarked_content_category_title:
      'Contenu ajouté aux favoris',
    pain_guide_home_bookmarked_content_category_subtitle:
      'Voir tout le contenu mis en favori.',
    pain_guide_home_bookmarked_content_empty:
      "Aucun contenu n'a été mis en signet pour le moment",

    pain_guide_content_feedback_title: 'Ce contenu a-t-il été utile ?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'fr'),
    pain_guide_content_feedback_no_button: shared('No', 'fr'),
    pain_guide_content_feedback_additional_field_text:
      'Commentaires supplémentaires',
    pain_guide_content_feedback_send_button: 'Envoyer un commentaire',
    pain_guide_content_feedback_update_button: 'Mettre à jour les commentaires',
    pain_guide_content_feedback_success_message: 'Commentaires mis à jour',
    pain_guide_content_add_bookmark_header_button:
      'Mettre le contenu en signet',
    pain_guide_content_remove_bookmark_header_button: 'Retirer le signet',
    pain_guide_content_feedback_disclaimer_text:
      "Tout commentaire formulé ici ne sera PAS partagé publiquement. Il ne servira qu'à des fins d'amélioration de l'expérience utilisateur. Pour en savoir plus sur la manière dont nous protégeons vos données, veuillez consulter notre {0} et notre {1}.",
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'fr'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'fr',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'fr'),
  },
  ko: {
    pain_guide_bottom_bar_button: '가이드',

    pain_guide_card_title: '통증 가이드',
    pain_guide_card_retry_button: shared('Retry', 'ko'),
    pain_guide_card_featured_articles_title: '추천 콘텐츠',
    pain_guide_card_featured_articles_view_all_button: '모두 보기',

    pain_guide_home_screen_title: '통증 가이드',
    pain_guide_home_featured_content_title: '추천 콘텐츠',
    pain_guide_home_content_by_category_title: '카테고리별 콘텐츠',
    pain_guide_home_coping_strategy_category: '대처 전략',
    pain_guide_home_about_pain_category: '통증에 대해 알아보기',
    pain_guide_home_treatments_category: '치료',
    pain_guide_home_user_stories_category: '회원 스토리',

    pain_guide_home_bookmarked_content_title: '권장 사항 및 북마크',
    pain_guide_home_recommended_content_category_title: '추천 콘텐츠',
    pain_guide_home_recommended_content_category_subtitle:
      '{1}의 {0}에서 추천함',
    pain_guide_home_bookmarked_content_category_title: '북마크된 콘텐츠',
    pain_guide_home_bookmarked_content_category_subtitle:
      '북마크한 콘텐츠를 모두 확인하세요.',
    pain_guide_home_bookmarked_content_empty:
      '아직 북마크 설정된 콘텐츠가 없습니다.',

    pain_guide_content_feedback_title: '이 콘텐츠가 도움이 되셨나요?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'ko'),
    pain_guide_content_feedback_no_button: shared('No', 'ko'),
    pain_guide_content_feedback_additional_field_text: '추가 피드백',
    pain_guide_content_feedback_send_button: '피드백 전송',
    pain_guide_content_feedback_update_button: '피드백 업데이트',
    pain_guide_content_feedback_success_message: '피드백 업데이트됨',
    pain_guide_content_add_bookmark_header_button: '콘텐츠 북마크 설정',
    pain_guide_content_remove_bookmark_header_button: '북마크 해제',
    pain_guide_content_feedback_disclaimer_text:
      '여기에 피드백으로 작성하시는 내용은 절대 공개되지 않을 것입니다. 사용자 경험을 개선하기 위한 목적으로만 이용됩니다. 당사에서 고객님의 데이터를 보호하는 방식에 대해서 알아보려면, 당사의 {0} 및 {1}을(를) 방문하세요.',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'ko'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'ko',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'ko'),
  },
  ru: {
    pain_guide_bottom_bar_button: 'Руководство',

    pain_guide_card_title: 'Всё о боли',
    pain_guide_card_retry_button: shared('Retry', 'ru'),
    pain_guide_card_featured_articles_title: 'Рекомендованные материалы',
    pain_guide_card_featured_articles_view_all_button: 'Просмотреть все',

    pain_guide_home_screen_title: 'Всё о боли',
    pain_guide_home_featured_content_title: 'Рекомендованные материалы',
    pain_guide_home_content_by_category_title: 'Информация по категориям',
    pain_guide_home_coping_strategy_category:
      'Стратегии преодоления трудностей',
    pain_guide_home_about_pain_category: 'Узнайте больше о боли',
    pain_guide_home_treatments_category: 'Лечение',
    pain_guide_home_user_stories_category: 'Рассказы пользователей',

    pain_guide_home_bookmarked_content_title: 'Рекомендации и закладки',
    pain_guide_home_recommended_content_category_title:
      'Рекомендованные материалы',
    pain_guide_home_recommended_content_category_subtitle: 'от {0}, {1}',
    pain_guide_home_bookmarked_content_category_title: 'Материалы в закладках',
    pain_guide_home_bookmarked_content_category_subtitle:
      'Просмотр всех статей, которые вы поместили в закладки.',
    pain_guide_home_bookmarked_content_empty: 'В закладках пока ничего нет',

    pain_guide_content_feedback_title: 'Помогла ли вам эта информация?',
    pain_guide_content_feedback_yes_button: shared('Yes', 'ru'),
    pain_guide_content_feedback_no_button: shared('No', 'ru'),
    pain_guide_content_feedback_additional_field_text: 'Дополнительный отзыв',
    pain_guide_content_feedback_send_button: 'Отправить отзыв',
    pain_guide_content_feedback_update_button: 'Обновить отзыв',
    pain_guide_content_feedback_success_message: 'Отзыв обновлен',
    pain_guide_content_add_bookmark_header_button: 'Добавить в закладки',
    pain_guide_content_remove_bookmark_header_button: 'Удалить закладку',
    pain_guide_content_feedback_disclaimer_text:
      'Всё, написанное вами в качестве отзыва, опубликовано НЕ будет. Эта информация используется только для улучшения опыта использования. Чтобы подробнее узнать, как мы защищаем ваши данные, ознакомьтесь с документами: {0} и {1}.',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'ru'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'ru',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'ru'),
  },
  'zh-rCN': {
    pain_guide_bottom_bar_button: '指导',

    pain_guide_card_title: '疼痛指南',
    pain_guide_card_retry_button: shared('Retry', 'zh-rCN'),
    pain_guide_card_featured_articles_title: '推荐内容',
    pain_guide_card_featured_articles_view_all_button: '查看全部',

    pain_guide_home_screen_title: '疼痛指南',
    pain_guide_home_featured_content_title: '推荐内容',
    pain_guide_home_content_by_category_title: '内容分类',
    pain_guide_home_coping_strategy_category: '应对策略',
    pain_guide_home_about_pain_category: '了解疼痛状况',
    pain_guide_home_treatments_category: '治疗',
    pain_guide_home_user_stories_category: '用户故事',

    pain_guide_home_bookmarked_content_title: '推荐和收藏的內容',
    pain_guide_home_recommended_content_category_title: '推荐内容',
    pain_guide_home_recommended_content_category_subtitle: '由 {1}的{0} 建议',
    pain_guide_home_bookmarked_content_category_title: '已添加书签的内容',
    pain_guide_home_bookmarked_content_category_subtitle:
      '查看所有已添加书签的内容。',
    pain_guide_home_bookmarked_content_empty: '尚未添加书签的内容',

    pain_guide_content_feedback_title: '这些内容有帮助吗？',
    pain_guide_content_feedback_yes_button: shared('Yes', 'zh-rCN'),
    pain_guide_content_feedback_no_button: shared('No', 'zh-rCN'),
    pain_guide_content_feedback_additional_field_text: '其他反馈',
    pain_guide_content_feedback_send_button: '发送反馈',
    pain_guide_content_feedback_update_button: '更新反馈',
    pain_guide_content_feedback_success_message: '反馈已更新',
    pain_guide_content_add_bookmark_header_button: '收藏内容',
    pain_guide_content_remove_bookmark_header_button: '取消收藏',
    pain_guide_content_feedback_disclaimer_text:
      '您的反馈内容不会公开分享，并仅用于改善用户体验。如欲详细了解我们如何保护您的数据，请访问我们的{0}和{1}。',
    pain_guide_content_feedback_disclaimer_eula_text: shared('EULA', 'zh-rCN'),
    pain_guide_content_feedback_disclaimer_privacy_policy_text: shared(
      'Privacy Policy',
      'zh-rCN',
    ),
    pain_guide_not_logged_in_error: shared('Login', 'zh-rCN'),
  },
});
