import * as types from './actiontypes';
import DeviceInfo from 'react-native-device-info';

const INITIAL = {
  appVersion: +(global.__REMOTEDEV__ ? 7000 : DeviceInfo.getBuildNumber()),
};

function UpgradesReducer(state = { ...INITIAL }, action) {
  switch (action.type) {
    case types.UPGRADE_APP_VERSION: {
      return {
        ...state,
        appVersion: action.payload,
      };
    }
    case types.OLD_ANDROID_UPGRADED: {
      return {
        ...state,
        old_android_upgraded: true,
      };
    }
    default:
      return state;
  }
}

export { UpgradesReducer };
