const deepCopy = (object) => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  let _object = typeof object.length === 'number' ? [...object] : { ...object };
  Object.keys(_object).forEach((key) => {
    if (key !== '__proto__') {
      _object[key] = deepCopy(_object[key]);
    }
  });
  return _object;
};

export { deepCopy };
