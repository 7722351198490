// @flow
import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { designConstants } from '../styles';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Text from './Text';
import TouchableNative from './TouchableNative';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import { withTheme } from '../styles/useThemeContext';

class HeaderIcon extends React.Component {
  lastPress: 0;

  onPress = () => {
    let now = new Date().getTime();
    if (now - this.lastPress < designConstants.buttonPressDelay) {
      return;
    }
    this.lastPress = 0;
    let btn = this.props.icon;
    this.props.back && (btn = 'back');
    this.props.menu && (btn = 'menu');
    this.props.checkmark && (btn = 'checkmark');

    trackButtonPress('Header button ' + btn);
    this.props.onPress();
  };

  render() {
    const {
      // Possible preset icons
      menu,
      back,
      checkmark,
      trash,

      // Color, which can be only white or blue in header
      blue,
      black,
      darkGray,
      gray,

      // If icon is not preset - these props defines its type and icon-name
      icon,
      iconType,
      children,

      style,
      text,
      loading,
      web,
      ...otherProps
    } = this.props;
    const { colors } = this.props.theme;

    const _style = StyleSheet.flatten(style);

    let iconStyle = {
      ..._style,

      padding: undefined,
      paddingRight: undefined,
      paddingLeft: undefined,
      paddingBottom: undefined,
      paddingTop: undefined,
      paddingHorizontal: undefined,
      paddingVertical: undefined,

      margin: undefined,
      marginRight: undefined,
      marginLeft: undefined,
      marginTop: undefined,
      marginBottom: undefined,
      marginHorizontal: undefined,
      marginVertical: undefined,
    };

    let color = colors.white;
    blue && (color = colors.buttonBlue);
    black && (color = colors.black);
    gray && (color = colors.gray);
    darkGray && (color = colors.darkGray);

    let iconToRender = null;

    if (menu && !designConstants.isWeb) {
      return null;
    }

    children && (iconToRender = children);

    menu &&
      (iconToRender = (
        <Icon
          type={'MaterialIcons'}
          name={'menu'}
          style={iconStyle}
          size={designConstants.icons.size}
          color={color}
        />
      ));

    back &&
      (iconToRender = (
        <Icon
          type={'MaterialIcons'}
          name={'arrow-back'}
          style={iconStyle}
          size={designConstants.icons.size}
          color={color}
        />
      ));

    checkmark &&
      (iconToRender = (
        <Icon
          name={'checkmark'}
          type={'Ionicons'}
          style={iconStyle}
          size={designConstants.icons.size}
          color={color}
        />
      ));

    trash &&
      (iconToRender = (
        <Icon
          name={'trash'}
          type={'Ionicons'}
          style={iconStyle}
          size={designConstants.icons.size}
          color={color}
        />
      ));

    icon &&
      (iconToRender = (
        <Icon
          type={iconType}
          name={icon}
          style={iconStyle}
          size={designConstants.icons.size}
          color={color}
        />
      ));

    text &&
      (iconToRender = (
        <Text style={[{ ...iconStyle }, { color: color }]}>{text}</Text>
      ));

    loading && (iconToRender = <ActivityIndicator color={colors.white} />);

    return (
      <TouchableNative
        {...setTestID(this.props.tag)}
        style={[
          {
            width: 48,
            height: 48,
            borderRadius: 24,
            alignItems: 'center',
            justifyContent: 'center',
          },
          style,
        ]}
        onPress={this.props.onPress}
        {...otherProps}
      >
        {iconToRender}
      </TouchableNative>
    );
  }
}

export default withTheme(HeaderIcon);

HeaderIcon.propTypes = {
  menu: PropTypes.bool,
  back: PropTypes.bool,
  checkmark: PropTypes.bool,
  trash: PropTypes.bool,

  blue: PropTypes.bool,
  gray: PropTypes.bool,
  darkGray: PropTypes.bool,

  icon: PropTypes.string,
  iconType: PropTypes.string,

  onPress: PropTypes.func,
  // style: ViewPropTypes.style
};

HeaderIcon.defaultProps = {
  menu: false,
  back: false,
  checkmark: false,
  trash: false,

  blue: false,
  gray: false,
  darkGray: false,

  icon: null,
  iconType: 'MaterialCommunityIcons',

  onPress: () => null,
  style: {},
};
