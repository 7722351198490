import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import getConfig from '../../../shared/utilities/getConfig';
import { useWindowDimensions } from 'react-native';
import strings from '../../Intro/strings';
import useThemeContext from '../../../design/styles/useThemeContext';

export default function EulaModal(props) {
  const { height } = useWindowDimensions();
  const { colors } = useThemeContext();
  const dialogHeight = height - 200;
  const link =
    getConfig('MMP_LINK_EULA') + '-' + strings.getLanguage().toLowerCase();

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="alert-dialog-title">{strings.eula_title}</DialogTitle>
      <iframe src={link} style={{ height: dialogHeight }} frameBorder="0" />
      <DialogActions>
        <Button
          onClick={props.onAccept}
          style={{
            backgroundColor: colors.lightGreen,
            color: colors.defaultBackground,
          }}
          autoFocus
        >
          {strings.eula_close_button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
