import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { Platform } from 'react-native';
import buildReducer from './reducer';
import { composeWithDevTools } from 'remote-redux-devtools';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import filterRecordsMiddleware from '../modules/Filter/middleware';
import remindersMiddleware from '../modules/Settings/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import getStorage from './storage';
import { DATABASE_VERSION, migrations } from './migration';
import getStoredStateAndroid from './getStoredStateAndroid';

let store;

let persistor;

const Storage = getStorage();

let restore = async (key) => {
  return new Promise(async (resolve) => {
    //if we have encryption key in restoredState, we need to persist encrypted store
    if (key) {
      let encryptor = encryptTransform({
        secretKey: key,
      });

      let persistorConfig = {
        version: DATABASE_VERSION,
        timeout: 0,
        key: 'encrypted:',
        storage: Storage,
        migrate: createMigrate(migrations, { debug: false }),
        blacklist: ['routes', 'filter', 'records', 'auth', 'sync', 'fields'],
        transforms: [encryptor],
      };
      if (Platform.OS === 'android') {
        persistorConfig.getStoredState = getStoredStateAndroid;
      }
      let rootReducer = await buildReducer();
      let persistedReducer = persistReducer(persistorConfig, rootReducer);

      let middlewares = [thunk, filterRecordsMiddleware, remindersMiddleware];
      if (__DEV__) {
        // const Reactotron = require('../../ReactotronConfig').default;
        // middlewares.push(Reactotron.createEnhancer());
      }

      const composeEnhancers =
        (typeof window !== 'undefined' &&
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;
      let store = createStore(
        persistedReducer,
        composeEnhancers(
          applyMiddleware(...middlewares),
          __DEV__
            ? require('../ReactotronConfig').default.createEnhancer()
            : (f) => f,
        ),
      );

      persistor = persistStore(store, null, () => {
        resolve(store);
      });
    } else {
      //persist "profile" unencrypted
      let persistorConfig = {
        version: DATABASE_VERSION,
        timeout: 0,
        key: 'unencrypted:',
        blacklist: ['routes', 'filter', 'records', 'sync', 'auth', 'fields'],
        migrate: createMigrate(migrations, { debug: false }),
        storage: Storage,
      };
      if (Platform.OS === 'android') {
        persistorConfig.getStoredState = getStoredStateAndroid;
      }

      let rootReducer = await buildReducer();
      let persistedReducer = persistReducer(persistorConfig, rootReducer);

      const _compose =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 150 })) ||
        composeWithDevTools({ realtime: true, port: 8000, maxAge: 100 }) ||
        compose;
      // const routesMiddleware = createReactNavigationReduxMiddleware(state => state.routes, 'root');

      let middlewares = [thunk, filterRecordsMiddleware, remindersMiddleware];
      if (__DEV__) {
      }

      const composeEnhancers =
        (typeof window !== 'undefined' &&
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;
      const Reactotron = require('../ReactotronConfig').default;
      let store = createStore(
        persistedReducer,
        composeEnhancers(
          applyMiddleware(...middlewares),
          __DEV__
            ? require('../ReactotronConfig').default.createEnhancer()
            : (f) => f,
        ),
      );

      persistor = persistStore(store, null, () => {
        resolve(store);
      });
    }
  });
};

let storePromise = null;
let getStore = async () => {
  if (store) {
    return store;
  }

  if (storePromise) {
    return await storePromise;
  }
  storePromise = new Promise((resolve) => {
    //Restore only 'profile' first, then look on encryption_key and restore state on that basis
    AsyncStorage.getItem('key', (err, result) => {
      restore(result).then((restored) => {
        store = restored;

        resolve(restored);
      });
    });
  });
  return storePromise;
};

export let getPersistor = () => {
  return persistor;
};

export let setPersistor = (pers) => {
  persistor = pers;
};

export let flush = () => {
  return persistor ? persistor.flush() : Promise.resolve();
};

export default getStore;
