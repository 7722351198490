import {
  INSTITUTION_CONFIG_RETRIEVED,
  SETTINGS_DEBUG_CHANGE_CREATED_AT,
  SETTINGS_DEBUG_REMINDERS_TOGGLE,
  SETTINGS_DELETE_REMINDER,
  SETTINGS_DISABLE_IDLE_TIMER,
  SETTINGS_EDIT_REMINDER,
  SETTINGS_IOS_NOTIFICATIONS_SYSTEM_ALERT_WAS_SHOWN,
  SETTINGS_SET_DARK_MODE_INTRODUCTION_SHOW,
  SETTINGS_SET_LANGUAGE,
  SETTINGS_SWITCH_REMINDER_ENABLED,
  SETTINGS_SWITCH_REPORT_CRASH,
  SETTINGS_SWITCH_REPORT_USAGE,
} from './actiontypes';
import { SAVE_RECORD_SUCCESS } from '../Records/actiontypes';
import { APP_OPEN } from '../Intro/actiontypes';

const REMINDER_INIT = {
  id: 1,
  enabled: true,
  vibration: true,
  sound: true,
  created_at: Date.now(),
  frequency_info: {
    frequency: 'daily',
    time: '20:00',
    repeat_every: '1',
  },
};

const INITIAL = {
  current: {},
  language: 'default',
  reminders: [{ ...REMINDER_INIT }],
  reminder_vibration: true,
  reminder_sound: true,
  report_usage: true,
  report_crash: true,
  privacy_changed: false,
  debug_reminders: false,
  ios_notifications_system_alert_was_shown: false,
  show_dark_mode_introduction: false,
  institution_config: {},
};

function SettingsReducer(state = { ...INITIAL }, action) {
  switch (action.type) {
    case SAVE_RECORD_SUCCESS:
      return {
        ...state,
      };
    case SETTINGS_DISABLE_IDLE_TIMER:
      return {
        ...state,
        disable_idle_timer: action.payload,
      };
    case APP_OPEN: {
      return {
        ...state,
        privacy_changed: false,
      };
    }
    case SETTINGS_SWITCH_REMINDER_ENABLED: {
      const id = action.payload.id;
      const { reminders } = state;
      return Array.isArray(reminders) && reminders.length > 0
        ? {
            ...state,
            reminders: reminders.map((reminder) => {
              if (reminder.id === id) {
                return { ...reminder, enabled: action.payload.enabled };
              }
              return reminder;
            }),
          }
        : {
            ...state,
            reminders: [],
          };
    }

    case SETTINGS_SWITCH_REPORT_USAGE:
      return {
        ...state,
        report_usage: !state.report_usage,
        privacy_changed: true,
      };
    case SETTINGS_SWITCH_REPORT_CRASH:
      return {
        ...state,
        report_crash: !state.report_crash,
        privacy_changed: true,
      };

    case SETTINGS_SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case SETTINGS_EDIT_REMINDER: {
      // Also there was the position flag in reminders, but for now it is not needed
      const reminder = action.payload;
      const { reminders } = state;
      let reminders_to_save = [];
      if (Array.isArray(reminders) && reminders.length > 0) {
        const reminderToFind = reminders.find(
          (_reminder) => _reminder.id === reminder.id,
        );
        if (reminderToFind) {
          reminders_to_save = [
            ...reminders.map((_reminder) => {
              return _reminder.id === reminder.id ? reminder : _reminder;
            }),
          ];
        } else {
          reminders_to_save = [...reminders, { ...reminder }];
        }
      } else {
        reminders_to_save = [{ ...reminder }];
      }
      return {
        ...state,
        reminders: reminders_to_save,
      };
    }

    case SETTINGS_DEBUG_CHANGE_CREATED_AT: {
      return {
        ...state,
        reminders: state.reminders.map((reminder) => {
          if (reminder.id === action.payload.id) {
            return { ...reminder, created_at: action.payload.created_at };
          } else {
            return reminder;
          }
        }),
      };
    }

    case SETTINGS_DEBUG_REMINDERS_TOGGLE: {
      return {
        ...state,
        debug_reminders: !state.debug_reminders,
      };
    }

    case SETTINGS_DELETE_REMINDER: {
      return {
        ...state,
        reminders: Array.isArray(state.reminders)
          ? [
              ...state.reminders.filter(
                (_reminder) => _reminder.id !== action.payload,
              ),
            ]
          : [],
      };
    }

    case SETTINGS_IOS_NOTIFICATIONS_SYSTEM_ALERT_WAS_SHOWN: {
      return {
        ...state,
        ios_notifications_system_alert_was_shown: true,
      };
    }
    case SETTINGS_SET_DARK_MODE_INTRODUCTION_SHOW: {
      return {
        ...state,
        show_dark_mode_introduction: action.payload,
      };
    }
    case INSTITUTION_CONFIG_RETRIEVED: {
      return {
        ...state,
        institution_config: action.payload,
      };
    }
    default:
      return state;
  }
}

export { SettingsReducer };
