// @flow
import React from 'react';
import { Image, View } from 'react-native';
import { Spacer, Text } from '../../../design/components';
import Button from '../../../design/components/Button';
import { designConstants } from '../../../design/styles';
import { Link } from '@react-navigation/native';
import strings from '../strings';
import useThemeContext from '../../../design/styles/useThemeContext';

type Props = {
  onContinue: Function,
};
export default function EulaUpdatedForm(props: Props) {
  const { colors } = useThemeContext();
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        alignSelf: 'stretch',
        backgroundColor: colors.defaultBackground,
      }}
    >
      <Text
        headerSmall
        style={{
          maxWidth: 270,
          textAlign: 'center',
        }}
      >
        {strings.eula_updated_form_title_text}
      </Text>
      <Text
        darkGray
        style={{
          fontSize: 14,
          lineHeight: 24,
          marginTop: 30,
          marginBottom: 30,
          alignSelf: 'stretch',
        }}
      >
        {strings.eula_updated_message_1_text}
      </Text>
      <Text
        darkGray
        style={{
          fontSize: 14,
          lineHeight: 24,
          alignSelf: 'stretch',
        }}
      >
        {strings.eula_updated_message_2_text}
      </Text>
      <Text
        darkGray
        style={{
          fontSize: 14,
          lineHeight: 24,
          alignSelf: 'stretch',
        }}
      >
        {strings.formatString(
          strings.eula_updated_message_3_text,
          <Link
            onPress={(event) => {
              if (designConstants.isWeb) {
                event.preventDefault();
                props.onPrivacyPolicyShow();
              }
            }}
            style={{ color: colors.buttonBlue }}
            to={'/privacy-policy'}
          >
            {strings.eula_updated_message_3_privacy_policy}
          </Link>,
        )}
      </Text>
      <Text
        darkGray
        style={{
          fontSize: 14,
          lineHeight: 24,
          alignSelf: 'stretch',
        }}
      >
        {strings.eula_updated_message_4_text}
      </Text>
      <Button
        primary
        onPress={props.onContinue}
        text={strings.eula_updated_continue_button}
        style={{ marginVertical: 20, alignSelf: 'stretch' }}
      />

      <Text
        darkGray
        style={{
          marginBottom: 20,
          fontSize: 14,
          textAlign: 'center',
        }}
      >
        {strings.formatString(
          strings.eula_updated_link_message,
          <Link
            onPress={(event) => {
              if (designConstants.isWeb) {
                event.preventDefault();
                props.onEulaShow();
              }
            }}
            style={{ color: colors.buttonBlue }}
            to={'/eula'}
          >
            {strings.eula_updated_link_part}
          </Link>,
        )}
      </Text>
      <Spacer />
      <Image source={require('../assets/data_security_badge.png')} />
    </View>
  );
}
