import {
  NAVIGATOR_SET_CURRENT_ROUTE,
  NAVIGATOR_TOGGLE_DRAWER,
  NAVIGATOR_WEB_INITIALIZED,
} from './actiontypes';

export function toggleDrawer() {
  return {
    type: NAVIGATOR_TOGGLE_DRAWER,
  };
}

export function webInitialized() {
  return {
    type: NAVIGATOR_WEB_INITIALIZED,
  };
}

export function setCurrentRoute(route) {
  return (dispatch) => {
    dispatch({
      type: NAVIGATOR_SET_CURRENT_ROUTE,
      payload: route,
    });
  };
}
