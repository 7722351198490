import { Platform } from 'react-native';
import { answersEnabled } from './gatracker';
import getAnalytics from './analytics';
import { designConstants } from '../../design/styles';
import getConfig from './getConfig';

const analytics = getAnalytics();

export function trackUserProperties(loggedIn, is_institution_member) {
  if (!answersEnabled) {
    return;
  }

  const login_status =
    Platform.OS === 'web' ? 'web' : loggedIn ? 'loggedin' : 'loggedout';
  analytics().setUserProperties({
    login_status,
    is_institution_member: is_institution_member ? '1' : '0',
  });
}

export function trackNotificationSettings(
  notifications_enabled,
  reminders_count,
) {
  if (!answersEnabled) {
    return;
  }

  analytics().setUserProperties({
    notifications_enabled: String(notifications_enabled),
    reminders_count: String(reminders_count),
  });
}

export function trackColorScheme(color_scheme) {
  if (!answersEnabled) {
    return;
  }

  analytics().setUserProperties({
    color_scheme: color_scheme || 'auto',
  });
}

export function trackSyncPress() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('sync_press');
}

export function trackRecentRecordsPress(pressType) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('recent_records_press', { event_type: pressType });
}

export function trackPainConditionsPress(pressType) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('pain_conditions_press', { event_type: pressType });
}

export function trackMedicationsPress(pressType) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('medications_press', { event_type: pressType });
}

export function trackTutorialBegin() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('tutorial_begin');
}

export function trackTutorialComplete(reason) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('tutorial_complete', { reason });
}
export function trackTimeChange(record) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('time_change', { record });
}

export function trackRateAppMessageShown() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('rate_app_message_received');
}

export function trackRateAppMessagePressed(reason) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('rate_app_message_pressed', { reason });
}

export function trackSignUpMessageShown() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('sign_up_message_received');
}

export function trackSignUpMessagePressed(reason) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('sign_up_message_pressed', { reason });
}

export function trackQuestionnaireStart(institution_id, questionnaire_id) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('questionnaire_start', {
    institution_id: String(institution_id),
    questionnaire_id: String(questionnaire_id),
  });
}

export function trackQuestionnaireSubmit(institution_id, questionnaire_id) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('questionnaire_submit', {
    institution_id: String(institution_id),
    questionnaire_id: String(questionnaire_id),
  });
}

export function trackQuestionnairesMessageShown() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('questionnaires_message_received');
}

export function trackQuestionnairesMessagePressed(reason) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('questionnaires_message_pressed', { reason });
}

export function trackNotificationReceived(title) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('notification_received', { title });
}

export function trackFilterOpen() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('filter_open');
}

export function trackDateRangeOpen() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('daterange_open');
}
export function trackNotificationPressed(title) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('notification_pressed', { title });
}

export function trackAppLaunch(login_status) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('app_launch', { login_status: login_status });
}
export function trackLogin(source, is_institution_member) {
  if (!answersEnabled) {
    return;
  }
  if (Platform.OS === 'web') {
    analytics().logEvent('login', { method: source });
  } else {
    analytics().logLogin({ method: source });
  }
  analytics().setUserProperties({
    login_status: Platform.OS === 'web' ? 'web' : 'loggedin',
    is_institution_member: is_institution_member ? '1' : '0',
  });
  // Answers.logLogin(source, true);
}

export function trackLogout() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('logout');
  analytics().setUserProperties({
    login_status: Platform.OS === 'web' ? 'web' : 'loggedout',
    is_institution_member: '0',
  });
}

export function trackSignUp(source) {
  if (!answersEnabled) {
    return;
  }
  // Answers.logSignUp(source, true);
  if (Platform.OS === 'web') {
    analytics().logEvent('sign_up', { method: source });
  } else {
    analytics().logSignUp({ method: source });
  }
}

export function trackPurchase(price, currency, item, title) {
  if (!answersEnabled) {
    return;
  }
  analytics().logPurchase({
    value: price,
    currency: currency,
    items: [
      {
        item_name: title,
        item_category: item.startsWith(
          getConfig('MMP_PRODUCT_ID_CREDITS_PREFIX'),
        )
          ? 'credits'
          : 'pro',
        price: price,
        item_id: item,
      },
    ],
  }); //{value: price, currency, coupon: item});
}

export function trackAddPainRecord() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('add_pain_record');
  // Answers.logCustom('Add PainRecord');
}
export function trackDuplicatePainRecord() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('duplicate_pain_record');
}

export function trackDuplicateDailyReflection() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('duplicate_daily_reflection');
}

export function trackDeletePainRecord() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('delete_pain_record');
}

export function trackDeleteDailyReflection() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('delete_daily_reflection');
}

export function trackStartRecordCreation(recordType) {
  if (!answersEnabled || !recordType) {
    return;
  }
  analytics().logEvent('record_create_start', { record_type: recordType });
}
export function trackStartRecordEditing(recordType) {
  if (!answersEnabled || !recordType) {
    return;
  }
  analytics().logEvent('record_edit_started', { record_type: recordType });
}
export function trackCompletesRecordCreation(recordType) {
  if (!answersEnabled || !recordType) {
    return;
  }
  analytics().logEvent('record_create_completed', { record_type: recordType });
}
export function trackCompletesRecordEditing(recordType) {
  if (!answersEnabled || !recordType) {
    return;
  }
  analytics().logEvent('record_edit_completed', { record_type: recordType });
}

export function trackAddDailyReflection() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('add_daily_reflection');
  // Answers.logCustom('Add DailyReflection');
}
export function trackGenerateReport() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('generate_report');
  // Answers.logCustom('Generate Report');
}

export function trackAddMedication() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('add_medication');
  // Answers.logCustom('Medication');
}

export function trackAddPainCondition() {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('add_pain_condition');
  // Answers.logCustom('PainCondition');
}

export function trackAddCustomValue(fieldType) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('add_custom_value', { type: fieldType });
}

export function trackBottomNavigatorClicks(tab) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('navigation_bar_click', { tab: tab });
}

export function trackLanguageChange(language) {
  if (!answersEnabled) {
    return;
  }

  const languages = {
    default: 'System Default',
    en: 'English',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    ko: 'Korean',
    ru: 'Russian',
    'zh-rCN': 'Simplified Chinese',
  };
  analytics().logEvent('language_change', { language: languages[language] });
}

export function trackPainGuide(action, id) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('pain_guide', { action, id: String(id) });
}

export function trackPainGuideProgram(action, id, module_id) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('pain_guide_program', {
    action,
    id: String(id),
    module_id: String(module_id),
  });
}

export function trackPainGuideProgramStart(id) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('pain_guide_program_start', {
    id: String(id),
  });
}
export function trackPainGuideProgramEnd(id) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('pain_guide_program_end', {
    id: String(id),
  });
}

export function trackTutorialScreen(action, page_index) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('tutorial_screen', {
    action,
    page_index: String(page_index),
  });
}

export function trackTutorialConditionAdded() {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('tutorial_condition_added');
}

export function trackTutorialMedicationAdded() {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('tutorial_medication_added');
}

export function trackIntroComplete() {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('intro_complete');
}

export function trackIntroScreen(action) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('intro_screen', {
    action,
  });
}

export function trackNotificationPermissionProvided() {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('notification_permission_provided');
}

export function trackNotificationPermissionScreen(action) {
  if (!answersEnabled) {
    return;
  }

  analytics().logEvent('notification_permission_screen', { action });
}

export function trackScreenOpen(screenName) {
  if (!answersEnabled) {
    return;
  }

  if (designConstants.isWeb) {
    analytics().logEvent('screen_view', {
      firebase_screen_class: screenName,
      firebase_screen: screenName,
    });
  } else {
    analytics().logScreenView({
      screen_class: screenName,
      screen_name: screenName,
    });
  }
}

export function trackPainGuideCategory(action, category_id) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('pain_guide', {
    action,
    category_id: String(category_id),
  });
}

export function trackRelogin(action) {
  if (!answersEnabled) {
    return;
  }
  analytics().logEvent('relogin', { action });
}
