import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import MMPIcon from '../../shared/components/MMPIcon';
import { Text, TouchableNative } from './index';
import { designConstants } from '../styles/constants';
import Icon from './Icon';
import strings from '../strings';
import Collapsible from 'react-native-collapsible';
import { withTheme } from '../styles/useThemeContext';

class ListSectionItem extends React.Component {
  state = {
    expanded: true,
  };

  // componentWillReceiveProps(nextProps) {
  //     console.log('Is changed', nextProps.label);
  //     Object.keys(nextProps).forEach(key => {
  //         console.log('Key changed', key, nextProps[key] !== this.props[key]);
  //     });
  // }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      this.state.expanded !== nextState.expanded ||
      (nextProps.hasOwnProperty('compareObj')
        ? nextProps.compareObj !== this.props.compareObj
        : this.props.children !== nextProps.children ||
          this.props.disabled !== nextProps.disabled ||
          this.props.label !== nextProps.label ||
          this.props.theme !== nextProps.theme ||
          this.props.theme !== nextProps.theme ||
          this.props.secondaryLabel !== nextProps.secondaryLabel ||
          this.props.mmpicon !== nextProps.mmpicon ||
          this.props.checked !== nextProps.checked);
    //        console.log('should update', nextProps.compareObj === this.props.compareObj, shouldUpdate, nextProps.label);
    return shouldUpdate;
  }

  onPress = () => {
    //        trackButtonPress('List section check ' + this.props.label);
    if (this.props.children) {
      if (!this.props.checked) {
        this.props.onPress && this.props.onPress();
      } else {
        this.setState({ expanded: !this.state.expanded });
      }
    } else {
      this.props.onPress && this.props.onPress();
    }
  };

  onCheckmarkPress = () => {
    if (!this.props.checked && !this.props.expanded) {
      this.setState({ expanded: true });
    }
    this.props.onPress && this.props.onPress();
  };

  render() {
    let CheckableWrapper = this.props.children ? TouchableNative : View;
    let collapsed = !(this.props.checked && this.state.expanded);
    const { colors } = this.props.theme;

    //        console.log('render section item', this.props.label, collapsed);
    //        let TouchableView = this.props.disabled ? TouchableWithoutFeedback : TouchableNative;
    return (
      <View
        style={[
          styles.defaultBorderWrapper,
          {
            backgroundColor: colors.defaultBackground,
            borderColor: colors.cardBorder,
          },
          this.props.style,
          this.props.checked && {
            borderColor: colors.buttonBlue,
          },
          this.props.disabled && {
            backgroundColor: colors.disabledBackground,
            borderColor: colors.disabledBackground,
          },
        ]}
        onLayout={this.props.onLayout ? this.props.onLayout : undefined}
      >
        <TouchableNative
          onPress={
            this.props.disabled ? this.props.onDisabledPress : this.onPress
          }
          style={styles.defaultItemWrapper}
          pointerEvents={'auto'}
        >
          {this.props.noIcon ? (
            <View style={{ height: 28 }} />
          ) : (
            <MMPIcon
              name={this.props.mmpicon}
              label={this.props.label}
              size={26}
              color={
                this.props.disabled ? colors.textSecondary : colors.buttonBlue
              }
              style={{
                marginRight: 8,
              }}
            />
          )}
          <View
            style={[
              styles.childWrapper,
              this.props.children && { marginRight: 56 },
            ]}
          >
            <Text medium>{this.props.label}</Text>
            {this.props.secondaryLabel && (
              <Text small gray>
                {this.props.secondaryLabel}
              </Text>
            )}
          </View>
          {this.props.checkbox && (
            <CheckableWrapper
              onPress={this.onCheckmarkPress}
              style={{
                width: 28,
                height: 28,
                borderRadius: 14,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                name={
                  this.props.checked
                    ? 'checkbox-marked-circle'
                    : 'checkbox-blank-circle-outline'
                }
                type={'MaterialCommunityIcons'}
                style={{
                  color: this.props.checked
                    ? colors.buttonBlue
                    : colors.textSecondary,
                }}
                size={designConstants.icons.size}
              />
            </CheckableWrapper>
          )}
          {this.props.menu && !this.props.disabled && (
            <TouchableNative
              onPress={this.onMenuPress}
              style={{
                backgroundColor: colors.defaultBackground,
                width: 28,
                height: 28,
                borderRadius: 14,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                name={'dots-vertical'}
                type={'MaterialCommunityIcons'}
                style={{ color: colors.buttonBlue }}
                size={designConstants.icons.size}
              />
            </TouchableNative>
          )}
        </TouchableNative>
        {false && this.props.children && this.props.checked && (
          <TouchableNative
            style={{
              position: 'absolute',
              right: 13,
              height: 48,
              alignItems: 'center',
              top: 5,
              justifyContent: 'center',
            }}
            onPress={this.onCheckmarkPress}
          >
            <Text medium style={{ color: colors.skyBlue }}>
              {strings.list_section_disable_button}
            </Text>
          </TouchableNative>
        )}
        {this.props.children && (
          <Collapsible
            collapsed={collapsed}
            style={{ padding: 8, paddingLeft: 7, paddingRight: 8 }}
          >
            {this.props.children}
          </Collapsible>
        )}
      </View>
    );
  }
}

ListSectionItem.propTypes = {
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  mmpicon: PropTypes.string,
  checked: PropTypes.bool,
  noIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  menu: PropTypes.bool,
  onDisabledPress: PropTypes.func,
};
const styles = StyleSheet.create({
  defaultItemWrapper: {
    padding: 16,
    paddingLeft: 12,
    paddingRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  defaultBorderWrapper: {
    minHeight: 60,
    alignSelf: 'stretch',
    marginBottom: 5,
    borderWidth: 1,
    borderRadius: 4,
  },
  circleTouchable: {
    borderRadius: 100,
    backgroundColor: 'transparent',
  },
  childWrapper: {
    flex: 1,
  },
});

export default withTheme(ListSectionItem);
