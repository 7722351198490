import getStore, { flush } from '../../redux/store';
//
import * as MMPApi from 'apiclient';
import { apiError, checkToken } from '../Api/actions';
import AllRecords from './records';
import * as Sync from './SyncService';
import * as types from './actiontypes';
import { resetOfflineMeds } from '../Meds/actions';
import { SET_LOGOUT_ATTEMPT_FLAG } from '../Auth/actiontypes';
import { logout } from '../Auth/actions';
import Toast from '../../design/components/Toast';
import strings from './strings';
import { bugsnag } from '../Settings/components/CrashUsageController';
import getConfig from '../../shared/utilities/getConfig';
import { updateMyProfile } from '../Profile/actions';
import { updateCustomLists } from '../Fields/actions';
import { retrieveQuestionnaires } from '../Questionnaires/actions';
import { getLang } from '../../shared/utilities/getLang';

export function closeSync() {
  return {
    type: types.SYNC_SYNC_IN_PROCESS_FLAG_OFF,
  };
}

export function webFirstSync() {
  return (dispatch, getState) => {
    return checkToken().then(async () => {
      return Promise.allSettled([
        dispatch(updateMyProfile()),
        dispatch(updateCustomLists()),
      ]).then(() => {
        return Promise.resolve();
      });
    });
  };
}

let SYNCFUNC = null;
//Continuous parameter set when we launch it second time
export function sync(continuous) {
  return (dispatch, getState) => {
    // Avoid second launch of sync

    if (!continuous && getState().sync.sync_in_progress) {
      if (SYNCFUNC) {
        return SYNCFUNC.then(() => {
          return Promise.resolve();
        });
      }
    }
    const unsetLogoutAttemptFlag = (withLogout = true) => {
      const state = getState();
      if (state && state.auth && state.auth.logout_attempt_flag === true) {
        dispatch({
          type: SET_LOGOUT_ATTEMPT_FLAG,
          payload: false,
        });
        withLogout && dispatch(logout(true));
        !withLogout &&
          Toast.show({
            supportedOrientations: ['portrait', 'landscape'],
            text: strings.sync_actions_cant_logout_after_sync_failure_toast_text,
            position: 'bottom',
            duration: 3000,
            buttonText:
              strings.sync_actions_cant_logout_after_sync_failure_toast_ok_button,
          });
        return true;
      }
      return false;
    };

    // Avoid sync for un-logged-in user
    if (!(getState().auth && getState().auth.user)) {
      return Promise.resolve();
    }

    let wasCaught = false;
    dispatch({ type: types.SYNC_SYNC_IN_PROCESS_FLAG_ON });
    const fail_count = getState().sync.fail_count || 0;
    const oneByOne = fail_count > getConfig('MMP_SYNC_FAIL_COUNT');
    if (oneByOne) {
      bugsnag && bugsnag.leaveBreadcrumb('Fail count: ' + fail_count);
    }
    SYNCFUNC = checkToken()
      .then(() => {
        dispatch({
          type: types.SYNC,
        });

        return getStore().then((store) => {
          let lastUpdated = store.getState().sync.last_updated || 0;
          let api = new MMPApi.RecordCallsApi();
          //Before calling get-all-records, set client timeout to 5 minutes
          let defaultClient = MMPApi.ApiClient.instance;
          defaultClient.timeout = 60000 * 5;

          let params = MMPApi.GetAllRecordsParams.constructFromObject({
            last_updated: lastUpdated,
          });
          let secondSyncRequired = false;
          let custom_lists = store.getState().fields;
          let is_institution_member =
            store.getState().auth?.user?.is_institution_member;
          return api
            .syncGetAllRecordsPost(getLang(), params)
            .then((result) => {
              //After get-all-records response, reset timeout to 1 minute
              let defaultClient = MMPApi.ApiClient.instance;
              defaultClient.timeout = 60000;

              // Parse get-all-records result
              // return Promise.reject({response: {text: '{"result_code": "143", "result_msg": "Simulated error"}'}});
              let server = new AllRecords(result);
              return server;
            })
            .then((server) => {
              dispatch({
                type: types.SYNC_OFFLINE_MEDICATIONS,
              });
              return Sync.syncOfflineMedicationsAndPainConditions(
                server,
                store.getState(),
                dispatch,
                store.getState,
              );
            })
            .then((result) => {
              if (result && result.secondSync) {
                // After intro medications sync we need to re-sync immediately
                return api
                  .syncGetAllRecordsPost(getLang(), params)
                  .then((server) => new AllRecords(server));
              }
              return result.server;
            })
            .then((server) => {
              return Sync.syncUserObject(server, store.getState(), dispatch);
            })
            .then((server) => {
              return Sync.syncMyProfile(server, store.getState(), dispatch);
            })
            .then((result) => {
              // Sync my profile
              if (result.profile) {
                dispatch({
                  type: types.GET_MY_PROFILE_SUCCESS,
                  payload: result.profile,
                });
              }
              return result.server;
            })
            .then((server) => {
              // Sync custom lists now
              dispatch({
                type: types.SYNC_CUSTOM_LISTS,
              });
              return Sync.syncCustomLists(server, store.getState());
            })
            .then((result) => {
              // Now dispatch
              if (result && result.secondSync) {
                secondSyncRequired = true;
              }
              if (result) {
                if (result.custom_lists) {
                  custom_lists = result.custom_lists;
                  dispatch({
                    type: types.SYNC_CUSTOM_LISTS_SUCCESS,
                    custom_lists: custom_lists,
                  });
                } else {
                  dispatch({
                    type: types.SYNC_CUSTOM_LISTS_CLEAR_FLAGS,
                  });
                }
              }
              return result;
            })
            .then((result) => {
              // Sync records now!
              return Sync.syncRecords(
                result.server,
                store.getState(),
                dispatch,
                oneByOne,
              );
            })
            .then((result) => {
              // Dispatch records
              if (result && result.secondSync) {
                secondSyncRequired = true;
                dispatch({
                  type: types.SYNC_RECORDS_CLEAR_FLAGS,
                  payload: {
                    changed: result.changed,
                    last_updated: lastUpdated,
                    flagsToClear: result.flagsToClear,
                  },
                });
              } else {
                dispatch({
                  type: types.SYNC_RECORDS_SUCCESS,
                  shouldFilter: result.recordsUpdated, //Only filter of there are any server records received
                  payload: {
                    records: result.records,
                    last_updated: lastUpdated,
                  },
                });
              }
              result.server.someRecordSyncFailed = result.someRecordSyncFailed;
              return result.server;
            })
            .then((server) => {
              let pending = getState().records.list.some(
                (rec) => rec.flag_updated,
              );
              if (pending && !server.someRecordSyncFailed) {
                secondSyncRequired = true;
              }
              return Promise.resolve(server);
            })
            .then((server) => {
              if (is_institution_member) {
                dispatch(retrieveQuestionnaires());
              }
              return Promise.resolve(server);
            })
            .then((server) => {
              if (!secondSyncRequired) {
                flush().then(() => {
                  setTimeout(() => {
                    dispatch({
                      type: types.SYNC_SUCCESS,
                      payload: server.time,
                    });
                  }, 500);
                });
              }
              return Promise.resolve();
            })
            .then(() => {
              // If second sync required - then sync again,if no - send sync successful
              if (secondSyncRequired) {
                return dispatch(sync(true));
              }

              setTimeout(() => {
                dispatch(resetOfflineMeds());
                dispatch({
                  type: types.SYNC_SUCCESS_CLOSE,
                });
                SYNCFUNC = null;
                dispatch({ type: types.SYNC_SYNC_IN_PROCESS_FLAG_OFF });
                unsetLogoutAttemptFlag();
              }, 2000);
              return Promise.resolve();
            })
            .catch((e) => {
              unsetLogoutAttemptFlag(false);
              wasCaught = true;
              SYNCFUNC = null;
              dispatch({ type: types.SYNC_SYNC_IN_PROCESS_FLAG_OFF });
              return dispatch(apiError(e, types.SYNC, sync));
            });
        });
      })
      .catch((error) => {
        if (error) {
          unsetLogoutAttemptFlag(false);
        }

        if (wasCaught) {
          return Promise.reject(error);
        }
        SYNCFUNC = null;
        dispatch({ type: types.SYNC_SYNC_IN_PROCESS_FLAG_OFF });
        return dispatch(apiError(error, types.CHECK_TOKEN));
      });

    return SYNCFUNC;
  };
}
