import * as types from './actiontypes';
import {
  createEmpty,
  createEmptyReflection,
  isAllDayChecked,
} from './constants';
import Moment from 'moment';
import { sync } from '../Sync/actions';
import { formatMoment } from '../../shared/utilities/timeutils';
import getStore from '../../redux/store';
import { trackGoal } from '../../shared/utilities/gatracker';
import { MILLISECONDS_IN_DAY } from '../Filter/filter';
import { deepCopy } from '../../shared/utilities/deepcopy';
import {
  trackAddDailyReflection,
  trackAddPainRecord,
} from '../../shared/utilities/answers';
import { refreshAllReminders } from '../Settings/notifications';
import { isNothingNamedField } from '../Fields/constants';

export const MAX_OVERLAYS_NUMBER = 6;

export function emptyPainRecord() {
  return (dispatch) => {
    let empty = createEmpty();
    dispatch({
      type: types.PREPARE_PAIN_RECORD,
      payload: {
        record: empty,
      },
    });
    return Promise.resolve();
  };
}

export function emptyDailyReflection(forceEmpty) {
  return (dispatch) => {
    let reflection = createEmptyReflection();
    return dispatch(setRecordToEdit(reflection, forceEmpty));
  };
}

export function clearDailyReflection() {
  return emptyDailyReflection(true);
}

export function deleteRecord(record) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_RECORD,
      shouldFilter: true,
      payload: record,
    });
    if (record.type === 'DailyReflection') {
      refreshAllReminders();
    }
    trackGoal(
      record.type === 'PainRecord'
        ? 'Pain Record Delete'
        : 'Daily Reflection Record Delete',
    );
    dispatch(sync());
    return Promise.resolve();
  };
}

export function setRecordToDuplicate(record) {
  record = deepCopy(record);
  record.id = -1;
  delete record.createDate;
  delete record.idOnServer;
  record.isDuplicate = true;
  record.recordTime = Moment().valueOf();
  if (record.type === 'DailyReflection') {
    let today = Moment(record.recordTime);
    let end = today.clone().set({ hour: 12, minute: 0, second: 0 });
    if (today.isBefore(end)) {
      today = today.add(-12, 'hour');
      record.recordTime = today.valueOf();
    }
  }
  record.timeOffset =
    new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1;

  return setRecordToEdit(record, true);
}

export function setRecordToEdit(record, skipReflectionCheck) {
  return (dispatch) => {
    return getStore().then((store) => {
      let toEdit = { ...record };
      toEdit.fields = { ...toEdit.fields };
      if (record.type === 'DailyReflection' && !skipReflectionCheck) {
        let today = formatMoment(record.recordTime, record.timeOffset);
        let end = Moment('12:00:00', 'hh:mm:ss');
        if (today.isBefore(end)) {
          today = today.add(-12, 'hour');
          toEdit.recordTime = today.valueOf();
        }
        let existing = store.getState().records.list.find((record) => {
          let mom = formatMoment(record.recordTime, record.timeOffset);
          return record.type === 'DailyReflection' && today.isSame(mom, 'day');
        });
        if (existing) {
          return dispatch(setRecordToEdit(existing, true));
        }
      }

      toEdit.type === 'DailyReflection'
        ? (toEdit.score = toEdit.score)
        : (toEdit.severity = toEdit.severity);
      let offset =
        new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1;
      //            console.log('START EDITING RECORD', toEdit.recordTime);
      toEdit.recordTime = toEdit.recordTime + toEdit.timeOffset - offset;
      //            console.log('START EDITING RECORD 2', toEdit.recordTime);
      toEdit.allDayChecked = isAllDayChecked(toEdit);
      let payload =
        record.type === 'DailyReflection'
          ? { reflection: { ...toEdit } }
          : { record: { ...toEdit } };

      dispatch({
        type: types.PREPARE_PAIN_RECORD,
        payload: payload,
      });
      return Promise.resolve();
    });
  };
}

export function setTimeDraft(value) {
  return {
    type: types.SET_RECORD_TIME,
    payload: {
      time: value,
      time_draft: true,
    },
  };
}

export function setCurrentTime(value, initial) {
  return {
    type: types.SET_RECORD_TIME,
    payload: {
      time: value,
      time_set: !initial,
    },
  };
}
export function setCurrentSeverity(value) {
  return {
    type: types.SET_SEVERITY,
    payload: value,
  };
}

export function checkMedication(medication, medtype, checked) {
  return {
    type: types.CHECK_MEDICATION,
    payload: {
      medication,
      medtype,
      checked,
    },
  };
}

export function changeMedicationType(medication, type) {
  return {
    type: types.CHANGE_CURRENT_MEDICATION_TYPE,
    payload: {
      medication: medication,
      nid: medication.nid,
      type: type,
    },
  };
}

export function changeDosage(medication, dosage, dosageOther) {
  return {
    type: types.CHANGE_CURRENT_DOSAGE,
    payload: {
      medication: medication,
      nid: medication.nid,
      dosage: dosage,
      other: dosageOther,
    },
  };
}

export function changeDosageUnits(old_nid, nid) {
  return {
    type: types.CHANGE_CURRENT_DOSAGE_UNITS,
    payload: {
      old_nid,
      nid,
    },
  };
}

export function checkNothing(fieldType, fieldId, checked) {
  return {
    type: types.CHECK_NOTHING,
    payload: {
      fieldType: fieldType,
      fieldId,
      checked,
    },
  };
}

export function checkField(fieldType, fieldId, checked) {
  return (dispatch, getState) => {
    const nothingFieldId = getState().fields[fieldType].findIndex((field) =>
      isNothingNamedField(field?.name),
    );
    return dispatch({
      type: types.CHECK_FIELD,
      payload: {
        fieldType: fieldType,
        fieldId: fieldId,
        checked,
        nothingFieldId,
      },
    });
  };
}

// export function checkFactor(factorType, fieldId, checked) {
//     return {
//         type: types.CHECK_FACTOR,
//         payload: {
//             factorType,
//             fieldId,
//             checked
//         }
//     };
// }

export function uncheckFactor(fieldType, fieldId) {
  return {
    type: types.UNCHECK_FACTOR_FIELD,
    payload: {
      fieldType: fieldType,
      fieldId: fieldId,
    },
  };
}

export function setTimeOffset(offset) {
  return {
    type: types.SET_TIME_OFFSET,
    payload: offset,
  };
}

export function setCurrentNote(note) {
  return {
    type: types.SET_NOTE,
    payload: note,
  };
}
export function setReflectionNote(note) {
  return {
    type: types.SET_REFLECTION_NOTE,
    payload: note,
  };
}

export function setReflectionScore(note) {
  return {
    type: types.SET_REFLECTION_SCORE,
    payload: note,
  };
}

export function setReflectionTime(time, initial) {
  return {
    type: types.SET_REFLECTION_TIME,
    payload: {
      time: time,
      time_set: !initial,
    },
  };
}

export function setPainType(painType) {
  return {
    type: types.SET_PAIN_TYPE,
    payload: painType,
  };
}

export function setDuration(duration) {
  return {
    type: types.SET_DURATION,
    payload: duration,
  };
}

export function setAllDayChecked(checked) {
  return {
    type: types.SET_ALLDAY_CHECKED,
    payload: checked,
  };
}

export function setDurationType(type) {
  return {
    type: types.SET_DURATION_TYPE,
    payload: type,
  };
}

export function setDurationUnits(units) {
  return {
    type: types.SET_DURATION_UNITS,
    payload: units,
  };
}

export function saveRecord() {
  return (dispatch, getState) => {
    let record = { ...(getState().records.current.record || {}) };
    let last_updated = getState().sync.last_updated;

    record.updateDate =
      record.updateDate && record.updateDate >= last_updated
        ? record.updateDate + 1
        : last_updated;
    record.localUpdated = Moment().valueOf();
    delete record.isDuplicate;
    record.severity = Math.round(parseFloat(record.severity || '0'));
    if (!record.recordTime) {
      let time = Moment().valueOf();
      record.recordTime = time;
      dispatch(setCurrentTime(time));
    }
    if (record.createDate) {
      //if edit, update recordTime
      if (record.durationType !== 'all_day') {
        record.recordTime =
          record.recordTime +
          new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1 -
          record.timeOffset;
      }
    } else {
      //Create pain record
      trackAddPainRecord();
      record.timeOffset =
        new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1;
      dispatch(
        setTimeOffset(
          new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1,
        ),
      );
    }
    //			record.recordTime = record.recordTime + record.timeOffset;
    //			dispatch(setCurrentTime(record.recordTime + record.timeOffset))

    if (!record.durationType) {
      record.lengthOfPainValue = 0;
      record.lengthOfPainUnit = null;
    } else if (!record.lengthOfPainUnit) {
      record.lengthOfPainUnit = 'Minutes';
    }
    if (record.durationType === 'all_day') {
      record.allDayChecked = true;
      console.log('RECORD TIME NOW?', record.recordTime, record.timeOffset);
      record.recordTime = Moment(record.recordTime).startOf('day').valueOf();
      let offset =
        new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1;
      record.recordTime = record.recordTime - record.timeOffset + offset;
      console.log('RECORD TIME AFTER?', record.recordTime, record.timeOffset);
      record.lengthOfPainValue = MILLISECONDS_IN_DAY / 1000; //Moment(record.recordTime).clone().add(1, 'day').diff(record.recordTime, 'seconds');
    } else {
      record.allDayChecked = false;
    }
    dispatch(setCurrentSeverity(record.severity));
    dispatch({
      type: types.SAVE_RECORD_SUCCESS,
      shouldFilter: true,
      payload: record,
    });
    return Promise.resolve();
  };
}

export function saveDailyReflection() {
  return (dispatch, getState) => {
    let record = { ...(getState().records.current.reflection || {}) };
    record = { ...record };
    record.localUpdated = Moment().valueOf();
    if (!record.recordTime) {
      record.recordTime = Moment().valueOf();
    } else if (record.createDate) {
      //if edit, update recordTime
      record.recordTime =
        record.recordTime +
        new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1 -
        record.timeOffset;
    } else {
      trackAddDailyReflection();
      record.timeOffset =
        new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1;
      dispatch(
        setTimeOffset(
          new Date(record.recordTime).getTimezoneOffset() * 1000 * 60 * -1,
        ),
      );
    }
    record.score = Math.round(record.score);
    dispatch({
      type: types.SAVE_DAILY_REFLECTION_SUCCESS,
      shouldFilter: true,
      payload: record,
    });

    refreshAllReminders();
    return Promise.resolve();
  };
}

export function deleteOfflineMedicationFromRecords(nid) {
  return {
    type: types.DELETE_OFFLINE_MEDICATION_FROM_RECORDS,
    payload: nid,
  };
}

export function removeJustDeletedMedicationFromCurrentRecord(nid) {
  return {
    type: types.REMOVE_JUST_DELETED_MEDICATION_FROM_CURRENT_RECORD,
    payload: nid,
  };
}

export function checkOverlay(field, fieldId, name) {
  return {
    type: types.CHECK_OVERLAY,
    payload: {
      fieldId,
      field,
      name,
    },
  };
}

export function resetOverlays() {
  return {
    type: types.RESET_OVERLAY,
    shouldFilter: true,
  };
}

export function updateOverlay(field, oldName, newName) {
  return {
    type: types.REPORT_UPDATE_CALENDAR_OVERLAY,
    payload: {
      field,
      oldName,
      newName,
    },
  };
}

const OVERLAYS_FIELDS = [
  'alleviating_factor',
  'aggravating_factor',
  'ineffective_factor',
  'location',
  'character',
  'environment',
  'symptom',
];

export function getMostCommonFields(records, fields) {
  let fieldsCount = records.reduce((acc, record) => {
    if (!record || !record.fields) {
      return acc;
    }
    OVERLAYS_FIELDS.forEach((field) => {
      if (record.fields[field]) {
        if (!acc[field]) {
          acc[field] = {};
        }
        record.fields[field].forEach((id) => {
          if (!acc[field][id]) {
            acc[field][id] = 0;
          }
          acc[field][id]++;
        });
      }
    });
    return acc;
  }, {});
  let total = 0;
  let result = {};
  OVERLAYS_FIELDS.forEach((field) => {
    if (total >= MAX_OVERLAYS_NUMBER || !fieldsCount || !fieldsCount[field]) {
      return;
    }
    // if(field==='aggravating_factor') {
    //     console.log('FIELDS', records.length, Object.keys(fieldsCount[field]).map(f => {return fields[field][f].name+"-"+fieldsCount[field][f]}));
    // }
    let sortedFields = Object.keys(fieldsCount[field])
      .sort((a, b) => fieldsCount[field][b] - fieldsCount[field][a])
      .slice(0, MAX_OVERLAYS_NUMBER - total);
    result[field] = sortedFields
      .filter((id) => fields && fields[field] && fields[field][id])
      .map((id) => fields[field][id].name);
    total = total + sortedFields.length;
  });
  console.log('');
  return result;
}
export function fixRecord(record) {
  return (dispatch) => {
    let rec = deepCopy(record);
    rec.idOnServer = 0;
    rec.flag_created = true;
    rec.flag_updated = true;
    dispatch({
      type: types.SAVE_RECORD_SUCCESS,
      shouldFilter: true,
      payload: rec,
    });
    return Promise.resolve();
  };
}
export function fixDeletedRecord(record) {
  return (dispatch) => {
    let rec = deepCopy(record);
    rec.idOnServer = 0;
    rec.flag_deleted = true;
    dispatch({
      type: types.SAVE_DELETED_RECORD_SUCCESS,
      shouldFilter: true,
      payload: rec,
    });
    return Promise.resolve();
  };
}

export function fixDailyReflection(record) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_RECORD_SUCCESS,
      shouldFilter: true,
      payload: record,
    });
    return Promise.resolve();
  };
}
