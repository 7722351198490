import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { designConstants } from '../styles';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import { withTheme } from '../styles/useThemeContext';

// Default button look is blue text with capital case
class MediumEmphasisButton extends Component {
  onPress = () => {
    trackButtonPress('Button ' + (this.props.tag || this.props.text));
    this.props.onPress();
  };

  render() {
    let { lightGreen, white, red, style, text, gray, ignoreSentenceCase } =
      this.props;
    const { colors } = this.props.theme;

    return (
      <TouchableNative
        onPress={this.onPress}
        {...setTestID(this.props.tag || this.props.text)}
        style={[mediumEmphasisButtonStyles.rippleWrapper, style]}
      >
        <Text
          blue
          style={[
            lightGreen && { color: colors.lightGreen },
            white && { color: colors.white },
            red && { color: colors.red },
            gray && { color: colors.gray },
            {
              padding: designConstants.paddings.buttonMediumEmphasisPadding,
              textAlign: 'center',
            },
          ]}
        >
          {ignoreSentenceCase ? text : toSentenceCase(text)}
        </Text>
      </TouchableNative>
    );
  }
}

const mediumEmphasisButtonStyles = StyleSheet.create({
  rippleWrapper: {
    minHeight: designConstants.heights.touchableMinHeight,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    borderWidth: designConstants.onePixel,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
});

MediumEmphasisButton.propTypes = {
  lightGreen: PropTypes.bool,
  white: PropTypes.bool,
  red: PropTypes.bool,
  caps: PropTypes.bool,
  // style: ViewPropTypes.style,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ignoreSentenceCase: PropTypes.bool,
};

MediumEmphasisButton.defaultProps = {
  lightGreen: false,
  white: false,
  red: false,
  caps: false,
  style: {},
  onPress: () => null,
  text: '',
  ignoreSentenceCase: false,
};

export default withTheme(MediumEmphasisButton);
