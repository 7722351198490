import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import React, { Suspense } from 'react';
import ManageMyPain from './App';
import SplashView from './modules/Navigator/components/SplashView';
import SessionStorage from 'redux-persist/lib/storage/session';
// import reportWebVitals from './reportWebVitals';

const urlParams = new URLSearchParams(window.location.search);
urlParams.has('utm_source') &&
  SessionStorage.setItem('utm_source', urlParams.get('utm_source'));
urlParams.has('utm_medium') &&
  SessionStorage.setItem('utm_medium', urlParams.get('utm_medium'));
urlParams.has('utm_campaign') &&
  SessionStorage.setItem('utm_campaign', urlParams.get('utm_campaign'));

class App extends React.Component {
  render() {
    return (
      <Suspense fallback={<SplashView />}>
        <ManageMyPain />
      </Suspense>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// reportWebVitals();
