import { useScrollToTop } from '@react-navigation/native';
import React, { Component, createRef, useRef } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { designConstants } from '../styles';
import KeyboardAvoidingHOC from './KeyboardAvoidingHOC';

class Content extends Component {
  currentScroll = 0;
  scrollContentTo = ({ x, y, animated }) => {
    this.props.scrollViewRef.current?.scrollTo({ x, y, animated });
  };

  scrollToEnd = (animated) => {
    this.props.scrollViewRef.current?.scrollToEnd({ animated });
  };

  handleScroll = (event) => {
    this.currentScroll = event.nativeEvent.contentOffset.y;
    this.props.onScroll && this.props.onScroll(event);
  };

  render() {
    const { children, style, form, fullWidth, ...otherProps } = this.props;
    const { defaultPadding, grow, contentContainerStyle } = this.props;
    //This avoid adding padding to ScrollView, because it breaks everything
    const {
      padding,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      ...otherStyles
    } = style || {};

    return (
      <ScrollView
        {...otherProps}
        keyboardShouldPersistTaps={'handled'}
        ref={this.props.scrollViewRef}
        contentContainerStyle={[
          designConstants.isWeb && {
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          },
          grow && { flexGrow: 1 },
        ]}
        onScroll={this.handleScroll}
        scrollEventThrottle={10}
        style={[styles.content, otherStyles]}
      >
        <View
          style={[
            defaultPadding && {
              padding: designConstants.paddings.contentDefaultPadding,
            },
            designConstants.isWeb && {
              flex: 1,
              maxWidth: fullWidth
                ? undefined
                : form
                  ? designConstants.maxFormWidth
                  : designConstants.webMaxWidth,
            },
            contentContainerStyle,
          ]}
        >
          {children}
        </View>
      </ScrollView>
    );
  }
}
const WrappedContent = KeyboardAvoidingHOC(Content);

export default React.forwardRef((props, forwardRef) => {
  const ref = useRef(null);

  useScrollToTop(ref);

  return <WrappedContent {...props} ref={forwardRef} scrollViewRef={ref} />;
});
// Content.propTypes = {
//   ...ViewPropTypes,
//   // contentContainerStyle: ViewPropTypes.style,
//   children: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.arrayOf(PropTypes.node),
//   ]),
//   // style: ViewPropTypes.style,
//   keyboardWasShown: PropTypes.bool,
//   grow: PropTypes.bool,
//   defaultPadding: PropTypes.bool,
//   onScrolledToEnd: PropTypes.func,
//   onScrolledToEndPadding: PropTypes.number,
// };
//
// Content.defaultProps = {
//   children: null,
//   contentContainerStyle: {},
//   style: {},
//   keyboardWasShown: false,
//   grow: false,
//   defaultPadding: false,
// };

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'transparent',
    flexGrow: 1,
  },
  webContainerStyle: designConstants.isWeb && {
    maxWidth: 1024,
  },
});
