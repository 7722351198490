import React from 'react';
import { Animated, View } from 'react-native';
import { connect } from 'react-redux';
import { Icon } from '../../../design/components';
import { withTheme } from '../../../design/styles/useThemeContext';

class SyncIndicator extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      spin: new Animated.Value(0),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.props.sync_in_progress && props.sync_in_progress) {
      this.spinStart();
    }
  }

  componentDidMount() {
    //        this.spinStart();
  }

  spinStop = () => {
    if (this.animated) {
      this.animated.stop();
      delete this.animated;
    }
  };
  spinStart = () => {
    //        if(this.animated && this.animated.Value!='0')
    this.state.spin.setValue(0);
    if (!this.animated) {
      this.animated = Animated.timing(
        // Animate over time
        this.state.spin, // The animated value to drive
        {
          toValue: -1,
          duration: 4000, // Make it take a while
          useNativeDriver: true,
        },
      );
    }
    this.animated.start((animation) => {
      if (animation.finished) {
        if (this.props.type !== 'info' && this.props.type !== 'success') {
          this.animated.stop();
          delete this.animated;
          return;
        }
        this.spinStart();
      }
    });
  };

  render() {
    const { colors } = this.props.theme;
    let spin = this.state.spin.interpolate({
      inputRange: [0, 1],
      outputRange: [
        '-90deg', // 'deg' or 'rad'
        '270deg',
      ],
    });

    return (
      <View>
        {this.props.type === 'error' ? (
          <Icon
            name="alert"
            type="MaterialCommunityIcons"
            size={18}
            color={colors.red}
          />
        ) : (
          <Animated.View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              transform: [{ rotate: spin }],
            }}
          >
            <Icon
              name="sync"
              type="MaterialCommunityIcons"
              size={18}
              color={colors.buttonBlue}
            />
          </Animated.View>
        )}

        {!this.props.hideDot && this.props.pending && (
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: 10,
              height: 10,
              borderRadius: 5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: colors.green,
            }}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sync_in_progress: state.sync.sync_in_progress,
    message: state.sync.message,
    type: state.sync.type || null,
    pending:
      state.records.list.some((rec) => rec.flag_updated) ||
      (state.profile.content &&
        ((state.profile.content.medications &&
          state.profile.content.medications.some(
            (med) => med.flag_created || med.flag_updated,
          )) ||
          (state.profile.content.condition &&
            state.profile.content.condition.some(
              (cond) => cond.flag_created || cond.flag_updated,
            )))) ||
      (state.meds &&
        state.meds.deleted &&
        ((state.meds.deleted.condition &&
          state.meds.deleted.condition.length > 0) ||
          (state.meds.deleted.medications &&
            state.meds.deleted.medications.length > 0))),
    // Fields need to be added
  };
};

export default connect(mapStateToProps)(withTheme(SyncIndicator));
