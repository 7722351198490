import React, { useCallback } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { designConstants } from '../styles';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import Footer from './Footer';
import setTestID from '../../shared/utilities/setTestID';
import useKeyboardShown from '../../shared/utilities/useKeyboardShown';
import useThemeContext from '../styles/useThemeContext';

// Default button look is gray text with sentence case
export default function FooterButton(props) {
  const { colors } = useThemeContext();
  const onPress = useCallback(() => {
    const { disabled } = props;
    if (disabled) {
      return;
    }
    trackButtonPress('Button ' + (props.tag || props.text));
    props.onPress();
  }, [props]);

  const isKeyboardShown = useKeyboardShown();
  let { capital, style, text, disabled, loading, ignoreSentenceCase } = props;
  const { width } = useWindowDimensions();

  const Touchable = disabled ? View : TouchableNative;

  return (
    <>
      <View
        style={[
          {
            height: isKeyboardShown
              ? 0
              : designConstants.footer.buttonContainerHeight +
                (props.navBarShown
                  ? 0
                  : designConstants.bottomSpacerForIPhoneX),
          },
        ]}
      >
        <Footer
          style={[
            footerButtonStyles.footerContainer,
            {
              width: width,
              backgroundColor: colors.defaultBackground,
              height:
                designConstants.footer.buttonContainerHeight +
                (props.navBarShown
                  ? 0
                  : designConstants.bottomSpacerForIPhoneX),
            },
            // designConstants.isWeb && {
            //   position: 'fixed',
            //   bottom: 0,
            //   width: '100%',
            //   backgroundColor: 'rgba(255, 255, 255, 0.98)',
            // },
          ]}
        >
          <Touchable
            style={[
              footerButtonStyles.buttonStyle,
              {
                backgroundColor: colors.lightGreen,
              },
              style,
              disabled && {
                backgroundColor: colors.buttonFooterBackground,
              },
            ]}
            {...setTestID(props.tag || props.text)}
            onPress={onPress}
          >
            {loading ? (
              <View style={footerButtonStyles.loadingContainer}>
                <ActivityIndicator size="small" color={colors.white} />
                {!!props.loadingText && (
                  <Text
                    style={{
                      textAlign: 'center',
                      marginLeft: 14,
                      color: colors.white,
                    }}
                  >
                    {props.loadingText}
                  </Text>
                )}
              </View>
            ) : (
              <Text center white style={footerButtonStyles.footerButtonText}>
                {ignoreSentenceCase ? text : toSentenceCase(text)}
              </Text>
            )}
          </Touchable>
        </Footer>
      </View>
    </>
  );
}

const footerButtonStyles = StyleSheet.create({
  footerContainer: {
    paddingHorizontal: designConstants.paddings.edgePadding,
    paddingTop: 24,
    position: 'fixed',
    bottom: 0,
    left: 0,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonStyle: {
    flex: 1,
    flexDirection: 'row',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    maxWidth: 400,
  },
  footerButtonText: {
    flex: 1,
  },
  rippleWrapper: {
    minHeight: designConstants.heights.touchableMinHeight,
    borderRadius: 15,
    borderWidth: designConstants.onePixel,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

FooterButton.propTypes = {
  caps: PropTypes.bool,
  capital: PropTypes.bool,
  // style: ViewPropTypes.style,
  minTextHeight: PropTypes.number,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  navBarShown: PropTypes.bool,
  ignoreSentenceCase: PropTypes.bool,
};

FooterButton.defaultProps = {
  caps: false,
  capital: false,
  minTextHeight: 0,
  disabled: false,
  style: {},
  onPress: () => null,
  text: '',
  navBarShown: false,
  ignoreSentenceCase: false,
};
