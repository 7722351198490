export function parse() {}

export default class AllRecords {
  constructor(serverResult) {
    this.recordsList = [];
    serverResult.forEach((record) => {
      switch (record.record_type) {
        case 'server_time':
          this.serverTime = record.created;
          break;
        case 'pain_record':
          this.recordsList.push(record);
          break;
        case 'user_preferences_record':
          this.custom_lists = record;
          break;
        case 'user_profile_record':
          this.user_profile = record;
          break;
        case 'user_settings_record':
          //Filters and overlays goes here
          break;
        case 'user_record':
          this.user = record;
          break;
      }
    });
  }

  get time() {
    return this.serverTime;
  }

  get records() {
    return this.recordsList;
  }
  get customLists() {
    return this.custom_lists;
  }

  get myProfile() {
    return this.user_profile;
  }

  get userRecord() {
    return this.user;
  }
}
