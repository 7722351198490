import * as types from './actiontypes';
import Moment from 'moment';
import { LOGOUT } from '../Auth/actiontypes';

const CURRENT_INIT = {
  id: 0,
  mode: 'any',
  pain_records: true,
  daily_reflections: true,
  fields: [],
  medications: {},
  severity_mode: 'all',
  severity: 0,
  severityEnd: 10,
  type_of_pain: [],
  date_mode: 'all',
  time_mode: 'all',
  duration_mode: 'all',
  duration: 0,
  dateRange: '30days',
  score: 0,
  scoreEnd: 10,
};

const initialState = {
  current: { ...CURRENT_INIT },
  filteredRecords: [],
  dateRangeRecords: 0,
  filteredWithoutDateRange: [],
  parsedRecords: {},
};

function FilterReducer(state = { ...initialState }, action) {
  let current = state.current || {};
  switch (action.type) {
    case LOGOUT:
      return { ...initialState };
    case types.FILTER_RECORDS_PARSER_FINISHED:
      return {
        ...state,
        parsedRecords: action.payload,
        parsedDate: action.date,
      };
    case types.FILTER_RECORDS_START:
      return {
        ...state,
        filtering: true,
      };
    case types.FILTER_CLEAR:
      let { dateRange, date, date_end } = state.current;
      state = { ...state };
      state.current = {
        ...CURRENT_INIT,
        id: state.current.id + 1,
        dateRange,
        date,
        date_end,
      };
      return state;
    case types.FILTER_RECORDS_RANGE_CHANGED:
      return {
        ...state,
        ...action.payload,
      };
    case types.FILTER_RECORDS_DATERANGE_END:
      state.dateRangeRecords = action.payload;
      return {
        ...state,
      };
    case types.FILTER_RECORDS_WITHOUT_DATERANGE:
      return {
        ...state,
        filteredWithoutDateRange: [...action.payload],
      };
    case types.FILTER_RECORDS_END:
      return {
        ...state,
        filteredRecords: action.payload.filtered,
        filteredWithoutDateRange: action.payload.filteredWithoutDateRange,
        first_day: action.payload.first_day,
        last_day: action.payload.last_day,
        dateRangeRecords: action.payload.dateRange,
        filtering: false,
      };
    case types.FILTER_APPLY_REPORT:
      state.current = { ...CURRENT_INIT };
      return {
        ...state,
      };
    case types.FILTER_APPLY_RESULTS:
      state.current = { ...CURRENT_INIT };
      return {
        ...state,
      };
    case types.FILTER_LOAD:
      state.current = action.payload || CURRENT_INIT;
      return {
        ...state,
      };
    case types.FILTER_SET_DATE:
      current.date = action.payload;
      return {
        ...state,
        current: { ...current },
        filtering: true,
      };
    case types.FILTER_SET_DATE_RANGE:
      current.dateRange = action.payload;
      return {
        ...state,
        current: { ...current },
        filtering: true,
      };
    case types.FILTER_SET_END_DATE:
      current.date_end = action.payload;
      return {
        ...state,
        current: { ...current },
        filtering: true,
      };
    case types.FILTER_SET_DATE_MODE:
      current.date_mode = action.payload;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_TIME:
      current.time = action.payload - (action.payload % 60000); //Remove seconds and milliseconds
      if (current.time_mode === '>') {
        current.time = current.time + 59999; //Not include current minute
      }
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_TIME_MODE:
      current.time_mode = action.payload;
      if (!current.time) {
        let startDate = Moment().startOf('day').valueOf();
        let time = Moment().valueOf() - startDate;
        current.time = time - (time % 60000); //Remove seconds and milliseconds
      }
      if (current.time_mode === '>' && current.time % 60000 === 0) {
        current.time = current.time + 59999; //Not include current minute
      } else if (current.time_mode !== '>' && current.time % 60000 !== 0) {
        current.time = current.time - (current.time % 60000); //Remove seconds and milliseconds
      }
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_DURATION:
      current.duration = action.payload;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_DURATION_UNITS:
      current.duration_units = action.payload;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_DURATION_MODE:
      current.duration_mode = action.payload;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_CHECK_TYPE_OF_PAIN:
      if (!current.type_of_pain) {
        current.type_of_pain = [];
      }
      let isAllCheckedType = current.mode === 'all';
      if (isAllCheckedType) {
        if (current.type_of_pain.includes(action.payload)) {
          current.type_of_pain = [];
        } else {
          current.type_of_pain = [action.payload];
        }
      } else {
        current.type_of_pain = current.type_of_pain.slice();
        if (current.type_of_pain.includes(action.payload)) {
          current.type_of_pain = current.type_of_pain.filter(
            (f) => f !== action.payload,
          );
        } else {
          current.type_of_pain.push(action.payload);
        }
      }
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_SCORE:
      current.score = action.payload.start;
      current.scoreEnd = action.payload.end;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_SEVERITY:
      current.severity = action.payload.start;
      current.severityEnd = action.payload.end;
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_SET_MODE:
      current.mode = action.payload;
      if (action.payload === 'all') {
        //If mode set to "all", then we need to uncheck all medications/factors/paintype except single one
        current.medications = { ...current.medications };
        Object.keys(current.medications).forEach((id) => {
          if (current.medications[id].type.length > 0) {
            current.medications[id] = {
              ...current.medications[id],
              type: current.medications[id].type.slice(-1),
            };
          }
        });

        current.factors = { ...current.factors };
        Object.keys(current.factors).forEach((id) => {
          if (current.factors[id].type.length > 0) {
            current.factors[id] = {
              ...current.factors[id],
              type: current.factors[id].type.slice(-1),
            };
          }
        });
        current.type_of_pain = current.type_of_pain.slice(-1);
      }
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_CHECK_FIELD:
      current = { ...current };
      if (!current.fields) {
        current.fields = [];
      }
      let field = {
        field: action.payload.fieldType,
        id: action.payload.fieldId,
      };
      let index = current.fields.find(
        (f) => f.field === field.field && f.id === field.id,
      );
      if (index) {
        current.fields = current.fields.filter(
          (f) => !(f.field === field.field && f.id === field.id),
        );
      } else {
        current.fields = current.fields.slice();
        current.fields.push(field);
      }
      return {
        ...state,
        current: current,
      };
    case types.FILTER_CHECK_MEDICATION:
      current = { ...current };
      if (!current.medications) {
        current.medications = {};
      }
      let hasMed = current.medications.hasOwnProperty(action.payload.nid);
      let isAllChecked = current.mode === 'all';
      if (hasMed && !action.payload.medicationType) {
        current.medications = { ...current.medications };
        delete current.medications[action.payload.nid];
      } else {
        let medication = {
          id: action.payload.nid,
          type: [action.payload.medicationType || 'alleviating'],
        };
        if (hasMed && !isAllChecked) {
          current.medications = { ...current.medications };
          if (
            current.medications[medication.id].type.includes(
              action.payload.medicationType,
            )
          ) {
            if (current.medications[medication.id].type.length == 1) {
              delete current.medications[medication.id];
            } else {
              let newType = current.medications[medication.id].type.filter(
                (t) => t !== action.payload.medicationType,
              );
              current.medications[medication.id] = {
                ...current.medications[medication.id],
                type: newType,
              };
            }
          } else {
            current.medications[medication.id] = {
              ...current.medications[medication.id],
            };
            current.medications[medication.id].type.push(
              action.payload.medicationType,
            );
          }
        } else {
          current.medications = { ...current.medications };
          current.medications[medication.id] = medication;
        }
      }
      return {
        ...state,
        current: current,
      };
    case types.FILTER_CHECK_FACTOR:
      current = { ...current };
      if (!current.factors) {
        current.factors = {};
      }
      let isAllCheckedFactor = current.mode === 'all';
      let hasFactor = current.factors.hasOwnProperty(action.payload.id);
      if (hasFactor && !action.payload.type) {
        current.factors = { ...current.factors };
        delete current.factors[action.payload.id];
      } else {
        let factor = {
          id: action.payload.id,
          type: [action.payload.type || 'alleviating'],
        };
        if (hasFactor && !isAllCheckedFactor) {
          current.factors = { ...current.factors };
          if (current.factors[factor.id].type.includes(action.payload.type)) {
            if (current.factors[factor.id].type.length == 1) {
              delete current.factors[factor.id];
            } else {
              let newType = current.factors[factor.id].type.filter(
                (t) => t !== action.payload.type,
              );
              current.factors[factor.id] = {
                ...current.factors[factor.id],
                type: newType,
              };
            }
          } else {
            current.factors[factor.id] = { ...current.factors[factor.id] };
            current.factors[factor.id].type.push(action.payload.type);
          }
        } else {
          current.factors = { ...current.factors };
          current.factors[factor.id] = factor;
        }
      }
      return {
        ...state,
        current: current,
      };
    case types.FILTER_CHECK_TYPE:
      current[action.payload] = !current[action.payload];
      return {
        ...state,
        current: { ...current },
      };
    case types.FILTER_UPDATE_DATE_AND_DATE_END_VALUES: {
      return {
        ...state,
        current: {
          ...state.current,
          date: action.payload.date,
          date_end: action.payload.date_end,
        },
      };
    }

    default:
      return state;
  }
}

export { FilterReducer };
