/**
 * Created by amoghbanta on 04/02/17.
 */
import * as types from './actiontypes';
import * as profileTypes from '../Profile/actiontypes';

function MedsReducer(state = {}, action) {
  switch (action.type) {
    case types.PREPARE_NEW_MEDICATION:
      delete state.current;
      return {
        ...state,
      };
    case types.MED_SET_CURRENT:
      if (
        typeof action.payload.take_as_value === 'undefined' ||
        action.payload.take_as_value === ''
      ) {
        action.payload.take_as_value = null;
      }
      return {
        ...state,
        current: { ...state.current, ...action.payload },
      };
    case types.EDIT_MEDICATION_NAME:
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            edit_medication_name: true,
          },
        },
      };
    case types.MED_SET_STRENGTH:
      return {
        ...state,
        current: { ...state.current, strength: action.payload },
      };
    case types.MED_SET_STRENGTH_UNITS:
      return {
        ...state,
        current: { ...state.current, strength_units: action.payload },
      };
    case types.MED_CLEAR:
      delete state.current.brand_name;
      delete state.current.common_name;
      delete state.current.common_name_translation;
      return {
        ...state,
        current: { ...state.current },
      };
    case types.MED_SET_START_DATE:
      return {
        ...state,
        current: { ...state.current, start_date: action.payload },
      };
    case types.MED_SET_END_DATE:
      return {
        ...state,
        current: { ...state.current, end_date: action.payload },
      };
    case types.MED_SET_TAKE_AS:
      return {
        ...state,
        current: { ...state.current, take_as_value: action.payload },
      };
    case types.MED_SET_FORM:
      return {
        ...state,
        current: { ...state.current, dosage_units: action.payload },
      };
    case types.MED_SET_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        current: { ...state.current, special_instructions: action.payload },
      };
    case types.MED_ADD_DOSAGE:
      if (state.current) {
        if (!state.current.dosage) {
          state.current.dosage = [];
        }
        if (action.payload.position >= 0) {
          state.current.dosage.splice(
            action.payload.position,
            0,
            action.payload.dosage,
          );
        } else {
          state.current.dosage.push(action.payload.dosage);
        }
        state.current.dosage = state.current.dosage.slice();
      }
      return {
        ...state,
        current: { ...state.current },
      };
    case types.MED_EDIT_DOSAGE:
      return {
        ...state,
      };
    case types.MED_DELETE_DOSAGE:
      if (state.current) {
        if (!state.current.dosage) {
          state.current.dosage = [];
        }
        let index = state.current.dosage.indexOf(action.payload);
        state.current.dosage.splice(index, 1);
        //                state.current.dosage = state.current.dosage.filter(dosage => !(dosage.frequency===action.payload.frequency && dosage.frequency_value===action.payload.frequency_value && dosage.value===action.payload.value && dosage.time===action.payload.time));
        state.current.dosage = state.current.dosage.slice();
      }
      return {
        ...state,
        current: { ...state.current },
      };
    case types.MED_CHECK_PURPOSE:
      if (state.current) {
        if (!state.current.purpose) {
          state.current.purpose = [];
        }
        let index = state.current.purpose.indexOf(action.payload);
        state.current.purpose = state.current.purpose.slice();
        if (index > -1) {
          state.current.purpose.splice(index, 1);
        } else {
          state.current.purpose.push(action.payload);
        }
      }
      return {
        ...state,
        current: { ...state.current },
      };

    case types.MED_ADD_PURPOSE:
      return {
        ...state,
      };
    case types.MED_SET_NOTE:
      return {
        ...state,
        current: { ...state.current, med_notes: action.payload },
      };

    case types.SET_OFFLINE_ID_FID_NID: {
      const { id, fid, nid } = action.payload;
      return {
        ...state,
        offline_id: id,
        offline_fid: fid,
        offline_nid: nid,
      };
    }

    case types.EDIT_TEMPORARY_PAIN_CONDITION_INFO_SUBSTITUTE_FID_AND_VALUE: {
      const fid = state.temporary_pain_condition_info.data.fid;
      return {
        ...state,
        temporary_pain_condition_info: {
          ...state.temporary_pain_condition_info,
          data: {
            original_fid: fid,
            fid: null,
            isShow: 1,
            ...state.temporary_pain_condition_info.data,
            ...action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.DELETE_OFFLINE_PAIN_CONDITION_MEDS: {
      const _state = { ...state };
      if (!_state.deleted) {
        _state.deleted = { condition: [], medications: [] };
      } else {
        if (!_state.deleted.condition) {
          _state.deleted.condition = [];
        }
        if (!_state.deleted.medications) {
          _state.deleted.medications = [];
        }
      }
      _state.deleted.condition.push(action.payload);
      return _state;
    }

    case types.REMOVE_DELETED_MEDS: {
      const _state = { ...state };
      if (!_state.deleted) {
        _state.deleted = { condition: [], medications: [] };
      } else {
        if (!_state.deleted.condition) {
          _state.deleted.condition = [];
        }
        if (!_state.deleted.medications) {
          _state.deleted.medications = [];
        }
      }

      return {
        ..._state,
        deleted: {
          condition: action.payload.condition ? [] : _state.deleted.condition,
          medications: action.payload.medications
            ? []
            : _state.deleted.medications,
        },
      };
    }

    case types.ADD_TEMPORARY_PAIN_CONDITION_INFO: {
      return {
        ...state,
        temporary_pain_condition_info: {
          ...action.payload,
        },
      };
    }

    case types.EDIT_TEMPORARY_PAIN_CONDITION_INFO: {
      return {
        ...state,
        temporary_pain_condition_info: {
          ...state.temporary_pain_condition_info,
          data: {
            ...state.temporary_pain_condition_info.data,
            ...action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_PAIN_CONDITION_INFO_SAVING: {
      return {
        ...state,
        temporary_pain_condition_info: {
          ...state.temporary_pain_condition_info,
          ...action.payload,
        },
      };
    }

    case types.DELETE_TEMPORARY_PAIN_CONDITION_INFO: {
      return {
        ...state,
        temporary_pain_condition_info: {
          data: null,
          new_created: null,
          type_of_action: null,
          scene_key_to_return_after_save: null,
          was_changed: null,
          saving: null,
        },
      };
    }

    case types.REMOVE_NETWORK_ERROR_FOR_MEDICATIONS_BRANDS: {
      return {
        ...state,
        medications_brands_requesting_failure: undefined,
      };
    }

    case types.SEARCH_BRANDS_REQUEST: {
      return {
        ...state,
        requestingBrands: true,
      };
    }
    case types.SEARCH_BRANDS_SUCCESS:
      return {
        ...state,
        brandsFound: [...action.payload],
        requestingBrands: false,
      };
    case types.SEARCH_BRANDS_FAILURE: {
      return {
        ...state,
        requestingBrands: false,
        medications_brands_requesting_failure: action.payload,
      };
    }

    // temporary_medication_info has the following structure:
    // temporary_medication_info: {
    // type_of_action: 'add' or 'edit',
    // scene_key_to_return_after_save: string,
    // data: object_of_medication
    // }
    case types.ADD_TEMPORARY_MEDICATION_INFO: {
      return { ...state, temporary_medication_info: { ...action.payload } };
    }

    case types.CLEAR_TEMPORARY_MEDICATION: {
      return { ...state, temporary_medication_info: null };
    }
    case types.EDIT_TEMPORARY_MEDICATION_STRENGTH: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            strengths_array:
              state.temporary_medication_info.data.strengths_array.map(
                (strength, index) => {
                  if (index === action.payload.index) {
                    return action.payload.strength;
                  } else {
                    return strength;
                  }
                },
              ),
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_SELECT_BRAND: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            brand_name: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_SELECT_MEDICATION: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            common_name: action.payload.common_name,
            common_name_translation: action.payload.common_name_translation,
            brand_name: action.payload.brand_name,
            edit_medication_name: false,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_STRENGTH_UNITS: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            strength_units: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_DOSAGE_UNITS: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            dosage_units: action.payload,
          },
          was_changed: true,
        },
      };
    }
    case profileTypes.EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID: {
      if (
        !state.temporary_medication_info ||
        !state.temporary_medication_info.data
      ) {
        return state;
      }

      const old_fid = action.payload.old_fid;
      const new_fid = action.payload.new_fid;
      let newPurpose = state.temporary_medication_info.data.purpose.map(
        (purpose) => {
          if (purpose == old_fid) {
            return new_fid;
          }
          return purpose;
        },
      );
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            purpose: newPurpose,
          },
          was_changed: true,
        },
      };
    }
    case types.EDIT_TEMPORARY_MEDICATION_PURPOSE: {
      if (
        !state.temporary_medication_info ||
        !state.temporary_medication_info.data
      ) {
        return state;
      }
      let newPurpose = [...state.temporary_medication_info.data.purpose];

      if (newPurpose.indexOf(action.payload) >= 0) {
        newPurpose = newPurpose.filter((purpose) => purpose !== action.payload);
      } else {
        newPurpose.push(action.payload);
      }

      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            purpose: newPurpose,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE: {
      if (
        !state.temporary_medication_info ||
        state.temporary_medication_info.data === null ||
        !state.temporary_medication_info.data
      ) {
        return { ...state };
      }
      let newPurpose = [...state.temporary_medication_info.data.purpose];
      if (newPurpose.indexOf(action.payload) >= 0) {
        newPurpose = newPurpose.filter((purpose) => purpose !== action.payload);
      }
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            purpose: newPurpose,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_START_DATE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            start_date: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_END_DATE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            end_date: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_TAKE_AS_VALUE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            take_as_value:
              action.payload ===
              state.temporary_medication_info.data.take_as_value
                ? null
                : action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_MED_NOTES: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            med_notes: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_SPECIAL_INSTRUCTIONS: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            special_instructions: action.payload,
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_ADD_DOSAGE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            dosage: [
              ...state.temporary_medication_info.data.dosage,
              { ...action.payload },
            ],
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_EDIT_DOSAGE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            dosage: state.temporary_medication_info.data.dosage.map(
              (dosage, index) => {
                if (index !== action.payload.dosage_index) {
                  return dosage;
                }
                return { ...action.payload.dosage };
              },
            ),
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_DELETE_DOSAGE: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          data: {
            ...state.temporary_medication_info.data,
            dosage: state.temporary_medication_info.data.dosage.filter(
              (dosage, index) => index !== action.payload,
            ),
          },
          was_changed: true,
        },
      };
    }

    case types.EDIT_TEMPORARY_MEDICATION_INFO_SAVING: {
      return {
        ...state,
        temporary_medication_info: {
          ...state.temporary_medication_info,
          ...action.payload,
        },
      };
    }

    case types.DELETE_OFFLINE_MEDICATION_MEDS: {
      const _state = { ...state };
      if (!_state.deleted) {
        _state.deleted = { condition: [], medications: [] };
      } else {
        if (!_state.deleted.condition) {
          _state.deleted.condition = [];
        }
        if (!_state.deleted.medications) {
          _state.deleted.medications = [];
        }
      }
      _state.deleted.medications.push(action.payload);
      return _state;
    }

    case types.DELETE_TEMPORARY_MEDICATION_INFO: {
      return {
        ...state,
        temporary_medication_info: {
          data: null,
          new_created: null,
          type_of_action: null,
          scene_key_to_return_after_save: null,
          was_changed: null,
          saving: null,
        },
      };
    }

    default:
      return state;
  }
}

export { MedsReducer };
