import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import TouchableNative from './TouchableNative';
import Icon from './Icon';
import PropTypes from 'prop-types';
import { designConstants } from '../styles';
import CollapsibleWrapper from './CollapsibleWrapper';
import { withTheme } from '../styles/useThemeContext';

class CollapsibleForm extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: props.collapsed };
  }

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { colors } = this.props.theme;
    const {
      animationVelocityPixelsPerSecond,
      standardHeader,
      header,
      children,
    } = this.props;
    const { collapsed } = this.state;

    let headerToRender = header;

    if (standardHeader) {
      headerToRender = (
        <View
          style={[
            collapsibleFormStyles.headerWrapper,
            {
              backgroundColor: colors.defaultBackground,
              borderColor: colors.grayLine,
            },
          ]}
        >
          {header}
          {collapsed ? (
            <Icon
              name={'expand-more'}
              type={'MaterialIcons'}
              size={designConstants.icons.size}
              style={{
                marginRight:
                  designConstants.icons.expandIconCorrectingMarginRight,
              }}
              color={colors.blue}
            />
          ) : (
            <Icon
              name={'expand-less'}
              type={'MaterialIcons'}
              size={designConstants.icons.size}
              style={{
                marginRight:
                  designConstants.icons.expandIconCorrectingMarginRight,
              }}
              color={colors.gray}
            />
          )}
        </View>
      );
    }

    return (
      <CollapsibleWrapper
        itemsToShowCollapsed={1}
        //animationVelocityPixelsPerSecond={animationVelocityPixelsPerSecond}
        collapsed={collapsed}
      >
        <TouchableNative onPress={this.toggleCollapsed}>
          {headerToRender}
        </TouchableNative>
        {children}
      </CollapsibleWrapper>
    );
  }
}

const collapsibleFormStyles = StyleSheet.create({
  headerWrapper: {
    paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
    paddingVertical: designConstants.paddings.textFieldVerticalPadding,
    minHeight: designConstants.heights.listItemMinHeightMedium,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: designConstants.onePixel,
  },
});

CollapsibleForm.propTypes = {
  header: PropTypes.node,
  collapsed: PropTypes.bool,
  animationVelocityPixelsPerSecond: PropTypes.number,
  standardHeader: PropTypes.bool,
};

CollapsibleForm.defaultProps = {
  header: null,
  collapsed: true,
  animationVelocityPixelsPerSecond: 1100,
  standardHeader: true,
};

export default withTheme(CollapsibleForm);
