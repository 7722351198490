import React, { useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu as MaterialMenu,
  MenuItem,
} from '@mui/material';
import Icon from './Icon';
import { designConstants } from '../styles';
import { Image, TouchableOpacity, View } from 'react-native';
import { useThemeContext, withTheme } from '../styles/useThemeContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  paper: (colors) => ({
    backgroundColor: colors.defaultBackground,
  }),
});
const Menu = ({ items, onItemPress, children }) => {
  const { colors } = useThemeContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles(colors);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPress = (itemId) => {
    setAnchorEl(null);
    onItemPress(itemId);
  };

  return (
    <TouchableOpacity>
      <div
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children}
      </div>
      <MaterialMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper }}
      >
        {items?.map((item) => {
          return (
            <MenuItem
              id={item.id}
              key={'key_' + item.id}
              style={{
                alignItems: 'flex-start',
              }}
              onClick={() => onPress(item.id)}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {item.image && (
                  <Image
                    source={{ uri: item.image }}
                    style={{
                      overlayColor: colors.gray,
                      marginRight:
                        designConstants.paddings.largePaddingBetweenTextAndIcon,
                    }}
                  />
                )}
                {item.icon && (
                  <ListItemIcon>
                    <Icon
                      type={
                        item.iconType
                          ? item.item.iconType
                          : 'MaterialCommunityIcons'
                      }
                      name={item.icon}
                      color={colors.gray}
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  primaryTypographyProps={{
                    style: { color: colors.textPrimary },
                  }}
                >
                  {item.text}
                </ListItemText>
              </View>
            </MenuItem>
          );
        })}
      </MaterialMenu>
    </TouchableOpacity>
  );
};

export default withTheme(Menu);
