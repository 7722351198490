import React from 'react';
import { ModalPicker } from './index';
import { designConstants } from '../styles';
import PropTypes from 'prop-types';
import { Image, StyleSheet, View } from 'react-native';
import Icon from './Icon';
import Text from './Text';
import FloatingInput from './FloatingInput';
import { withTheme } from '../styles/useThemeContext';

class ListActionSheet extends React.Component {
  lastPress = 0;

  onItemSelected = (id) => {
    let now = new Date().getTime();
    if (now - this.lastPress < designConstants.buttonPressDelay) {
      return;
    }
    this.lastPress = now;
    this.props.onItemPress(id);
  };

  modal = null;
  bindModal = (ref) => {
    this.modal = ref;
  };

  toggleVisibility() {
    if (this.modal) {
      this.modal.toggleModalVisibility();
    }
  }

  getChildren = () => {
    let child = this.props.children;
    const { colors } = this.props.theme;
    this.props.picker &&
      (child = (
        <Text>
          <Text style={{ color: colors.buttonBlue }}>
            {' '}
            {this.props.pickerText}{' '}
          </Text>
          <Icon
            type="MaterialCommunityIcons"
            name="menu-down"
            size={20}
            style={{ marginTop: 3, color: colors.buttonBlue }}
          />
        </Text>
      ));

    this.props.pickerFloating &&
      (child = (
        <FloatingInput
          label={this.props.pickerLabel}
          value={this.props.pickerText}
          picker
        />
      ));
    return child;
  };

  render() {
    const {
      items,
      style,
      androidInitialBarStyle,
      androidInitialBarColor,
      triggerSelectOnlyAfterModalWasClosed,
      selectedId,
      autoHide,
    } = this.props;
    const { colors } = this.props.theme;
    const child = this.props.picker ? (
      <Text>
        <Text style={{ color: colors.buttonBlue }}>
          {' '}
          {this.props.pickerText}{' '}
        </Text>
        <Icon
          type="MaterialCommunityIcons"
          name="menu-down"
          size={20}
          style={{ marginTop: 3, color: colors.buttonBlue }}
        />
      </Text>
    ) : (
      this.props.children
    );

    return (
      <ModalPicker
        ref={this.bindModal}
        defaultStyleOfChildrenRendering={false}
        headerText={this.props.headerText}
        onSelect={this.onItemSelected}
        androidInitialBarStyle={androidInitialBarStyle}
        androidInitialBarColor={androidInitialBarColor}
        customPickerElement={child ? React.cloneElement(child) : <View />}
        triggerSelectOnlyAfterModalWasClosed={
          triggerSelectOnlyAfterModalWasClosed
        }
        style={style}
        autoHide={autoHide}
      >
        {items.map((item) => {
          if (!item) {
            return null;
          }

          return (
            <View
              id={item.id}
              style={listActionSheet.item}
              key={'key_' + item.id}
            >
              {item.image && (
                <Image
                  source={{ uri: item.image }}
                  style={[
                    listActionSheet.imageIcon,
                    {
                      overlayColor: colors.gray,
                    },
                  ]}
                />
              )}
              {item.icon && (
                <Icon
                  type={
                    item.iconType ? item.iconType : 'MaterialCommunityIcons'
                  }
                  name={item.icon}
                  style={listActionSheet.icon}
                  color={colors.gray}
                />
              )}

              <Text
                style={{
                  color:
                    selectedId && selectedId === item.id
                      ? colors.buttonBlue
                      : colors.gray,
                }}
              >
                {item.text}
              </Text>
            </View>
          );
        })}
      </ModalPicker>
    );
  }
}

const listActionSheet = StyleSheet.create({
  item: {
    flexDirection: 'row',
    height: designConstants.heights.listItemMinHeightMedium,
    alignItems: 'center',
    //marginBottom: designConstants.paddings.paddingBetweenElementsSmall
    //marginBottom: designConstants.paddings.paddingBetweenElementsSmall
  },
  text: {},
  icon: {
    marginRight: designConstants.paddings.largePaddingBetweenTextAndIcon,
  },
  imageIcon: {
    marginRight: designConstants.paddings.largePaddingBetweenTextAndIcon,
  },
});

ListActionSheet.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
      iconType: PropTypes.string,
    }),
  ).isRequired,
  onItemPress: PropTypes.func.isRequired,
  // style: ViewPropTypes.style,
  androidInitialBarStyle: PropTypes.oneOf([
    'default',
    'dark-content',
    'light-content',
  ]),
  androidInitialBarColor: PropTypes.string,
  picker: PropTypes.bool,
  pickerText: PropTypes.string,
  triggerSelectOnlyAfterModalWasClosed: PropTypes.bool,
  headerText: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoHide: PropTypes.bool,
};

ListActionSheet.defaultProps = {
  items: [],
  onItemPress: () => null,
  style: {},
  headerText: null,
  picker: false,
  androidInitialBarStyle: 'default',
  androidInitialBarColor: '#0C74AD',
  triggerSelectOnlyAfterModalWasClosed: false,
  selectedId: null,
  autoHide: true,
};

export default withTheme(ListActionSheet);
