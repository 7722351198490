import React, { Suspense, useEffect, useState } from 'react';
import { designConstants } from '../../../design/styles/constants';
import {
  getActionFromState,
  getPathFromState,
  getStateFromPath,
  NavigationContainer,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';
import { keepAlive, stopKeepAlive, webTokenOutdated } from '../../Api/actions';
import { navigationRef } from './NavigationService';
import SplashScene from '../scenes/SplashScene';
import getConfig from '../../../shared/utilities/getConfig';
import { useIdleTimer } from 'react-idle-timer';
import {
  getInstitutionTranslations,
  loadTranslations,
} from '../../../shared/utilities/languageutils';
import SplashView from '../components/SplashView';
import { setCurrentRoute } from '../actions';
import { Linking, useColorScheme } from 'react-native';
import RedirectingScreen from '../scenes/RedirectingScreen';
import { nanoid } from 'nanoid/non-secure';
import EulaUpdatedWebDialog from '../../Auth/containers/EulaUpdatedWebDialog';
import {
  trackAppLaunch,
  trackColorScheme,
  trackScreenOpen,
} from '../../../shared/utilities/answers';
import useThemeContext from '../../../design/styles/useThemeContext';
import { getConfigForInstitution } from '../../Settings/actions';

const AnytimeNavigator = React.lazy(() => {
  return import(
    /* webpackChunkName: "AnytimeNavigator" */ './AnytimeNavigator'
  );
});

const LoginTokenOutdatedScene = React.lazy(() => {
  return import(
    /* webpackChunkName: "TokenOutdated" */ '../../Auth/scenes/LoginTokenOutdatedScene'
  );
});
const AuthNavigator = React.lazy(() => {
  return import(
    /* webpackChunkName: "Authentication", webpackPrefetch: true */ './AuthNavigator'
  );
});
const WebNavigator = React.lazy(() => {
  return import(
    /* webpackChunkName: "MainScreens", webpackPrefetch: true */ './WebNavigator'
  );
});
const CommonNavigator = React.lazy(() => {
  return import(/* webpackChunkName: "SecondaryScreens" */ './CommonNavigator');
});

const prefix = 'http://localhost:3000/';
const commonScreens = {
  main: './',
  createRecord: 'records/create',
  editRecord: 'records/edit/:id',
  createReflection: 'reflections/create',
  editReflection: 'reflections/edit/:id',

  addPainCondition: 'conditions/add',
  editPainCondition: 'conditions/edit/:id',
  searchPainCondition: 'conditions/search',
  editPainConditionsList: 'conditions/list',

  addMedication: 'medications/add',
  editMedication: 'medications/edit/:id',
  searchMedication: 'medications/search',
  editMedicationsList: 'medications/list',

  editList: 'fields/edit/:field',
  subscriptionSettings: 'subscriptions-preferences',
  downloadReport: 'report/:report_uuid',
  downloadReportOld: 'user/:userId/report/:report_oldid',

  createReport: 'reports/create',
  reportOverlays: 'reports/overlays',
};

const config = {
  screens: {
    anytime: {
      screens: {
        'forgot-password': 'forgot-password',
        eulaFrame: 'eula',
        privacyPolicy: 'privacy-policy',
        contactUs: 'contact-us',
        logout: 'logout',
      },
    },
    auth: {
      screens: {
        login: '*',
        register: 'register',
        activateAccount: 'activate-user/:token',
        verifyEmail: 'change-email/:token',
        passwordReset: 'reset-password/:token',
        // downloadReport: 'report/:report_uuid',
        unsubscribeEmail: 'unsubscribe/:token',
        expiredLink: 'expired-link',
        landing: '/',
      },
    },
    home: {
      screens: {
        editList: 'fields/editlist/:field',
        test: 'testScreen',
        addField: 'fields/add/:field',
        insights: 'results/insights',
        charts: 'results/charts',
        timeline: 'results/timeline',
        records: 'results/records',
        calendar: 'results/calendar',
        reports: 'reports/list',

        painGuideHome: 'guide/home',
        painGuideCategories: 'guide/categories/:category_id?',
        painGuideContent: 'guide/content/:id/:category_id?',
        painGuideBookmarked: 'guide/bookmarked',
        painGuideProgram: 'guide/program/:program_id',

        userGuide: 'user-guide',
        faq: 'faq',

        myProfile: 'profile',
        settings: 'settings',
        questionnaires:
          'questionnaires_page/:institution_id?/:questionnaire_id?/:responses_mode?',
      },
    },
    common: {
      screens: {
        ...commonScreens,
      },
    },
  },
};

const Stack = createStackNavigator();

export default function App() {
  const routeNameRef = React.useRef();
  let institution_id = window?.__institution_id;

  const { colors, colorTheme } = useThemeContext();
  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();

  const web_token_outdated = useSelector(
    (state) => !!state.auth?.web_token_outdated,
  );
  const language = useSelector((state) => state.settings?.language);
  const loggedIn = useSelector(
    (state) => !!state.auth?.navigate_to_main_screen,
  );
  const token = useSelector((state) => !!state.auth?.user?.token);
  const [webRedirectUrl, setWebRedirectUrl] = useState(null);
  const [institutionTranslations, setInstitutionTranslations] = useState(
    getInstitutionTranslations(),
  );
  const [navigationCompleted, setNavigationCompleted] = useState(false);
  const [appNotSetup, setAppNotSetup] = useState(false);

  const introViewed = useSelector(
    (state) => state.intro.wizardSecondStepViewed,
  );

  const currentEulaVersion = useSelector(
    (state) => state.profile.content?.eula_version,
  );
  const disableIdleTimer = useSelector(
    (state) => state.settings.disable_idle_timer,
  );
  const appEulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));

  const eulaOutdated = currentEulaVersion < appEulaVersion;

  const onIdle = () => {
    //Only outdate token if we already logged in
    if (appNotSetup || !initialized || web_token_outdated) {
      return;
    }
    if (loggedIn) {
      dispatch(webTokenOutdated());
    }
  };

  const colorScheme = useColorScheme();

  useEffect(() => {
    trackColorScheme(colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--default-window-bg',
      colors.defaultWindowBg,
    );
    document.documentElement.style.setProperty(
      '--drawer-logo-color',
      colors.textPrimary,
    );
  }, [colorTheme, colors]);
  useEffect(() => {
    if (institution_id) {
      dispatch(getConfigForInstitution(institution_id));
    }
  }, [dispatch, institution_id]);

  //Track app launch event only once when app starts
  React.useEffect(() => {
    trackAppLaunch('web');
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(keepAlive());
    } else {
      stopKeepAlive();
    }
  }, [dispatch, token]);

  useEffect(() => {
    (async () => {
      // If user opens app not with "auth" screens,
      // then we need to redirect user to that screen after he logs in
      if (navigationCompleted && webRedirectUrl !== 'handled') {
        if (!loggedIn) {
          const initialUrl = await Linking.getInitialURL();
          const url = new URL(initialUrl);
          const searchParams = new URLSearchParams(url.search);

          //This adds support of opening screen directly with "redirect" GET param
          const redirectPath = searchParams.get('redirect');
          const path = new URL(initialUrl).pathname;

          const currentState = getStateFromPath(path, config);
          const isAuthOrAnytime =
            !currentState ||
            currentState.routes[0].name === 'auth' ||
            currentState.routes[0].name === 'anytime';
          //If we have redirect param, then redirect user to this route
          if (redirectPath) {
            setWebRedirectUrl(redirectPath);
          } else {
            //Else we should check that opened screen not from "auth" ones
            //If so, then redirect user immediately to login screen (instead of landing one)
            //And store "webRedirectUrl"
            if (!isAuthOrAnytime) {
              const path = url.pathname;
              setWebRedirectUrl(path);
              const newState = getStateFromPath(
                '/login?redirect=' + path,
                config,
              );
              navigationRef.current.reset(newState);
            }
          }
        } else if (webRedirectUrl) {
          // After user actually logs in, we need to check stored webRedirectUrl
          // extract "navigation action" from given path, and lead user to that path
          const path = webRedirectUrl;
          const state = getStateFromPath(path, config);
          const action = getActionFromState(state, config);
          if (action !== undefined) {
            navigationRef?.current.dispatch(action);
          } else {
            navigationRef?.current.reset(state);
          }
          // linkTo('/common/subscriptions');
          // window.location.href = webRedirectUrl;
          setWebRedirectUrl('handled');
        }
      }
    })();
  }, [loggedIn, navigationCompleted, webRedirectUrl]);

  let timeout = getConfig('MMP_IDLE_TIMEOUT');
  const idleTimer = useIdleTimer({
    onIdle,
    timeout,
    disabled: disableIdleTimer,
  });

  if (appNotSetup) {
    return <RedirectingScreen />;
  }

  if (institution_id && !institutionTranslations) {
    loadTranslations(institution_id, (result) => {
      if (result.error_translations) {
        setAppNotSetup(true);
        return;
      }
      setInstitutionTranslations(result);
    });
    return null;
  }

  if (!initialized) {
    return <SplashScene onInitialized={() => setInitialized(true)} />;
  }

  if (web_token_outdated) {
    return <LoginTokenOutdatedScene />;
  }

  const linking = {
    prefixes: [prefix],
    config,
    getStateFromPath: (path, options) => {
      let state = getStateFromPath(path, options);
      return state;
    },
    getPathFromState: (state, config) => {
      let path = getPathFromState(state, config);
      return path;
    },
  };

  // const BugsnagPerformanceNavigationContainer = BugsnagPerformance.getPlugin(
  //   BugsnagPluginReactNavigationNativePerformance,
  // ).createNavigationContainer(NavigationContainer);
  // const BugsnagNavigationContainer = Bugsnag.getPlugin(
  //   'reactNavigation',
  // ).createNavigationContainer(BugsnagPerformanceNavigationContainer);

  return (
    <Suspense fallback={<SplashView />}>
      <NavigationContainer
        linking={linking}
        ref={(ref) => {
          navigationRef.current = ref;
          setNavigationCompleted(true);
        }}
        onReady={() => {
          // We should not use tracking here, as on web onStateChange is called
          // even on first launch, so it will give incorrect results
          //const currentRouteName = navigationRef.current.getCurrentRoute().name;
          //trackScreenOpen(currentRouteName);
        }}
        onStateChange={(state) => {
          const previousRouteName = routeNameRef.current;

          //Params not updated if navigated through deep link initially
          //Bug here: https://github.com/react-navigation/react-navigation/issues/10046
          //So we need to update path manually for questionnaires to be working
          const path = getPathFromState(state, config);
          if (path.startsWith('/questionnaires_page')) {
            const id = window.history.state?.id ?? nanoid();
            window.history.replaceState({ id }, '', path);
          }
          const currentRouteName =
            navigationRef.current?.getCurrentRoute().name;
          console.log('TRACKCHANGED', currentRouteName);
          if (previousRouteName !== currentRouteName) {
            trackScreenOpen(currentRouteName);
          }
          dispatch(setCurrentRoute(currentRouteName));
          routeNameRef.current = currentRouteName;
        }}
      >
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
        >
          {designConstants.isWeb &&
          (!loggedIn || !token || web_token_outdated) ? (
            <>
              <Stack.Screen name={'auth'} component={AuthNavigator} />
              <Stack.Screen name={'anytime'} component={AnytimeNavigator} />
            </>
          ) : (
            <>
              <Stack.Screen name={'home'} component={WebNavigator} />
              <Stack.Screen name={'common'} component={CommonNavigator} />
              <Stack.Screen name={'anytime'} component={AnytimeNavigator} />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
      {eulaOutdated && <EulaUpdatedWebDialog />}
    </Suspense>
  );
}
