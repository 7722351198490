export const CHECK_OVERLAY = 'CHECK_OVERLAY';
export const RESET_OVERLAY = 'RESET_OVERLAY';

// This file serves every action which could be performed by both Views and Logic
export const PREPARE_PAIN_RECORD = 'PREPARE_NEW_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const CHECK_FIELD = 'CHECK_FIELD';
export const CHECK_NOTHING = 'CHECK_NOTHING';
export const CHECK_FACTOR = 'CHECK_FACTOR';
export const UNCHECK_FACTOR_FIELD = 'UNCHECK_FACTOR_FIELD';
export const CHECK_MEDICATION = 'CHECK_MEDICATION';
export const CHANGE_CURRENT_MEDICATION_TYPE = 'CHANGE_CURRENT_MEDICATION_TYPE';
export const CHANGE_CURRENT_DOSAGE = 'CHANGE_CURRENT_DOSAGE';
export const CHANGE_CURRENT_DOSAGE_UNITS = 'CHANGE_CURRENT_DOSAGE_UNITS';
export const SET_SEVERITY = 'SET_SEVERITY';
export const SET_TIME_OFFSET = 'SET_TIME_OFFSET';
export const SET_RECORD_TIME = 'SET_RECORD_TIME';
export const SET_NOTE = 'SET_NOTE';
export const SET_PAIN_TYPE = 'SET_PAIN_TYPE';
export const SET_DURATION = 'SET_DURATION';
export const SET_DURATION_UNITS = 'SET_DURATION_UNITS';
export const SET_DURATION_TYPE = 'SET_DURATION_TYPE';
export const SET_ALLDAY_CHECKED = 'SET_ALLDAY_CHECKED';
export const SAVE_RECORD_SUCCESS = 'SAVE_RECORD_SUCCESS';
export const SAVE_DELETED_RECORD_SUCCESS = 'SAVE_DELETED_RECORD_SUCCESS';
export const SAVE_DAILY_REFLECTION_SUCCESS = 'SAVE_DAILY_REFLECTION_SUCCESS';

// Unsync medication handling for records
export const DELETE_OFFLINE_MEDICATION_FROM_RECORDS =
  'DELETE_OFFLINE_MEDICATION_FROM_RECORDS';

//Reflection types
export const SET_REFLECTION_NOTE = 'SET_REFLECTION_NOTE';
export const SET_REFLECTION_TIME = 'SET_REFLECTION_TIME';
export const SET_REFLECTION_SCORE = 'SET_REFLECTION_SCORE';

export const LOGOUT = 'LOGOUT';

//Sync types
export const SYNC_RECORDS_SUCCESS = 'SYNC_RECORDS_SUCCESS';
export const SYNC_RECORDS_CLEAR_FLAGS = 'SYNC_RECORDS_CLEAR_FLAGS';

//report types
export const REPORT_UPDATE_CALENDAR_OVERLAY = 'REPORT_UPDATE_CALENDAR_OVERLAY';

export const REMOVE_JUST_DELETED_MEDICATION_FROM_CURRENT_RECORD =
  'REMOVE_JUST_DELETED_MEDICATION_FROM_CURRENT_RECORD';

export const UPDATE_1806_ADD_LOCALLY_UPDATED =
  'UPDATE_1806_ADD_LOCALLY_UPDATED';
