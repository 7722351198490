import React from 'react';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import { designConstants } from '../styles';
import useThemeContext from '../styles/useThemeContext';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function Container(props) {
  const { children, style, fullHeight, bottomBar, ...otherProps } = props;
  const { colors } = useThemeContext();
  const { height } = useWindowDimensions();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const insets = Platform.OS !== 'web' && bottomBar ? useSafeAreaInsets() : {};
  return (
    <View
      {...otherProps}
      style={[
        styles.container,
        { backgroundColor: colors.defaultWindowBg },
        style,
      ]}
    >
      <View
        style={[
          {
            flex: 1,
          },
          fullHeight && {
            height: designConstants.isWeb
              ? '100dvh'
              : bottomBar
                ? undefined
                : height,
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
});
