import React, { useState } from 'react';
import {
  Container,
  Content,
  Header,
  HeaderIcon,
  Icon,
  LoadingOverlay,
  Text,
  TouchableNative,
} from '../../../design/components';
import sIntro from '../../Intro/strings';
import { designConstants } from '../../../design/styles';
import EulaUpdatedForm from './EulaUpdatedForm';
import Modal from 'react-native-modal';
import { View } from 'react-native';
import Alert from '../../../design/components/Alert';
import strings from '../strings';
import { logout, updateEulaVersion } from '../actions';
import ErrorDialog from '../../../shared/components/ErrorDialog';
import { useDispatch } from 'react-redux';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import EulaModal from '../components/EulaModal';
import useThemeContext from '../../../design/styles/useThemeContext';

export default function EulaUpdatedWebDialog(props) {
  const dispatch = useDispatch();
  const { colors } = useThemeContext();
  const [loading, setLoading] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showEula, setShowEula] = useState(false);

  const onCancel = () => {
    Alert.alert(
      strings.eula_updated_cancel_dialog_title,
      strings.eula_updated_cancel_dialog_text,
      [
        {
          text: strings.eula_updated_cancel_dialog_yes_button,
          onPress: () => {
            dispatch(logout(true));
          },
        },
        {
          text: strings.eula_updated_cancel_dialog_no_button,
          onPress: () => {},
        },
      ],
    );
  };

  const onAccept = () => {
    setLoading(true);
    dispatch(updateEulaVersion())
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        ErrorDialog(null, e);
        setLoading(false);
      });
  };

  const isTablet = designConstants.useIsTablet();

  if (!isTablet) {
    return (
      <Container>
        <Header>
          <HeaderIcon icon={'close'} onPress={onCancel} />
          <Text headerTitle>{sIntro.eula_title}</Text>
        </Header>
        <Content
          grow
          keyboardShouldPersistTaps="always"
          contentContainerStyle={{
            alignSelf: 'stretch',
            backgroundColor: colors.defaultBackground,
            padding: 20,
          }}
        >
          <EulaUpdatedForm
            onContinue={onAccept}
            onEulaShow={() => setShowEula(true)}
            onPrivacyPolicyShow={() => setShowPrivacyPolicy(true)}
          />
        </Content>
        <EulaModal
          open={showEula}
          onAccept={() => {
            setShowEula(false);
          }}
        />
        <PrivacyPolicyModal
          open={showPrivacyPolicy}
          onAccept={() => {
            setShowPrivacyPolicy(false);
          }}
        />
        {loading && <LoadingOverlay visible={true} />}
      </Container>
    );
  } else {
    if (loading) {
      return <LoadingOverlay visible={true} />;
    }
    if (showEula) {
      return (
        <EulaModal
          open={true}
          onAccept={() => {
            setShowEula(false);
          }}
        />
      );
    }
    if (showPrivacyPolicy) {
      return (
        <PrivacyPolicyModal
          open={true}
          onAccept={() => {
            setShowPrivacyPolicy(false);
          }}
        />
      );
    }
    return (
      <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        backdropColor={'#444444'}
        backdropOpacity={0.5}
        avoidKeyboard={true}
        onBackButtonPress={onCancel}
        onBackdropPress={onCancel}
        isVisible={true}
        style={{ alignItems: 'center' }}
      >
        <View
          style={{
            backgroundColor: colors.defaultBackground,
            borderRadius: 8,
            maxWidth: 370,
            paddingHorizontal: 20,
            paddingVertical: 30,
          }}
        >
          <EulaUpdatedForm
            onContinue={onAccept}
            onEulaShow={() => setShowEula(true)}
            onPrivacyPolicyShow={() => setShowPrivacyPolicy(true)}
          />
          <TouchableNative
            style={{
              alignSelf: 'flex-end',
              right: 20,
              top: 30,
              position: 'absolute',
            }}
            onPress={onCancel}
          >
            <Icon name={'close'} />
          </TouchableNative>
        </View>
      </Modal>
    );
  }
}
