// @flow
import { KEEP_ALIVE } from './actiontypes';

type State = {
  keep_alive_timeout: number,
};
function ApiReducer(state: State = { keep_alive_timeout: 0 }, action) {
  switch (action.type) {
    case KEEP_ALIVE:
      return {
        ...state,
        keep_alive_timeout: action.payload,
      };
    default:
      return state;
  }
}

export { ApiReducer };
