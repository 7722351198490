import {
  CARD_HIDE,
  PAIN_GUIDE_HIDE_ENGLISH,
  PAIN_GUIDE_IGNORE_ENGLISH,
  PAIN_GUIDE_INTRODUCED,
  QUESTIONNAIRES_DELAY,
  RATE_ME_DELAY,
  RATE_ME_RATED,
} from './actiontypes';
import {
  SAVE_DAILY_REFLECTION_SUCCESS,
  SAVE_RECORD_SUCCESS,
} from '../Records/actiontypes';
import { GET_MY_PROFILE_SUCCESS } from '../Profile/actiontypes';
import { Platform } from 'react-native';
import getConfig from '../../shared/utilities/getConfig';
import { trackRateAppMessageShown } from '../../shared/utilities/answers';
import { REGISTER_SUCCESS, SET_USER } from '../Auth/actiontypes';
import { SYNC_SUCCESS } from '../Sync/actiontypes';

export const RECENT_RECORDS_CARD = 'RECENT_RECORDS_CARD';
export const PAIN_CONDITIONS_CARD = 'PAIN_CONDITIONS_CARD';
export const MEDICATIONS_CARD = 'MEDICATIONS_CARD';
export const RATE_CARD = 'RATE_CARD';
export const SIGNUP_CARD = 'SIGNUP_CARD';
export const QUESTIONNAIRES_CARD = 'QUESTIONNAIRES_CARD';
export const PAIN_GUIDE_CARD = 'PAIN_GUIDE_CARD';

const INITIAL = {
  cards: sortCards([
    { type: PAIN_CONDITIONS_CARD, priority: 60, count: 2, canHide: true },
    { type: MEDICATIONS_CARD, priority: 50, count: 2, canHide: true },
    { type: RECENT_RECORDS_CARD, priority: 90, count: 2 },
    { type: RATE_CARD, priority: 100, canHide: true },
    { type: SIGNUP_CARD, priority: 110, canHide: true },
    { type: QUESTIONNAIRES_CARD, priority: 120, canHide: true },
    { type: PAIN_GUIDE_CARD, priority: 115, canHide: true },
  ]),
  pain_guide_introduced: false,
  pain_guide_already_synced: false,
};

function sortCards(cards) {
  return cards.sort((a, b) => {
    return a.priority - b.priority;
  });
}

function FeedReducer(state = { ...INITIAL }, action) {
  switch (action.type) {
    case SYNC_SUCCESS: {
      //If user already synced before, then we should show "pain_guide_introduced" once user receives it
      return {
        ...state,
        pain_guide_already_synced: true,
      };
    }
    case SET_USER: {
      //If user already synced before and receives "pain_guide_available" flag
      const pain_guide_introduced =
        (!state.pain_guide_already_synced &&
          action.payload.pain_guide_available) ||
        state.pain_guide_introduced;
      return {
        ...state,
        pain_guide_introduced,
      };
    }
    case PAIN_GUIDE_INTRODUCED:
      return {
        ...state,
        pain_guide_introduced: true,
      };
    case PAIN_GUIDE_HIDE_ENGLISH:
      return {
        ...state,
        pain_guide_hide: true,
      };
    case PAIN_GUIDE_IGNORE_ENGLISH:
      return {
        ...state,
        pain_guide_ignore: true,
      };
    case CARD_HIDE:
    case RATE_ME_RATED:
      let cards = state.cards.slice();
      let card = cards.find((c) => c.type === action.payload);
      let newCard = { ...card, hidden: !card.hidden };
      cards.splice(cards.indexOf(card), 1, newCard);
      return {
        ...state,
        cards,
      };
    case RATE_ME_DELAY:
    case SAVE_RECORD_SUCCESS:
    case SAVE_DAILY_REFLECTION_SUCCESS:
      if (action.payload.id !== -1) {
        return state;
      }
      let rateCardIndex = state.cards.findIndex((c) => c.type === RATE_CARD);
      let rateCard = state.cards[rateCardIndex];
      if (!rateCard) {
        return state;
      }
      if (!rateCard.recordsAdded) {
        rateCard.recordsAdded = 0;
      }
      let signUpCardIndex = state.cards.findIndex(
        (c) => c.type === SIGNUP_CARD,
      );
      let signUpCard = state.cards[signUpCardIndex];
      if (!signUpCard) {
        return state;
      }
      if (!signUpCard.recordsAdded) {
        signUpCard.recordsAdded = 0;
      }
      if (action.type === RATE_ME_DELAY) {
        rateCard.recordsAdded = 0;
        rateCard.delayed = 1;
      } else {
        rateCard.recordsAdded++;
        signUpCard.recordsAdded++;
      }
      const toShow = getConfig('MMP_RATE_ME_RECORDS_ADDED_TO_SHOW');
      if (rateCard.recordsAdded === toShow) {
        trackRateAppMessageShown();
      }
      let newCards = state.cards.slice();
      newCards.splice(rateCardIndex, 1, { ...rateCard });
      newCards.splice(signUpCardIndex, 1, { ...signUpCard });
      return {
        ...state,
        cards: newCards,
      };
    case QUESTIONNAIRES_DELAY: {
      let cards = state.cards.slice();
      let card = cards.find((c) => c.type === QUESTIONNAIRES_CARD);
      let newCard = { ...card, delayTime: action.payload };
      cards.splice(cards.indexOf(card), 1, newCard);
      return {
        ...state,
        cards,
      };
    }
    case REGISTER_SUCCESS: {
      let cards = state.cards.slice();
      let card = cards.find((c) => c.type === SIGNUP_CARD);
      let newCard = { ...card, hidden: true };
      cards.splice(cards.indexOf(card), 1, newCard);
      return {
        ...state,
        cards,
      };
    }
    case GET_MY_PROFILE_SUCCESS:
      let cards1 = state.cards.slice();
      let card1 = cards1.find((c) => c.type === RATE_CARD);
      let hidden = false;
      if (Platform.OS === 'android') {
        if (action.payload.content.android_rn_rated) {
          hidden = true;
        }
      } else {
        if (action.payload.content.apple_rn_rated) {
          hidden = true;
        }
      }
      let newCard1 = { ...card1, hidden };
      cards1.splice(cards1.indexOf(card1), 1, newCard1);
      return {
        ...state,
        cards: cards1,
      };
    default:
      return state;
  }
}

export { FeedReducer };
