import * as types from './actiontypes';
import {
  ADD_OFFLINE_MEDICATION_TO_PROFILE,
  ADD_OFFLINE_PAIN_CONDITION_TO_PROFILE,
  DELETE_OFFLINE_MEDICATION_FROM_PROFILE,
  DELETE_OFFLINE_PAIN_CONDITION_FROM_PROFILE,
  EDIT_OFFLINE_MEDICATION_IN_PROFILE,
  EDIT_OFFLINE_PAIN_CONDITION_IN_PROFILE,
  TOGGLE_SHOW_OFFLINE_PAIN_CONDITION_IN_PROFILE,
} from './actiontypes';
import { apiError, checkToken } from '../Api/actions';
import { Platform } from 'react-native';
import strings from '../Api/strings';
import { EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE } from '../Meds/actiontypes';
import { designConstants } from '../../design/styles';

import * as MMPApi from 'apiclient';
import { getLang } from '../../shared/utilities/getLang';

export function saveMyProfile(profile) {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.SAVE_MY_PROFILE,
      });

      let api = new MMPApi.RecordCallsApi();
      profile = { ...profile };
      profile.content = { ...profile.content };
      let record = MMPApi.ApiRecordObject.constructFromObject(profile);
      delete record.content.medications;
      delete record.content.condition;
      return api.syncUpdateRecordPost(getLang(), record).then(
        (result) => {
          console.log('profile save success!!!');
          dispatch({
            type: types.SAVE_MY_PROFILE_SUCCESS,
            payload: result,
          });
          return Promise.resolve();
        },
        (error) => {
          console.log('profile save success!!! error');
          return dispatch(apiError(error, types.SAVE_MY_PROFILE));
        },
      );
    });
  };
}

export function uploadAvatar(imageBase64) {
  return (dispatch) => {
    return checkToken().then(() => {
      var api = new MMPApi.ImageCallsApi();
      var params = MMPApi.UploadAvatarObject.constructFromObject({
        image: imageBase64,
      });
      return api.syncUploadAvatarPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.UPLOAD_AVATAR,
            payload: result,
          });
          return Promise.resolve(result);
        },
        (error) => {
          return dispatch(apiError(error, types.UPLOAD_AVATAR));
        },
      );
    });
  };
}

export function setRated() {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.GET_MY_PROFILE,
      });
      var api = new MMPApi.RecordCallsApi();
      var params = MMPApi.GetRecordParams.constructFromObject({
        type: 'user_profile_record',
      });
      return api.syncGetRecordPost(getLang(), params).then(
        (result) => {
          if (result && result.content) {
            if (Platform.OS === 'android') {
              result.content.android_rn_rated = 1;
            } else {
              result.content.apple_rn_rated = 1;
            }
          }
          return dispatch(saveMyProfile(result));
        },
        (error) => {
          let err = apiError(error, types.GET_MY_PROFILE, updateMyProfile);
          return dispatch(err);
        },
      );
    });
  };
}

export function getMyProfile(token, token_type) {
  let defaultClient = MMPApi.ApiClient.instance;
  defaultClient.defaultHeaders = {};
  defaultClient.authentications = {
    token: {
      type: 'apiKey',
      in: 'header',
      name: 'Authorization',
      apiKeyPrefix: token_type,
      apiKey: token,
    },
  };
  var api = new MMPApi.RecordCallsApi();
  var params = MMPApi.GetRecordParams.constructFromObject({
    type: 'user_profile_record',
  });

  return api.syncGetRecordPost(getLang(), params).then((result) => {
    // defaultClient.authentications = null;
    return Promise.resolve(result);
  });
}

export function updateMyProfile() {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.GET_MY_PROFILE,
      });
      var api = new MMPApi.RecordCallsApi();
      var params = MMPApi.GetRecordParams.constructFromObject({
        type: 'user_profile_record',
      });

      return api.syncGetRecordPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.GET_MY_PROFILE_SUCCESS,
            payload: result,
          });
          return Promise.resolve(result);
        },

        (error) => {
          let err = apiError(error, types.GET_MY_PROFILE, updateMyProfile);
          return dispatch(err);
        },
      );
    });
  };
}

export function setProfileName(name) {
  return {
    type: types.SET_DISPLAY_NAME,
    payload: name,
  };
}

export function setProfileGender(value) {
  if (!value && value !== null) {
    value = 'o';
  }
  return {
    type: types.SET_GENDER,
    payload: value,
  };
}

export function setProfileCountry(value) {
  return {
    type: types.SET_COUNTRY,
    payload: value,
  };
}

export function setProfileHeight(value) {
  return {
    type: types.SET_HEIGHT,
    payload: value,
  };
}

export function setProfileWeight(value) {
  return {
    type: types.SET_WEIGHT,
    payload: value,
  };
}

export function setProfileHeightUnits(value) {
  return {
    type: types.SET_HEIGHT_UNITS,
    payload: value,
  };
}

export function setProfileBirthday(value) {
  return {
    type: types.SET_BIRTHDAY,
    payload: value,
  };
}

export function setProfileWeightUnits(value) {
  return {
    type: types.SET_WEIGHT_UNITS,
    payload: value,
  };
}

export function searchMedication(text) {
  if (false) {
    return {
      type: types.SEARCH_MEDICATION_SUCCESS,
      payload: [
        ['med1', 'generic1'],
        ['med1', 'generic1'],
        [text, 'generic + text'],
      ],
    };
  }

  if (text === null) {
    return {
      type: types.SEARCH_MEDICATION_SUCCESS,
      payload: [],
    };
  }
  return (dispatch) => {
    //DO NOT UNCOMMENT checkToken, since we are able to search medication even offline
    //return checkToken().then(() => {
    dispatch({
      type: types.SEARCH_MEDICATION_REQUEST,
    });
    let api = new MMPApi.SearchApi();
    let params = MMPApi.FindMedicationParams.constructFromObject({
      value: text,
    });
    return api.coreFindMedicationPost(getLang(), params).then(
      (result) => {
        dispatch({
          type: types.SEARCH_MEDICATION_SUCCESS,
          payload: result,
        });
        return Promise.resolve();
      },
      (error) => {
        let err = apiError(error, types.SEARCH_MEDICATION_FAILURE);
        return dispatch(err);
      },
    );
    // });
    //});
  };
}

export function searchPainCondition(text) {
  if (text === null) {
    return {
      type: types.SEARCH_CONDITION_SUCCESS,
      payload: [],
    };
  }
  return (dispatch) => {
    //DO NOT UNCOMMENT checkToken, since we are able to search condition even offline
    // return checkToken().then(() => {
    dispatch({
      type: types.SEARCH_CONDITION_REQUEST,
    });
    let api = new MMPApi.SearchApi();
    let params = MMPApi.FindConditionParams.constructFromObject({
      value: text,
    });
    return api.coreFindConditionPost(getLang(), params).then(
      (result) => {
        dispatch({
          type: types.SEARCH_CONDITION_SUCCESS,
          payload: result,
        });
        return Promise.resolve(result);
      },
      (error) => {
        let errText =
          error && error.response
            ? error.response.body || error.response.text
            : strings.error_in_data_connection;
        let err = apiError(error, types.SEARCH_CONDITION_FAILURE);
        dispatch({
          type: types.SEARCH_CONDITION_FAILURE,
          payload: errText,
        });
        return dispatch(err);
      },
    );
    // });
  };
}

export function addUnsyncMedicationToProfile(medication) {
  return {
    type: types.ADD_UNSYNC_MEDICATION_TO_PROFILE,
    payload: medication,
  };
}

export function addUnsyncMedicationsArrayToProfile(medications) {
  return {
    type: types.ADD_UNSYNC_MEDICATIONS_ARRAY_TO_PROFILE,
    payload: medications,
  };
}

export function addUnsyncPainConditionToProfile(painCondition) {
  return {
    type: types.ADD_UNSYNC_PAIN_CONDITION_TO_PROFILE,
    payload: painCondition,
  };
}

export function addUnsyncPainConditionsArrayToProfile(painConditions) {
  return {
    type: types.ADD_UNSYNC_PAIN_CONDITIONS_ARRAY_TO_PROFILE,
    payload: painConditions,
  };
}

export function editUnsyncPainCondition({
  fid,
  value,
  diagnosed,
  first_symptom,
  new_created,
  type,
}) {
  return {
    type: types.EDIT_UNSYNC_PAIN_CONDITION,
    payload: { fid, value, diagnosed, first_symptom, new_created, type },
  };
}

export function deleteUnsyncPainCondition(fid) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_UNSYNC_PAIN_CONDITION,
      payload: fid,
    });
    dispatch({
      type: EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE,
      payload: fid,
    });
  };
}

export function toggleShowOfUnsyncPainCondition(fid) {
  return {
    type: types.TOGGLE_SHOW_OF_UNSYNC_PAIN_CONDITION,
    payload: fid,
  };
}

export function editUnsyncMedicationInProfile(medication) {
  return {
    type: types.EDIT_UNSYNC_MEDICATION_IN_PROFILE,
    payload: medication,
  };
}

export function deleteUnsyncMedicationFromProfile(nid) {
  return {
    type: types.DELETE_UNSYNC_MEDICATION_FROM_PROFILE,
    payload: nid,
  };
}

export function addOfflinePainCondition(condition) {
  return {
    type: ADD_OFFLINE_PAIN_CONDITION_TO_PROFILE,
    payload: condition,
  };
}

export function editOfflinePainCondition(condition) {
  return {
    type: EDIT_OFFLINE_PAIN_CONDITION_IN_PROFILE,
    payload: condition,
  };
}

export function toggleShowOfflinePainCondition(fid) {
  return {
    type: TOGGLE_SHOW_OFFLINE_PAIN_CONDITION_IN_PROFILE,
    payload: fid,
  };
}

export function deleteOfflinePainCondition(fid) {
  return {
    type: DELETE_OFFLINE_PAIN_CONDITION_FROM_PROFILE,
    payload: fid,
  };
}

export function addOfflineMedication(medication) {
  return {
    type: ADD_OFFLINE_MEDICATION_TO_PROFILE,
    payload: medication,
  };
}

export function editOfflineMedication(medication) {
  return {
    type: EDIT_OFFLINE_MEDICATION_IN_PROFILE,
    payload: medication,
  };
}

export function deleteOfflineMedication(nid) {
  return {
    type: DELETE_OFFLINE_MEDICATION_FROM_PROFILE,
    payload: nid,
  };
}

export function removeNetworkErrorForPainConditionsAndMedications() {
  return {
    type: types.REMOVE_NETWORK_ERROR_FOR_PAIN_CONDITIONS_AND_MEDICATIONS,
  };
}

export function selectPhotoFromProvider(provider, access_token) {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.SELECT_PHOTO_FROM_PROVIDER_REQUEST,
      });
      const api = new MMPApi.ImageCallsApi();
      let params = MMPApi.UpdateAvatarFromProviderParams.constructFromObject({
        client_type: 'web',
        provider,
      });
      if (access_token) {
        params = MMPApi.UpdateAvatarFromProviderParams.constructFromObject({
          provider,
          access_token,
          client_type: designConstants.isWeb ? 'web' : undefined,
        });
      }

      return api
        .syncUpdateAvatarFromProviderPost(getLang(), params)
        .then((data) => {
          dispatch({
            type: types.SELECT_PHOTO_FROM_PROVIDER_SUCCESS,
            payload: {
              provider: data.provider,
              url: data.url,
              updated_at: data.updated_at,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: types.SELECT_PHOTO_FROM_PROVIDER_FAILURE,
          });
          return Promise.reject(error);
        });
    });
  };
}

export function removePhoto() {
  return (dispatch) => {
    return checkToken().then(() => {
      const api = new MMPApi.ImageCallsApi();

      return api
        .syncRemoveAvatarPost()
        .then((data) => {
          dispatch({
            type: types.REMOVE_PHOTO_SUCCESS,
          });
          return Promise.resolve();
        })
        .catch((error) => {
          let err = apiError(error, types.REMOVE_PHOTO_REQUEST, removePhoto);
          dispatch({
            type: types.REMOVE_PHOTO_FAILURE,
          });
          return dispatch(err);
        });
    });
  };
}
