import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = ['overlays_screen_overlays_selected_text'];

const STRINGS = new LocalizedStrings({
  en: {
    // Report screen
    // containers/ReportsTabView.js
    reports_screen_header: 'Reports',
    reports_tab_view_create_new_report_tab_text: 'Generate report',
    reports_tab_view_previous_tab_text: 'Previous reports',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: 'Generated on {0} at {1}',
    previous_reports_sections_included_title: 'Sections included:',
    previous_reports_sections_included_summary: 'Pain Summary',
    previous_reports_sections_included_profile: 'Profile',
    previous_reports_sections_included_records: 'Record Details',
    previous_reports_sections_included_charts: 'Charts',
    previous_reports_sections_included_timeline: 'Timeline',
    previous_reports_sections_included_calendar: 'Calendar',
    previous_reports_download_this_report_button: 'Download This Report',
    previous_reports_date_range: 'Date range: {0} to {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: 'Generate Report',

    report_screen_offline_title: 'Generate and download reports.',
    report_screen_offline_text:
      'Register an account or login to enjoy report generation, data sync and many more features.',
    report_screen_offline_view_sample_report_button: 'View sample report',
    report_screen_offline_signup_button: shared('Register For Free', 'en'),
    report_screen_offline_login_text: shared('already_registered', 'en'),
    report_screen_offline_login_button: shared('Log In', 'en'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated: 'No reports were generated yet.',
    previous_reports_generate_first_report_text:
      'Generate your first report by clicking the button below.',
    previous_reports_keep_tracking:
      'Keep tracking to generate your first report.',
    previous_reports_generate_first_report_button: 'Generate your first report',
    previous_reports_add_pain_record: 'Add pain record',
    previous_reports_no_pain_records: 'No pain records available.',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: 'Credit(s)\nAvailable',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'en'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: 'Select sections to include',
    create_report_advanced_sections_header:
      'Select advanced sections to include',
    create_report_advanced_sections_subheader: '{0} credit needed',
    create_report_plan_text:
      'According to your plan, only 30 days will be included',
    create_report_plan_upgrade_button: shared('Upgrade', 'en'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'en'),
    create_report_daterange_reset_toast_ok: shared('OK', 'en'),
    create_report_credit_will_be_used: '1 credit will be used',
    create_report_loading_text: 'Generating report...',
    create_report_screen_header: 'Generate Report',
    create_report_filter_screen_header: shared('Filter Records', 'en'),
    create_report_generate_report_button: 'Generate Report',

    create_report_apple_set_password_title: shared('Password Not Set', 'en'),
    create_report_apple_set_password_message:
      'Please set a password before generating report, and try again',
    create_report_apple_set_password_set_button: shared('Set Password', 'en'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'en'),

    // Overlays screen
    overlays_screen_screen_header: 'Overlays',
    overlays_screen_reset_button: 'Reset Defaults',
    overlays_screen_reset_toast: 'Overlays reset to 6 most commonly used',
    overlays_screen_header: 'Select up to 6 overlays',
    overlays_screen_overlays_limit_error_toast_text:
      'Only 6 overlays can be selected',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'en',
    ),
    overlays_screen_apply_button: shared('Apply', 'en'),
    overlays_screen_overlays_selected_text_one: '{0}/6 overlays selected',
    overlays_screen_overlays_selected_text_other: '{0}/6 overlays selected',

    lite_warning_order_credits_button: shared('Order Credits', 'en'),
    lite_warning_upgrade_button: shared('Upgrade', 'en'),
    lite_warning_use_credit_button: 'Use Credit',
    lite_warning_with_credits:
      'A credit will be used to generate a report that includes more than the last 30 days.',
    lite_warning_without_credits:
      'A credit is needed to generate a report that includes more than the last 30 days.',
    lite_warning_section_with_credits:
      'A credit will be used to generate a report that includes advanced sections.',
    lite_warning_section_without_credits:
      'A credit is needed to generate a report that includes advanced sections.',

    report_drawer_pain_summary_header: 'Pain Summary',
    report_drawer_patient_profile_header: 'Patient Profile ',
    report_drawer_filter_summary_header: 'Filter Summary',
    report_drawer_record_details_header: 'Record Details',
    report_drawer_chart_view_header: 'Chart View',
    report_drawer_calendar_view_header: 'Calendar View',
    report_drawer_timeline_view_header: 'Timeline View',
    report_drawer_all_sections_subheader: 'All Sections',
    report_drawer_not_included_subheader: 'Not Included',
    report_drawer_sections_checked: '{0} out of {1} sections checked',
    report_drawer_change_values_included_subheader: 'Change values included',
    report_drawer_change_values_not_included_subheader:
      'Change values not included',

    pain_summary_change_values_label: 'Include change values',
    pain_summary_change_values_secondary_label:
      'Shows changes in current period compared to the previous period',
    pain_summary_change_values_disabled_error:
      'There are no records in the previous period to compare against',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'en'),

    calendar_section_include_following_overlays: 'Overlays displayed:',
    calendar_section_overlays_button: 'Select overlays',
    calendar_section_overlays_default: '6 most frequently used',
    calendar_section_overlays_above_button:
      'or manually select the overlays to display',

    charts_section_content_header: 'Include the following:',
    charts_section_content_locations: shared('Locations', 'en'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'en'),
    charts_section_content_character: shared('Characteristics', 'en'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'en',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'en',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'en',
    ),
    charts_section_content_environments: shared('Environments', 'en'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'en',
    ),

    patient_profile_section_content_header: 'Include the following:',
    patient_profile_section_content_name: shared('Name', 'en'),
    patient_profile_section_content_birthday: shared('Birth Date', 'en'),
    patient_profile_section_content_height: shared('Height', 'en'),
    patient_profile_section_content_weight: shared('Weight', 'en'),
    patient_profile_section_content_gender: shared('Gender', 'en'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'en',
    ),
    patient_profile_section_content_medications: shared('Medications', 'en'),

    record_details_content_pain_records_header: shared('Pain Records', 'en'),
    record_details_content_locations: shared('Locations', 'en'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'en'),
    record_details_content_character: shared('Characteristics', 'en'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'en',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'en',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'en',
    ),
    record_details_content_environments: shared('Environments', 'en'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'en',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'en',
    ),
    record_details_content_severity: 'Severity',
    record_details_content_notes: shared('Notes', 'en'),
    record_details_content_score: shared('Score', 'en'),
    record_details_content_reflection_notes: shared('Notes', 'en'),
    record_details_content_sort_by: shared('Sort By', 'en'),
    record_details_content_sort_by_duration: shared('Duration', 'en'),
    record_details_content_sort_by_severity: 'Severity / Severity',
    record_details_content_sort_by_date_created: 'Date Created',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'en'),
    record_details_content_sort_order: shared('Sort Order', 'en'),
    record_details_content_sort_order_ascending: shared('Ascending', 'en'),
    record_details_content_sort_order_descending: shared('Descending', 'en'),

    timeline_view_section_content_header: 'Include the following:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'en'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'en',
    ),

    report_success_text:
      'Report request received.\n\nYou will receive an email with a link to your report within an hour (please also check Spam/Junk folder)',
    report_w_credit_success_text:
      'Report request received, and a credit was required.\n\nYou will receive an email with a link to your report (please also check Spam/Junk folder) and your credit balance will be updated within an hour.',
    report_success_button: shared('OK', 'en'),

    report_subscription_expired_title: 'Subscription expired',
    report_subscription_expired_message:
      'Your subscription is invalid and a credit is needed to generate a report.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'en',
    ),
    report_subscription_expired_use_credit_button: 'Use Credit',
    report_subscription_expired_cancel_button: shared('Cancel', 'en'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'en'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'en',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'en',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'en',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'en',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'en',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'en',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'en',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'en',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'en',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'en',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'en',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'en',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'en',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'en',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'en',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'en',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'en',
    ),
  },

  de: {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: 'Berichte',
    reports_tab_view_create_new_report_tab_text: 'Bericht generieren',
    reports_tab_view_previous_tab_text: 'Frühere Berichte',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: 'Generiert am {0} um {1}',
    previous_reports_sections_included_title: 'Enthaltene Abschnitte:',
    previous_reports_sections_included_summary: 'Schmerzzusammenfassung',
    previous_reports_sections_included_profile: 'Profil',
    previous_reports_sections_included_records: 'Details zum Eintrag',
    previous_reports_sections_included_charts: 'Diagramme',
    previous_reports_sections_included_timeline: 'Zeitstrahl',
    previous_reports_sections_included_calendar: 'Kalender',
    previous_reports_download_this_report_button:
      'Diesen Bericht herunterladen',
    previous_reports_date_range: 'Zeitraum: {0} bis {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: 'Bericht erstellen',

    report_screen_offline_title: 'Berichte generieren und herunterladen.',
    report_screen_offline_text:
      'Registrieren Sie ein Konto oder loggen Sie sich ein, um Berichte zu erstellen, Daten zu synchronisieren und viele weitere Funktionen zu nutzen.',
    report_screen_offline_view_sample_report_button: 'Beispielbericht ansehen',
    report_screen_offline_signup_button: shared('Register For Free', 'de'),
    report_screen_offline_login_text: shared('already_registered', 'de'),
    report_screen_offline_login_button: shared('Log In', 'de'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated:
      'Es wurden noch keine Berichte erstellt.',
    previous_reports_generate_first_report_text:
      'Erzeugen Sie den ersten Bericht mit Klick auf den Button unten.',
    previous_reports_keep_tracking:
      'Erfassen Sie weiter, um Ihren ersten Bericht zu erzeugen.',
    previous_reports_generate_first_report_button:
      'Erzeugen Sie Ihren ersten Bericht',
    previous_reports_add_pain_record: 'Schmerzeintrag hinzufügen',
    previous_reports_no_pain_records: 'Keine Schmerzeinträge verfügbar.',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label:
      'Verfügbar(es)\nGuthabenpunkt(e)',
    credits_bottom_bar_order_credits_button: 'Guthabenpunkte\naufladen',

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header:
      'Wählen Sie die Abschnitte, die miteinbezogen werden sollen',
    create_report_advanced_sections_header:
      'Wählen Sie die erweiterten Abschnitte, die miteinbezogen werden sollen',
    create_report_advanced_sections_subheader: '{0} Guthabenpunkt erforderlich',
    create_report_plan_text:
      'Aufgrund der Art Ihres Abonnements werden nur die letzten 30 Tage miteinbezogen',
    create_report_plan_upgrade_button: shared('Upgrade', 'de'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'de'),
    create_report_daterange_reset_toast_ok: shared('OK', 'de'),
    create_report_credit_will_be_used: '1 Guthabenpunkt wird verwendet',
    create_report_loading_text: 'Erstelle Bericht…',
    create_report_screen_header: 'Bericht erstellen',
    create_report_filter_screen_header: shared('Filter Records', 'de'),
    create_report_generate_report_button: 'Bericht erstellen',

    create_report_apple_set_password_title: shared('Password Not Set', 'de'),
    create_report_apple_set_password_message:
      'Bitte legen Sie ein Passwort fest, bevor Sie einen Bericht erstellen, und versuchen Sie es nochmal',
    create_report_apple_set_password_set_button: shared('Set Password', 'de'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'de'),

    // Overlays screen
    overlays_screen_screen_header: 'Einblendungen',
    overlays_screen_reset_button: 'Standardeinstellungen zurücksetzen',
    overlays_screen_reset_toast:
      'Einblendungen werden auf die am häufigsten verwendeten 6 zurückgesetzt',
    overlays_screen_header: 'Wählen Sie bis zu 6 Einblendungen',
    overlays_screen_overlays_limit_error_toast_text:
      'Es können nur 6 Einblendungen gewählt werden',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'de',
    ),
    overlays_screen_apply_button: shared('Apply', 'de'),
    overlays_screen_overlays_selected_text_one:
      '{0}/6 Einblendungen ausgewählt',
    overlays_screen_overlays_selected_text_other:
      '{0}/6 Einblendungen ausgewählt',

    lite_warning_order_credits_button: shared('Order Credits', 'de'),
    lite_warning_upgrade_button: shared('Upgrade', 'de'),
    lite_warning_use_credit_button: 'Guthabenpunkt verwenden',
    lite_warning_with_credits:
      'Ein Guthabenpunkt wird zur Erstellung eines Berichts verwendet, der mehr als die letzten 30 Tage enthält.',
    lite_warning_without_credits:
      'Ein Guthabenpunkt wird zur Erstellung eines Berichts benötigt, der mehr als die letzten 30 Tage enthält.',
    lite_warning_section_with_credits:
      'Ein Guthabenpunkt wird zur Erstellung eines Berichts verwendet, der erweiterte Abschnitte enthält.',
    lite_warning_section_without_credits:
      'Ein Guthabenpunkt wird zur Erstellung eines Berichts benötigt, der erweiterte Abschnitte enthält.',

    report_drawer_pain_summary_header: 'Schmerzzusammenfassung',
    report_drawer_patient_profile_header: 'Patientenprofil',
    report_drawer_filter_summary_header: 'Filter-Zusammenfassung',
    report_drawer_record_details_header: 'Details zum Eintrag',
    report_drawer_chart_view_header: 'Tabellenansicht',
    report_drawer_calendar_view_header: 'Kalenderansicht',
    report_drawer_timeline_view_header: 'Zeitstrahlansicht',
    report_drawer_all_sections_subheader: 'Alle Abschnitte',
    report_drawer_not_included_subheader: 'Nicht miteinbezogen',
    report_drawer_sections_checked: '{0} von {1} Abschnitten ausgewählt',
    report_drawer_change_values_included_subheader:
      'Veränderungswerte miteinbezogen',
    report_drawer_change_values_not_included_subheader:
      'Veränderungswerte nicht miteinbezogen',

    pain_summary_change_values_label: 'Veränderungswerte miteinbeziehen',
    pain_summary_change_values_secondary_label:
      'Zeigt Änderungen im aktuellen Zeitraum im Vergleich zum vorangegeangenen Zeitraum',
    pain_summary_change_values_disabled_error:
      'Es gibt keine Einträge im vorangegangenen Zeitraum, mit denen verglichen werden könnte',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'de'),

    calendar_section_include_following_overlays: 'Angezeigte Einblendungen:',
    calendar_section_overlays_button: 'Einblendungen wählen',
    calendar_section_overlays_default: 'Die 6 am häufigsten verwendeten',
    calendar_section_overlays_above_button:
      'oder wählen Sie die anzuzeigenden Einblendungen manuell',

    charts_section_content_header: 'Folgendes miteinbeziehen:',
    charts_section_content_locations: shared('Locations', 'de'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'de'),
    charts_section_content_character: shared('Characteristics', 'de'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'de',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'de',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'de',
    ),
    charts_section_content_environments: shared('Environments', 'de'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'de',
    ),

    patient_profile_section_content_header: 'Folgendes miteinbeziehen:',
    patient_profile_section_content_name: shared('Name', 'de'),
    patient_profile_section_content_birthday: shared('Birth Date', 'de'),
    patient_profile_section_content_height: shared('Height', 'de'),
    patient_profile_section_content_weight: shared('Weight', 'de'),
    patient_profile_section_content_gender: shared('Gender', 'de'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'de',
    ),
    patient_profile_section_content_medications: shared('Medications', 'de'),

    record_details_content_pain_records_header: shared('Pain Records', 'de'),
    record_details_content_locations: shared('Locations', 'de'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'de'),
    record_details_content_character: shared('Characteristics', 'de'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'de',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'de',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'de',
    ),
    record_details_content_environments: shared('Environments', 'de'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'de',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'de',
    ),
    record_details_content_severity: 'Stärke',
    record_details_content_notes: shared('Notes', 'de'),
    record_details_content_score: shared('Score', 'de'),
    record_details_content_reflection_notes: shared('Notes', 'de'),
    record_details_content_sort_by: shared('Sort By', 'de'),
    record_details_content_sort_by_duration: shared('Duration', 'de'),
    record_details_content_sort_by_severity: 'Stärke / Stärke',
    record_details_content_sort_by_date_created: 'Erstellungsdatum',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'de'),
    record_details_content_sort_order: shared('Sort Order', 'de'),
    record_details_content_sort_order_ascending: shared('Ascending', 'de'),
    record_details_content_sort_order_descending: shared('Descending', 'de'),

    timeline_view_section_content_header: 'Folgendes miteinbeziehen:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'de'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'de',
    ),

    report_success_text:
      'Berichtanfrage erhalten.\n\nInnerhalb der nächsten Stunde erhalten Sie eine E-Mail mit einem Link zu Ihrem Bericht (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    report_w_credit_success_text:
      'Berichtanfrage erhalten und ein Guthabenpunkt wurde benötigt.\n\nInnerhalb der nächsten Stunde wird Ihr Guthabenpunkt aktualisiert und Sie erhalten eine E-Mail mit einem Link zu Ihrem Bericht (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    report_success_button: shared('OK', 'de'),

    report_subscription_expired_title: 'Abonnement abgelaufen',
    report_subscription_expired_message:
      'Ihr Abonnement ist ungültig und ein Guthabenpunkt wird zur Erstellung eines Berichts benötigt.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'de',
    ),
    report_subscription_expired_use_credit_button: 'Guthabenpunkt verwenden',
    report_subscription_expired_cancel_button: shared('Cancel', 'de'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'de'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'de',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'de',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'de',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'de',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'de',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'de',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'de',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'de',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'de',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'de',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'de',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'de',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'de',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'de',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'de',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'de',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'de',
    ),
  },

  es: {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: 'Informes',
    reports_tab_view_create_new_report_tab_text: 'Generar informe',
    reports_tab_view_previous_tab_text: 'Informes anteriores',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: 'Generado el {0} a las {1}',
    previous_reports_sections_included_title: 'Secciones incluidas:',
    previous_reports_sections_included_summary: 'Resumen de dolor',
    previous_reports_sections_included_profile: 'Perfil',
    previous_reports_sections_included_records: 'Detalles del registro',
    previous_reports_sections_included_charts: 'Diagramas',
    previous_reports_sections_included_timeline: 'Cronología',
    previous_reports_sections_included_calendar: 'Calendario',
    previous_reports_download_this_report_button: 'Descargar este informe',
    previous_reports_date_range: 'Intervalo de fechas: {0} hasta {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: 'Crear informe',

    report_screen_offline_title: 'Generar y descargar informes.',
    report_screen_offline_text:
      'Registre una cuenta o inicie sesión para poder generar informes, sincronizar datos y muchas más funciones.',
    report_screen_offline_view_sample_report_button: 'Ver informe de ejemplo',
    report_screen_offline_signup_button: shared('Register For Free', 'es'),
    report_screen_offline_login_text: shared('already_registered', 'es'),
    report_screen_offline_login_button: shared('Log In', 'es'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated: 'Aún no se han generado informes.',
    previous_reports_generate_first_report_text:
      'Genere su primer informe haciendo clic en el siguiente botón.',
    previous_reports_keep_tracking:
      'Continúe haciendo seguimiento para generar su primer informe.',
    previous_reports_generate_first_report_button: 'Genere su primer informe',
    previous_reports_add_pain_record: 'Agregar registro de dolor',
    previous_reports_no_pain_records: 'No hay registros de dolor disponibles.',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: 'Crédito(s)\ndisponible(s)',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'es'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: 'Seleccione secciones a incluir',
    create_report_advanced_sections_header:
      'Seleccione secciones avanzadas  a incluir',
    create_report_advanced_sections_subheader: 'Requiere {0} crédito',
    create_report_plan_text: 'De acuerdo a su plan, solo se incluyen 30 días',
    create_report_plan_upgrade_button: shared('Upgrade', 'es'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'es'),
    create_report_daterange_reset_toast_ok: shared('OK', 'es'),
    create_report_credit_will_be_used: 'Se utilizará 1 crédito',
    create_report_loading_text: 'Creando informe…',
    create_report_screen_header: 'Crear informe',
    create_report_filter_screen_header: shared('Filter Records', 'es'),
    create_report_generate_report_button: 'Crear informe',

    create_report_apple_set_password_title: shared('Password Not Set', 'es'),
    create_report_apple_set_password_message:
      'Por favor establezca una contraseña antes de generar un informe, e inténtelo de nuevo',
    create_report_apple_set_password_set_button: shared('Set Password', 'es'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'es'),

    // Overlays screen
    overlays_screen_screen_header: 'Superposiciones',
    overlays_screen_reset_button: 'Restablecer valores predeterminados',
    overlays_screen_reset_toast:
      'Superposiciones restablecidas a las 6 más utilizadas',
    overlays_screen_header: 'Seleccione hasta 6 superposiciones',
    overlays_screen_overlays_limit_error_toast_text:
      'Solo puede seleccionar 6 superposiciones',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'es',
    ),
    overlays_screen_apply_button: shared('Apply', 'es'),
    overlays_screen_overlays_selected_text_one:
      '{0}/6 superposiciones seleccionadas',
    overlays_screen_overlays_selected_text_other:
      '{0}/6 superposiciones seleccionadas',

    lite_warning_order_credits_button: shared('Order Credits', 'es'),
    lite_warning_upgrade_button: shared('Upgrade', 'es'),
    lite_warning_use_credit_button: 'Usar crédito',
    lite_warning_with_credits:
      'Se usará un crédito para generar un informe que incluya más de los últimos 30 días.',
    lite_warning_without_credits:
      'Se necesita un crédito para crear un informe que incluya más de los últimos 30 días.',
    lite_warning_section_with_credits:
      'Se usará un crédito para generar un informe que incluya secciones avanzadas.',
    lite_warning_section_without_credits:
      'Se necesita un crédito para crear un informe que incluya secciones avanzadas.',

    report_drawer_pain_summary_header: 'Resumen de dolor',
    report_drawer_patient_profile_header: 'Perfil del paciente',
    report_drawer_filter_summary_header: 'Resumen de filtros',
    report_drawer_record_details_header: 'Detalles del registro',
    report_drawer_chart_view_header: 'Vista de gráfico',
    report_drawer_calendar_view_header: 'Vista de calendario',
    report_drawer_timeline_view_header: 'Vista de cronología',
    report_drawer_all_sections_subheader: 'Todas las secciones',
    report_drawer_not_included_subheader: 'No se incluye',
    report_drawer_sections_checked: '{0} de {0} secciones revisadas',
    report_drawer_change_values_included_subheader:
      'Valores de cambio incluidos',
    report_drawer_change_values_not_included_subheader:
      'Valores de cambio no incluidos',

    pain_summary_change_values_label: 'Incluir valores de cambio',
    pain_summary_change_values_secondary_label:
      'Muestra cambios en el periodo actual comparado con el periodo anterior',
    pain_summary_change_values_disabled_error:
      'No hay registros en el periodo anterior para hacer comparaciones',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'es'),

    calendar_section_include_following_overlays: 'Superposiciones mostradas:',
    calendar_section_overlays_button: 'Seleccione superposiciones',
    calendar_section_overlays_default: 'Las 6 usadas con más frecuencia',
    calendar_section_overlays_above_button:
      'o seleccione manualmente las superposiciones a mostrar',

    charts_section_content_header: 'Incluir lo siguiente:',
    charts_section_content_locations: shared('Locations', 'es'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'es'),
    charts_section_content_character: shared('Characteristics', 'es'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'es',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'es',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'es',
    ),
    charts_section_content_environments: shared('Environments', 'es'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'es',
    ),

    patient_profile_section_content_header: 'Incluir lo siguiente:',
    patient_profile_section_content_name: shared('Name', 'es'),
    patient_profile_section_content_birthday: shared('Birth Date', 'es'),
    patient_profile_section_content_height: shared('Height', 'es'),
    patient_profile_section_content_weight: shared('Weight', 'es'),
    patient_profile_section_content_gender: shared('Gender', 'es'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'es',
    ),
    patient_profile_section_content_medications: shared('Medications', 'es'),

    record_details_content_pain_records_header: shared('Pain Records', 'es'),
    record_details_content_locations: shared('Locations', 'es'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'es'),
    record_details_content_character: shared('Characteristics', 'es'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'es',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'es',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'es',
    ),
    record_details_content_environments: shared('Environments', 'es'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'es',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'es',
    ),
    record_details_content_severity: 'Gravedad',
    record_details_content_notes: shared('Notes', 'es'),
    record_details_content_score: shared('Score', 'es'),
    record_details_content_reflection_notes: shared('Notes', 'es'),
    record_details_content_sort_by: shared('Sort By', 'es'),
    record_details_content_sort_by_duration: shared('Duration', 'es'),
    record_details_content_sort_by_severity: 'Gravedad / Gravedad',
    record_details_content_sort_by_date_created: 'Fecha de creación',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'es'),
    record_details_content_sort_order: shared('Sort Order', 'es'),
    record_details_content_sort_order_ascending: shared('Ascending', 'es'),
    record_details_content_sort_order_descending: shared('Descending', 'es'),

    timeline_view_section_content_header: 'Incluir lo siguiente:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'es'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'es',
    ),

    report_success_text:
      'Solicitud de informe recibida.\nRecibirá un correo con un vínculo a su informe dentro de una hora (por favor verifique también su carpeta de correo no deseado)',
    report_w_credit_success_text:
      'Recibimos su solicitud de informe y dedujimos un crédito.\nRecibirá un correo con un vínculo a su informe (por favor verifique también su carpeta de correo no deseado) y su balance de créditos se actualizará dentro de una hora',
    report_success_button: shared('OK', 'es'),

    report_subscription_expired_title: 'Su suscripción expiró',
    report_subscription_expired_message:
      'Su suscripción es inválida y se necesita un crédito para crear un informe.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'es',
    ),
    report_subscription_expired_use_credit_button: 'Usar crédito',
    report_subscription_expired_cancel_button: shared('Cancel', 'es'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'es'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'es',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'es',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'es',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'es',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'es',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'es',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'es',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'es',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'es',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'es',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'es',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'es',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'es',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'es',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'es',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'es',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'es',
    ),
  },

  fr: {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: 'Rapports',
    reports_tab_view_create_new_report_tab_text: 'Générer un rapport',
    reports_tab_view_previous_tab_text: 'Rapports précédents',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: 'Généré le {0} à {1}',
    previous_reports_sections_included_title: 'Rubriques incluses :',
    previous_reports_sections_included_summary: 'Sommaire de la douleur',
    previous_reports_sections_included_profile: 'Profil',
    previous_reports_sections_included_records: "Détails d'enregistrement",
    previous_reports_sections_included_charts: 'Diagrammes',
    previous_reports_sections_included_timeline: 'Chronologie',
    previous_reports_sections_included_calendar: 'Calendrier',
    previous_reports_download_this_report_button: 'Télécharger ce rapport',
    previous_reports_date_range: 'Intervalle de dates: {0} au {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: 'Créer rapport',

    report_screen_offline_title: 'Générer et télécharger des rapports.',
    report_screen_offline_text:
      'Créer un compte ou se connecter pour profiter de la génération de rapports, de la synchronisation des données et de nombreuses autres fonctionnalités.',
    report_screen_offline_view_sample_report_button:
      'Voir un exemple de rapport',
    report_screen_offline_signup_button: shared('Register For Free', 'fr'),
    report_screen_offline_login_text: shared('already_registered', 'fr'),
    report_screen_offline_login_button: shared('Log In', 'fr'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated:
      "Aucun rapport n'a été généré pour le moment.",
    previous_reports_generate_first_report_text:
      'Générez votre premier rapport en cliquant sur le bouton ci-dessous.',
    previous_reports_keep_tracking:
      'Continuez à effectuer un suivi afin de pouvoir générer votre premier rapport.',
    previous_reports_generate_first_report_button:
      'Générez votre premier rapport',
    previous_reports_add_pain_record: 'Ajouter un Enregistrement',
    previous_reports_no_pain_records:
      "Aucun enregistrement de la douleur n'est disponible.",

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: 'Crédit(s)\ndisponible(s)',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'fr'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: 'Sélectionnez les sections à inclure',
    create_report_advanced_sections_header:
      'Sélectionnez les sections avancées à inclure',
    create_report_advanced_sections_subheader: '{0} crédit requis',
    create_report_plan_text: 'Votre plan ne comprend que 30 jours',
    create_report_plan_upgrade_button: shared('Upgrade', 'fr'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'fr'),
    create_report_daterange_reset_toast_ok: shared('OK', 'fr'),
    create_report_credit_will_be_used: '1 crédit sera utilisé',
    create_report_loading_text: 'Création de rapport en cours...',
    create_report_screen_header: 'Créer rapport',
    create_report_filter_screen_header: shared('Filter Records', 'fr'),
    create_report_generate_report_button: 'Créer rapport',

    create_report_apple_set_password_title: shared('Password Not Set', 'fr'),
    create_report_apple_set_password_message:
      'Veuillez définir un mot de passe avant de générer un rapport, puis réessayez',
    create_report_apple_set_password_set_button: shared('Set Password', 'fr'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'fr'),

    // Overlays screen
    overlays_screen_screen_header: 'Superpositions',
    overlays_screen_reset_button: 'Restaurer les paramètres par défaut',
    overlays_screen_reset_toast:
      'Superpositions réinitialisées avec les 6 les plus couramment utilisées',
    overlays_screen_header: "Sélectionnez jusqu'à 6 superpositions",
    overlays_screen_overlays_limit_error_toast_text:
      'Vous pouvez sélectionner 6 superpositions seulement',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'fr',
    ),
    overlays_screen_apply_button: shared('Apply', 'fr'),
    overlays_screen_overlays_selected_text_one:
      '{0} superposition sur 6 a été sélectionnée',
    overlays_screen_overlays_selected_text_other:
      '{0} superpositions sur 6 ont été sélectionnées',

    lite_warning_order_credits_button: shared('Order Credits', 'fr'),
    lite_warning_upgrade_button: shared('Upgrade', 'fr'),
    lite_warning_use_credit_button: 'Utiliser un crédit',
    lite_warning_with_credits:
      'Un crédit sera utilisé pour créer un rapport contenant plus des 30 derniers jours.',
    lite_warning_without_credits:
      'Un crédit est nécessaire pour créer un rapport contenant plus des 30 derniers jours.',
    lite_warning_section_with_credits:
      'Un crédit sera utilisé pour créer un rapport contenant des sections avancées.',
    lite_warning_section_without_credits:
      'Un crédit sera utilisé pour créer un rapport contenant des sections avancées.',

    report_drawer_pain_summary_header: 'Sommaire de la douleur',
    report_drawer_patient_profile_header: 'Profil du patient',
    report_drawer_filter_summary_header: 'Résumé de filtre',
    report_drawer_record_details_header: "Détails d'enregistrement",
    report_drawer_chart_view_header: 'Affichage du graphique',
    report_drawer_calendar_view_header: 'Affichage du calendrier',
    report_drawer_timeline_view_header: 'Affichage chronologique',
    report_drawer_all_sections_subheader: 'Toutes les sections',
    report_drawer_not_included_subheader: 'Non inclus',
    report_drawer_sections_checked: '{0} sections sur {0} vérifiées',
    report_drawer_change_values_included_subheader:
      'Valeurs de changement incluses',
    report_drawer_change_values_not_included_subheader:
      'Valeurs de changement non incluses',

    pain_summary_change_values_label: 'Inclure des valeurs de changement',
    pain_summary_change_values_secondary_label:
      'Affiche des changements dans la période actuelle comparée avec la période antérieure',
    pain_summary_change_values_disabled_error:
      "Il n'y a pas d'enregistrements dans la période antérieure pour faire la comparaison",
    pain_summary_change_values_disabled_error_ok: shared('OK', 'fr'),

    calendar_section_include_following_overlays: 'Superpositions affichées:',
    calendar_section_overlays_button: 'Sélectionnez des superpositions',
    calendar_section_overlays_default: 'Les 6 les plus utilisées',
    calendar_section_overlays_above_button:
      'ou sélectionnez manuellement les superpositions à montrer',

    charts_section_content_header: 'Inclure les éléments suivants:',
    charts_section_content_locations: shared('Locations', 'fr'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'fr'),
    charts_section_content_character: shared('Characteristics', 'fr'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'fr',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'fr',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'fr',
    ),
    charts_section_content_environments: shared('Environments', 'fr'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'fr',
    ),

    patient_profile_section_content_header: 'Inclure les éléments suivants:',
    patient_profile_section_content_name: shared('Name', 'fr'),
    patient_profile_section_content_birthday: shared('Birth Date', 'fr'),
    patient_profile_section_content_height: shared('Height', 'fr'),
    patient_profile_section_content_weight: shared('Weight', 'fr'),
    patient_profile_section_content_gender: shared('Gender', 'fr'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'fr',
    ),
    patient_profile_section_content_medications: shared('Medications', 'fr'),

    record_details_content_pain_records_header: shared('Pain Records', 'fr'),
    record_details_content_locations: shared('Locations', 'fr'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'fr'),
    record_details_content_character: shared('Characteristics', 'fr'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'fr',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'fr',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'fr',
    ),
    record_details_content_environments: shared('Environments', 'fr'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'fr',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'fr',
    ),
    record_details_content_severity: 'Gravité',
    record_details_content_notes: shared('Notes', 'fr'),
    record_details_content_score: shared('Score', 'fr'),
    record_details_content_reflection_notes: shared('Notes', 'fr'),
    record_details_content_sort_by: shared('Sort By', 'fr'),
    record_details_content_sort_by_duration: shared('Duration', 'fr'),
    record_details_content_sort_by_severity: 'Gravité / Gravité',
    record_details_content_sort_by_date_created: 'Date de création',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'fr'),
    record_details_content_sort_order: shared('Sort Order', 'fr'),
    record_details_content_sort_order_ascending: shared('Ascending', 'fr'),
    record_details_content_sort_order_descending: shared('Descending', 'fr'),

    timeline_view_section_content_header: 'Inclure les éléments suivants:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'fr'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'fr',
    ),

    report_success_text:
      'Nous avons reçu votre demande de rapport.\nVous allez recevoir un courriel avec un lien vers votre rapport dans une heure (veuillez également vérifier le dossier Spam / Courrier indésirable)',
    report_w_credit_success_text:
      'Nous avons reçu votre demande de rapport et nous avons déduit un crédit.\n\nVous allez recevoir un courriel avec un lien vers votre rapport (veuillez également vérifier le dossier Spam / Courrier indésirable) et votre solde créditeur sera mis à jour dans une heure',
    report_success_button: shared('OK', 'fr'),

    report_subscription_expired_title: 'La souscription est expirée',
    report_subscription_expired_message:
      'Votre souscription est invalide et il est nécessaire un crédit pour créer un rapport.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'fr',
    ),
    report_subscription_expired_use_credit_button: 'Utiliser un crédit',
    report_subscription_expired_cancel_button: shared('Cancel', 'fr'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'fr'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'fr',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'fr',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'fr',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'fr',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'fr',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'fr',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'fr',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'fr',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'fr',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'fr',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'fr',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'fr',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'fr',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'fr',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'fr',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'fr',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'fr',
    ),
  },

  ko: {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: '보고서',
    reports_tab_view_create_new_report_tab_text: '보고서 생성',
    reports_tab_view_previous_tab_text: '이전 보고서',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: '{0} {1}에 생성됨',
    previous_reports_sections_included_title: '포함된 섹션:',
    previous_reports_sections_included_summary: '통증 요약',
    previous_reports_sections_included_profile: '프로필',
    previous_reports_sections_included_records: '기록 세부 정보',
    previous_reports_sections_included_charts: '차트',
    previous_reports_sections_included_timeline: '타임 라인',
    previous_reports_sections_included_calendar: '달력',
    previous_reports_download_this_report_button: '이 보고서 다운로드',
    previous_reports_date_range: '날짜 범위: {0} 부터 {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: '보고서 생성',

    report_screen_offline_title: '보고서 생성 및 다운로드',
    report_screen_offline_text:
      '계정을 가입하거나 로그인하여 보고서 생성, 데이터 동기화 등의 다수 기능을 이용하세요.',
    report_screen_offline_view_sample_report_button: '샘플 보고서 확인하기',
    report_screen_offline_signup_button: shared('Register For Free', 'ko'),
    report_screen_offline_login_text: shared('already_registered', 'ko'),
    report_screen_offline_login_button: shared('Log In', 'ko'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated: '아직 생성된 보고서가 없습니다.',
    previous_reports_generate_first_report_text:
      '아래의 버튼을 눌러서 첫 번째 보고서를 생성하세요.',
    previous_reports_keep_tracking:
      '계속 기록하여 첫 번째 보고서를 생성하세요.',
    previous_reports_generate_first_report_button:
      '첫 번째 보고서를 생성하세요',
    previous_reports_add_pain_record: '통증 기록 추가',
    previous_reports_no_pain_records: '통증 기록이 없습니다.',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: '크레딧\n이용 가능',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'ko'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: '포함할 섹션 선택',
    create_report_advanced_sections_header: '포함할 고급 섹션 선택',
    create_report_advanced_sections_subheader: '크레딧 {0} 필요함',
    create_report_plan_text: '귀하의 플랜에 따르면 30일만 포함됩니다',
    create_report_plan_upgrade_button: shared('Upgrade', 'ko'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'ko'),
    create_report_daterange_reset_toast_ok: shared('OK', 'ko'),
    create_report_credit_will_be_used: '1 크레딧이 사용 됩니다',
    create_report_loading_text: '보고서를 생성하는 중...',
    create_report_screen_header: '보고서 생성',
    create_report_filter_screen_header: shared('Filter Records', 'ko'),
    create_report_generate_report_button: '보고서 생성',

    create_report_apple_set_password_title: shared('Password Not Set', 'ko'),
    create_report_apple_set_password_message:
      '보고서를 만들기 전에 암호를 설정하세요, 그리고 다시 시도하세요',
    create_report_apple_set_password_set_button: shared('Set Password', 'ko'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'ko'),

    // Overlays screen
    overlays_screen_screen_header: '오버레이',
    overlays_screen_reset_button: '기본값 재설정',
    overlays_screen_reset_toast:
      '오버레이가 가장 일반적으로 사용되는 6으로 재설정됩니다',
    overlays_screen_header: '최대 6개의 오버레이를 선택합니다',
    overlays_screen_overlays_limit_error_toast_text:
      '6개의 오버레이만 선택할 수 있습니다',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'ko',
    ),
    overlays_screen_apply_button: shared('Apply', 'ko'),
    overlays_screen_overlays_selected_text_one: '{0}/6 오버레이 선택됨',
    overlays_screen_overlays_selected_text_other: '{0}/6 오버레이 선택됨',

    lite_warning_order_credits_button: shared('Order Credits', 'ko'),
    lite_warning_upgrade_button: shared('Upgrade', 'ko'),
    lite_warning_use_credit_button: '크레딧 사용',
    lite_warning_with_credits:
      '크레딧은 지난 30일 이상을 포함하는 보고서를 생성하는 데 사용됩니다.',
    lite_warning_without_credits:
      '지난 30일 이상을 포함하는 보고서를 생성하려면 크레딧이 필요합니다.',
    lite_warning_section_with_credits:
      '크레딧은 고급 섹션을 포함하는 보고서를 생성하는 데 사용됩니다.',
    lite_warning_section_without_credits:
      '고급 섹션을 포함하는 보고서를 생성하려면 크레딧이 필요합니다.',

    report_drawer_pain_summary_header: '통증 요약',
    report_drawer_patient_profile_header: '환자 프로파일 ',
    report_drawer_filter_summary_header: '필터 요약',
    report_drawer_record_details_header: '기록 세부 정보',
    report_drawer_chart_view_header: '차트 보기',
    report_drawer_calendar_view_header: '일정관리 보기',
    report_drawer_timeline_view_header: '타임라인 보기',
    report_drawer_all_sections_subheader: '모든 섹션',
    report_drawer_not_included_subheader: '포함되지 않음',
    report_drawer_sections_checked: '{1}개 섹션 중 {0}개가 확인됨',
    report_drawer_change_values_included_subheader: '포함된 값 변경',
    report_drawer_change_values_not_included_subheader: '값 변경 미포함',

    pain_summary_change_values_label: '변경 값 포함',
    pain_summary_change_values_secondary_label:
      '이전 기간에 비해 현재 기간의 변경 사항 표시',
    pain_summary_change_values_disabled_error:
      '이전 기간에 비교할 기록이 없습니다',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'ko'),

    calendar_section_include_following_overlays: '오버레이 표시:',
    calendar_section_overlays_button: '오버레이 선택',
    calendar_section_overlays_default: '가장 자주 사용되는 6개',
    calendar_section_overlays_above_button:
      '또는 표시할 오버레이를 수동으로 선택하세요',

    charts_section_content_header: '다음을 포함합니다:',
    charts_section_content_locations: shared('Locations', 'ko'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'ko'),
    charts_section_content_character: shared('Characteristics', 'ko'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'ko',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'ko',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'ko',
    ),
    charts_section_content_environments: shared('Environments', 'ko'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'ko',
    ),

    patient_profile_section_content_header: '다음을 포함합니다:',
    patient_profile_section_content_name: shared('Name', 'ko'),
    patient_profile_section_content_birthday: shared('Birth Date', 'ko'),
    patient_profile_section_content_height: shared('Height', 'ko'),
    patient_profile_section_content_weight: shared('Weight', 'ko'),
    patient_profile_section_content_gender: shared('Gender', 'ko'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'ko',
    ),
    patient_profile_section_content_medications: shared('Medications', 'ko'),

    record_details_content_pain_records_header: shared('Pain Records', 'ko'),
    record_details_content_locations: shared('Locations', 'ko'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'ko'),
    record_details_content_character: shared('Characteristics', 'ko'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'ko',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'ko',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'ko',
    ),
    record_details_content_environments: shared('Environments', 'ko'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'ko',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'ko',
    ),
    record_details_content_severity: '심각성',
    record_details_content_notes: shared('Notes', 'ko'),
    record_details_content_score: shared('Score', 'ko'),
    record_details_content_reflection_notes: shared('Notes', 'ko'),
    record_details_content_sort_by: shared('Sort By', 'ko'),
    record_details_content_sort_by_duration: shared('Duration', 'ko'),
    record_details_content_sort_by_severity: '심각성 / 심각성',
    record_details_content_sort_by_date_created: '생성된 날짜',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'ko'),
    record_details_content_sort_order: shared('Sort Order', 'ko'),
    record_details_content_sort_order_ascending: shared('Ascending', 'ko'),
    record_details_content_sort_order_descending: shared('Descending', 'ko'),

    timeline_view_section_content_header: '다음을 포함합니다:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'ko'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'ko',
    ),

    report_success_text:
      '보고서 요청이 수신되었습니다.\n\n 1시간 내에 보고서 링크가 포함된 이메일을 받게 됩니다 (스팸/휴지통을 확인하세요)',
    report_w_credit_success_text:
      '보고서 요청이 수신되었으며 크레딧이 요구되었습니다.\n\n보고서에 대한 링크가 포함된 이메일을 받게 되며 (스팸/휴지통을 확인하세요) 1시간 이내에 크레딧 잔액이 업데이트됩니다 ',
    report_success_button: shared('OK', 'ko'),

    report_subscription_expired_title: '구독 만료됨',
    report_subscription_expired_message:
      '구독이 잘못되었으며 보고서를 생성하려면 크레딧이 필요합니다.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'ko',
    ),
    report_subscription_expired_use_credit_button: '크레딧 사용',
    report_subscription_expired_cancel_button: shared('Cancel', 'ko'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'ko'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'ko',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ko',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ko',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'ko',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'ko',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ko',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ko',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ko',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'ko',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'ko',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'ko',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'ko',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'ko',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'ko',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'ko',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'ko',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'ko',
    ),
  },

  ru: {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: 'Отчёты',
    reports_tab_view_create_new_report_tab_text: 'Создать отчет',
    reports_tab_view_previous_tab_text: 'Предыдущие отчеты',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: 'Создан {0} в {1}',
    previous_reports_sections_included_title: 'Включенные разделы:',
    previous_reports_sections_included_summary: 'Сводка по болям',
    previous_reports_sections_included_profile: 'Профиль',
    previous_reports_sections_included_records: 'Информация о записи',
    previous_reports_sections_included_charts: 'Графики',
    previous_reports_sections_included_timeline: 'Хронология',
    previous_reports_sections_included_calendar: 'Календарь',
    previous_reports_download_this_report_button: 'Загрузить этот отчет',
    previous_reports_date_range: 'Отчетный период: с {0} по {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: 'Создать отчет',

    report_screen_offline_title: 'Создавайте и загружайте отчёты.',
    report_screen_offline_text:
      'Зарегистрируйте аккаунт или войдите в систему, чтобы создавать отчёты, синхронизировать данные и пользоваться другими функциями.',
    report_screen_offline_view_sample_report_button: 'Показать пример отчёта',
    report_screen_offline_signup_button: shared('Register For Free', 'ru'),
    report_screen_offline_login_text: shared('already_registered', 'ru'),
    report_screen_offline_login_button: shared('Log In', 'ru'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated: 'Созданных отчетов пока нет.',
    previous_reports_generate_first_report_text:
      'Создайте свой первый отчет, нажав кнопку ниже.',
    previous_reports_keep_tracking:
      'Продолжайте отслеживание, чтобы создать первый отчет.',
    previous_reports_generate_first_report_button: 'Создайте свой первый отчет',
    previous_reports_add_pain_record: 'Создайте свой первый отчет',
    previous_reports_no_pain_records: 'Записи о боли отсутствуют.',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: 'Доступно\nкредитов',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'ru'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: 'Выберите нужные разделы',
    create_report_advanced_sections_header:
      'Выберите нужные расширенные разделы',
    create_report_advanced_sections_subheader: 'Нужен {0} кредит',
    create_report_plan_text:
      'Согласно вашему тарифному плану, в отчет войдут только 30 дней',
    create_report_plan_upgrade_button: shared('Upgrade', 'ru'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'ru'),
    create_report_daterange_reset_toast_ok: shared('OK', 'ru'),
    create_report_credit_will_be_used: 'Будет использован 1 кредит',
    create_report_loading_text: 'Создание отчета...',
    create_report_screen_header: 'Создать отчет',
    create_report_filter_screen_header: shared('Filter Records', 'ru'),
    create_report_generate_report_button: 'Создать отчет',

    create_report_apple_set_password_title: shared('Password Not Set', 'ru'),
    create_report_apple_set_password_message:
      'Перед созданием отчета установите пароль и попробуйте снова',
    create_report_apple_set_password_set_button: shared('Set Password', 'ru'),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'ru'),

    // Overlays screen
    overlays_screen_screen_header: 'Инфослои',
    overlays_screen_reset_button: 'Сбросить "по умолчанию"',
    overlays_screen_reset_toast: 'Инфослои сброшены до 6 самых используемых',
    overlays_screen_header: 'Выберите до 6 инфослоев',
    overlays_screen_overlays_limit_error_toast_text:
      'Можно выбрать только 6 инфослоев',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'ru',
    ),
    overlays_screen_apply_button: shared('Apply', 'ru'),
    overlays_screen_overlays_selected_text_one: 'выбран {0} из 6 инфослоев',
    overlays_screen_overlays_selected_text_other: 'выбраны {0} из 6 инфослоев',

    lite_warning_order_credits_button: shared('Order Credits', 'ru'),
    lite_warning_upgrade_button: shared('Upgrade', 'ru'),
    lite_warning_use_credit_button: 'Применить кредит',
    lite_warning_with_credits:
      'Для создания отчета за период более 30 дней будет использован кредит.',
    lite_warning_without_credits:
      'Для создания отчета за период более 30 дней требуется кредит.',
    lite_warning_section_with_credits:
      'Для создания отчета с расширенными разделами будет использован кредит.',
    lite_warning_section_without_credits:
      'Для создания отчета с расширенными разделами требуется кредит.',

    report_drawer_pain_summary_header: 'Сводка по болям',
    report_drawer_patient_profile_header: 'Профиль пациента ',
    report_drawer_filter_summary_header: 'Информация о фильтрах',
    report_drawer_record_details_header: 'Информация о записи',
    report_drawer_chart_view_header: 'Показать график',
    report_drawer_calendar_view_header: 'Показать календарь',
    report_drawer_timeline_view_header: 'Показать хронологию',
    report_drawer_all_sections_subheader: 'Все разделы',
    report_drawer_not_included_subheader: 'Не включены',
    report_drawer_sections_checked: '{0} из {1} отмеченных разделов',
    report_drawer_change_values_included_subheader:
      'Изменения показателей включены',
    report_drawer_change_values_not_included_subheader:
      'Изменения показателей не включены',

    pain_summary_change_values_label: 'Включить изменения показателей',
    pain_summary_change_values_secondary_label:
      'Показывает изменения в текущем периоде в сравнении с предыдущим',
    pain_summary_change_values_disabled_error:
      'Нет записей для сравнения в предыдущем периоде',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'ru'),

    calendar_section_include_following_overlays: 'Показанные инфослои:',
    calendar_section_overlays_button: 'Выберите инфослои',
    calendar_section_overlays_default: '6 наиболее часто используемых',
    calendar_section_overlays_above_button:
      'или выберите инфослои для отображения вручную',

    charts_section_content_header: 'Включить следующие:',
    charts_section_content_locations: shared('Locations', 'ru'),
    charts_section_content_symptoms: shared('Other Associated Symptoms', 'ru'),
    charts_section_content_character: shared('Characteristics', 'ru'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'ru',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'ru',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'ru',
    ),
    charts_section_content_environments: shared('Environments', 'ru'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'ru',
    ),

    patient_profile_section_content_header: 'Включить следующие:',
    patient_profile_section_content_name: shared('Name', 'ru'),
    patient_profile_section_content_birthday: shared('Birth Date', 'ru'),
    patient_profile_section_content_height: shared('Height', 'ru'),
    patient_profile_section_content_weight: shared('Weight', 'ru'),
    patient_profile_section_content_gender: shared('Gender', 'ru'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'ru',
    ),
    patient_profile_section_content_medications: shared('Medications', 'ru'),

    record_details_content_pain_records_header: shared('Pain Records', 'ru'),
    record_details_content_locations: shared('Locations', 'ru'),
    record_details_content_symptom: shared('Other Associated Symptoms', 'ru'),
    record_details_content_character: shared('Characteristics', 'ru'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'ru',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'ru',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'ru',
    ),
    record_details_content_environments: shared('Environments', 'ru'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'ru',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'ru',
    ),
    record_details_content_severity: 'Интенсивность',
    record_details_content_notes: shared('Notes', 'ru'),
    record_details_content_score: shared('Score', 'ru'),
    record_details_content_reflection_notes: shared('Notes', 'ru'),
    record_details_content_sort_by: shared('Sort By', 'ru'),
    record_details_content_sort_by_duration: shared('Duration', 'ru'),
    record_details_content_sort_by_severity: 'Интенсивность / Интенсивность',
    record_details_content_sort_by_date_created: 'Дата создания',
    record_details_content_sort_by_timing_of_day: shared('Time of Day', 'ru'),
    record_details_content_sort_order: shared('Sort Order', 'ru'),
    record_details_content_sort_order_ascending: shared('Ascending', 'ru'),
    record_details_content_sort_order_descending: shared('Descending', 'ru'),

    timeline_view_section_content_header: 'Включить следующие:',
    timeline_view_section_content_pain_severity: shared('Pain Severity', 'ru'),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'ru',
    ),

    report_success_text:
      'Получен запрос отчета.\n\nВ течение часа вы получите электронное письмо со ссылкой на свой отчет (на всякий случай загляните в папку СПАМ)',
    report_w_credit_success_text:
      'Получен запрос отчета, для которого нужен кредит.\n\nВ течение часа вы получите электронное письмо со ссылкой на свой отчет (на всякий случай загляните в папку СПАМ), а ваш баланс кредитов будет обновлен.',
    report_success_button: shared('OK', 'ru'),

    report_subscription_expired_title: 'Срок подписки истек',
    report_subscription_expired_message:
      'Ваша подписка недействительна, для создания отчета требуется кредит.',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'ru',
    ),
    report_subscription_expired_use_credit_button: 'Применить кредит',
    report_subscription_expired_cancel_button: shared('Cancel', 'ru'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared('Locations', 'ru'),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'ru',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ru',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ru',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'ru',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'ru',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ru',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ru',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ru',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'ru',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'ru',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'ru',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'ru',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'ru',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'ru',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'ru',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'ru',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'ru',
    ),
  },

  'zh-rCN': {
    // Report screen
    //containers/ReportsTabView.js
    reports_screen_header: '报告',
    reports_tab_view_create_new_report_tab_text: '生成报告',
    reports_tab_view_previous_tab_text: '已往报告',

    //containers/PreviousReportsContainer
    previous_reports_generated_on: '于 {0} {1} 生成',
    previous_reports_sections_included_title: '包括',
    previous_reports_sections_included_summary: '疼痛摘要',
    previous_reports_sections_included_profile: '我的档案',
    previous_reports_sections_included_records: '记录详情',
    previous_reports_sections_included_charts: '图表',
    previous_reports_sections_included_timeline: '时间线',
    previous_reports_sections_included_calendar: '日历',
    previous_reports_download_this_report_button: '下载此报告',
    previous_reports_date_range: '日期区间: {0} 到 {1}',

    // scenes/CreateReportScene.js
    report_screen_screen_header: '生成报告',

    report_screen_offline_title: '生成和下载报告。',
    report_screen_offline_text:
      '立即注册或登录帐户，即可使用报告生成、数据同步和更多功能。',
    report_screen_offline_view_sample_report_button: '查看样本报告',
    report_screen_offline_signup_button: shared('Register For Free', 'zh-rCN'),
    report_screen_offline_login_text: shared('already_registered', 'zh-rCN'),
    report_screen_offline_login_button: shared('Log In', 'zh-rCN'),

    //containers/NoReportscontainer.js
    previous_reports_no_reports_generated: '未有生成报告。',
    previous_reports_generate_first_report_text:
      '通过点击下方按钮，生成您的第一份报告。',
    previous_reports_keep_tracking: '继续跟踪，来生成您的第一份报告。',
    previous_reports_generate_first_report_button: '生成您的第一份报告。',
    previous_reports_add_pain_record: '添加疼痛记录',
    previous_reports_no_pain_records: '无可用的疼痛记录。',

    // Report screen
    // containers/CreditsBottomBar.js
    credits_bottom_bar_credits_available_label: '可用\n点数',
    credits_bottom_bar_order_credits_button: shared('Order Credits', 'zh-rCN'),

    // scenes/CreateReportContainer.js
    create_report_basic_sections_header: '选择要包括的部分',
    create_report_advanced_sections_header: '选择要包括的高级部分',
    create_report_advanced_sections_subheader: '需要 {0} 个点数',
    create_report_plan_text: '根据您的订购计划，仅限于30天',
    create_report_plan_upgrade_button: shared('Upgrade', 'zh-rCN'),
    create_report_daterange_reset_toast: shared('date_range_changed', 'zh-rCN'),
    create_report_daterange_reset_toast_ok: shared('OK', 'zh-rCN'),
    create_report_credit_will_be_used: '将使用1个点',
    create_report_loading_text: '正在生成报告...',
    create_report_screen_header: '生成报告',
    create_report_filter_screen_header: shared('Filter Records', 'zh-rCN'),
    create_report_generate_report_button: '生成报告',

    create_report_apple_set_password_title: shared(
      'Password Not Set',
      'zh-rCN',
    ),
    create_report_apple_set_password_message:
      '请在生成报告之前设置密码，然后重试',
    create_report_apple_set_password_set_button: shared(
      'Set Password',
      'zh-rCN',
    ),
    create_report_apple_set_password_cancel_button: shared('Cancel', 'zh-rCN'),

    // Overlays screen
    overlays_screen_screen_header: '日历图示',
    overlays_screen_reset_button: '重置缺省值',
    overlays_screen_reset_toast: '日历图示重置为最常用的6种',
    overlays_screen_header: '最多可选择6个日历图示',
    overlays_screen_overlays_limit_error_toast_text: '只能选择6个日历图示',
    overlays_screen_overlays_limit_error_toast_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),
    overlays_screen_apply_button: shared('Apply', 'zh-rCN'),
    overlays_screen_overlays_selected_text_one: '从 6 个图示中，选择了{0} 个',
    overlays_screen_overlays_selected_text_other: '从 6 个图示中，选择了{0} 个',

    lite_warning_order_credits_button: shared('Order Credits', 'zh-rCN'),
    lite_warning_upgrade_button: shared('Upgrade', 'zh-rCN'),
    lite_warning_use_credit_button: '使用点数',
    lite_warning_with_credits: '将使用1点用于生成包含超过最近30天信息的报告。',
    lite_warning_without_credits:
      '需要使用1点才能生成包含超过最近30天信息的报告。',
    lite_warning_section_with_credits: '将使用1点用于生成包含高级部分的报告。',
    lite_warning_section_without_credits:
      '需要使用1点才能生成包含高级部分的报告。',

    report_drawer_pain_summary_header: '疼痛摘要',
    report_drawer_patient_profile_header: '患者档案',
    report_drawer_filter_summary_header: '过滤条件摘要',
    report_drawer_record_details_header: '记录详情',
    report_drawer_chart_view_header: '图表视图',
    report_drawer_calendar_view_header: '日历视图',
    report_drawer_timeline_view_header: '时间线视图',
    report_drawer_all_sections_subheader: '所有部分',
    report_drawer_not_included_subheader: '不包含',
    report_drawer_sections_checked: '选中{1}个部分中的{0}个',
    report_drawer_change_values_included_subheader: '变化值已包括',
    report_drawer_change_values_not_included_subheader: '变化值未包括',

    pain_summary_change_values_label: '包括变化值',
    pain_summary_change_values_secondary_label:
      '显示当前周期与上一周期相比的变化',
    pain_summary_change_values_disabled_error: '上一周期没有可供对比的记录',
    pain_summary_change_values_disabled_error_ok: shared('OK', 'zh-rCN'),

    calendar_section_include_following_overlays: '显示的日历图示：',
    calendar_section_overlays_button: '选择日历图示',
    calendar_section_overlays_default: '6项最常用的',
    calendar_section_overlays_above_button: '或手动选择要显示的日历图示',

    charts_section_content_header: '包括以下：',
    charts_section_content_locations: shared('Locations', 'zh-rCN'),
    charts_section_content_symptoms: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    charts_section_content_character: shared('Characteristics', 'zh-rCN'),
    charts_section_content_aggravating_factors: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    charts_section_content_alleviating_factors: shared(
      'Alleviating Factors',
      'zh-rCN',
    ),
    charts_section_content_ineffective_factors: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    charts_section_content_environments: shared('Environments', 'zh-rCN'),
    charts_section_content_meaningful_activities: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),

    patient_profile_section_content_header: '包括以下：',
    patient_profile_section_content_name: shared('Name', 'zh-rCN'),
    patient_profile_section_content_birthday: shared('Birth Date', 'zh-rCN'),
    patient_profile_section_content_height: shared('Height', 'zh-rCN'),
    patient_profile_section_content_weight: shared('Weight', 'zh-rCN'),
    patient_profile_section_content_gender: shared('Gender', 'zh-rCN'),
    patient_profile_section_content_pain_conditions: shared(
      'Pain Conditions',
      'zh-rCN',
    ),
    patient_profile_section_content_medications: shared(
      'Medications',
      'zh-rCN',
    ),

    record_details_content_pain_records_header: shared(
      'Pain Records',
      'zh-rCN',
    ),
    record_details_content_locations: shared('Locations', 'zh-rCN'),
    record_details_content_symptom: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    record_details_content_character: shared('Characteristics', 'zh-rCN'),
    record_details_content_aggravating_factors: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    record_details_content_ineffective_factors: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    record_details_content_alleviating_factors: shared(
      'Alleviating Factors',
      'zh-rCN',
    ),
    record_details_content_environments: shared('Environments', 'zh-rCN'),
    record_details_content_meaningful_activities: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    record_details_content_daily_reflections_header: shared(
      'Daily Reflections',
      'zh-rCN',
    ),
    record_details_content_severity: '严重程度',
    record_details_content_notes: shared('Notes', 'zh-rCN'),
    record_details_content_score: shared('Score', 'zh-rCN'),
    record_details_content_reflection_notes: shared('Notes', 'zh-rCN'),
    record_details_content_sort_by: shared('Sort By', 'zh-rCN'),
    record_details_content_sort_by_duration: shared('Duration', 'zh-rCN'),
    record_details_content_sort_by_severity: '严重程度 / 严重程度',
    record_details_content_sort_by_date_created: '创建日期',
    record_details_content_sort_by_timing_of_day: shared(
      'Time of Day',
      'zh-rCN',
    ),
    record_details_content_sort_order: shared('Sort Order', 'zh-rCN'),
    record_details_content_sort_order_ascending: shared('Ascending', 'zh-rCN'),
    record_details_content_sort_order_descending: shared(
      'Descending',
      'zh-rCN',
    ),

    timeline_view_section_content_header: '包括以下：',
    timeline_view_section_content_pain_severity: shared(
      'Pain Severity',
      'zh-rCN',
    ),
    timeline_view_section_content_reflection_score: shared(
      'Daily Reflection Score',
      'zh-rCN',
    ),

    report_success_text:
      '收到报告请求。\n\n您将在一小时内收到一封电子邮件，其中包含查看您的报告的链接 (请同时检查垃圾邮件/垃圾文件夹)',
    report_w_credit_success_text:
      '收到报告请求，需要1个信用点。\n\n您将收到一封电子邮件，其中包含查看您的报告的链接 (请同时检查垃圾邮件/垃圾文件夹)，您的点数余额将在一小时内更新',
    report_success_button: shared('OK', 'zh-rCN'),

    report_subscription_expired_title: '订阅已过期',
    report_subscription_expired_message: '您的订阅无效，生成报告需要信用点。',
    report_subscription_expired_go_subscription_button: shared(
      'Manage Subscription',
      'zh-rCN',
    ),
    report_subscription_expired_use_credit_button: '使用点数',
    report_subscription_expired_cancel_button: shared('Cancel', 'zh-rCN'),

    // components/OverlayDrawer
    overlay_drawer_location_section_header_plural: shared(
      'Locations',
      'zh-rCN',
    ),
    overlay_drawer_location_section_header_secondary: shared(
      'locations_question',
      'zh-rCN',
    ),
    overlay_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    overlay_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'zh-rCN',
    ),
    overlay_drawer_character_section_header_plural: shared(
      'Characteristics',
      'zh-rCN',
    ),
    overlay_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'zh-rCN',
    ),
    overlay_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    overlay_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'zh-rCN',
    ),
    overlay_drawer_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    overlay_drawer_ineffective_factor_header_secondary: shared(
      'ineffective_factors_question',
      'zh-rCN',
    ),
    overlay_drawer_environment_section_header_plural: shared(
      'Environments',
      'zh-rCN',
    ),
    overlay_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'zh-rCN',
    ),
    overlay_drawer_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'zh-rCN',
    ),
    overlay_drawer_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'zh-rCN',
    ),
    overlay_drawer_meaningful_activities_header_text: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    overlay_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'zh-rCN',
    ),
    overlay_drawer_medications_section_header_plural: shared(
      'Medications',
      'zh-rCN',
    ),
    overlay_drawer_medications_section_header_secondary: shared(
      'medications_question',
      'zh-rCN',
    ),
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
