import { bugsnag } from '../../Settings/components/CrashUsageController';
import { deleteRecord, fixDailyReflection } from '../../Records/actions';
import { formatMoment } from '../../../shared/utilities/timeutils';
import { MILLISECONDS_IN_DAY } from '../../Filter/filter';

const TIME_12_HOURS = MILLISECONDS_IN_DAY / 2;

export default function FixDailyReflections(dispatch, getState) {
  let localRecords = getState().records.list;
  let newRecords = localRecords.slice();
  let affectedRecords = [];
  let recordsToDelete = [];

  localRecords.forEach((record) => {
    if (!record.deleted && record.type === 'DailyReflection') {
      let recordLocalMoment = formatMoment(
        record.recordTime,
        record.timeOffset,
      );
      let currentHour = parseFloat(recordLocalMoment.format('HH'));
      if (currentHour < 12) {
        affectedRecords.push(record);
      }
    }
  });

  affectedRecords = affectedRecords.map((rec) => {
    let fixed = { ...rec };
    fixed.recordTime = rec.recordTime + TIME_12_HOURS;
    return fixed;
  });
  console.log('step 3');

  affectedRecords.forEach((record) => {
    let index = newRecords.findIndex((rec) => rec.id === record.id);
    newRecords.splice(index, 1, record);
  });
  console.log('step 4');

  //Also we should try to search local daily reflections which have same date...
  newRecords = newRecords.filter(
    (rec) => rec.type === 'DailyReflection' && !rec.deleted,
  );
  console.log('step 4.1', newRecords.length);
  let moments = {};

  newRecords.forEach((record) => {
    let recordLocalMoment = formatMoment(record.recordTime, record.timeOffset);
    moments[record.id] = recordLocalMoment;
  });

  newRecords.forEach((record) => {
    if (!record.deleted && record.type === 'DailyReflection') {
      let recordLocalMoment = moments[record.id];
      newRecords.forEach((rec) => {
        if (
          record.id !== rec.id &&
          !rec.deleted &&
          rec.type === 'DailyReflection'
        ) {
          let recMoment = moments[rec.id];
          if (recordLocalMoment.isSame(recMoment, 'date')) {
            //Same day record found... Save only newest one
            console.log('FOUND LOCAL RECORDS TO DELETE', rec, record);
            if (
              rec.updateDate > record.updateDate ||
              (rec.updateDate === record.updateDate &&
                rec.createDate > record.createDate)
            ) {
              recordsToDelete.push(record);
            }
          }
        }
      });
    }
  });

  //if we need to delete some record - then don't "update" it as affected
  recordsToDelete.forEach((rec) => {
    let index = affectedRecords.findIndex((record) => record.id === rec.id);
    if (index >= 0) {
      affectedRecords.splice(index, 1);
    }
  });

  if (affectedRecords.length > 0) {
    bugsnag &&
      bugsnag.notify(
        new Error(
          'Found ' +
            affectedRecords.length +
            ' daily reflections with wrong time',
          affectedRecords.map((rec) =>
            formatMoment(rec.recordTime, rec.timeOffset).format('LLL'),
          ),
        ),
      );
    console.log('AFFECTED:', affectedRecords.length, recordsToDelete.length);

    affectedRecords.forEach((rec) => {
      dispatch(fixDailyReflection(rec));
    });
  }

  if (recordsToDelete.length > 0) {
    bugsnag &&
      bugsnag.notify(
        new Error(
          'Found ' +
            recordsToDelete.length +
            ' daily reflections which duplicates existing records. Deleting them',
        ),
      );
  }

  console.log('FOUND RECORDS', affectedRecords.length, recordsToDelete);
  return Promise.all(
    affectedRecords.map((rec) => {
      return dispatch(fixDailyReflection(rec));
    }),
  ).then(() => {
    return Promise.all(
      recordsToDelete.map((rec) => {
        return dispatch(deleteRecord(rec));
      }),
    );
  });
}
