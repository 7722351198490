import * as types from './actiontypes';
import { KEEP_ALIVE, WEB_TOKEN_OUTDATED } from './actiontypes';
import { login, saveToken, syncFailedLogout } from '../Auth/actions';
import getStore from '../../redux/store';
import strings from './strings';
import AuthStrings from '../Auth/strings';
import moment from 'moment';
import { updateAppFromStore } from '../../shared/utilities/updateAppFromStore';
import * as MMPApi from 'apiclient';
import { Platform } from 'react-native';
import { bugsnag } from '../Settings/components/CrashUsageController';
import FindRecordsWithMissingID from '../Upgrade/updates/001FindRecordsWithMissingID';
import { sync } from '../Sync/actions';
import FixDailyReflections from '../Upgrade/updates/002FixDailyReflectionsTime';
import FixDailyReflectionsFromServer from '../Upgrade/updates/003FixDailyReflectionsTimeFromServer';
import NavigationService from '../Navigator/navigators/NavigationService';
import { designConstants } from '../../design/styles';
import getConfig from '../../shared/utilities/getConfig';
import Alert from '../../design/components/Alert';
import DeviceInfo from 'react-native-device-info';
import { getLang } from '../../shared/utilities/getLang';

const KEEP_ALIVE_TIMEOUT = getConfig('MMP_IDLE_TIMEOUT');

let keepAliveTimer = null;

export function stopKeepAlive() {
  if (keepAliveTimer) {
    clearTimeout(keepAliveTimer);
    keepAliveTimer = null;
  }
}
export function keepAlive() {
  stopKeepAlive();
  return (dispatch, getState) => {
    return checkToken().then(() => {
      let defaultClient = MMPApi.ApiClient.instance;
      fillAppHeaders(defaultClient, getState);

      let api = new MMPApi.AuthCallsApi();

      return api.coreKeepAlivePost(getLang()).then(
        (result) => {
          //send keep alive every minute,
          keepAliveTimer = setTimeout(() => {
            dispatch({
              type: KEEP_ALIVE,
              payload: moment().valueOf() + KEEP_ALIVE_TIMEOUT,
            });
            dispatch(keepAlive());
          }, KEEP_ALIVE_TIMEOUT);
          return Promise.resolve();
        },
        (error) => {
          return dispatch(webTokenOutdated());
          // return dispatch(apiError(error, KEEP_ALIVE));
        },
      );
    });
  };
}

export function fillAppHeaders(client, getState) {
  const userId = getState().auth?.user?.user_id;

  let headers = {};
  headers['X-Client-Type'] = Platform.OS;
  headers['X-App-Package'] = designConstants.isWeb
    ? window.location.hostname
    : DeviceInfo.getBundleId();
  headers['X-App-Version'] = /\d+\.\d+/.exec(
    //Version is defined in package.json and injected here by webpack
    // eslint-disable-next-line no-undef
    designConstants.isWeb ? __VERSION__ : DeviceInfo.getVersion(),
  )[0];
  headers['X-Authenticated-Userid'] = userId || 0;
  client.defaultHeaders = headers;
}

export async function checkToken() {
  return new Promise((resolve, reject) => {
    getStore().then((store) => {
      if (
        store.getState().auth &&
        store.getState().auth.user &&
        store.getState().auth.user.token
      ) {
        let token = store.getState().auth.user.token;
        let defaultClient = MMPApi.ApiClient.instance;
        fillAppHeaders(defaultClient, store.getState);
        let token_type =
          store.getState().auth.user.token_type ||
          Platform.OS.toLowerCase() ||
          'default';
        defaultClient.authentications = {
          token: {
            type: 'apiKey',
            in: 'header',
            name: 'Authorization',
            apiKeyPrefix: token_type,
            apiKey: token,
          },
        };
        resolve();
      } else {
        reject({ result_code: -1, result_msg: 'You are not logged in' });
      }
    });
  });
}

export function clearError() {
  return {
    type: types.CLEAR_ERROR,
  };
}

export function apiError(error, reason, action) {
  return (dispatch, getState) => {
    // console.log('error', JSON.parse(JSON.stringify(error)));
    const synced_first_time = getState().sync.synced_first_time;
    let text =
      error && error.response
        ? error.response.body || error.response.text
        : strings.error_in_data_connection;

    console.log('API Error', reason, text);
    try {
      if (
        typeof text === 'string' &&
        (text.includes('result_msg') || text.includes('error_msg'))
      ) {
        text = JSON.parse(text);
      } else if (typeof text === 'string') {
        text = JSON.parse(
          '{"result_code": "-1", "result_msg":"' + text + '" }',
        );
      } else if (!text.result_msg && !text.error_msg) {
        text = JSON.parse(
          '{"result_code": "-1", "result_msg":"' + text.toString() + '" }',
        );
      }
    } catch (e) {
      console.log('ERROR OF ERROR', e);
      text = {
        result_code: '-1',
        result_msg: strings.error_in_data_connection,
        result_error: e.toString() + '. RAW ' + JSON.stringify(error),
      };
    }
    console.log('TEXT error', text);
    let msg = text.result_msg;
    let code = text.result_code;

    let code176 = code == 176 && reason != 'LOGIN_FORCE_DEVICE_ID';

    if (code != 145 && code != 152 && !code176) {
      //Do not notify us about expired token and account activation requirement - it's useless
      let request = 'NO REQUEST';
      if (error && error.response && error.response.req) {
        request = JSON.parse(JSON.stringify(error.response.req)).data;
        //Hide password and token from being sent
        if (request) {
          request.password && (request.password = 'HIDDEN');
          request.email && (request.email = 'HIDDEN');
          request.prev_token && (request.prev_token = 'HIDDEN');

          //Hide records from being sent
          request.content &&
            typeof request.content.note !== 'undefined' &&
            (request = { data: 'HIDDEN' });
          request.username && (request.username = 'HIDDEN');
          request.access_token && (request.access_token = 'HIDDEN');

          if (request.content) {
            request.content.birth_date &&
              (request.content.birth_date = 'HIDDEN');
            request.content.name && (request.content.name = 'HIDDEN');
            request.content.display_name &&
              (request.content.display_name = 'HIDDEN');
            request.content.providers && (request.content.providers = 'HIDDEN');
            request.content.encryption_key &&
              (request.content.encryption_key = 'HIDDEN');
            request.content.avatar && (request.content.avatar = 'HIDDEN');
          }
        }
        //Stringify it
        request = JSON.stringify(request);
      }
      let description =
        error.response &&
        error.response.req &&
        error.response.req.url + ' ' + request;
      // console.log('DESCRIPTION', description);
      if (code == 143) {
        description =
          error.response &&
          error.response.req &&
          error.response.req.url +
            ' ' +
            request +
            ' ' +
            JSON.stringify(error.response.req.header);
        getStore().then((store) => {
          //TODO: Disabled error 143 detection
          // bugsnag &&
          //   bugsnag.notify(
          //     new Error(
          //       'Error 143 received with token ' +
          //         store.getState().auth.user.token +
          //         ' ' +
          //         description
          //     )
          //   );
        });
      } else if (code != 132 && code != 135 && code != 168) {
        bugsnag &&
          bugsnag.notify(
            error instanceof Error ? error : new Error(msg + ' ' + description),
          ); //, { metaData: {reason: action}});
      }
    }
    if (code == 204) {
      //Seems like we found local record with missing recordId on server
      return dispatch(FindRecordsWithMissingID).then(() => {
        return dispatch(sync());
      });
    }
    if (code == 180) {
      return dispatch(FixDailyReflectionsFromServer).then(() => {
        return dispatch(sync());
      });
    }
    if (code == 227) {
      return dispatch(FixDailyReflections).then(() => {
        return dispatch(sync());
      });
    }
    if (code == 145) {
      // if token expired - get new token and retry
      return getStore().then((store) => {
        let username = store.getState().auth.user.username;
        if (username === 'loading...') {
          const resultError = { result_code: 143, result_msg: msg };
          if (designConstants.isWeb) {
            dispatch(webTokenOutdated());
          } else {
            NavigationService.navigate('errors', {
              screen: 'login143',
              params: { username, error143: true },
            });
          }
          return Promise.reject(resultError);
        }
        let token = store.getState().auth.user.token;
        return dispatch(login(username, null, token))
          .then((result) => {
            return dispatch(saveToken(result));
          })
          .then(() => {
            if (action) {
              return dispatch(action());
            } else {
              return Promise.resolve();
            }
          })
          .catch((error) => {
            return dispatch(apiError(error, types.API_ERROR));
          });
      });
    }

    let resultError = { result_code: code, result_msg: msg };
    if (code == 156) {
      Alert.alert(
        strings.error_app_need_to_be_updated_title,
        strings.error_app_need_to_be_updated_message,
        [
          {
            text: strings.error_app_need_to_be_updated_close_button,
            onPress: () => {},
          },
          {
            text: strings.error_app_need_to_be_updated_update_button,
            onPress: () => {
              updateAppFromStore();
            },
          },
        ],
      );
      return Promise.reject(resultError);
    }
    if (code == 143) {
      //Token invalid - need to enter password
      resultError = {
        result_code: code,
        result_msg: AuthStrings.login_logged_out_warning_text,
      };
      getStore().then((store) => {
        let username =
          store.getState().auth.user && store.getState().auth.user.email;
        if (designConstants.isWeb) {
          dispatch(webTokenOutdated());
        } else {
          setTimeout(() => {
            NavigationService.navigate('errors', {
              screen: 'login143',
              params: { username, error143: true },
            });
          }, 500);
        }
      });
      dispatch({
        type: types.API_ERROR,
        error: AuthStrings.login_logged_out_warning_text,
        errorCode: code,
        reason: reason,
      });

      return Promise.reject(resultError);
    }

    if (code176) {
      //Workaround for new device id style with "_ios" "_apro" "_alite" suffixes
      return getStore().then((store) => {
        let username = store.getState().auth.user.username;
        if (username === 'loading...') {
          const resultError = { result_code: 143, result_msg: msg };
          if (designConstants.isWeb) {
            dispatch(webTokenOutdated());
          } else {
            NavigationService.navigate('errors', {
              screen: 'login143',
              params: { username, error143: true },
            });
          }
          return Promise.reject(resultError);
        }
        let token = store.getState().auth.user.token;
        return dispatch(login(username, null, token, true))
          .then(() => {
            if (action) {
              return dispatch(action());
            } else {
              return Promise.resolve();
            }
          })
          .catch((error) => {
            return dispatch(apiError(error, types.API_ERROR));
          });
      });
    }

    // let noTransition = false;
    //
    // if (
    //     code == 143
    //     || code == 142
    //     || code == 144
    //     || code == 154
    //     || code == 155
    //     || code == 176
    //     // || code == 189
    //     || code == 191
    //     || code == 195
    // ) { // Token is invalid - need to force logout...
    //     dispatch(logout());
    //     noTransition = true;
    // }
    // Try to translate code
    // Not needed
    // msg = strings['server_response_' + code] || msg;

    if (!synced_first_time) {
      setTimeout(
        () => {
          dispatch(syncFailedLogout(true));
        },
        designConstants.isWeb ? 0 : 1000,
      );
      bugsnag &&
        bugsnag.notify(
          error instanceof Error ? error : new Error(JSON.stringify(error)),
        );
      // return Promise.reject({
      //   result_code: 100,
      //   result_msg: SyncStrings.sync_first_sync_failed,
      // });
    }

    dispatch({
      type: types.API_ERROR,
      error: msg,
      errorCode: code,
      reason: reason,
    });

    // if (noTransition) {
    //     resultError.noTransition = noTransition;
    // }

    return Promise.reject(resultError);
  };
}

export function webTokenOutdated() {
  return (dispatch) => {
    dispatch({
      type: WEB_TOKEN_OUTDATED,
      payload: window.location.pathname,
    });
  };
}

export function getAllRecords() {
  return (dispatch) => {};
}
