import React from 'react';
import { connect } from 'react-redux';
import Bugsnag from '@bugsnag/react-native';
import getConfig from '../../../shared/utilities/getConfig';
import BugsnagPerformance from '@bugsnag/browser-performance';

// const bugsnag = Busgnag;
class CrashUsageController extends React.Component {
  constructor(props) {
    super(props);

    this.initCrashReport(this.props);
    this.initUsageReport(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.initCrashReport(props);
    this.initUsageReport(props);
  }

  initCrashReport = (props) => {
    const configuration = {};
    configuration.apiKey = getConfig('MMP_BUGSNAG_API_KEY');
    // Bugsnag.start(configuration);
    // if (props.user && props.user.user_id) {
    //   Bugsnag.setUser(
    //     props.user.user_id.toString(),
    //     'someusername',
    //     'someemail',
    //   );
    // } else {
    //   Bugsnag.setUser(null, null, null);
    // }
  };

  initUsageReport = (props) => {
    if (props.report_usage) {
      BugsnagPerformance.start({
        apiKey: getConfig('MMP_BUGSNAG_API_KEY'),
        // plugins: [bugsnagNavigationPlugin],
      });
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    report_usage: state.settings.report_usage,
    report_crash: state.settings.report_crash,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(CrashUsageController);
export { Bugsnag as bugsnag };
