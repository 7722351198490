import SessionStorage from 'redux-persist/lib/storage/session';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function getAsyncStorage() {
  return AsyncStorage;
}

export default () => {
  return SessionStorage;
};
