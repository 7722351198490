import React from 'react';
import { Platform, StyleSheet, Text as RNText } from 'react-native';
import { designConstants } from '../styles';
import PropTypes from 'prop-types';
import { withTheme } from '../styles/useThemeContext';

// Default text size is 16, default color is black/white (depends on theme), default align is left
class Text extends React.Component {
  getStyle = () => {
    const {
      small,
      medium,
      large,
      black,
      darkGrayRecords,
      lightGray,
      skyBlue,
      lightBlue,
      gray,
      darkGray,
      white,
      blue,
      red,
      redDanger,
      lightGreen,
      note,
      header,
      headerTitle,
      headerSmall,
      headerMedium,
      headerLarge,
      center,
      right,
      bold,
      fontWeight,
    } = this.props;

    const colors = this.props.theme.colors;
    if (headerTitle) {
      const style =
        Platform.OS === 'ios'
          ? styles.whiteTitleForHeaderIOS
          : styles.whiteTitleForHeader;
      return { ...style, color: colors.white };
    }

    const style = {
      fontFamily: designConstants.fontFamily,
      fontSize: designConstants.fontSizes.default,
      color: colors.textPrimary,
      textAlignVertical: 'center',
      textAlign: 'left',
    };

    small && (style.fontSize = designConstants.fontSizes.small);
    medium && (style.fontSize = designConstants.fontSizes.medium);
    large && (style.fontSize = designConstants.fontSizes.large);

    headerSmall && (style.fontSize = designConstants.fontSizes.headerSmall);
    (header || headerMedium) &&
      (style.fontSize = designConstants.fontSizes.headerMedium);
    headerLarge && (style.fontSize = designConstants.fontSizes.headerLarge);

    center && (style.textAlign = 'center');
    right && (style.textAlign = 'right');

    black && (style.color = colors.black);
    lightGray && (style.color = colors.lightGray);
    darkGrayRecords && (style.color = colors.textSecondary);
    skyBlue && (style.color = colors.skyBlue);
    lightBlue && (style.color = colors.lightBlue);
    gray && (style.color = colors.gray);
    darkGray && (style.color = colors.textSecondary);
    red && (style.color = colors.red);
    redDanger && (style.color = colors.redDanger);
    white && (style.color = colors.white);
    blue && (style.color = colors.buttonBlue);
    lightGreen && (style.color = colors.lightGreen);

    fontWeight && (style.fontWeight = fontWeight);
    bold && (style.fontWeight = 'bold');

    if (note) {
      style.fontSize = designConstants.fontSizes.medium;
      style.color = colors.gray;
    }

    return style;
  };

  render() {
    const {
      style,
      children,
      onPress,
      small,
      medium,
      large,
      darkGrayRecords,
      lightGray,
      skyBlue,
      lightBlue,
      gray,
      white,
      blue,
      red,
      lightGreen,
      note,
      header,
      headerTitle,
      headerSmall,
      headerMedium,
      headerLarge,
      center,
      right,
      bold,
      fontWeight,
      ...otherProps
    } = this.props;

    const headerTitileProps = headerTitle
      ? { ellipsize: 'end', numberOfLines: 2 }
      : {};

    return (
      <RNText
        style={[this.getStyle(), style]}
        {...otherProps}
        {...headerTitileProps}
        pointerEvents={headerTitle ? 'none' : null}
        onPress={onPress}
      >
        {children}
      </RNText>
    );
  }
}

Text.propTypes = {
  ...RNText.propTypes,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,

  headerSmall: PropTypes.bool,
  headerMedium: PropTypes.bool,
  headerLarge: PropTypes.bool,
  fontWeight: PropTypes.oneOf(
    'normal',
    'bold',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ),

  center: PropTypes.bool,
  right: PropTypes.bool,

  white: PropTypes.bool,
  blue: PropTypes.bool,
  gray: PropTypes.bool,
  darkGray: PropTypes.bool,
  red: PropTypes.bool,
  redDanger: PropTypes.bool,
  lightGreen: PropTypes.bool,

  lightGray: PropTypes.bool,
  skyBlue: PropTypes.bool,
  lightBlue: PropTypes.bool,

  bold: PropTypes.bool,

  headerTitle: PropTypes.bool,

  header: PropTypes.bool,
  note: PropTypes.bool,
};

Text.defaultProps = {
  small: false,
  medium: false,
  large: false,

  headerSmall: false,
  headerMedium: false,
  headerLarge: false,

  fontWeight: 'normal',

  center: false,
  right: false,

  white: false,
  blue: false,
  gray: false,
  darkGray: false,
  red: false,
  redDanger: false,
  lightGreen: false,

  lightGray: false,
  skyBlue: false,
  lightBlue: false,

  bold: false,

  headerTitle: false,

  header: false,
  note: false,
};

const styles = StyleSheet.create({
  whiteTitleForHeaderIOS: {
    marginLeft: 50,
    marginRight: 50,
    fontSize: designConstants.fontSizes.headerSmall,
    textAlign: 'center',
    fontFamily: designConstants.fontFamily,
    position: 'absolute',
    left: 0,
    right: 0,
  },
  whiteTitleForHeader: {
    flex: 10,
    marginLeft: designConstants.isWeb
      ? 0
      : designConstants.paddings.paddingBetweenElementsLarge,
    marginRight: 20,
    fontSize: designConstants.fontSizes.headerSmall,
    textAlign: 'left',
    textAlignVertical: 'center',
    fontFamily: designConstants.fontFamily,
  },
});
export default withTheme(Text);
