import LocalizedStrings from 'react-native-localization';
import shared from '../strings/shared';

const strings = new LocalizedStrings({
  en: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'en'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: 'S',
    frequency_selector_weekdays_monday_short: 'M',
    frequency_selector_weekdays_tuesday_short: 'T',
    frequency_selector_weekdays_wednesday_short: 'W',
    frequency_selector_weekdays_thursday_short: 'T',
    frequency_selector_weekdays_friday_short: 'F',
    frequency_selector_weekdays_saturday_short: 'S',
    frequency_selector_weekdays_sunday: 'Sunday',
    frequency_selector_weekdays_monday: 'Monday',
    frequency_selector_weekdays_tuesday: 'Tuesday',
    frequency_selector_weekdays_wednesday: 'Wednesday',
    frequency_selector_weekdays_thursday: 'Thursday',
    frequency_selector_weekdays_friday: 'Friday',
    frequency_selector_weekdays_saturday: 'Saturday',

    frequency_selector_frequencies_hourly: 'hour(s)',
    frequency_selector_frequencies_daily: 'day(s)',
    frequency_selector_frequencies_weekly: 'week(s)',
    frequency_selector_frequencies_monthly: 'month(s)',

    frequency_selector_repeat_every_number_label: 'Repeat every',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'en',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'en'),
    frequency_selector_frequency_label: 'Unit of time',
    frequency_selector_start_time_label: shared('Start Time', 'en'),
    frequency_selector_start_time_error: shared("Can't be empty", 'en'),
    frequency_selector_end_time_label: shared('End Time', 'en'),
    frequency_selector_end_time_error: shared("Can't be empty", 'en'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'en'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'en'),
    frequency_selector_monthly_on_day_label: 'Day of month',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'en'),
    frequency_selector_period_picker_header: 'Select unit of time',
    frequency_selector_period_picker_close_button: shared('Close', 'en'),
    frequency_selector_day_of_month_picker_header: 'Select day of month',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'en'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'en'),
    datepicker_cancel_button_text: shared('Cancel', 'en'),
    datepicker_clear_button_text: 'Clear',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: 'Start of Record',
    range_date_picker_record_end_label: 'End of Record',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'en',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'en',
    ),
    edit_date_range_content_all_time: shared('All Time', 'en'),
    edit_date_range_content_custom_time: shared('Custom Range', 'en'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'en'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'en',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'en',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'en'),
    edit_date_range_content_end_date_label: shared('End Date', 'en'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'en'),
    error_dialog_retry_button: shared('Retry', 'en'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'en'),
    list_section_content_additional_values_can_be_shown:
      '{0} additional values can be shown',
    list_section_content_add_new_edit_list_button: 'Add New / Edit List',
    list_section_content_nothing_item_subtitle:
      'If you select this, other options will be unavailable',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'en'),

    section_drawer_select_all_button: 'Select All',
    section_drawer_continue_to_next_section: 'Continue to next Section',
    go_back_button: 'Go Back',
  },
  de: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'de'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: 'So',
    frequency_selector_weekdays_monday_short: 'Mo',
    frequency_selector_weekdays_tuesday_short: 'Di',
    frequency_selector_weekdays_wednesday_short: 'Mi',
    frequency_selector_weekdays_thursday_short: 'Do',
    frequency_selector_weekdays_friday_short: 'Fr',
    frequency_selector_weekdays_saturday_short: 'Sa',
    frequency_selector_weekdays_sunday: 'Sonntag',
    frequency_selector_weekdays_monday: 'Montag',
    frequency_selector_weekdays_tuesday: 'Dienstag',
    frequency_selector_weekdays_wednesday: 'Mittwoch',
    frequency_selector_weekdays_thursday: 'Donnerstag',
    frequency_selector_weekdays_friday: 'Freitag',
    frequency_selector_weekdays_saturday: 'Samstag',

    frequency_selector_frequencies_hourly: 'Stunde(n)',
    frequency_selector_frequencies_daily: 'Tag(e)',
    frequency_selector_frequencies_weekly: 'Woche(n)',
    frequency_selector_frequencies_monthly: 'Monat(e)',

    frequency_selector_repeat_every_number_label: 'Wiederholen alle',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'de',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'de'),
    frequency_selector_frequency_label: 'Zeiteinheit',
    frequency_selector_start_time_label: shared('Start Time', 'de'),
    frequency_selector_start_time_error: shared("Can't be empty", 'de'),
    frequency_selector_end_time_label: shared('End Time', 'de'),
    frequency_selector_end_time_error: shared("Can't be empty", 'de'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'de'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'de'),
    frequency_selector_monthly_on_day_label: 'Tag des Monats',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'de'),
    frequency_selector_period_picker_header: 'Zeiteinheit auswählen',
    frequency_selector_period_picker_close_button: shared('Close', 'de'),
    frequency_selector_day_of_month_picker_header: 'Tag des Monats wählen',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'de'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'de'),
    datepicker_cancel_button_text: shared('Cancel', 'de'),
    datepicker_clear_button_text: 'Löschen',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: 'Beginn des Eintrags',
    range_date_picker_record_end_label: 'Ende des Eintrags',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'de',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'de',
    ),
    edit_date_range_content_all_time: shared('All Time', 'de'),
    edit_date_range_content_custom_time: shared('Custom Range', 'de'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'de'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'de',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'de',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'de'),
    edit_date_range_content_end_date_label: shared('End Date', 'de'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'de'),
    error_dialog_retry_button: shared('Retry', 'de'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'de'),
    list_section_content_additional_values_can_be_shown:
      '{0} weitere Werte können angezeigt werden',
    list_section_content_add_new_edit_list_button:
      'Neue Liste / Liste bearbeiten',
    list_section_content_nothing_item_subtitle:
      'Wenn Sie dies wählen, sind andere Optionen nicht verfügbar',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'de'),

    section_drawer_select_all_button: 'Alles auswählen',
    section_drawer_continue_to_next_section: 'Weiter zum nächsten Abschnitt',
    go_back_button: 'Zurück',
  },

  es: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'es'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: 'dom',
    frequency_selector_weekdays_monday_short: 'lun',
    frequency_selector_weekdays_tuesday_short: 'mar',
    frequency_selector_weekdays_wednesday_short: 'mié',
    frequency_selector_weekdays_thursday_short: 'jue',
    frequency_selector_weekdays_friday_short: 'vie',
    frequency_selector_weekdays_saturday_short: 'sáb',
    frequency_selector_weekdays_sunday: 'domingo',
    frequency_selector_weekdays_monday: 'lunes',
    frequency_selector_weekdays_tuesday: 'martes',
    frequency_selector_weekdays_wednesday: 'miércoles',
    frequency_selector_weekdays_thursday: 'jueves',
    frequency_selector_weekdays_friday: 'viernes',
    frequency_selector_weekdays_saturday: 'sábado',

    frequency_selector_frequencies_hourly: 'hora(s)',
    frequency_selector_frequencies_daily: 'día(s)',
    frequency_selector_frequencies_weekly: 'semana(s)',
    frequency_selector_frequencies_monthly: 'mes(es)',

    frequency_selector_repeat_every_number_label: 'Repetir cada',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'es',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'es'),
    frequency_selector_frequency_label: 'Unidad de tiempo',
    frequency_selector_start_time_label: shared('Start Time', 'es'),
    frequency_selector_start_time_error: shared("Can't be empty", 'es'),
    frequency_selector_end_time_label: shared('End Time', 'es'),
    frequency_selector_end_time_error: shared("Can't be empty", 'es'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'es'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'es'),
    frequency_selector_monthly_on_day_label: 'Día del mes',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'es'),
    frequency_selector_period_picker_header: 'Seleccione unidad de tiempo',
    frequency_selector_period_picker_close_button: shared('Close', 'es'),
    frequency_selector_day_of_month_picker_header: 'Seleccione día del mes',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'es'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'es'),
    datepicker_cancel_button_text: shared('Cancel', 'es'),
    datepicker_clear_button_text: 'Borrar',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: 'Inicio de registro',
    range_date_picker_record_end_label: 'Fin de registro',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'es',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'es',
    ),
    edit_date_range_content_all_time: shared('All Time', 'es'),
    edit_date_range_content_custom_time: shared('Custom Range', 'es'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'es'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'es',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'es',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'es'),
    edit_date_range_content_end_date_label: shared('End Date', 'es'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'es'),
    error_dialog_retry_button: shared('Retry', 'es'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'es'),
    list_section_content_additional_values_can_be_shown:
      'Se pueden mostrar {0} valores adicionales',
    list_section_content_add_new_edit_list_button:
      'Agregar nuevo / Editar lista',
    list_section_content_nothing_item_subtitle:
      'Si selecciona esta, las otras opciones no estarán disponibles',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'es'),

    section_drawer_select_all_button: 'Seleccionar todo',
    section_drawer_continue_to_next_section: 'Continuar a la siguiente sección',
    go_back_button: 'Regresar',
  },

  fr: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'fr'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: 'dim',
    frequency_selector_weekdays_monday_short: 'lun',
    frequency_selector_weekdays_tuesday_short: 'mar',
    frequency_selector_weekdays_wednesday_short: 'mer',
    frequency_selector_weekdays_thursday_short: 'jeu',
    frequency_selector_weekdays_friday_short: 'ven',
    frequency_selector_weekdays_saturday_short: 'sam',
    frequency_selector_weekdays_sunday: 'dimanche',
    frequency_selector_weekdays_monday: 'lundi',
    frequency_selector_weekdays_tuesday: 'mardi',
    frequency_selector_weekdays_wednesday: 'mercredi',
    frequency_selector_weekdays_thursday: 'jeudi',
    frequency_selector_weekdays_friday: 'vendredi',
    frequency_selector_weekdays_saturday: 'samedi',

    frequency_selector_frequencies_hourly: 'heure(s)',
    frequency_selector_frequencies_daily: 'jour(s)',
    frequency_selector_frequencies_weekly: 'semaine(s)',
    frequency_selector_frequencies_monthly: 'mois',

    frequency_selector_repeat_every_number_label: 'Répéter chaque',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'fr',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'fr'),
    frequency_selector_frequency_label: 'Unité de temps',
    frequency_selector_start_time_label: shared('Start Time', 'fr'),
    frequency_selector_start_time_error: shared("Can't be empty", 'fr'),
    frequency_selector_end_time_label: shared('End Time', 'fr'),
    frequency_selector_end_time_error: shared("Can't be empty", 'fr'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'fr'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'fr'),
    frequency_selector_monthly_on_day_label: 'Jour du mois',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'fr'),
    frequency_selector_period_picker_header: "Sélectionnez l'unité de temps",
    frequency_selector_period_picker_close_button: shared('Close', 'fr'),
    frequency_selector_day_of_month_picker_header:
      'Sélectionnez le jour du mois',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'fr'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'fr'),
    datepicker_cancel_button_text: shared('Cancel', 'fr'),
    datepicker_clear_button_text: 'Supprimer',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: "Début de l'enregistrement",
    range_date_picker_record_end_label: "Fin de l'enregistrement",

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'fr',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'fr',
    ),
    edit_date_range_content_all_time: shared('All Time', 'fr'),
    edit_date_range_content_custom_time: shared('Custom Range', 'fr'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'fr'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'fr',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'fr',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'fr'),
    edit_date_range_content_end_date_label: shared('End Date', 'fr'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'fr'),
    error_dialog_retry_button: shared('Retry', 'fr'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'fr'),
    list_section_content_additional_values_can_be_shown:
      '{0} valeurs supplémentaires peuvent être affichées',
    list_section_content_add_new_edit_list_button:
      'Ajouter une nouvelle liste / Modifier une liste',
    list_section_content_nothing_item_subtitle:
      "Si vous sélectionnez ceci, d'autres options ne seront plus disponibles",

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'fr'),

    section_drawer_select_all_button: 'Tout sélectionner',
    section_drawer_continue_to_next_section: 'Passer à la rubrique suivante',
    go_back_button: 'Retourner',
  },

  ko: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'ko'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: '일',
    frequency_selector_weekdays_monday_short: '월',
    frequency_selector_weekdays_tuesday_short: '화',
    frequency_selector_weekdays_wednesday_short: '수',
    frequency_selector_weekdays_thursday_short: '목',
    frequency_selector_weekdays_friday_short: '금',
    frequency_selector_weekdays_saturday_short: '일',
    frequency_selector_weekdays_sunday: '일요일',
    frequency_selector_weekdays_monday: '월요일',
    frequency_selector_weekdays_tuesday: '화요일',
    frequency_selector_weekdays_wednesday: '수요일',
    frequency_selector_weekdays_thursday: '목요일',
    frequency_selector_weekdays_friday: '금요일',
    frequency_selector_weekdays_saturday: '토요일',

    frequency_selector_frequencies_hourly: '시간',
    frequency_selector_frequencies_daily: '일',
    frequency_selector_frequencies_weekly: '주',
    frequency_selector_frequencies_monthly: '개월',

    frequency_selector_repeat_every_number_label: '횟수',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'ko',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'ko'),
    frequency_selector_frequency_label: '시간 단위',
    frequency_selector_start_time_label: shared('Start Time', 'ko'),
    frequency_selector_start_time_error: shared("Can't be empty", 'ko'),
    frequency_selector_end_time_label: shared('End Time', 'ko'),
    frequency_selector_end_time_error: shared("Can't be empty", 'ko'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'ko'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'ko'),
    frequency_selector_monthly_on_day_label: '월일',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'ko'),
    frequency_selector_period_picker_header: '시간 단위 선택',
    frequency_selector_period_picker_close_button: shared('Close', 'ko'),
    frequency_selector_day_of_month_picker_header: '월일 선택',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'ko'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'ko'),
    datepicker_cancel_button_text: shared('Cancel', 'ko'),
    datepicker_clear_button_text: '삭제',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: '기록 시작',
    range_date_picker_record_end_label: '기록 끝',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'ko',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'ko',
    ),
    edit_date_range_content_all_time: shared('All Time', 'ko'),
    edit_date_range_content_custom_time: shared('Custom Range', 'ko'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'ko'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'ko',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'ko',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'ko'),
    edit_date_range_content_end_date_label: shared('End Date', 'ko'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'ko'),
    error_dialog_retry_button: shared('Retry', 'ko'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'ko'),
    list_section_content_additional_values_can_be_shown:
      '{0}개의 추가 값이 나타날 수 있습니다',
    list_section_content_add_new_edit_list_button: '새 항목 추가 / 목록 편집',
    list_section_content_nothing_item_subtitle:
      '이를 선택하면 다른 옵션을 선택할 수 없습니다',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'ko'),

    section_drawer_select_all_button: '전체 선택',
    section_drawer_continue_to_next_section: '다음 섹션으로 이동',
    go_back_button: '돌아 가기',
  },

  ru: {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'ru'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: 'вс',
    frequency_selector_weekdays_monday_short: 'пн',
    frequency_selector_weekdays_tuesday_short: 'вт',
    frequency_selector_weekdays_wednesday_short: 'ср',
    frequency_selector_weekdays_thursday_short: 'чт',
    frequency_selector_weekdays_friday_short: 'пт',
    frequency_selector_weekdays_saturday_short: 'сб',
    frequency_selector_weekdays_sunday: 'воскресенье',
    frequency_selector_weekdays_monday: 'понедельник',
    frequency_selector_weekdays_tuesday: 'вторник',
    frequency_selector_weekdays_wednesday: 'среда',
    frequency_selector_weekdays_thursday: 'четверг',
    frequency_selector_weekdays_friday: 'пятница',
    frequency_selector_weekdays_saturday: 'суббота',

    frequency_selector_frequencies_hourly: 'ч.',
    frequency_selector_frequencies_daily: 'дн',
    frequency_selector_frequencies_weekly: 'нед.',
    frequency_selector_frequencies_monthly: 'мес.',

    frequency_selector_repeat_every_number_label: 'Повторять каждые',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'ru',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'ru'),
    frequency_selector_frequency_label: 'Единица времени',
    frequency_selector_start_time_label: shared('Start Time', 'ru'),
    frequency_selector_start_time_error: shared("Can't be empty", 'ru'),
    frequency_selector_end_time_label: shared('End Time', 'ru'),
    frequency_selector_end_time_error: shared("Can't be empty", 'ru'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'ru'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'ru'),
    frequency_selector_monthly_on_day_label: 'День месяца',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'ru'),
    frequency_selector_period_picker_header: 'Выбрать единицу времени',
    frequency_selector_period_picker_close_button: shared('Close', 'ru'),
    frequency_selector_day_of_month_picker_header: 'Выберите день месяца',
    frequency_selector_day_of_month_picker_close_button: shared('Close', 'ru'),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'ru'),
    datepicker_cancel_button_text: shared('Cancel', 'ru'),
    datepicker_clear_button_text: 'Сбросить',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: 'Начальное время записи',
    range_date_picker_record_end_label: 'Конечное время записи',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'ru',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'ru',
    ),
    edit_date_range_content_all_time: shared('All Time', 'ru'),
    edit_date_range_content_custom_time: shared('Custom Range', 'ru'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'ru'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'ru',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'ru',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'ru'),
    edit_date_range_content_end_date_label: shared('End Date', 'ru'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'ru'),
    error_dialog_retry_button: shared('Retry', 'ru'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'ru'),
    list_section_content_additional_values_can_be_shown:
      'Можно показать доп. значения: {0}',
    list_section_content_add_new_edit_list_button:
      'Добавить нов./изменить список',
    list_section_content_nothing_item_subtitle:
      'Если выбрать этот вариант, другие варианты будут недоступны',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'ru'),

    section_drawer_select_all_button: 'Выбрать все',
    section_drawer_continue_to_next_section: 'Перейти к следующему разделу',
    go_back_button: 'Назад',
  },

  'zh-rCN': {
    // Utilities
    // utilities/contactus.js
    utilities_contactus_email: 'mailto:{0}?subject={1}&body={2}',
    utilities_contactus_email_contact: 'contact@managinglife.com',
    utilities_contactus_email_subject: '{0} Device Contact',
    utilities_contactus_email_error: shared('mail_app_error', 'zh-rCN'),

    // Components
    // EditMedication screen in profile, Medication Instructions section
    // Reminders Section in Settings Screen
    // components/FrequencySelector/FrequencySelector.js
    frequency_selector_weekdays_sunday_short: '周日',
    frequency_selector_weekdays_monday_short: '周一',
    frequency_selector_weekdays_tuesday_short: '周二',
    frequency_selector_weekdays_wednesday_short: '周三',
    frequency_selector_weekdays_thursday_short: '周四',
    frequency_selector_weekdays_friday_short: '周五',
    frequency_selector_weekdays_saturday_short: '周六',
    frequency_selector_weekdays_sunday: '星期日',
    frequency_selector_weekdays_monday: '星期一',
    frequency_selector_weekdays_tuesday: '星期二',
    frequency_selector_weekdays_wednesday: '星期三',
    frequency_selector_weekdays_thursday: '星期四',
    frequency_selector_weekdays_friday: '星期五',
    frequency_selector_weekdays_saturday: '星期六',

    frequency_selector_frequencies_hourly: '小时',
    frequency_selector_frequencies_daily: '天',
    frequency_selector_frequencies_weekly: '周',
    frequency_selector_frequencies_monthly: '月',

    frequency_selector_repeat_every_number_label: '每',
    frequency_selector_repeat_every_number_error: shared(
      "Can't be empty",
      'zh-rCN',
    ),
    frequency_selector_week_days_error: shared("Can't be empty", 'zh-rCN'),
    frequency_selector_frequency_label: '时间单位',
    frequency_selector_start_time_label: shared('Start Time', 'zh-rCN'),
    frequency_selector_start_time_error: shared("Can't be empty", 'zh-rCN'),
    frequency_selector_end_time_label: shared('End Time', 'zh-rCN'),
    frequency_selector_end_time_error: shared("Can't be empty", 'zh-rCN'),
    frequency_selector_time_of_day_label: shared('Time of Day', 'zh-rCN'),
    frequency_selector_time_of_day_error: shared("Can't be empty", 'zh-rCN'),
    frequency_selector_monthly_on_day_label: '日',
    frequency_selector_monthly_on_day_error: shared("Can't be empty", 'zh-rCN'),
    frequency_selector_period_picker_header: '选择时间单位',
    frequency_selector_period_picker_close_button: shared('Close', 'zh-rCN'),
    frequency_selector_day_of_month_picker_header: '选择日',
    frequency_selector_day_of_month_picker_close_button: shared(
      'Close',
      'zh-rCN',
    ),

    // components/datepicker/index.js
    datepicker_ok_button_text: shared('OK', 'zh-rCN'),
    datepicker_cancel_button_text: shared('Cancel', 'zh-rCN'),
    datepicker_clear_button_text: '清除已选择日期',

    // components/RangeDatePicker/index.js
    range_date_picker_record_start_label: '记录开始',
    range_date_picker_record_end_label: '记录结束',

    // components/EditDateRangeContent.js
    edit_date_range_content_clipboard_header_last_7_days: shared(
      'Last 7 days',
      'zh-rCN',
    ),
    edit_date_range_content_clipboard_header_last_30_days: shared(
      'Last 30 days',
      'zh-rCN',
    ),
    edit_date_range_content_all_time: shared('All Time', 'zh-rCN'),
    edit_date_range_content_custom_time: shared('Custom Range', 'zh-rCN'),
    edit_date_range_content_daterange_apply_button: shared('Apply', 'zh-rCN'),
    edit_date_range_content_start_should_be_greater_toast: shared(
      'date_range_error',
      'zh-rCN',
    ),
    edit_date_range_content_start_should_be_greater_button_text: shared(
      'OK',
      'zh-rCN',
    ),
    edit_date_range_content_start_date_label: shared('Start Date', 'zh-rCN'),
    edit_date_range_content_end_date_label: shared('End Date', 'zh-rCN'),

    // components/ErrorDialog.js
    error_dialog_ok_button_text: shared('OK', 'zh-rCN'),
    error_dialog_retry_button: shared('Retry', 'zh-rCN'),

    // components/ListSectionContent.js
    list_section_content_edit_list_upper_button: shared('Edit List', 'zh-rCN'),
    list_section_content_additional_values_can_be_shown:
      '可以显示{0}个其它数值',
    list_section_content_add_new_edit_list_button: '添加 / 编辑列表',
    list_section_content_nothing_item_subtitle:
      '如果您选择这一项，其他选项将不可用',

    // comonents/LoadingScreen.js
    loading_screen_retry_button: shared('Retry', 'zh-rCN'),

    section_drawer_select_all_button: '全选',
    section_drawer_continue_to_next_section: '继续下一部分',
    go_back_button: '返回',
  },
});

export default strings;
