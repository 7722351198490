import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [];

const STRINGS = new LocalizedStrings({
  en: {
    subscriptions_scene_header: 'Subscriptions',
    subscriptions_scene_reminders_and_updates_title: 'Reminders and updates',
    subscriptions_scene_newsletters_title: 'Newsletters',
    subscriptions_scene_special_offers_title: 'Special offers',
    subscriptions_scene_you_sure_text:
      'Are you sure you want to unsubscribe {0} from promotional mailing list sent by ManagingLife?  It may take up to 24 hours for the changes to take effect.',
    subscriptions_scene_yes_unsubscribe_button: 'Yes, Unsubscribe',
    subscriptions_scene_no_keep_subscribe_button:
      'No, I want to stay subscribed',
    subscriptions_scene_save_preferences_button: 'Save Preferences',
    subscriptions_scene_email_preferences_text:
      'Email subscription preferences for {0}',
    subscriptions_scene_failed_to_load: 'Failed to load user subscriptions',
    subscriptions_scene_save_success_toast:
      'Your subscription preferences saved',
    subscriptions_scene_save_failed_toast:
      'Failed to save subscription settings',
    subscriptions_scene_error_try_again_button: shared('Retry', 'en'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'en',
    ),

    settings_scene_version_label: 'Version',
    settings_scene_general_title: 'General',
    settings_scene_reminder_mode_none: 'None',
    settings_scene_reminder_mode_sound: 'Sound',
    settings_scene_reminder_mode_vibration: 'Vibration',
    settings_scene_notifications_title: 'In-app Notifications',
    settings_scene_email_title: 'Email',
    settings_scene_hints_and_tips_label: 'Hints and Tips',
    settings_scene_transitional_pain_service_label: 'Transitional Pain Service',

    report_success_alert_close_button: shared('Close', 'en'),
    report_success_alert_open_button: 'Open Report',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: 'Add Reminder',
    edit_reminder_edit_reminder_header: 'Edit Reminder',
    edit_reminder_next_reminder_label: 'Next Reminder: {0}',
    edit_reminder_frequency_section_label: 'Repeat',
    edit_reminder_notifications_section_label: 'Notifications',
    edit_reminder_sound_label: 'Sound',
    edit_reminder_vibration_label: 'Vibration',
    edit_reminder_ios_sound_label: 'Silent',
    edit_reminder_enabled_label: 'Reminder status',
    edit_reminder_reminders_sequence_label:
      'Reset starting time of reminder to now',
    edit_reminder_create_reminder_footer_button_text: 'Add Reminder',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'en',
    ),
    edit_reminder_delete_confirmation_title: 'Delete reminder?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'en'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'en'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'en',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'en'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'en'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'en'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      'Notifications must be first enabled in Settings',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'en',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'en'),
    settings_privacy_header: 'Privacy',
    settings_privacy_restart_ios_required:
      'Before privacy changes can be applied, discard Manage My Pain from the App Switcher list, and then re-open the app.',
    settings_privacy_restart_android_required:
      'Before privacy changes can be applied, remove Manage My Pain from the Recent Apps list, and then re-open the app.',
    settings_privacy_restart_button: 'Restart now',
    settings_privacy_usage_reporting: 'Allow anonymous usage reporting',
    settings_privacy_crash_reporting: 'Allow crash reporting',
    settings_enable_notifications_in_settings_toast_text:
      'Do not forget to enable notifications in app settings',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'en',
    ),
    settings_request_notifications_permissions_text:
      'Permission is needed before notifications can be displayed.',
    settings_request_notifications_permissions_button_text: 'Allow',
    settings_notifications_battery_saving_mode_alert_text:
      'Power saving mode is enabled on your device, which may prevent you from receiving notifications',
    settings_notifications_battery_saving_mode_alert_button_text:
      'Open power settings',

    // Currently hidden start
    settings_scene_device_settings_title: 'Device',
    settings_scene_language_picker_title: 'Language',
    settings_scene_language_default: 'System Default',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: 'Reminders',
    settings_scene_reminders_settings_subtitle: '(Click a reminder to edit it)',
    settings_scene_add_reminder_button_text: 'Add Reminder',
    settings_scene_reminder_disabled_text: 'Disabled',
    settings_scene_reminder_notification_text: 'Notification:',

    settings_scene_hourly_reminder_text: 'Hourly from {0} to {1}',
    settings_scene_daily_reminder_text: 'Daily at {0}',
    settings_scene_weekly_reminder_text: 'Weekly on {0} at {1}',
    settings_scene_monthly_reminder_text: 'Monthly on day {0} at {1}',
    settings_scene_repeat_every_reminder_text: 'Every {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      'Every {0} hours from {1} to {2}',
    settings_scene_repeat_every_reminder_daily_text: 'Every {0} days at {1}',
    settings_scene_repeat_every_reminder_weekly_text:
      'Every {0} weeks on {1} at {2}',
    settings_scene_repeat_every_reminder_monthly_text:
      'Every {0} months on day {1} at {2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: 'Version {0}',

    // Notifications functionality
    notifications_keepup_content_text: 'Keep up the good work!',
    notifications_keepup_subtext: 'Tap to continue your consistent tracking',
    notifications_subtext: 'Tap to record it',
    notifications_content_text: 'How is your pain?',
    notifications_reflection_content_text: 'How was your day?',
    notifications_add_record_alert_button_text: shared('Add Record', 'en'),
    notifications_cancel_alert_button_text: shared('Cancel', 'en'),
    notifications_reminder_channel_name: 'Reminders',
    notifications_reminder_channel_description:
      'App will remind you to track your pain',

    settings_scene_dark_mode_item: 'App Theme',
    settings_scene_dark_mode_item_dark: 'Dark',
    settings_scene_dark_mode_item_light: 'Light',
    settings_scene_dark_mode_item_default: 'System Default',
  },

  de: {
    subscriptions_scene_header: 'Abonnements',
    subscriptions_scene_reminders_and_updates_title: 'Erinnerungen und Updates',
    subscriptions_scene_newsletters_title: 'Newsletter',
    subscriptions_scene_special_offers_title: 'Sonderangebote',
    subscriptions_scene_you_sure_text:
      'Sind Sie sicher, dass Sie {0} von den ManagingLife E-Mails abmelden möchten? Es kann bis zu 24 Stunden dauern, bis die Änderungen wirksam werden.',
    subscriptions_scene_yes_unsubscribe_button: 'Ja, abmelden',
    subscriptions_scene_no_keep_subscribe_button:
      'Nein, ich möchte angemeldet bleiben',

    subscriptions_scene_save_preferences_button: 'Einstellungen speichern',
    subscriptions_scene_email_preferences_text:
      'E-Mail-Abonnementeinstellungen für {0}',
    subscriptions_scene_failed_to_load:
      'Benutzerabonnements konnten nicht geladen werden',
    subscriptions_scene_save_success_toast:
      'Abonnementeinstellungen gespeichert',
    subscriptions_scene_save_failed_toast:
      'Fehler beim Speichern der Abonnementeinstellungen',

    settings_scene_notifications_title: 'In-App-Benachrichtigungen',
    settings_scene_email_title: 'E-Mail',
    settings_scene_hints_and_tips_label: 'Hinweise und Tipps',

    report_success_alert_close_button: shared('Close', 'de'),
    report_success_alert_open_button: 'Bericht öffnen',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: 'Erinnerung hinzufügen',
    edit_reminder_edit_reminder_header: 'Erinnerung bearbeiten',
    edit_reminder_next_reminder_label: 'Nächste Erinnerung: {0}',
    edit_reminder_frequency_section_label: 'Wiederholen',
    edit_reminder_notifications_section_label: 'Benachrichtigungen',
    edit_reminder_sound_label: 'Ton',
    edit_reminder_vibration_label: 'Vibration',
    edit_reminder_ios_sound_label: 'Lautlos',
    edit_reminder_enabled_label: 'Erinnerungsstatus',
    edit_reminder_reminders_sequence_label:
      'Startzeit der Erinnerung auf jetzt setzen',
    edit_reminder_create_reminder_footer_button_text: 'Erinnerung hinzufügen',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'de',
    ),
    edit_reminder_delete_confirmation_title: 'Erinnerung löschen?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'de'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'de'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'de',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'de'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'de'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'de'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      'Benachrichtigungen müssen zuerst in den Einstellungen aktiviert werden',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'de',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'de'),
    settings_privacy_header: 'Privatsphäre',
    settings_privacy_restart_ios_required:
      'Bevor die Änderungen an den Privatsphäre-Einstellungen angewendet werden können, muss Manage My Pain aus der App-Switcher-Liste entfernt und erneut geöffnet werden.',
    settings_privacy_restart_android_required:
      'Bevor die Änderungen an den Privatsphäre-Einstellungen angewendet werden können, muss Manage My Pain aus der Liste zuletzt verwendeter Apps entfernt und erneut geöffnet werden.',
    settings_privacy_restart_button: 'Jetzt neu starten',
    settings_privacy_usage_reporting:
      'Übermittlung anonymer Nutzungsdaten erlauben',
    settings_privacy_crash_reporting:
      'Übermittlung von Absturzberichten erlauben',
    settings_enable_notifications_in_settings_toast_text:
      'Vergessen Sie nicht, Benachrichtigungen in den Einstellungen der App zu aktivieren',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'de',
    ),
    settings_request_notifications_permissions_text:
      'Erlaubnis wird benötigt, bevor Benachrichtigungen angezeigt werden können.',
    settings_request_notifications_permissions_button_text: 'Erlauben',
    settings_notifications_battery_saving_mode_alert_text:
      'Der Energiesparmodus Ihres Gerätes ist eingeschaltet, was dazu führen kann, dass Sie keine Benachrichtigungen erhalten',
    settings_notifications_battery_saving_mode_alert_button_text:
      'Energieeinstellungen öffnen',

    // Currently hidden start
    settings_scene_device_settings_title: 'Gerät',
    settings_scene_language_picker_title: 'Sprache',
    settings_scene_language_default: 'System-Standardeinstellung',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: 'Erinnerungen',
    settings_scene_reminders_settings_subtitle:
      '(Tippen Sie auf eine Erinnerung, um sie zu bearbeiten)',
    settings_scene_add_reminder_button_text: 'Erinnerung hinzufügen',
    settings_scene_reminder_disabled_text: 'Deaktiviert',
    settings_scene_reminder_notification_text: 'Benachrichtigung:',

    settings_scene_hourly_reminder_text: 'Stündlich von {0} bis {1}',
    settings_scene_daily_reminder_text: 'Täglich um {0}',
    settings_scene_weekly_reminder_text: 'Wöchentlich am {0} um {1}',
    settings_scene_monthly_reminder_text: 'Monatlich am {1}. Tag um {1}',
    settings_scene_repeat_every_reminder_text: 'Alle {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      'Alle {0} Stunden von {1} bis {2}',
    settings_scene_repeat_every_reminder_daily_text: 'Alle {0} Tage um {1}',
    settings_scene_repeat_every_reminder_weekly_text:
      'Alle {0} Wochen am {1} um {2}',
    settings_scene_repeat_every_reminder_monthly_text:
      'Alle {0} Monate  am {1}. Tag um {2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: 'Version {0}',
    subscriptions_scene_error_try_again_button: shared('Retry', 'de'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'de',
    ),

    settings_scene_version_label: 'Version',
    settings_scene_general_title: 'Allgemein',
    settings_scene_reminder_mode_none: 'Keine',
    settings_scene_reminder_mode_sound: 'Ton',
    settings_scene_reminder_mode_vibration: 'Vibration',

    // Notifications functionality
    notifications_keepup_content_text: 'Weiter so!',
    notifications_keepup_subtext:
      'Antippen, um mit der Aufzeichnung fortzufahren',
    notifications_subtext: 'Antippen, um Eintragungen zu machen',
    notifications_content_text: 'Wie sind Ihre Schmerzen?',
    notifications_reflection_content_text: 'Wie war Ihr Tag?',
    notifications_add_record_alert_button_text: shared('Add Record', 'de'),
    notifications_cancel_alert_button_text: shared('Cancel', 'de'),
    notifications_reminder_channel_name: 'Erinnerungen',
    notifications_reminder_channel_description:
      'Die App wird Sie daran erinnern, Ihre Schmerzen nachzuverfolgen',

    settings_scene_dark_mode_item: 'App-Design',
    settings_scene_dark_mode_item_dark: 'Dunkel',
    settings_scene_dark_mode_item_light: 'Hell',
    settings_scene_dark_mode_item_default: 'Systemstandard',
  },

  es: {
    subscriptions_scene_header: 'Suscripciones',
    subscriptions_scene_reminders_and_updates_title:
      'Recordatorios y actualizaciones',
    subscriptions_scene_newsletters_title: 'Boletines de noticias',
    subscriptions_scene_special_offers_title: 'Ofertas especiales',
    subscriptions_scene_you_sure_text:
      '¿Está seguro que desea dar de baja a {0} de la lista de correos electrónicos de ManagingLife? Puede tardar hasta 24 horas para que el cambio tenga efecto.',
    subscriptions_scene_yes_unsubscribe_button: 'Sí, dar de baja',
    subscriptions_scene_no_keep_subscribe_button:
      'No, quiero permanecer suscrito',

    subscriptions_scene_save_preferences_button: 'Guardar preferencias',
    subscriptions_scene_email_preferences_text:
      'Preferencias de {0} para el correo electrónico de la suscripción',
    subscriptions_scene_failed_to_load:
      'No se pudieron cargar las suscripciones del usuario',
    subscriptions_scene_save_success_toast:
      'Se guardaron sus preferencias de suscripción',
    subscriptions_scene_save_failed_toast:
      'No se pudieron guardar los ajustes de suscripción',

    subscriptions_scene_error_try_again_button: shared('Retry', 'es'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'es',
    ),
    settings_scene_notifications_title:
      'Notificaciones dentro de la aplicación',
    settings_scene_email_title: 'Correo electrónico',
    settings_scene_hints_and_tips_label: 'Consejos y sugerencias',

    report_success_alert_close_button: shared('Close', 'es'),
    report_success_alert_open_button: 'Abrir informe',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: 'Agregar recordatorio',
    edit_reminder_edit_reminder_header: 'Editar recordatorio',
    edit_reminder_next_reminder_label: 'Recordatorio siguiente: {0}',
    edit_reminder_frequency_section_label: 'Repetir',
    edit_reminder_notifications_section_label: 'Notificaciones',
    edit_reminder_sound_label: 'Sonido',
    edit_reminder_vibration_label: 'Vibrar',
    edit_reminder_ios_sound_label: 'Silencio',
    edit_reminder_enabled_label: 'Estado del recordatorio',
    edit_reminder_reminders_sequence_label:
      'Restablezca el momento de inicio del recordatorio a "Ahora"',
    edit_reminder_create_reminder_footer_button_text: 'Agregar recordatorio',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'es',
    ),
    edit_reminder_delete_confirmation_title: '¿Eliminar recordatorio?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'es'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'es'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'es',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'es'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'es'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'es'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      'Debe habilitar las notificaciones en Ajustes primero',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'es',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'es'),
    settings_privacy_header: 'Confidencialidad',
    settings_privacy_restart_ios_required:
      'Antes de aplicar los cambios de confidencialidad, elimine Manage My Pain de la lista Últimas aplicaciones y abra de nuevo la aplicación',
    settings_privacy_restart_android_required:
      'Antes de aplicar los cambios de confidencialidad, elimine Manage My Pain de la lista de Aplicaciones recientes y abra de nuevo la aplicación',
    settings_privacy_restart_button: 'Reiniciar ahora',
    settings_privacy_usage_reporting: 'Permitir reporte de uso anónimo',
    settings_privacy_crash_reporting: 'Permitir reporte de falla',
    settings_enable_notifications_in_settings_toast_text:
      'No olvide activar las notificaciones en los ajustes de la aplicación',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'es',
    ),
    settings_request_notifications_permissions_text:
      'Se necesita autorización antes de que se puedan mostrar las notificaciones.',
    settings_request_notifications_permissions_button_text: 'Autorizar',
    settings_notifications_battery_saving_mode_alert_text:
      'Su dispositivo está en modo ahorro de energía, lo que puede impedir que reciba notificaciones',
    settings_notifications_battery_saving_mode_alert_button_text:
      'Abrir configuración de energía',

    // Currently hidden start
    settings_scene_device_settings_title: 'Dispositivo',
    settings_scene_language_picker_title: 'Idioma',
    settings_scene_language_default: 'Predeterminado del sistema',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: 'Recordatorios',
    settings_scene_reminders_settings_subtitle:
      '(Haga clic en un recordatorio para editarlo)',
    settings_scene_add_reminder_button_text: 'Agregar recordatorio',
    settings_scene_reminder_disabled_text: 'Desactivado',
    settings_scene_reminder_notification_text: 'Notificación:',

    settings_scene_hourly_reminder_text: 'Por hora desde las {0} hasta las {1}',
    settings_scene_daily_reminder_text: 'Diariamente a las {0}',
    settings_scene_weekly_reminder_text: 'Semanalmente el {0} a las {1}',
    settings_scene_monthly_reminder_text: 'Mensualmente el día {0} a las {1}',
    settings_scene_repeat_every_reminder_text: 'Cada {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      'Cada {0} horas de {1} a {2}',
    settings_scene_repeat_every_reminder_daily_text: 'Cada {0} días a las {1}',
    settings_scene_repeat_every_reminder_weekly_text:
      'Cada {0} semanas el {1} a las {2}',
    settings_scene_repeat_every_reminder_monthly_text:
      'Cada {0} meses el día {1} a las {2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: 'Versión {0}',

    settings_scene_version_label: 'Versión',
    settings_scene_general_title: 'General',
    settings_scene_reminder_mode_none: 'Ninguno',
    settings_scene_reminder_mode_sound: 'Sonido',
    settings_scene_reminder_mode_vibration: 'Vibrar',

    // Notifications functionality
    notifications_keepup_content_text: '¡Siga así, lo está haciendo bien!',
    notifications_keepup_subtext:
      'Toque para continuar con su seguimiento coherente',
    notifications_subtext: 'Toque para registrarlo',
    notifications_content_text: '¿Cómo está su dolor?',
    notifications_reflection_content_text: '¿Cómo estuvo su día?',
    notifications_add_record_alert_button_text: shared('Add Record', 'es'),
    notifications_cancel_alert_button_text: shared('Cancel', 'es'),
    notifications_reminder_channel_name: 'Recordatorios',
    notifications_reminder_channel_description:
      'La aplicación le recordará que debe registrar su dolor',

    settings_scene_dark_mode_item: 'Tema de la aplicación',
    settings_scene_dark_mode_item_dark: 'Oscuro',
    settings_scene_dark_mode_item_light: 'Claro',
    settings_scene_dark_mode_item_default: 'Predeterminado',
  },

  fr: {
    subscriptions_scene_header: 'Abonnements',
    subscriptions_scene_reminders_and_updates_title: 'Rappels et mises à jour',
    subscriptions_scene_newsletters_title: 'Newsletters',
    subscriptions_scene_special_offers_title: 'Offres spéciales',
    subscriptions_scene_you_sure_text:
      "Êtes-vous sûr(e) de vouloir désabonner l'adresse {0} des emails envoyés par ManagingLife ? La prise en compte de ces changements peut nécessiter jusqu'à 24 heures.",
    subscriptions_scene_yes_unsubscribe_button: 'Oui, me désabonner',
    subscriptions_scene_no_keep_subscribe_button:
      'Non, je veux rester abonné(e)',

    subscriptions_scene_save_preferences_button: 'Enregistrer les préférences',
    subscriptions_scene_email_preferences_text:
      "Préférences d'abonnement aux e-mails pour {0}",
    subscriptions_scene_failed_to_load:
      "Échec du chargement des abonnements de l'utilisateur",
    subscriptions_scene_save_success_toast:
      "Préférences d'abonnement enregistrées",
    subscriptions_scene_save_failed_toast:
      "Échec de l'enregistrement des paramètres d'abonnement",

    subscriptions_scene_error_try_again_button: shared('Retry', 'fr'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'fr',
    ),

    settings_scene_notifications_title:
      "Notifications intégrées à l'application",
    settings_scene_email_title: 'E-mail',
    settings_scene_hints_and_tips_label: 'Conseils et astuces',

    report_success_alert_close_button: shared('Close', 'fr'),
    report_success_alert_open_button: 'Ouvrir le rapport',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: 'Ajouter un rappel',
    edit_reminder_edit_reminder_header: 'Modifier le rappel',
    edit_reminder_next_reminder_label: 'Rappel suivant : {0}',
    edit_reminder_frequency_section_label: 'Répéter',
    edit_reminder_notifications_section_label: 'Notifications',
    edit_reminder_sound_label: 'Sonnerie',
    edit_reminder_vibration_label: 'Vibreur',
    edit_reminder_ios_sound_label: 'Silence',
    edit_reminder_enabled_label: 'État de notification',
    edit_reminder_reminders_sequence_label:
      'Rétablir le moment de début du rappel à partir de "Maintenant"',
    edit_reminder_create_reminder_footer_button_text: 'Ajouter un rappel',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'fr',
    ),
    edit_reminder_delete_confirmation_title: 'Supprimer le rappel ?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'fr'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'fr'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'fr'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'fr'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'fr'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      "Vous devez activer d'abord les notifications dans les paramètres",
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'fr',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'fr'),
    settings_privacy_header: 'Confidentialité',
    settings_privacy_restart_ios_required:
      "Avant d'appliquer les changements de confidentialité, supprimez Manage My Pain dans la liste Commutateur d'appli et lancez l'appli à nouveau",
    settings_privacy_restart_android_required:
      "Avant d'appliquer les changements de confidentialité, supprimez Manage My Pain dans la liste Applications récentes et lancez l'appli à nouveau",
    settings_privacy_restart_button: 'Redémarrer maintenant',
    settings_privacy_usage_reporting: "Permettre le rapport d'usage anonyme",
    settings_privacy_crash_reporting: "Permettre le rapport d'incidents",
    settings_enable_notifications_in_settings_toast_text:
      "N'oubliez pas d'activer les notifications dans Paramètres d'appli",
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'fr',
    ),
    settings_request_notifications_permissions_text:
      'Autorisation requise avant de montrer des notifications.',
    settings_request_notifications_permissions_button_text: 'Autoriser',
    settings_notifications_battery_saving_mode_alert_text:
      "Le mode Économie d'énergie est activé sur votre appareil ; cela peut vous empêcher de recevoir des notifications",
    settings_notifications_battery_saving_mode_alert_button_text:
      "Ouvrir les paramètres d'alimentation",

    // Currently hidden start
    settings_scene_device_settings_title: 'Dispositif',
    settings_scene_language_picker_title: 'Langue',
    settings_scene_language_default: 'Défaut du système',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: 'Rappels',
    settings_scene_reminders_settings_subtitle:
      '(Cliquez sur un rappel pour le modifier)',
    settings_scene_add_reminder_button_text: 'Ajouter un rappel',
    settings_scene_reminder_disabled_text: 'Désactivé',
    settings_scene_reminder_notification_text: 'Notification :',

    settings_scene_hourly_reminder_text: 'Par heure entre {​0} et {​1}',
    settings_scene_daily_reminder_text: 'Chaque jour à {0}',
    settings_scene_weekly_reminder_text: 'Chaque semaine le {​0} à {​1}',
    settings_scene_monthly_reminder_text: 'Chaque mois le {​0} à {​1}',
    settings_scene_repeat_every_reminder_text: 'Chaque {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      'Chaque {0} heures de {1} à {2}',
    settings_scene_repeat_every_reminder_daily_text: 'Chaque {0} jours à {1}',
    settings_scene_repeat_every_reminder_weekly_text:
      'Chaque {0} semaines le {1} à {2}',
    settings_scene_repeat_every_reminder_monthly_text:
      'Chaque {0} mois le jour {1} à {2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: 'Version {0}',

    settings_scene_version_label: 'Version',
    settings_scene_general_title: 'Général',
    settings_scene_reminder_mode_none: 'Aucun',
    settings_scene_reminder_mode_sound: 'Sonnerie',
    settings_scene_reminder_mode_vibration: 'Vibreur',

    // Notifications functionality
    notifications_keepup_content_text: 'Continuez le bon travail !',
    notifications_keepup_subtext:
      "Tapez l'écran pour continuer votre suivi cohérente",
    notifications_subtext: "Tapez pour l'enregistrer",
    notifications_content_text: 'Comment va votre douleur ?',
    notifications_reflection_content_text:
      "Comment s'est passée votre journée ?",
    notifications_add_record_alert_button_text: shared('Add Record', 'fr'),
    notifications_cancel_alert_button_text: shared('Cancel', 'fr'),
    notifications_reminder_channel_name: 'Rappels',
    notifications_reminder_channel_description:
      "L'application vous rappellera d'effectuer un suivi de votre douleur",

    settings_scene_dark_mode_item: "Thème de l'application",
    settings_scene_dark_mode_item_dark: 'Sombre',
    settings_scene_dark_mode_item_light: 'Clair',
    settings_scene_dark_mode_item_default: 'Réglage par défaut du système',
  },

  ko: {
    subscriptions_scene_header: '구독',
    subscriptions_scene_reminders_and_updates_title: '알림 및 업데이트',
    subscriptions_scene_newsletters_title: '뉴스레터',
    subscriptions_scene_special_offers_title: '특별 행사',
    subscriptions_scene_you_sure_text:
      'ManagingLife에서 보낸 이메일에서 {0}의 가입을 취소하시겠습니까? 변경사항이 적용되는 데 최대 24시간이 걸릴 수 있습니다.',
    subscriptions_scene_yes_unsubscribe_button: '예, 구독을 취소합니다.',
    subscriptions_scene_no_keep_subscribe_button:
      '아니요, 구독 상태를 유지하고 싶습니다.',

    subscriptions_scene_save_preferences_button: '맞춤 설정 저장',
    subscriptions_scene_email_preferences_text:
      '{0} 님의 이메일 구독 맞춤 설정',
    subscriptions_scene_failed_to_load: '사용자 구독 로드 실패',
    subscriptions_scene_save_success_toast: '구독 맞춤 설정이 저장됐습니다',
    subscriptions_scene_save_failed_toast: '구독 설정 저장 실패',

    subscriptions_scene_error_try_again_button: shared('Retry', 'ko'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'ko',
    ),

    settings_scene_notifications_title: '앱 내 알림',
    settings_scene_email_title: '이메일',
    settings_scene_hints_and_tips_label: '힌트와 팁',

    report_success_alert_close_button: shared('Close', 'ko'),
    report_success_alert_open_button: '보고서 열기',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: '알림 추가',
    edit_reminder_edit_reminder_header: '알림 편집',
    edit_reminder_next_reminder_label: '다음 미리 알림: {0}',
    edit_reminder_frequency_section_label: '반복',
    edit_reminder_notifications_section_label: '알림',
    edit_reminder_sound_label: '알림 소리',
    edit_reminder_vibration_label: '진동',
    edit_reminder_ios_sound_label: '무음',
    edit_reminder_enabled_label: '알림 상태',
    edit_reminder_reminders_sequence_label:
      '알림 시작 시간을 지금으로 재설정합니다',
    edit_reminder_create_reminder_footer_button_text: '알림 추가',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'ko',
    ),
    edit_reminder_delete_confirmation_title: '미리알림을 삭제합니까?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'ko'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'ko'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'ko'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'ko'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'ko'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      '먼저 설정에서 알림을 활성화해야 합니다',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'ko',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'ko'),
    settings_privacy_header: '개인정보 보호정책',
    settings_privacy_restart_ios_required:
      '개인 정보 변경을 적용하기 전에 앱 스위처 목록에서 Manage My Pain를 삭제한 다음 앱을 다시 엽니다.',
    settings_privacy_restart_android_required:
      '개인 정보 변경 사항을 적용하기 전에 최근 앱 목록에서 Manage My Pain를 제거한 다음 앱을 다시 엽니다.',
    settings_privacy_restart_button: '지금 다시 시작합니다',
    settings_privacy_usage_reporting: '익명 사용 보고를 허용합니다',
    settings_privacy_crash_reporting: '충돌 보고를 허용합니다',
    settings_enable_notifications_in_settings_toast_text:
      '앱 설정에서 알림을 사용하도록 설정하는 것을 잊지 마세요',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'ko',
    ),
    settings_request_notifications_permissions_text:
      '알림을 표시하려면 먼저 사용 권한이 필요합니다.',
    settings_request_notifications_permissions_button_text: '수락',
    settings_notifications_battery_saving_mode_alert_text:
      '전원 절약 모드를 기기에서 켜세요. 이는 알림 수신을 방지합니다.',
    settings_notifications_battery_saving_mode_alert_button_text:
      '전원 설정 열기',

    // Currently hidden start
    settings_scene_device_settings_title: '장치',
    settings_scene_language_picker_title: '언어',
    settings_scene_language_default: '시스템 기본값',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: '미리 알림',
    settings_scene_reminders_settings_subtitle:
      '(미리알림을 클릭하여 편집하세요)',
    settings_scene_add_reminder_button_text: '알림 추가',
    settings_scene_reminder_disabled_text: '비활성화 됨',
    settings_scene_reminder_notification_text: '알림:',

    settings_scene_hourly_reminder_text: '{0}에서 {1}까지 매 시간',
    settings_scene_daily_reminder_text: '매일 {0}',
    settings_scene_weekly_reminder_text: '매주 {0} {1}',
    settings_scene_monthly_reminder_text: '매월 {0}일 {1}',
    settings_scene_repeat_every_reminder_text: '매 {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      '{1}부터 {2}까지 {0}시간마다',
    settings_scene_repeat_every_reminder_daily_text: '{1}에 {0}일마다',
    settings_scene_repeat_every_reminder_weekly_text: '{1} {2}에 {0}주마다',
    settings_scene_repeat_every_reminder_monthly_text: '{1} {2}에 {0}개월마다',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: '버전 {0}',

    settings_scene_version_label: '버전',
    settings_scene_general_title: '일반',
    settings_scene_reminder_mode_none: '없음',
    settings_scene_reminder_mode_sound: '알림 소리',
    settings_scene_reminder_mode_vibration: '진동',

    // Notifications functionality
    notifications_keepup_content_text: '앞으로도 꾸준히 하세요!',
    notifications_keepup_subtext: '일관성 있는 트랙을 계속하려면 눌러주세요',
    notifications_subtext: '기록하려면 눌러주세요',
    notifications_content_text: '통증은 어떻습니까?',
    notifications_reflection_content_text: '오늘 어떠셨나요?',
    notifications_add_record_alert_button_text: shared('Add Record', 'ko'),
    notifications_cancel_alert_button_text: shared('Cancel', 'ko'),
    notifications_reminder_channel_name: '미리 알림',
    notifications_reminder_channel_description:
      '당신의 통증을 기록하도록 앱이 미리알림을 보냅니다',

    settings_scene_dark_mode_item: '앱 테마',
    settings_scene_dark_mode_item_dark: '다크',
    settings_scene_dark_mode_item_light: '라이트',
    settings_scene_dark_mode_item_default: '시스템 기본 설정',
  },

  ru: {
    subscriptions_scene_header: 'Подписки',
    subscriptions_scene_reminders_and_updates_title: 'Напоминания и обновления',
    subscriptions_scene_newsletters_title: 'Новостная рассылка',
    subscriptions_scene_special_offers_title: 'Специальные предложения',
    subscriptions_scene_you_sure_text:
      'Уверены, что хотите отменить подписку на рассылку ManagingLife для адреса {0}? Изменения вступят в силу не ранее чем через 24 часа.',
    subscriptions_scene_yes_unsubscribe_button: 'Да, отменить подписку',
    subscriptions_scene_no_keep_subscribe_button: 'Нет, сохранить подписку',

    subscriptions_scene_save_preferences_button: 'Сохранить настройки',
    subscriptions_scene_email_preferences_text: 'Настройки email-рассылки {0}',
    subscriptions_scene_failed_to_load: 'Ошибка загрузки рассылок пользователя',
    subscriptions_scene_save_success_toast: 'Ваши настройки рассылки сохранены',
    subscriptions_scene_save_failed_toast:
      'Ошибка сохранения настроек рассылки',

    subscriptions_scene_error_try_again_button: shared('Retry', 'ru'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'ru',
    ),

    settings_scene_notifications_title: 'Уведомления в приложении',
    settings_scene_email_title: 'Email',
    settings_scene_hints_and_tips_label: 'Советы и подсказки',

    report_success_alert_close_button: shared('Close', 'ru'),
    report_success_alert_open_button: 'Открыть отчет',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: 'Добавить напоминание',
    edit_reminder_edit_reminder_header: 'Изменить напоминание',
    edit_reminder_next_reminder_label: 'Следующее напоминание: {0}',
    edit_reminder_frequency_section_label: 'Повторить',
    edit_reminder_notifications_section_label: 'Уведомления',
    edit_reminder_sound_label: 'Звук уведомления',
    edit_reminder_vibration_label: 'Вибрация',
    edit_reminder_ios_sound_label: 'Без звука',
    edit_reminder_enabled_label: 'Статус напоминания',
    edit_reminder_reminders_sequence_label:
      'Установить время начала напоминания на текущее',
    edit_reminder_create_reminder_footer_button_text: 'Добавить напоминание',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'ru',
    ),
    edit_reminder_delete_confirmation_title:
      'Точно хотите удалить напоминание?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'ru'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'ru'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'ru'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'ru'),
    edit_reminder_back_confirmation_cancel_button_text: shared('Cancel', 'ru'),
    edit_reminder_enable_notifications_in_settings_toast_text:
      'Сначала нужно включить напоминания в настройках',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'ru',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'ru'),
    settings_privacy_header: 'Конфиденциальность',
    settings_privacy_restart_ios_required:
      'Перед изменением настроек конфиденциальности удалите Manage My Pain из списка Переключателя программ и перезапустите приложение.',
    settings_privacy_restart_android_required:
      'Перед изменением настроек конфиденциальности удалите Manage My Pain из списка недавних программ и перезапустите программу.',
    settings_privacy_restart_button: 'Перезапустить сейчас',
    settings_privacy_usage_reporting:
      'Разрешить передачу анонимных отчётов об использовании',
    settings_privacy_crash_reporting:
      'Разрешить передачу отчетов о сбоях приложения',
    settings_enable_notifications_in_settings_toast_text:
      'Не забудьте включить уведомления в настройках приложения',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'ru',
    ),
    settings_request_notifications_permissions_text:
      'Для показа уведомлений необходимо разрешение.',
    settings_request_notifications_permissions_button_text: 'Разрешить',
    settings_notifications_battery_saving_mode_alert_text:
      'На вашем устройстве включён режим энергосбережения, из-за чего уведомления могут не приходить',
    settings_notifications_battery_saving_mode_alert_button_text:
      'Открыть настройки батареи',

    // Currently hidden start
    settings_scene_device_settings_title: 'Устройство',
    settings_scene_language_picker_title: 'Язык',
    settings_scene_language_default: 'Язык системы',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: 'Напоминания',
    settings_scene_reminders_settings_subtitle:
      '(Нажмите на напоминание, чтобы отредактировать)',
    settings_scene_add_reminder_button_text: 'Добавить напоминание',
    settings_scene_reminder_disabled_text: 'Отключено',
    settings_scene_reminder_notification_text: 'Уведомление:',

    settings_scene_hourly_reminder_text: 'Ежечасно с {0} до {1}',
    settings_scene_daily_reminder_text: 'Ежедневно в {0}',
    settings_scene_weekly_reminder_text: 'Еженедельно в {0} в {1}',
    settings_scene_monthly_reminder_text: 'Ежемесячно, {0} числа в {1}',
    settings_scene_repeat_every_reminder_text: 'Каждые {1} {0}',
    settings_scene_repeat_every_reminder_hourly_text:
      'Каждые {0} ч с {1} до {2}',
    settings_scene_repeat_every_reminder_daily_text: 'Каждые {0} д. в {1}',
    settings_scene_repeat_every_reminder_weekly_text:
      'Каждые {0} нед. в {1} в {2}',
    settings_scene_repeat_every_reminder_monthly_text:
      'Каждые {0} мес. {1} числа в {2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: 'Версия {0}',

    settings_scene_version_label: 'Версия',
    settings_scene_general_title: 'Общие',
    settings_scene_reminder_mode_none: 'Нет',
    settings_scene_reminder_mode_sound: 'Звук',
    settings_scene_reminder_mode_vibration: 'Вибрация',

    // Notifications functionality
    notifications_keepup_content_text:
      'Отлично! Вы уже долгое время не забываете записывать ваше состояние!',
    notifications_keepup_subtext: 'Нажмите здесь, чтобы продолжить ваши записи',
    notifications_subtext: 'Нажмите здесь, чтобы добавить запись',
    notifications_content_text: 'Как ваше самочувствие?',
    notifications_reflection_content_text: 'Как прошел ваш день?',
    notifications_add_record_alert_button_text: shared('Add Record', 'ru'),
    notifications_cancel_alert_button_text: shared('Cancel', 'ru'),
    notifications_reminder_channel_name: 'Напоминания',
    notifications_reminder_channel_description:
      'Приложение напомнит об отслеживании боли',

    settings_scene_dark_mode_item: 'Тема приложения',
    settings_scene_dark_mode_item_dark: 'Темная',
    settings_scene_dark_mode_item_light: 'Светлая',
    settings_scene_dark_mode_item_default: 'Системная',
  },

  'zh-rCN': {
    subscriptions_scene_header: '订阅',
    subscriptions_scene_reminders_and_updates_title: '提醒与更新',
    subscriptions_scene_newsletters_title: '通讯',
    subscriptions_scene_special_offers_title: '特别优惠',
    subscriptions_scene_you_sure_text:
      '您确定要取消订阅由 ManagingLife 发送到 {0} 的电子邮件吗？更改可能需要 24 小时才能生效。',
    subscriptions_scene_yes_unsubscribe_button: '是，取消订阅',
    subscriptions_scene_no_keep_subscribe_button: '否，我想继续订阅',

    subscriptions_scene_save_preferences_button: '保存偏好',
    subscriptions_scene_email_preferences_text: '{0}的邮件订阅偏好',
    subscriptions_scene_failed_to_load: '加载用户订阅失败',
    subscriptions_scene_save_success_toast: '您的订阅设置已保存',
    subscriptions_scene_save_failed_toast: '保存订阅设置失败',

    subscriptions_scene_error_try_again_button: shared('Retry', 'zh-rCN'),
    subscriptions_scene_error_contact_support_button: shared(
      'contact_support',
      'zh-rCN',
    ),

    settings_scene_notifications_title: '应用内通知',
    settings_scene_email_title: '电子邮件',
    settings_scene_hints_and_tips_label: '提示与技巧',

    report_success_alert_close_button: shared('Close', 'zh-rCN'),
    report_success_alert_open_button: '打开报告',

    // scenes/EditReminder.js
    edit_reminder_add_reminder_header: '添加提醒',
    edit_reminder_edit_reminder_header: '编辑提醒',
    edit_reminder_next_reminder_label: '下一步提醒: {0}',
    edit_reminder_frequency_section_label: '重复',
    edit_reminder_notifications_section_label: '通知',
    edit_reminder_sound_label: '声音',
    edit_reminder_vibration_label: '震动',
    edit_reminder_ios_sound_label: '无声',
    edit_reminder_enabled_label: '提醒状态',
    edit_reminder_reminders_sequence_label: '将提醒的开始时间重置为现在',
    edit_reminder_create_reminder_footer_button_text: '添加提醒',
    edit_reminder_save_reminder_footer_button_text: shared(
      'Apply changes',
      'zh-rCN',
    ),
    edit_reminder_delete_confirmation_title: '删除提醒?',
    edit_reminder_delete_confirmation_button_text: shared('Delete', 'zh-rCN'),
    edit_reminder_cancel_confirmation_button_text: shared('Cancel', 'zh-rCN'),
    edit_reminder_back_confirmation_title: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    edit_reminder_back_confirmation_save_button_text: shared('Save', 'zh-rCN'),
    edit_reminder_back_confirmation_no_button_text: shared('Discard', 'zh-rCN'),
    edit_reminder_back_confirmation_cancel_button_text: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_reminder_enable_notifications_in_settings_toast_text:
      '必须先在“设置”中启用通知',
    edit_reminder_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'zh-rCN',
    ),

    // Settings screen
    // scenes/SettingScene.js
    settings_scene_header: shared('Settings', 'zh-rCN'),
    settings_privacy_header: '隐私',
    settings_privacy_restart_ios_required:
      '要让隐私更改生效，请从“应用程序切换”列表中关闭Manage My Pain，然后重新打开该应用。',
    settings_privacy_restart_android_required:
      '要让隐私更改生效，请从“最近的应用”列表中删除Manage My Pain，然后重新打开该应用。',
    settings_privacy_restart_button: '现在重启',
    settings_privacy_usage_reporting: '允许匿名使用报告',
    settings_privacy_crash_reporting: '允许故障报告',
    settings_enable_notifications_in_settings_toast_text:
      '请不要忘记在应用设置中启用通知',
    settings_enable_notifications_in_settings_toast_ok_button: shared(
      'OK',
      'zh-rCN',
    ),
    settings_request_notifications_permissions_text: '在显示通知之前需要权限。',
    settings_request_notifications_permissions_button_text: '允许',
    settings_notifications_battery_saving_mode_alert_text:
      '省电模式已经开启，你可能无法接收通知',
    settings_notifications_battery_saving_mode_alert_button_text:
      '打开电源设置',

    // Currently hidden start
    settings_scene_device_settings_title: '设备',
    settings_scene_language_picker_title: '语言',
    settings_scene_language_default: '系统默认',
    settings_scene_language_en: 'English',
    settings_scene_language_de: 'Deutsch',
    settings_scene_language_es: 'Español',
    settings_scene_language_fr: 'Français',
    settings_scene_language_ko: '한국어',
    settings_scene_language_ru: 'Русский',
    'settings_scene_language_zh-rCN': '简体中文',
    // Currently hidden end

    settings_scene_reminders_settings_title: '提醒',
    settings_scene_reminders_settings_subtitle: '(单击提醒进行编辑）',
    settings_scene_add_reminder_button_text: '添加提醒',
    settings_scene_reminder_disabled_text: '已禁用',
    settings_scene_reminder_notification_text: '通知：',

    settings_scene_hourly_reminder_text: '{0}到{1}间每小时',
    settings_scene_daily_reminder_text: '每天{0}',
    settings_scene_weekly_reminder_text: '每周{0}{1}',
    settings_scene_monthly_reminder_text: '每月{0}日{1}',
    settings_scene_repeat_every_reminder_text: '每{1}{0}',
    settings_scene_repeat_every_reminder_hourly_text: '{1}到{2}间每{0}小时',
    settings_scene_repeat_every_reminder_daily_text: '每{0}天{1}',
    settings_scene_repeat_every_reminder_weekly_text: '每 {0}周{1}{2}',
    settings_scene_repeat_every_reminder_monthly_text: '每 {0}月{1}日{2}',

    settings_scene_app_name_bottom_header: 'Manage My Pain',
    settings_scene_app_version_bottom_label: '版本{0}',

    settings_scene_version_label: '版本',
    settings_scene_general_title: '一般',
    settings_scene_reminder_mode_none: '没有',
    settings_scene_reminder_mode_sound: '声音',
    settings_scene_reminder_mode_vibration: '震动',

    // Notifications functionality
    notifications_keepup_content_text: '您做得很好，请继续坚持！',
    notifications_keepup_subtext: '点击以持续跟踪',
    notifications_subtext: '点击以进行记录',
    notifications_content_text: '您的疼痛怎么样了？',
    notifications_reflection_content_text: '您今天过得怎么样？',
    notifications_add_record_alert_button_text: shared('Add Record', 'zh-rCN'),
    notifications_cancel_alert_button_text: shared('Cancel', 'zh-rCN'),
    notifications_reminder_channel_name: '提醒',
    notifications_reminder_channel_description:
      '应用程式会提醒您追踪自己的痛苦狀況',

    settings_scene_dark_mode_item: '应用主题',
    settings_scene_dark_mode_item_dark: '深色模式',
    settings_scene_dark_mode_item_light: '浅色模式',
    settings_scene_dark_mode_item_default: '系统默认',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
