let currentScreen = 'noscreen';
let usageStatisticsEnabled = true;
let answersEnabled = true;
export { usageStatisticsEnabled, answersEnabled };

export function ScreenTracker(props) {
  return null;
}
export function setTrackingEnabled(value, enableAnswers) {}

export function trackMenuPress(button) {}

export function trackButtonPress(button, value) {}

export function trackGoal(goal, label) {}
