import { FIELDS_ALL } from '../Fields/constants';
import {
  deleteMedicationOfflineMeds,
  isSameCondition,
  isSameMedication,
  mergeDosages,
  mergePurposes,
  removeDeletedMeds,
} from '../Meds/actions';
import * as types from '../Meds/actiontypes';
import { formatMoment } from '../../shared/utilities/timeutils';
import { setUser, togglePro, toggleSubscription } from '../Auth/actions';
import { Platform } from 'react-native';
import { RATE_CARD } from '../Feed/reducer';
import { setRated } from '../Profile/actions';
import { MILLISECONDS_IN_DAY } from '../Filter/filter';
import { EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID } from '../Profile/actiontypes';
import {
  trackAddMedication,
  trackAddPainCondition,
} from '../../shared/utilities/answers';
import { trackGoal } from '../../shared/utilities/gatracker';
import { bugsnag } from '../Settings/components/CrashUsageController';
import { allSettledSeq } from '../../shared/utilities/allSettled';
import * as RNIap from 'react-native-iap';
import {
  addPurchase,
  completePurchase,
  purchasesRestored,
} from '../Iap/actions';
import {
  SUBSCRIBE_ANNUAL_PRODUCT_ID,
  SUBSCRIBE_PRODUCT_ID,
} from '../Iap/reducer';
import * as MMPApi from 'apiclient';
import { getLang } from '../../shared/utilities/getLang';

//Usually we send all records at once.
//But sometimes sync of just single record is failed. And in this situation we will try to send records one by one
export function syncRecords(server, store, dispatch, oneByOne) {
  let deletedRecords = store.records.deleted.filter(
    (record) => record.idOnServer,
  ); //Take only records which were already created on server
  let local = store.records.list.slice().concat(deletedRecords);
  let localIdMap = {};

  let unsynced = local
    .filter((rec) => rec.flag_updated)
    .map((rec) => {
      return { ...rec };
    });
  let flagsToClear = unsynced.slice();
  local.forEach((rec) => {
    localIdMap[rec.idOnServer] = rec;
  });

  let fields = {};

  let customLists = store.fields;
  //We need map of "field name > field" to faster search fields by name
  FIELDS_ALL.forEach((field) => {
    if (customLists[field]) {
      fields[field] = {};
      customLists[field].forEach((f) => {
        if (f && f.name) {
          fields[field][f.name] = f;
        }
      });
    }
  });

  // if(server.recordsList.length>0) {
  //     let handled = unsynced.filter(rec => server.recordsList.some(record => record.created===rec.createDate));
  //     unsynced = unsynced.filter(rec => server.recordsList.none(record => record.created===rec.createDate));
  // }
  let recordsToCreate = unsynced.filter((f) => {
    return !f.idOnServer;
  });
  let recordsToUpdate = unsynced.filter((f) => {
    return f.idOnServer && !f.flag_deleted;
  });
  let recordsToDelete = unsynced.filter((f) => {
    return f.idOnServer && f.flag_deleted;
  });

  let recordsUpdated = server.recordsList.slice();
  let idsChanged = [];

  //Search if any record which came from server is "new"
  //It is needed to avoi unnecessary records filtering if no records were updated
  //UpdateDate will be different, because it updated on server side already...
  server.recordsList &&
    server.recordsList.forEach((record) => {
      let localRecord = local.find((rec) => rec.createDate === record.created);
      if (localRecord && localRecord.localUpdated === record.updated_local) {
        let index = recordsUpdated.indexOf(record);
        if (index >= 0) {
          recordsUpdated.splice(index, 1);
        }
      }
    });

  //Search server records locally in unsynced ones
  //We do it to prevent situations when records were sent to server correctly, but response was not received,
  //and it prevents for multiple records created on server this way
  unsynced.forEach((rec) => {
    let found =
      server.recordsList &&
      server.recordsList.find((record) => record.created === rec.createDate);
    if (found) {
      let toCreate = recordsToCreate.find(
        (rec) => rec.createDate === found.created,
      );
      if (toCreate) {
        recordsToCreate.splice(recordsToCreate.indexOf(toCreate), 1);
        let record = { ...rec, idOnServer: found.record_id };
        idsChanged.push(record);
        //Update if record was updated locally...
        if (toCreate.localUpdated > found.updated_local) {
          recordsToUpdate.push(record);
        }
      }
    }
  });

  //If local records have daily reflections with same dates which we have on server already - error will be thrown.
  //This code properly handles this situation, and updates current record instead of trying to create new one
  let serverReflections = server.recordsList.filter(
    (record) =>
      record.content && record.content.pain_record_type === 'DailyReflection',
  );
  let localReflections = [...recordsToCreate, ...recordsToUpdate].filter(
    (record) => record.type === 'DailyReflection',
  );

  //Firstly, we need to check local records for duplicated dates for daily reflections
  localReflections.forEach((a) => {
    let aTime = formatMoment(a.recordTime, a.timeOffset);
    if (!a.deleted) {
      localReflections.forEach((b) => {
        if (a !== b) {
          let bTime = formatMoment(b.recordTime, b.timeOffset);
          if (!b.deleted && bTime.isSame(aTime, 'day')) {
            if (a.createDate > b.createDate) {
              b.deleted = true;
              if (recordsToCreate.indexOf(b) >= 0) {
                recordsToCreate.splice(recordsToCreate.indexOf(b), 1);
              } else {
                recordsToUpdate.splice(recordsToUpdate.indexOf(b), 1);
              }
              local.splice(local.indexOf(b), 1);
            } else {
              a.deleted = true;
              if (recordsToCreate.indexOf(a) >= 0) {
                recordsToCreate.splice(recordsToCreate.indexOf(a), 1);
              } else {
                recordsToUpdate.splice(recordsToUpdate.indexOf(a), 1);
              }
              local.splice(local.indexOf(a), 1);
            }
          }
        }
      });
    }
  });

  localReflections.forEach((record) => {
    let localTime = formatMoment(record.recordTime, record.timeOffset);
    let sameDayReflection = serverReflections.find((rec) => {
      let time = formatMoment(rec.content.timing, rec.content.time_offset);
      return (
        rec.created !== record.createDate &&
        !rec.deleted &&
        time.isSame(localTime, 'day')
      );
    });
    if (sameDayReflection) {
      recordsToCreate.splice(recordsToCreate.indexOf(record), 1);
      local.splice(local.indexOf(record), 1);
      record = { ...record };
      /*
        We are unable to update time_offset on server,
        so we have to re-calculate local recordTime in server's time_offset
      */
      record.recordTime =
        record.recordTime +
        record.timeOffset -
        sameDayReflection.content.time_offset;
      record.timeOffset = sameDayReflection.content.time_offset;
      //If local record is newer, then we need to send it
      if (record.localUpdated > sameDayReflection.updated_local) {
        recordsToUpdate.push(record);
      }
      //idsChanged should contain old "createDate", because we search by that to clear "updated" and "created" flags
      idsChanged.push({ ...record });
      record.idOnServer = sameDayReflection.record_id;
      record.createDate = sameDayReflection.created;
    }
  });

  if (
    recordsToCreate.length > 0 ||
    recordsToUpdate.length > 0 ||
    recordsToDelete.length > 0 ||
    idsChanged.length > 0
  ) {
    if (oneByOne) {
      (recordsToCreate.length > 50 ||
        recordsToUpdate.length > 50 ||
        recordsToDelete.length > 50) &&
        bugsnag &&
        bugsnag.notify(
          new Error(
            'Sync fail limit reached. Trying to create records one by one.' +
              ' C:' +
              recordsToCreate.length +
              ' U:' +
              recordsToUpdate.length +
              ' D:' +
              recordsToDelete.length,
          ),
        );
      bugsnag &&
        bugsnag.leaveBreadcrumb(
          'Sync fail limit reached. One by one.' +
            ' C:' +
            recordsToCreate.length +
            ' U:' +
            recordsToUpdate.length +
            ' D:' +
            recordsToDelete.length,
        );

      recordsToCreate.length > 0 &&
        recordsToCreate.length < 10 &&
        recordsToCreate.forEach((rec) => {
          bugsnag &&
            bugsnag.leaveBreadcrumb(
              'C:' +
                rec.idOnServer +
                ' ' +
                rec.createDate +
                ' ' +
                rec.localUpdated +
                ' ' +
                rec.recordTime +
                ' ' +
                rec.timeOffset +
                ' ' +
                rec.type,
            );
        });
      recordsToUpdate.length > 0 &&
        recordsToUpdate.length < 10 &&
        recordsToCreate.forEach((rec) => {
          bugsnag &&
            bugsnag.leaveBreadcrumb(
              'U:' +
                rec.idOnServer +
                ' ' +
                rec.createDate +
                ' ' +
                rec.localUpdated +
                ' ' +
                rec.recordTime +
                ' ' +
                rec.timeOffset +
                ' ' +
                rec.type,
            );
        });
      recordsToDelete.length > 0 &&
        recordsToDelete.length < 10 &&
        recordsToCreate.forEach((rec) => {
          bugsnag &&
            bugsnag.leaveBreadcrumb(
              'D:' +
                rec.idOnServer +
                ' ' +
                rec.createDate +
                ' ' +
                rec.localUpdated +
                ' ' +
                rec.recordTime +
                ' ' +
                rec.timeOffset +
                ' ' +
                rec.type,
            );
        });
    }

    return Promise.resolve()
      .then((result) => {
        //Then delete records
        if (recordsToDelete.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.RecordCallsApi();
        let records = recordsToDelete.map((rec) =>
          MMPApi.PainRecord.constructFromObject(recordToServer(rec, fields)),
        );
        if (!oneByOne) {
          return api.syncDeleteRecordsPost(getLang(), { params: records });
        } else {
          return allSettledSeq(
            records.map((record) => {
              return () =>
                api.syncDeleteRecordPost(getLang(), record).catch((err) => {
                  flagsToClear = flagsToClear.filter(
                    (rec) => rec.createDate !== record.created,
                  );
                });
            }),
          ).then((results) => {
            results.forEach((status, index) => {
              bugsnag &&
                bugsnag.leaveBreadcrumb(
                  'Deleting record ' + index + ' ' + status.status,
                );
              if (status.status === 'rejected') {
                bugsnag.notify(
                  new Error('Found record deletion error on ' + status.reason),
                );
              }
            });
          });
        }
      })
      .then((result) => {
        //Then update records
        if (recordsToUpdate.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.RecordCallsApi();
        let records = recordsToUpdate.map((rec) =>
          MMPApi.PainRecord.constructFromObject(recordToServer(rec, fields)),
        );
        if (!oneByOne) {
          return api.syncUpdateRecordsPost(getLang(), { params: records });
        } else {
          return allSettledSeq(
            records.map((record) => {
              return () =>
                api.syncUpdateRecordPost(getLang(), record).catch((err) => {
                  flagsToClear = flagsToClear.filter(
                    (rec) => rec.createDate !== record.created,
                  );
                });
            }),
          ).then((results) => {
            results.forEach((status, index) => {
              bugsnag &&
                bugsnag.leaveBreadcrumb(
                  'Updating record ' + index + ' ' + status.status,
                );
              if (status.status === 'rejected') {
                bugsnag.notify(
                  new Error('Found record updating error on ' + status.reason),
                );
              }
            });
          });
        }
      })
      .then((result) => {
        //Create records
        if (recordsToCreate.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.RecordCallsApi();
        let records = recordsToCreate.map((rec) => {
          let serverRecord = recordToServer(rec, fields);
          let record = MMPApi.PainRecord.constructFromObject(serverRecord);
          return record;
        });
        if (!oneByOne) {
          return api.syncCreateRecordsPost(getLang(), { params: records });
        } else {
          return allSettledSeq(
            records.map((record) => {
              return () =>
                api
                  .syncCreateRecordPost(getLang(), { params: record })
                  .catch((err) => {
                    flagsToClear = flagsToClear.filter(
                      (rec) => rec.createDate !== record.created,
                    );
                  });
            }),
          ).then((results) => {
            results.forEach((status, index) => {
              bugsnag &&
                bugsnag.leaveBreadcrumb(
                  'Creating record ' + index + ' ' + status.status,
                );
              if (status.status === 'rejected') {
                bugsnag.notify(
                  new Error('Found record creation error on ' + status.reason),
                );
              }
            });
            return results.map((result) => {
              if (result.status === 'rejected') {
                return 0;
              }
              return result?.value?.id;
            });
          });
        }
      })
      .then((result) => {
        // throw new Error();
        //Parse IDs
        // console.log('IDONSERVER', result);
        if (
          result instanceof Array &&
          result.length === recordsToCreate.length
        ) {
          for (let i = 0; i < result.length; i++) {
            idsChanged.push({ ...recordsToCreate[i], idOnServer: result[i] });
          }
        }
        return Promise.resolve();
      })
      .then((result) => {
        if (oneByOne) {
          bugsnag &&
            bugsnag.notify(
              new Error(
                'Debug catch about records one by one creation ' +
                  flagsToClear.length,
              ),
            );
        }
        return {
          secondSync: flagsToClear.length > 0, //Second sync is required only if we sent some record to server
          server: server,
          changed: idsChanged,
          flagsToClear: flagsToClear,
          someRecordSyncFailed: flagsToClear.length !== unsynced.length,
        };
      });
  }

  let records = [];
  server.recordsList.forEach((rec) => {
    let localRec = recordToLocal(rec, fields);
    records.push(localRec);
  });

  //Sync records
  // local = local.map((rec) => {
  //     if (localIdMap[rec.idOnServer]) {
  //         return localIdMap[rec.idOnServer];
  //     }
  //     return rec;
  // });

  //Id will be set in reducer, because "local" contains both deleted and not deleted records
  // for (let i = 0; i < local.length; i++) {
  //     local[i].id = i;
  // }
  return Promise.resolve({
    records,
    server,
    recordsUpdated: recordsUpdated.length > 0,
  });
}

async function checkSubscription(
  dispatch,
  serverSubscriber,
  subscription_market,
) {
  const purchases = await RNIap.getAvailablePurchases();
  const purchase = purchases.find(
    (purchase) => purchase.productId == SUBSCRIBE_PRODUCT_ID,
  );
  if (!purchase) {
    //If user is subscriber on server side, then we should enable subscription, but tell user its not done on this device
    if (serverSubscriber) {
      dispatch(toggleSubscription(true, false, false));
      return dispatch(togglePro(true, false));
    }
    dispatch(
      toggleSubscription(
        serverSubscriber,
        (subscription_market === 'apple' && Platform.OS === 'ios') ||
          (subscription_market === 'google' && Platform.OS === 'android'),
        true,
      ),
    );
    return dispatch(togglePro(true, false));
  }
  return new Promise((resolve, reject) => {
    const toggle = toggleSubscription;
    return dispatch(
      completePurchase(
        purchase,
        purchase.productId === SUBSCRIBE_PRODUCT_ID ||
          purchase.productId === SUBSCRIBE_ANNUAL_PRODUCT_ID,
      ),
    )
      .then(() => {
        dispatch(addPurchase(purchase));
        return dispatch(toggle(true, true, false)); //Ok, we still need to set "pro" even if it was successful response from server...
      })
      .catch((err) => {
        //Some error happened. But we still need to set "pro" locally (but not for subscription
        if (err.result_code == 199) {
          //199 - Receipt already consumed, 1001 - receipt consumed by another user
          dispatch(addPurchase(purchase));
          return dispatch(togglePro(true, false));
        }
        if (err.result_code == 1001) {
          dispatch(addPurchase(purchase));
          return dispatch(toggle(true, true, false));
        }
        if (err.result_code == 1003 || err.result_code == 1007) {
          //1003 - purchase expired. Just set "pro" in this case, 1007 - purchase is expired, and belongs to another user
          //Set subscription expired
          dispatch(
            toggleSubscription(
              serverSubscriber,
              (subscription_market === 'apple' && Platform.OS === 'ios') ||
                (subscription_market === 'google' && Platform.OS === 'android'),
              true,
            ),
          );

          return dispatch(togglePro(true, false));
        }
        return resolve();
      });
  }).then(() => {
    dispatch(purchasesRestored());
    return Promise.resolve();
  });
}

//Here we need dispatch, but that's bad practice =\
export function syncUserObject(server, store, dispatch) {
  if (server.userRecord) {
    dispatch(setUser(server.userRecord));

    //Here we need to check subscription, as it could be paused/cancelled/etc
    const is_subscription =
      store.iap.is_subscription && store.iap.is_subscription_managed;
    const subscription_market = store.iap.subscription_market;
    if (is_subscription != server.userRecord?.content?.is_subscriber) {
      checkSubscription(
        dispatch,
        server.userRecord?.content?.is_subscriber,
        subscription_market,
      );
    }
  }
  return server;
}

//Here we need dispatch, but that's bad practice =\
export function syncMyProfile(server, store, dispatch) {
  if (server.myProfile) {
    let profile = { ...server.myProfile };
    profile.content = { ...profile.content };
    if (
      store.feed.cards &&
      store.feed.cards.find((c) => c.type === RATE_CARD)
    ) {
      let localRated = store.feed.cards.find(
        (c) => c.type === RATE_CARD,
      ).hidden;
      let serverRated =
        profile.content[
          Platform.OS === 'android' ? 'android_rn_rated' : 'apple_rn_rated'
        ] == '1';
      if (localRated && localRated !== serverRated) {
        profile.content[
          Platform.OS === 'android' ? 'android_rn_rated' : 'apple_rn_rated'
        ] = '1';
        return dispatch(setRated()).then(() => {
          return Promise.resolve({ profile, server });
        });
      }
    }
    return { profile, server };
  }
  return { server };
}

export function syncOfflineMedicationsAndPainConditions(
  server,
  _store,
  dispatch,
  getState,
) {
  // if (!server.myProfile) {
  //     return {server};
  // }

  const store = getState();
  if (!server.myProfile && !store.profile && !store.profile.content) {
    return { server };
  }

  if (
    !(server.myProfile || store.profile) ||
    !(server.myProfile || store.profile).content
  ) {
    return { server };
  }

  let _localMedications =
    (store.profile.content && store.profile.content.medications) || [];
  let _localConditions =
    (store.profile.content && store.profile.content.condition) || [];

  let serverMedications = (server.myProfile || store.profile).content
    .medications;
  let serverConditions = (server.myProfile || store.profile).content.condition;

  let localMedications = _localMedications.filter(
    (med) => med.flag_created || med.flag_updated,
  );
  let localConditions = _localConditions.filter(
    (cond) => cond.flag_created || cond.flag_updated,
  );
  const localConditionsNewFids = [];
  const localConditionsNewFidsMap = {};
  const deleted =
    store.meds && store.meds.deleted
      ? store.meds.deleted
      : { condition: [], medications: [] };

  const was_deleted =
    deleted.condition.length > 0 || deleted.medications.length > 0;

  let api = new MMPApi.MedicationsApi();

  return Promise.resolve()
    .then(() => {
      const fids = [];
      deleted.condition.forEach((condition) => {
        if (condition.fid > 0) {
          fids.push(condition.fid);
        }
      });

      let api = new MMPApi.ConditionsApi();
      if (fids.length === 0) {
        return Promise.resolve();
      }

      return Promise.all(
        fids.map((fid) => {
          let params = MMPApi.DeleteConditionParams.constructFromObject({
            fid: fid,
          });
          return api
            .syncDeleteConditionPost(getLang(), params)
            .catch((error) => {
              const code =
                error &&
                error.response &&
                error.response.body &&
                error.response.body.result_code;
              // If condition is already exists or not found - it is ok
              if (!code || (code != '216' && code != '215')) {
                throw error;
              }
              return Promise.resolve();
            });
        }),
      );
    })
    .then(() => {
      const nids = [];
      deleted.medications.map((medication) => {
        if (medication.nid > 0) {
          nids.push(medication.nid);
        }
      });

      let api = new MMPApi.MedicationsApi();
      if (nids.length === 0) {
        return Promise.resolve();
      }

      return Promise.all(
        nids.map((nid) => {
          let params = MMPApi.DeleteMedicationParams.constructFromObject({
            nid: nid,
          });
          return api
            .syncDeleteMedicationPost(getLang(), params)
            .catch((error) => {
              const code =
                error &&
                error.response &&
                error.response.body &&
                error.response.body.result_code;
              // If medication is not found - it is ok
              if (!code || code != '212') {
                throw error;
              }
              return Promise.resolve();
            });
        }),
      );
    })
    .then(() => {
      dispatch(removeDeletedMeds(false, true));
    })
    .then(() => {
      return Promise.all(
        localConditions.map((condition) => {
          condition = { ...condition };

          const old_fid = condition.fid;

          let serverCondition = serverConditions.find((serv) =>
            isSameCondition(serv, condition),
          );

          const addPainCondition = () => {
            let params =
              MMPApi.ApiPainConditionObject.constructFromObject(condition);
            let api = new MMPApi.ConditionsApi();

            return api
              .syncAddConditionPost(getLang(), { condition: params })
              .then((result) => {
                const original_fid =
                  condition.fid < 0 ? condition.original_fid : condition.fid;
                condition.fid = result.fid;
                localConditionsNewFids.push(result.fid);

                delete condition.flag_created;
                delete condition.flag_updated;
                delete condition.flag_deleted;

                condition.original_fid = original_fid;

                dispatch({
                  type: types.EDIT_PAIN_CONDITION_SUCCESS,
                  payload: condition,
                });

                // Substitute fid of purpose in localMedications if it was changed
                if (original_fid > 0 && original_fid != condition.fid) {
                  localConditionsNewFidsMap[original_fid] = condition.fid;
                }
                dispatch({
                  type: EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID,
                  payload: {
                    old_fid: original_fid,
                    new_fid: condition.fid,
                  },
                });
                return Promise.resolve();
              })
              .catch((error) => {
                const code =
                  error &&
                  error.response &&
                  error.response.body &&
                  error.response.body.result_code;
                // If condition is already exists or not found - it is ok
                if (!code || (code != '216' && code != '215')) {
                  throw error;
                }
                return Promise.resolve();
              });
          };
          if (serverCondition) {
            condition.fid = serverCondition.fid;
            condition.diagnosed =
              condition.diagnosed || serverCondition.diagnosed;
            condition.first_symptom =
              condition.first_symptom || serverCondition.first_symptom;

            let params =
              MMPApi.ApiPainConditionObject.constructFromObject(condition);
            let api = new MMPApi.ConditionsApi();
            return api
              .syncEditConditionPost(getLang(), { condition: params })
              .then((result) => {
                if (result.fid) {
                  condition.original_fid = condition.fid;
                  condition.fid = result.fid;

                  // Substitute fid of purpose in localMedications if it was changed
                  dispatch({
                    type: EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID,
                    payload: {
                      old_fid: old_fid,
                      new_fid: condition.fid,
                    },
                  });
                }
                delete condition.flag_created;
                delete condition.flag_updated;
                delete condition.flag_deleted;
                dispatch({
                  type: types.EDIT_PAIN_CONDITION_SUCCESS,
                  payload: condition,
                });
                return Promise.resolve();
              })
              .catch((error) => {
                const code =
                  error &&
                  error.response &&
                  error.response.body &&
                  error.response.body.result_code;
                // If condition is already exists or not found - it is ok
                if (!code || (code != '216' && code != '215')) {
                  throw error;
                }

                if (code == '216') {
                  return addPainCondition();
                }

                return Promise.resolve();
              });
          } else {
            return addPainCondition();
          }
        }),
      );
    })
    .then(() => {
      // Updated medications are needed
      let _state = getState();
      localMedications =
        (_state.profile.content && _state.profile.content.medications) || [];
      localMedications = localMedications.filter(
        (med) => med.flag_created || med.flag_updated,
      );
      return Promise.all(
        localMedications.map((medication) => {
          medication = { ...medication };
          medication.dosage =
            medication.dosage?.filter((dosage) => {
              return dosage.value !== null;
            }) || [];
          let serverMed = serverMedications.find((serv) =>
            isSameMedication(serv, medication),
          );
          if (
            typeof medication.take_as_value === 'undefined' ||
            medication.take_as_value === ''
          ) {
            medication.take_as_value = null;
          }

          let api = new MMPApi.MedicationsApi();

          // If there were some changes for conditions on the server,
          // we need to check that all purposes(conditions) either exist on the server or were created locally.
          if (
            server.myProfile &&
            server.myProfile.content &&
            server.myProfile.content.condition
          ) {
            const serverConditionsFids = server.myProfile.content.condition.map(
              (condition) => condition.fid,
            );

            const mergedConditionsFids =
              localConditionsNewFids.concat(serverConditionsFids);

            medication.purpose = medication.purpose.filter((purpose) =>
              mergedConditionsFids.includes(purpose),
            );
          }

          const addMedication = () => {
            let params =
              MMPApi.ApiMedicationObject.constructFromObject(medication);

            return api
              .syncAddMedicationPost(getLang(), params)
              .then((result) => {
                medication.old_nid = medication.nid;
                medication.nid_old = medication.nid;
                medication.nid = result.nid;

                delete medication.flag_created;
                delete medication.flag_updated;
                delete medication.flag_deleted;

                trackGoal('Add Medication');
                trackAddMedication();
                dispatch({
                  type: types.EDIT_MEDICATION_SUCCESS,
                  payload: medication,
                });
                dispatch({
                  type: types.MED_NID_CHANGED,
                  payload: {
                    old_nid: medication.old_nid,
                    nid: medication.nid,
                  },
                });
                return Promise.resolve();
              })
              .catch((error) => {
                const code =
                  error &&
                  error.response &&
                  error.response.body &&
                  error.response.body.result_code;
                if (code && (code == 223 || code == 224)) {
                  dispatch(deleteMedicationOfflineMeds(medication.nid));
                  return Promise.resolve();
                }
                // If medication is not found it is ok
                if (!code || code != '212') {
                  throw error;
                }
                return Promise.resolve();
              });
          };
          if (serverMed) {
            medication.nid_old = medication.nid;
            medication.old_nid = medication.nid;
            medication.nid = serverMed.nid;
            medication.fid = serverMed.fid;
            medication.purpose = mergePurposes(
              medication.purpose,
              serverMed.purpose,
            );
            medication.purpose = medication.purpose.filter((purpose) => {
              return purpose > 0 && !localConditionsNewFidsMap[purpose];
            });
            medication.dosage = mergeDosages(
              medication.dosage,
              serverMed.dosage,
            );
            medication.dosage =
              medication.dosage?.filter((dosage) => {
                return dosage.value !== null;
              }) || [];

            if (!(medication.start_date || medication.end_date)) {
              medication.start_date = serverMed.start_date;
              medication.end_date = serverMed.end_date;
            }

            medication.med_notes = medication.med_notes || serverMed.med_notes;
            medication.special_instructions =
              medication.special_instructions || serverMed.special_instructions;
            medication.take_as_value =
              medication.take_as_value || serverMed.take_as_value;
            if (
              typeof medication.take_as_value === 'undefined' ||
              medication.take_as_value === ''
            ) {
              medication.take_as_value = null;
            }

            let params =
              MMPApi.ApiMedicationObject.constructFromObject(medication);
            return api
              .syncEditMedicationPost(getLang(), params)
              .then((result) => {
                medication.nid = result.nid;
                medication.fid = result.fid;

                delete medication.flag_created;
                delete medication.flag_updated;
                delete medication.flag_deleted;

                dispatch({
                  type: types.EDIT_MEDICATION_SUCCESS,
                  payload: medication,
                });
                dispatch({
                  type: types.MED_NID_CHANGED,
                  payload: {
                    old_nid: medication.old_nid,
                    nid: serverMed.nid,
                  },
                });
                return Promise.resolve();
              })
              .catch((error) => {
                const code =
                  error &&
                  error.response &&
                  error.response.body &&
                  error.response.body.result_code;
                // If medication is not found - it is ok
                if (code && (code == 223 || code == 224)) {
                  dispatch(deleteMedicationOfflineMeds(medication.nid));
                  return Promise.resolve();
                }
                if (!code || code != '212') {
                  throw error;
                }

                return Promise.resolve();
              });
          } else {
            return addMedication();
          }
        }),
      );
    })
    .then(() => {
      return {
        secondSync:
          localMedications.length > 0 ||
          localConditions.length > 0 ||
          was_deleted,
        server: server,
      };
    });
}

//Here we need dispatch, but that's bad practice =\
export function syncIntroMedications(server, store, dispatch, getState) {
  // if (!server.myProfile) {
  //     return {server};
  // }
  let localMedications =
    (store.profile.content && store.profile.content.medications) || [];
  let localConditions =
    (store.profile.content && store.profile.content.condition) || [];
  let serverMedications = (server.myProfile || store.profile).content
    .medications;
  let serverConditions = (server.myProfile || store.profile).content.condition;
  localMedications = localMedications.filter((med) => med.offline); // && med.nid < 0);//.filter(cond => !serverMedications.some(serv => serv.));
  localConditions = localConditions.filter((cond) => cond.offline); // && cond.fid < 0);//.filter(cond => !serverConditions.some(serv => ));
  let api = new MMPApi.MedicationsApi();

  return Promise.resolve()
    .then(() => {
      return Promise.all(
        localConditions.map((condition) => {
          condition = { ...condition };

          const old_fid = condition.fid;

          let serverCondition = serverConditions.find((serv) =>
            isSameCondition(serv, condition),
          );
          if (serverCondition) {
            //Found server, update it with local values
            condition.fid = serverCondition.fid;
            condition.diagnosed =
              condition.diagnosed || serverCondition.diagnosed;
            condition.first_symptom =
              condition.first_symptom || serverCondition.first_symptom;

            let params =
              MMPApi.ApiPainConditionObject.constructFromObject(condition);
            let api = new MMPApi.ConditionsApi();

            return api
              .syncEditConditionPost(getLang(), { condition: params })
              .then((result) => {
                if (result.fid) {
                  condition.original_fid = condition.fid;
                  condition.fid = result.fid;

                  // Substitute fid of purpose in localMedications if it was changed
                  dispatch({
                    type: EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID,
                    payload: {
                      old_fid: old_fid,
                      new_fid: condition.fid,
                    },
                  });
                }
                condition.offline = false;
                dispatch({
                  type: types.EDIT_PAIN_CONDITION_SUCCESS,
                  payload: condition,
                });
                return Promise.resolve();
              });
          } else {
            let params =
              MMPApi.ApiPainConditionObject.constructFromObject(condition);

            let api = new MMPApi.MedicationsApi();
            return api
              .syncAddConditionPost(getLang(), { condition: params })
              .then((result) => {
                const original_fid = condition.fid;
                condition.fid = result.fid;
                condition.offline = false;
                dispatch({
                  type: types.ADD_PAIN_CONDITION_SUCCESS,
                  payload: condition,
                });

                trackGoal('Add Condition');
                trackAddPainCondition();
                // Substitute fid of purpose in localMedications if it was changed
                dispatch({
                  type: EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID,
                  payload: {
                    old_fid: original_fid,
                    new_fid: condition.fid,
                  },
                });
                return Promise.resolve();
              });
          }
        }),
      );
    })
    .then(() => {
      // Updated medications are needed
      let _state = getState();
      localMedications =
        (_state.profile.content && _state.profile.content.medications) || [];
      localMedications = localMedications.filter((med) => med.offline); // && med.nid < 0);
      return Promise.all(
        localMedications.map((medication) => {
          medication = { ...medication };
          medication.dosage =
            medication.dosage?.filter((dosage) => {
              return dosage.value !== null;
            }) || [];

          let serverMed = serverMedications.find((serv) =>
            isSameMedication(serv, medication),
          );
          if (serverMed) {
            //Found server, update it with local values
            medication.nid_old = medication.nid;
            medication.old_nid = medication.nid;
            medication.nid = serverMed.nid;
            medication.fid = serverMed.fid;
            medication.end;

            medication.purpose = [...medication.purpose, ...serverMed.purpose];

            let params =
              MMPApi.ApiMedicationObject.constructFromObject(medication);
            let api = new MMPApi.MedicationsApi();
            return api
              .syncEditMedicationPost(getLang(), params)
              .then((result) => {
                medication.nid = result.nid;
                medication.offline = false;
                dispatch({
                  type: types.EDIT_MEDICATION_SUCCESS,
                  payload: medication,
                });
                dispatch({
                  type: types.MED_NID_CHANGED,
                  payload: {
                    old_nid: medication.old_nid,
                    nid: serverMed.nid,
                  },
                });
                return Promise.resolve();
              });
          } else {
            let params =
              MMPApi.ApiMedicationObject.constructFromObject(medication);

            let api = new MMPApi.MedicationsApi();
            return api
              .syncAddMedicationPost(getLang(), params)
              .then((result) => {
                medication.old_nid = medication.nid;
                medication.nid_old = medication.nid;
                medication.nid = result.nid;
                medication.offline = false;
                dispatch({
                  type: types.EDIT_MEDICATION_SUCCESS,
                  payload: medication,
                });
                dispatch({
                  type: types.MED_NID_CHANGED,
                  payload: {
                    old_nid: medication.old_nid,
                    nid: medication.nid,
                  },
                });
                return Promise.resolve();
              });
          }
        }),
      );
    })
    .then(() => {
      return {
        secondSync: localMedications.length > 0 || localConditions.length > 0,
        server: server,
      };
    });
}

function notifyErrorForCustomLists(reason, field, localFields, serverFields) {
  let brokenLocalFields = [];
  Object.keys(localFields).forEach((type) => {
    if (Array.isArray(localFields[type])) {
      localFields[type]?.forEach((field) => {
        if (field && !field?.type) {
          brokenLocalFields.push('type:' + JSON.stringify(field));
        }
      });
    } else {
      brokenLocalFields.push(
        'typeNotArray:' + type + ' ' + JSON.stringify(localFields[type]),
      );
    }
  });
  bugsnag.notify(
    new Error(
      reason +
        ' ' +
        field +
        ' ' +
        brokenLocalFields.join(',') +
        ' ' +
        JSON.stringify(serverFields),
    ),
  );
}

export function syncCustomLists(server, store) {
  //Now we check each field separately
  let local = Object.assign({}, store.fields);

  //server custom lists "updated" timestamp
  //    let serverUpdated = server.customLists.updated;

  //Search for fields with appropriate flag
  //Here we reduce (concat all fields in single one) and filter by flag
  // We should ignore ineffective factors here
  let fields = Object.values({ ...local, ineffective_factor: [] }).reduce(
    (arr, fields) => arr.concat(fields),
    [],
  );
  fields = fields.filter((f) => f).slice();
  let fieldsToCreate = fields.filter((f) => {
    return f.flag_created;
  });
  let fieldsToUpdate = fields.filter((f) => {
    return f.flag_updated;
  });
  let fieldsToDelete = fields.filter((f) => {
    return f.flag_deleted;
  });
  let fieldsToUpdateShow = fields.filter((f) => {
    return f.flag_update_show;
  });

  let serverFields = server.customLists && server.customLists.content;

  //now filter all fields which already exists on server
  fieldsToCreate = fieldsToCreate.filter((f) => {
    if (f.systemDefault || f.isMedication) {
      bugsnag &&
        bugsnag.notify(
          new Error(
            'Trying to create system default field' + JSON.stringify(f),
          ),
        );
      return false;
    }
    //If server already have our value, then we don't need to send it again, so delete from list and clear flag
    let serverField =
      f.type === 'ineffective_factor' ? 'alleviating_factor' : f.type;
    let serverFound =
      serverFields &&
      serverFields[serverField]?.find((s) => {
        return s.value === f.name;
      });
    if (serverFields && !serverFields[serverField]) {
      notifyErrorForCustomLists(
        'Server fields empty on create',
        f,
        local,
        serverFields,
      );
    }
    if (serverFound) {
      f.fid = serverFound.fid;
      delete f.flag_created;
    }
    return !serverFound;
  });

  fieldsToUpdate = fieldsToUpdate.filter((f) => {
    if (f.systemDefault || f.isMedication) {
      bugsnag &&
        bugsnag.notify(
          new Error(
            'Trying to update system default field' + JSON.stringify(f),
          ),
        );
      return false;
    }
    if (!f.fid) {
      return false;
    }
    //If server already have our value, then we don't need to send it again, so delete from list and clear flag
    let serverField =
      f.type === 'ineffective_factor' ? 'alleviating_factor' : f.type;
    let serverFound =
      serverFields &&
      serverFields[serverField]?.find((s) => {
        return s.value === f.name;
      });
    if (serverFields && !serverFields[serverField]) {
      notifyErrorForCustomLists(
        'Server fields empty on update',
        f,
        local,
        serverFields,
      );
    }
    if (serverFound) {
      f.fid = serverFound.fid;
      delete f.flag_updated;
    }
    return !serverFound;
  });

  fieldsToDelete = fieldsToDelete.filter((f) => {
    if (f.systemDefault || f.isMedication) {
      bugsnag &&
        bugsnag.notify(
          new Error(
            'Trying to delete system default field' + JSON.stringify(f),
          ),
        );
      return false;
    }
    if (!f.fid) {
      return false;
    }
    //If server already have this field deleted, then don't delete it again
    let serverField =
      f.type === 'ineffective_factor' ? 'alleviating_factor' : f.type;
    let serverFound = serverFields
      ? serverFields[serverField]?.find((s) => {
          if (s.value_type === 'medication') {
            return false;
          }
          return s.value === f.name;
        })
      : true;
    if (serverFields && !serverFields[serverField]) {
      notifyErrorForCustomLists(
        'Server fields empty on delete',
        f,
        local,
        serverFields,
      );
    }

    if (!serverFound) {
      delete f.flag_deleted;
    }
    return serverFound;
  });

  //Sync lists sequentally - return promise
  fieldsToUpdateShow = fieldsToUpdateShow.filter((f) => {
    //Only update show on records, which existing on backend already
    let serverField =
      f.type === 'ineffective_factor' ? 'alleviating_factor' : f.type;
    if (!serverFields) {
      //If server don't return any values - send value anyway
      return !!f.fid;
    }
    let serverFound =
      serverFields &&
      serverFields[serverField]?.find((s) => {
        return s.value === f.name;
      });
    if (serverFields && !serverFields[serverField]) {
      notifyErrorForCustomLists(
        'Server fields empty on update show',
        f,
        local,
        serverFields,
      );
    }
    if (serverFound) {
      f.fid = serverFound.fid;
      return true;
    }
    return !!f.fid;
  });

  if (
    fieldsToCreate.length > 0 ||
    fieldsToUpdate.length > 0 ||
    fieldsToUpdateShow.length > 0 ||
    fieldsToDelete.length > 0
  ) {
    return Promise.resolve()
      .then(() => {
        //Firstly delete fields
        if (fieldsToDelete.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.SectionFieldsApi();
        let params = MMPApi.DeleteSectionFieldsParams.constructFromObject({
          section_fields: fieldsToDelete.map((f) => {
            return customLocalToServer(f);
          }),
        });

        return api.syncDeleteSectionFieldsPost(getLang(), params);
      })
      .then((res) => {
        //then edit
        if (fieldsToUpdate.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.SectionFieldsApi();
        let params = MMPApi.AddEditSectionFieldsParams.constructFromObject({
          section_fields: fieldsToUpdate.map((f) => {
            return customLocalToServer(f);
          }),
        });

        return api.syncEditSectionFieldsPost(getLang(), params);
      })
      .then((res) => {
        //Then we need to handle result from 'update' and assign correct FIDs
        if (res instanceof Array && res.length === fieldsToUpdate.length) {
          for (let i = 0; i < res.length; i++) {
            fieldsToUpdate[i].fid = res[i];
          }
        }
      })
      .then((res) => {
        //then create fields, ignore result from deletion
        if (fieldsToCreate.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.SectionFieldsApi();
        let params = MMPApi.AddEditSectionFieldsParams.constructFromObject({
          section_fields: fieldsToCreate.map((f) => {
            return customLocalToServer(f);
          }),
        });

        return api.syncAddNewSectionFieldsPost(getLang(), params);
      })
      .then((res) => {
        //Then we need to handle result from 'create' and assign correct FIDs
        if (res instanceof Array && res.length === fieldsToCreate.length) {
          for (let i = 0; i < res.length; i++) {
            fieldsToCreate[i].fid = res[i];
          }
        }
      })
      .then((res) => {
        //And then edit "show"
        if (fieldsToUpdateShow.length === 0) {
          return Promise.resolve();
        }
        let api = new MMPApi.RecordCallsApi();
        let content = {};
        fieldsToUpdateShow
          .map((f) => {
            return customLocalToServer(f);
          })
          .forEach((f) => {
            if (!content[f.type]) {
              content[f.type] = [];
            }
            content[f.type].push(f);
          });
        let params = MMPApi.ApiRecordObject.constructFromObject({
          record_type: 'user_preferences_record',
          content: content,
        });

        return api.syncUpdateRecordPost(getLang(), params);
      })
      .then((res) => {
        return { secondSync: true, server: server };
      });
  }

  //TODO still need to handle error 216
  // if (fieldToEdit.size() > 0) {
  //     List<ApiContentUserPreferencesRecord.ApiFieldObject> fieldObjects = new ArrayList<>(fieldToEdit.keySet());
  //     BaseResponse.ListOfStringResponse fieldResponse = ApiExecutor.execute(NetworkManager.getSyncApis().editSectionFields(new SectionCalls.EditSectionFieldsRequest(fieldObjects)));
  //     if (fieldResponse.error != 0) { //Section field was not found. Need to clean up "fid" and try to create field next time
  //         if (fieldResponse.error == 216) {
  //             for (ApiContentUserPreferencesRecord.ApiFieldObject obj : fieldToEdit.keySet()) {
  //                 IBaseDataAware dataAware = fieldToEdit.get(obj);
  //                 dataAware.fid = 0;
  //                 dataAware.updateFlag = FieldListProxy.FIELD_FLAG_CREATE;
  //                 try {
  //                     dbHelper.getGenericDao(dataAware.getClass()).update(dataAware);
  //                 } catch (SQLException e) {
  //                     e.printStackTrace();
  //                 }
  //             }
  //         } else {
  //             throw new ServerResponseException(fieldResponse.getStatusCode(), context);
  //         }
  //     } else {
  //         //Need to update "fid" for new sections
  //         for (int i = 0; i < fieldObjects.size(); i++) {
  //             ApiContentUserPreferencesRecord.ApiFieldObject obj = fieldObjects.get(i);
  //             IBaseDataAware dataAware = fieldToEdit.get(obj);
  //             if (fieldResponse.result != null && fieldResponse.result.size() >= i) {
  //                 dataAware.setFid(Integer.parseInt(fieldResponse.result.get(i)));
  //             }
  //         }
  //         dbHelper.clearFlagsForFields(fieldToEdit.values());
  //     }
  // }

  //Need to assign "ids" to server fields

  return Promise.resolve({ custom_lists: serverFields, server: server });
}

export function customServerToLocal(str, id, type, icon, colour) {
  let data = {};
  data.name = str.value;
  data.fid = str.fid;
  data.id = id;
  data.show = str.isShow;
  data.type = type;
  data.icon_code = icon;
  data.icon_colour = colour;
  data.systemDefault = str.value_type === 'default';
  data.isMedication = str.value_type === 'medication';
  data.position = str.position;
  if (str.medications) {
    data.medication = str.medications;
  }
  if (str.value_translations) {
    data.translations = str.value_translations;
  }
  return data;
}

function customLocalToServer(str) {
  let data = {};
  data.value = str.name;
  data.value_type = str.isMedication
    ? 'medication'
    : str.systemDefault
      ? 'default'
      : 'custom';
  data.type =
    str.type === 'ineffective_factor' ? 'alleviating_factor' : str.type;
  data.isShow = str.show ? 1 : 0;
  data.position = str.position;
  data.fid = str.fid;
  return data;
}

export function recordToLocal(apiRecord, fields, last_updated) {
  // console.log('recordToLocal', apiRecord);
  let painRecord = {};
  let content = apiRecord.content;
  painRecord.last_updated = last_updated;
  painRecord.idOnServer = apiRecord.record_id;
  painRecord.createDate = apiRecord.created;
  painRecord.updateDate = apiRecord.updated;
  painRecord.localUpdated = apiRecord.updated_local;
  painRecord.lengthOfPainType = content.pain_type;
  painRecord.note = content.notes || '';
  if (content.device_id !== null) {
    painRecord.deviceId = content.device_id;
  }
  if (content.pain_duration !== null) {
    painRecord.lengthOfPainValue =
      content.pain_duration && parseInt(content.pain_duration);
  }
  painRecord.durationType =
    content.pain_duration_type ||
    (painRecord.lengthOfPainValue === MILLISECONDS_IN_DAY / 1000
      ? 'all_day'
      : painRecord.lengthOfPainValue > 0
        ? 'duration'
        : null);
  painRecord.lengthOfPainUnit = content.pain_duration_unit;
  painRecord.type = content.pain_record_type;
  if (content.severity !== null) {
    if (painRecord.type === 'PainRecord') {
      painRecord.severity = content.severity;
    } else {
      painRecord.score = content.severity;
    }
  }
  if (content.time_offset !== null) {
    painRecord.timeOffset = content.time_offset;
  }
  painRecord.recordTime = parseInt(content.timing);

  if (content.deleted !== null) {
    painRecord.deleted = content.deleted !== null && content.deleted > 0;
  }
  painRecord.fields = {};
  painRecord.medications = {};
  FIELDS_ALL.forEach((field) => {
    if (content[field] && field !== 'ineffective_factor') {
      painRecord.fields[field] = content[field].map((f) => {
        let name = f.value || f;
        let fieldToCheck = fields[field][name];
        if (!fieldToCheck) {
          //Why we don't have such field?? For now just ignore it
          return null;
        }
        if (fieldToCheck.medication) {
          let medication =
            fieldToCheck.medication.find(
              (med) => med.units === f.dosage_units,
            ) || fieldToCheck.medication[0];
          painRecord.medications[medication.nid] = {};
          painRecord.medications[medication.nid].typeOfMedication =
            f.effectiveness == 0 ? 'ineffective' : field.replace('_factor', '');
          painRecord.medications[medication.nid].dosage = f.dosage;
          painRecord.medications[medication.nid].otherDosage =
            !medication.dosages.some((d) => d === f.dosage);
          return null;
        }
        if (field === 'alleviating_factor') {
          return { id: fieldToCheck.id, effective: f.effectiveness == 1 };
        } else {
          return fieldToCheck.id;
        }
      });
      if (field === 'alleviating_factor') {
        painRecord.fields.ineffective_factor = painRecord.fields[field].filter(
          (f) => f && !f.effective,
        );
        painRecord.fields[field] = painRecord.fields[field].filter(
          (f) => f && f.effective,
        );
        painRecord.fields.ineffective_factor =
          painRecord.fields.ineffective_factor.map((f) => f.id);
        painRecord.fields[field] = painRecord.fields[field].map((f) => f.id);
      } else {
        painRecord.fields[field] = painRecord.fields[field].filter(
          (f) => typeof f === 'number',
        );
      }
    }
  });

  return painRecord;
}

export function recordToServer(painRecord, fields) {
  let apiRecord = {};
  apiRecord.record_type = 'pain_record';
  // apiRecord.skip_record = painRecord.severity>=9;
  apiRecord.record_id = painRecord.idOnServer;
  apiRecord.updated_local = painRecord.localUpdated;
  apiRecord.created = painRecord.createDate;
  //    apiRecord.updated = painRecord.updateDate;
  apiRecord.content = {};
  let content = apiRecord.content;

  content.pain_record_type = painRecord.type;
  content.notes = painRecord.note;
  content.device_id = painRecord.deviceId;
  content.pain_duration = painRecord.lengthOfPainValue;
  content.pain_duration_unit = painRecord.lengthOfPainUnit;
  content.pain_duration_type = painRecord.durationType;
  content.pain_type = painRecord.lengthOfPainType;
  if (painRecord.type === 'PainRecord') {
    content.severity = painRecord.severity;
  } else {
    content.severity = painRecord.score;
  }
  content.time_offset = painRecord.timeOffset;
  content.timing = painRecord.recordTime;
  content.deleted = painRecord.deleted ? 1 : 0;

  FIELDS_ALL.forEach((field) => {
    if (painRecord.fields && painRecord.fields[field]) {
      content[field] = painRecord.fields[field]
        .map((f) => {
          if (field.endsWith('_factor')) {
            let obj = Object.values(fields[field]).find((s) => s.id === f);
            return (
              obj && {
                value: obj.name,
                effectiveness: field === 'alleviating_factor' ? '1' : '0',
                fid: obj.fid,
              }
            );
          } else {
            let obj = Object.values(fields[field]).find((s) => s.id === f);
            return obj && obj.name;
          }
        })
        .filter((f) => !!f);
      if (field === 'ineffective_factor') {
        if (!content.alleviating_factor) {
          content.alleviating_factor = [];
        }
        content.alleviating_factor = content.alleviating_factor.concat(
          content.ineffective_factor,
        );
      }
    }
  });

  if (painRecord.medications) {
    Object.keys(painRecord.medications).forEach((nid) => {
      let factor = painRecord.medications[nid].typeOfMedication + '_factor';

      let factorField =
        painRecord.medications[nid].typeOfMedication === 'aggravating'
          ? 'aggravating_factor'
          : 'alleviating_factor';
      if (!content[factorField]) {
        content[factorField] = [];
      }
      let field = Object.values(fields[factor]).find(
        (f) => f.medication && f.medication.find((med) => med.nid == nid),
      );
      if (!field) {
        console.log('Field not found!!', nid);
        return;
      }
      let toPush = {
        value: field.name,
        dosage: painRecord.medications[nid].dosage,
        dosage_units: field.medication.find((med) => med.nid == nid).units,
      };
      if (factor !== 'aggravating_factor') {
        toPush.effectiveness = factor === 'alleviating_factor' ? '1' : '0';
      }
      content[factorField].push(toPush);
    });
  }
  return apiRecord;
}
