import * as types from './actiontypes';
import { apiError, checkToken, fillAppHeaders } from '../Api/actions';
import { sync, webFirstSync } from '../Sync/actions';
import { Platform } from 'react-native';
import { TOGGLE_PRO, TOGGLE_SUBSCRIPTION } from '../Iap/actiontypes';
import { resetOfflineMeds } from '../Meds/actions';
import DeviceInfo from 'react-native-device-info';
import { trackLogin, trackSignUp } from '../../shared/utilities/answers';
import { designConstants } from '../../design/styles/constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { webInitialized } from '../Navigator/actions';
import { setLanguage } from '../Settings/actions';
import * as profileTypes from '../Profile/actiontypes';
import getConfig from '../../shared/utilities/getConfig';
import { updateMyProfile } from '../Profile/actions';
import * as MMPApi from 'apiclient';
import getStorage from '../../redux/storage';
import { getLang } from '../../shared/utilities/getLang';
import ErrorDialog from '../../shared/components/ErrorDialog';

const SessionStorage = designConstants.isWeb
  ? getStorage()
  : require('@react-native-async-storage/async-storage').default;

let sha256 = require('sha256');
let md5 = require('md5');

async function getUtmParams(login_only) {
  const utm_source_initial = designConstants.isWeb
    ? window.location.hostname
    : DeviceInfo.getBundleId() +
      ' ' +
      DeviceInfo.getVersion() +
      ' ' +
      Platform.OS;
  if (designConstants.isWeb) {
    const utm_source = login_only
      ? undefined
      : (await SessionStorage.getItem('utm_source')) || utm_source_initial;
    const utm_medium =
      (await SessionStorage.getItem('utm_medium')) || undefined;
    const utm_campaign =
      (await SessionStorage.getItem('utm_campaign')) || undefined;

    return {
      utm_source: typeof utm_source === 'string' ? utm_source : undefined,
      utm_medium: typeof utm_medium === 'string' ? utm_medium : undefined,
      utm_campaign: typeof utm_campaign === 'string' ? utm_campaign : undefined,
    };
  } else {
    return {
      utm_source: login_only ? undefined : utm_source_initial,
    };
  }
}
export function getDeviceId() {
  let bundleId =
    Platform.OS === 'ios'
      ? '_ios'
      : DeviceInfo.getBundleId() === 'com.lcs.mmp.full'
        ? '_apro'
        : '_alite';
  return DeviceInfo.getUniqueIdSync() + bundleId;
}

export function updateEulaVersion() {
  return (dispatch, getState) => {
    const profile = { ...getState().profile };
    return checkToken().then(() => {
      const appEulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));
      const appPrivacyVersion = parseInt(getConfig('MMP_PRIVACY_VERSION'));
      let api = new MMPApi.RecordCallsApi();
      profile.content = {
        ...profile.content,
        eula_version: appEulaVersion,
        privacy_notice_version: appPrivacyVersion,
      };
      let record = MMPApi.ApiRecordObject.constructFromObject(profile);
      return api.syncUpdateRecordPost(getLang(), record).then(
        (result) => {
          return dispatch(updateMyProfile());
        },
        (error) => {
          return dispatch(apiError(error, types.UPDATE_EULA));
        },
      );
    });
  };
}

//Managed means that purchase was done on this device, so we can show "manage"
export function togglePro(value, managed) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_PRO,
      payload: { value, managed },
    });
    return Promise.resolve();
  };
}

export function toggleSubscription(value, managed, is_expired) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_SUBSCRIPTION,
      payload: { value, managed, is_expired: is_expired || false },
    });
    return Promise.resolve();
  };
}

export function setUser(user) {
  return (dispatch) => {
    dispatch({
      type: types.SET_USER,
      payload: user.content,
    });
    return Promise.resolve();
  };
}

export function syncFailedLogout() {
  return (dispatch, getState) => {
    if (!designConstants.isWeb) {
      require('react-native-fbsdk-next').LoginManager.logOut();
    }

    dispatch({
      type: types.SYNC_FAILED_LOGOUT,
    });

    if (!designConstants.isWeb) {
      AsyncStorage.getItem('pushToken').then((pushToken) => {
        if (pushToken) {
          dispatch(registerPushToken(pushToken, false));
        }
      });
    }

    return Promise.resolve();
  };
}
export function logout(force = false, onLogoutError) {
  return (dispatch, getState) => {
    // if (designConstants.isWeb) {
    //   force = true;
    // }
    if (getState().sync.sync_in_progress && !force) {
      dispatch({
        type: types.SET_LOGOUT_ATTEMPT_FLAG,
        payload: true,
      });
      return Promise.resolve();
    }

    let state = getState();
    let pending =
      state.records.list.some((rec) => rec.flag_updated) ||
      (state.profile.content &&
        ((state.profile.content.medications &&
          state.profile.content.medications.some(
            (med) => med.flag_created || med.flag_updated,
          )) ||
          (state.profile.content.condition &&
            state.profile.content.condition.some(
              (cond) => cond.flag_created || cond.flag_updated,
            )))) ||
      (state.meds &&
        state.meds.deleted &&
        ((state.meds.deleted.condition &&
          state.meds.deleted.condition.length > 0) ||
          (state.meds.deleted.medications &&
            state.meds.deleted.medications.length > 0)));

    if (pending && !force) {
      dispatch({
        type: types.SET_LOGOUT_ATTEMPT_FLAG,
        payload: true,
      });
      return dispatch(sync()).catch((err) => {
        onLogoutError && onLogoutError(err);
      });
    }

    dispatch({
      type: types.LOGOUT,
    });
    if (!designConstants.isWeb) {
      require('react-native-fbsdk-next').LoginManager.logOut();
    }
    dispatch(resetOfflineMeds());

    if (designConstants.isWeb) {
      dispatch(setLanguage('default'));
    }

    //Send FCM token to server after logout to make token "anonymous"
    if (!designConstants.isWeb) {
      AsyncStorage.getItem('pushToken').then((pushToken) => {
        if (pushToken) {
          dispatch(registerPushToken(pushToken, false));
        }
      });
    }

    return Promise.resolve();
  };
}

export function clearLogin() {
  return {
    type: types.LOGIN_CLEAR,
  };
}

export function authFlush() {
  return {
    type: types.AUTH_FLUSH,
  };
}

function getDeviceDetails() {
  let device_details = '';
  device_details += 'BRAND:' + DeviceInfo.getBrand();
  device_details += ',DEVICE:' + DeviceInfo.getModel();
  device_details += ',VERSION:' + DeviceInfo.getSystemVersion();

  return device_details;
}

/*
 * Get institution ID associated with URL (if any)
 * */
export function getInstitutionId(url) {
  return (dispatch) => {
    let api = new MMPApi.GetInstitutionDataApi();
    let params = MMPApi.GetInstitutionIdParams.constructFromObject({
      routing_url: url,
    });

    return api
      .institutionsGetInstitutionIdPost(getLang(), params)
      .then((result) => {
        // when there is an institution associated - update state
        if (result.institution) {
          dispatch({ type: types.SET_INSTITUTION, payload: result });
        }
        return Promise.resolve();
      })
      .catch((error) => {
        return dispatch(apiError(error, types.GET_INSTITUTION_ID));
      });
  };
}

export function proceedToMainScreen() {
  return (dispatch) => {
    return dispatch(webFirstSync()).then(() => {
      dispatch({ type: types.NAVIGATE_TO_MAIN_SCREEN });
      dispatch(webInitialized());
    });
  };
}

export function resendActivation(username, password) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    let passwordEncrypted = sha256('Uw0BEKDs7LZ1$Oh*xQO2' + md5(password));
    params = MMPApi.ResendActivationParams.constructFromObject({
      username: username,
      password: passwordEncrypted,
    });
    dispatch({ type: types.RESEND_ACTIVATION });
    return api
      .coreResendActivationPost(getLang(), params)
      .then((result) => {
        return Promise.resolve();
      })
      .catch((error) => {
        return dispatch(apiError(error, types.RESEND_ACTIVATION));
      });
  };
}

export function resendActivationWithProvider(email, provider, token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.ResendActivationWithProviderParams.constructFromObject({
      email: email,
      provider: provider,
      access_token: token,
    });
    dispatch({ type: types.RESEND_ACTIVATION });
    return api
      .coreResendActivationWithProviderPost(getLang(), params)
      .then((result) => {
        return Promise.resolve();
      })
      .catch((error) => {
        return dispatch(apiError(error, types.RESEND_ACTIVATION));
      });
  };
}

export function loginAndSync(
  username,
  password,
  old_token,
  forceUseOldDeviceId,
  fakeDeviceId,
) {
  return (dispatch) => {
    return dispatch(
      login(username, password, old_token, forceUseOldDeviceId, fakeDeviceId),
    )
      .then((result) => dispatch(saveToken(result)))
      .then((result) => {
        //Send FCM token to server after login
        if (!designConstants.isWeb) {
          AsyncStorage.getItem('pushToken').then((pushToken) => {
            if (pushToken) {
              dispatch(registerPushToken(pushToken, true));
            }
          });
        }
        return result;
      })
      .then(async (result) => {
        await dispatch(firstSync(result));
        return result;
      })
      .catch((error) => {
        console.log('login error', error?.result_msg);
        return error?.result_msg
          ? Promise.reject(error)
          : dispatch(
              apiError(
                error,
                forceUseOldDeviceId ? 'LOGIN_FORCE_DEVICE_ID' : types.LOGIN,
              ),
            );
      });
  };
}

export function login(
  username,
  password,
  old_token,
  forceUseOldDeviceId,
  fakeDeviceId,
) {
  return async (dispatch, getState) => {
    const pushToken = await AsyncStorage.getItem('pushToken');
    console.log('PUSHTOKEN', pushToken);
    dispatch({
      type: types.LOGIN,
    });
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    let state = getState();
    let token_type = Platform.OS.toLowerCase();
    //We are placing institution id here for now, as it's global. But
    //TODO: Maybe worth to move it to outer page
    let institution_id = window?.__institution_id;

    if (state && state.auth && state.auth.user && state.auth.user.token_type) {
      token_type = state.auth.user.token_type;
      if (token_type === 'default') {
        token_type = Platform.OS.toLowerCase();
      }
    }

    let params = {};

    if (password) {
      let passwordEncrypted = sha256('Uw0BEKDs7LZ1$Oh*xQO2' + md5(password));
      params = {
        username: username,
        password: passwordEncrypted,
        token_type,
        device_id:
          getDeviceId() + (fakeDeviceId ? Math.random(1000000000) : ''),
        device_details: getDeviceDetails(),
      };
    } else {
      params = {
        username: username,
        prev_token: old_token,
        token_type,
        device_id: forceUseOldDeviceId
          ? DeviceInfo.getUniqueIdSync()
          : getDeviceId(),
        device_details: getDeviceDetails(),
      };
    }
    if (institution_id) {
      params.institution = institution_id;
    }
    params = MMPApi.AuthParams.constructFromObject(params);
    return api
      .coreLoginUserPost(getLang(), params)
      .then((result) => {
        return {
          ...result,
          password_set: true,
          token_type,
        };
      })
      .catch((error) => {
        return dispatch(
          apiError(
            error,
            forceUseOldDeviceId ? 'LOGIN_FORCE_DEVICE_ID' : types.LOGIN,
          ),
        );
      });
  };
}

export function saveToken(result) {
  return (dispatch, getState) => {
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    let token = defaultClient.authentications.token;
    defaultClient.authentications = {
      token: {
        type: 'apiKey',
        in: 'header',
        name: 'Authorization',
        apiKeyPrefix: result.token_type,
        apiKey: result.token,
      },
    };

    const source = Platform.OS + ' ' + result.token_type;
    trackLogin(source, result.is_institution_member || 0);

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: result,
    });
    return Promise.resolve(result);
  };
}

export function firstSync(result) {
  return (dispatch, getState) => {
    if (designConstants.isWeb) {
      dispatch(sync()).catch((error) => {
        ErrorDialog(null, error);
      });
      return Promise.resolve(result);
    }
    return dispatch(sync());
  };
}

export function loginWithFacebook(
  email,
  accessToken,
  login_only,
  institution_id,
  study_id,
  registration_code,
  activation_code,
  fakeDeviceId,
) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch, getState) => {
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    const secondary_institution =
      window?.__institution_join_institution_if_no_consent;
    const silentJoin = institution_id == secondary_institution;
    if (silentJoin) {
      activation_code = undefined;
    }
    const appEulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));
    const appPrivacyVersion = parseInt(getConfig('MMP_PRIVACY_VERSION'));

    const utm_params = await getUtmParams(login_only);
    let params = MMPApi.LoginUserWithProviderParams.constructFromObject({
      provider: 'facebook',
      access_token: accessToken,
      token_type: 'facebook',
      login_only: login_only ? 1 : 0,
      device_id: getDeviceId() + (fakeDeviceId ? Math.random(1000000000) : ''),
      device_details: getDeviceDetails(),
      activation_code,
      email: email,
      institution: activation_code ? undefined : institution_id || undefined,
      study_id: activation_code ? undefined : study_id,
      registration_code: registration_code,
      eula_version: login_only ? undefined : appEulaVersion,
      privacy_notice_version: login_only ? undefined : appPrivacyVersion,
      ...utm_params,
    });
    if (!login_only) {
      dispatch({
        type: types.LOGIN_WITH_PROVIDER,
      });
    }

    return api
      .coreLoginUserWithProviderPost(getLang(), params)
      .then((result) => {
        if (!result.token) {
          //If no token is returned, that we decide that it's registration instead, and account needs to be activated
          console.log('not activated error');
          return Promise.reject({
            response: {
              body: {
                result_msg: result?.result_msg || 'Account not activated',
                result_code: 152,
              },
            },
          }); //152 - account needs to be activated
        }
        return { ...result, token_type: 'facebook' };
      })
      .then((result) => {
        if (!login_only) {
          dispatch(saveToken(result));
          dispatch(firstSync(result));
          //Send FCM token to server after login
          if (!designConstants.isWeb) {
            AsyncStorage.getItem('pushToken').then((pushToken) => {
              if (pushToken) {
                dispatch(registerPushToken(pushToken, true));
              }
            });
          }
        }
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.LOGIN_WITH_PROVIDER));
      });
  };
}

export function finishGoogleLogin(result) {
  return (dispatch) => {
    dispatch({
      type: types.LOGIN_WITH_PROVIDER,
    });
    result.token_type = 'google';
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: result,
    });
    if (!designConstants.isWeb) {
      AsyncStorage.getItem('pushToken').then((pushToken) => {
        if (pushToken) {
          dispatch(registerPushToken(pushToken, true));
        }
      });
    }
    dispatch(saveToken(result));
    return dispatch(firstSync());
  };
}

export function finishAppleLogin(result) {
  return (dispatch) => {
    dispatch({
      type: types.LOGIN_WITH_PROVIDER,
    });
    result.token_type = 'apple';
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: result,
    });
    if (!designConstants.isWeb) {
      AsyncStorage.getItem('pushToken').then((pushToken) => {
        if (pushToken) {
          dispatch(registerPushToken(pushToken, true));
        }
      });
    }
    dispatch(saveToken(result));
    return dispatch(firstSync());
  };
}
export function loginWithGoogle(
  email,
  accessToken,
  login_only,
  institution_id,
  study_id,
  registration_code,
  activation_code,
  fakeDeviceId,
) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch, getState) => {
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    const secondary_institution =
      window?.__institution_join_institution_if_no_consent;
    const silentJoin = institution_id == secondary_institution;
    if (silentJoin) {
      activation_code = undefined;
    }
    const appEulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));
    const appPrivacyVersion = parseInt(getConfig('MMP_PRIVACY_VERSION'));

    const utm_params = await getUtmParams(login_only);

    let params = MMPApi.LoginUserWithProviderParams.constructFromObject({
      provider: designConstants.isWeb ? 'google-auth' : 'google',
      client_type: designConstants.isWeb ? 'web' : 'mobile',
      access_token: accessToken,
      token_type: 'google',
      login_only: login_only ? 1 : 0,
      email,
      activation_code,
      device_id: getDeviceId() + (fakeDeviceId ? Math.random(1000000000) : ''),
      device_details: getDeviceDetails(),
      institution: activation_code ? undefined : institution_id || undefined,
      study_id: activation_code ? undefined : study_id,
      registration_code: registration_code,
      eula_version: login_only ? undefined : appEulaVersion,
      privacy_notice_version: login_only ? undefined : appPrivacyVersion,
      ...utm_params,
    });
    if (!login_only) {
      dispatch({
        type: types.LOGIN_WITH_PROVIDER,
      });
    }
    return api
      .coreLoginUserWithProviderPost(getLang(), params)
      .then((result) => {
        result.token_type = 'google';
        if (designConstants.isWeb) {
          result.google_token = accessToken;
        }
        return Promise.resolve(result);
      })
      .then((result) => {
        if (!login_only) {
          dispatch(saveToken(result));
          dispatch(firstSync(result));
          //Send FCM token to server after login
          if (!designConstants.isWeb) {
            AsyncStorage.getItem('pushToken').then((pushToken) => {
              if (pushToken) {
                dispatch(registerPushToken(pushToken, true));
              }
            });
          }
        }
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.LOGIN_WITH_PROVIDER));
      });
  };
}

export function loginWithApple(
  email,
  accessToken,
  login_only,
  institution_id,
  study_id,
  registration_code,
  activation_code,
  fakeDeviceId,
) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch, getState) => {
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    const redirect_uri = designConstants.isWeb
      ? window.location.origin + '/provider/apple/redirect'
      : undefined;

    const secondary_institution =
      window?.__institution_join_institution_if_no_consent;
    const silentJoin = institution_id == secondary_institution;
    if (silentJoin) {
      activation_code = undefined;
    }
    const appEulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));
    const appPrivacyVersion = parseInt(getConfig('MMP_PRIVACY_VERSION'));

    const utm_params = await getUtmParams(login_only);

    let params = MMPApi.LoginUserWithProviderParams.constructFromObject({
      provider: 'apple',
      access_token: accessToken,
      token_type: 'apple',
      redirect_uri,
      email,
      activation_code,
      client_type: designConstants.isWeb ? 'web' : 'mobile',
      login_only: login_only ? 1 : 0,
      device_id: getDeviceId() + (fakeDeviceId ? Math.random(1000000000) : ''),
      device_details: getDeviceDetails(),
      institution: activation_code ? undefined : institution_id || undefined,
      study_id: activation_code ? undefined : study_id,
      registration_code: registration_code,
      eula_version: login_only ? undefined : appEulaVersion,
      privacy_notice_version: login_only ? undefined : appPrivacyVersion,
      ...utm_params,
    });
    if (!login_only) {
      dispatch({
        type: types.LOGIN_WITH_PROVIDER,
      });
    }
    return api
      .coreLoginUserWithProviderPost(getLang(), params)
      .then((result) => {
        result.token_type = 'apple';
        return Promise.resolve(result);
      })
      .then((result) => {
        if (!login_only) {
          dispatch(saveToken(result));
          dispatch(firstSync(result));
          //Send FCM token to server after login
          if (!designConstants.isWeb) {
            AsyncStorage.getItem('pushToken').then((pushToken) => {
              if (pushToken) {
                dispatch(registerPushToken(pushToken, true));
              }
            });
          }
        }
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.LOGIN_WITH_PROVIDER));
      });
  };
}

export function connectWithProvider(provider, token) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.AuthCallsApi();
      let params = MMPApi.ProviderParams.constructFromObject({
        provider: provider,
        access_token: token,
        client_type: designConstants.isWeb ? 'web' : undefined,
      });

      return api.coreConnectUserToProviderPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.CONNECT_WITH_PROVIDER,
            payload: {
              provider,
              token,
            },
          });
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.CONNECT_WITH_PROVIDER));
        },
      );
    });
  };
}

export function disconnectFromProvider(provider) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.AuthCallsApi();
      let params = MMPApi.ProviderParams.constructFromObject({
        provider: provider,
      });

      return api.coreDisconnectUserFromProviderPost(getLang(), params).then(
        (result) => {
          return dispatch({
            type: types.DISCONNECT_FROM_PROVIDER,
            payload: provider,
          });
        },
        (error) => {
          return dispatch(apiError(error, types.DISCONNECT_FROM_PROVIDER));
        },
      );
    });
  };
}

export function register(
  email,
  username,
  password,
  institution,
  study_id,
  registration_code,
  activationCode,
) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch, getState) => {
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    fillAppHeaders(defaultClient, getState);

    let passwordEncrypted = require('sha256')(
      'Uw0BEKDs7LZ1$Oh*xQO2' + require('md5')(password),
    );
    let signupParams = {
      email: email,
      username: username,
      password: passwordEncrypted,
    };

    const secondary_institution =
      window?.__institution_join_institution_if_no_consent;

    if (activationCode) {
      signupParams.activation_code = activationCode;
      if (institution === secondary_institution) {
        signupParams.institution = institution;
        //        signupParams.study_id = study_id;
      }
    } else {
      signupParams.institution = institution;
      signupParams.study_id = study_id;
      signupParams.registration_code = registration_code;
    }
    if (designConstants.isWeb) {
      const utm_params = await getUtmParams(false);
      signupParams = {
        ...signupParams,
        ...utm_params,
      };
    } else {
      signupParams.utm_source =
        DeviceInfo.getBundleId() +
        ' ' +
        DeviceInfo.getVersion() +
        ' ' +
        Platform.OS;
    }
    signupParams.eula_version = parseInt(getConfig('MMP_EULA_VERSION'));
    signupParams.privacy_notice_version = parseInt(
      getConfig('MMP_PRIVACY_VERSION'),
    );

    let params = MMPApi.RegisterParams.constructFromObject(signupParams);
    return api
      .coreRegisterUserPost(getLang(), params)
      .then((result) => {
        const skip_consent = window?.__institution_skip_consent;
        if (
          !!activationCode &&
          institution !== secondary_institution &&
          (skip_consent || !!study_id)
        ) {
          return dispatch(
            joinInstitutionByActivationCode(
              result.user_id,
              activationCode,
              study_id,
              registration_code,
            ),
          )
            .then((joinResult) => {
              return Promise.resolve({ ...result, ...joinResult });
            })
            .catch((err) => {
              return Promise.resolve({ ...result, ...err });
            });
        }
        return Promise.resolve(result);
      })
      .then((result) => {
        const secondary_institution =
          window?.__institution_join_institution_if_no_consent;
        if (!!institution && institution == secondary_institution) {
          result = {
            ...result,
            institution_msg: undefined,
            result_msg: undefined,
          };
        }

        return result;
      })
      .then(
        (result) => {
          trackSignUp('Drupal');
          dispatch({
            type: types.REGISTER_SUCCESS,
            payload: result,
          });
          return Promise.resolve(result);
        },
        (error) => {
          return dispatch(apiError(error, types.REGISTER));
        },
      );
  };
}

export function changePassword(oldpassword, newpassword) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.AuthCallsApi();
      let passwordEncrypted = require('sha256')(
        'Uw0BEKDs7LZ1$Oh*xQO2' + require('md5')(newpassword),
      );
      let currentPasswordEncrypted = require('sha256')(
        'Uw0BEKDs7LZ1$Oh*xQO2' + require('md5')(oldpassword),
      );
      let params = MMPApi.ChangePasswordParams.constructFromObject({
        current_password: currentPasswordEncrypted,
        password: passwordEncrypted,
      });
      return api.coreChangePasswordPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.CHANGE_PASSWORD_SUCCESS,
          });
          return Promise.resolve();
        },
        (error) => {
          return dispatch(apiError(error, types.CHANGE_PASSWORD));
        },
      );
    });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params = MMPApi.ForgotPasswordParams.constructFromObject({
      email: email,
    });
    return api.coreForgotPasswordPost(getLang(), params).then(
      (result) => {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
        });
        return Promise.resolve();
      },
      (error) => {
        return dispatch(apiError(error, types.FORGOT_PASSWORD));
      },
    );
  };
}

export function changeEmail(email, password) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.AuthCallsApi();
      let passwordEncrypted = require('sha256')(
        'Uw0BEKDs7LZ1$Oh*xQO2' + require('md5')(password),
      );
      let params = MMPApi.ChangeEmailParams.constructFromObject({
        email: email,
        password: passwordEncrypted,
      });
      return api.coreChangeEmailPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.CHANGE_EMAIL_SUCCESS,
          });
          return Promise.resolve();
        },
        (error) => {
          return dispatch(apiError(error, types.CHANGE_EMAIL));
        },
      );
    });
  };
}

export function requestIsPasswordSet() {
  return (dispatch) => {
    return checkToken().then(() => {
      let user_record_params = MMPApi.GetRecordParams.constructFromObject({
        type: 'user_record',
      });
      dispatch({
        type: types.GET_IS_PASSWORD_SET_REQUEST,
      });

      let api = new MMPApi.RecordCallsApi();

      return api.syncGetRecordPost(getLang(), user_record_params).then(
        (result) => {
          dispatch({
            type: types.GET_IS_PASSWORD_SET_SUCCESS,
            payload: result,
          });
          return Promise.resolve(result?.content?.password_set);
        },
        (error) => {
          dispatch({
            type: types.GET_IS_PASSWORD_SET_FAILURE,
          });

          let err = apiError(
            error,
            types.GET_IS_PASSWORD_SET_REQUEST,
            requestIsPasswordSet,
          );
          return dispatch(err);
        },
      );
    });
  };
}

export function joinInstitution(institution, study_id, registration_code) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch) => {
    let api = new MMPApi.UserInstitutionsApi();
    let params;
    const utm_params = await getUtmParams(false);
    params = MMPApi.JoinInstitutionParams.constructFromObject({
      institution,
      study_id,
      registration_code,
      ...utm_params,
    });
    return api
      .institutionsJoinInstitutionPostWithHttpInfo(getLang(), params)
      .then((result) => {
        dispatch({ type: types.JOIN_INSTITUTION_SUCCESS });
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, 'JOIN_INSTITUTION'));
      });
  };
}

export function getEmailByActivationCode(activationCode) {
  return (dispatch) => {
    let api = new MMPApi.UserInstitutionsApi();
    let params;
    params = MMPApi.GetEmailByActivationCodeParams.constructFromObject({
      activation_code: activationCode,
    });
    return api
      .institutionsGetEmailByActivationCodePost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result?.email || null);
      })
      .catch((error) => {
        return dispatch(apiError(error, 'GET_EMAIL_BY_ACTIVATION_CODE'));
      });
  };
}

export function registerPushToken(pushToken, loggedIn) {
  return (dispatch) => {
    let api = new MMPApi.NotificationsApi();
    let params = new MMPApi.RegisterFcmTokenParams.constructFromObject({
      fcm_token: pushToken,
      type: Platform.OS,
    });

    // messaging()
    //   .subscribeToTopic('news')
    //   .then((result) => {
    //   })
    //   .catch((err) => {
    //   });
    if (loggedIn) {
      return checkToken().then(() => {
        return api.notificationsRegisterFcmTokenPost(getLang(), params);
      });
    } else {
      return api.notificationsRegisterAnonymousFcmTokenPost(getLang(), params);
    }
  };
}

export function joinInstitutionByActivationCode(
  uid,
  activationCode,
  study_id,
  registration_code,
) {
  const skip_consent = window?.__institution_skip_consent;
  if (skip_consent) {
    study_id = undefined;
  }

  return async (dispatch, getState) => {
    let api = new MMPApi.UserInstitutionsApi();
    let params;
    const user_id = uid || getState().auth?.user?.user_id;
    const utm_params = await getUtmParams(false);
    params = MMPApi.JoinInstitutionByActivationCodeParams.constructFromObject({
      activation_code: activationCode,
      uid: user_id,
      study_id,
      registration_code,
      ...utm_params,
    });
    return api
      .institutionsJoinInstitutionByActivationCodePost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, 'JOIN_INSTITUTION_BY_ACTIVATION_CODE'));
      });
  };
}

export function verifyAccount(account_token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.VerifyAccountParams.constructFromObject({ account_token });
    dispatch({ type: types.ACTIVATE_ACCOUNT });
    return api
      .coreVerifyAccountPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.ACTIVATE_ACCOUNT));
      });
  };
}

export function verifyEmail(email_token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.VerifyEmailParams.constructFromObject({ email_token });
    dispatch({ type: types.ACTIVATE_ACCOUNT });
    return api
      .coreVerifyEmailPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.VERIFY_EMAIL));
      });
  };
}

export function verifyPasswordReset(password_token, new_password) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    let passwordEncrypted = require('sha256')(
      'Uw0BEKDs7LZ1$Oh*xQO2' + require('md5')(new_password),
    );

    params = MMPApi.ResetPasswordParams.constructFromObject({
      password_token,
      new_password: passwordEncrypted,
    });
    dispatch({ type: types.RESET_PASSWORD });
    return api
      .coreResetPasswordPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.RESET_PASSWORD));
      });
  };
}

export function unsubscribeEmail(unsubscribe_email_token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.UnsubscribeEmailParams.constructFromObject({
      unsubscribe_email_token,
    });
    dispatch({ type: types.UNSUBSCRIBE_EMAIL });
    return api
      .coreUnsubscribeEmailPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.UNSUBSCRIBE_EMAIL));
      });
  };
}

export function getPasswordResetEmail(password_token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.GetResetPasswordEmailParams.constructFromObject({
      password_token,
    });
    dispatch({ type: types.UNSUBSCRIBE_EMAIL });
    return api
      .coreGetResetPasswordEmailPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result.email);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.UNSUBSCRIBE_EMAIL));
      });
  };
}

export function getEmailForUnsubscribe(unsubscribe_email_token) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let params;
    params = MMPApi.UnsubscribeEmailParams.constructFromObject({
      unsubscribe_email_token,
    });
    dispatch({ type: types.UNSUBSCRIBE_EMAIL });
    return api
      .coreGetUnsubscribeEmailEmailPost(getLang(), params)
      .then((result) => {
        return Promise.resolve(result.email);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.UNSUBSCRIBE_EMAIL));
      });
  };
}

export function deleteAccount() {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    let defaultClient = MMPApi.ApiClient.instance;
    defaultClient.timeout = 60000 * 5;
    dispatch({ type: profileTypes.DELETE_ACCOUNT_PENDING });
    return api
      .coreDeleteUserPost(getLang())
      .then((result) => {
        dispatch({ type: profileTypes.ACCOUNT_DELETED });
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, profileTypes.DELETE_ACCOUNT_PENDING));
      });
  };
}
