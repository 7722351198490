import React, { Component } from 'react';
import {
  Dimensions,
  FlatList,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { designConstants } from '../styles';
import Footer from './Footer';
import Icon from './Icon';
import ModalWrapper from './ModalWrapper';
import { useThemeContext, withTheme } from '../styles/useThemeContext';

const isIOS = Platform.OS === 'ios';
const windowWidth = Dimensions.get('window').width;

class ModalPicker extends Component {
  state = {
    modalVisible: false,
    // If selected id was not changed in picker, innerSelectedId will be null
    innerSelectedId: null,
  };

  onSelect = (id) => {
    const { onSelect, triggerSelectOnlyAfterModalWasClosed, autoHide } =
      this.props;

    if (!autoHide) {
      return () => {
        onSelect(id);
      };
    }

    if (triggerSelectOnlyAfterModalWasClosed) {
      return () => {
        this.setState(
          { innerSelectedId: id },
          autoHide ? this.toggleModalVisibility : () => {},
        );
      };
    }

    return () => {
      onSelect(id);
      autoHide && this.toggleModalVisibility();
    };
  };

  selectAfterModalWasClosed = () => {
    const { onSelect, triggerSelectOnlyAfterModalWasClosed } = this.props;
    const { innerSelectedId } = this.state;
    if (triggerSelectOnlyAfterModalWasClosed && innerSelectedId) {
      onSelect(innerSelectedId);
      this.setState({ innerSelectedId: null });
    }
  };

  toggleModalVisibility = () => {
    this.setState((prevState) => {
      return {
        modalVisible: !prevState.modalVisible,
      };
    });
  };

  // Previous implementation, it works correct, but have perfomance issues if list is too long
  _renderModalContent = () => {
    const {
      children,
      selectedId,
      cancelButtonText,
      defaultStyleOfChildrenRendering,
      headerText,
      endListToolTip,
    } = this.props;
    const { colors } = this.props.theme;
    const modalPickerStyles = getStyles(colors);
    let newChildren = [];

    if (defaultStyleOfChildrenRendering) {
      newChildren = React.Children.map(children, (child) => {
        return (
          <TouchableNative
            onPress={this.onSelect(child.props.id)}
            style={[
              modalPickerStyles.defaultItemWrapper,
              child.props.id === selectedId && {
                backgroundColor: colors.buttonBlue,
              },
            ]}
            key={'itemKey_' + child.props.id}
          >
            <View style={modalPickerStyles.childWrapper}>{child}</View>

            <View style={modalPickerStyles.checkedCircle}>
              {child.props.id === selectedId && (
                <Icon
                  name={'checkmark'}
                  type={'Ionicons'}
                  size={designConstants.icons.size}
                  color={colors.buttonBlue}
                />
              )}
            </View>
          </TouchableNative>
        );
      });
    } else {
      newChildren = React.Children.map(children, (child) => {
        return (
          <TouchableNative
            onPress={this.onSelect(child.props.id)}
            key={'itemKey_' + child.props.id}
          >
            {child}
          </TouchableNative>
        );
      });
    }

    // Important: do not wrap ScrollView in additional Views - it could break ModalWrapper rendering
    return [
      headerText ? (
        <Text
          style={modalPickerStyles.header}
          onLayout={this.onHeaderLayout}
          key={'text_header'}
        >
          {headerText}
        </Text>
      ) : (
        <View style={modalPickerStyles.emptyHeader} key={'text_header_empty'} />
      ),
      <ScrollView style={modalPickerStyles.scrollView} key={'scrollview'}>
        {newChildren}
        {endListToolTip}
      </ScrollView>,
      cancelButtonText && (
        <Footer style={modalPickerStyles.footer} key={'footer'}>
          <TouchableNative
            style={modalPickerStyles.cancelButton}
            onPress={this.toggleModalVisibility}
          >
            <Text lightGray ellipsizeMode={'tail'} numberOfLines={1}>
              {cancelButtonText}
            </Text>
          </TouchableNative>
        </Footer>
      ),
    ];
  };

  keyExtractor = (item) => {
    return 'extracted_key_' + item.key;
  };

  renderItem = ({ item }) => item;

  renderModalContent = () => {
    const {
      children,
      selectedId,
      cancelButtonText,
      defaultStyleOfChildrenRendering,
      headerText,
      endListToolTip,
    } = this.props;
    const { colors } = this.props.theme;
    const modalPickerStyles = getStyles(colors);
    let newChildren = [];

    if (defaultStyleOfChildrenRendering) {
      newChildren = React.Children.map(children, (child) => {
        const checked = child.props.id === selectedId;

        return (
          <TouchableNative
            onPress={this.onSelect(child.props.id)}
            style={[
              modalPickerStyles.defaultItemWrapper,
              {
                borderColor: checked ? colors.buttonBlue : colors.liteGray,
              },
            ]}
            key={'itemKey_' + child.props.id}
          >
            <View style={modalPickerStyles.childWrapper}>{child}</View>

            <View style={modalPickerStyles.checkedCircle}>
              <Icon
                name={
                  checked
                    ? 'checkbox-marked-circle'
                    : 'checkbox-blank-circle-outline'
                }
                type={'MaterialCommunityIcons'}
                size={designConstants.icons.size}
                color={checked ? colors.buttonBlue : colors.textSecondary}
              />
            </View>
          </TouchableNative>
        );
      });
    } else {
      newChildren = React.Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        return (
          <TouchableNative
            onPress={this.onSelect(child.props.id)}
            key={'itemKey_' + child.props.id}
          >
            {child}
          </TouchableNative>
        );
      });
    }

    // Important: do not wrap ScrollView in additional Views - it could break ModalWrapper rendering
    return [
      headerText ? (
        <Text
          style={modalPickerStyles.header}
          onLayout={this.onHeaderLayout}
          key={'text_header'}
        >
          {headerText}
        </Text>
      ) : (
        <View style={modalPickerStyles.emptyHeader} key={'text_header_empty'} />
      ),
      <FlatList
        style={modalPickerStyles.scrollView}
        key={'flatlist'}
        keyboardShouldPersistTaps="always"
        data={newChildren}
        renderItem={this.renderItem}
        keyExtractor={this.keyExtractor}
        scrollEnabled={true}
      />,
      <View key={'endlisttooltip'}>{endListToolTip}</View>,
      cancelButtonText && (
        <Footer style={modalPickerStyles.footer} key={'footer'}>
          <TouchableNative
            style={modalPickerStyles.cancelButton}
            onPress={this.toggleModalVisibility}
          >
            <Text lightGray ellipsizeMode={'tail'} numberOfLines={1}>
              {cancelButtonText}
            </Text>
          </TouchableNative>
        </Footer>
      ),
    ];
  };

  render() {
    const {
      children,
      selectedId,
      style,
      placeholder,
      customPickerElement,
      androidInitialBarColor,
      androidInitialBarStyle,
      currentScene,
    } = this.props;
    const { colors } = this.props.theme;
    const modalPickerStyles = getStyles(colors);
    const { modalVisible } = this.state;

    if (customPickerElement) {
      return (
        <TouchableNative onPress={this.toggleModalVisibility} style={style}>
          {customPickerElement}
          <ModalWrapper
            isVisible={modalVisible}
            closeModal={
              designConstants.isWeb ? () => false : this.toggleModalVisibility
            }
            androidInitialBarColor={androidInitialBarColor}
            androidInitialBarStyle={androidInitialBarStyle}
            onModalHide={this.selectAfterModalWasClosed}
            currentScene={currentScene}
          >
            {this.renderModalContent()}
          </ModalWrapper>
        </TouchableNative>
      );
    }

    let selectedChild = null;
    if (selectedId !== null) {
      React.Children.forEach(children, (child) => {
        if (child.props.id === selectedId) {
          selectedChild = child;
        }
      });
    }

    if (!selectedChild) {
      selectedChild = <Text lightGray>{placeholder}</Text>;
    }

    return (
      <TouchableNative
        onPress={this.toggleModalVisibility}
        style={[modalPickerStyles.pickerButton, style]}
      >
        <ModalPickerDefaultComponent>
          {selectedChild}
        </ModalPickerDefaultComponent>
        <ModalWrapper
          isVisible={modalVisible}
          closeModal={this.toggleModalVisibility}
          androidInitialBarColor={androidInitialBarColor}
          androidInitialBarStyle={androidInitialBarStyle}
          onModalHide={this.selectAfterModalWasClosed}
          currentScene={currentScene}
        >
          {this.renderModalContent()}
        </ModalWrapper>
      </TouchableNative>
    );
  }
}

const ModalPickerDefaultComponent = ({ children }) => {
  const { colors } = useThemeContext();
  const modalPickerStyles = getStyles(colors);
  return (
    <View style={modalPickerStyles.itemWrapper}>
      <View style={modalPickerStyles.childWrapper}>{children}</View>
      <View style={modalPickerStyles.iconWrapper}>
        <Icon
          name={'expand-more'}
          type={'MaterialIcons'}
          size={designConstants.icons.size}
          style={{
            marginRight: designConstants.icons.expandIconCorrectingMarginRight,
          }}
          color={colors.textPrimary}
        />
      </View>
    </View>
  );
};

const getStyles = (colors) =>
  StyleSheet.create({
    header: {
      textAlign: 'left',
      fontSize: designConstants.fontSizes.headerLarge,
      paddingVertical: 3 * designConstants.paddings.textFieldVerticalPadding,
      //paddingBottom: 3 * designConstants.paddings.textFieldVerticalPadding,
      paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
      backgroundColor: colors.defaultBackground,
    },
    emptyHeader: {
      height: designConstants.paddings.paddingBetweenElementsSmall,
      backgroundColor: colors.defaultBackground,
    },
    pickerButton: {
      flexDirection: 'row',
    },
    scrollView: {
      paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
      backgroundColor: colors.defaultBackground,
    },
    cancelButton: {
      height: designConstants.footer.height,
      width: windowWidth,
      backgroundColor: colors.defaultBackground,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    itemWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: designConstants.paddings.textFieldVerticalPadding,
      paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
      height: designConstants.heights.touchableMinHeight,
    },
    childWrapper: {
      flex: 5,
    },
    iconWrapper: {
      width: designConstants.icons.width,
      height: designConstants.icons.height,
      alignItems: 'center',
      justifyContent: 'center',
    },

    footer: {
      borderTopWidth: 0,
      marginTop: -1, // android is not hiding borderTop
      elevation: 0,
    },

    defaultItemWrapper: {
      paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
      paddingVertical: designConstants.paddings.textFieldVerticalPadding,
      marginBottom: designConstants.paddings.paddingBetweenElementsSmall,
      borderWidth: 1,
      borderColor: colors.buttonBlue,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: designConstants.heights.listItemMinHeightMedium,
      flex: 1,
      borderRadius: 4,
    },

    checkedCircle: {
      borderRadius: 100,
      width: designConstants.icons.wrappingCircleWidth,
      height: designConstants.icons.wrappingCircleHeight,
      backgroundColor: colors.defaultBackground,
      justifyContent: 'center',
      alignItems: 'center',
    },

    defaultAnchorStyle: {
      alignItems: 'center',
      flexDirection: 'row',
      backgroundColor: colors.defaultBackground,
    },
  });

ModalPicker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onSelect: PropTypes.func,
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  androidInitialBarColor: PropTypes.string,
  androidInitialBarStyle: PropTypes.oneOf([
    'default',
    'light-content',
    'dark-content',
  ]),
  // style: ViewPropTypes.style,
  placeholder: PropTypes.string,
  cancelButtonText: PropTypes.string,
  headerText: PropTypes.string,
  customPickerElement: PropTypes.node,
  endListToolTip: PropTypes.node,

  // This prop is defining will the children list be rendered with default checmarks if it is selected and
  // default style
  defaultStyleOfChildrenRendering: PropTypes.bool,
  // This prop informs about current screen, if it is changed, the modal should be closed
  currentScene: PropTypes.string,
  triggerSelectOnlyAfterModalWasClosed: PropTypes.bool,
  autoHide: PropTypes.bool,
};
ModalPicker.defaultProps = {
  children: null,
  onSelect: () => {},
  selectedId: null,
  androidInitialBarColor: '#0C74AD',
  androidInitialBarStyle: 'default',
  placeholder: null,
  cancelButtonText: null,
  customPickerElement: null,
  endListToolTip: null,
  defaultStyleOfChildrenRendering: true,
  headerText: null,
  currentScene: null,
  triggerSelectOnlyAfterModalWasClosed: true,
  autoHide: true,
};

export { ModalPickerDefaultComponent };
export default withTheme(ModalPicker);
