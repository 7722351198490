// @flow
import {
  NAVIGATOR_SET_CURRENT_ROUTE,
  NAVIGATOR_TOGGLE_DRAWER,
  NAVIGATOR_WEB_INITIALIZED,
} from './actiontypes';

type State = {
  info: {
    routeName: string,
  },
  drawerOpen: boolean,
  webInitialized: boolean,
};

const initialState = {
  info: {
    routeName: 'root',
  },
  drawerOpen: false,
  webInitialized: false,
};

const NavigatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATOR_TOGGLE_DRAWER: {
      state = { ...state };
      state.drawerOpen = !state.drawerOpen;
      break;
    }
    case NAVIGATOR_SET_CURRENT_ROUTE: {
      state = { ...state };
      state.currentRoute = action.payload;
      break;
    }
    case NAVIGATOR_WEB_INITIALIZED: {
      state = { ...state };
      state.webInitialized = true;
      break;
    }
  }
  return state;
};

export { NavigatorReducer };
