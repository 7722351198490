import React, { useMemo } from 'react';
import { Text, View } from 'react-native';
import { connect, Provider } from 'react-redux';
import SplashView from './modules/Navigator/components/SplashView';
import getStore from './redux/store';
import WebWrappers from './modules/Navigator/containers/WebWrappers';
import LoadingOverlay from './design/components/LoadingOverlay';
import authStrings from './modules/Auth/strings';
import AppNavigator from './modules/Navigator/navigators/AppNavigator';
import { setAppLanguage } from './shared/utilities/languageutils';
// import WebWrappers from './modules/Navigator/containers/WebWrappers';
// const AppNavigator = React.lazy(() => {
//   return import(
//     /* webpackChunkName: "InitialLoad" */ './modules/Navigator/navigators/AppNavigator'
//   );
// });
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getConfig from './shared/utilities/getConfig';
import buildConfig from './build_config.json';
import { designConstants } from './design/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import AppThemeProvider from './design/styles/ThemeProvider';
import useThemeContext from './design/styles/useThemeContext';
import CrashUsageController from './modules/Settings/components/CrashUsageController';
import { SafeAreaProvider } from 'react-native-safe-area-context';

if (__DEV__) {
  require('./ReactotronConfig');
}

require('moment/locale/de.js');
require('moment/locale/es.js');
require('moment/locale/fr.js');
require('moment/locale/ru.js');
require('moment/locale/ko.js');
require('moment/locale/zh-cn.js');
require('./strings/plurals');

const MuiThemeProvider = (props) => {
  const { colorTheme } = useThemeContext();
  const baseTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorTheme,
        },
        overrides: {
          MuiPaper: {
            root: {
              backgroundColor: 'transparent',
            },
          },
        },
      }),
    [colorTheme],
  );
  return <ThemeProvider theme={baseTheme} {...props} />;
};

const Overlay = (props) => {
  const { sync_in_progress, message, currentScene, logout_attempt_flag } =
    props;
  let canBeVisible = false;
  if (logout_attempt_flag) {
    canBeVisible = true;
  }
  return (
    <LoadingOverlay
      modal={false}
      visible={canBeVisible && sync_in_progress}
      textContent={sync_in_progress ? message : null}
    />
  );
};

const ConnectedOverlay = connect((state) => {
  return {
    sync_in_progress: state.sync ? state.sync.sync_in_progress : false,
    message:
      state.sync && state.auth.logout_attempt_flag
        ? authStrings.logout_logging_out_message_for_overlay
        : state.sync
          ? state.sync.message
          : null,
    currentScene:
      state.routes && state.routes.info ? state.routes.info.routeName : null,
    logout_attempt_flag: state.auth ? state.auth.logout_attempt_flag : null,
  };
})(Overlay);

class ManageMyPain extends React.Component {
  constructor(props) {
    super(props);
    console.log('SLOAD', 2, new Date().getTime());
    // checkTranslations();
    // window.__institution_landing_background =
    //   '/institutions/gsc/landing_background.jpg';
    // window.__institution_landing_logo =
    //   '/institutions/gsc/landing_institution_logo.png';
    // window.__institution_id = 1743395;
    // window.__institution_join_institution_if_no_consent = 1676771;
    // window.__institution_skip_consent = true;
    // window.__institution_study_id_validation_on_decline = false;
    this.store = null;
    this.state = {
      restored: false,
    };
  }

  UNSAFE_componentWillMount() {
    //        console.log('LANGUAGE??', authStrings.getInterfaceLanguage());
    console.log('SLOAD', 3, new Date().getTime());
    getStore().then((store) => {
      console.log('SLOAD', 4, store, new Date().getTime());
      setAppLanguage(store.getState().settings.language);
      this.store = store;
      // setTimeout(() => {
      console.log('SLOAD', 5, new Date().getTime());
      this.setState({ restored: true });
      // }, 300);
    });
  }

  render() {
    const { restored } = this.state;
    console.log('SLOAD', 6, new Date().getTime());
    if (!restored) {
      return <SplashView />;
    }
    console.log('SLOAD', 7, new Date().getTime());
    let RootView = WebWrappers;
    return (
      <SafeAreaProvider>
        <View style={{ flex: 1, alignSelf: 'stretch' }}>
          <GestureHandlerRootView>
            <GoogleOAuthProvider
              clientId={getConfig('MMP_GOOGLE_SIGNIN_WEB_CLIENT_ID')}
            >
              <Provider store={this.store} testID={'provider_store'}>
                <CrashUsageController />
                <AppThemeProvider>
                  <MuiThemeProvider>
                    <RootView style={{ flex: 1, alignSelf: 'stretch' }}>
                      <AppNavigator />
                    </RootView>
                  </MuiThemeProvider>
                </AppThemeProvider>
                <ConnectedOverlay />
              </Provider>
            </GoogleOAuthProvider>
          </GestureHandlerRootView>
          {(getConfig('MMP_APPLE_PACKAGE_NAME').includes('.dev') ||
            getConfig('MMP_APPLE_PACKAGE_NAME').includes('.preprod')) && (
            <Text
              note
              center
              style={{
                position: 'fixed',
                top: designConstants.statusBarHeight + 5,
                left: 5,
                zIndex: 999999,
              }}
            >
              {'V. ' +
                getConfig('MMP_BUGSNAG_STAGE') +
                ' ' +
                buildConfig.branch +
                ' ' +
                buildConfig.commit}
            </Text>
          )}
        </View>
      </SafeAreaProvider>
    );
  }
}

export default ManageMyPain;
