import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { designConstants } from '../styles';
import Icon from './Icon';
import Text from './Text';
import Switch from './Switch';
import { withTheme } from '../styles/useThemeContext';

class SwitchSection extends Component {
  render() {
    const { colors } = this.props.theme;
    const {
      text,
      icon,
      customRightSide,
      switchValue,
      onSwitchValueChange,
      style,
    } = this.props;
    return (
      <View style={[switchSectionStyles.switchSection, style]}>
        <View style={switchSectionStyles.switchSectionLeftContent}>
          {icon ? (
            <Icon
              name={icon.name}
              type={icon.type}
              color={colors.textPrimary}
            />
          ) : null}
          <Text
            style={{
              marginLeft: icon
                ? designConstants.paddings.paddingBetweenElementsSmall
                : 0,
              marginRight: designConstants.paddings.paddingBetweenElementsSmall,
            }}
          >
            {text}
          </Text>
        </View>
        {customRightSide ? (
          customRightSide
        ) : (
          <View style={switchSectionStyles.switchWrapper}>
            <Switch value={switchValue} onValueChange={onSwitchValueChange} />
          </View>
        )}
      </View>
    );
  }
}

const switchSectionStyles = StyleSheet.create({
  switchSection: {
    marginVertical: designConstants.paddings.paddingBetweenElementsMedium,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchSectionSpacer: {
    height: designConstants.paddings.paddingBetweenElementsLarge,
  },
  switchSectionLeftContent: {
    flex: 5,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  switchSectionText: {
    marginLeft: designConstants.paddings.paddingBetweenElementsSmall,
  },
  switchWrapper: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

SwitchSection.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  customRightSide: PropTypes.node,
  switchValue: PropTypes.bool,
  onSwitchValueChange: PropTypes.func,
};

SwitchSection.defaultProps = {
  text: null,
  icon: null,
  customRightSide: null,
  switchValue: false,
  onSwitchValueChange: () => null,
};

export default withTheme(SwitchSection);
