import LocalizedStrings from 'react-native-localization';

export default new LocalizedStrings({
  en: {
    error_in_data_connection: 'Data connection cannot be established',
    error_app_need_to_be_updated_title: 'Please update app to sync',
    error_app_need_to_be_updated_message:
      'We have made changes to the app. Synchronization of your data will not occur until you update.',
    error_app_need_to_be_updated_update_button: 'Update',
    error_app_need_to_be_updated_close_button: 'Do It Later',
  },

  de: {
    error_in_data_connection: 'Datenverbindung kann nicht hergestellt werden',
    error_app_need_to_be_updated_title:
      'Bitte aktualisieren Sie die App um zu synchronisieren',
    error_app_need_to_be_updated_message:
      'Wir haben Änderungen an der App vorgenommen. Die Synchronisierung Ihrer Daten erfolgt erst, wenn Sie das Update durchführen.',
    error_app_need_to_be_updated_update_button: 'Aktualisieren',
    error_app_need_to_be_updated_close_button: 'Später machen',
  },

  es: {
    error_in_data_connection: 'Conexión de datos no puede ser establecida',
    error_app_need_to_be_updated_title:
      'Por favor, actualice la aplicación para sincronizar',
    error_app_need_to_be_updated_message:
      'Hemos realizado cambios en la aplicación. La sincronización de sus datos no ocurrirá hasta que actualice.',
    error_app_need_to_be_updated_update_button: 'Actualizar',
    error_app_need_to_be_updated_close_button: 'Hacer más tarde',
  },

  fr: {
    error_in_data_connection: 'Connexion de données ne peut pas être établie',
    error_app_need_to_be_updated_title:
      'Veuillez mettre app à jour pour synchroniser',
    error_app_need_to_be_updated_message:
      "Nous avons effectué des changements au niveau de l'application. La synchronisation de vos données ne pourra pas se faire tant que vous n'aurez pas effectué la mise à jour.",
    error_app_need_to_be_updated_update_button: 'Mettre à Jour',
    error_app_need_to_be_updated_close_button: 'Faire plus tard',
  },

  ko: {
    error_in_data_connection: '데이터 연결이 설정 되지 않습니다',
    error_app_need_to_be_updated_title: '동기화 할 어플을 업데이트하십시오',
    error_app_need_to_be_updated_message:
      '저희는 앱에 변경사항을 적용했습니다. 업데이트할 때까지 데이터가 동기화되지 않을 것입니다.',
    error_app_need_to_be_updated_update_button: '업데이트',
    error_app_need_to_be_updated_close_button: '나중에 하기',
  },

  ru: {
    error_in_data_connection: 'Сбой при попытке соединения с сервером',
    error_app_need_to_be_updated_title:
      'Пожалуйста, обновите приложение для синхронизации',
    error_app_need_to_be_updated_message:
      'Наше приложение претерпело изменения. Синхронизация не будет происходить, пока вы не обновите приложение.',
    error_app_need_to_be_updated_update_button: 'Обновить',
    error_app_need_to_be_updated_close_button: 'Позже',
  },

  'zh-rCN': {
    error_in_data_connection: '无法建立数据连接',
    error_app_need_to_be_updated_title: '请更新应用以同步',
    error_app_need_to_be_updated_message:
      '我们对应用程序进行了更改。 在您更新之前，不会同步您的数据。',
    error_app_need_to_be_updated_update_button: '更新',
    error_app_need_to_be_updated_close_button: '稍后再做',
  },
});
