import medsConstants from './constants';
import strings from './strings';

export function getStrengthUnits(strength_units) {
  const medicationStrengthUnitsObject = medsConstants.strengthUnits.find(
    (strengthUnit) => strengthUnit.value === strength_units,
  );
  let medicationStrengthUnits =
    strings[
      medsConstants.strengthUnits[medsConstants.strengthUnits.length - 1]
        .display_value
    ];
  medicationStrengthUnitsObject &&
    (medicationStrengthUnits =
      strings[medicationStrengthUnitsObject.display_value]);

  return medicationStrengthUnits;
}
