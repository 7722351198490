import React from 'react';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import useThemeContext from '../styles/useThemeContext';

const useStyles = makeStyles({
  switch: (colors) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colors.buttonBlue,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: colors.opacityBlue,
    },
  }),
});
export default (props) => {
  const { colors } = useThemeContext();
  const classes = useStyles(colors);

  return <Switch {...props} className={classes.switch} color="primary" />;
};
