// @flow
import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Text from './Text';
import PropTypes from 'prop-types';
import { designConstants } from '../../design/styles';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import Collapsible from 'react-native-collapsible';
import Orientation from 'react-native-orientation-locker';
import SyncIndicator from '../../modules/Sync/components/SyncIndicator';
import Button from './Button';
import { Spacer } from './index';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import useThemeContext from '../styles/useThemeContext';

// Since the Card should be full-wide width, the width is fixed, and all correspondent widths are accessible via
// static props, it is needed e.g. for graphs WebView width calculation
const CARD_MARGIN = 8;
const CARD_PADDING = 20;
const getCardContainerWidth = () => {
  let width = Dimensions.get('window').width;
  return width - 2 * 8;
};
const getCardContentWidth = () => {
  if (designConstants.isWeb) {
    return 500;
  }
  let width = Dimensions.get('window').width;
  return width - 2 * 8 - 2 * 20;
};
const CARD_MIN_WIDTH = 300;

export {
  getCardContainerWidth,
  getCardContentWidth,
  CARD_MARGIN,
  CARD_PADDING,
  CARD_MIN_WIDTH,
};

class CardItemWide extends React.Component {
  state = {};
  onPress = () => {
    const { title, onBottomButtonPress } = this.props;
    title && trackButtonPress('Card bottom ' + title);
    onBottomButtonPress && onBottomButtonPress();
  };

  _onOrientationDidChange = (orientation) => {
    this.forceUpdate();
  };

  componentDidMount() {
    Orientation.addOrientationListener(this._onOrientationDidChange);
  }
  componentWillUnmount() {
    Orientation.removeOrientationListener(this._onOrientationDidChange);
  }

  onLayout = (e) => {
    if (this.props.onLayout) {
      this.props.onLayout(e);
    }

    let newWidth = e.nativeEvent.layout.width;
    let currentWidth = this.state.width;
    if (newWidth !== currentWidth) {
      this.setState({ width: newWidth });
    }
  };

  render() {
    const {
      title,
      children,
      titleStyle,
      boldTitle,
      bottomButton,
      style,
      noTitle,
      noPadding,
      collapsed,
      hasSync,
      onPressSync,
      syncText,
    } = this.props;
    return (
      <Collapsible
        collapsed={collapsed}
        style={[
          // { width: -1, flex: 1, alignSelf: 'stretch' },
          cardItemWideStyles.container,
          { backgroundColor: this.props.theme.colors.cardContainer },
          style,
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: 16,
          }}
        >
          {!noTitle &&
            (title ? (
              <Text
                bold={boldTitle}
                style={[cardItemWideStyles.text, titleStyle]}
              >
                {title}
              </Text>
            ) : (
              <View style={cardItemWideStyles.spacer} />
            ))}
          {hasSync ? (
            <>
              <Spacer />
              <Button
                outlined
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 6,
                }}
                onPress={onPressSync}
              >
                <SyncIndicator />
                <Text medium blue style={{ marginLeft: 6 }}>
                  {toSentenceCase(syncText)}
                </Text>
              </Button>
            </>
          ) : this.props.titleLeft ? (
            <Spacer />
          ) : null}
        </View>
        <View
          style={!noPadding && cardItemWideStyles.content}
          onLayout={this.onLayout}
        >
          {children}
        </View>
        {bottomButton && (
          <Button
            outlined
            noBorder
            style={cardItemWideStyles.bottomButton}
            text={bottomButton}
            onPress={this.onPress}
            ignoreSentenceCase
          />
        )}
      </Collapsible>
    );
  }
}

CardItemWide.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  boldTitle: PropTypes.bool,
  // titleStyle: ViewPropTypes.style,
  // style: ViewPropTypes.style,
  onBottomButtonPress: PropTypes.func,
  bottomButton: PropTypes.string,
  noTitle: PropTypes.bool,
  titleLeft: PropTypes.bool,
  noPadding: PropTypes.bool,
  collapsed: PropTypes.bool,
};

CardItemWide.defaultProps = {
  title: null,
  children: null,
  boldTitle: true,
  titleStyle: {},
  style: {},
  onBottomButtonPress: () => null,
  bottomButton: null,
  noTitle: false,
  noPadding: false,
  titleLeft: false,
  collapsed: false,
};

const cardItemWideStyles = StyleSheet.create({
  container: {
    //marginHorizontal: 8,
    marginVertical: 5,
  },
  content: {
    paddingHorizontal: 20,
    paddingVertical: 18,
    alignSelf: 'stretch',
  },
  spacer: {
    height: 20,
  },
  text: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 8,
  },
  bottomButton: {
    alignSelf: 'flex-start',
    borderRadius: 0,
    marginBottom: 12,
    marginLeft: -16,
    justifyContent: 'center',
  },
  buttonText: {
    alignSelf: 'stretch',
    textAlign: 'center',
  },
});

export default function ThemedCardItemWide(props) {
  const theme = useThemeContext();
  return <CardItemWide {...props} theme={theme} />;
}
