import { PAIN_GUIDE_CARD } from '../../modules/Feed/reducer';

export default (state) => {
  let cards = state.feed.cards;

  if (!cards.some((card) => card.type === PAIN_GUIDE_CARD)) {
    cards = cards.map((card) => {
      card.priority = card.priority * 10;
      return card;
    });
    const newCards = [
      ...cards,
      { type: PAIN_GUIDE_CARD, priority: 115, canHide: true },
    ];
    state = { ...state, feed: { ...state.feed, cards: newCards } };
    return state;
  }
  return state;
};
