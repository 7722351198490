import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import getConfig from '../../../shared/utilities/getConfig';
import { useWindowDimensions } from 'react-native';
import navigatorStrings from '../../Navigator/strings';
import strings from '../../Navigator/strings';
import introStrings from '../../Intro/strings';
import { designConstants } from '../../../design/styles';
import useThemeContext from '../../../design/styles/useThemeContext';

export default function PrivacyPolicyModal(props) {
  const { colors } = useThemeContext();
  const { height } = useWindowDimensions();
  const dialogHeight = height - 200;
  const link =
    getConfig('MMP_LINK_PRIVACY_POLICY') +
    '-' +
    strings.getLanguage().toLowerCase();

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="alert-dialog-title">
        {navigatorStrings.privacy_policy_screen_header}
      </DialogTitle>
      <iframe src={link} style={{ height: dialogHeight }} frameBorder="0" />
      <DialogActions>
        <Button
          onClick={props.onAccept}
          style={{
            backgroundColor: colors.lightGreen,
            color: colors.white,
          }}
          autoFocus
        >
          {introStrings.eula_close_button}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
