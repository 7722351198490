import {
  FILTER_RECORDS_END,
  FILTER_RECORDS_FINISHED,
  FILTER_RECORDS_PARSER_FINISHED,
} from './actiontypes';
import { applyDateRange, filterRecords } from './filter';
import Moment from 'moment/moment';
import { getOffset } from '../../shared/utilities/timeutils';
import { parserInvocation, recordsDataTransformer } from '../Records/services';
import { getMostCommonFields } from '../Records/actions';
import { formatMedicationField } from '../Records/utils';

const sortRecords = (records) => {
  let offset = getOffset(Moment().valueOf());
  return records.sort((a, b) => {
    let timeA = a.recordTime + a.timeOffset - offset;
    let timeB = b.recordTime + b.timeOffset - offset;
    return timeB - timeA;
  });
};

let filterTimer = null;
const filterRecordsMiddleware = (store) => (next) => (action) => {
  // if(action.type===SAVE_RECORD_SUCCESS) {
  //     next(action);
  //
  // }
  // if(action.type===DELETE_RECORD) {
  //     next(action);
  //     let filtered = [...store.getState().filter.filteredRecords];
  //     let index = filtered.findIndex(record => record.id===action.payload.id);
  //     filtered.splice(index, 1)
  //     return next({type: FILTER_RECORDS_END, payload: filtered});
  // }
  if (action.applyDateRange || action.shouldFilter) {
    next(action);
    // if(filterTimer) {
    //     clearTimeout(filterTimer);
    // }
    // filterTimer = setTimeout(() => {
    //     filterTimer = null;
    let records = store.getState().records.list;
    // next({type: 'FILTER_TIMEOUT_START_RECORDS'});

    let first_day = Moment().valueOf();
    let last_day = Moment().valueOf();
    let offset = getOffset(Moment().valueOf());

    first_day = records.reduce(
      (a, b) =>
        Math.min(a, b.recordTime + b.timeOffset - offset || Number.MAX_VALUE),
      first_day,
    );
    last_day = records.reduce(
      (a, b) =>
        Math.max(a, b.recordTime + b.timeOffset - offset || Number.MIN_VALUE),
      last_day,
    );
    //            next({type: FILTER_RECORDS_RANGE_CHANGED, payload: {first_day, last_day}});

    // Without preparing data for graphs
    // let filter = store.getState().filter.current;
    // let dateRanged = applyDateRange(records, filter);
    // next({type: FILTER_RECORDS_DATERANGE_END, payload: dateRanged});
    // let filtered = filterRecords(dateRanged, filter);
    // filtered = sortRecords(filtered);
    // return next({type: FILTER_RECORDS_END, payload: filtered});

    let filter = store.getState().filter.current;
    //            if(action.applyDateRange) {
    let dateRanged = applyDateRange(records, filter);
    //                next({type: FILTER_RECORDS_DATERANGE_END, payload: dateRanged.length});
    //            }

    let filteredWithoutDateRange = filterRecords(records, filter);
    let userId =
      (store.getState().auth &&
        store.getState().auth.user &&
        store.getState().auth.user.user_id) ||
      0;
    let transformedRecords = recordsDataTransformer(
      filteredWithoutDateRange.sort((a, b) => b.recordTime - a.recordTime),
      store.getState().fields,
      userId,
    );
    //            next({type: FILTER_RECORDS_WITHOUT_DATERANGE, payload: filteredWithoutDateRange});
    let filtered = applyDateRange(filteredWithoutDateRange, filter);
    filtered = sortRecords(filtered);

    setTimeout(() => {
      //    InteractionManager.runAfterInteractions(() => {
      next(
        parseRecords(
          store.getState(),
          transformedRecords,
          filter,
          first_day,
          last_day,
          records,
        ),
      );
      next({
        type: FILTER_RECORDS_END,
        payload: {
          first_day,
          last_day,
          filtered,
          filteredWithoutDateRange: transformedRecords,
          dateRange: dateRanged.length,
        },
      });
    }, 10);

    // }, 5000);
    return next({ type: FILTER_RECORDS_FINISHED });
  }

  return next(action);
};

const parseRecords = (
  state,
  data,
  currentFilter,
  first_day,
  last_day,
  allRecords,
) => {
  const loggedIn = !!(state.auth && state.auth.user);
  const patient_id = loggedIn ? state.auth.user.user_id : null;

  const current = currentFilter || {
    date: null,
    date_end: null,
    date_mode: null,
  };
  let start_date = current.date;
  let end_date = current.date_end;
  const _now = Date.now();

  if (
    current.dateRange &&
    current.dateRange !== 'alltime' &&
    current.dateRange !== 'custom'
  ) {
    if (current.dateRange === '7days') {
      start_date = Moment().add(-7, 'day'); //.startOf('day').valueOf();
      end_date = _now;
    } else if (current.dateRange === '30days') {
      start_date = Moment().add(-30, 'day'); // .startOf('day').valueOf();
      end_date = _now;
    }
  }

  const date = {
    date: Moment(start_date).startOf('day').valueOf(),
    date_end: Moment(end_date).endOf('day').valueOf(),
    date_mode: current.dateRange,
  };

  const overlaysProp = state.records.overlays;
  const fields = state.fields;
  const overlays =
    overlaysProp && Object.values(overlaysProp).some((arr) => arr.length > 0)
      ? overlaysProp
      : getMostCommonFields(allRecords, fields);
  const overlaysTranslated = { ...overlays };
  Object.keys(overlaysTranslated).forEach((field) => {
    overlaysTranslated[field] = overlaysTranslated[field].map((value) => {
      let medication = fields.alleviating_factor?.find(
        (med) => med?.medication && med?.name === value,
      );
      value = formatMedicationField(medication) || value;
      return value;
    });
  });
  console.log('PARSER INVOKE');
  const parserResult = parserInvocation(
    data,
    patient_id,
    { ...date, first_day, last_day },
    overlaysTranslated,
  );
  return {
    type: FILTER_RECORDS_PARSER_FINISHED,
    payload: parserResult,
    date: date,
  };
};
export default filterRecordsMiddleware;

// const filterRecordsMiddleware = store => next => action => {
//     let syncActivityFinished = false;
//     let actionQueue = [];
//
//     function flushQueue() {
//         actionQueue.forEach(a => store.dispatch(a)); // flush queue
//         actionQueue = [];
//     }
//
//     function asyncDispatch(asyncAction) {
//         actionQueue = actionQueue.concat([asyncAction]);
//
//         if (syncActivityFinished) {
//             flushQueue();
//         }
//     }
//
//     const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });
//
//     next(action);
//
//
//     syncActivityFinished = true;
//     flushQueue();
// };
