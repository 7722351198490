import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import TouchableNative from './TouchableNative';
import Text from './Text';
import Icon from './Icon';
import PropTypes from 'prop-types';
import { designConstants } from '../styles';
import { withTheme } from '../styles/useThemeContext';

class SelectableListItem extends Component {
  render() {
    const {
      icon,
      selected,
      touchableCheckmark,
      onPress,
      onCheckmarkPress,
      text,
      secondaryText,
      selectable,
      touchable,
      withSecondaryText,
      additionalSecondaryContent,
      style,
    } = this.props;
    const { colors } = this.props.theme;
    const Wrapper = touchable ? TouchableNative : View;

    return (
      <Wrapper
        pointerEvents={'auto'}
        style={[
          selectableListItemStyles.wrapper,
          {
            backgroundColor: colors.defaultBackground,
            borderColor: colors.buttonBlue,
          },
          selected && { backgroundColor: colors.buttonBlue },
          !selected &&
            (touchableCheckmark || selectable) && {
              borderColor: colors.liteGray,
            },
          style,
        ]}
        onPress={onPress}
      >
        {icon && (
          <View style={selectableListItemStyles.iconWrapper}>
            {React.cloneElement(icon, {
              ...icon.props,
              color: selected ? colors.white : colors.buttonBlue,
            })}
          </View>
        )}

        <View style={selectableListItemStyles.column}>
          <View style={selectableListItemStyles.textWrapper}>
            <Text white={selected}>{text}</Text>
          </View>
          {(withSecondaryText ||
            secondaryText ||
            additionalSecondaryContent) && (
            <View style={selectableListItemStyles.textWrapper}>
              <Text
                gray={!selected}
                white={selected}
                small
                style={selectableListItemStyles.secondaryText}
              >
                {secondaryText}
              </Text>
              {additionalSecondaryContent ? additionalSecondaryContent : null}
            </View>
          )}
        </View>

        {!touchableCheckmark && selectable && (
          <View style={selectableListItemStyles.checkmarkWrapper}>
            {selected && (
              <View
                style={[
                  selectableListItemStyles.checkmarkCircle,
                  {
                    backgroundColor: colors.defaultBackground,
                  },
                ]}
              >
                <Icon
                  name={'checkmark'}
                  type={'Ionicons'}
                  color={colors.buttonBlue}
                  size={designConstants.icons.size}
                />
              </View>
            )}
          </View>
        )}

        {touchableCheckmark && (
          <View style={selectableListItemStyles.checkmarkWrapper}>
            <TouchableNative
              style={[
                selectableListItemStyles.checkmarkCircle,
                !selected && {
                  borderWidth: 1,
                  borderColor: colors.liteGray,
                },
              ]}
              onPress={onCheckmarkPress}
            >
              {selected && (
                <Icon
                  name={'checkmark'}
                  type={'Ionicons'}
                  color={colors.buttonBlue}
                  size={designConstants.icons.size}
                />
              )}
            </TouchableNative>
          </View>
        )}
      </Wrapper>
    );
  }
}

const selectableListItemStyles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: designConstants.paddings.textFieldHorizontalPadding,
    paddingVertical: designConstants.paddings.textFieldVerticalPadding,
    minHeight: designConstants.heights.listItemMinHeightMedium,
    marginBottom: designConstants.paddings.paddingBetweenElementsMedium,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },
  column: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  secondaryText: {
    marginTop: designConstants.paddings.paddingBetweenElementsSmall,
  },
  iconWrapper: {
    marginRight: designConstants.paddings.textFieldHorizontalPadding,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkmarkWrapper: {
    width: 28,
    marginLeft: designConstants.paddings.textFieldHorizontalPadding,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkmarkCircle: {
    borderRadius: 30,
    height: 28,
    width: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

SelectableListItem.propTypes = {
  icon: PropTypes.node,
  selectable: PropTypes.bool,
  touchableCheckmark: PropTypes.bool,
  selected: PropTypes.bool,
  touchable: PropTypes.bool,
  onPress: PropTypes.func,
  onCheckmarkPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // Needed if some of items in list could have not secondary text, but should look unified
  withSecondaryText: PropTypes.bool,
  additionalSecondaryContent: PropTypes.node,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // style: ViewPropTypes.style
};
SelectableListItem.defaultProps = {
  icon: null,
  selectable: true,
  touchableCheckmark: false,
  selected: false,
  touchable: true,
  onPress: () => null,
  onCheckmarkPress: () => null,
  text: null,
  withSecondaryText: false,
  additionalSecondaryContent: null,
  secondaryText: null,
  style: {},
};

export default withTheme(SelectableListItem);
