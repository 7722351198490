import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [
  'edit_list_delete_confirmation',
  'edit_list_item_confirmation',
];

const STRINGS = new LocalizedStrings({
  en: {
    add_field_dialog_toast_ok_button: shared('OK', 'en'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'en'),
    edit_list_medications_screen_header: shared('Medications', 'en'),

    edit_list_sorting_screen_header: 'Sort List',
    edit_list_items_header:
      'Click on an item to prevent it from being shown on your list',
    edit_list_sorting_header:
      'Drag handle on the right-hand side to re-order items',
    edit_list_sort_button: 'Sort List',
    edit_list_add_new_header: "Can't find what you're looking for? {0}",
    edit_list_add_new_button: shared('Add New', 'en'),
    edit_list_sort_abc_button: 'Sort Alphabetically',
    edit_list_delete_confirmation_one:
      'There is {0} record that will lose its association with the {1}, {2}.  Would you like to continue?',
    edit_list_delete_confirmation_other:
      'There are {0} records that will lose their associations with the {1}, {2}.  Would you like to continue?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'en'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'en'),
    edit_list_delete_successful_toast_message:
      'Item has been successfully deleted',
    edit_list_delete_successful_ok_button_text: shared('OK', 'en'),
    edit_list_item_confirmation_one:
      'There is {0} record associated with {1}, and it will now be associated with {2} instead.  Would you like to continue?',
    edit_list_item_confirmation_other:
      'There are {0} records associated with {1}, and they will now be associated with {2} instead.  Would you like to continue?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'en'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'en'),
    edit_list_apply_button: shared('Apply changes', 'en'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'en',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'en'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'en'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'en'),
    edit_list_header_title:
      'Enable a value to show it on your list or disable to hide it',

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'en'),
    edit_list_item_delete_action: shared('Delete', 'en'),
    edit_list_item_show_action: 'Show',
    edit_list_item_hide_action: 'Hide',
    edit_list_item_deleting_text: 'Deleting {0}',
    edit_list_item_adding_text: 'Adding {0}',
    edit_list_item_editing_text: 'Editing {0}',
    //Add/edit field screen
    add_field_screen_header: 'Add {0}',
    add_field_edit_screen_header: 'Edit {0}',
    add_field_header: 'Add any custom item to your list.',
    add_field_edit_header: 'Edit the item below',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'en'),
    add_field_done_button: shared('Add New', 'en'),
    add_field_duplicate_error: 'This item already exists in your list',
    add_field_successfully_added: '{0} added succesfully',
    add_field_successfully_edited: '{0} edited succesfully',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'en'),
    constants_location_section_header_singular: shared('Location', 'en'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'en',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'en',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'en',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'en',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'en'),
    constants_character_section_header_singular: shared('Characteristic', 'en'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'en',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'en',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'en',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'interventions_question',
      'en',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'en',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'en',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'en',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'en',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'en',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'en',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'en'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'en',
    ),
    constants_environment_section_header_plural: shared('Environments', 'en'),
    constants_environment_section_header_singular: shared('Environment', 'en'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'en',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'en',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'en',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'en',
    ),
    constants_medication_section_header_singular: shared('Medication', 'en'),
    constants_medication_section_header_plural: shared('Medications', 'en'),

    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'en'),
    constants_timing_section_header_secondary: shared('timing_question', 'en'),
    constants_notes_section_header: shared('Notes', 'en'),
    constants_notes_section_header_secondary: 'Record any other thoughts',
    constants_severity_section_header: 'Severity',
    constants_severity_section_header_secondary:
      'What is the intensity of your pain?',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'en',
    ),
    constants_daily_reflection_section_header_secondary:
      'What did you do that mattered to you?',
  },
  de: {
    add_field_dialog_toast_ok_button: shared('OK', 'de'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'de'),
    edit_list_medications_screen_header: shared('Medications', 'de'),

    edit_list_sorting_screen_header: 'Liste sortieren',
    edit_list_items_header:
      'Klicken Sie auf einen Wert, damit er nicht auf Ihrer Liste erscheint',
    edit_list_sorting_header:
      'Ziehen Sie die Schaltfläche auf der rechten Seite, um Werte neu anzuordnen',
    edit_list_sort_button: 'Liste sortieren',
    edit_list_add_new_header: 'Sie finden nicht, wonach Sie suchen? {0}',
    edit_list_add_new_button: shared('Add New', 'de'),
    edit_list_sort_abc_button: 'Alphabetisch sortieren',
    edit_list_delete_confirmation_one:
      '{0} Eintrag wird seine Verknüpfung mit „{1} {2}“ verlieren. Möchten Sie forfahren?',
    edit_list_delete_confirmation_other:
      '{0} Einträge werden ihre Verknüpfung mit „{1} {2}“ verlieren. Möchten Sie forfahren?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'de'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'de'),
    edit_list_delete_successful_toast_message:
      'Wert wurde erfolgreich gelöscht',
    edit_list_delete_successful_ok_button_text: shared('OK', 'de'),
    edit_list_item_confirmation_one:
      '{0} Eintrag ist zur Zeit mit {1} verknüpft und wird fortan stattdessen mit {2} verknüpft sein. Möchten Sie fortfahren?',
    edit_list_item_confirmation_other:
      '{0} Einträge sind  zur Zeit mit {1} verknüpft und werden  fortan stattdessen mit {2} verknüpft sein. Möchten Sie fortfahren?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'de'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'de'),
    edit_list_apply_button: shared('Apply changes', 'de'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'de',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'de'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'de'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'de'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'de'),
    edit_list_item_delete_action: shared('Delete', 'de'),
    edit_list_item_show_action: 'Anzeigen',
    edit_list_item_hide_action: 'Ausblenden',
    edit_list_item_deleting_text: 'Lösche {0}',
    edit_list_item_adding_text: 'Hinzufüge {0}',
    edit_list_item_editing_text: 'Bearbeitung {0}',

    //Add/edit field screen
    add_field_screen_header: '{0} hinzufügen',
    add_field_edit_screen_header: '{0} bearbeiten',
    add_field_header: 'Fügen Sie Ihrer Liste einen individuellen Wert hinzu.',
    add_field_edit_header: 'Untenstehenden Wert bearbeiten',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'de'),
    add_field_done_button: shared('Add New', 'de'),
    add_field_duplicate_error: 'Dieser Wert existiert bereits in Ihrer Liste',
    add_field_successfully_added: '{0} erfolgreich hinzugefügt',
    add_field_successfully_edited: '{0} erfolgreich bearbeitet',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'de'),
    constants_location_section_header_singular: shared('Location', 'de'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'de',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'de',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'de',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'de',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'de'),
    constants_character_section_header_singular: shared('Characteristic', 'de'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'de',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'de',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'de',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'de',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'de',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'de',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'de',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'de',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'de',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'de',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'de'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'de',
    ),
    constants_environment_section_header_plural: shared('Environments', 'de'),
    constants_environment_section_header_singular: shared('Environment', 'de'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'de',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'de',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'de',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'de',
    ),
    constants_medication_section_header_singular: shared('Medication', 'de'),
    constants_medication_section_header_plural: shared('Medications', 'de'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'de'),
    constants_timing_section_header_secondary: shared('timing_question', 'de'),
    constants_notes_section_header: shared('Notes', 'de'),
    constants_notes_section_header_secondary: 'Notieren Sie weitere Gedanken',
    constants_severity_section_header: 'Stärke',
    constants_severity_section_header_secondary:
      'Wie intensiv sind Ihre Schmerzen?',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'de',
    ),
    constants_daily_reflection_section_header_secondary:
      'Was haben Sie heute getan, das für Sie bedeutend war?',
  },

  es: {
    add_field_dialog_toast_ok_button: shared('OK', 'es'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'es'),
    edit_list_medications_screen_header: shared('Medications', 'es'),
    edit_list_sorting_screen_header: 'Ordenar lista',
    edit_list_items_header:
      'Haga clic en un elemento para evitar que aparezca en su lista',
    edit_list_sorting_header:
      'Deslice el controlador de la derecha para reordenar elementos',
    edit_list_sort_button: 'Ordenar lista',
    edit_list_add_new_header: '¿No encuentra lo que busca? {0}',
    edit_list_add_new_button: shared('Add New', 'es'),
    edit_list_sort_abc_button: 'Ordenar alfabéticamente',
    edit_list_delete_confirmation_one:
      'Hay {0} registro que perderá su asociación con el {1}, {2}. ¿Desea continuar?',
    edit_list_delete_confirmation_other:
      'Hay {0} registros que perderán su asociación con el {1}, {2}. ¿Desea continuar?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'es'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'es'),
    edit_list_delete_successful_toast_message:
      'Elemento eliminado satisfactoriamente',
    edit_list_delete_successful_ok_button_text: shared('OK', 'es'),
    edit_list_item_confirmation_one:
      'Hay {0} registro asociado con {1} y ahora estará asociado con {2}. ¿Desea continuar?',
    edit_list_item_confirmation_other:
      'Hay {0} registros asociados con {1} y ahora estarán asociados con {2}. ¿Desea continuar?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'es'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'es'),
    edit_list_apply_button: shared('Apply changes', 'es'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'es',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'es'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'es'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'es'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'es'),
    edit_list_item_delete_action: shared('Delete', 'es'),
    edit_list_item_show_action: 'Mostrar',
    edit_list_item_hide_action: 'Ocultar',
    edit_list_item_deleting_text: 'Eliminando {0}',
    edit_list_item_adding_text: 'Añadiendo {0}',
    edit_list_item_editing_text: 'Editando {0}',

    //Add/edit field screen
    add_field_screen_header: 'Agregar {0}',
    add_field_edit_screen_header: 'Editar {0}',
    add_field_header: 'Agregar cualquier elemento personalizado a su lista.',
    add_field_edit_header: 'Editar el elemento de abajo',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'es'),
    add_field_done_button: shared('Add New', 'es'),
    add_field_duplicate_error: 'Este elemento ya está en su lista',
    add_field_successfully_added: '{0} agregado satisfactoriamente',
    add_field_successfully_edited: '{0} editado satisfactoriamente',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'es'),
    constants_location_section_header_singular: shared('Location', 'es'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'es',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'es',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'es',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'es',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'es'),
    constants_character_section_header_singular: shared('Characteristic', 'es'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'es',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'es',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'es',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'es',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'es',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'es',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'es',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'es',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'es',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'es',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'es'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'es',
    ),
    constants_environment_section_header_plural: shared('Environments', 'es'),
    constants_environment_section_header_singular: shared('Environment', 'es'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'es',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'es',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'es',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'es',
    ),
    constants_medication_section_header_singular: shared('Medication', 'es'),
    constants_medication_section_header_plural: shared('Medications', 'es'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'es'),
    constants_timing_section_header_secondary: shared('timing_question', 'es'),
    constants_notes_section_header: shared('Notes', 'es'),
    constants_notes_section_header_secondary:
      'Registre cualquier otra observación',
    constants_severity_section_header: 'Gravedad',
    constants_severity_section_header_secondary:
      '¿Cuál es la intensidad de su dolor?',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'es',
    ),
    constants_daily_reflection_section_header_secondary:
      '¿Qué cosa hizo que fue significativa para usted?',
  },

  fr: {
    add_field_dialog_toast_ok_button: shared('OK', 'fr'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'fr'),
    edit_list_medications_screen_header: shared('Medications', 'fr'),

    edit_list_sorting_screen_header: 'Trier la liste',
    edit_list_items_header:
      "Cliquez sur un élément pour éviter qu'il ne soit montré dans votre liste",
    edit_list_sorting_header:
      "Faites glisser l'icône de droite pour réorganiser des éléments",
    edit_list_sort_button: 'Trier la liste',
    edit_list_add_new_header: 'Vous ne trouvez pas ce que vous cherchiez ? {0}',
    edit_list_add_new_button: shared('Add New', 'fr'),
    edit_list_sort_abc_button: 'Trier alphabétiquement',
    edit_list_delete_confirmation_one:
      'Il y a {0} enregistrement qui va perdre son association avec le {1}, {2}. Voulez-vous continuer ?',
    edit_list_delete_confirmation_other:
      'Il y a {0} enregistrements qui vont perdre leur association avec le {1}, {2}. Voulez-vous continuer ?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'fr'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'fr'),
    edit_list_delete_successful_toast_message:
      "L'élément a été supprimé avec succès",
    edit_list_delete_successful_ok_button_text: shared('OK', 'fr'),
    edit_list_item_confirmation_one:
      'Il y a {0} enregistrement associé avec {1} et maintenant il sera associé avec {2}. Voulez-vous continuer ?',
    edit_list_item_confirmation_other:
      'Il y a {0} enregistrements associés avec {1} et maintenant ils seront associés avec {2}. Voulez-vous continuer ?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'fr'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'fr'),
    edit_list_apply_button: shared('Apply changes', 'fr'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'fr'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'fr'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'fr'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'fr'),
    edit_list_item_delete_action: shared('Delete', 'fr'),
    edit_list_item_show_action: 'Afficher',
    edit_list_item_hide_action: 'Masquer',
    edit_list_item_deleting_text: 'Suppression {0}',
    edit_list_item_adding_text: 'Ajout {0}',
    edit_list_item_editing_text: 'Modification {0}',

    //Add/edit field screen
    add_field_screen_header: 'Ajouter : {0}',
    add_field_edit_screen_header: 'Modifier : {0}',
    add_field_header: "Ajouter n'importe quel élément personnalisé",
    add_field_edit_header: "Modifier l'élément ci-dessous",
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'fr'),
    add_field_done_button: shared('Add New', 'fr'),
    add_field_duplicate_error: 'Cette élément est déjà dans votre liste',
    add_field_successfully_added: '{0} ajouté(e) avec succès',
    add_field_successfully_edited: '{0} modifié(e) avec succès',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'fr'),
    constants_location_section_header_singular: shared('Location', 'fr'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'fr',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'fr',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'fr',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'fr',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'fr'),
    constants_character_section_header_singular: shared('Characteristic', 'fr'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'fr',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'fr',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'fr',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'fr',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'fr',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'fr',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'fr',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'fr',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'fr',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'fr',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'fr'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'fr',
    ),
    constants_environment_section_header_plural: shared('Environments', 'fr'),
    constants_environment_section_header_singular: shared('Environment', 'fr'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'fr',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'fr',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'fr',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'fr',
    ),
    constants_medication_section_header_singular: shared('Medication', 'fr'),
    constants_medication_section_header_plural: shared('Medications', 'fr'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'fr'),
    constants_timing_section_header_secondary: shared('timing_question', 'fr'),
    constants_notes_section_header: shared('Notes', 'fr'),
    constants_notes_section_header_secondary:
      "Enregistrez d'autres observations",
    constants_severity_section_header: 'Gravité',
    constants_severity_section_header_secondary:
      "Quelle est l'intensité de votre douleur?",
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'fr',
    ),
    constants_daily_reflection_section_header_secondary:
      "Qu'est-ce que vous avez fait qui a été significatif?",
  },

  ko: {
    add_field_dialog_toast_ok_button: shared('OK', 'ko'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'ko'),
    edit_list_medications_screen_header: shared('Medications', 'ko'),

    edit_list_sorting_screen_header: '목록 정렬',
    edit_list_items_header: '목록에 표시되지 않도록 하려면 항목을 클릭하십시오',
    edit_list_sorting_header: '오른쪽 손잡이를 끌어 항목을 다시 정렬하십시오',
    edit_list_sort_button: '목록 정렬',
    edit_list_add_new_header: '뭘 찾는지 모르겠나요? {0}',
    edit_list_add_new_button: shared('Add New', 'ko'),
    edit_list_sort_abc_button: '알파벳순으로 정렬',
    edit_list_delete_confirmation_one:
      '{1}, {2}과(와) 연관성이 손실되는 {0}개의 기록이 있습니다. 계속하시겠습니까?',
    edit_list_delete_confirmation_other:
      '{1}, {2}과(와) 연관 없는 {0}개의 기록이 있습니다. 계속하시겠습니까?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'ko'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'ko'),
    edit_list_delete_successful_toast_message:
      '항목이 성공적으로 삭제되었습니다',
    edit_list_delete_successful_ok_button_text: shared('OK', 'ko'),
    edit_list_item_confirmation_one:
      '{1}과(와) 연결된 {0}개의 레코드가 있으며 대신 {2}과(와) 연결됩니다. 계속하시겠습니까?',
    edit_list_item_confirmation_other:
      '{1}과(와) 연결된 {0}개의 레코드가 있으며 대신 {2}과(와) 연결됩니다. 계속하시겠습니까?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'ko'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'ko'),
    edit_list_apply_button: shared('Apply changes', 'ko'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'ko'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'ko'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'ko'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'ko'),
    edit_list_item_delete_action: shared('Delete', 'ko'),
    edit_list_item_show_action: '보기',
    edit_list_item_hide_action: '숨기기',
    edit_list_item_deleting_text: '{0} 삭제 중',
    edit_list_item_adding_text: '{0} 첨가',
    edit_list_item_editing_text: '{0} 편집',

    //Add/edit field screen
    add_field_screen_header: '{0} 추가',
    add_field_edit_screen_header: '{0} 편집',
    add_field_header: '사용자 지정 항목을 목록에 추가합니다.',
    add_field_edit_header: '아래 항목을 편집합니다',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'ko'),
    add_field_done_button: shared('Add New', 'ko'),
    add_field_duplicate_error: '이 항목은 목록에 이미 있습니다',
    add_field_successfully_added: '{0} 성공적으로 추가됨',
    add_field_successfully_edited: '{0} 성공적으로 편집됨',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'ko'),
    constants_location_section_header_singular: shared('Location', 'ko'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'ko',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ko',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'ko',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ko',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'ko'),
    constants_character_section_header_singular: shared('Characteristic', 'ko'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'ko',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'ko',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'ko',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'ko',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ko',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'ko',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ko',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ko',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'ko',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'ko',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'ko'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'ko',
    ),
    constants_environment_section_header_plural: shared('Environments', 'ko'),
    constants_environment_section_header_singular: shared('Environment', 'ko'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'ko',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'ko',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'ko',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'ko',
    ),
    constants_medication_section_header_singular: shared('Medication', 'ko'),
    constants_medication_section_header_plural: shared('Medications', 'ko'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'ko'),
    constants_timing_section_header_secondary: shared('timing_question', 'ko'),
    constants_notes_section_header: shared('Notes', 'ko'),
    constants_notes_section_header_secondary: '기타 견해를 기록',
    constants_severity_section_header: '심각도',
    constants_severity_section_header_secondary:
      '통증의 강도는 어느 정도입니까?',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'ko',
    ),
    constants_daily_reflection_section_header_secondary:
      '자신의 문제 사항을 위해 무엇을 하셨나요?',
  },

  ru: {
    add_field_dialog_toast_ok_button: shared('OK', 'ru'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'ru'),
    edit_list_medications_screen_header: shared('Medications', 'ru'),
    edit_list_sorting_screen_header: 'Сортировка списка',
    edit_list_items_header:
      'Нажмите на элемент, если хотите убрать его из списка',
    edit_list_sorting_header:
      'Потяните маркер справа для изменения порядка элементов',
    edit_list_sort_button: 'Сортировка списка',
    edit_list_add_new_header: 'Не можете что-то найти? {0}',
    edit_list_add_new_button: shared('Add New', 'ru'),
    edit_list_sort_abc_button: 'Сортировка по алфавиту',
    edit_list_delete_confirmation_one:
      '{0} запись утратит связь с разделами {1}, {2}.  Хотите продолжить?',
    edit_list_delete_confirmation_other:
      '{0} записей утратят связь с разделами {1}, {2}.  Хотите продолжить?',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'ru'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'ru'),
    edit_list_delete_successful_toast_message: 'Элемент успешно удален',
    edit_list_delete_successful_ok_button_text: shared('OK', 'ru'),
    edit_list_item_confirmation_one:
      '{0} запись связана с {1} и теперь она будет привязана к {2}.  Хотите продолжить?',
    edit_list_item_confirmation_other:
      '{0} записей связано с {1} и теперь они будет привязаны к {2}.  Хотите продолжить?',
    edit_list_item_confirmation_no_button: shared('Cancel', 'ru'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'ru'),
    edit_list_apply_button: shared('Apply changes', 'ru'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'ru'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'ru'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'ru'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'ru'),
    edit_list_item_delete_action: shared('Delete', 'ru'),
    edit_list_item_show_action: 'Показать',
    edit_list_item_hide_action: 'Скрыть',
    edit_list_item_deleting_text: 'Удаляется: {0}',
    edit_list_item_adding_text: 'Добавляется: {0}',
    edit_list_item_editing_text: 'Изменяется: {0}',

    //Add/edit field screen
    add_field_screen_header: 'Добавить {0}',
    add_field_edit_screen_header: 'Изменить {0}',
    add_field_header: 'Добавьте любой элемент в свой список.',
    add_field_edit_header: 'Редактирование элемента ниже',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'ru'),
    add_field_done_button: shared('Add New', 'ru'),
    add_field_duplicate_error: 'Такой элемент уже есть в вашем списке',
    add_field_successfully_added: 'Элемент успешно добавлен',
    add_field_successfully_edited: 'Элемент успешно добавлен',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'ru'),
    constants_location_section_header_singular: shared('Location', 'ru'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'ru',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ru',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'ru',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ru',
    ),
    constants_character_section_header_plural: shared('Characteristics', 'ru'),
    constants_character_section_header_singular: shared('Characteristic', 'ru'),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'ru',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'ru',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'ru',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'ru',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ru',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'ru',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ru',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ru',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'ru',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'ru',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'ru'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'ru',
    ),
    constants_environment_section_header_plural: shared('Environments', 'ru'),
    constants_environment_section_header_singular: shared('Environment', 'ru'),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'ru',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'ru',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'ru',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'ru',
    ),
    constants_medication_section_header_singular: shared('Medication', 'ru'),
    constants_medication_section_header_plural: shared('Medications', 'ru'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'ru'),
    constants_timing_section_header_secondary: shared('timing_question', 'ru'),
    constants_notes_section_header: shared('Notes', 'ru'),
    constants_notes_section_header_secondary: 'Запишите все ваши наблюдения',
    constants_severity_section_header: 'Сила',
    constants_severity_section_header_secondary:
      'Какова интенсивность вашей боли?',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'ru',
    ),
    constants_daily_reflection_section_header_secondary:
      'Какие важные дела вы сделали?',
  },

  'zh-rCN': {
    add_field_dialog_toast_ok_button: shared('OK', 'zh-rCN'),

    //Edit fields list
    // scenes/EditListScene.js
    edit_list_screen_header: shared('Edit List', 'zh-rCN'),
    edit_list_medications_screen_header: shared('Medications', 'zh-rCN'),

    edit_list_sorting_screen_header: '排序列表',
    edit_list_items_header: '单击某个项目可以让其不显示在列表中',
    edit_list_sorting_header: '拖动右侧的手柄以重新排序项目',
    edit_list_sort_button: '排序列表',
    edit_list_add_new_header: '找不到您要找的内容？{0}',
    edit_list_add_new_button: shared('Add New', 'zh-rCN'),
    edit_list_sort_abc_button: '按字母排序',
    edit_list_delete_confirmation_one:
      '有{0}条记录将失去与{1}，{2}的关联。您要继续吗？',
    edit_list_delete_confirmation_other:
      '有{0}条记录将失去与{1}，{2}的关联。您要继续吗？',
    edit_list_delete_confirmation_yes_button_text: shared('Delete', 'zh-rCN'),
    edit_list_delete_confirmation_no_button_text: shared('Cancel', 'zh-rCN'),
    edit_list_delete_successful_toast_message: '项目已成功删除',
    edit_list_delete_successful_ok_button_text: shared('OK', 'zh-rCN'),
    edit_list_item_confirmation_one:
      '有{0}条记录与{1}相关联，现在它将与{2}相关联。您要继续吗？',
    edit_list_item_confirmation_other:
      '有{0}条记录与{1}相关联，现在它们将与{2}相关联。您要继续吗？',
    edit_list_item_confirmation_no_button: shared('Cancel', 'zh-rCN'),
    edit_list_item_confirmation_yes_button: shared('Continue', 'zh-rCN'),
    edit_list_apply_button: shared('Apply changes', 'zh-rCN'),
    edit_list_back_without_save_confirmation: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    edit_list_back_without_save_cancel_button_text: shared('Cancel', 'zh-rCN'),
    edit_list_back_without_save_yes_button_text: shared('Save', 'zh-rCN'),
    edit_list_back_without_save_no_button_text: shared('Discard', 'zh-rCN'),

    // components/EditListItem.js
    edit_list_item_edit_action: shared('Edit', 'zh-rCN'),
    edit_list_item_delete_action: shared('Delete', 'zh-rCN'),
    edit_list_item_show_action: '显示',
    edit_list_item_hide_action: '隐藏',
    edit_list_item_deleting_text: '删除{0}',
    edit_list_item_adding_text: '添加{0}',
    edit_list_item_editing_text: '编辑{0}',

    //Add/edit field screen
    add_field_screen_header: '添加{0}',
    add_field_edit_screen_header: '编辑{0}',
    add_field_header: '在您的列表中添加任何自定义项目。',
    add_field_edit_header: '编辑下面的项目',
    add_field_edit_field_label: '{0}',
    add_field_field_label: '{0}',
    add_field_edit_field_helper_text: null,
    add_field_field_helper_text: null,
    add_field_edit_done_button: shared('Apply changes', 'zh-rCN'),
    add_field_done_button: shared('Add New', 'zh-rCN'),
    add_field_duplicate_error: '您的列表中已经存在此项目',
    add_field_successfully_added: '{0}成功添加',
    add_field_successfully_edited: '{0}成功编辑',

    // ./constants.js
    // Used dynamically in ./constants.js
    constants_location_section_header_plural: shared('Locations', 'zh-rCN'),
    constants_location_section_header_singular: shared('Location', 'zh-rCN'),
    constants_location_section_header_secondary: shared(
      'locations_question',
      'zh-rCN',
    ),
    constants_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    constants_symptoms_section_header_singular: shared(
      'Other Associated Symptom',
      'zh-rCN',
    ),
    constants_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'zh-rCN',
    ),
    constants_character_section_header_plural: shared(
      'Characteristics',
      'zh-rCN',
    ),
    constants_character_section_header_singular: shared(
      'Characteristic',
      'zh-rCN',
    ),
    constants_character_section_header_secondary: shared(
      'characteristics_question',
      'zh-rCN',
    ),
    constants_alleviating_factors_section_header_singular: shared(
      'Intervention',
      'zh-rCN',
    ),
    constants_alleviating_factors_section_header_plural: shared(
      'Interventions',
      'zh-rCN',
    ),
    constants_alleviating_factors_section_header_secondary: shared(
      'alleviating_factors_question',
      'zh-rCN',
    ),
    constants_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    constants_aggravating_factors_section_header_singular: shared(
      'Aggravating Factor',
      'zh-rCN',
    ),
    constants_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'zh-rCN',
    ),
    constants_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    constants_ineffective_factors_section_header_singular: shared(
      'Ineffective Factor',
      'zh-rCN',
    ),
    constants_ineffective_factors_section_header_secondary: shared(
      'ineffective_factors_question',
      'zh-rCN',
    ),
    constants_factors_section_header_plural: shared('Interventions', 'zh-rCN'),
    constants_factors_section_header_secondary: shared(
      'interventions_question',
      'zh-rCN',
    ),
    constants_environment_section_header_plural: shared(
      'Environments',
      'zh-rCN',
    ),
    constants_environment_section_header_singular: shared(
      'Environment',
      'zh-rCN',
    ),
    constants_environment_section_header_secondary: shared(
      'environment_question',
      'zh-rCN',
    ),
    constants_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    constants_meaningful_activities_section_header_singular: shared(
      'Meaningful Activity',
      'zh-rCN',
    ),
    constants_meaningful_activities_section_header_secondary: shared(
      'meaningful_activities_question',
      'zh-rCN',
    ),
    constants_medication_section_header_singular: shared(
      'Medication',
      'zh-rCN',
    ),
    constants_medication_section_header_plural: shared('Medications', 'zh-rCN'),
    // This strings (8) are not fields, but widely used sections headers
    constants_timing_section_header: shared('Timing', 'zh-rCN'),
    constants_timing_section_header_secondary: shared(
      'timing_question',
      'zh-rCN',
    ),
    constants_notes_section_header: shared('Notes', 'zh-rCN'),
    constants_notes_section_header_secondary: '请记录您的任何其它想法',
    constants_severity_section_header: '严重程度',
    constants_severity_section_header_secondary: '您的疼痛强烈程度如何？',
    constants_daily_reflection_section_header: shared(
      'Daily Reflection Score',
      'zh-rCN',
    ),
    constants_daily_reflection_section_header_secondary:
      '您今天做了哪些有意义的事情？',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
