import getConfig from './getConfig';
import { Linking, Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const APP_STORE_LINK =
  'itms-apps://itunes.apple.com/app/id' + getConfig('MMP_APPLE_APP_ID');
const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=' + DeviceInfo.getBundleId();
const updateAppFromStore = (options) => {
  if (Platform.OS === 'ios') {
    openURL(APP_STORE_LINK);
  } else if (Platform.OS === 'android') {
    openURL(GOOGLE_PLAY_LINK);
  }
};

const openURL = (url, callback = () => {}) => {
  console.log('OPEN', url);
  Linking.canOpenURL(url).then((supported) => {
    callback(supported);
    if (supported) {
      Linking.openURL(url);
    }
  });
};

export { updateAppFromStore };
