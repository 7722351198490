import defaultData from './defaultData';
import { medicationParser } from '../Meds/constants';
import authStrings from '../Auth/strings';

export let FID = -1;
export let NID = -1;
export let ID = 1;
const getFid = () => {
  return FID--;
};
const getNid = () => {
  return NID--;
};
const getId = () => {
  return ID++;
};

export const predefinedData = {
  conditions: [
    ...defaultData.painConditions.map((painCondition) => {
      return {
        ...painCondition,
        fid: painCondition.fid ? painCondition.fid : getFid(),
        isShow: 1,
        first_symptom: null,
        diagnosed: null,
        display_value: null,
        checked: false,
      };
    }),
  ],

  medications: [
    ...defaultData.medications.map((medication) => {
      const { dosages, strength_units_in_order, dosage_units_in_order } =
        medicationParser(medication);
      const added_strengths = [];

      dosages.forEach((dosage, index) => {
        const new_dosage = {
          id: index + 1,
          strength_units: dosage.strength_unit,
          strength: dosage.strengths_concatenated,
          selected: false, // index === 0,
          ids: { id: getId(), fid: getFid(), nid: getNid() },
        };
        added_strengths.push(new_dosage);
      });

      return {
        ...medication,
        id: getId(),
        fid: getFid(),
        nid: getNid(),
        brand_name: null,
        strength: null,
        purpose: [],

        strength_units: dosages[0].strength_unit,
        dosage_units: dosage_units_in_order[0].value,
        display_name: null,

        start_date: null,
        end_date: null,
        med_notes: null,
        special_instructions: null,
        dosage: [],
        display_value: null,
        checked: false,

        added_strengths,

        strength_units_in_order,
        dosage_units_in_order,
      };
    }),
  ],
};
export const getDefaultValues = (data, type) => {
  const currentLang = authStrings.getLanguage();
  return data.map((val) => {
    (type === 'painConditions') !== undefined &&
      (val.display_value = val.localized_values?.[currentLang]
        ? val.localized_values[currentLang]
        : val.localized_values?.en);

    if (type === 'medications') {
      val.is_default = true;
      val.brand_name = null;
      val.add_moment = 0;
    }

    val.flag_created = true;

    //        delete val.localized_values;
    return val;
  });
};
