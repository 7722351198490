import fields_names_strings from '../../modules/Fields/fields_names_strings';

export default (state) => {
  console.log('MIGRATE', state);
  const fields = { ...state };
  //We need to set "already_synced" flag in case if user is logged in
  //state.auth is not available during upgrade, because it's located in separate reducer. So have to use "profile"
  Object.keys(fields).forEach((category) => {
    if (!Array.isArray(fields[category])) {
      return;
    }
    if (
      category !== 'alleviating_factor' &&
      category !== 'aggravating_factor' &&
      category !== 'symptom'
    ) {
      return;
    }
    const maxId = fields[category].reduce((prev, current) => {
      if (!current) {
        return prev;
      }
      return Math.max(prev, current.id);
    }, 0);
    const maxFid = fields[category].reduce((prev, current) => {
      if (!current) {
        return prev;
      }
      return Math.max(prev, current.fid);
    }, 0);
    const isNoneExists = fields[category].find(
      (field) =>
        field?.name === 'Nothing' ||
        field?.name === fields_names_strings.Nothing,
    );
    if (!isNoneExists) {
      fields[category].push({
        name: 'Nothing',
        icon_code: 'none',
        systemDefault: true,
        show: true,
        position: -1,
        fid: maxFid + 1,
        id: maxId + 1,
      });
    } else {
      fields[category] = fields[category].map((field) => {
        if (field?.id === isNoneExists.id) {
          return {
            ...field,
            name: 'Nothing',
            icon_code: 'none',
            systemDefault: true,
            position: -1,
          };
        } else {
          return field;
        }
      });
    }
  });
  return fields;
};
