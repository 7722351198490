import * as types from './actiontypes';

export function performFilter() {
  return {
    type: types.FILTER_RECORDS_START,
    shouldFilter: true,
    applyDateRange: true,
  };
}

export function clearFilter() {
  return {
    type: types.FILTER_CLEAR,
    shouldFilter: true,
  };
}

export function loadFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: types.FILTER_LOAD,
      payload: filter,
    });
    return Promise.resolve();
  };
}

export function setReportFilter(filter) {
  return (dispatch) => {
    dispatch({
      type: types.FILTER_APPLY_REPORT,
      payload: filter,
    });
    return Promise.resolve();
  };
}

export function checkType(type) {
  return {
    type: types.FILTER_CHECK_TYPE,
    shouldFilter: true,
    payload: type,
  };
}

export function setSeverity({ start, end }) {
  return {
    type: types.FILTER_SET_SEVERITY,
    shouldFilter: true,
    payload: { start, end },
  };
}

export function setDateMode(mode) {
  return {
    type: types.FILTER_SET_DATE_MODE,
    payload: mode,
  };
}

export function setDate(date) {
  return {
    type: types.FILTER_SET_DATE,
    applyDateRange: true,
    shouldFilter: true,
    payload: date,
  };
}

export function setEndDate(date) {
  return {
    type: types.FILTER_SET_END_DATE,
    applyDateRange: true,
    shouldFilter: true,
    payload: date,
  };
}

export function setDateRange(dateRange, applyDateRange) {
  return {
    type: types.FILTER_SET_DATE_RANGE,
    applyDateRange: applyDateRange,
    shouldFilter: false,
    payload: dateRange,
  };
}

export function setDurationMode(mode) {
  return {
    type: types.FILTER_SET_DURATION_MODE,
    shouldFilter: true,
    payload: mode,
  };
}

export function setDurationUnits(units) {
  return {
    type: types.FILTER_SET_DURATION_UNITS,
    shouldFilter: true,
    payload: units,
  };
}

export function setDuration(duration) {
  return {
    type: types.FILTER_SET_DURATION,
    shouldFilter: true,
    payload: duration,
  };
}

export function setTimeMode(mode) {
  return {
    type: types.FILTER_SET_TIME_MODE,
    shouldFilter: true,
    payload: mode,
  };
}

export function setTime(time) {
  return {
    type: types.FILTER_SET_TIME,
    shouldFilter: true,
    payload: time,
  };
}

export function checkTypeOfPain(type_of_pain) {
  return {
    type: types.FILTER_CHECK_TYPE_OF_PAIN,
    shouldFilter: true,
    payload: type_of_pain,
  };
}

export function setScore({ start, end }) {
  return {
    type: types.FILTER_SET_SCORE,
    shouldFilter: true,
    payload: { start, end },
  };
}

export function setMode(mode) {
  return {
    type: types.FILTER_SET_MODE,
    shouldFilter: true,
    payload: mode,
  };
}

export function checkMedication(medicationType, nid) {
  return {
    type: types.FILTER_CHECK_MEDICATION,
    shouldFilter: true,
    payload: {
      medicationType,
      nid,
    },
  };
}
export function checkFactor(type, id) {
  return {
    type: types.FILTER_CHECK_FACTOR,
    shouldFilter: true,
    payload: {
      type,
      id,
    },
  };
}

export function checkField(fieldType, fieldId, field) {
  return {
    type: types.FILTER_CHECK_FIELD,
    shouldFilter: true,
    payload: {
      fieldType: fieldType,
      fieldId: fieldId,
      field: field,
    },
  };
}

export function updateDateAndDateEndValues(
  date,
  date_end,
  applyDateRange = true,
  shouldFilter = true,
) {
  return {
    type: types.FILTER_UPDATE_DATE_AND_DATE_END_VALUES,
    applyDateRange: applyDateRange,
    shouldFilter: shouldFilter,
    payload: { date, date_end },
  };
}
