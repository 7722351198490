// This file serves every action which could be performed by both Views and Logic
export const DEFAULT_FIELDS_GENERATED = 'DEFAULT_FIELDS_GENERATED';

//Field types
export const CHANGE_POSITIONS = 'CHANGE_POSITIONS';
export const CHANGE_SHOW = 'CHANGE_SHOW';
export const ADD_FIELD = 'ADD_FIELD';
export const EDIT_FIELD = 'EDIT_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';

export const ADD_OFFLINE_MEDICATION_TO_FIELDS =
  'ADD_OFFLINE_MEDICATION_TO_FIELDS';
export const EDIT_OFFLINE_MEDICATION_IN_FIELDS =
  'EDIT_OFFLINE_MEDICATION_IN_FIELDS';
export const DELETE_OFFLINE_MEDICATION_FROM_FIELDS =
  'DELETE_OFFLINE_MEDICATION_FROM_FIELDS';
export const ADD_OFFLINE_MEDICATIONS_ARRAY_TO_FIELDS =
  'ADD_OFFLINE_MEDICATIONS_ARRAY_TO_FIELDS';
export const ADD_NEW_DEFAULT_FIELD = 'ADD_NEW_DEFAULT_FIELD';

export const SYNC_CUSTOM_LISTS = 'SYNC_CUSTOM_LISTS';
export const SYNC_CUSTOM_LISTS_SUCCESS = 'SYNC_CUSTOM_LISTS_SUCCESS';
export const SYNC_CUSTOM_LISTS_UPGRADE = 'SYNC_CUSTOM_LISTS_UPGRADE';
export const SYNC_CUSTOM_LISTS_CLEAR_FLAGS = 'SYNC_CUSTOM_LISTS_CLEAR_FLAGS';
