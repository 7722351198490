export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const toSentenceCase = (string) => {
  const _string = String(string);
  return _string.length > 0
    ? _string.charAt(0).toUpperCase() + _string.slice(1).toLowerCase()
    : '';
};

export const toCapitalCase = (string, separator = ' ') => {
  let result = String(string)
    .split(separator)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(separator);
  if (separator === ' ' && result.includes('/')) {
    return toCapitalCase(result, '/');
  }
  return result;
};
