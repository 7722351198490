import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [];

const STRINGS = new LocalizedStrings({
  en: {
    // ./reducer.js
    sync_reducer_synchronizing_message: 'Synchronizing...',
    sync_reducer_processing_preferences: 'Processing updated preferences...',
    sync_reducer_successful_sync_message: shared('sync_success', 'en'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      'Cannot log out because sync failed.  Please try logging out again.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'en',
    ),
  },
  de: {
    // ./reducer.js
    sync_reducer_synchronizing_message: 'Synchronisiere…',
    sync_reducer_processing_preferences:
      'Verarbeite aktualisierte Einstellungen…',
    sync_reducer_successful_sync_message: shared('sync_success', 'de'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      'Abmelden aufgrund fehlgeschlagener Synchronisierung nicht möglich. Bitte versuchen Sie erneut, sich abzumelden.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'de',
    ),
  },
  es: {
    // ./reducer.js
    sync_reducer_synchronizing_message: 'Sincronizando…',
    sync_reducer_processing_preferences:
      'Procesando preferencias de actualización…',
    sync_reducer_successful_sync_message: shared('sync_success', 'es'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      'No puede cerrar sesión porque falló la sincronización. Intente cerrar sesión de nuevo.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'es',
    ),
  },

  fr: {
    // ./reducer.js
    sync_reducer_synchronizing_message: 'Synchronisation en cours…',
    sync_reducer_processing_preferences:
      'Traitement des préférences actualisées en cours...',
    sync_reducer_successful_sync_message: shared('sync_success', 'fr'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      'Impossible de se déconnecter car la synchronisation a échoué. Essayez de vous déconnecter à nouveau.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'fr',
    ),
  },

  ko: {
    // ./reducer.js
    sync_reducer_synchronizing_message: '동기화하는 중입니다...',
    sync_reducer_processing_preferences:
      '업데이트된 기본 설정을 처리하는 중입니다...',
    sync_reducer_successful_sync_message: shared('sync_success', 'ko'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      '동기화에 실패하여 로그아웃할 수 없습니다. 로그아웃을 다시 시도하십시오.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'ko',
    ),
  },

  ru: {
    // ./reducer.js
    sync_reducer_synchronizing_message: 'Синхронизация...',
    sync_reducer_processing_preferences: 'Обработка новых настроек...',
    sync_reducer_successful_sync_message: shared('sync_success', 'ru'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      'Выйти не удалось из-за ошибки синхронизации.  Пожалуйста, попробуйте выйти еще раз.',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'ru',
    ),
  },
  'zh-rCN': {
    // ./reducer.js
    sync_reducer_synchronizing_message: '正在同步...',
    sync_reducer_processing_preferences: '正在处理更新的偏好设置...',
    sync_reducer_successful_sync_message: shared('sync_success', 'zh-rCN'),

    // ./actions.js
    sync_actions_cant_logout_after_sync_failure_toast_text:
      '由于同步失败，无法登出。请尝试再次登出。',
    sync_actions_cant_logout_after_sync_failure_toast_ok_button: shared(
      'OK',
      'zh-rCN',
    ),
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
