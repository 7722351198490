import React, { Component } from 'react';
import { PixelRatio, StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import KeyboardNotifyingHOC from './KeyboardNotifyingHOC';
import { designConstants } from '../styles/index';
import { withTheme } from '../styles/useThemeContext';

class Footer extends Component {
  render() {
    const { children, style, keyboardIsShown, ignoreKeyboard } = this.props;
    const { colors } = this.props.theme;
    // cant make height === 0 if keyboard is opened, because all inner content will be still
    // visible, that is why ignoreKeyboard is needed
    return keyboardIsShown && !ignoreKeyboard ? null : (
      <View
        style={[
          styles.footer,
          { backgroundColor: colors.defaultBackground },
          style,
        ]}
      >
        {children}
      </View>
    );
  }
}

export default KeyboardNotifyingHOC(withTheme(Footer));

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  // style: ViewPropTypes.style,
  keyboardIsShown: PropTypes.bool,
  ignoreKeyboard: PropTypes.bool,
};

Footer.defaultProps = {
  children: null,
  keyboardIsShown: false,
  style: {},
  ignoreKeyboard: false,
};

const styles = StyleSheet.create({
  footer: {
    height: designConstants.footer.height,
    paddingBottom: designConstants.bottomSpacerForIPhoneX,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
    borderColor: '#cbcbcb',
    // elevation: 2,
  },
});
