import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text from './Text';
import TouchableNative from './TouchableNative';
import useThemeContext from '../styles/useThemeContext';

export default function SettingsRow(props) {
  const RootView = props.onPress ? TouchableNative : View;
  const { colors } = useThemeContext();

  return (
    <RootView
      onPress={props.onPress}
      style={[
        settingsRowStyles.rowRoot,
        { backgroundColor: colors.cardHeader },
      ]}
    >
      <View style={{ flex: 1 }}>
        <Text redDanger={props.redDanger}>{props.title}</Text>
      </View>
      {props.children}
    </RootView>
  );
}

const settingsRowStyles = StyleSheet.create({
  rowRoot: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 56,
    alignItems: 'center',
    marginTop: 10,
    paddingHorizontal: 20,
    borderRadius: 4,
  },
});
