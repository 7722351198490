import React, { Component } from 'react';
import {
  Platform,
  TouchableOpacity,
  TouchableNativeFeedback,
  View,
  StyleSheet,
  Keyboard,
} from 'react-native';
import { designConstants } from '../styles/index';
import PropTypes from 'prop-types';
import { withTheme } from '../styles/useThemeContext';

const activeOpacityDefaultIOS = 0.8;
const activeOpacityAndroidText = 0.8;

class TouchableNative extends Component {
  onPressMock = () => {};

  onPress = (event) => {
    const { onPress } = this.props;
    Keyboard.dismiss();
    typeof onPress === 'function' && onPress(event);
  };

  render() {
    const props = this.props;
    if (Platform.OS === 'ios' || designConstants.isWeb) {
      return (
        <TouchableOpacity
          activeOpacity={activeOpacityDefaultIOS}
          {...props}
          onPress={this.onPress}
        >
          {props.children}
        </TouchableOpacity>
      );
    }

    // if (
    //     props.children
    //     && !Array.isArray(props.children)
    //     && props.children.type.displayName === 'Text'
    // ) {
    //     const flattenParentStyle = StyleSheet.flatten(props.style);
    //     const flattenChildrenStyle = StyleSheet.flatten(props.children.props.style);
    //
    //     if (
    //         (!flattenParentStyle || (!flattenParentStyle.backgroundColor && !flattenParentStyle.borderWidth))
    //         &&
    //         (!flattenChildrenStyle || (!flattenChildrenStyle.backgroundColor && !flattenChildrenStyle.borderWidth))
    //     ) {
    //         return (
    //             <TouchableOpacity activeOpacity={activeOpacityAndroidText} {...this.props}>
    //                 {props.children}
    //             </TouchableOpacity>
    //         );
    //     }
    // }

    let { onPress, onLayout, innerStyle, ..._props } = this.props;
    const { colors } = this.props.theme;
    const flattenedStyle = StyleSheet.flatten(_props.style);

    let styleForInnerComponent = { alignSelf: 'stretch' };
    let styleForWrapper = {};
    let borderLess = false;

    if (flattenedStyle) {
      const { width, height } = flattenedStyle;

      const {
        padding,
        paddingVertical,
        paddingHorizontal,
        paddingLeft,
        paddingRight,
        paddingTop,

        paddingBottom,
        flexDirection,
        justifyContent,
        alignItems,
        flex,

        ..._styleForWrapper
      } = flattenedStyle;

      flexDirection && (styleForInnerComponent.flexDirection = flexDirection);
      justifyContent &&
        (styleForInnerComponent.justifyContent = justifyContent);
      alignItems && (styleForInnerComponent.alignItems = alignItems);
      flex && (styleForInnerComponent.flex = flex);
      padding && (styleForInnerComponent.padding = padding);
      paddingVertical &&
        (styleForInnerComponent.paddingVertical = paddingVertical);
      paddingHorizontal &&
        (styleForInnerComponent.paddingHorizontal = paddingHorizontal);
      paddingLeft && (styleForInnerComponent.paddingLeft = paddingLeft);
      paddingRight && (styleForInnerComponent.paddingRight = paddingRight);
      paddingTop && (styleForInnerComponent.paddingTop = paddingTop);
      paddingBottom && (styleForInnerComponent.paddingBottom = paddingBottom);
      width && (styleForInnerComponent.width = width);
      height && (styleForInnerComponent.height = height);
      width === height && width > 0 && (borderLess = true);
      styleForWrapper = {
        ..._styleForWrapper,
        flex,
        justifyContent,
        alignItems,
      };
    }

    // Ignore style prop
    const { style, accesible, accessibilityLabel, ...__props } = _props;

    // To solve bug with ripple effect
    if (Object.keys(styleForWrapper).length === 0) {
      styleForWrapper.borderRadius = 0;
    }

    let background;
    if (Platform.Version >= 21) {
      background = TouchableNativeFeedback.Ripple(
        colors.nativeTouchFeedbackAndroid,
        borderLess,
      );
    } else {
      background = TouchableNativeFeedback.SelectableBackground();
    }
    return (
      <View style={styleForWrapper} onLayout={onLayout}>
        <TouchableNativeFeedback
          onPress={this.onPress}
          background={background}
          style={{ overflow: 'hidden', borderRadius: 24 }}
          accesible={accesible}
          accessibilityLabel={accessibilityLabel}
          delayPressIn={0}
        >
          <View
            pointerEvents={'box-only'}
            {...__props}
            style={[styleForInnerComponent, innerStyle]}
          >
            {__props.children}
          </View>
        </TouchableNativeFeedback>
      </View>
    );
  }
}
TouchableNative.propTypes = {
  ...TouchableOpacity.propTypes,
  ...TouchableNativeFeedback.propTypes,
  children: PropTypes.node,
};

export default withTheme(TouchableNative);
