import AddSignupCard from './migrations/002AddSignupCard';
import AddQuestionnairesCard from './migrations/003AddQuestionnairesCard';
import AddPainGuideFeedSetting from './migrations/004AddPainGuideFeedSetting';
import AddPainGuideCard from './migrations/005AddPainGuideCard';
import AddNoneValueToFields from './migrations/005AddNoneValueToFields';
import FixPasswordSetField from './migrations/007FixPasswordSetField';

export const DATABASE_VERSION = 7;

export const migrations = {
  2: AddSignupCard, //Add signup card to state
  3: AddQuestionnairesCard, //Add signup card to state
  4: AddPainGuideFeedSetting,
  5: AddPainGuideCard,
};

export const fieldsMigrations = {
  6: AddNoneValueToFields,
};

export const authMigrations = {
  7: FixPasswordSetField,
};
