import Toast from '../../design/components/Toast';

import strings from '../strings';
import Alert from '../../design/components/Alert';

export default (title, message, onRetry) => {
  if (message && message.result_msg) {
    message = message.result_msg;
  }
  if (message && message.message) {
    message = message.message;
  }
  //    message = JSON.stringify(message);
  if (!message || typeof message !== 'string') {
    console.log('unknown error happened', message);
    message = 'Unknown error';
  }
  if (!title) {
    title = 'Error';
  }
  let but = [
    {
      text: strings.error_dialog_ok_button_text,
      onPress: () => {},
    },
  ];
  console.log('ERROR SHOW', message);
  if (onRetry) {
    but.push({ text: strings.error_dialog_retry_button, onPress: onRetry });
    Alert.alert(title, message, but, { cancellable: false });
  } else {
    console.log('TOAST SHOW', message);
    Toast.show({
      supportedOrientations: ['portrait', 'landscape'],
      text: message,
      position: 'bottom',
      duration: 3000,
      buttonText: strings.error_dialog_ok_button_text,
    });
  }
};
