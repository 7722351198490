import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select as MaterialSelect } from '@mui/material';
import useThemeContext from '../styles/useThemeContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    '&&': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent!important',
    },
  },
  icon: (colors) => ({
    fill: colors.buttonBlue,
    position: 'static',
  }),
  selectRoot: (colors) => ({
    color: colors.buttonBlue,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  }),
  menuRoot: (colors) => ({
    backgroundColor: colors.defaultWindowBg,
  }),
  menuItem: (colors) => ({
    color: colors.textPrimary,
    '&.Mui-selected': {
      color: colors.buttonBlue,
    },
  }),
});

const Select = ({ selectedId, onItemPress, children, items }) => {
  const [selectedOption, setSelectedOption] = useState(selectedId);
  const { colors } = useThemeContext();
  const classes = useStyles(colors);

  const handleChange = (event) => {
    const _selectedOption = event.target.value;
    setSelectedOption(_selectedOption);
    onItemPress(_selectedOption);
  };

  useEffect(() => {
    setSelectedOption(selectedId);
  }, [selectedId]);

  return (
    <FormControl style={{ marginTop: 4, marginLeft: 4 }}>
      {children}
      <MaterialSelect
        value={selectedOption}
        onChange={handleChange}
        className={classes.root}
        MenuProps={{
          PopoverClasses: {
            paper: classes.menuRoot,
          },
        }}
        inputProps={{
          classes: {
            root: classes.selectRoot,
            icon: classes.icon,
          },
        }}
        disableUnderline
      >
        {items?.map((item) => (
          <MenuItem
            classes={{ root: classes.menuItem }}
            key={'key_' + item.id}
            value={item.id}
          >
            {item.text}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

export default Select;
