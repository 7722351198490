// Refactor direct medicationsDosageUnits.find by replacing on getDosageUnitsDisplayValue
const getDosageUnitsDisplayValue = (units) => {
  const unit_object = medicationsDosageUnits.find(
    (_unit) => _unit.value === units,
  );
  if (!unit_object) {
    return medicationsDosageUnits.find((_unit) => _unit.value === 'units')
      .getCountDisplayValue;
  }
  return unit_object.getCountDisplayValue;
};

export const medicationParser = (response) => {
  // Response is looking like:
  // {
  //      common_name: string,
  //      brand_name: string,
  //      top_dosage_units: Array<String>, - e.g. 'capsules'
  //      top_strength: Array<String> - e.g 30/90/50%, 30/90mL
  // }
  // Parsing is done in following way:
  // if medication is mixture - looking for top strength and if it defined only for one medication (no slashes) -
  // this value will be assigned for every mixtures component.
  // strength_unit of first top_strength will be selected as default strength_unit
  // other will be next, and al un-included - by alphabetical order
  // if top_strength is empty - the default values will be used.
  // top_dosage_units - defines order and selected value for dosage_units picker

  let { common_name, brand_name, top_dosage_units, top_strengths } = response;
  top_dosage_units = [...top_dosage_units];
  top_strengths = [...top_strengths];

  const words = String(common_name).split(' ');
  const length = words.length;
  let mixtCount = 1;

  if (length > 0 && words.includes('mixt')) {
    mixtCount = common_name.split(' - ').length;
  }

  let dosages = [];

  if (Array.isArray(top_strengths)) {
    top_strengths.forEach((tdu, index) => {
      if (!tdu) {
        return;
      }
      tdu = tdu.toString();
      const tdu_length = tdu.length;
      let strength_unit = '';
      for (let i = tdu_length - 1; i >= 0; i--) {
        if (Number.isInteger(parseInt(tdu[i], 10))) {
          break;
        }
        strength_unit = tdu[i] + strength_unit;
      }

      if (!strength_unit) {
        strength_unit = 'mg';
      }

      let strengths =
        tdu.lastIndexOf(strength_unit) >= 0
          ? tdu.substring(0, tdu.lastIndexOf(strength_unit)).split('/')
          : tdu.split('/');

      let strengthAreCorrect = true;
      strengths.forEach((strength) => {
        if (strength === '') {
          strengthAreCorrect = false;
        }
      });
      if (strengths.length < mixtCount) {
        strengthAreCorrect = false;
      }

      const strengths_concatenated = tdu.substring(
        0,
        tdu.lastIndexOf(strength_unit),
      );

      strengthAreCorrect &&
        dosages.push({ strength_unit, strengths, strengths_concatenated });
    });
  }

  // if (dosages.length < 3) {
  //     let strength_unit = default_data['mg'];
  //
  //     switch (dosages.length) {
  //         case 0: {
  //             let strengths1 = [];
  //             let strengths_concatenated1 = '';
  //
  //             strengths_concatenated1 = 50;
  //             for (let i = 0; i < mixtCount; i++) {
  //                 strengths1.push(50);
  //                 i > 0 && (strengths_concatenated1 = strengths_concatenated1 + '/' + 50);
  //             }
  //             dosages.push({strength_unit, strengths1, strengths_concatenated1});
  //
  //             let strengths_concatenated2 = 100;
  //             let strengths2 = strengths1.map((v, i) => {
  //                 i > 0 && (strengths_concatenated2 = strengths_concatenated2 + '/' + 100);
  //                 return 100;
  //             });
  //
  //             let strengths_concatenated3 = 150;
  //             let strengths3 = strengths1.map((v, i) => {
  //                 i > 0 && (strengths_concatenated3 = strengths_concatenated3 + '/' + 150);
  //                 return 150;
  //             });
  //
  //             dosages.push({strength_unit, strengths: strengths2, strengths_concatenated: strengths_concatenated2});
  //             dosages.push({strength_unit, strengths: strengths3, strengths_concatenated: strengths_concatenated3});
  //
  //             break;
  //         }
  //         case 1: {
  //             let strengths1 = dosages[0].strengths;
  //
  //
  //             let strengths_concatenated2 = parseFloat(strengths1[0]) + 50;
  //             let strengths2 = strengths1.map((v, i) => {
  //                 i > 0 && (strengths_concatenated2 = strengths_concatenated2 + '/' + (parseFloat(v) + 50));
  //                 return parseFloat(v) + 50;
  //             });
  //
  //             let strengths_concatenated3 = parseFloat(strengths2[0]) + 50;
  //             let strengths3 = strengths2.map((v, i) => {
  //                 i > 0 && (strengths_concatenated3 = strengths_concatenated3 + '/' + (parseFloat(v) + 50));
  //                 return parseFloat(v) + 50;
  //             });
  //
  //             dosages.push({strength_unit, strengths: strengths2, strengths_concatenated: strengths_concatenated2});
  //             dosages.push({strength_unit, strengths: strengths3, strengths_concatenated: strengths_concatenated3});
  //
  //             break;
  //         }
  //         case 2: {
  //             let strengths1 = dosages[0].strengths;
  //             let strengths2 = dosages[1].strengths;
  //
  //             let strengths_concatenated3 = parseFloat(strengths2[0]) + parseFloat(strengths1[0]);
  //             let strengths3 = strengths2.map((v, i) => {
  //                 i > 0 && (strengths_concatenated3 = strengths_concatenated3 + '/' + (parseFloat(v) + parseFloat(strengths1[i])));
  //                 return parseFloat(v) + parseFloat(strengths1[i]);
  //             });
  //
  //             dosages.push({strength_unit, strengths: strengths3, strengths_concatenated: strengths_concatenated3});
  //             break;
  //
  //         }
  //     }
  // }

  const strength_units_in_order = [];
  dosages.forEach((dosage) => {
    if (
      strength_units_in_order.filter(
        (dosage_unit) => dosage_unit.value === dosage.strength_unit,
      ).length === 0
    ) {
      strength_units_in_order.push({ value: dosage.strength_unit });
    }
  });
  strengthUnits.forEach((unit) => {
    if (
      strength_units_in_order.filter(
        (pre_defined_unit) => pre_defined_unit.value === unit.value,
      ).length === 0
    ) {
      strength_units_in_order.push({ ...unit });
    }
  });

  const dosage_units_in_order = [];

  top_dosage_units.forEach((dosage_unit) => {
    const med_dosage_unit_array = medicationsDosageUnits.filter(
      (med_dosage_unit) => dosage_unit === med_dosage_unit.value,
    );
    if (med_dosage_unit_array.length === 1) {
      dosage_units_in_order.push({ ...med_dosage_unit_array[0] });
    }
  });

  medicationsDosageUnits.forEach((med_dosage_unit) => {
    if (
      dosage_units_in_order.filter(
        (dosage_unit) => dosage_unit.value === med_dosage_unit.value,
      ).length === 0
    ) {
      dosage_units_in_order.push({ ...med_dosage_unit });
    }
  });

  return { dosages, strength_units_in_order, dosage_units_in_order };
};

const medicationsDosageUnits = [
  {
    value: 'applications',
    display_value: 'constants_dosage_applications',
    icon_code: 'dosage-applications',
    getCountDisplayValue: 'constants_dosage_applications_count', // function
  },
  {
    value: 'capsules',
    display_value: 'constants_dosage_capsules',
    icon_code: 'dosage-capsules',
    getCountDisplayValue: 'constants_dosage_capsules_count', // function
  },
  {
    value: 'drops',
    display_value: 'constants_dosage_drops',
    icon_code: 'dosage-drops',
    getCountDisplayValue: 'constants_dosage_drops_count', // function
  },
  {
    value: 'injections',
    display_value: 'constants_dosage_injections',
    icon_code: 'dosage-injections',
    getCountDisplayValue: 'constants_dosage_injections_count', // function
  },
  {
    value: 'mL',
    display_value: 'constants_dosage_ml',
    icon_code: 'dosage-ml',
    getCountDisplayValue: 'constants_dosage_ml_count', // function
  },
  {
    value: 'patches',
    display_value: 'constants_dosage_patches',
    icon_code: 'dosage-patches',
    getCountDisplayValue: 'constants_dosage_patches_count', // function
  },
  {
    value: 'puffs',
    display_value: 'constants_dosage_puffs',
    icon_code: 'dosage-puffs',
    getCountDisplayValue: 'constants_dosage_puffs_count', // function
  },
  {
    value: 'sprays',
    display_value: 'constants_dosage_sprays',
    icon_code: 'dosage-sprays',
    getCountDisplayValue: 'constants_dosage_sprays_count', // function
  },
  {
    value: 'suppositories',
    display_value: 'constants_dosage_suppositories',
    icon_code: 'dosage-suppositories',
    getCountDisplayValue: 'constants_dosage_suppositories_count', // function
  },
  {
    value: 'tablets',
    display_value: 'constants_dosage_tablets',
    icon_code: 'dosage-tablets',
    getCountDisplayValue: 'constants_dosage_tablets_count', // function
  },
  {
    value: 'units',
    display_value: 'constants_dosage_units',
    icon_code: 'dosage-units',
    getCountDisplayValue: 'constants_dosage_units_count', // function
  },
];

const strengthUnits = [
  { value: '%', display_value: 'constants_strength_unit_%' },
  { value: 'µg', display_value: 'constants_strength_unit_µg' },
  { value: 'g', display_value: 'constants_strength_unit_g' },
  { value: 'mg', display_value: 'constants_strength_unit_mg' },
];

export default {
  medicationsDosageUnits,
  strengthUnits,
  getDosageUnitsDisplayValue,
};
