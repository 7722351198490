export const APP_OPEN = 'APP_OPEN';

// unsync
export const APP_UPDATE_SETTINGS = 'APP_UPDATE_SETTINGS';
export const LOGIN_FROM_INTRO = 'LOGIN_FROM_INTRO';
export const CLEAN_INTRO_ENTRIES = 'CLEAN_INTRO_ENTRIES';
export const CLEAR_INTRO_STATE = 'CLEAR_INTRO_STATE';

export const ADD_UNSYNC_PAIN_CONDITION = 'ADD_UNSYNC_PAIN_CONDITION';
export const UPDATE_UNSYNC_PAIN_CONDITION = 'UPDATE_UNSYNC_PAIN_CONDITION';

export const ADD_UNSYNC_MEDICATION = 'ADD_UNSYNC_MEDICATION';
export const UPDATE_UNSYNC_MEDICATION = 'UPDATE_UNSYNC_MEDICATION';
export const CLEAR_UNSYNC_MEDICATION = 'CLEAR_UNSYNC_MEDICATION';

export const UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS =
  'UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS';
export const UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH =
  'UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH';
export const UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH =
  'UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH';
export const UPDATE_UNSYNC_MEDICATION_BRAND = 'UPDATE_UNSYNC_MEDICATION_BRAND';
export const ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION =
  'ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION';
