import {
  Dimensions,
  Keyboard,
  PixelRatio,
  Platform,
  StatusBar,
  useWindowDimensions,
} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { useEffect, useState } from 'react';
import getWebViewPath from './webViewPath';

const platform = Platform.OS;
const { width, height } = Dimensions.get('window');
const isIphoneX =
  Platform.OS === 'ios' &&
  !Platform.isPad &&
  !Platform.isTVOS &&
  DeviceInfo.hasNotch();

const isIphone14 = Platform.OS === 'ios' && DeviceInfo.hasDynamicIsland();

global.__REMOTEDEV__ = false;
//Do not use tablet format on devices width width less than 600dp
const isWeb = Platform.OS === 'web';
const webMaxPhoneWidth = 600;
const subscriptionMarket = Platform.OS === 'ios' ? 'apple' : 'google';
const isTabletNative =
  DeviceInfo.isTablet() &&
  (Platform.OS === 'ios' ||
    Math.min(height, width) >= 600 ||
    Math.max(height, width) >= 940);
const useIsTablet = () => {
  if (isWeb) {
    //isWeb constant and never changed, so we are safe to use it like that
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const windowSize = useWindowDimensions();
    return windowSize.width > webMaxPhoneWidth;
  }

  return isTabletNative;
};

const useKeyboardVisible = () => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);

  useEffect(() => {
    let keyboardShowListener;
    let keyboardHideListener;
    if (Platform.OS === 'ios') {
      keyboardShowListener = Keyboard.addListener('keyboardWillShow', () => {
        setKeyboardVisible(true);
      });
      keyboardHideListener = Keyboard.addListener('keyboardWillHide', () => {
        setKeyboardVisible(false);
      });
    } else {
      keyboardShowListener = Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardVisible(true);
      });
      keyboardHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardVisible(false);
      });
    }
    return () => {
      keyboardHideListener.remove();
      keyboardShowListener.remove();
    };
  }, []);
  return isKeyboardVisible;
};

const isTablet = () => {
  if (global.__REMOTEDEV__) {
    return false;
  }
  if (isWeb) {
    return Dimensions.get('window').width > webMaxPhoneWidth;
  }

  return isTabletNative;
};

let { indexWebViewPath, questionnairesWebViewPath, localAssetsWebViewPath } =
  getWebViewPath();

//Read indexWebViewPath

export const designConstants = {
  fontFamily:
    platform === 'ios'
      ? 'System'
      : platform === 'web'
        ? 'Roboto Regular'
        : 'Roboto',
  fontSizes: {
    // --- To delete ---
    header1: 25,
    header2: 23,
    header3: 20,
    text1: 18,
    text2: 16,
    text3: 14,
    text4: 12,
    // ---

    small: 12,
    medium: 14,
    default: 16,
    large: 18,

    headerSmall: 20,
    headerMedium: 23,
    headerLarge: 25,
  },

  // Not only padding, but margins and other spaces - padding here is general term for spaces
  paddings: {
    // This padding is used for content and also for distinct elements inside of content
    contentDefaultPadding: 30,

    edgePadding: 16,

    headerHorizontalPadding: 4,

    textFieldHorizontalPadding: 12,
    textFieldVerticalPadding: 8,

    paddingBetweenElementsExtraSmall: 2,
    paddingBetweenElementsSmall: 5,
    paddingBetweenElementsMedium: 10,
    paddingBetweenElementsLarge: 20,

    buttonMediumEmphasisPadding: 5,
    buttonLowEmphasisPadding: 5,

    largePaddingBetweenTextAndIcon: 36,
  },

  heights: {
    touchableMinHeight: 48,

    listItemMinHeightSmall: 44,
    listItemMinHeightMedium: 60,
    listItemMinHeightLarge: 72,
  },

  footer: {
    height: 50 + (isIphoneX ? 34 : 0),
    heightWithoutIPhoneXPadding: 50,
    buttonContainerHeight: 80,
  },

  header: {
    headerTextMargin: 32,
  },

  icons: {
    wrappingCircleWidth: 28,
    wrappingCircleHeight: 28,
    borderRadius: 100,
    iconMarginLeft: 8,

    // ???
    width: 24,
    height: 24,

    size: 24,

    expandIconCorrectingMarginRight: -6,
  },

  buttonPressDelay: 1200,
  onePixel: 1 / PixelRatio.getPixelSizeForLayoutSize(1),
  bottomSpacerForIPhoneX: isIphoneX ? 34 : 0,
  topSpacerForIPhoneX: isIphoneX ? 14 : 0, // Default status bar is 20

  contentPadding1: 30,
  footerHeight: 50 + (isIphoneX ? 34 : 0),
  statusBarHeight:
    Platform.OS === 'web'
      ? 0
      : Platform.OS === 'ios'
        ? 20 + (isIphone14 ? 22 : isIphoneX ? 14 : 0)
        : StatusBar.currentHeight,
  headerHeight: Platform.OS === 'ios' ? 60 : 56,

  activeOpacity: 0.8,
  backgroundOverlayOpacity: 0.5,
  isIphoneX: isIphoneX,
  isTablet: isTablet,
  useIsTablet: useIsTablet,
  useKeyboardVisible: useKeyboardVisible,
  isWeb: Platform.OS === 'web',
  // isTablet:false,
  twoColumns: width > 480,
  windowHeight: height,
  windowWidth: width,
  maxFormWidth: 500,
  webMaxPhoneWidth,
  webMaxWidth: 1140,
  webDrawerWidth: 280,
  indexWebViewPath,
  questionnairesWebViewPath,
  localAssetsWebViewPath,
  subscriptionMarket,
};
