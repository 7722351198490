import * as types from './actiontypes';
import strings from './strings';
import { APP_OPEN } from '../Intro/actiontypes';
import * as profileTypes from '../Profile/actiontypes';

const INITIAL = {
  last_updated: 0,
  first_launch: true,
  fail_count: 0,
  synced_first_time: false,
};

function SyncReducer(state = { ...INITIAL }, action) {
  switch (action.type) {
    case APP_OPEN:
      return {
        ...state,
        message: null,
        first_launch: false,
        sync_in_progress: false,
      };
    case profileTypes.ACCOUNT_DELETED:
    case types.LOGOUT:
      //We need to clear message after logout
      return {
        ...{
          last_updated: 0,
          sync_in_progress: 0,
          message: null,
          synced_first_time: false,
        },
      };
    case types.SYNC:
      return {
        ...state,
        message: strings.sync_reducer_synchronizing_message,
        type: 'info',
      };
    case types.SYNC_CUSTOM_LISTS:
      return {
        ...state,
        message: strings.sync_reducer_processing_preferences,
        type: 'info',
      };
    case types.SYNC_CUSTOM_LISTS_SUCCESS:
      //Custom lists synced!
      return {
        ...state,
        message: strings.sync_reducer_processing_preferences,
        type: 'info',
      };
    case types.SYNC_SUCCESS:
      return {
        ...state,
        last_updated: action.payload,
        message: strings.sync_reducer_successful_sync_message,
        type: 'success',
        synced_first_time: true,
        fail_count: 0,
      };
    case types.SYNC_SUCCESS_CLOSE:
      state.message = null;
      state.type = null;
      return {
        ...state,
      };
    case types.SYNC_SYNC_IN_PROCESS_FLAG_ON: {
      console.log('TURN ON SYNC FLAG');
      return {
        ...state,
        sync_in_progress: true,
      };
    }

    case types.SYNC_SYNC_IN_PROCESS_FLAG_OFF: {
      console.log('TURN OFF SYNC FLAG');
      return {
        ...state,
        sync_in_progress: false,
      };
    }
    case types.API_ERROR:
      if (action.reason === types.SYNC || action.reason === types.CHECK_TOKEN) {
        return {
          ...state,
          message: action.error,
          type: 'error',
          sync_in_progress: false,
          fail_count: (state.fail_count || 0) + 1,
        };
      }

    default:
      return state;
  }
}

export { SyncReducer };
