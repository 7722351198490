import React from 'react';
import { ActivityIndicator } from 'react-native';
import { withTheme } from '../styles/useThemeContext';

class Spinner extends React.Component {
  render() {
    const { colors } = this.props.theme;
    return (
      <ActivityIndicator
        size="large"
        color={colors.buttonBlue}
        style={[{ flex: 1 }, { ...this.props.style }]}
        {...this.props}
      />
    );
  }
}

export default withTheme(Spinner);
