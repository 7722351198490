import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const plurals = [
  'pain_condition_list_delete_confirmation_popup_text_usages_count',
  'medication_list_delete_confirmation_popup_text_usages_count',

  'edit_pain_condition_edit_confirmation_pop_up_text_usages_count',
  'edit_medication_edit_confirmation_pop_up_text_usages_count',

  'medication_instructions_hours_count',
  'medication_instructions_days_count',
  'medication_instructions_weeks_count',
  'medication_instructions_months_count',
  'medication_instructions_hourly_count',
  'medication_instructions_daily_count',
  'medication_instructions_weekly_count',
  'medication_instructions_monthly_count',

  'constants_dosage_applications_count',
  'constants_dosage_capsules_count',
  'constants_dosage_drops_count',
  'constants_dosage_injections_count',
  'constants_dosage_ml_count',
  'constants_dosage_patches_count',
  'constants_dosage_puffs_count',
  'constants_dosage_sprays_count',
  'constants_dosage_suppositories_count',
  'constants_dosage_tablets_count',
  'constants_dosage_units_count',
];

const strings = new LocalizedStrings({
  en: {
    // Profile Screen - pain_condition section, Purposes List
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'en'),
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      'This condition is associated with {0} medication',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      'This condition is associated with {0} medications',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      'This condition is associated with' + ' {0} medications',
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'en'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'en'),
    pain_condition_list_delete_confirmation_popup_title: 'Delete condition?',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'en',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'en',
    ),
    pain_condition_list_add_new_button: shared('Add New', 'en'),
    pain_condition_list_delete_pain_condition_successful_toast_text:
      'Condition successfully deleted',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'en'),
    pain_condition_list_delete_pain_condition_overlay_text:
      'Deleting condition...',
    pain_condition_list_diagnosed_row: 'Date of diagnosis',
    pain_condition_list_first_symptom_row: 'Date of first symptom',
    pain_condition_list_no_pain_conditions_text:
      'No conditions have been added to your Profile',

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'en'),
    medication_list_add_new_button: shared('Add New', 'en'),

    medication_list_delete_medication_overlay_text: 'Deleting medication...',
    medication_list_no_current_medications_text:
      'No current medications have been added to your Profile',
    medication_list_no_medications_text:
      'No medications have been added to your Profile',
    medication_list_current_medications_header: 'Current medication(s):',
    medication_list_outdated_medications_header: 'Previous medication(s):',
    medication_list_edit_button_action_sheet: shared('Edit', 'en'),
    medication_list_delete_button_action_sheet: shared('Delete', 'en'),
    medication_list_delete_confirmation_popup_title: 'Delete medication?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      'There is {0} record associated with this' +
      ' medication. Would you like to continue?',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      'There are {0} records associated with' +
      ' this medication. Would you like to continue?',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      'There are {0} records' +
      ' associated with this medication. Would you like to continue?',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'en',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'en',
    ),
    medication_list_delete_medication_successful_toast_text:
      'Medication successfully deleted',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'en'),
    medication_list_generic_label: shared('Generic', 'en'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'µg',
    medication_collapsible_form_strength_unit_g: 'g',
    medication_collapsible_form_strength_unit_mg: 'mg',

    medication_collapsible_form_add_strength_button: shared('Add', 'en'),
    medication_collapsible_form_choose_brand_placeholder: 'Choose brand',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'en'),
    medication_collapsible_form_add_new_brand_button_label:
      "Can't find your brand?\n{0}",
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'en'),
    medication_collapsible_form_dose_title: 'or create your own dose',
    medication_collapsible_form_dosage_units_modal_picker_header:
      'Medication form',
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      'Choose medication format',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'en',
    ),
    medication_collapsible_form_strength_units_modal_picker_header:
      'Strength unit',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'en'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'µg',
    constants_strength_unit_g: 'g',
    constants_strength_unit_mg: 'mg',

    constants_dosage_applications: 'applications',
    constants_dosage_capsules: 'capsules',
    constants_dosage_drops: 'drops',
    constants_dosage_injections: 'injections',
    constants_dosage_ml: 'mL',
    constants_dosage_patches: 'patches',
    constants_dosage_puffs: 'puffs',
    constants_dosage_sprays: 'sprays',
    constants_dosage_suppositories: 'suppositories',
    constants_dosage_tablets: 'tablets',
    constants_dosage_units: 'units',

    constants_dosage_applications_plural: 'application(s)',
    constants_dosage_capsules_plural: 'capsule(s)',
    constants_dosage_drops_plural: 'drop(s)',
    constants_dosage_injections_plural: 'injection(s)',
    constants_dosage_ml_plural: 'mL',
    constants_dosage_patches_plural: 'patch(es)',
    constants_dosage_puffs_plural: 'puff(s)',
    constants_dosage_sprays_plural: 'spray(s)',
    constants_dosage_suppositories_plural: 'suppository(ies)',
    constants_dosage_tablets_plural: 'tablet(s)',
    constants_dosage_units_plural: 'unit(s)',

    constants_dosage_applications_count_one: '{0} application',
    constants_dosage_applications_count_other: '{0} applications',
    constants_dosage_applications_count_many: '{0} applications',
    constants_dosage_capsules_count_one: '{0} capsule',
    constants_dosage_capsules_count_other: '{0} capsules',
    constants_dosage_capsules_count_many: '{0} capsules',
    constants_dosage_drops_count_one: '{0} drop',
    constants_dosage_drops_count_other: '{0} drops',
    constants_dosage_drops_count_many: '{0} drops',
    constants_dosage_injections_count_one: '{0} injection',
    constants_dosage_injections_count_other: '{0} injections',
    constants_dosage_injections_count_many: '{0} injections',
    constants_dosage_ml_count_one: '{0} ml',
    constants_dosage_ml_count_other: '{0} ml',
    constants_dosage_ml_count_many: '{0} ml',
    constants_dosage_patches_count_one: '{0} patch',
    constants_dosage_patches_count_other: '{0} patches',
    constants_dosage_puffs_count_one: '{0} puff',
    constants_dosage_puffs_count_other: '{0} puffs',
    constants_dosage_puffs_count_many: '{0} puffs',
    constants_dosage_sprays_count_one: '{0} spray',
    constants_dosage_sprays_count_other: '{0} sprays',
    constants_dosage_sprays_count_many: '{0} sprays',
    constants_dosage_suppositories_count_one: '{0} suppository',
    constants_dosage_suppositories_count_other: '{0} suppositories',
    constants_dosage_suppositories_count_many: '{0} suppositories',
    constants_dosage_tablets_count_one: '{0} tablet',
    constants_dosage_tablets_count_other: '{0} tablets',
    constants_dosage_tablets_count_many: '{0} tablets',
    constants_dosage_units_count_one: '{0} unit',
    constants_dosage_units_count_other: '{0} units',
    constants_dosage_units_count_many: '{0} units',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: 'Condition',
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      'Condition has already been added',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'en'),
    search_pain_condition_add_new_pain_condition_alert_title:
      "Are you sure you can't find condition {0}?",
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'en',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'en',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      'Condition successfully added',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'en'),
    search_pain_condition_pain_condition_selected_toast_text:
      'Condition selected',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'en',
    ),

    search_pain_condition_header_title: 'Add Pain Condition',
    search_pain_condition_text_before_search_bar:
      'Search for your condition and select it from the resulting list.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'en',
    ),

    search_pain_condition_secondary_text_above_results:
      'Are any of these the condition you are looking for?',
    search_pain_condition_secondary_text_above_not_found_results:
      'Condition does not exist in our database.',
    search_pain_condition_pain_condition_not_found:
      "Can't find your condition?\n{0}",
    search_pain_condition_add_new_button_text: shared('Add New', 'en'),
    search_pain_condition_create_button_text: 'Add condition',

    // Edit Pain Condition Screen,
    // scenes/EditPainConditionScene.js
    edit_pain_condition_name_label: shared('Name', 'en'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'en',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'en',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'en'),
    edit_pain_condition_header_add_new_title: 'Add Pain Condition',
    edit_pain_condition_header_edit_title: 'Edit Pain Condition',
    edit_pain_condition_back_without_save_confirmation_title:
      'Your changes will be lost if you proceed. Save changes?',
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'en',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'en',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'en',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'en',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'en',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title: 'Edit Pain Condition?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      'This condition is associated with {0}' +
      ' medication. Would you like to continue editing?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      'This condition is associated with {0} medications. Would you like to continue editing?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      'This condition is associated with {0} medications. Would you like to continue editing',

    edit_pain_condition_saving_overlay_text: 'Saving condition...',
    edit_pain_condition_adding_text: 'Adding condition',
    edit_pain_condition_editing_text: 'Editing condition',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      'Condition successfully added',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'en'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      'Condition successfully edited',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'en'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'en'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: 'My Conditions',
    edit_pain_conditions_back_button_text: shared('Back', 'en'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'en'),
    edit_pain_conditions_back_confirmation_alert_text:
      'Some pain conditions visibility were changed. Would' +
      ' you like to save it?',
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'en',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'en',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'en',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: 'Add Medication',
    search_medication_input_label: shared('Medication', 'en'),
    search_medication_text_before_search_bar:
      'Search for the name of your medication (without strength) and select it from the resulting list.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'en',
    ),
    search_medication_secondary_text_above_results:
      'Are any of these the medication you are looking for?',
    search_medication_secondary_text_above_not_found_results:
      'Medication does not exist in our database.',
    search_medication_medication_not_found: "Can't find your medication?\n{0}",
    search_medication_request_medication: shared('Request It', 'en'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      'Mobile request: Missing medication',
    search_medication_data_medication_email_first_line:
      'The following medication, {0}, was not found.',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'en',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: 'Add new brand',
    search_medication_brand_title:
      "Type the brand of your medication below and we'll add it.",
    search_medication_brand_ok_button: shared('OK', 'en'),
    search_medication_brand_label: 'Brand',
    search_medication_brand_selected_medication_label_text: 'Brand of ',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'en',
    ),
    search_medication_brand_similar_results_search_helper:
      'Are any of these the brand you are looking for?',
    search_medication_brand_no_similar_item_search_helper:
      'Brand does not exist in our database.',
    search_medication_brand_request_brand_label: "Can't find your brand?\n{0}",
    search_medication_brand_request_brand: shared('Request It', 'en'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      'Your brand will be added shortly after we receive your request. In the meantime, your' +
      ' medication will be added as Generic.',
    search_medication_brand_added_toast: 'Brand successfully added',
    search_medication_brand_selected_toast:
      'Brand successfully selected for the medication',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: 'Add Medication',
    edit_medication_header_edit_title: 'Edit Medication',
    edit_medication_back_without_save_confirmation_title:
      'Your changes will be lost if you proceed. Save changes?',
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'en',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'en',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'en',
    ),
    edit_medication_saving_overlay_text: 'Saving medication...',
    edit_medication_editing_text: 'Editing medication',
    edit_medication_adding_text: 'Adding medication',
    edit_medication_medication_description_section_header:
      'Medication Description',
    edit_medication_medication_purposes_section_header: 'Purpose',
    edit_medication_medication_instructions_section_header: 'Instructions',
    edit_medication_medication_notes_header: shared('Notes', 'en'),
    edit_medication_medication_successfully_added_toast_text:
      'Medication successfully added',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'en',
    ),
    edit_medication_medication_successfully_edited_toast_text:
      'Medication successfully edited',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'en',
    ),
    edit_medication_medication_duplication_toast_text:
      'Medication already exists in your profile',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'en',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'en',
    ),
    edit_medication_edit_confirmation_pop_up_title: 'Edit Medication?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      'There is {0} record associated with this' +
      ' medication. Would you like to continue?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      'There are {0} record associated with this' +
      ' medication. Would you like to continue?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      'There are {0} record associated with' +
      ' this medication. Would you like to continue?',
    edit_medication_apply_button_text: shared('Apply changes', 'en'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: 'My Medications',
    edit_medications_footer_apply_button: shared('Continue', 'en'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: 'Brand',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'en',
    ),
    medication_description_strength_label: 'Strength',
    medication_description_strength_error: 'Enter value before saving',
    medication_description_form_label: 'Form',
    medication_description_search_again_button_text: shared(
      'Search again',
      'en',
    ),
    medication_description_dosage_units_picker_header: 'Medication form',
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'en',
    ),
    medication_description_strength_units_picker_header: 'Strength unit',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'en',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: 'Edit List',
    medication_purposes_purposes_list_is_empty: 'Purposes list is empty',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      "I'm taking this medication",
    medication_instructions_start_date_label: 'Start Date',
    medication_instructions_start_date_datepicker_selecting_text:
      'Select start date',
    medication_instructions_end_date_label: 'End Date',
    medication_instructions_end_date_datepicker_selecting_text:
      'Select end date',
    medication_instructions_take_as_header: 'Take as',
    medication_instructions_dosages_header: 'Dosage',
    medication_instructions_no_dosages_text: 'No dosages have been added',
    medication_instructions_take_as_value_needed: 'Needed',
    medication_instructions_take_as_value_scheduled: 'Scheduled',
    medication_instructions_take_as_value_scheduled_and_needed:
      'Scheduled & needed',

    medication_instructions_frequencies_hourly: 'Hourly',
    medication_instructions_frequencies_daily: 'Daily',
    medication_instructions_frequencies_weekly: 'Weekly',
    medication_instructions_frequencies_monthly: 'Monthly',

    medication_instructions_add_dosage_button_text: 'Add dosage',
    medication_instructions_special_instructions_label:
      'Additional instructions',
    medication_instructions_delete_dosage_confirmation_popup_title:
      'Delete dosage',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'en'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'en'),
    medication_instructions_add_dosage_modal_header: 'Add Dosage',
    medication_instructions_edit_dosage_modal_header: 'Edit Dosage',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'en',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'en'),
    medication_instructions_add_dosage_modal_dosage_number_label: 'Dosage',
    medication_instructions_dosage_time_string_start_time_and_end_time:
      'between {0} and {1}',
    medication_instructions_dosage_time_string_start_time: 'after {0}',
    medication_instructions_dosage_time_string_end_time: 'before {0}',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: 'on {0} day of month',
    medication_instructions_add_dosage_modal_dosage_number_error:
      'Enter value before saving',

    medication_instructions_hourly_count_one: 'Every hour',
    medication_instructions_hourly_count_other: 'Every {0} hours',
    medication_instructions_hourly_count_many: 'Every {0} hours',
    medication_instructions_daily_count_one: 'Every day',
    medication_instructions_daily_count_other: 'Every {0} days',
    medication_instructions_daily_count_many: 'Every {0} days',
    medication_instructions_weekly_count_one: 'Every week',
    medication_instructions_weekly_count_other: 'Every {0} weeks',
    medication_instructions_weekly_count_many: 'Every {0} weeks',
    medication_instructions_monthly_count_one: 'Every month',
    medication_instructions_monthly_count_other: 'Every {0} months',
    medication_instructions_monthly_count_many: 'Every {0} months',

    medication_instructions_close_dosage_modal_confirmation_popup_title:
      'Your changes will be lost if you proceed. Save changes?',
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'en'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'en'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'en'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      'End Date must be equal to or later than Start Date',
    medication_instructions_start_after_end_time_validation_failed_alert:
      'End Time must be equal to or later than Start Time',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: 'Enter any additional information',
  },
  de: {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'de'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'de'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'de'),
    pain_condition_list_add_new_button: shared('Add New', 'de'),
    pain_condition_list_no_pain_conditions_text:
      'Ihrem Profil wurden keine Schmerzzustände hinzugefügt.',

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title:
      'Schmerzzustand löschen?',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      'Dieser Schmezzustand ist mit {0} Medikament verknüpft. Diese Verknüpfung wird aufgehoben.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      'Dieser Schmezzustand ist mit {0} Medikamenten verknüpft. Diese Verknüpfungen werden aufgehoben.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      'Dieser Schmezzustand ist mit {0} Medikamenten verknüpft. Diese Verknüpfungen werden aufgehoben.',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'de',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'de',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      'Lösche Schmerzzustand…',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      'Schmerzzustand erfolgreich gelöscht',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'de'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'de'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'de',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'de'),
    medication_list_add_new_button: shared('Add New', 'de'),

    medication_list_delete_medication_overlay_text: 'Lösche Medikament…',
    medication_list_no_current_medications_text:
      'Ihrem Profil wurden keine aktuellen Medikamente  hinzugefügt.',
    medication_list_no_medications_text:
      'Ihrem Profil wurden keine  Medikamente  hinzugefügt.',
    medication_list_current_medications_header: 'Aktuelle/s Medikament/e:',
    medication_list_outdated_medications_header: 'Frühere/s Medikament/e:',
    medication_list_edit_button_action_sheet: shared('Edit', 'de'),
    medication_list_delete_button_action_sheet: shared('Delete', 'de'),
    medication_list_delete_confirmation_popup_title: 'Medikament löschen?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      '{0} Eintrag ist mit diesem Medikament verknüpft. Diese Verknüpfung wird aufgehoben.',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      '{0} Einträge sind  mit diesem Medikament verknüpft. Diese Verknüpfungen werden aufgehoben.',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      '{0} Einträge sind  mit diesem Medikament verknüpft. Diese Verknüpfungen werden aufgehoben.',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'de',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'de',
    ),
    medication_list_delete_medication_successful_toast_text:
      'Medikament erfolgreich gelöscht',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'de'),
    medication_list_generic_label: shared('Generic', 'de'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'µg',
    medication_collapsible_form_strength_unit_g: 'g',
    medication_collapsible_form_strength_unit_mg: 'mg',

    medication_collapsible_form_add_strength_button: shared('Add', 'de'),
    medication_collapsible_form_choose_brand_placeholder: 'Marke wählen',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'de'),
    medication_collapsible_form_add_new_brand_button_label:
      'Ihre Marke ist nicht aufgeführt?\n{0}',
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'de'),
    medication_collapsible_form_dose_title:
      'oder erstellen Sie Ihre eigene Dosis',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'de',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      'Darreichungsform des Medikaments wählen',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'de',
    ),
    medication_collapsible_form_strength_units_modal_picker_header:
      'Stärkeeinheit',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'de'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'µg',
    constants_strength_unit_g: 'g',
    constants_strength_unit_mg: 'mg',

    constants_dosage_applications: 'Anwendungen',
    constants_dosage_capsules: 'Kapseln',
    constants_dosage_drops: 'Tropfen',
    constants_dosage_injections: 'Injektionen',
    constants_dosage_ml: 'ml',
    constants_dosage_patches: 'Pflastern',
    constants_dosage_puffs: 'Inhalationen',
    constants_dosage_sprays: 'Sprühstöße',
    constants_dosage_suppositories: 'Zäpfchen',
    constants_dosage_tablets: 'Tabletten',
    constants_dosage_units: 'Einheiten',

    constants_dosage_applications_plural: 'Anwendung(en)',
    constants_dosage_capsules_plural: 'Kapsel(n)',
    constants_dosage_drops_plural: 'Tropfen',
    constants_dosage_injections_plural: 'Injektion(en)',
    constants_dosage_ml_plural: 'ml',
    constants_dosage_patches_plural: 'Pflaster',
    constants_dosage_puffs_plural: 'Inhalation(en)',
    constants_dosage_sprays_plural: 'Sprühstoß/-stöße',
    constants_dosage_suppositories_plural: 'Zäpfchen',
    constants_dosage_tablets_plural: 'Tablette(n)',
    constants_dosage_units_plural: 'Einheit(en)',

    constants_dosage_applications_count_one: '{0} Anwendung',
    constants_dosage_applications_count_other: '{0} Anwendungen',
    constants_dosage_applications_count_many: '{0} Anwendungen',
    constants_dosage_capsules_count_one: '{0} Kapsel',
    constants_dosage_capsules_count_other: '{0} Kapseln',
    constants_dosage_capsules_count_many: '{0} Kapseln',
    constants_dosage_drops_count_one: '{0} Tropfen',
    constants_dosage_drops_count_other: '{0} Tropfen',
    constants_dosage_drops_count_many: '{0} Tropfen',
    constants_dosage_injections_count_one: '{0} Injektion',
    constants_dosage_injections_count_other: '{0} Injektionen',
    constants_dosage_injections_count_many: '{0} Injektionen',
    constants_dosage_ml_count_one: '{0} ml',
    constants_dosage_ml_count_other: '{0} ml',
    constants_dosage_ml_count_many: '{0} ml',
    constants_dosage_patches_count_one: '{0} Pflaster',
    constants_dosage_patches_count_other: '{0} Pflaster',
    constants_dosage_puffs_count_one: '{0} Inhalation',
    constants_dosage_puffs_count_other: '{0} Inhalationen',
    constants_dosage_puffs_count_many: '{0} Inhalationen',
    constants_dosage_sprays_count_one: '{0} Sprühstoß',
    constants_dosage_sprays_count_other: '{0} Sprühstöße',
    constants_dosage_sprays_count_many: '{0} Sprühstöße',
    constants_dosage_suppositories_count_one: '{0} Zäpfchen',
    constants_dosage_suppositories_count_other: '{0} Zäpfchen',
    constants_dosage_suppositories_count_many: '{0} Zäpfchen',
    constants_dosage_tablets_count_one: '{0} Tablette',
    constants_dosage_tablets_count_other: '{0} Tabletten',
    constants_dosage_tablets_count_many: '{0} Tabletten',
    constants_dosage_units_count_one: '{0} Einheit',
    constants_dosage_units_count_other: '{0} Einheiten',
    constants_dosage_units_count_many: '{0} Einheiten',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'de'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      'Schmerzzustand wurde bereits hinzugefügt',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'de'),
    search_pain_condition_add_new_pain_condition_alert_title:
      'Sind Sie sicher, dass Sie Schmerzzustand {0} nicht finden können?',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'de',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'de',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      'Schmerzzustand erfolgreich hinzugefügt',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'de'),
    search_pain_condition_pain_condition_selected_toast_text:
      'Schmerzzustand ausgewählt',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'de',
    ),

    search_pain_condition_header_title: 'Schmerzzustand hinzufügen',
    search_pain_condition_text_before_search_bar:
      'Suchen Sie nach Ihrem Schmerzzustand und wählen Sie ihn aus der angezeigten Ergebnisliste.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'de',
    ),

    search_pain_condition_secondary_text_above_results:
      'Sehen Sie den Schmerzzustand, nach dem Sie suchen?',
    search_pain_condition_secondary_text_above_not_found_results:
      'Schmerzzustand existiert nicht in unserer Datenbank.',
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'de',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'de'),
    search_pain_condition_create_button_text: 'Schmerzzustand hinzufügen',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'de'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'de',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'de',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'de'),
    edit_pain_condition_header_add_new_title: 'Schmerzzustand hinzufügen',
    edit_pain_condition_header_edit_title: 'Schmerzzustand bearbeiten',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'de',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'de',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'de',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'de',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'de',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'de',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title:
      'Schmerzzustand bearbeiten?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      'Dieser Schmerzzustand ist mit {0} Medikament verknüpft, das neu verknüpft wird.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      'Dieser Schmerzzustand ist mit {0} Medikamenten verknüpft, die neu verknüpft werden.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      'Dieser Schmerzzustand ist mit {0} Medikamenten verknüpft, die neu verknüpft werden.',

    edit_pain_condition_saving_overlay_text: 'Speichere Zustand…',
    edit_pain_condition_adding_text: 'Zustand hinzufügen',
    edit_pain_condition_editing_text: 'Zustand bearbeiten',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      'Schmerzzustand erfolgreich hinzugefügt',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'de'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      'Schmerzzustand erfolgreich bearbeitet',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'de'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'de'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: 'Meine Schmerzzustände',
    edit_pain_conditions_back_button_text: shared('Back', 'de'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'de'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'de',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'de',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'de',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'de',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: 'Medikament hinzufügen',
    search_medication_input_label: shared('Medication', 'de'),
    search_medication_text_before_search_bar:
      'Suchen Sie nach dem Namen Ihres Medikaments (ohne Stärke) und wählen Sie es aus der Liste aus.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'de',
    ),
    search_medication_secondary_text_above_results:
      'Ist eines von diesen das Medikament, nach dem Sie suchen?',
    search_medication_secondary_text_above_not_found_results:
      'Medikament existiert nicht in unserer Datenbank.',
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'de',
    ),
    search_medication_request_medication: shared('Request It', 'de'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      'Mobile Anfrage: Fehlendes Medikament',
    search_medication_data_medication_email_first_line:
      'Das Medikament {0} wurde nicht gefunden.',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'de',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: 'Neue Marke hinzufügen',
    search_medication_brand_title:
      'Geben Sie den Markennamen Ihres Medikaments unten ein und wir fügen es hinzu.',
    search_medication_brand_ok_button: shared('OK', 'de'),
    search_medication_brand_label: 'Marke',
    search_medication_brand_selected_medication_label_text: 'Marke von',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'de',
    ),
    search_medication_brand_similar_results_search_helper:
      'Ist eine von diesen die Marke, nach der Sie suchen?',
    search_medication_brand_no_similar_item_search_helper:
      'Marke existiert nicht in unserer Datenbank.',
    search_medication_brand_request_brand_label:
      'Ihre Marke ist nicht aufgeführt?\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'de'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      'Ihre Marke wird hinzugefügt, kurz nachdem wir Ihre Anfrage erhalten haben. In der Zwischenzeit wird Ihre Medikament als Generikum hinzugefügt.',
    search_medication_brand_added_toast: 'Marke erfolgreich hinzugefügt',
    search_medication_brand_selected_toast:
      'Marke erfolgreich für das Medikament ausgewählt',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: 'Medikament hinzufügen',
    edit_medication_header_edit_title: 'Medikament bearbeiten',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'de',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'de',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'de',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'de',
    ),
    edit_medication_saving_overlay_text: 'Speichere Medikament...',
    edit_medication_editing_text: 'Medikamente bearbeiten',
    edit_medication_adding_text: 'Medikamente hinzufügen',
    edit_medication_medication_description_section_header:
      'Beschreibung des Medikaments',
    edit_medication_medication_purposes_section_header: 'Zweck',
    edit_medication_medication_instructions_section_header: 'Anweisungen',
    edit_medication_medication_notes_header: shared('Notes', 'de'),
    edit_medication_medication_successfully_added_toast_text:
      'Medikament erfolgreich hinzugefügt',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'de',
    ),
    edit_medication_medication_successfully_edited_toast_text:
      'Medikament erfolgreich bearbeitet',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'de',
    ),
    edit_medication_medication_duplication_toast_text:
      'Medikament existiert bereits in Ihrem Profil',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'de',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'de',
    ),
    edit_medication_edit_confirmation_pop_up_title: 'Medikament bearbeiten?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      '{0} Eintrag ist mit diesem Medikament verknüpft und wird neu verknüpft.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      '{0} Einträge sind mit diesem Medikament verknüpft und werden neu verknüpft.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      '{0} Einträge sind mit diesem Medikament verknüpft und werden neu verknüpft.',
    edit_medication_apply_button_text: shared('Apply changes', 'de'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: 'Meine Medikamente',
    edit_medications_footer_apply_button: shared('Continue', 'de'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: 'Marke',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'de',
    ),
    medication_description_strength_label: 'Stärke',
    medication_description_strength_error:
      'Geben Sie einen Wert ein, bevor Sie speichern',
    medication_description_form_label: shared('Medication Form', 'de'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'de',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'de',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'de',
    ),
    medication_description_strength_units_picker_header: 'Stärkeeinheit',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'de',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'de',
    ),
    medication_purposes_purposes_list_is_empty: 'Liste der Zwecke ist leer',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      'Ich nehme dieses Medikament aktuell ein',
    medication_instructions_start_date_label: shared('Start Date', 'de'),
    medication_instructions_start_date_datepicker_selecting_text:
      'Startdatum wählen',
    medication_instructions_end_date_label: shared('End Date', 'de'),
    medication_instructions_end_date_datepicker_selecting_text:
      'Enddatum wählen',
    medication_instructions_take_as_header: 'Einnahme nach',
    medication_instructions_dosages_header: shared('Dosage', 'de'),
    medication_instructions_no_dosages_text: 'Es wurde keine Dosis hinzugefügt',
    medication_instructions_take_as_value_needed: 'Bedarf',
    medication_instructions_take_as_value_scheduled: 'Zeitplan',
    medication_instructions_take_as_value_scheduled_and_needed:
      'Zeitplan und Bedarf',

    medication_instructions_frequencies_hourly: 'Stündlich',
    medication_instructions_frequencies_daily: 'Täglich',
    medication_instructions_frequencies_weekly: 'Wöchentlich',
    medication_instructions_frequencies_monthly: 'Monatlich',

    medication_instructions_add_dosage_button_text: 'Dosis hinzufügen',
    medication_instructions_special_instructions_label:
      'Zusätzliche Anweisungen',
    medication_instructions_delete_dosage_confirmation_popup_title:
      'Dosis löschen',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'de'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'de'),
    medication_instructions_add_dosage_modal_header: 'Dosis hinzufügen',
    medication_instructions_edit_dosage_modal_header: 'Dosis bearbeiten',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'de',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'de'),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'de',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      'zwischen {0} und {1}',
    medication_instructions_dosage_time_string_start_time: 'nach {0}',
    medication_instructions_dosage_time_string_end_time: 'vor {0}',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: 'am {0}. Tag des Monats',
    medication_instructions_add_dosage_modal_dosage_number_error:
      'Geben Sie einen Wert ein, bevor Sie speichern',

    medication_instructions_hourly_count_one: 'Stündlich',
    medication_instructions_hourly_count_other: 'Alle {0} Stunden',
    medication_instructions_hourly_count_many: 'Alle {0} Stunden',
    medication_instructions_daily_count_one: 'Täglich',
    medication_instructions_daily_count_other: 'Alle {0} Tage',
    medication_instructions_daily_count_many: 'Alle {0} Tage',
    medication_instructions_weekly_count_one: 'Wöchentlich',
    medication_instructions_weekly_count_other: 'Alle {0} Wochen',
    medication_instructions_weekly_count_many: 'Alle {0} Wochen',
    medication_instructions_monthly_count_one: 'Monatlich',
    medication_instructions_monthly_count_other: 'Alle {0} Monate',
    medication_instructions_monthly_count_many: 'Alle {0} Monate',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'de',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'de'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'de'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'de'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'de'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      'Endzeit kann nicht vor der Startzeit liegen',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'de'),
  },

  es: {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'es'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'es'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'es'),
    pain_condition_list_add_new_button: shared('Add New', 'es'),
    pain_condition_list_no_pain_conditions_text:
      'No se han agregado condiciones de dolor a su perfil',

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title:
      '¿Eliminar condición desencadenante de dolor?',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      'Esta condición desencadenante de dolor está asociada con {0} medicamento, el cual perderá su asociación',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      'Esta condición desencadenante de dolor está asociada con {0} medicamentos, los cuales perderán su asociación',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      'Esta condición desencadenante de dolor está asociada con {0} medicamentos, los cuales perderán su asociación',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'es',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'es',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      'Eliminando condición desencadenante de dolor…',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      'Condición desencadenante de dolor eliminada satisfactoriamente',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'es'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'es'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'es',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'es'),
    medication_list_add_new_button: shared('Add New', 'es'),

    medication_list_delete_medication_overlay_text: 'Eliminando medicamento…',
    medication_list_no_current_medications_text:
      'No se han agregado medicamentos actuales a su perfil',
    medication_list_no_medications_text:
      'No se han agregado medicamentos actuales a su perfil',
    medication_list_current_medications_header: 'Medicamento(s) actual(es):',
    medication_list_outdated_medications_header: 'Medicamento(s) anterior(es):',
    medication_list_edit_button_action_sheet: shared('Edit', 'es'),
    medication_list_delete_button_action_sheet: shared('Delete', 'es'),
    medication_list_delete_confirmation_popup_title: '¿Eliminar medicamento?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      'Hay {0} registro asociado con este medicamento, el cual perderá su asociación.',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      'Hay {0} registros asociados con este medicamento, los cuales perderán su asociación.',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      'Hay {0} registros asociados con este medicamento, los cuales perderán su asociación.',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'es',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'es',
    ),
    medication_list_delete_medication_successful_toast_text:
      'Medicamento eliminado satisfactoriamente',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'es'),
    medication_list_generic_label: shared('Generic', 'es'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'µg',
    medication_collapsible_form_strength_unit_g: 'g',
    medication_collapsible_form_strength_unit_mg: 'mg',

    medication_collapsible_form_add_strength_button: shared('Add', 'es'),
    medication_collapsible_form_choose_brand_placeholder: 'Escoger marca',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'es'),
    medication_collapsible_form_add_new_brand_button_label:
      '¿No puede encontrar su marca?\n{0}',
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'es'),
    medication_collapsible_form_dose_title: 'o cree su propia dosis',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'es',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      'Escoja el formato del medicamento',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'es',
    ),
    medication_collapsible_form_strength_units_modal_picker_header:
      'Unidad de concentración',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'es'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'µg',
    constants_strength_unit_g: 'g',
    constants_strength_unit_mg: 'mg',

    constants_dosage_applications: 'aplicaciones',
    constants_dosage_capsules: 'cápsulas',
    constants_dosage_drops: 'gotas',
    constants_dosage_injections: 'inyecciones',
    constants_dosage_ml: 'mL',
    constants_dosage_patches: 'parches',
    constants_dosage_puffs: 'pulverizaciones',
    constants_dosage_sprays: 'atomizaciones',
    constants_dosage_suppositories: 'supositorios',
    constants_dosage_tablets: 'tabletas',
    constants_dosage_units: 'unidades',

    constants_dosage_applications_plural: 'aplicación(es)',
    constants_dosage_capsules_plural: 'cápsula(s)',
    constants_dosage_drops_plural: 'gota(s)',
    constants_dosage_injections_plural: 'inyección(es)',
    constants_dosage_ml_plural: 'mL',
    constants_dosage_patches_plural: 'parche(es)',
    constants_dosage_puffs_plural: 'pulverización(es)',
    constants_dosage_sprays_plural: 'atomización(es)',
    constants_dosage_suppositories_plural: 'supositorio(s)',
    constants_dosage_tablets_plural: 'tableta(s)',
    constants_dosage_units_plural: 'unidad(es)',

    constants_dosage_applications_count_one: '{0} aplicación',
    constants_dosage_applications_count_other: '{0} aplicaciones',
    constants_dosage_applications_count_many: '{0} aplicaciones',
    constants_dosage_capsules_count_one: '{0} cápsula',
    constants_dosage_capsules_count_other: '{0} cápsulas',
    constants_dosage_capsules_count_many: '{0} cápsulas',
    constants_dosage_drops_count_one: '{0} gota',
    constants_dosage_drops_count_other: '{0} gotas',
    constants_dosage_drops_count_many: '{0} gotas',
    constants_dosage_injections_count_one: '{0} inyección',
    constants_dosage_injections_count_other: '{0} inyecciones',
    constants_dosage_injections_count_many: '{0}inyecciones',
    constants_dosage_ml_count_one: '{0}mL',
    constants_dosage_ml_count_other: '{0}mL',
    constants_dosage_ml_count_many: '{0}mL',
    constants_dosage_patches_count_one: '{0} parche',
    constants_dosage_patches_count_other: '{0} parches',
    constants_dosage_puffs_count_one: '{0} pulverización',
    constants_dosage_puffs_count_other: '{0} pulverizaciones',
    constants_dosage_puffs_count_many: '{0} pulverizaciones',
    constants_dosage_sprays_count_one: '{0} atomización',
    constants_dosage_sprays_count_other: '{0} atomizaciones',
    constants_dosage_sprays_count_many: '{0} atomizaciones',
    constants_dosage_suppositories_count_one: '{0}  supositorio',
    constants_dosage_suppositories_count_other: '{0} supositorios',
    constants_dosage_suppositories_count_many: '{0} supositorios',
    constants_dosage_tablets_count_one: '{0} tableta',
    constants_dosage_tablets_count_other: '{0} tabletas',
    constants_dosage_tablets_count_many: '{0} tabletas',
    constants_dosage_units_count_one: '{0} unidad',
    constants_dosage_units_count_other: '{0} unidades',
    constants_dosage_units_count_many: '{0} unidades',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'es'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      'La condición desencadenante de dolor ya se agregó',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'es'),
    search_pain_condition_add_new_pain_condition_alert_title:
      '¿Está seguro de que no puede encontrar la condición desencadenante de dolor {0}?',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'es',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'es',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      'Condición desencadenante de dolor agregada satisfactoriamente',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'es'),
    search_pain_condition_pain_condition_selected_toast_text:
      'Condición desencadenante de dolor seleccionada',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'es',
    ),

    search_pain_condition_header_title:
      'Agregar condición desencadenante de dolor',
    search_pain_condition_text_before_search_bar:
      'Busque su condición desencadenante de dolor y selecciónela en la lista que aparece.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'es',
    ),

    search_pain_condition_secondary_text_above_results:
      '¿Puede ver la condición desencadenante de dolor que está buscando?',
    search_pain_condition_secondary_text_above_not_found_results:
      'La condición desencadenante de dolor no existe en nuestra base de datos',
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'es',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'es'),
    search_pain_condition_create_button_text:
      'Agregar condición desencadenante de dolor',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'es'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'es',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'es',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'es'),
    edit_pain_condition_header_add_new_title:
      'Agregar condición desencadenante de dolor',
    edit_pain_condition_header_edit_title:
      'Editar condición desencadenante de dolor',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'es',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'es',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'es',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'es',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'es',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'es',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title:
      '¿Editar condición desencadenante de dolor?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      'Esta condición desencadenante de dolor está asociada con {0} medicamento, el cual se asociará nuevamente',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      'Esta condición desencadenante de dolor está asociada con {0} medicamentos, los cuales se asociarán nuevamente',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      'Esta condición desencadenante de dolor está asociada con {0} medicamentos, los cuales se asociarán nuevamente',

    edit_pain_condition_saving_overlay_text: 'Guardando condición…',
    edit_pain_condition_adding_text: 'Añadiendo condición',
    edit_pain_condition_editing_text: 'Editando condición',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      'Condición desencadenante de dolor agregada satisfactoriamente',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'es'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      'Condición desencadenante de dolor editada satisfactoriamente',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'es'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'es'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title:
      'Mis condiciones desencadenantes de dolor',
    edit_pain_conditions_back_button_text: shared('Back', 'es'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'es'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'es',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'es',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'es',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'es',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: 'Agregar medicamento',
    search_medication_input_label: shared('Medication', 'es'),
    search_medication_text_before_search_bar:
      'Busque el nombre de su medicamento (sin la potencia) y selecciónelo en la lista de resultados.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'es',
    ),
    search_medication_secondary_text_above_results:
      '¿Alguno de estos es el medicamento que busca?',
    search_medication_secondary_text_above_not_found_results:
      'El medicamento no existe en nuestra base de datos.',
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'es',
    ),
    search_medication_request_medication: shared('Request It', 'es'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      'Solicitud móvil: medicamento faltante',
    search_medication_data_medication_email_first_line:
      'El siguiente medicamento, {0}, no se encontró.',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'es',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: 'Agregar nueva marca',
    search_medication_brand_title:
      'Escriba la marca de su medicamento abajo y la agregaremos.',
    search_medication_brand_ok_button: shared('OK', 'es'),
    search_medication_brand_label: 'Marca',
    search_medication_brand_selected_medication_label_text: 'Marca de',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'es',
    ),
    search_medication_brand_similar_results_search_helper:
      '¿Alguna de estas es la marca que busca?',
    search_medication_brand_no_similar_item_search_helper:
      'La marca no existe en nuestra base de datos.',
    search_medication_brand_request_brand_label:
      '¿No puede encontrar su marca?\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'es'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      'Su marca se agregará poco después de que recibamos su solicitud. Por ahora, su medicamento se agregará como Genérico.',
    search_medication_brand_added_toast: 'Marca agregada satisfactoriamente',
    search_medication_brand_selected_toast:
      'Marca seleccionada satisfactoriamente para el medicamento',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: 'Agregar medicamento',
    edit_medication_header_edit_title: 'Editar medicamento',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'es',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'es',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'es',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'es',
    ),
    edit_medication_saving_overlay_text: 'Guardando medicamento…',
    edit_medication_editing_text: 'Editando medicamento',
    edit_medication_adding_text: 'Añadiendo medicamento',
    edit_medication_medication_description_section_header:
      'Descripción del medicamento',
    edit_medication_medication_purposes_section_header: 'Objetivo',
    edit_medication_medication_instructions_section_header: 'Instrucciones',
    edit_medication_medication_notes_header: shared('Notes', 'es'),
    edit_medication_medication_successfully_added_toast_text:
      'Medicamento agregado satisfactoriamente',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'es',
    ),
    edit_medication_medication_successfully_edited_toast_text:
      'Medicamento editado satisfactoriamente',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'es',
    ),
    edit_medication_medication_duplication_toast_text:
      'Medicamento ya existe en su perfil',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'es',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'es',
    ),
    edit_medication_edit_confirmation_pop_up_title: '¿Editar medicamento?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      'Hay {0} registro asociado con este medicamento, el cual se asociará nuevamente.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      'Hay {0} registros asociados con este medicamento, los cuales se asociarán nuevamente.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      'Hay {0} registros asociados con este medicamento, los cuales se asociarán nuevamente.',
    edit_medication_apply_button_text: shared('Apply changes', 'es'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: 'Mis medicamentos',
    edit_medications_footer_apply_button: shared('Continue', 'es'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: 'Marca',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'es',
    ),
    medication_description_strength_label: 'Concentración',
    medication_description_strength_error: 'Introduzca valor antes de guardar',
    medication_description_form_label: shared('Medication Form', 'es'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'es',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'es',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'es',
    ),
    medication_description_strength_units_picker_header:
      'Unidad de concentración',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'es',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'es',
    ),
    medication_purposes_purposes_list_is_empty:
      'La lista de objetivos está vacía',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      'Estoy tomando este medicamento',
    medication_instructions_start_date_label: shared('Start Date', 'es'),
    medication_instructions_start_date_datepicker_selecting_text:
      'Seleccione fecha de inicio',
    medication_instructions_end_date_label: shared('End Date', 'es'),
    medication_instructions_end_date_datepicker_selecting_text:
      'Seleccione fecha de fin',
    medication_instructions_take_as_header: 'Tomar como',
    medication_instructions_dosages_header: shared('Dosage', 'es'),
    medication_instructions_no_dosages_text: 'No se agregaron dosis',
    medication_instructions_take_as_value_needed: 'Necesario',
    medication_instructions_take_as_value_scheduled: 'Programado',
    medication_instructions_take_as_value_scheduled_and_needed:
      'Programado y necesario',

    medication_instructions_frequencies_hourly: 'Por hora',
    medication_instructions_frequencies_daily: 'Diariamente',
    medication_instructions_frequencies_weekly: 'Semanalmente',
    medication_instructions_frequencies_monthly: 'Mensualmente',

    medication_instructions_add_dosage_button_text: 'Agregar dosis',
    medication_instructions_special_instructions_label:
      'Instrucciones adicionales',
    medication_instructions_delete_dosage_confirmation_popup_title:
      'Eliminar dosis',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'es'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'es'),
    medication_instructions_add_dosage_modal_header: 'Agregar dosis',
    medication_instructions_edit_dosage_modal_header: 'Editar dosis',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'es',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'es'),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'es',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      'Entre {0} y {1}',
    medication_instructions_dosage_time_string_start_time: 'después de {0}',
    medication_instructions_dosage_time_string_end_time: 'antes de {0}',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: 'el día {0} del mes',
    medication_instructions_add_dosage_modal_dosage_number_error:
      'Introduzca valor antes de guardar',

    medication_instructions_hourly_count_one: 'Por hora',
    medication_instructions_hourly_count_other: 'Cada {0} horas',
    medication_instructions_hourly_count_many: 'Cada {0} horas',
    medication_instructions_daily_count_one: 'Diariamente',
    medication_instructions_daily_count_other: 'Cada {0} días',
    medication_instructions_daily_count_many: 'Cada {0} días',
    medication_instructions_weekly_count_one: 'Semanalmente',
    medication_instructions_weekly_count_other: 'Cada {0} semanas',
    medication_instructions_weekly_count_many: 'Cada {0} semanas',
    medication_instructions_monthly_count_one: 'Mensualmente',
    medication_instructions_monthly_count_other: 'Cada {0} meses',
    medication_instructions_monthly_count_many: 'Cada {0} meses',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'es',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'es'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'es'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'es'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'es'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      'La hora de fin debe ser igual o posterior a la hora de inicio',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'es'),
  },

  fr: {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'fr'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'fr'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'fr'),
    pain_condition_list_add_new_button: shared('Add New', 'fr'),
    pain_condition_list_no_pain_conditions_text:
      "Vous n'avez pas ajouté aucune condition douloureuse à votre profil",

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title:
      'Supprimer la condition douloureuse ?',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      'Cette condition douloureuse est associée à {0} médicaments, qui perdront leur association.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      'Cette condition douloureuse est associée à {0} médicaments, qui perdront leur association.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      'Cette condition douloureuse est associée à {0} médicaments, qui perdront leur association.',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'fr',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      'Suppression de condition douloureuse en cours…',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      'Condition douloureuse supprimée avec succès',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'fr'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'fr'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'fr',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'fr'),
    medication_list_add_new_button: shared('Add New', 'fr'),

    medication_list_delete_medication_overlay_text:
      'Suppression du médicament en cours…',
    medication_list_no_current_medications_text:
      "Vous n'avez pas ajouté un médicament actuel dans votre profil",
    medication_list_no_medications_text:
      "Vous n'avez pas ajouté des médicaments actuels dans votre profil",
    medication_list_current_medications_header: 'Médicament(s) actuel(s):',
    medication_list_outdated_medications_header: 'Médicament(s) antérieur(s):',
    medication_list_edit_button_action_sheet: shared('Edit', 'fr'),
    medication_list_delete_button_action_sheet: shared('Delete', 'fr'),
    medication_list_delete_confirmation_popup_title:
      'Supprimer le médicament ?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      'Il y a {0} enregistrement associé avec ce médicament qui va perdre son association',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      'Il y a {0} enregistrements associés avec ce médicament qui vont perdre leur association',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      'Il y a {0} enregistrements associés avec ce médicament qui vont perdre leur association',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'fr',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    medication_list_delete_medication_successful_toast_text:
      'Médicament supprimé avec succès',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'fr'),
    medication_list_generic_label: shared('Generic', 'fr'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'µg',
    medication_collapsible_form_strength_unit_g: 'g',
    medication_collapsible_form_strength_unit_mg: 'mg',

    medication_collapsible_form_add_strength_button: shared('Add', 'fr'),
    medication_collapsible_form_choose_brand_placeholder: 'Choisir la marque',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'fr'),
    medication_collapsible_form_add_new_brand_button_label:
      'Vous ne trouvez-pas le marque que vous cherchiez ?\n{0}',
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'fr'),
    medication_collapsible_form_dose_title: 'ou créez votre propre dosage',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'fr',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      'Veuillez choisir la forme du médicament',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'fr',
    ),
    medication_collapsible_form_strength_units_modal_picker_header:
      'Unité de concentration',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'fr'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'µg',
    constants_strength_unit_g: 'g',
    constants_strength_unit_mg: 'mg',

    constants_dosage_applications: 'applications',
    constants_dosage_capsules: 'gélules',
    constants_dosage_drops: 'gouttes',
    constants_dosage_injections: 'injections',
    constants_dosage_ml: 'mL',
    constants_dosage_patches: 'patchs',
    constants_dosage_puffs: 'inhalations',
    constants_dosage_sprays: 'pulvérisations',
    constants_dosage_suppositories: 'suppositoires',
    constants_dosage_tablets: 'comprimés',
    constants_dosage_units: 'unités',

    constants_dosage_applications_plural: 'application(s)',
    constants_dosage_capsules_plural: 'gélule(s)',
    constants_dosage_drops_plural: 'goutte(s)',
    constants_dosage_injections_plural: 'injection(s)',
    constants_dosage_ml_plural: 'mL',
    constants_dosage_patches_plural: 'patch(s)',
    constants_dosage_puffs_plural: 'inhalation(s)',
    constants_dosage_sprays_plural: 'pulvérisation(s)',
    constants_dosage_suppositories_plural: 'suppositoire(s)',
    constants_dosage_tablets_plural: 'comprimé(s)',
    constants_dosage_units_plural: 'unité(s)',

    constants_dosage_applications_count_one: '{0} application',
    constants_dosage_applications_count_other: '{0} applications',
    constants_dosage_applications_count_many: '{0} applications',
    constants_dosage_capsules_count_one: '{0} gélule',
    constants_dosage_capsules_count_other: '{0} gélules',
    constants_dosage_capsules_count_many: '{0} gélules',
    constants_dosage_drops_count_one: '{0} goutte',
    constants_dosage_drops_count_other: '{0} gouttes',
    constants_dosage_drops_count_many: '{0} gouttes',
    constants_dosage_injections_count_one: '{0} injection',
    constants_dosage_injections_count_other: '{0} injections',
    constants_dosage_injections_count_many: '{0} injections',
    constants_dosage_ml_count_one: '{0}mL',
    constants_dosage_ml_count_other: '{0}mL',
    constants_dosage_ml_count_many: '{0}mL',
    constants_dosage_patches_count_one: '{0} patch',
    constants_dosage_patches_count_other: '{0} patchs',
    constants_dosage_puffs_count_one: '{0} inhalation',
    constants_dosage_puffs_count_other: '{0} inhalations',
    constants_dosage_puffs_count_many: '{0} inhalations',
    constants_dosage_sprays_count_one: '{0} pulvérisation',
    constants_dosage_sprays_count_other: '{0} pulvérisations',
    constants_dosage_sprays_count_many: '{0} pulvérisations',
    constants_dosage_suppositories_count_one: '{0}  suppositoire',
    constants_dosage_suppositories_count_other: '{0}  suppositoires',
    constants_dosage_suppositories_count_many: '{0}  suppositoires',
    constants_dosage_tablets_count_one: '{0} comprimé',
    constants_dosage_tablets_count_other: '{0} comprimés',
    constants_dosage_tablets_count_many: '{0} comprimés',
    constants_dosage_units_count_one: '{0} unité',
    constants_dosage_units_count_other: '{0} unités',
    constants_dosage_units_count_many: '{0} unités',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'fr'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      'La condition douloureuse a été déjà ajoutée',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'fr'),
    search_pain_condition_add_new_pain_condition_alert_title:
      'Êtes-vous sûr de ne pas pouvoir trouver la condition douloureuse ?',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'fr',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'fr',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      'Condition douloureuse ajoutée avec succès',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'fr'),
    search_pain_condition_pain_condition_selected_toast_text:
      'Condition douloureuse sélectionnée',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'fr',
    ),

    search_pain_condition_header_title: 'Ajouter une condition douloureuse',
    search_pain_condition_text_before_search_bar:
      'Recherchez votre condition douloureuse et sélectionnez-la dans la liste des résultats.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'fr',
    ),

    search_pain_condition_secondary_text_above_results:
      'Voyez-vous la condition douloureuse que vous cherchiez ?',
    search_pain_condition_secondary_text_above_not_found_results:
      "La condition douloureuse n'existe pas dans notre base de données",
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'fr',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'fr'),
    search_pain_condition_create_button_text:
      'Ajouter une condition douloureuse',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'fr'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'fr',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'fr',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'fr'),
    edit_pain_condition_header_add_new_title:
      'Ajouter une condition douloureuse',
    edit_pain_condition_header_edit_title: 'Modifier la condition douloureuse',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'fr',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'fr',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'fr',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title:
      'Modifier la condition douloureuse ?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      'Cette condition douloureuse est associée avec {0} médicament qui sera associé à nouveau',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      'Cette condition douloureuse est associée avec {0} médicaments qui seront associés à nouveau',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      'Cette condition douloureuse est associée avec {0} médicaments qui seront associés à nouveau',

    edit_pain_condition_saving_overlay_text:
      'Sauvegarde de condition douloureuse en cours…',
    edit_pain_condition_adding_text: "Ajout d'une condition",
    edit_pain_condition_editing_text: "Modification d'une condition",
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      'Condition douloureuse ajoutée avec succès',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'fr'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      'Condition douloureuse modifiée avec succès',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'fr'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'fr'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: 'Mes conditions douloureuses',
    edit_pain_conditions_back_button_text: shared('Back', 'fr'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'fr'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'fr',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'fr',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: 'Ajouter un médicament',
    search_medication_input_label: shared('Medication', 'fr'),
    search_medication_text_before_search_bar:
      'Recherchez le nom de votre médicament (sans le dosage) et sélectionnez-le dans la liste affichée.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'fr',
    ),
    search_medication_secondary_text_above_results:
      'Voyez-vous le médicament que vous cherchiez ?',
    search_medication_secondary_text_above_not_found_results:
      "Le médicament n'existe pas dans notre base de données",
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'fr',
    ),
    search_medication_request_medication: shared('Request It', 'fr'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      'Requête mobile: médicament manquant',
    search_medication_data_medication_email_first_line:
      "Le médicament suivant, {0}, n'a pas été trouvé.",
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'fr',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: 'Ajouter nouvelle marque',
    search_medication_brand_title:
      "Écrivez la marque de votre médicament ci-dessous et nous l'ajouterons",
    search_medication_brand_ok_button: shared('OK', 'fr'),
    search_medication_brand_label: 'Marque',
    search_medication_brand_selected_medication_label_text: 'Marque de',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'fr',
    ),
    search_medication_brand_similar_results_search_helper:
      'Voyez-vous la marque que vous cherchiez ?',
    search_medication_brand_no_similar_item_search_helper:
      "La marque n'existe pas dans notre base de données.",
    search_medication_brand_request_brand_label:
      'Vous ne trouvez-pas le marque que vous cherchiez ?\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'fr'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      'Votre marque sera ajoutée peut après la réception de votre requête. Pour le moment, votre médicament sera ajouté comme Générique.',
    search_medication_brand_added_toast: 'Marque ajoutée avec succès',
    search_medication_brand_selected_toast:
      'Marque sélectionnée avec succès pour le médicament',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: 'Ajouter médicament',
    edit_medication_header_edit_title: 'Modifier médicament',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'fr',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'fr',
    ),
    edit_medication_saving_overlay_text: 'Sauvegarde de médicament en cours…',
    edit_medication_editing_text: 'Modification des médicaments',
    edit_medication_adding_text: 'Ajout de médicaments',
    edit_medication_medication_description_section_header:
      'Description du médicament',
    edit_medication_medication_purposes_section_header: 'Objectif',
    edit_medication_medication_instructions_section_header: 'Instructions',
    edit_medication_medication_notes_header: shared('Notes', 'fr'),
    edit_medication_medication_successfully_added_toast_text:
      'Médicament ajouté avec succès',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'fr',
    ),
    edit_medication_medication_successfully_edited_toast_text:
      'Médicament modifié avec succès',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'fr',
    ),
    edit_medication_medication_duplication_toast_text:
      'Médicament existe déjà dans votre profil',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'fr',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'fr',
    ),
    edit_medication_edit_confirmation_pop_up_title: 'Modifier médicament ?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      'Il y a {0} enregistrement associé avec ce médicament qui seront associés à nouveau.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      'Il y a {0} enregistrements associés avec ce médicament qui seront associés à nouveau.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      'Il y a {0} enregistrements associés avec ce médicament qui seront associés à nouveau.',
    edit_medication_apply_button_text: shared('Apply changes', 'fr'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: 'Mes médicaments',
    edit_medications_footer_apply_button: shared('Continue', 'fr'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: 'Marque',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'fr',
    ),
    medication_description_strength_label: 'Concentration',
    medication_description_strength_error:
      'Entrez une valeur avant de sauvegarder',
    medication_description_form_label: shared('Medication Form', 'fr'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'fr',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'fr',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'fr',
    ),
    medication_description_strength_units_picker_header:
      'Unité de concentration',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'fr',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'fr',
    ),
    medication_purposes_purposes_list_is_empty: "La liste d'objectifs est vide",

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      'Je prends actuellement ce médicament',
    medication_instructions_start_date_label: shared('Start Date', 'fr'),
    medication_instructions_start_date_datepicker_selecting_text:
      'Sélectionnez la date de début',
    medication_instructions_end_date_label: shared('End Date', 'fr'),
    medication_instructions_end_date_datepicker_selecting_text:
      'Sélectionnez la date de fin',
    medication_instructions_take_as_header: 'Prendre comme',
    medication_instructions_dosages_header: shared('Dosage', 'fr'),
    medication_instructions_no_dosages_text: 'Aucun dosage ajouté',
    medication_instructions_take_as_value_needed: 'Nécessaire',
    medication_instructions_take_as_value_scheduled: 'Programmé',
    medication_instructions_take_as_value_scheduled_and_needed:
      'Programmé et nécessaire',

    medication_instructions_frequencies_hourly: 'Par heure',
    medication_instructions_frequencies_daily: 'Par jour',
    medication_instructions_frequencies_weekly: 'Par semaine',
    medication_instructions_frequencies_monthly: 'Mensuellement',

    medication_instructions_add_dosage_button_text: 'Ajouter dosage',
    medication_instructions_special_instructions_label:
      'Instructions additionnelles',
    medication_instructions_delete_dosage_confirmation_popup_title:
      'Supprimer dosage',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'fr'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'fr'),
    medication_instructions_add_dosage_modal_header: 'Ajouter dosage',
    medication_instructions_edit_dosage_modal_header: 'Modifier dosage',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'fr',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'fr'),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'fr',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      'Entre {0} et {1}',
    medication_instructions_dosage_time_string_start_time: 'après {0}',
    medication_instructions_dosage_time_string_end_time: 'avant {0}',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: 'le jour {0} du mois',
    medication_instructions_add_dosage_modal_dosage_number_error:
      'Entres une valeur avant de sauvegarder',

    medication_instructions_hourly_count_one: 'Par heure',
    medication_instructions_hourly_count_other: 'Chaque {0} heures',
    medication_instructions_hourly_count_many: 'Chaque {0} heures',
    medication_instructions_daily_count_one: 'Par jour',
    medication_instructions_daily_count_other: 'Chaque {0} jours',
    medication_instructions_daily_count_many: 'Chaque {0} jours',
    medication_instructions_weekly_count_one: 'Par semaine',
    medication_instructions_weekly_count_other: 'Chaque {0} semaines',
    medication_instructions_weekly_count_many: 'Every {0} weeks',
    medication_instructions_monthly_count_one: 'Mensuellement',
    medication_instructions_monthly_count_other: 'Chaque {0} mois',
    medication_instructions_monthly_count_many: 'Chaque {0} mois',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'fr'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'fr'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'fr'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'fr'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      "L'heure de fin doit être égale ou postérieure à l'heure de début",

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'fr'),
  },

  ko: {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'ko'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'ko'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'ko'),
    pain_condition_list_add_new_button: shared('Add New', 'ko'),
    pain_condition_list_no_pain_conditions_text:
      '프로필에 통증 상태가 추가되지 않았습니다',

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title:
      '통증 상태를 삭제합니까?',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      '이 통증 상태는 {0}개의 약물과 연관되어 있으며, 이로 인해 관련성이 손실됩니다.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      '이 통증 상태는 {0}개의 약물과 연관되어 있으며, 이로 인해 관련성이 손실됩니다.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      '이 통증 상태는 {0}개의 약물과 연관되어 있으며, 이로 인해 관련성이 손실됩니다.',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'ko',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      '통증 상태를 삭제하는 중입니다...',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      '통증 상태가 삭제되었습니다',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'ko'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'ko'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'ko',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'ko'),
    medication_list_add_new_button: shared('Add New', 'ko'),

    medication_list_delete_medication_overlay_text: '약을 삭제하는 중입니다...',
    medication_list_no_current_medications_text:
      '현재 복용 중인 약이 프로필에 추가되지 않았습니다',
    medication_list_no_medications_text: '프로필에 약이 추가되지 않았습니다',
    medication_list_current_medications_header: '현재 약:',
    medication_list_outdated_medications_header: '이전 약:',
    medication_list_edit_button_action_sheet: shared('Edit', 'ko'),
    medication_list_delete_button_action_sheet: shared('Delete', 'ko'),
    medication_list_delete_confirmation_popup_title: '약을 삭제합니까?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      '이 약과 관련된 기록은 {0}개이며, 이로 인해 관련성이 손실됩니다.',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      '이 약과 관련된 기록은 {0}개이며, 이로 인해 관련성이 손실됩니다.',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      '이 약과 관련된 기록은 {0}개이며, 이로 인해 관련성이 손실됩니다.',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'ko',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    medication_list_delete_medication_successful_toast_text:
      '약이 성공적으로 삭제되었습니다',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'ko'),
    medication_list_generic_label: shared('Generic', 'ko'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'µg',
    medication_collapsible_form_strength_unit_g: 'g',
    medication_collapsible_form_strength_unit_mg: 'mg',

    medication_collapsible_form_add_strength_button: shared('Add', 'ko'),
    medication_collapsible_form_choose_brand_placeholder: '브랜드 선택하기',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'ko'),
    medication_collapsible_form_add_new_brand_button_label:
      '당신의 브랜드를 찾을 수 없나요?\n{0}',
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'ko'),
    medication_collapsible_form_dose_title: '아니면 당신만의 복용량을 만드세요',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'ko',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      '약물 형식을 선택합니다',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'ko',
    ),
    medication_collapsible_form_strength_units_modal_picker_header: '강도 단위',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'ko'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'µg',
    constants_strength_unit_g: 'g',
    constants_strength_unit_mg: 'mg',

    constants_dosage_applications: '밴드',
    constants_dosage_capsules: '캡슐',
    constants_dosage_drops: '방울',
    constants_dosage_injections: '주사',
    constants_dosage_ml: 'mL',
    constants_dosage_patches: '패치',
    constants_dosage_puffs: '흡입제',
    constants_dosage_sprays: '스프레이',
    constants_dosage_suppositories: '좌약',
    constants_dosage_tablets: '알약',
    constants_dosage_units: '단위',

    constants_dosage_applications_plural: '밴드',
    constants_dosage_capsules_plural: '캡슐',
    constants_dosage_drops_plural: '방울',
    constants_dosage_injections_plural: '주사',
    constants_dosage_ml_plural: 'mL',
    constants_dosage_patches_plural: '패치',
    constants_dosage_puffs_plural: '흡입제',
    constants_dosage_sprays_plural: '스프레이',
    constants_dosage_suppositories_plural: '좌약',
    constants_dosage_tablets_plural: '알약',
    constants_dosage_units_plural: '단위',

    constants_dosage_applications_count_one: '밴드 {0}',
    constants_dosage_applications_count_other: '밴드 {0}',
    constants_dosage_applications_count_many: '밴드 {0}',
    constants_dosage_capsules_count_one: '{0} 캡슐',
    constants_dosage_capsules_count_other: '{0} 캡슐',
    constants_dosage_capsules_count_many: '{0} 캡슐',
    constants_dosage_drops_count_one: '{0} 방울',
    constants_dosage_drops_count_other: '{0} 방울',
    constants_dosage_drops_count_many: '{0} 방울',
    constants_dosage_injections_count_one: '{0} 주사',
    constants_dosage_injections_count_other: '{0} 주사',
    constants_dosage_injections_count_many: '{0} 주사',
    constants_dosage_ml_count_one: '{0}mL',
    constants_dosage_ml_count_other: '{0}mL',
    constants_dosage_ml_count_many: '{0}mL',
    constants_dosage_patches_count_one: '{0} 패치',
    constants_dosage_patches_count_other: '{0} 패치',
    constants_dosage_puffs_count_one: '{0} 흡입제',
    constants_dosage_puffs_count_other: '{0} 흡입제',
    constants_dosage_puffs_count_many: '{0} 흡입제',
    constants_dosage_sprays_count_one: '{0} 스프레이',
    constants_dosage_sprays_count_other: '{0} 스프레이',
    constants_dosage_sprays_count_many: '{0} 스프레이',
    constants_dosage_suppositories_count_one: '{0} 좌약',
    constants_dosage_suppositories_count_other: '{0} 좌약',
    constants_dosage_suppositories_count_many: '{0} 좌약',
    constants_dosage_tablets_count_one: '{0} 알약',
    constants_dosage_tablets_count_other: '{0} 알약',
    constants_dosage_tablets_count_many: '{0} 알약',
    constants_dosage_units_count_one: '{0} 단위',
    constants_dosage_units_count_other: '{0} 단위',
    constants_dosage_units_count_many: '{0} 단위',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'ko'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      '통증 상태가 이미 추가되었습니다',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'ko'),
    search_pain_condition_add_new_pain_condition_alert_title:
      '{0} 통증 상태를 찾을 수 없습니까?',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'ko',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'ko',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      '통증 상태가 추가되었습니다',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'ko'),
    search_pain_condition_pain_condition_selected_toast_text:
      '통증 상태가 선택되었습니다',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'ko',
    ),

    search_pain_condition_header_title: '통증 상태를 추가합니다',
    search_pain_condition_text_before_search_bar:
      '통증 상태를 검색하고 결과 목록에서 선택합니다.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'ko',
    ),

    search_pain_condition_secondary_text_above_results:
      '당신이 찾고 있는 통증 상태가 보이나요?',
    search_pain_condition_secondary_text_above_not_found_results:
      '저희 데이터베이스에 통증 상태가 없습니다.',
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'ko',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'ko'),
    search_pain_condition_create_button_text: '통증 상태를 추가합니다',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'ko'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'ko',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'ko',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'ko'),
    edit_pain_condition_header_add_new_title: '통증 상태를 추가합니다',
    edit_pain_condition_header_edit_title: '통증 상태 편집',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'ko',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'ko',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'ko',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title:
      '통증 상태를 편집합니까?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      '이 통증 상태는 {0}개의 약물과 연관되어 있으며, 다시 연관됩니다.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      '이 통증 상태는 {0}개의 약물과 관련이 있으며, 이는 다시 연관됩니다.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      '이 통증 상태는 {0}개의 약물과 관련이 있으며, 이는 다시 연관됩니다.',

    edit_pain_condition_saving_overlay_text: '상태 저장 중…',
    edit_pain_condition_adding_text: '통증 질환 추가 중',
    edit_pain_condition_editing_text: '통증 질환 편집 중',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      '통증 상태가 추가되었습니다',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'ko'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      '통증 상태를 성공적으로 편집함',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'ko'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'ko'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: '나의 통증 상태',
    edit_pain_conditions_back_button_text: shared('Back', 'ko'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'ko'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'ko',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'ko',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: '약 추가',
    search_medication_input_label: shared('Medication', 'ko'),
    search_medication_text_before_search_bar:
      '복용량을 제외한 약물의 명칭을 검색한 후, 결과 목록에서 선택하세요.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'ko',
    ),
    search_medication_secondary_text_above_results:
      '이 약들 중 당신이 찾고 있는 것이 있나요?',
    search_medication_secondary_text_above_not_found_results:
      '우리 데이터베이스에 약이 없습니다.',
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'ko',
    ),
    search_medication_request_medication: shared('Request It', 'ko'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      '모바일 요청: 약품이 누락되었습니다',
    search_medication_data_medication_email_first_line:
      '다음 약물인 {0}을(를) 찾을 수 없습니다.',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'ko',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: '새 브랜드 추가',
    search_medication_brand_title:
      '아래에 약의 브랜드를 입력하시면 추가해 드리겠습니다.',
    search_medication_brand_ok_button: shared('OK', 'ko'),
    search_medication_brand_label: '브랜드',
    search_medication_brand_selected_medication_label_text: '브랜드',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'ko',
    ),
    search_medication_brand_similar_results_search_helper:
      '이 브랜드들 중 당신이 찾고 있는 브랜드는 있습니까?',
    search_medication_brand_no_similar_item_search_helper:
      '저희 데이터베이스에 브랜드가 없습니다.',
    search_medication_brand_request_brand_label:
      '당신의 브랜드를 찾을 수 없나요?\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'ko'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      '귀하의 브랜드는 귀하의 요청을 받은 직후에 추가될 것입니다. 그 동안, 당신의 약은 Generic으로 추가될 것입니다.',
    search_medication_brand_added_toast: '브랜드가 추가되었습니다',
    search_medication_brand_selected_toast: '약품에 대한 브랜드를 선택했습니다',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: '의약품 추가',
    edit_medication_header_edit_title: '의약품 편집',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'ko',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'ko',
    ),
    edit_medication_saving_overlay_text: '약을 저장하는 중...',
    edit_medication_editing_text: '약품 편집 중',
    edit_medication_adding_text: '약품 추가 중',
    edit_medication_medication_description_section_header: '의약품 설명',
    edit_medication_medication_purposes_section_header: '목적',
    edit_medication_medication_instructions_section_header: '지침',
    edit_medication_medication_notes_header: shared('Notes', 'ko'),
    edit_medication_medication_successfully_added_toast_text: '약 추가 성공',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'ko',
    ),
    edit_medication_medication_successfully_edited_toast_text: '약 편집 성공',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'ko',
    ),
    edit_medication_medication_duplication_toast_text:
      '약은 이미 프로필에 추가되었습니다',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'ko',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'ko',
    ),
    edit_medication_edit_confirmation_pop_up_title: '약을 편집합니까?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      '이 약과 관련된 기록은 {0}개이며, 다시 연관됩니다.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      '이 약과 관련된 기록은 {0}개이며, 다시 연관됩니다.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      '이 약과 관련된 기록은 {0}개이며, 다시 연관됩니다.',
    edit_medication_apply_button_text: shared('Apply changes', 'ko'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: '내 의약품',
    edit_medications_footer_apply_button: shared('Continue', 'ko'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: '브랜드',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'ko',
    ),
    medication_description_strength_label: '강도',
    medication_description_strength_error: '저장 전에 값을 입력하세요',
    medication_description_form_label: shared('Medication Form', 'ko'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'ko',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'ko',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'ko',
    ),
    medication_description_strength_units_picker_header: '강도 단위',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'ko',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'ko',
    ),
    medication_purposes_purposes_list_is_empty: '목적 목록이 비어있습니다',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      '저는 이 약을 먹고 있습니다',
    medication_instructions_start_date_label: shared('Start Date', 'ko'),
    medication_instructions_start_date_datepicker_selecting_text:
      '시작일자선택',
    medication_instructions_end_date_label: shared('End Date', 'ko'),
    medication_instructions_end_date_datepicker_selecting_text: '종료일 선택',
    medication_instructions_take_as_header: '섭취 방식',
    medication_instructions_dosages_header: shared('Dosage', 'ko'),
    medication_instructions_no_dosages_text: '복용량이 추가되지 않음',
    medication_instructions_take_as_value_needed: '필요할 때',
    medication_instructions_take_as_value_scheduled: '평소 일정에 따름',
    medication_instructions_take_as_value_scheduled_and_needed:
      '필요할 때 일정에 따름',

    medication_instructions_frequencies_hourly: '1시간마다',
    medication_instructions_frequencies_daily: '매일',
    medication_instructions_frequencies_weekly: '매주',
    medication_instructions_frequencies_monthly: '매월',

    medication_instructions_add_dosage_button_text: '복용량 정보 추가',
    medication_instructions_special_instructions_label: '추가 지침',
    medication_instructions_delete_dosage_confirmation_popup_title: '용량 삭제',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'ko'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'ko'),
    medication_instructions_add_dosage_modal_header: '복용량 정보 추가',
    medication_instructions_edit_dosage_modal_header: '용량 편집',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'ko',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'ko'),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'ko',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      '{0}~{1}',
    medication_instructions_dosage_time_string_start_time: '{0} 이후',
    medication_instructions_dosage_time_string_end_time: '{0} 이전',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: '매월 {0}일에',
    medication_instructions_add_dosage_modal_dosage_number_error:
      '입력전에 값을 입력하세요',

    medication_instructions_hourly_count_one: '1시간마다',
    medication_instructions_hourly_count_other: '매 {0}시간',
    medication_instructions_hourly_count_many: '매 {0}시간',
    medication_instructions_daily_count_one: '매일',
    medication_instructions_daily_count_other: '매 {0}일',
    medication_instructions_daily_count_many: '매 {0}일',
    medication_instructions_weekly_count_one: '매주',
    medication_instructions_weekly_count_other: '매 {0}주',
    medication_instructions_weekly_count_many: '매 {0}주',
    medication_instructions_monthly_count_one: '매월',
    medication_instructions_monthly_count_other: '매 {0}개월',
    medication_instructions_monthly_count_many: '매 {0}개월',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'ko'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'ko'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'ko'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'ko'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      '종료 시간은 시작 시간과 같거나 그 이상이어야 함',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'ko'),
  },

  ru: {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'ru'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'ru'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'ru'),
    pain_condition_list_add_new_button: shared('Add New', 'ru'),
    pain_condition_list_no_pain_conditions_text:
      'В ваш профиль не добавлены болевые синдромы',

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title:
      'Удалить болевой синдром?',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      'Этот болевой синдром связан с {0} препаратом и эта связь будет потеряна.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      'Этот болевой синдром связан с {0} препаратами и эта связь будет потеряна.',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      'Этот болевой синдром связан с {0} препаратами и эта связь будет потеряна.',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'ru',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      'Удаление болевого синдрома…',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      'Болевой синдром удален',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'ru'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'ru'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'ru',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'ru'),
    medication_list_add_new_button: shared('Add New', 'ru'),

    medication_list_delete_medication_overlay_text: 'Удаление препарата...',
    medication_list_no_current_medications_text:
      'В ваш профиль не добавлены принимаемые препараты',
    medication_list_no_medications_text: 'В ваш профиль не добавлены препараты',
    medication_list_current_medications_header: 'Принимаемые препараты:',
    medication_list_outdated_medications_header: 'Ранее принимаемые препараты:',
    medication_list_edit_button_action_sheet: shared('Edit', 'ru'),
    medication_list_delete_button_action_sheet: shared('Delete', 'ru'),
    medication_list_delete_confirmation_popup_title: 'Удалить препарат?',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      'Этот препарат связан с {0} записью и эта связь будет потеряна.',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      'Этот препарат связан с {0} записями и эта связь будет потеряна.',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      'Этот препарат связан с {0} записями и эта связь будет потеряна.',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'ru',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    medication_list_delete_medication_successful_toast_text: 'Препарат удален',
    medication_list_delete_medication_toast_ok_button_text: shared('OK', 'ru'),
    medication_list_generic_label: shared('Generic', 'ru'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: 'мкг',
    medication_collapsible_form_strength_unit_g: 'г',
    medication_collapsible_form_strength_unit_mg: 'мг',

    medication_collapsible_form_add_strength_button: shared('Add', 'ru'),
    medication_collapsible_form_choose_brand_placeholder: 'Выберите название',
    medication_collapsible_form_brand_modal_close_button: shared('Close', 'ru'),
    medication_collapsible_form_add_new_brand_button_label:
      'Не нашли название?\n{0}',
    medication_collapsible_form_add_new_brand_button: shared('Add New', 'ru'),
    medication_collapsible_form_dose_title:
      'или создайте собственную дозировку',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'ru',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      'Выберите лекарственную форму',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'ru',
    ),
    medication_collapsible_form_strength_units_modal_picker_header:
      'Доза препарата',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'ru'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: 'мкг',
    constants_strength_unit_g: 'г',
    constants_strength_unit_mg: 'мг',

    constants_dosage_applications: 'компрессы',
    constants_dosage_capsules: 'капсулы',
    constants_dosage_drops: 'капли',
    constants_dosage_injections: 'инъекции',
    constants_dosage_ml: 'мл',
    constants_dosage_patches: 'пластыри',
    constants_dosage_puffs: 'ингаляции',
    constants_dosage_sprays: 'впрыскивания',
    constants_dosage_suppositories: 'суппозитории',
    constants_dosage_tablets: 'таблетки',
    constants_dosage_units: 'единицы',

    constants_dosage_applications_plural: 'компресс(-ы)',
    constants_dosage_capsules_plural: 'капсула(-ы)',
    constants_dosage_drops_plural: 'капля(-и)',
    constants_dosage_injections_plural: 'инъекция(-и)',
    constants_dosage_ml_plural: 'мл',
    constants_dosage_patches_plural: 'пластырь(-и)',
    constants_dosage_puffs_plural: 'ингаляция(-и)',
    constants_dosage_sprays_plural: 'впрыскивание(-я)',
    constants_dosage_suppositories_plural: 'суппозиторий(-и)',
    constants_dosage_tablets_plural: 'таблетка(-и)',
    constants_dosage_units_plural: 'единица(-ы)',

    constants_dosage_applications_count_one: '{0} компресс',
    constants_dosage_applications_count_many: '{0} компрессов',
    constants_dosage_applications_count_few: '{0} компресса',
    constants_dosage_capsules_count_one: '{0} капсула',
    constants_dosage_capsules_count_many: '{0} капсул',
    constants_dosage_capsules_count_few: '{0} капсулы',
    constants_dosage_drops_count_one: '{0} капля',
    constants_dosage_drops_count_many: '{0} капель',
    constants_dosage_drops_count_few: '{0} капли',
    constants_dosage_injections_count_one: '{0} инъекция',
    constants_dosage_injections_count_many: '{0} инъекций',
    constants_dosage_injections_count_few: '{0} инъекции',
    constants_dosage_ml_count_one: '{0} мл',
    constants_dosage_ml_count_many: '{0} мл',
    constants_dosage_ml_count_few: '{0} мл',
    constants_dosage_patches_count_one: '{0} пластырь',
    constants_dosage_patches_count_many: '{0} пластырей',
    constants_dosage_patches_count_few: '{0} пластыря',
    constants_dosage_puffs_count_one: '{0} ингаляция',
    constants_dosage_puffs_count_many: '{0} ингаляций',
    constants_dosage_puffs_count_few: '{0} ингаляции',
    constants_dosage_sprays_count_one: '{0} впрыскивание',
    constants_dosage_sprays_count_many: '{0} впрыскиваний',
    constants_dosage_sprays_count_few: '{0} впрыскивания',
    constants_dosage_suppositories_count_one: '{0} суппозиторий',
    constants_dosage_suppositories_count_many: '{0} суппозиториев',
    constants_dosage_suppositories_count_few: '{0} суппозитория',
    constants_dosage_tablets_count_one: '{0} таблетка',
    constants_dosage_tablets_count_many: '{0} таблеток',
    constants_dosage_tablets_count_few: '{0} таблетки',
    constants_dosage_units_count_one: '{0} ед.',
    constants_dosage_units_count_many: '{0} ед.',
    constants_dosage_units_count_few: '{0} ед.',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'ru'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      'Болевой синдром уже добавлен',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'ru'),
    search_pain_condition_add_new_pain_condition_alert_title:
      'Уверены, что не можете найти болевой синдром {0}?',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'ru',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'ru',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      'Болевой синдром добавлен',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'ru'),
    search_pain_condition_pain_condition_selected_toast_text:
      'Болевой синдром выбран',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'ru',
    ),

    search_pain_condition_header_title: 'Добавить болевой синдром',
    search_pain_condition_text_before_search_bar:
      'Найдите ваш болевой синдром и выберите его в списке результатов.',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'ru',
    ),

    search_pain_condition_secondary_text_above_results:
      'Нашли болевой синдром, который искали?',
    search_pain_condition_secondary_text_above_not_found_results:
      'Болевой синдром не найден в нашей базе данных.',
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'ru',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'ru'),
    search_pain_condition_create_button_text: 'Добавить болевой синдром',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'ru'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'ru',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'ru',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'ru'),
    edit_pain_condition_header_add_new_title: 'Добавить болевой синдром',
    edit_pain_condition_header_edit_title: 'Изменить болевой синдром',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'ru',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'ru',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'ru',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title:
      'Изменить болевой синдром?',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      'Этот болевой синдром связан с {0} препаратом и эта связь будет изменена.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      'Этот болевой синдром связан с {0} препаратами и эта связь будет изменена.',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      'Этот болевой синдром связан с {0} препаратами и эта связь будет изменена.',

    edit_pain_condition_saving_overlay_text: 'Сохранение синдрома…',
    edit_pain_condition_adding_text: 'Добавление синдрома',
    edit_pain_condition_editing_text: 'Изменение синдрома',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      'Болевой синдром добавлен',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'ru'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      'Болевой синдром изменен',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'ru'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'ru'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: 'мои болевые синдромы',
    edit_pain_conditions_back_button_text: shared('Back', 'ru'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'ru'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'ru',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'ru',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: 'Добавить препарат',
    search_medication_input_label: shared('Medication', 'ru'),
    search_medication_text_before_search_bar:
      'Найдите название своего препарата (без указания дозировки) и выберите его из списка результатов.',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'ru',
    ),
    search_medication_secondary_text_above_results:
      'Есть в списке препарат, который вы искали?',
    search_medication_secondary_text_above_not_found_results:
      'Препарат не найден в нашей базе данных.',
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'ru',
    ),
    search_medication_request_medication: shared('Request It', 'ru'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject:
      'Мобильный запрос: отсутствует препарат',
    search_medication_data_medication_email_first_line:
      'Препарат {0} не найден.',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'ru',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: 'Добавить новое название',
    search_medication_brand_title:
      'Введите название вашего препарата и мы добавим его в базу данных.',
    search_medication_brand_ok_button: shared('OK', 'ru'),
    search_medication_brand_label: 'Название',
    search_medication_brand_selected_medication_label_text: 'Другое название ',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'ru',
    ),
    search_medication_brand_similar_results_search_helper:
      'Есть в списке название, которое вы искали?',
    search_medication_brand_no_similar_item_search_helper:
      'Название не найдено в нашей базе данных.',
    search_medication_brand_request_brand_label: 'Не нашли название?\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'ru'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      'Мы добавим ваше название вскоре после получения запроса. Пока ваш препарат будет добавлен в качестве дженерика.',
    search_medication_brand_added_toast: 'Название добавлено',
    search_medication_brand_selected_toast:
      'Название выбрано в качестве препарата',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: 'Добавить препарат',
    edit_medication_header_edit_title: 'Изменить препарат',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'ru',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'ru',
    ),
    edit_medication_saving_overlay_text: 'Сохранение препарата...',
    edit_medication_editing_text: 'Изменение препарата',
    edit_medication_adding_text: 'Добавление препарата',
    edit_medication_medication_description_section_header: 'Описание препарата',
    edit_medication_medication_purposes_section_header: 'Показания',
    edit_medication_medication_instructions_section_header: 'Инструкции',
    edit_medication_medication_notes_header: shared('Notes', 'ru'),
    edit_medication_medication_successfully_added_toast_text:
      'Препарат добавлен',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'ru',
    ),
    edit_medication_medication_successfully_edited_toast_text:
      'Препарат изменен',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'ru',
    ),
    edit_medication_medication_duplication_toast_text:
      'Препарат уже есть в вашем профиле',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'ru',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'ru',
    ),
    edit_medication_edit_confirmation_pop_up_title: 'Изменить препарат?',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      'Этот препарат связан с {0} записью и эта связь будет восстановлена.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      'Этот препарат связан с {0} записями и эта связь будет восстановлена.',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      'Этот препарат связан с {0} записями и эта связь будет восстановлена.',
    edit_medication_apply_button_text: shared('Apply changes', 'ru'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: 'Мои препараты',
    edit_medications_footer_apply_button: shared('Continue', 'ru'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: 'Название',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'ru',
    ),
    medication_description_strength_label: 'Активное вещество',
    medication_description_strength_error: 'Введите значение до сохранения',
    medication_description_form_label: shared('Medication Form', 'ru'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'ru',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'ru',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'ru',
    ),
    medication_description_strength_units_picker_header:
      'Ед. измер. акт. вещества',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'ru',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'ru',
    ),
    medication_purposes_purposes_list_is_empty: 'Список показаний пуст',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text:
      'Я принимаю этот препарат',
    medication_instructions_start_date_label: shared('Start Date', 'ru'),
    medication_instructions_start_date_datepicker_selecting_text:
      'Выберите дату начала',
    medication_instructions_end_date_label: shared('End Date', 'ru'),
    medication_instructions_end_date_datepicker_selecting_text:
      'Выберите дату окончания',
    medication_instructions_take_as_header: 'Принимать',
    medication_instructions_dosages_header: shared('Dosage', 'ru'),
    medication_instructions_no_dosages_text: 'Дозировка не указана',
    medication_instructions_take_as_value_needed: 'По необходимости',
    medication_instructions_take_as_value_scheduled: 'По графику',
    medication_instructions_take_as_value_scheduled_and_needed:
      'По графику и необходимости',

    medication_instructions_frequencies_hourly: 'Каждый час',
    medication_instructions_frequencies_daily: 'Ежедневно',
    medication_instructions_frequencies_weekly: 'еженедельно',
    medication_instructions_frequencies_monthly: 'Ежемесячно',

    medication_instructions_add_dosage_button_text: 'Добавить дозировку',
    medication_instructions_special_instructions_label:
      'Дополнительные инструкции',
    medication_instructions_delete_dosage_confirmation_popup_title:
      'Удалить дозировку',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'ru'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'ru'),
    medication_instructions_add_dosage_modal_header: 'Добавить дозировку',
    medication_instructions_edit_dosage_modal_header: 'Изменить дозировку',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'ru',
    ),
    medication_instructions_done_button_dosage_modal_text: shared('Done', 'ru'),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'ru',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      'между {0} и {1}',
    medication_instructions_dosage_time_string_start_time: 'после {0}',
    medication_instructions_dosage_time_string_end_time: 'до {0}',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string:
      'каждое {0} число месяца',
    medication_instructions_add_dosage_modal_dosage_number_error:
      'Введите значение до сохранения',

    medication_instructions_hourly_count_one: 'Каждый час',
    medication_instructions_hourly_count_other: 'каждые {0} часов',
    medication_instructions_hourly_count_many: 'каждые {0} часа',
    medication_instructions_daily_count_one: 'Ежедневно',
    medication_instructions_daily_count_other: 'каждые {0} дней',
    medication_instructions_daily_count_many: 'каждые {0} дня',
    medication_instructions_weekly_count_one: 'еженедельно',
    medication_instructions_weekly_count_other: 'каждые {0} недель',
    medication_instructions_weekly_count_many: 'каждые {0} недели',
    medication_instructions_monthly_count_one: 'Ежемесячно',
    medication_instructions_monthly_count_other: 'каждые {0} месяцев',
    medication_instructions_monthly_count_many: 'каждые {0} месяца',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'ru'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'ru'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'ru'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'ru'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      'Время окончания должно быть позже времени начала',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'ru'),
  },

  'zh-rCN': {
    // containers/PainConditionListContainer.js
    pain_condition_list_header: shared('Pain Conditions', 'zh-rCN'),

    // Profile Screen - pain_condition section, Purposes List
    pain_condition_list_edit_button_action_sheet: shared('Edit', 'zh-rCN'),
    pain_condition_list_delete_button_action_sheet: shared('Delete', 'zh-rCN'),
    pain_condition_list_add_new_button: shared('Add New', 'zh-rCN'),
    pain_condition_list_no_pain_conditions_text:
      '您的个人档案中未添加任何疼痛情况',

    // Delete Condition Confirmation
    pain_condition_list_delete_confirmation_popup_title: '删除疼痛病情？',
    pain_condition_list_delete_confirmation_popup_text_usages_count_one:
      '与此疼痛病情相关的{0}种药物会失去其关联。',
    pain_condition_list_delete_confirmation_popup_text_usages_count_other:
      '与此疼痛病情相关的{0}种药物会失去其关联。',
    pain_condition_list_delete_confirmation_popup_text_usages_count_many:
      '与此疼痛病情相关的{0}种药物会失去其关联。',
    pain_condition_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'zh-rCN',
    ),
    pain_condition_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    pain_condition_list_delete_pain_condition_overlay_text:
      '正在删除疼痛病情...',
    pain_condition_list_delete_pain_condition_successful_toast_text:
      '疼痛病情已成功删除',
    pain_condition_list_delete_pain_condition_successful_toast_ok_button_text:
      shared('OK', 'zh-rCN'),
    pain_condition_list_diagnosed_row: shared('Date of Diagnosis', 'zh-rCN'),
    pain_condition_list_first_symptom_row: shared(
      'Date of First Symptom',
      'zh-rCN',
    ),

    // Profile Screen - medication sections
    // containers/MedicationListContainer.js
    medication_list_header: shared('Medications', 'zh-rCN'),
    medication_list_add_new_button: shared('Add New', 'zh-rCN'),

    medication_list_delete_medication_overlay_text: '删除药物...',
    medication_list_no_current_medications_text:
      '您的个人档案中未添加任何当前药物',
    medication_list_no_medications_text: '您的个人档案中未添加任何药物',
    medication_list_current_medications_header: '当前药物：',
    medication_list_outdated_medications_header: '此前的药物：',
    medication_list_edit_button_action_sheet: shared('Edit', 'zh-rCN'),
    medication_list_delete_button_action_sheet: shared('Delete', 'zh-rCN'),
    medication_list_delete_confirmation_popup_title: '删除药物？',
    medication_list_delete_confirmation_popup_text_usages_count_one:
      '与此药物相关的{0}条记录会失去其关联。',
    medication_list_delete_confirmation_popup_text_usages_count_other:
      '与此药物相关的{0}条记录会失去其关联。',
    medication_list_delete_confirmation_popup_text_usages_count_many:
      '与此药物相关的{0}条记录会失去其关联。',
    medication_list_delete_confirmation_popup_delete_button: shared(
      'Delete',
      'zh-rCN',
    ),
    medication_list_delete_confirmation_popup_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    medication_list_delete_medication_successful_toast_text: '药物成功删除',
    medication_list_delete_medication_toast_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),
    medication_list_generic_label: shared('Generic', 'zh-rCN'),

    // IntroScreens, WizardSecondStep Screens
    // components/MedicationCollapsibleForm.js
    'medication_collapsible_form_strength_unit_%': '%',
    medication_collapsible_form_strength_unit_µg: '微克',
    medication_collapsible_form_strength_unit_g: '克',
    medication_collapsible_form_strength_unit_mg: '毫克',

    medication_collapsible_form_add_strength_button: shared('Add', 'zh-rCN'),
    medication_collapsible_form_choose_brand_placeholder: '选择品牌',
    medication_collapsible_form_brand_modal_close_button: shared(
      'Close',
      'zh-rCN',
    ),
    medication_collapsible_form_add_new_brand_button_label:
      '找不到您使用的品牌？\n{0}',
    medication_collapsible_form_add_new_brand_button: shared(
      'Add New',
      'zh-rCN',
    ),
    medication_collapsible_form_dose_title: '或创建您的剂量',
    medication_collapsible_form_dosage_units_modal_picker_header: shared(
      'Medication Form',
      'zh-rCN',
    ),
    medication_collapsible_form_dosage_units_modal_picker_placeholder:
      '选择药物剂型',
    medication_collapsible_form_dosage_units_modal_picker_close_button: shared(
      'Close',
      'zh-rCN',
    ),
    medication_collapsible_form_strength_units_modal_picker_header: '剂量单位',
    medication_collapsible_form_strength_units_modal_picker_close_button:
      shared('Close', 'zh-rCN'),
    medication_collapsible_form_dosage_unit_strength_already_exists:
      'Strength already exists in the list',

    // Constants, used in parsing default data, and for general things
    // constants.js
    'constants_strength_unit_%': '%',
    constants_strength_unit_µg: '微克',
    constants_strength_unit_g: '克',
    constants_strength_unit_mg: '毫克',

    constants_dosage_applications: '敷用',
    constants_dosage_capsules: '胶囊',
    constants_dosage_drops: '滴液',
    constants_dosage_injections: '注射',
    constants_dosage_ml: 'mL',
    constants_dosage_patches: '药膏',
    constants_dosage_puffs: '吸入剂',
    constants_dosage_sprays: '喷雾剂',
    constants_dosage_suppositories: '栓剂',
    constants_dosage_tablets: '药片',
    constants_dosage_units: '单位',

    constants_dosage_applications_plural: '敷用',
    constants_dosage_capsules_plural: '胶囊',
    constants_dosage_drops_plural: '滴液',
    constants_dosage_injections_plural: '注射',
    constants_dosage_ml_plural: 'mL',
    constants_dosage_patches_plural: '药膏',
    constants_dosage_puffs_plural: '吸入剂',
    constants_dosage_sprays_plural: '喷雾剂',
    constants_dosage_suppositories_plural: '栓剂',
    constants_dosage_tablets_plural: '药片',
    constants_dosage_units_plural: '单位',

    constants_dosage_applications_count_one: '{0}敷用',
    constants_dosage_applications_count_other: '{0}敷用',
    constants_dosage_applications_count_many: '{0}敷用',
    constants_dosage_capsules_count_one: '{0}胶囊',
    constants_dosage_capsules_count_other: '{0}胶囊',
    constants_dosage_capsules_count_many: '{0}胶囊',
    constants_dosage_drops_count_one: '{0}滴液',
    constants_dosage_drops_count_other: '{0}滴液',
    constants_dosage_drops_count_many: '{0}滴液',
    constants_dosage_injections_count_one: '{0}注射',
    constants_dosage_injections_count_other: '{0}注射',
    constants_dosage_injections_count_many: '{0}注射',
    constants_dosage_ml_count_one: '{0}mL',
    constants_dosage_ml_count_other: '{0}mL',
    constants_dosage_ml_count_many: '{0}mL',
    constants_dosage_patches_count_one: '{0}药膏',
    constants_dosage_patches_count_other: '{0}药膏',
    constants_dosage_puffs_count_one: '{0}吸入剂',
    constants_dosage_puffs_count_other: '{0}吸入剂',
    constants_dosage_puffs_count_many: '{0}吸入剂',
    constants_dosage_sprays_count_one: '{0}喷雾剂',
    constants_dosage_sprays_count_other: '{0}喷雾剂',
    constants_dosage_sprays_count_many: '{0}喷雾剂',
    constants_dosage_suppositories_count_one: '{0}栓剂',
    constants_dosage_suppositories_count_other: '{0}栓剂',
    constants_dosage_suppositories_count_many: '{0}栓剂',
    constants_dosage_tablets_count_one: '{0}药片',
    constants_dosage_tablets_count_other: '{0}药片',
    constants_dosage_tablets_count_many: '{0}药片',
    constants_dosage_units_count_one: '{0}单位',
    constants_dosage_units_count_other: '{0}单位',
    constants_dosage_units_count_many: '{0}单位',

    // Search Pain Condition Screen,
    // scenes/SearchPainConditionScene.js
    search_pain_condition_input_label: shared('Pain Condition', 'zh-rCN'),
    search_pain_condition_pain_condition_has_already_been_added_alert_title:
      '疼痛病情已添加',
    search_pain_condition_pain_condition_has_already_been_added_alert_ok_button_text:
      shared('OK', 'zh-rCN'),
    search_pain_condition_add_new_pain_condition_alert_title:
      '您确定找不到疼痛病情{0}吗？',
    search_pain_condition_add_new_pain_condition_yes_button_text: shared(
      'Yes',
      'zh-rCN',
    ),
    search_pain_condition_add_new_pain_condition_no_button_text: shared(
      'No',
      'zh-rCN',
    ),
    search_pain_condition_pain_condition_successfully_added_toast_text:
      '疼痛病情成功添加',
    search_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'zh-rCN'),
    search_pain_condition_pain_condition_selected_toast_text: '疼痛病情已选中',
    search_pain_condition_pain_condition_selected_toast_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),

    search_pain_condition_header_title: '添加疼痛病情',
    search_pain_condition_text_before_search_bar:
      '搜索您的疼痛病情，您可以在结果列表中选择相关病情。',
    search_pain_condition_search_bar_helper_text: shared(
      'Type three or more letters',
      'zh-rCN',
    ),

    search_pain_condition_secondary_text_above_results:
      '你看到正在寻找的疼痛病情吗？',
    search_pain_condition_secondary_text_above_not_found_results:
      '我们的数据库中没有这种疼痛病情。',
    search_pain_condition_pain_condition_not_found: shared(
      'cant_find_condition',
      'zh-rCN',
    ),
    search_pain_condition_add_new_button_text: shared('Add New', 'zh-rCN'),
    search_pain_condition_create_button_text: '添加疼痛病情',

    // Edit Pain Condition Screen,
    edit_pain_condition_name_label: shared('Name', 'zh-rCN'),
    edit_pain_condition_diagnosed_input_label: shared(
      'Date of Diagnosis',
      'zh-rCN',
    ),
    edit_pain_condition_first_symptom_input_label: shared(
      'Date of First Symptom',
      'zh-rCN',
    ),
    edit_pain_condition_search_again_button: shared('Search again', 'zh-rCN'),
    edit_pain_condition_header_add_new_title: '添加疼痛病情',
    edit_pain_condition_header_edit_title: '编辑疼痛病情',
    edit_pain_condition_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    edit_pain_condition_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_pain_condition_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'zh-rCN',
    ),
    edit_pain_condition_back_without_save_confirmation_save_button: shared(
      'Save',
      'zh-rCN',
    ),

    edit_pain_condition_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_pain_condition_edit_confirmation_pop_up_continue_button: shared(
      'Edit',
      'zh-rCN',
    ),
    edit_pain_condition_edit_confirmation_pop_up_title: '编辑疼痛病情？',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_one:
      '与此疼痛病情相关的{0}种药物会重新关联。',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_many:
      '与此疼痛病情相关的{0}种药物会重新关联。',
    edit_pain_condition_edit_confirmation_pop_up_text_usages_count_other:
      '与此疼痛病情相关的{0}种药物会重新关联。',

    edit_pain_condition_saving_overlay_text: '保存病情...',
    edit_pain_condition_adding_text: '添加病症',
    edit_pain_condition_editing_text: '编辑病症',
    edit_pain_condition_pain_condition_successfully_added_toast_text:
      '疼痛病情成功添加',
    edit_pain_condition_pain_condition_successfully_added_toast_ok_button_text:
      shared('OK', 'zh-rCN'),
    edit_pain_condition_pain_condition_successfully_edited_toast_text:
      '疼痛病情成功编辑',
    edit_pain_condition_pain_condition_successfully_edited_toast_ok_button_text:
      shared('OK', 'zh-rCN'),
    edit_pain_condition_apply_button_text: shared('Apply changes', 'zh-rCN'),

    // Edit Pain Conditions List Screen
    // scenes/EditPainConditionsListScene.js
    edit_pain_conditions_list_header_title: '我的疼痛病情',
    edit_pain_conditions_back_button_text: shared('Back', 'zh-rCN'),
    edit_pain_conditions_apply_button_text: shared('Apply changes', 'zh-rCN'),
    edit_pain_conditions_back_confirmation_alert_text: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    edit_pain_conditions_back_confirmation_alert_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_pain_conditions_back_confirmation_alert_discard_button: shared(
      'Discard',
      'zh-rCN',
    ),
    edit_pain_conditions_back_confirmation_alert_save_button: shared(
      'Save',
      'zh-rCN',
    ),

    // Search Medication Screen,
    // scenes/SearchMedicationScene.js
    search_medication_header_title: '添加药物',
    search_medication_input_label: shared('Medication', 'zh-rCN'),
    search_medication_text_before_search_bar:
      '搜索您的药物名称（不含强度），并从结果列表中进行选择。',
    search_medication_search_bar_helper_text: shared(
      'Type three or more letters',
      'zh-rCN',
    ),
    search_medication_secondary_text_above_results:
      '这里有您正在寻找的药物吗？',
    search_medication_secondary_text_above_not_found_results:
      '我们的数据库中没有这种药物。',
    search_medication_medication_not_found: shared(
      'cant_find_medication',
      'zh-rCN',
    ),
    search_medication_request_medication: shared('Request It', 'zh-rCN'),
    search_medication_data_medication_email: 'mailto:{0}?subject={1}&body={2}',
    search_medication_data_medication_email_contact: 'contact@managinglife.com',
    search_medication_data_medication_email_subject: '手机客户端请求：缺少药品',
    search_medication_data_medication_email_first_line: '未找到以下药物：{0}。',
    search_medication_data_medication_email_error: shared(
      'mail_app_error',
      'zh-rCN',
    ),

    // Search Brand Screen
    // scenes/SearchMedicationBrandScene.js
    search_medication_brand_header: '添加新品牌',
    search_medication_brand_title: '请在在下面填写您的药物品牌，我们将添加它。',
    search_medication_brand_ok_button: shared('OK', 'zh-rCN'),
    search_medication_brand_label: '品牌',
    search_medication_brand_selected_medication_label_text:
      '品牌对应的通用药品名称',
    search_medication_brand_three_letters_search_helper: shared(
      'Type three or more letters',
      'zh-rCN',
    ),
    search_medication_brand_similar_results_search_helper:
      '这里有您正在寻找的品牌吗？',
    search_medication_brand_no_similar_item_search_helper:
      '我们的数据库中没有这个品牌。',
    search_medication_brand_request_brand_label: '找不到您使用的品牌？\n{0}',
    search_medication_brand_request_brand: shared('Request It', 'zh-rCN'),
    search_medication_brand_data_medication_email:
      'mailto:{0}?subject={1}&body={2}',
    search_medication_brand_data_medication_email_contact:
      'contact@managinglife.com',
    search_medication_brand_data_medication_email_subject:
      'Mobile request: Missing medication brand',
    search_medication_brand_data_medication_email_first_line:
      'The following brand, {0}, was not found.',
    search_medication_brand_email_text:
      'The following brand, {0}, was not found.',
    search_medication_brand_addition_of_brand_text:
      '我们会在收到您的请求后很快添加您的品牌。在此期间，您的药物将被作为通用药物添加。',
    search_medication_brand_added_toast: '品牌成功添加',
    search_medication_brand_selected_toast: '成功选取药物品牌',

    // Edit Medication Screen,
    // scenes/EditMedication.js
    edit_medication_header_add_new_title: '添加药物',
    edit_medication_header_edit_title: '编辑药物',
    edit_medication_back_without_save_confirmation_title: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    edit_medication_back_without_save_confirmation_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_medication_back_without_save_confirmation_discard_button: shared(
      'Discard',
      'zh-rCN',
    ),
    edit_medication_back_without_save_confirmation_save_button: shared(
      'Save',
      'zh-rCN',
    ),
    edit_medication_saving_overlay_text: '保存药物...',
    edit_medication_editing_text: '编辑药物',
    edit_medication_adding_text: '添加药物',
    edit_medication_medication_description_section_header: '药物描述',
    edit_medication_medication_purposes_section_header: '用途',
    edit_medication_medication_instructions_section_header: '说明',
    edit_medication_medication_notes_header: shared('Notes', 'zh-rCN'),
    edit_medication_medication_successfully_added_toast_text: '药物成功添加',
    edit_medication_medication_successfully_added_toast_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),
    edit_medication_medication_successfully_edited_toast_text: '药物成功编辑',
    edit_medication_medication_successfully_edited_toast_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),
    edit_medication_medication_duplication_toast_text: '你的档案已包含药物信息',

    edit_medication_edit_confirmation_pop_up_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),
    edit_medication_edit_confirmation_pop_up_continue_button: shared(
      'Continue',
      'zh-rCN',
    ),
    edit_medication_edit_confirmation_pop_up_title: '编辑药物？',
    edit_medication_edit_confirmation_pop_up_text_usages_count_one:
      '与此药物相关的{0}条记录会重新关联。',
    edit_medication_edit_confirmation_pop_up_text_usages_count_many:
      '与此药物相关的{0}条记录会重新关联。',
    edit_medication_edit_confirmation_pop_up_text_usages_count_other:
      '与此药物相关的{0}条记录会重新关联。',
    edit_medication_apply_button_text: shared('Apply changes', 'zh-rCN'),

    // Edit Medications List Screen
    // scenes/MedicationsList.js
    edit_medications_list_header_title: '我的药物',
    edit_medications_footer_apply_button: shared('Continue', 'zh-rCN'),

    // Medication Description added or edited medication in profile
    // components/MedicationDescription.js
    medication_description_brand_name_label: '品牌',
    medication_description_medication_common_name_label: shared(
      'Medication',
      'zh-rCN',
    ),
    medication_description_strength_label: '剂量',
    medication_description_strength_error: '保存前请输入值',
    medication_description_form_label: shared('Medication Form', 'zh-rCN'),
    medication_description_search_again_button_text: shared(
      'Search again',
      'zh-rCN',
    ),
    medication_description_dosage_units_picker_header: shared(
      'Medication Form',
      'zh-rCN',
    ),
    medication_description_dosage_units_picker_close_button: shared(
      'Close',
      'zh-rCN',
    ),
    medication_description_strength_units_picker_header: '剂量单位',
    medication_description_strength_units_picker_close_button: shared(
      'Close',
      'zh-rCN',
    ),

    // Medication Purposes of added or edited medication in profile
    // components/MedicationPurposes.js
    medication_purposes_edit_purposes_list_button_text: shared(
      'Edit List',
      'zh-rCN',
    ),
    medication_purposes_purposes_list_is_empty: '用途列表为空',

    // Medication Instructions of added or edited medication in profile
    // components/MedicationInstructions.js
    medication_instructions_taking_medication_button_text: '我服用这种药',
    medication_instructions_start_date_label: shared('Start Date', 'zh-rCN'),
    medication_instructions_start_date_datepicker_selecting_text:
      '选择开始日期',
    medication_instructions_end_date_label: shared('End Date', 'zh-rCN'),
    medication_instructions_end_date_datepicker_selecting_text: '选择结束日期',
    medication_instructions_take_as_header: '用法',
    medication_instructions_dosages_header: shared('Dosage', 'zh-rCN'),
    medication_instructions_no_dosages_text: '没有添加剂量',
    medication_instructions_take_as_value_needed: '需要时使用',
    medication_instructions_take_as_value_scheduled: '按时使用',
    medication_instructions_take_as_value_scheduled_and_needed:
      '按时和需要时使用',

    medication_instructions_frequencies_hourly: '每小时',
    medication_instructions_frequencies_daily: '每天',
    medication_instructions_frequencies_weekly: '每周',
    medication_instructions_frequencies_monthly: '每月',

    medication_instructions_add_dosage_button_text: '添加剂量',
    medication_instructions_special_instructions_label: '附加说明',
    medication_instructions_delete_dosage_confirmation_popup_title: '删除剂量',
    medication_instructions_delete_dosage_confirmation_popup_delete_button:
      shared('Delete', 'zh-rCN'),
    medication_instructions_delete_dosage_confirmation_popup_cancel_button:
      shared('Cancel', 'zh-rCN'),
    medication_instructions_add_dosage_modal_header: '添加剂量',
    medication_instructions_edit_dosage_modal_header: '编辑剂量',
    medication_instructions_close_dosage_modal_footer_text: shared(
      'Close',
      'zh-rCN',
    ),
    medication_instructions_done_button_dosage_modal_text: shared(
      'Done',
      'zh-rCN',
    ),
    medication_instructions_add_dosage_modal_dosage_number_label: shared(
      'Dosage',
      'zh-rCN',
    ),
    medication_instructions_dosage_time_string_start_time_and_end_time:
      '{0}和{1}之间',
    medication_instructions_dosage_time_string_start_time: '{0}之后',
    medication_instructions_dosage_time_string_end_time: '{0}之前',
    medication_instructions_dosage_dosage_and_time_separator: ', ',
    medication_instructions_dosage_repeat_every_and_week_days_separator: ', ',
    medication_instructions_dosage_week_days_separator: ', ',
    medication_instructions_dosage_repeat_every_and_month_day_separator: ', ',
    medication_instructions_dosage_day_of_moth_string: '每月{0}日',
    medication_instructions_add_dosage_modal_dosage_number_error:
      '保存前请输入值',

    medication_instructions_hourly_count_one: '每小时',
    medication_instructions_hourly_count_other: '每{0}小时',
    medication_instructions_hourly_count_many: '每{0}小时',
    medication_instructions_daily_count_one: '每天',
    medication_instructions_daily_count_other: '每{0}天',
    medication_instructions_daily_count_many: '每{0}天',
    medication_instructions_weekly_count_one: '每周',
    medication_instructions_weekly_count_other: '每{0}周',
    medication_instructions_weekly_count_many: '每{0}周',
    medication_instructions_monthly_count_one: '每月',
    medication_instructions_monthly_count_other: '每{0}个月',
    medication_instructions_monthly_count_many: '每{0}个月',

    medication_instructions_close_dosage_modal_confirmation_popup_title: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    medication_instructions_close_dosage_modal_confirmation_popup_cancel_button:
      shared('Cancel', 'zh-rCN'),
    medication_instructions_close_dosage_modal_confirmation_popup_discard_button:
      shared('Discard', 'zh-rCN'),
    medication_instructions_close_dosage_modal_confirmation_popup_save_button:
      shared('Save', 'zh-rCN'),
    medication_instructions_start_after_end_date_validation_failed_alert:
      shared('date_range_error', 'zh-rCN'),
    medication_instructions_start_after_end_time_validation_failed_alert:
      '结束时间必须等于或晚于开始时间',

    // Medication Notes of added or edited medication in profile
    // components/MedicationNotes.js
    medication_notes_med_notes_label: shared('notes_hint_label', 'zh-rCN'),
  },
});

strings.plurals = pluralFunc(strings, plurals);

export default strings;
