import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = ['hours', 'minutes', 'seconds'];

const STRINGS = new LocalizedStrings({
  en: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'en'),
    results_scene_daterange_toast_button_ok: shared('OK', 'en'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'en'),
    results_scene_tab_records_header: shared('Results', 'en'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text: 'Pain record saved successfully',
    create_record_saving_record_loading: 'Saving pain record...',
    create_record_add_record_screen_header: shared('Add Pain Record', 'en'),
    create_record_edit_record_screen_header: 'Edit Pain Record',
    create_record_back_without_save_confirmation_alert_title:
      'Save record and close?',
    create_record_back_without_save_confirmation_alert_msg:
      'If you save record and close, your record will be saved and you can edit it later',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'back_to_editing',
      'en',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'save_and_close',
      'en',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'close_without_saving',
      'en',
    ),
    create_record_apply_button: shared('save_record_and_close', 'en'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'en',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'en',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'en',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'en',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'en',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'en',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'en',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'en',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'en',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'en',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'en'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'en',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'en'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'en',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'en',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'en',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'en'),
    create_record_saving: 'Saving...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text: 'Daily reflection saved successfully',
    create_reflection_success_toast_ok_button_text: shared('OK', 'en'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'en',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'en'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'en',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'en',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      'Edit Daily Reflection',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'en',
    ),
    create_reflection_apply_button_text: shared('save_record_and_close', 'en'),
    create_reflection_saving: 'Saving...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'en',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'en',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'en'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'en'),
    record_card_factor_ineffective: shared('No Change', 'en'),
    record_card_factor_aggravating: shared('Worse', 'en'),
    record_card_medications_header: shared('Medications', 'en'),
    record_card_medication_generic: shared('Generic', 'en'),
    record_card_delete_action: shared('Delete', 'en'),
    record_card_edit_action: shared('Edit', 'en'),
    record_card_copy_action: 'Duplicate',

    record_card_location_section_header_plural: shared('Locations', 'en'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'en',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'en',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'en',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'en',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'en',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'en'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'en',
    ),
    record_card_notes_section_header: shared('Notes', 'en'),
    record_card_delete_record_alert_title:
      'Are you sure you want to delete record?',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'en'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'en'),
    record_card_delete_record_toast_text: 'Record deleted successfully',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'en'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'en'),
    timing_section_start_time: shared('Start Time', 'en'),
    timing_section_type_header: 'What type of pain is it?',
    timing_section_type_constant: shared('Constant', 'en'),
    timing_section_type_intermittent: shared('Intermittent', 'en'),
    timing_section_type_breakthrough: shared('Breakthrough', 'en'),
    timing_section_end_header: 'When did it end?',
    timing_section_duration_button: 'Set duration',
    timing_section_end_date_button: 'Set end date/time',
    timing_section_all_day_button: shared('All Day', 'en'),
    timing_section_end_date: shared('End Date', 'en'),
    timing_section_end_time: shared('End Time', 'en'),
    timing_section_duration_Seconds: shared('Seconds', 'en'),
    timing_section_duration_Minutes: shared('Minutes', 'en'),
    timing_section_duration_Hours: shared('Hours', 'en'),
    timing_section_duration_label: shared('Duration', 'en'),
    timing_section_duration_units_label: shared('Units', 'en'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'en',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'en',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header:
      'How is your pain after taking this medication?',
    medications_section_add_button: shared('Add New', 'en'),
    medications_section_edit_button: shared('Edit List', 'en'),
    medications_section_no_medications:
      'You have not added any medications to your profile',
    medications_section_dosage_label: shared('Dosage', 'en'),
    medications_section_dosage_helper_label:
      'enter dosage above or choose one from below',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'en',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'en'),
    medications_section_medication_form_label: shared('Medication Form', 'en'),
    medications_section_generic_medication: shared('Generic', 'en'),
    medications_section_alleviating_button: shared('Better', 'en'),
    medications_section_ineffective_button: shared('No Change', 'en'),
    medications_section_aggravating_button: shared('Worse', 'en'),

    // components/SeveritySlider.js
    factors_section_type_header: 'How is your pain after this intervention?',
    factors_section_alleviating_button: shared('Better', 'en'),
    factors_section_ineffective_button: shared('No Change', 'en'),
    factors_section_aggravating_button: shared('Worse', 'en'),
    factors_section_edit_list_upper_button: shared('Edit List', 'en'),

    // Insights Tab
    insights_tab_nothing_to_show_text:
      'Selected records contain insufficient data',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'en'),
    insights_tab_pain_records_severity_average_text: 'Average:',
    insights_tab_pain_records_text: shared('Pain Records', 'en') + ':',
    insights_tab_function_graph_header: shared('Function', 'en'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'en') + ':',
    insights_tab_daily_reflections_severity_average_text: 'Average:',
    insights_tab_pain_duration_header: shared('Duration', 'en'),
    insights_tab_pain_type_header: shared('Type of Pain', 'en'),
    insights_tab_tool_tip:
      'Based on {0}% of {1} ' +
      shared('Pain Records', 'en') +
      ' that contain information about {2}',
    insights_tab_meaningful_activities_tool_tip:
      'Based on {0}% of {1} ' +
      shared('Daily Reflections', 'en') +
      ' that contain information about {2}',
    insights_tab_more_button_text: shared('More', 'en'),
    insights_tab_less_button_text: shared('Less', 'en'),
    insights_tab_location_header: shared('Locations', 'en'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'en'),
    insights_tab_character_header: shared('Characteristics', 'en'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'en',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'en',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'en',
    ),
    insights_tab_environment_header: shared('Environment', 'en'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'en',
    ),
    insights_tab_indicator_new_text: 'NEW',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      'No records have been added or no records exist in the specified date range',
    charts_tab_no_records_in_section:
      'No records exist in the specified section',
    charts_tab_tool_tip:
      'Based on {0}% of {1} records that contain information about {2}',

    // Calendar Tab
    calendar_tab_select_overlays_button: 'Change Overlays',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'en'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'en'),
    timeline_tab_pain_record_trendline: shared('Trend', 'en'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'en'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'en'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'en'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'en'),
    timeline_tab_column_function: shared('Function', 'en'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'en'),
    records_tab_view_insights_tab_text: shared('Insights', 'en'),
    records_tab_view_charts_tab_text: shared('Charts', 'en'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'en'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'en'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'en'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'en'),
    services_characteristics_value: shared('Characteristics', 'en'),
    services_type_of_pain_value: shared('Type of Pain', 'en'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'en',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'en'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'en'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'en'),
    services_environment_value: shared('Environment', 'en'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'en'),

    // Results screen
    severity_slider_0_slider_label: 'No pain',
    severity_slider_10_slider_label: 'Worst ever',
    severity_slider_today_label: 'today',
    severity_slider_how_is_your_pain: 'How is your pain {0}?',
    severity_slider_how_is_your_pain_today: 'How is your pain {0} at {1}?',
    severity_slider_how_is_your_pain_at_date: 'How is your pain on {0} at {1}?',
    severity_slider_how_was_your_pain_today: 'How was your pain {0} at {1}?',
    severity_slider_how_was_your_pain_at_date:
      'How was your pain on {0} at {1}?',
    severity_slider_right_now_label: 'right now',
    severity_slider_change_date_time: 'Change date/time',
    reflection_slider_change_date: 'Change date',
    severity_slider_drag_this_slider: 'Drag this slider',

    // scenes/CreateRecordScene.js
    record_card_show_more: 'show {0} more',
    record_card_details: 'Details',

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'en'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      'No records have been added - add one and it will appear here.',
    record_list_no_records_in_date_range:
      'No records exist in the specified date range - change the date range',
    record_list_no_records_in_filter:
      'No records meet the specified filter criteria - remove it',

    // Services, which populates data for Insights tab
    seconds_one: '{0} Second',
    seconds_other: '{0} Seconds',
    minutes_one: '{0} Minute',
    minutes_other: '{0} Minutes',
    hours_one: '{0} Hour',
    hours_other: '{0} Hours',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0} for {1}',
    reflection_slider_what_did_you_do_today_label:
      'What did you do that mattered to you {0}?',
    reflection_slider_what_did_you_do_label:
      'What did you do that mattered to you {0}?',
    reflection_slider_today_label: 'today',

    reflection_slider_reflection_exists_today_title:
      'Daily Reflection already exists today',
    reflection_slider_reflection_exists_today_message:
      "If you want to edit today's record, do so through the Records list. Otherwise, select another date for the new Daily Reflection.",
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'en',
    ),
    reflection_slider_reflection_exists_today_select_date_text: 'Select Date',
    reflection_slider_0_slider_label: 'Nothing',
    reflection_slider_10_slider_label: 'Everything I wanted',

    reflection_slider_reflection_exists:
      'Daily Reflection exists on selected date',
    reflection_slider_reflection_exists_message:
      'If you want to edit the existing record, do so through the Records list.  Otherwise, select another date.',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'en',
    ),
    reflection_slider_reflection_exists_select_again_text: 'Select Again',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: 'Show per page:',
    records_list_pagination_records_per_page_text: '{0} Records',
  },

  de: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'de'),
    results_scene_daterange_toast_button_ok: shared('OK', 'de'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'de'),
    results_scene_tab_records_header: shared('Results', 'de'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text: 'Schmerzeintrag erfolgreich gespeichert',
    create_record_saving_record_loading: 'Speichere Schmerzeintrag...',
    create_record_add_record_screen_header: shared('Add Pain Record', 'de'),
    create_record_edit_record_screen_header: 'Schmerzeintrag bearbeiten',
    create_record_back_without_save_confirmation_alert_title:
      'Eintrag speichern und schließen?',
    create_record_back_without_save_confirmation_alert_msg:
      'Wenn Sie den Eintrag speichern und schließen, wird Ihr Eintrag gespeichert und Sie können ihn später bearbeiten.',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'de',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'de',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'de',
    ),
    create_record_apply_button: shared('Save', 'de'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'de',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'de',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'de',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'de',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'de',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'de',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'de',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'de',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'de',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'de',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'de'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'de',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'de'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'de',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'de',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'de',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'de'),
    create_record_saving: 'Speichern...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text:
      'Tagesübersicht erfolgreich gespeichert',
    create_reflection_success_toast_ok_button_text: shared('OK', 'de'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'de',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'de'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'de',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'de',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      'Tagesübersicht bearbeiten',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'de',
    ),
    create_reflection_apply_button_text: shared('Save', 'de'),
    create_reflection_saving: 'Speichern...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'de',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'de',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'de'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'de'),
    record_card_factor_ineffective: shared('No Change', 'de'),
    record_card_factor_aggravating: shared('Worse', 'de'),
    record_card_medications_header: shared('Medications', 'de'),
    record_card_medication_generic: shared('Generic', 'de'),
    record_card_delete_action: shared('Delete', 'de'),
    record_card_edit_action: shared('Edit', 'de'),
    record_card_copy_action: 'Duplizieren',

    record_card_location_section_header_plural: shared('Locations', 'de'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'de',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'de',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'de',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'de',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'de',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'de'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'de',
    ),
    record_card_notes_section_header: shared('Notes', 'de'),
    record_card_delete_record_alert_title:
      'Sind Sie sicher, dass Sie den Eintrag löschen möchten?',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'de'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'de'),
    record_card_delete_record_toast_text: 'Eintrag erfolgreich gelöscht',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'de'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'de'),
    timing_section_start_time: shared('Start Time', 'de'),
    timing_section_type_header: 'Um welche Art von Schmerzen handelt es sich?',
    timing_section_type_constant: shared('Constant', 'de'),
    timing_section_type_intermittent: shared('Intermittent', 'de'),
    timing_section_type_breakthrough: shared('Breakthrough', 'de'),
    timing_section_end_header: 'Wann haben sie aufgehört?',
    timing_section_duration_button: 'Dauer festlegen',
    timing_section_end_date_button: 'Enddatum/-zeit festlegen',
    timing_section_all_day_button: shared('All Day', 'de'),
    timing_section_end_date: shared('End Date', 'de'),
    timing_section_end_time: shared('End Time', 'de'),
    timing_section_duration_Seconds: shared('Seconds', 'de'),
    timing_section_duration_Minutes: shared('Minutes', 'de'),
    timing_section_duration_Hours: shared('Hours', 'de'),
    timing_section_duration_label: shared('Duration', 'de'),
    timing_section_duration_units_label: shared('Units', 'de'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'de',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'de',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header:
      'Wie sind Ihre Schmerzen nach Einnahme dieses Medikaments?',
    medications_section_add_button: shared('Add New', 'de'),
    medications_section_edit_button: shared('Edit List', 'de'),
    medications_section_no_medications:
      'Sie haben Ihrem Profil keine Medikamente hinzugefügt',
    medications_section_dosage_label: shared('Dosage', 'de'),
    medications_section_dosage_helper_label:
      'dosis oben eingeben oder unten auswählen',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'de',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'de'),
    medications_section_medication_form_label: shared('Medication Form', 'de'),
    medications_section_generic_medication: shared('Generic', 'de'),
    medications_section_alleviating_button: shared('Better', 'de'),
    medications_section_ineffective_button: shared('No Change', 'de'),
    medications_section_aggravating_button: shared('Worse', 'de'),

    // components/SeveritySlider.js
    factors_section_type_header:
      'Wie sind Ihre Schmerzen nach dieser Intervention?',
    factors_section_alleviating_button: shared('Better', 'de'),
    factors_section_ineffective_button: shared('No Change', 'de'),
    factors_section_aggravating_button: shared('Worse', 'de'),
    factors_section_edit_list_upper_button: shared('Edit List', 'de'),

    // Insights Tab
    insights_tab_nothing_to_show_text:
      'Die ausgewählten Einträge enthalten unzureichende Daten',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'de'),
    insights_tab_pain_records_severity_average_text: 'Durchschnitt:',
    insights_tab_pain_records_text: shared('Pain Records', 'de') + ':',
    insights_tab_function_graph_header: shared('Function', 'de'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'de') + ':',
    insights_tab_daily_reflections_severity_average_text: 'Durchschnitt:',
    insights_tab_pain_duration_header: shared('Duration', 'de'),
    insights_tab_pain_type_header: shared('Type of Pain', 'de'),
    insights_tab_tool_tip:
      'Basierend auf {0}% von {1} ' +
      shared('Pain Records', 'de') +
      'n, die Informationen zu “{2}” enthalten',
    insights_tab_meaningful_activities_tool_tip:
      'Basierend auf {0}% von {1} ' +
      shared('Daily Reflections', 'de') +
      ', die Informationen zu “{2}” enthalten',
    insights_tab_more_button_text: shared('More', 'de'),
    insights_tab_less_button_text: shared('Less', 'de'),
    insights_tab_location_header: shared('Locations', 'de'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'de'),
    insights_tab_character_header: shared('Characteristics', 'de'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'de',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'de',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'de',
    ),
    insights_tab_environment_header: shared('Environment', 'de'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'de',
    ),
    insights_tab_indicator_new_text: 'NEU',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      'Es wurden keine Einträge hinzugefügt oder es existieren keine Einträge für den angegebenen Datumsbereich',
    charts_tab_no_records_in_section:
      'In diesem Abschnitt sind keine Einträge vorhanden',
    charts_tab_tool_tip:
      'Basierend auf {0}% von {1} Einträgen, die Informationen zu {2} enthalten',

    // Calendar Tab
    calendar_tab_select_overlays_button: 'Einblendung Ändern',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'de'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'de'),
    timeline_tab_pain_record_trendline: shared('Trend', 'de'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'de'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'de'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'de'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'de'),
    timeline_tab_column_function: shared('Function', 'de'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'de'),
    records_tab_view_insights_tab_text: shared('Insights', 'de'),
    records_tab_view_charts_tab_text: shared('Charts', 'de'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'de'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'de'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'de'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'de'),
    services_characteristics_value: shared('Characteristics', 'de'),
    services_type_of_pain_value: shared('Type of Pain', 'de'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'de',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'de'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'de'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'de'),
    services_environment_value: shared('Environment', 'de'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'de'),

    // Results screen
    severity_slider_0_slider_label: 'Keine Schmerzen',
    severity_slider_10_slider_label: 'Maximal',
    severity_slider_today_label: 'heute',
    severity_slider_how_is_your_pain: 'Wie sind Ihre Schmerzen {0}?',
    severity_slider_how_is_your_pain_today:
      'Wie sind Ihre Schmerzen {0} um {1}?',
    severity_slider_how_is_your_pain_at_date:
      'Wie sind Ihre Schmerzen am {0} um {1}?',
    severity_slider_how_was_your_pain_today:
      'Wie waren Ihre Schmerzen {0} um {1}?',
    severity_slider_how_was_your_pain_at_date:
      'Wie waren Ihre Schmerzen am {0} um {1}?',
    severity_slider_right_now_label: 'jetzt gerade',
    severity_slider_change_date_time: 'Datum/Uhrzeit ändern',
    reflection_slider_change_date: 'Datum ändern',
    severity_slider_drag_this_slider: 'Bewegen Sie diesen Schieberegler',

    // scenes/CreateRecordScene.js
    record_card_show_more: '{0} mehr anzeigen',
    record_card_details: 'Details',
    add_record_loading: shared('Loading...', 'de'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'de'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      'Es wurden keine Einträge hinzugefügt - fügen Sie einen hinzu und er wird hier erscheinen.',
    record_list_no_records_in_date_range:
      'Es existieren keine Einträge für den angegebenen Zeitraum - ändern Sie den Zeitraum',
    record_list_no_records_in_filter:
      'Es gibt keine Einträge, die den ausgewählten Filterkriterien entsprechen - wählen Sie weniger oder andere Kriterien',

    // Services, which populates data for Insights tab
    seconds_one: '{0} Sekunden',
    seconds_other: '{0} Sekunden',
    minutes_one: '{0} Minute',
    minutes_other: '{0} Minuten',
    hours_one: '{0} Stunde',
    hours_other: '{0} Stunden',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0} für {1}',
    reflection_slider_what_did_you_do_today_label:
      'Was haben Sie {0} getan, das Ihnen wichtig war?',
    reflection_slider_what_did_you_do_label:
      'Was haben Sie am {0} getan, das Ihnen wichtig war?',
    reflection_slider_today_label: 'heute',

    reflection_slider_reflection_exists_today_title:
      'Tagesübersicht für heute existiert bereits',
    reflection_slider_reflection_exists_today_message:
      'Wenn Sie den heutigen Eintrag bearbeiten möchten, nutzen Sie hierzu die Liste der Einträge. Andernfalls wählen Sie bitte ein anderes Datum für die neue Tagesübersicht.',
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'de',
    ),
    reflection_slider_reflection_exists_today_select_date_text: 'Datum wählen',
    reflection_slider_0_slider_label: 'Nichts',
    reflection_slider_10_slider_label: 'Alles, was ich wollte',

    reflection_slider_reflection_exists:
      'Tagesübersicht für das gewählte Datum existiert bereits',
    reflection_slider_reflection_exists_message:
      'Wenn Sie den bereits bestehenden Eintrag bearbeiten möchten, nutzen Sie hierzu die Liste der Einträge. Andernfalls wählen Sie bitte ein anderes Datum.',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'de',
    ),
    reflection_slider_reflection_exists_select_again_text: 'Neue Auswahl',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: 'pro Seite anzeigen:',
    records_list_pagination_records_per_page_text: '{0} Einträge',
  },

  es: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'es'),
    results_scene_daterange_toast_button_ok: shared('OK', 'es'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'es'),
    results_scene_tab_records_header: shared('Results', 'es'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text:
      'Registro de dolor guardado satisfactoriamente',
    create_record_saving_record_loading: 'Guardando registro de dolor…',
    create_record_add_record_screen_header: shared('Add Pain Record', 'es'),
    create_record_edit_record_screen_header: 'Editar registro de dolor',
    create_record_back_without_save_confirmation_alert_title:
      '¿Guardar registro y cerrar?',
    create_record_back_without_save_confirmation_alert_msg:
      'Si guarda y cierra ahora, su registro se guardará y podrá editarlo más adelante',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'es',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'es',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'es',
    ),
    create_record_apply_button: shared('Save', 'es'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'es',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'es',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'es',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'es',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'es',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'es',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'es',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'es',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'es',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'es',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'es'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'es',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'es'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'es',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'es',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'es',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'es'),
    create_record_saving: 'Guardando...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text:
      'Reflexión diaria guardada satisfactoriamente',
    create_reflection_success_toast_ok_button_text: shared('OK', 'es'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'es',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'es'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'es',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'es',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      'Editar reflexión diaria',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'es',
    ),
    create_reflection_apply_button_text: shared('Save', 'es'),
    create_reflection_saving: 'Guardando...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'es',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'es',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'es'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'es'),
    record_card_factor_ineffective: shared('No Change', 'es'),
    record_card_factor_aggravating: shared('Worse', 'es'),
    record_card_medications_header: shared('Medications', 'es'),
    record_card_medication_generic: shared('Generic', 'es'),
    record_card_delete_action: shared('Delete', 'es'),
    record_card_edit_action: shared('Edit', 'es'),
    record_card_copy_action: 'Duplicar',

    record_card_location_section_header_plural: shared('Locations', 'es'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'es',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'es',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'es',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'es',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'es',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'es'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'es',
    ),
    record_card_notes_section_header: shared('Notes', 'es'),
    record_card_delete_record_alert_title:
      '¿Seguro de que quiere eliminar el registro?',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'es'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'es'),
    record_card_delete_record_toast_text:
      'Registro eliminado satisfactoriamente',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'es'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'es'),
    timing_section_start_time: shared('Start Time', 'es'),
    timing_section_type_header: '¿Qué tipo de dolor es?',
    timing_section_type_constant: shared('Constant', 'es'),
    timing_section_type_intermittent: shared('Intermittent', 'es'),
    timing_section_type_breakthrough: shared('Breakthrough', 'es'),
    timing_section_end_header: '¿Cuándo terminó?',
    timing_section_duration_button: 'Establezca duración',
    timing_section_end_date_button: 'Establezca fecha/hora de fin',
    timing_section_all_day_button: shared('All Day', 'es'),
    timing_section_end_date: shared('End Date', 'es'),
    timing_section_end_time: shared('End Time', 'es'),
    timing_section_duration_Seconds: shared('Seconds', 'es'),
    timing_section_duration_Minutes: shared('Minutes', 'es'),
    timing_section_duration_Hours: shared('Hours', 'es'),
    timing_section_duration_label: shared('Duration', 'es'),
    timing_section_duration_units_label: shared('Units', 'es'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'es',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'es',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header:
      '¿Cómo es su dolor después de tomar este medicamento?',
    medications_section_add_button: shared('Add New', 'es'),
    medications_section_edit_button: shared('Edit List', 'es'),
    medications_section_no_medications:
      'No ha agregado medicamentos a su perfil',
    medications_section_dosage_label: shared('Dosage', 'es'),
    medications_section_dosage_helper_label:
      'introduzca la dosis arriba o elija una debajo',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'es',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'es'),
    medications_section_medication_form_label: shared('Medication Form', 'es'),
    medications_section_generic_medication: shared('Generic', 'es'),
    medications_section_alleviating_button: shared('Better', 'es'),
    medications_section_ineffective_button: shared('No Change', 'es'),
    medications_section_aggravating_button: shared('Worse', 'es'),

    // components/SeveritySlider.js
    factors_section_type_header:
      '¿Cómo es su dolor después de esta intervención?',
    factors_section_alleviating_button: shared('Better', 'es'),
    factors_section_ineffective_button: shared('No Change', 'es'),
    factors_section_aggravating_button: shared('Worse', 'es'),
    factors_section_edit_list_upper_button: shared('Edit List', 'es'),

    // Insights Tab
    insights_tab_nothing_to_show_text:
      'Los registros seleccionados tienen datos insuficientes',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'es'),
    insights_tab_pain_records_severity_average_text: 'Promedio:',
    insights_tab_pain_records_text: shared('Pain Records', 'es') + ':',
    insights_tab_function_graph_header: shared('Function', 'es'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'es') + ':',
    insights_tab_daily_reflections_severity_average_text: 'Promedio:',
    insights_tab_pain_duration_header: shared('Duration', 'es'),
    insights_tab_pain_type_header: shared('Type of Pain', 'es'),
    insights_tab_tool_tip:
      'Basado en el {0}% de {1} ' +
      shared('Pain Records', 'es') +
      ' con información sobre “{2}”',
    insights_tab_meaningful_activities_tool_tip:
      'Basado en el {0}% de {1} ' +
      shared('Daily Reflections', 'es') +
      ' con información sobre “{2}”',
    insights_tab_more_button_text: shared('More', 'es'),
    insights_tab_less_button_text: shared('Less', 'es'),
    insights_tab_location_header: shared('Locations', 'es'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'es'),
    insights_tab_character_header: shared('Characteristics', 'es'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'es',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'es',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'es',
    ),
    insights_tab_environment_header: shared('Environment', 'es'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'es',
    ),
    insights_tab_indicator_new_text: 'NUEVO',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      'No se han agregado registros o no existen registros en el rango de fecha especificado',
    charts_tab_no_records_in_section:
      'No existen registros en la sección especificada',
    charts_tab_tool_tip:
      'Con base en {0}% de {1} registros que contienen información sobre {2}',

    // Calendar Tab
    calendar_tab_select_overlays_button: 'Cambiar superposiciones',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'es'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'es'),
    timeline_tab_pain_record_trendline: shared('Trend', 'es'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'es'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'es'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'es'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'es'),
    timeline_tab_column_function: shared('Function', 'es'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'es'),
    records_tab_view_insights_tab_text: shared('Insights', 'es'),
    records_tab_view_charts_tab_text: shared('Charts', 'es'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'es'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'es'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'es'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'es'),
    services_characteristics_value: shared('Characteristics', 'es'),
    services_type_of_pain_value: shared('Type of Pain', 'es'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'es',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'es'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'es'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'es'),
    services_environment_value: shared('Environment', 'es'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'es'),

    // Results screen
    severity_slider_0_slider_label: 'Ningún Dolor',
    severity_slider_10_slider_label: 'Peor que nunca',
    severity_slider_today_label: 'hoy',
    severity_slider_how_is_your_pain: '¿Cómo está su dolor {0}?',
    severity_slider_how_is_your_pain_today:
      '¿Cómo está su dolor {0} a las {1}?',
    severity_slider_how_is_your_pain_at_date:
      '¿Cómo está su dolor el {0} a las {1}?',
    severity_slider_how_was_your_pain_today:
      '¿Cómo estuvo su dolor {0} a las {1}?',
    severity_slider_how_was_your_pain_at_date:
      '¿Cómo estuvo su dolor el {0} a las {1}?',
    severity_slider_right_now_label: 'ahora mismo',
    severity_slider_change_date_time: 'Cambiar fecha/hora',
    reflection_slider_change_date: 'Cambiar fecha',
    severity_slider_drag_this_slider: 'Arrastre el interruptor',

    // scenes/CreateRecordScene.js
    record_card_show_more: 'mostrar {0} más',
    record_card_details: 'Detalles',
    add_record_loading: shared('Loading...', 'es'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'es'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      'No se han agregado registros. Agregue uno y aparecerá aquí.',
    record_list_no_records_in_date_range:
      'No existen registros en el rango de fecha especificado. Cambie el rango de fecha',
    record_list_no_records_in_filter:
      'Ningún registro cumple con los criterios de filtro especificados. Elimínelo',

    // Services, which populates data for Insights tab
    seconds_one: '{0} segundo',
    seconds_other: '{0} segundos',
    minutes_one: '{0} minuto',
    minutes_other: '{0} minutos',
    hours_one: '{0} hora',
    hours_other: '{0} horas',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0} durante {1}',
    reflection_slider_what_did_you_do_today_label:
      '¿Qué hizo que fue significativo para usted {0}?',
    reflection_slider_what_did_you_do_label:
      '¿Qué hizo que fue significativo para usted el {0}?',
    reflection_slider_today_label: 'hoy',

    reflection_slider_reflection_exists_today_title:
      'La reflexión diaria ya existe hoy',
    reflection_slider_reflection_exists_today_message:
      'Si quiere editar el registro de hoy, hágalo desde la lista de registros. De lo contrario, seleccione otra fecha para la nueva reflexión diaria.',
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'es',
    ),
    reflection_slider_reflection_exists_today_select_date_text:
      'Seleccionar fecha',
    reflection_slider_0_slider_label: 'Nada',
    reflection_slider_10_slider_label: 'Todo lo que quise',

    reflection_slider_reflection_exists:
      'La reflexión diaria existe en la fecha seleccionada',
    reflection_slider_reflection_exists_message:
      'Si quiere editar el registro existente, hágalo desde la lista de registros. De lo contrario, seleccione otra fecha.',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'es',
    ),
    reflection_slider_reflection_exists_select_again_text:
      'Seleccione de nuevo',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: 'Mostrar por página:',
    records_list_pagination_records_per_page_text: '{0} Registros',
  },

  fr: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'fr'),
    results_scene_daterange_toast_button_ok: shared('OK', 'fr'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'fr'),
    results_scene_tab_records_header: shared('Results', 'fr'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text:
      'Enregistrement de la douleur sauvegardé avec succès',
    create_record_saving_record_loading:
      "Sauvegarde d'enregistrement de la douleur en cours…",
    create_record_add_record_screen_header: shared('Add Pain Record', 'fr'),
    create_record_edit_record_screen_header: "Modifier l'enregistrement",
    create_record_back_without_save_confirmation_alert_title:
      "Sauvegarder l'enregistrement et fermer ?",
    create_record_back_without_save_confirmation_alert_msg:
      "Si vous sauvegardez l'enregistrement et fermez, votre enregistrement sera sauvegardé et vous pourrez le modifier ultérieurement.",
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'fr',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'fr',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'fr',
    ),
    create_record_apply_button: shared('Save', 'fr'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'fr',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'fr',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'fr',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'fr',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'fr',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'fr',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'fr',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'fr',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'fr',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'fr',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'fr'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'fr',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'fr'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'fr',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'fr',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'fr',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'fr'),
    create_record_saving: 'Enregistrement...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text:
      'Réflexion quotidienne sauvegardée avec succès',
    create_reflection_success_toast_ok_button_text: shared('OK', 'fr'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'fr'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'fr',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'fr',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      'Modifier la réflexion',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'fr',
    ),
    create_reflection_apply_button_text: shared('Save', 'fr'),
    create_reflection_saving: 'Enregistrement...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'fr',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'fr',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'fr'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'fr'),
    record_card_factor_ineffective: shared('No Change', 'fr'),
    record_card_factor_aggravating: shared('Worse', 'fr'),
    record_card_medications_header: shared('Medications', 'fr'),
    record_card_medication_generic: shared('Generic', 'fr'),
    record_card_delete_action: shared('Delete', 'fr'),
    record_card_edit_action: shared('Edit', 'fr'),
    record_card_copy_action: 'Dupliquer',

    record_card_location_section_header_plural: shared('Locations', 'fr'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'fr',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'fr',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'fr',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'fr',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'fr',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'fr'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'fr',
    ),
    record_card_notes_section_header: shared('Notes', 'fr'),
    record_card_delete_record_alert_title:
      "Êtes-vous sûr de supprimer l'enregistrement ?",
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'fr'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'fr'),
    record_card_delete_record_toast_text: 'Enregistrement supprimé avec succès',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'fr'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'fr'),
    timing_section_start_time: shared('Start Time', 'fr'),
    timing_section_type_header: 'Quel type de douleur avez-vous ?',
    timing_section_type_constant: shared('Constant', 'fr'),
    timing_section_type_intermittent: shared('Intermittent', 'fr'),
    timing_section_type_breakthrough: shared('Breakthrough', 'fr'),
    timing_section_end_header: 'Quand la douleur a-t-elle fini ?',
    timing_section_duration_button: 'Établir durée',
    timing_section_end_date_button: 'Établir date/heure de fin',
    timing_section_all_day_button: shared('All Day', 'fr'),
    timing_section_end_date: shared('End Date', 'fr'),
    timing_section_end_time: shared('End Time', 'fr'),
    timing_section_duration_Seconds: shared('Seconds', 'fr'),
    timing_section_duration_Minutes: shared('Minutes', 'fr'),
    timing_section_duration_Hours: shared('Hours', 'fr'),
    timing_section_duration_label: shared('Duration', 'fr'),
    timing_section_duration_units_label: shared('Units', 'fr'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'fr',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'fr',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header:
      'Comment ressentez-vous la douleur après avoir pris ce médicament ?',
    medications_section_add_button: shared('Add New', 'fr'),
    medications_section_edit_button: shared('Edit List', 'fr'),
    medications_section_no_medications:
      "Vous n'avez pas ajouté des médicaments dans votre profil",
    medications_section_dosage_label: shared('Dosage', 'fr'),
    medications_section_dosage_helper_label:
      'indiquez le dosage ci-dessus ou choisissez-en un ci-dessous',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'fr',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'fr'),
    medications_section_medication_form_label: shared('Medication Form', 'fr'),
    medications_section_generic_medication: shared('Generic', 'fr'),
    medications_section_alleviating_button: shared('Better', 'fr'),
    medications_section_ineffective_button: shared('No Change', 'fr'),
    medications_section_aggravating_button: shared('Worse', 'fr'),

    // components/SeveritySlider.js
    factors_section_type_header:
      'Comment ressentez-vous la douleur après cette intervention ?',
    factors_section_alleviating_button: shared('Better', 'fr'),
    factors_section_ineffective_button: shared('No Change', 'fr'),
    factors_section_aggravating_button: shared('Worse', 'fr'),
    factors_section_edit_list_upper_button: shared('Edit List', 'fr'),

    // Insights Tab
    insights_tab_nothing_to_show_text:
      'Les enregistrements sélectionnés ont des donnés insuffisants',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'fr'),
    insights_tab_pain_records_severity_average_text: 'Moyenne :',
    insights_tab_pain_records_text: shared('Pain Records', 'fr') + ' :',
    insights_tab_function_graph_header: shared('Function', 'fr'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'fr') + ' :',
    insights_tab_daily_reflections_severity_average_text: 'Moyenne :',
    insights_tab_pain_duration_header: shared('Duration', 'fr'),
    insights_tab_pain_type_header: shared('Type of Pain', 'fr'),
    insights_tab_tool_tip:
      'Fondé sur le {0}% de {1} ' +
      shared('Pain Records', 'fr') +
      ' contenant information sur {2}',
    insights_tab_meaningful_activities_tool_tip:
      'Fondé sur le {0}% de {1} ' +
      shared('Daily Reflections', 'fr') +
      ' contenant information sur {2}',
    insights_tab_more_button_text: shared('More', 'fr'),
    insights_tab_less_button_text: shared('Less', 'fr'),
    insights_tab_location_header: shared('Locations', 'fr'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'fr'),
    insights_tab_character_header: shared('Characteristics', 'fr'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'fr',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'fr',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'fr',
    ),
    insights_tab_environment_header: shared('Environment', 'fr'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'fr',
    ),
    insights_tab_indicator_new_text: 'NOUVEAU',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      "Vous n'avez pas ajouté des enregistrements ou il n'y en a pas aucun pour la période spécifiée",
    charts_tab_no_records_in_section:
      "Aucun enregistrement n'existe dans la rubrique spécifiée",
    charts_tab_tool_tip:
      'Basé sur {0} % de {1} enregistrements ayant information sur {2}',

    // Calendar Tab
    calendar_tab_select_overlays_button: 'Changer Éléments',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'fr'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'fr'),
    timeline_tab_pain_record_trendline: shared('Trend', 'fr'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'fr'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'fr'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'fr'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'fr'),
    timeline_tab_column_function: shared('Function', 'fr'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'fr'),
    records_tab_view_insights_tab_text: shared('Insights', 'fr'),
    records_tab_view_charts_tab_text: shared('Charts', 'fr'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'fr'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'fr'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'fr'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'fr'),
    services_characteristics_value: shared('Characteristics', 'fr'),
    services_type_of_pain_value: shared('Type of Pain', 'fr'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'fr',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'fr'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'fr'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'fr'),
    services_environment_value: shared('Environment', 'fr'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'fr'),

    // Results screen
    severity_slider_0_slider_label: 'Aucune Douleur',
    severity_slider_10_slider_label: 'La Plus Mauvaise',
    severity_slider_today_label: "aujourd'hui",
    severity_slider_how_is_your_pain:
      'Comment ressentez-vous votre douleur {0} ?',
    severity_slider_how_is_your_pain_today:
      'Comment ressentez-vous votre douleur {0} à {1} ?',
    severity_slider_how_is_your_pain_at_date:
      'Comment ressentez-vous votre douleur le {0} à {1} ?',
    severity_slider_how_was_your_pain_today:
      'Comment avez-vous ressenti votre douleur {0} à {1} ?',
    severity_slider_how_was_your_pain_at_date:
      'Comment avez-vous ressenti votre douleur le {0} à {1} ?',
    severity_slider_right_now_label: 'maintenant',
    severity_slider_change_date_time: "Modifier la date / l'heure",
    reflection_slider_change_date: 'Modifier la date',
    severity_slider_drag_this_slider: 'Faites glisser ce curseur',

    // scenes/CreateRecordScene.js
    record_card_show_more: 'montrer {0} plus',
    record_card_details: 'Détails',
    add_record_loading: shared('Loading...', 'fr'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'fr'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      'Aucun enregistrement ajouté. Ajoutez un et vous le verrez ici',
    record_list_no_records_in_date_range:
      "Il n'y a pas aucun enregistrement dans la période spécifiée. Changez la période",
    record_list_no_records_in_filter:
      'Aucun enregistrement ne répond pas aux critères spécifiés. Supprimez-le',

    // Services, which populates data for Insights tab
    seconds_one: '{0} seconde',
    seconds_other: '{0} secondes',
    minutes_one: '{0} minute',
    minutes_other: '{0} minutes',
    hours_one: '{0} heure',
    hours_other: '{0} heures',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0} pendant {1}',
    reflection_slider_what_did_you_do_today_label:
      "Qu'avez-vous fait qui a été significatif pour vous {0} ?",
    reflection_slider_what_did_you_do_label:
      "Qu'avez-vous fait qui a été significatif pour vous le {0} ?",
    reflection_slider_today_label: "aujourd'hui",

    reflection_slider_reflection_exists_today_title:
      "La réflexion quotidienne existe déjà pour aujourd'hui",
    reflection_slider_reflection_exists_today_message:
      "Si vous voulez modifier l'enregistrement d'aujourd'hui, faites-le depuis la liste d'enregistrements. Autrement, sélectionnez une autre date pour la nouvelle réflexion quotidienne.",
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'fr',
    ),
    reflection_slider_reflection_exists_today_select_date_text:
      'Sélectionnez une date',
    reflection_slider_0_slider_label: 'Rien',
    reflection_slider_10_slider_label: "Tout ce que j'ai voulu",

    reflection_slider_reflection_exists:
      'La réflexion quotidienne existe dans la date sélectionnée',
    reflection_slider_reflection_exists_message:
      "Si vous voulez modifier l'enregistrement existant, faites-le depuis la liste d'enregistrements. Autrement, sélectionnez une autre date.",
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'fr',
    ),
    reflection_slider_reflection_exists_select_again_text:
      'Sélectionnez à nouveau',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: 'Afficher par page:',
    records_list_pagination_records_per_page_text: '{0} Enregistrements',
  },

  ko: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'ko'),
    results_scene_daterange_toast_button_ok: shared('OK', 'ko'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'ko'),
    results_scene_tab_records_header: shared('Results', 'ko'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text: '통증 기록이 성공적으로 저장되었습니다',
    create_record_saving_record_loading: '통증 기록을 저장하고 있습니다...',
    create_record_add_record_screen_header: shared('Add Pain Record', 'ko'),
    create_record_edit_record_screen_header: '통증 기록을 편집합니다',
    create_record_back_without_save_confirmation_alert_title:
      '기록을 저장하고 닫습니까?',
    create_record_back_without_save_confirmation_alert_msg:
      '기록을 저장하고 닫았다면, 귀하의 기록이 저장되며 나중에 편집할 수 있습니다',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'ko',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'ko',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'ko',
    ),
    create_record_apply_button: shared('Save', 'ko'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'ko',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'ko',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ko',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ko',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'ko',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'ko',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ko',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ko',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'ko',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'ko',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'ko'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'ko',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'ko'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'ko',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'ko',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'ko',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'ko'),
    create_record_saving: '할인...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text:
      '일일 반영이 성공적으로 저장되었습니다',
    create_reflection_success_toast_ok_button_text: shared('OK', 'ko'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'ko'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'ko',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'ko',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      '일일 반영을 편집합니다',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'ko',
    ),
    create_reflection_apply_button_text: shared('Save', 'ko'),
    create_reflection_saving: '할인...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'ko',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'ko',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'ko'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'ko'),
    record_card_factor_ineffective: shared('No Change', 'ko'),
    record_card_factor_aggravating: shared('Worse', 'ko'),
    record_card_medications_header: shared('Medications', 'ko'),
    record_card_medication_generic: shared('Generic', 'ko'),
    record_card_delete_action: shared('Delete', 'ko'),
    record_card_edit_action: shared('Edit', 'ko'),
    record_card_copy_action: '복사',

    record_card_location_section_header_plural: shared('Locations', 'ko'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ko',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'ko',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ko',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'ko',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ko',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'ko'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'ko',
    ),
    record_card_notes_section_header: shared('Notes', 'ko'),
    record_card_delete_record_alert_title: '기록을 삭제하시겠습니까?',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'ko'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'ko'),
    record_card_delete_record_toast_text: '기록이 삭제되었습니다',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'ko'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'ko'),
    timing_section_start_time: shared('Start Time', 'ko'),
    timing_section_type_header: '어떤 종류의 통증인가요?',
    timing_section_type_constant: shared('Constant', 'ko'),
    timing_section_type_intermittent: shared('Intermittent', 'ko'),
    timing_section_type_breakthrough: shared('Breakthrough', 'ko'),
    timing_section_end_header: '언제 끝났어요?',
    timing_section_duration_button: '기간을 설정합니다',
    timing_section_end_date_button: '종료 날짜 / 시간을 설정합니다',
    timing_section_all_day_button: shared('All Day', 'ko'),
    timing_section_end_date: shared('End Date', 'ko'),
    timing_section_end_time: shared('End Time', 'ko'),
    timing_section_duration_Seconds: shared('Seconds', 'ko'),
    timing_section_duration_Minutes: shared('Minutes', 'ko'),
    timing_section_duration_Hours: shared('Hours', 'ko'),
    timing_section_duration_label: shared('Duration', 'ko'),
    timing_section_duration_units_label: shared('Units', 'ko'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'ko',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'ko',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header: '이 약을 먹고 나서 통증이 어떻습니까?',
    medications_section_add_button: shared('Add New', 'ko'),
    medications_section_edit_button: shared('Edit List', 'ko'),
    medications_section_no_medications: '프로필에 약을 추가하지 않았습니다',
    medications_section_dosage_label: shared('Dosage', 'ko'),
    medications_section_dosage_helper_label:
      '그 이상의 복용량을 입력하거나 아래에서 선택하세요',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'ko',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'ko'),
    medications_section_medication_form_label: shared('Medication Form', 'ko'),
    medications_section_generic_medication: shared('Generic', 'ko'),
    medications_section_alleviating_button: shared('Better', 'ko'),
    medications_section_ineffective_button: shared('No Change', 'ko'),
    medications_section_aggravating_button: shared('Worse', 'ko'),

    // components/SeveritySlider.js
    factors_section_type_header: '이 개입 후에 당신의 고통은 어떻습니까?',
    factors_section_alleviating_button: shared('Better', 'ko'),
    factors_section_ineffective_button: shared('No Change', 'ko'),
    factors_section_aggravating_button: shared('Worse', 'ko'),
    factors_section_edit_list_upper_button: shared('Edit List', 'ko'),

    // Insights Tab
    insights_tab_nothing_to_show_text: '선택한 기록에 데이터가 부족합니다',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'ko'),
    insights_tab_pain_records_severity_average_text: '평균:',
    insights_tab_pain_records_text: shared('Pain Records', 'ko') + ':',
    insights_tab_function_graph_header: shared('Function', 'ko'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'ko') + ':',
    insights_tab_daily_reflections_severity_average_text: '평균:',
    insights_tab_pain_duration_header: shared('Duration', 'ko'),
    insights_tab_pain_type_header: shared('Type of Pain', 'ko'),
    insights_tab_tool_tip:
      '“{2}” 정보와 ' +
      shared('Pain Records', 'ko') +
      '을(를) 포함하고 있는 {1} 참가자 중 {0}%에 기초함',
    insights_tab_meaningful_activities_tool_tip:
      '“{2}” 정보와 ' +
      shared('Daily Reflections', 'ko') +
      '을(를) 포함하고 있는 {1} 참가자 중 {0}%에 기초함',
    insights_tab_more_button_text: shared('More', 'ko'),
    insights_tab_less_button_text: shared('Less', 'ko'),
    insights_tab_location_header: shared('Locations', 'ko'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'ko'),
    insights_tab_character_header: shared('Characteristics', 'ko'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'ko',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'ko',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'ko',
    ),
    insights_tab_environment_header: shared('Environment', 'ko'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'ko',
    ),
    insights_tab_indicator_new_text: '신규',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      '기록이 추가되지 않았거나 지정된 날짜 범위에 기록이 없습니다',
    charts_tab_no_records_in_section: '지정한 항목에 기록이 없습니다',
    charts_tab_tool_tip:
      '{2}에 대한 정보가 포함된 {1}개 기록의 {0}%를 기준으로 합니다',

    // Calendar Tab
    calendar_tab_select_overlays_button: '오버레이 변경',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'ko'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'ko'),
    timeline_tab_pain_record_trendline: shared('Trend', 'ko'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'ko'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'ko'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'ko'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'ko'),
    timeline_tab_column_function: shared('Function', 'ko'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'ko'),
    records_tab_view_insights_tab_text: shared('Insights', 'ko'),
    records_tab_view_charts_tab_text: shared('Charts', 'ko'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'ko'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'ko'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'ko'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'ko'),
    services_characteristics_value: shared('Characteristics', 'ko'),
    services_type_of_pain_value: shared('Type of Pain', 'ko'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'ko',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'ko'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'ko'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'ko'),
    services_environment_value: shared('Environment', 'ko'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'ko'),

    // Results screen
    severity_slider_0_slider_label: '통증 없음',
    severity_slider_10_slider_label: '최악',
    severity_slider_today_label: '오늘',
    severity_slider_how_is_your_pain: '{0}의 통증은 어떻습니까?',
    severity_slider_how_is_your_pain_today: '{1}의 통증은 어떻습니까?',
    severity_slider_how_is_your_pain_at_date: '{1}에 {0}의 통증은 어떻습니까?',
    severity_slider_how_was_your_pain_today: '{1}에 {0}의 통증은 어땠습니까?',
    severity_slider_how_was_your_pain_at_date: '{1}에 {0}의 통증은 어땠습니까?',
    severity_slider_right_now_label: '지금 당장',
    severity_slider_change_date_time: '날짜/시간 변경',
    reflection_slider_change_date: '날짜 변경',
    severity_slider_drag_this_slider: '이 슬라이더 드래기하기',

    // scenes/CreateRecordScene.js
    record_card_show_more: '{0}개 더 표시',
    record_card_details: '세부정보',
    add_record_loading: shared('Loading...', 'ko'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'ko'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      '기록이 추가되지 않았습니다. 하나를 추가하면 여기에 나타납니다.',
    record_list_no_records_in_date_range:
      '지정된 날짜 범위에 기록이 없습니다. 날짜 범위를 변경하십시오',
    record_list_no_records_in_filter:
      '지정된 필터 기준을 충족하는 기록이 없습니다. 제거하십시오',

    // Services, which populates data for Insights tab
    seconds_one: '{0}초',
    seconds_other: '{0}초',
    minutes_one: '{0}분',
    minutes_other: '{0}분',
    hours_one: '{0}시간',
    hours_other: '{0}시간',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{1} 동안 {0}',
    reflection_slider_what_did_you_do_today_label:
      '{0} 중요한 것은 무엇입니까?',
    reflection_slider_what_did_you_do_label: '{0} 중요한 것은 무엇입니까?',
    reflection_slider_today_label: '오늘',

    reflection_slider_reflection_exists_today_title:
      '일일 반영이 현재 이미 존재합니다',
    reflection_slider_reflection_exists_today_message:
      '오늘의 기록을 편집하려면 기록 목록을 통해 편집해야 합니다. 그렇지 않으면 새 일일 반영에 대한 다른 날짜를 선택합니다.',
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'ko',
    ),
    reflection_slider_reflection_exists_today_select_date_text: '날짜 선택',
    reflection_slider_0_slider_label: '없음',
    reflection_slider_10_slider_label: '내가 원했던 모든 것',

    reflection_slider_reflection_exists: '선택한 날짜에 일일 반영이 있습니다',
    reflection_slider_reflection_exists_message:
      '오늘의 기록을 편집하려면 기록 목록을 통해 편집해야 합니다. 그렇지 않으면 새 일일 반영에 대한 다른 날짜를 선택합니다.',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'ko',
    ),
    reflection_slider_reflection_exists_select_again_text: '다시 선택하세요',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: '페이지당 표시:',
    records_list_pagination_records_per_page_text: '{0} 기록',
  },

  ru: {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'ru'),
    results_scene_daterange_toast_button_ok: shared('OK', 'ru'),
    results_scene_filter_records_screen_header: shared('Filter Records', 'ru'),
    results_scene_tab_records_header: shared('Results', 'ru'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text: 'Запись о боли сохранена',
    create_record_saving_record_loading: 'Сохранение записи о боли...',
    create_record_add_record_screen_header: shared('Add Pain Record', 'ru'),
    create_record_edit_record_screen_header: 'Изменить запись о боли',
    create_record_back_without_save_confirmation_alert_title:
      'Сохранить и закрыть запись?',
    create_record_back_without_save_confirmation_alert_msg:
      'Если вы сохраните и закроете запись, она будет сохранена, и вы сможете отредактировать её позже',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'ru',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'ru',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'ru',
    ),
    create_record_apply_button: shared('Save', 'ru'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'ru',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'ru',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ru',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'ru',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'ru',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'ru',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ru',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'ru',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'ru',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'ru',
    ),
    create_record_drawer_factors_header: shared('interventions_question', 'ru'),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'ru',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'ru'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'ru',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'ru',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'ru',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared('Notes', 'ru'),
    create_record_saving: 'Сохранение...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text: 'Рефлексия за день сохранена',
    create_reflection_success_toast_ok_button_text: shared('OK', 'ru'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'ru'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'ru',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'ru',
    ),
    create_reflection_edit_daily_reflection_screen_header:
      'Редактировать рефлексию за день',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'ru',
    ),
    create_reflection_apply_button_text: shared('Save', 'ru'),
    create_reflection_saving: 'Сохранение...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'ru',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'ru',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'ru'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'ru'),
    record_card_factor_ineffective: shared('No Change', 'ru'),
    record_card_factor_aggravating: shared('Worse', 'ru'),
    record_card_medications_header: shared('Medications', 'ru'),
    record_card_medication_generic: shared('Generic', 'ru'),
    record_card_delete_action: shared('Delete', 'ru'),
    record_card_edit_action: shared('Edit', 'ru'),
    record_card_copy_action: 'Скопировать',

    record_card_location_section_header_plural: shared('Locations', 'ru'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'ru',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'ru',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'ru',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'ru',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'ru',
    ),
    record_card_environment_section_header_plural: shared('Environment', 'ru'),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'ru',
    ),
    record_card_notes_section_header: shared('Notes', 'ru'),
    record_card_delete_record_alert_title:
      'Уверены, что хотите удалить запись?',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'ru'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'ru'),
    record_card_delete_record_toast_text: 'Запись успешно удалена',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'ru'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'ru'),
    timing_section_start_time: shared('Start Time', 'ru'),
    timing_section_type_header: 'Какой это тип боли?',
    timing_section_type_constant: shared('Constant', 'ru'),
    timing_section_type_intermittent: shared('Intermittent', 'ru'),
    timing_section_type_breakthrough: shared('Breakthrough', 'ru'),
    timing_section_end_header: 'Когда боль прошла?',
    timing_section_duration_button: 'Укажите длительность',
    timing_section_end_date_button: 'Укажите время/дату окончания',
    timing_section_all_day_button: shared('All Day', 'ru'),
    timing_section_end_date: shared('End Date', 'ru'),
    timing_section_end_time: shared('End Time', 'ru'),
    timing_section_duration_Seconds: shared('Seconds', 'ru'),
    timing_section_duration_Minutes: shared('Minutes', 'ru'),
    timing_section_duration_Hours: shared('Hours', 'ru'),
    timing_section_duration_label: shared('Duration', 'ru'),
    timing_section_duration_units_label: shared('Units', 'ru'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'ru',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'ru',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header:
      'Как изменилась боль после приема этого препарата?',
    medications_section_add_button: shared('Add New', 'ru'),
    medications_section_edit_button: shared('Edit List', 'ru'),
    medications_section_no_medications:
      'Вы ещё не добавили препараты в свой профиль',
    medications_section_dosage_label: shared('Dosage', 'ru'),
    medications_section_dosage_helper_label:
      'введите дозировку или выберите значение ниже',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'ru',
    ),
    medications_section_dosage_units_picker_close_button: shared('Close', 'ru'),
    medications_section_medication_form_label: shared('Medication Form', 'ru'),
    medications_section_generic_medication: shared('Generic', 'ru'),
    medications_section_alleviating_button: shared('Better', 'ru'),
    medications_section_ineffective_button: shared('No Change', 'ru'),
    medications_section_aggravating_button: shared('Worse', 'ru'),

    // components/SeveritySlider.js
    factors_section_type_header:
      'Как изменилась боль после этого вмешательства?',
    factors_section_alleviating_button: shared('Better', 'ru'),
    factors_section_ineffective_button: shared('No Change', 'ru'),
    factors_section_aggravating_button: shared('Worse', 'ru'),
    factors_section_edit_list_upper_button: shared('Edit List', 'ru'),

    // Insights Tab
    insights_tab_nothing_to_show_text:
      'Выбранные записи содержат недостаточно данных',
    insights_tab_pain_intensity_graph_header: shared('Pain Intensity', 'ru'),
    insights_tab_pain_records_severity_average_text: 'Среднее:',
    insights_tab_pain_records_text: shared('Pain Records', 'ru') + ':',
    insights_tab_function_graph_header: shared('Function', 'ru'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'ru') + ':',
    insights_tab_daily_reflections_severity_average_text: 'Среднее:',
    insights_tab_pain_duration_header: shared('Duration', 'ru'),
    insights_tab_pain_type_header: shared('Type of Pain', 'ru'),
    insights_tab_tool_tip:
      'Исходя из {0}% от {1} ' +
      shared('Pain Records', 'ru') +
      'содержащих информацию о {2}',
    insights_tab_meaningful_activities_tool_tip:
      'Исходя из {0}% от {1} ' +
      shared('Daily Reflections', 'ru') +
      'содержащих информацию о {2}',
    insights_tab_more_button_text: shared('More', 'ru'),
    insights_tab_less_button_text: shared('Less', 'ru'),
    insights_tab_location_header: shared('Locations', 'ru'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'ru'),
    insights_tab_character_header: shared('Characteristics', 'ru'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'ru',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'ru',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'ru',
    ),
    insights_tab_environment_header: shared('Environment', 'ru'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'ru',
    ),
    insights_tab_indicator_new_text: 'НОВОЕ',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      'В выбранный период времени записи не добавлялись или не существуют',
    charts_tab_no_records_in_section: 'В указанном разделе записей нет',
    charts_tab_tool_tip:
      'Исходя из {0} % из {1} записей содержащих информацию о {2}',

    // Calendar Tab
    calendar_tab_select_overlays_button: 'Изменить инфослои',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'ru'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'ru'),
    timeline_tab_pain_record_trendline: shared('Trend', 'ru'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'ru'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'ru'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'ru'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'ru'),
    timeline_tab_column_function: shared('Function', 'ru'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'ru'),
    records_tab_view_insights_tab_text: shared('Insights', 'ru'),
    records_tab_view_charts_tab_text: shared('Charts', 'ru'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'ru'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'ru'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'ru'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'ru'),
    services_characteristics_value: shared('Characteristics', 'ru'),
    services_type_of_pain_value: shared('Type of Pain', 'ru'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'ru',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'ru'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'ru'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'ru'),
    services_environment_value: shared('Environment', 'ru'),
    services_meaningful_activities_value: shared('Meaningful Activities', 'ru'),

    // Results screen
    severity_slider_0_slider_label: 'Нет боли',
    severity_slider_10_slider_label: 'Очень больно',
    severity_slider_today_label: 'сегодня',
    severity_slider_how_is_your_pain: 'Как оцените свою боль {0}?',
    severity_slider_how_is_your_pain_today: 'Как оцените свою боль {0} в {1}?',
    severity_slider_how_is_your_pain_at_date:
      'Как оцените свою боль {0} в {1}?',
    severity_slider_how_was_your_pain_today: 'Как оцените свою боль {0} в {1}?',
    severity_slider_how_was_your_pain_at_date:
      'Как оцените свою боль {0} в {1}?',
    severity_slider_right_now_label: 'прямо сейчас',
    severity_slider_change_date_time: 'Изменить дату/время',
    reflection_slider_change_date: 'Изменить дату',
    severity_slider_drag_this_slider: 'Потяните ползунок',

    // scenes/CreateRecordScene.js
    record_card_show_more: 'показать еще {0}',
    record_card_details: 'Подробнее',
    add_record_loading: shared('Loading...', 'ru'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'ru'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      'Записи не добавлены — добавьте запись и она появится здесь',
    record_list_no_records_in_date_range:
      'За выбранный период времени записей нет — измените период',
    record_list_no_records_in_filter:
      'Заданному критерию фильтра не отвечает ни одна запись — удалите критерий',

    // Services, which populates data for Insights tab
    seconds_one: '{0} сек.',
    seconds_other: '{0} сек.',
    minutes_one: '{0} мин.',
    minutes_other: '{0} мин.',
    hours_one: '{0} ч.',
    hours_other: '{0} ч.',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0}; длительность: {1}',
    reflection_slider_what_did_you_do_today_label:
      'Что значимое для вас вы сделали {0}?',
    reflection_slider_what_did_you_do_label:
      'Что значимое для вас вы сделали {0}?',
    reflection_slider_today_label: 'сегодня',

    reflection_slider_reflection_exists_today_title:
      'Ежедневная рефлексия за сегодня уже существует',
    reflection_slider_reflection_exists_today_message:
      'Если хотите изменить запись за сегодня, сделайте это через список записей. В ином случае выберите другую дату и введите новую рефлексию.',
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'ru',
    ),
    reflection_slider_reflection_exists_today_select_date_text: 'Выбрать дату',
    reflection_slider_0_slider_label: 'Ничего',
    reflection_slider_10_slider_label: 'Всё, что планировал',

    reflection_slider_reflection_exists:
      'В выбранную дату рефлексия за день уже существует',
    reflection_slider_reflection_exists_message:
      'Если хотите изменить существующую запись, сделайте это через список записей.  В ином случае, выберите другую дату.',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'ru',
    ),
    reflection_slider_reflection_exists_select_again_text: 'Выберите еще раз',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: 'Показать:',
    records_list_pagination_records_per_page_text: '{0} Записей',
  },

  'zh-rCN': {
    // ./services.js
    // Charts tab
    results_scene_daterange_reset_toast: shared('date_range_changed', 'zh-rCN'),
    results_scene_daterange_toast_button_ok: shared('OK', 'zh-rCN'),
    results_scene_filter_records_screen_header: shared(
      'Filter Records',
      'zh-rCN',
    ),
    results_scene_tab_records_header: shared('Results', 'zh-rCN'),

    // components/Chart.js
    // components/FactorsListSectionContent.js
    create_record_success_toast_text: '疼痛记录已成功保存',
    create_record_saving_record_loading: '正在保存疼痛记录...',
    create_record_add_record_screen_header: shared('Add Pain Record', 'zh-rCN'),
    create_record_edit_record_screen_header: '编辑疼痛记录',
    create_record_back_without_save_confirmation_alert_title: '保存记录并关闭?',
    create_record_back_without_save_confirmation_alert_msg:
      '如果您保存记录并关闭，记录将被保存，您可以稍后进行编辑',
    create_record_back_without_save_confirmation_cancel_button_text: shared(
      'Cancel',
      'zh-rCN',
    ),
    create_record_back_without_save_confirmation_yes_button_text: shared(
      'Save',
      'zh-rCN',
    ),
    create_record_back_without_save_confirmation_no_button_text: shared(
      'Discard',
      'zh-rCN',
    ),
    create_record_apply_button: shared('Save', 'zh-rCN'),
    create_record_drawer_location_section_header_plural: shared(
      'Locations',
      'zh-rCN',
    ),
    create_record_drawer_location_section_header_secondary: shared(
      'locations_question',
      'zh-rCN',
    ),
    create_record_drawer_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    create_record_drawer_symptoms_section_header_secondary: shared(
      'other_associated_symptoms_question',
      'zh-rCN',
    ),
    create_record_drawer_character_section_header_plural: shared(
      'Characteristics',
      'zh-rCN',
    ),
    create_record_drawer_character_section_header_secondary: shared(
      'characteristics_question',
      'zh-rCN',
    ),
    create_record_drawer_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    create_record_drawer_aggravating_factors_section_header_secondary: shared(
      'aggravating_factors_question',
      'zh-rCN',
    ),
    create_record_drawer_medication_header: shared(
      'medications_question',
      'zh-rCN',
    ),
    create_record_drawer_medication_secondary_header: shared(
      'Medications',
      'zh-rCN',
    ),
    create_record_drawer_factors_header: shared(
      'interventions_question',
      'zh-rCN',
    ),
    create_record_drawer_factors_secondary_header: shared(
      'Interventions',
      'zh-rCN',
    ),
    create_record_drawer_timing_section_header: shared('Timing', 'zh-rCN'),
    create_record_drawer_timing_section_header_secondary: shared(
      'timing_question',
      'zh-rCN',
    ),
    create_record_drawer_environment_section_header_plural: shared(
      'Environment',
      'zh-rCN',
    ),
    create_record_drawer_environment_section_header_secondary: shared(
      'environment_question',
      'zh-rCN',
    ),
    create_record_drawer_notes_section_header: null,
    create_record_drawer_notes_section_header_secondary: shared(
      'Notes',
      'zh-rCN',
    ),
    create_record_saving: '保存...',

    // components/Insights.js, components/InsightsSection.js, components/Indicator.js
    // components/MedicationListSectionContent.js
    create_reflection_success_toast_text: '每日回顾成功保存',
    create_reflection_success_toast_ok_button_text: shared('OK', 'zh-rCN'),
    create_reflection_alert_back_without_confirmation_text: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    create_reflection_alert_back_without_confirmation_cancel_button_text:
      shared('Cancel', 'zh-rCN'),
    create_reflection_alert_back_without_confirmation_no_button_text: shared(
      'Discard',
      'zh-rCN',
    ),
    create_reflection_alert_back_without_confirmation_yes_button_text: shared(
      'Save',
      'zh-rCN',
    ),
    create_reflection_edit_daily_reflection_screen_header: '编辑每日回顾',
    create_reflection_add_daily_reflection_screen_header: shared(
      'Add Daily Reflection',
      'zh-rCN',
    ),
    create_reflection_apply_button_text: shared('Save', 'zh-rCN'),
    create_reflection_saving: '保存...',

    // components/NotesSectionContent.js
    reflection_drawer_meaningful_activities_header: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    reflection_drawer_meaningful_activities_header_secondary: shared(
      'meaningful_activities_question',
      'zh-rCN',
    ),
    reflection_drawer_notes_header: null,
    reflection_drawer_notes_header_secondary: shared('Notes', 'zh-rCN'),

    // components/RecordCard.js
    record_card_factor_alleviating: shared('Better', 'zh-rCN'),
    record_card_factor_ineffective: shared('No Change', 'zh-rCN'),
    record_card_factor_aggravating: shared('Worse', 'zh-rCN'),
    record_card_medications_header: shared('Medications', 'zh-rCN'),
    record_card_medication_generic: shared('Generic', 'zh-rCN'),
    record_card_delete_action: shared('Delete', 'zh-rCN'),
    record_card_edit_action: shared('Edit', 'zh-rCN'),
    record_card_copy_action: '复制',

    record_card_location_section_header_plural: shared('Locations', 'zh-rCN'),
    record_card_symptoms_section_header_plural: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    record_card_character_section_header_plural: shared(
      'Characteristics',
      'zh-rCN',
    ),
    record_card_aggravating_factors_section_header_plural: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    record_card_alleviating_factors_section_header_plural: shared(
      'Alleviating Factors',
      'zh-rCN',
    ),
    record_card_ineffective_factors_section_header_plural: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    record_card_environment_section_header_plural: shared(
      'Environment',
      'zh-rCN',
    ),
    record_card_meaningful_activities_section_header_plural: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    record_card_notes_section_header: shared('Notes', 'zh-rCN'),
    record_card_delete_record_alert_title: '您确定要删除记录吗？',
    record_card_delete_record_alert_yes_button_text: shared('Delete', 'zh-rCN'),
    record_card_delete_record_alert_no_button_text: shared('Cancel', 'zh-rCN'),
    record_card_delete_record_toast_text: '记录成功删除',
    record_card_delete_record_toast_ok_button_text: shared('OK', 'zh-rCN'),

    // components/RecordsTabView.js
    timing_section_start_date: shared('Start Date', 'zh-rCN'),
    timing_section_start_time: shared('Start Time', 'zh-rCN'),
    timing_section_type_header: '这是什么类型的疼痛？',
    timing_section_type_constant: shared('Constant', 'zh-rCN'),
    timing_section_type_intermittent: shared('Intermittent', 'zh-rCN'),
    timing_section_type_breakthrough: shared('Breakthrough', 'zh-rCN'),
    timing_section_end_header: '这次疼痛什么时候结束？',
    timing_section_duration_button: '设定持续时间',
    timing_section_end_date_button: '设定结束日期/时间',
    timing_section_all_day_button: shared('All Day', 'zh-rCN'),
    timing_section_end_date: shared('End Date', 'zh-rCN'),
    timing_section_end_time: shared('End Time', 'zh-rCN'),
    timing_section_duration_Seconds: shared('Seconds', 'zh-rCN'),
    timing_section_duration_Minutes: shared('Minutes', 'zh-rCN'),
    timing_section_duration_Hours: shared('Hours', 'zh-rCN'),
    timing_section_duration_label: shared('Duration', 'zh-rCN'),
    timing_section_duration_units_label: shared('Units', 'zh-rCN'),
    timing_section_toast_error_start_end_time_text: shared(
      'date_range_error',
      'zh-rCN',
    ),
    timing_section_toast_error_start_end_time_ok_button_text: shared(
      'OK',
      'zh-rCN',
    ),

    // components/ReflectionSliderComponent
    medications_section_type_header: '服用这种药物后疼痛情况怎么样？',
    medications_section_add_button: shared('Add New', 'zh-rCN'),
    medications_section_edit_button: shared('Edit List', 'zh-rCN'),
    medications_section_no_medications: '您尚未在个人档案中添加任何药物',
    medications_section_dosage_label: shared('Dosage', 'zh-rCN'),
    medications_section_dosage_helper_label: '在上方输入剂量或从下方选一',
    medications_section_dosage_units_picker_header: shared(
      'Medication Form',
      'zh-rCN',
    ),
    medications_section_dosage_units_picker_close_button: shared(
      'Close',
      'zh-rCN',
    ),
    medications_section_medication_form_label: shared(
      'Medication Form',
      'zh-rCN',
    ),
    medications_section_generic_medication: shared('Generic', 'zh-rCN'),
    medications_section_alleviating_button: shared('Better', 'zh-rCN'),
    medications_section_ineffective_button: shared('No Change', 'zh-rCN'),
    medications_section_aggravating_button: shared('Worse', 'zh-rCN'),

    // components/SeveritySlider.js
    factors_section_type_header: '此次干预后您的疼痛情况怎么样？',
    factors_section_alleviating_button: shared('Better', 'zh-rCN'),
    factors_section_ineffective_button: shared('No Change', 'zh-rCN'),
    factors_section_aggravating_button: shared('Worse', 'zh-rCN'),
    factors_section_edit_list_upper_button: shared('Edit List', 'zh-rCN'),

    // Insights Tab
    insights_tab_nothing_to_show_text: '所选记录包含的数据不足',
    insights_tab_pain_intensity_graph_header: shared(
      'Pain Intensity',
      'zh-rCN',
    ),
    insights_tab_pain_records_severity_average_text: '平均:',
    insights_tab_pain_records_text: shared('Pain Records', 'zh-rCN') + ':',
    insights_tab_function_graph_header: shared('Function', 'zh-rCN'),
    insights_tab_daily_reflections_text:
      shared('Daily Reflections', 'zh-rCN') + ':',
    insights_tab_daily_reflections_severity_average_text: '平均:',
    insights_tab_pain_duration_header: shared('Duration', 'zh-rCN'),
    insights_tab_pain_type_header: shared('Type of Pain', 'zh-rCN'),
    insights_tab_tool_tip:
      '基于包含“{2}”信息的#v的{1}条' +
      shared('Pain Records', 'zh-rCN') +
      '中的{0}%',
    insights_tab_meaningful_activities_tool_tip:
      '基于包含“{2}”信息的#v的{1}条' +
      shared('Daily Reflections', 'zh-rCN') +
      '中的{0}%',
    insights_tab_more_button_text: shared('More', 'zh-rCN'),
    insights_tab_less_button_text: shared('Less', 'zh-rCN'),
    insights_tab_location_header: shared('Locations', 'zh-rCN'),
    insights_tab_symptom_header: shared('Other Associated Symptoms', 'zh-rCN'),
    insights_tab_character_header: shared('Characteristics', 'zh-rCN'),
    insights_tab_alleviating_factor_for_calculation_header: shared(
      'Alleviating Factors',
      'zh-rCN',
    ),
    insights_tab_aggravating_factor_for_calculation_header: shared(
      'Aggravating Factors',
      'zh-rCN',
    ),
    insights_tab_ineffective_factor_for_calculation_header: shared(
      'Ineffective Factors',
      'zh-rCN',
    ),
    insights_tab_environment_header: shared('Environment', 'zh-rCN'),
    insights_tab_meaningful_activities_header: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    insights_tab_indicator_new_text: '新',

    // Charts Tab
    charts_tab_nothing_to_show_text:
      '未添加任何记录或指定的日期范围内不存在记录',
    charts_tab_no_records_in_section: '指定部分不存在記錄',
    charts_tab_tool_tip: '基于{1}条记录中的{0}%，其中包含有关{2}的信息',

    // Calendar Tab
    calendar_tab_select_overlays_button: '修改图示',

    //Timeline tab
    timeline_tab_pain_intensity_graph: shared('Daily Average', 'zh-rCN'),
    timeline_tab_function_graph: shared('Daily Reflection Score', 'zh-rCN'),
    timeline_tab_pain_record_trendline: shared('Trend', 'zh-rCN'),
    timeline_tab_daily_reflection_trendline: shared('Trend', 'zh-rCN'),
    timeline_tab_pain_intensity_daily_high: shared('Daily High', 'zh-rCN'),
    timeline_tab_pain_intensity_daily_low: shared('Daily Low', 'zh-rCN'),
    timeline_tab_column_pain_severity: shared('Pain Intensity', 'zh-rCN'),
    timeline_tab_column_function: shared('Function', 'zh-rCN'),

    // Tab Selector
    records_tab_view_records_tab_text: shared('Records', 'zh-rCN'),
    records_tab_view_insights_tab_text: shared('Insights', 'zh-rCN'),
    records_tab_view_charts_tab_text: shared('Charts', 'zh-rCN'),
    records_tab_view_calendar_tab_text: shared('Calendar', 'zh-rCN'),
    records_tab_view_timeline_tab_text: shared('Timeline', 'zh-rCN'),

    // Record card
    notes_section_hint_label: shared('notes_hint_label', 'zh-rCN'),

    // Records screen, root tab view
    // Reflection Slider
    services_pain_locations_value: shared('Locations', 'zh-rCN'),
    services_characteristics_value: shared('Characteristics', 'zh-rCN'),
    services_type_of_pain_value: shared('Type of Pain', 'zh-rCN'),
    services_other_associated_symptoms_value: shared(
      'Other Associated Symptoms',
      'zh-rCN',
    ),
    services_alleviating_factors_value: shared('Alleviating Factors', 'zh-rCN'),
    services_aggravating_factors_value: shared('Aggravating Factors', 'zh-rCN'),
    services_ineffective_factors_value: shared('Ineffective Factors', 'zh-rCN'),
    services_environment_value: shared('Environment', 'zh-rCN'),
    services_meaningful_activities_value: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),

    // Results screen
    severity_slider_0_slider_label: '不疼',
    severity_slider_10_slider_label: '从来没有这么疼过',
    severity_slider_today_label: '今天',
    severity_slider_how_is_your_pain: '{0}您的疼痛怎么样？',
    severity_slider_how_is_your_pain_today: '{0}{1}时您的疼痛怎么样？',
    severity_slider_how_is_your_pain_at_date: '{0}{1}时您的疼痛怎么样？',
    severity_slider_how_was_your_pain_today: '{0}{1}时您的疼痛怎么样？',
    severity_slider_how_was_your_pain_at_date: '{0}{1}时您的疼痛怎么样？',
    severity_slider_right_now_label: '现在',
    severity_slider_change_date_time: '更改日期 / 时间',
    reflection_slider_change_date: '更改日期',
    severity_slider_drag_this_slider: '拖动滑块',

    // scenes/CreateRecordScene.js
    record_card_show_more: '显示更多{0}条',
    record_card_details: '详情',
    add_record_loading: shared('Loading...', 'zh-rCN'),

    // scenes/CreateReflectionScene.js
    record_card_all_day_timing: shared('All Day', 'zh-rCN'),

    // scenes/ResultsViewScene.js
    record_list_no_records_added:
      '没有添加记录 - 添加一条记录，它就会显示在这里。',
    record_list_no_records_in_date_range:
      '指定日期范围内不存在记录 - 更改日期范围',
    record_list_no_records_in_filter: '没有记录符合指定的过滤条件 - 移除条件',

    // Services, which populates data for Insights tab
    seconds_one: '{0}秒',
    seconds_other: '{0}秒',
    minutes_one: '{0}分钟',
    minutes_other: '{0}分钟',
    hours_one: '{0}小时',
    hours_other: '{0}小时',

    // Timing section
    // Below string is "Breakthrough for 45 minutes"
    record_card_record_timing: '{0}，持续{1}',
    reflection_slider_what_did_you_do_today_label:
      '{0}, 您做了哪些有意义的事情？',
    reflection_slider_what_did_you_do_label: '{0}, 您做了哪些有意义的事情？',
    reflection_slider_today_label: '今天',

    reflection_slider_reflection_exists_today_title: '今天已经有每日回顾',
    reflection_slider_reflection_exists_today_message:
      '如果要编辑今天的记录，请通过“记录”列表进行编辑。否则，请为选择其他日期添加每日回顾。',
    reflection_slider_reflection_exists_today_cancel_button_text: shared(
      'Cancel',
      'zh-rCN',
    ),
    reflection_slider_reflection_exists_today_select_date_text: '选择日期',
    reflection_slider_0_slider_label: '没有',
    reflection_slider_10_slider_label: '所有我想做的',

    reflection_slider_reflection_exists: '选定日期已经有每日回顾',
    reflection_slider_reflection_exists_message:
      '如果要编辑现有记录，请通过“记录”列表进行编辑。否则，请选择其他日期。',
    reflection_slider_reflection_exists_cancel_button_text: shared(
      'Cancel',
      'zh-rCN',
    ),
    reflection_slider_reflection_exists_select_again_text: '再次选择',

    //components.RecordListPagination.web.js
    records_list_pagination_show_per_page_text: '显示在每一页上:',
    records_list_pagination_records_per_page_text: '{0} 记录',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
