import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [];

const STRINGS = new LocalizedStrings({
  en: {
    delete_account_warning_text:
      'You are requesting that your account be deleted. Once deleted, your information will be permanently removed and cannot be retrieved. If you would like to proceed, type "DELETE" below and press "Delete Account"',
    delete_account_delete_field: 'Type "DELETE" here',
    delete_account_delete_text: 'DELETE',
    delete_account_delete_button: 'Delete account',
    delete_account_cancel_button: shared('Cancel', 'en'),
    delete_account_deleted_toast:
      'Your account is being deleted - you will receive an email confirmation once completed.',
    delete_account_processing_text: shared('Loading...', 'en'),

    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: 'Save Profile',
    my_profile_subscription_plan_section_header: 'Subscription Plan & Credits',
    my_profile_account_information_section_header: 'Account Information',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'en'),
    my_profile_medications_section_header: shared('Medications', 'en'),
    my_profile_about_me_section_header: 'About Me',
    my_profile_login_button_header: 'Log in to see more options',
    my_profile_login_button_text: shared('Log In', 'en'),
    my_profile_logout_button_text: shared('Log Out', 'en'),
    my_profile_screen_header: shared('My Profile', 'en'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'en',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'en',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'en'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'en'),
    my_profile_save_success_toast_text: 'Profile saved successfully',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'en'),
    my_profile_overlay_saving_profile_text: 'Saving profile...',
    my_profile_overlay_loading_profile_text: 'Loading profile...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: 'Account Management',
    account_management_connect_with: 'Connect with',
    account_management_access_and_information: 'Access & Information',
    account_management_others: 'Others',
    account_information_loading_credits_placeholder: 'Loading credits...',
    account_information_username_label: 'Username',
    account_information_email_label: shared('Email address', 'en'),
    account_information_password_label: shared('Password', 'en'),
    account_information_set_password_label: 'Password has not been set',
    account_information_set_password_button_text: 'Set',
    account_information_set_password_success_text:
      'Password reset requested – check your email',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'en',
    ),
    account_information_reset_password_ok_button: shared('OK', 'en'),
    account_information_email_subscriptions_label:
      'Email Subscription preferences',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      'Uploading avatar failed - max file size is 2Mb',

    avatar_area_refresh_photo_from_facebook_option_text:
      'Refresh photo from Facebook',
    avatar_area_select_photo_from_facebook_option_text:
      'Select photo from Facebook',
    avatar_area_select_photo_from_google_option_text:
      'Select photo from Google',
    avatar_area_refresh_photo_from_google_option_text:
      'Refresh photo from Google',
    avatar_area_remove_photo_option_text: 'Remove photo',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'en'),
    avatar_area_no_connected_account_toast_text:
      'You must connect account to Facebook or Google',
    avatar_area_take_photo_option_text: 'Take new photo',
    avatar_area_choose_from_library_option_text: 'Select from gallery',
    avatar_area_edit_photo_title: 'Crop Photo',
    avatar_area_take_photo_camera_request_description:
      'This permission is needed so you can take a photo and set it as your profile image',
    avatar_area_choose_from_library_request_description:
      'This permission is needed so you can select an image and set it as your profile image',
    avatar_area_edit_photo_choose_button: 'Select image',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'en'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'en'),
    about_me_gender_label: shared('Gender', 'en'),
    about_me_gender_other_label: 'Other gender',
    about_me_birthday_label: shared('Birth Date', 'en'),
    about_me_height_label: shared('Height', 'en'),
    about_me_feet_label: 'Feet',
    about_me_inches_label: 'Inches',
    about_me_height_units_label: shared('Units', 'en'),
    about_me_weight_label: shared('Weight', 'en'),
    about_me_weight_units_label: shared('Units', 'en'),
    about_me_country_label: 'Country of residence',
    about_me_country_not_set_label: '(Not Set)',
    about_me_gender_m: 'Male',
    about_me_gender_f: 'Female',
    about_me_gender_o: 'Other',
    about_me_height_units_c: 'centimeters',
    about_me_height_units_i: 'feet/inches',
    about_me_weight_units_k: 'kilograms',
    about_me_weight_units_p: 'pounds',
    about_me_weight_units_s: 'stones',
  },

  de: {
    delete_account_warning_text:
      'Sie beantragen die Löschung Ihres Kontos. Nach der Löschung werden Ihre Daten dauerhaft entfernt und können nicht mehr abgerufen werden. Falls Sie fortfahren möchten, geben Sie unten "LÖSCHEN" ein und klicken Sie auf "Konto löschen".',
    delete_account_delete_field: 'Geben Sie hier "LÖSCHEN" ein',
    delete_account_delete_text: 'LÖSCHEN',
    delete_account_delete_button: 'Konto löschen',
    delete_account_cancel_button: shared('Cancel', 'de'),
    delete_account_deleted_toast:
      'Ihr Konto wird gelöscht - Sie erhalten eine E-Mail-Bestätigung, sobald dies abgeschlossen ist.',
    delete_account_processing_text: shared('Loading...', 'de'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: 'Profil speichern',
    my_profile_subscription_plan_section_header: 'Abonnement & Guthabenpunkte',
    my_profile_account_information_section_header: 'Kontoinformationen',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'de'),
    my_profile_medications_section_header: shared('Medications', 'de'),
    my_profile_about_me_section_header: 'Über mich',
    my_profile_login_button_header:
      'Melden Sie sich an, um weitere Optionen zu sehen',
    my_profile_login_button_text: shared('Log In', 'de'),
    my_profile_logout_button_text: shared('Log Out', 'de'),
    my_profile_screen_header: shared('My Profile', 'de'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'de',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'de',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'de'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'de'),
    my_profile_save_success_toast_text: 'Profil erfolgreich gespeichert',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'de'),
    my_profile_overlay_saving_profile_text: 'Speichere Profil...',
    my_profile_overlay_loading_profile_text: 'Lade Profil...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: 'Kontoinformationen',
    account_management_access_and_information: 'Zugang & Informationen',
    account_management_others: 'Anderes',
    account_information_loading_credits_placeholder: 'Lade Guthabenpunkte...',
    account_information_username_label: 'Benutzername',
    account_information_email_label: shared('Email address', 'de'),
    account_information_password_label: shared('Password', 'de'),
    account_information_set_password_label: 'Passwort wurde nicht festgelegt',
    account_information_set_password_button_text: 'Festlegen',
    account_information_set_password_success_text:
      'Passwort zurücksetzen angefordert - überprüfen Sie Ihren Posteingang',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'de',
    ),
    account_information_reset_password_ok_button: shared('OK', 'de'),
    account_information_email_subscriptions_label: 'Abonnement-Einstellungen',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      'Hochladen des Avatars fehlgeschlagen - maximale Dateigröße ist 2Mb',

    avatar_area_refresh_photo_from_facebook_option_text:
      'Foto via Facebook aktualisieren',
    avatar_area_select_photo_from_facebook_option_text:
      'Foto von Facebook auswählen',
    avatar_area_select_photo_from_google_option_text:
      'Foto von Google auswählen',
    avatar_area_refresh_photo_from_google_option_text:
      'Foto via Google aktualisieren',
    avatar_area_remove_photo_option_text: 'Foto entfernen',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'de'),
    avatar_area_no_connected_account_toast_text:
      'Sie müssen Ihr Konto mit Facebook oder Google verknüpfen',
    avatar_area_take_photo_option_text: 'Neues Foto aufnehmen',
    avatar_area_choose_from_library_option_text: 'Aus Galerie auswählen',
    avatar_area_edit_photo_title: 'Foto zuschneiden',
    avatar_area_take_photo_camera_request_description:
      'Diese Berechtigung wird benötigt, damit Sie ein Foto aufnehmen und als Ihr Profilbild festlegen können',
    avatar_area_choose_from_library_request_description:
      'Diese Berechtigung wird benötigt, damit Sie ein Bild auswählen und als Ihr Profilbild festlegen können',
    avatar_area_edit_photo_choose_button: 'Bild auswählen',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'de'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'de'),
    about_me_gender_label: shared('Gender', 'de'),
    about_me_gender_other_label: 'Anderes Geschlecht',
    about_me_birthday_label: shared('Birth Date', 'de'),
    about_me_height_label: shared('Height', 'de'),
    about_me_feet_label: 'Fuß',
    about_me_inches_label: 'Zoll',
    about_me_height_units_label: shared('Units', 'de'),
    about_me_weight_label: shared('Weight', 'de'),
    about_me_weight_units_label: shared('Units', 'de'),
    about_me_country_label: 'Wohnsitz',
    about_me_country_not_set_label: '(Nicht Angegeben)',
    about_me_gender_m: 'Männlich',
    about_me_gender_f: 'Weiblich',
    about_me_gender_o: 'Anderes',
    about_me_height_units_c: 'Zentimeter',
    about_me_height_units_i: 'Fuß/Zoll',
    about_me_weight_units_k: 'Kilogramm',
    about_me_weight_units_p: 'Pfund',
    about_me_weight_units_s: 'Stein',
  },

  es: {
    delete_account_warning_text:
      'Usted ha solicitado eliminar su cuenta. Una vez eliminada, su información será borrada de forma permanente y no podrá recuperarse. Si desea continuar, introduzca "ELIMINAR" a continuación y pulse "Eliminar cuenta"',
    delete_account_delete_field: 'Introduzca "ELIMINAR" aquí',
    delete_account_delete_text: 'ELIMINAR',
    delete_account_delete_button: 'Eliminar cuenta',
    delete_account_cancel_button: shared('Cancel', 'es'),
    delete_account_deleted_toast:
      'Su cuenta está siendo eliminada. Recibirá una confirmación por correo electrónico una vez finalizado el proceso.',
    delete_account_processing_text: shared('Loading...', 'es'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: 'Guardar perfil',
    my_profile_subscription_plan_section_header:
      'Planes de suscripción y créditos',
    my_profile_account_information_section_header: 'Información de cuenta',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'es'),
    my_profile_medications_section_header: shared('Medications', 'es'),
    my_profile_about_me_section_header: 'Acerca de mí',
    my_profile_login_button_header: 'Inicie sesión para ver más opciones',
    my_profile_login_button_text: shared('Log In', 'es'),
    my_profile_logout_button_text: shared('Log Out', 'es'),
    my_profile_screen_header: shared('My Profile', 'es'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'es',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'es',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'es'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'es'),
    my_profile_save_success_toast_text: 'Perfil guardado satisfactoriamente',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'es'),
    my_profile_overlay_saving_profile_text: 'Guardando perfil…',
    my_profile_overlay_loading_profile_text: 'Cargando perfil…',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: 'Información de cuenta',
    account_management_access_and_information: 'Acceso e información',
    account_management_others: 'Otros',
    account_information_loading_credits_placeholder: 'Cargando créditos…',
    account_information_username_label: 'Nombre de usuario',
    account_information_email_label: shared('Email address', 'es'),
    account_information_password_label: shared('Password', 'es'),
    account_information_set_password_label: 'No creó una contraseña',
    account_information_set_password_button_text: 'Crear',
    account_information_set_password_success_text:
      'Restablecimiento de contraseña solicitado. Revise su correo',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'es',
    ),
    account_information_reset_password_ok_button: shared('OK', 'es'),
    account_information_email_subscriptions_label:
      'Preferencias de suscripción',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      'Fallo al cargar la imagen del avatar. El tamaño máximo del archivo es 2MB.',
    avatar_area_refresh_photo_from_facebook_option_text:
      'Actualizar foto desde Facebook',
    avatar_area_select_photo_from_facebook_option_text:
      'Seleccionar foto desde Facebook',
    avatar_area_select_photo_from_google_option_text:
      'Seleccionar foto desde Google',
    avatar_area_refresh_photo_from_google_option_text:
      'Actualizar foto desde Google',
    avatar_area_remove_photo_option_text: 'Eliminar foto',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'es'),
    avatar_area_no_connected_account_toast_text:
      'Debe conectar la cuenta a Facebook o a Google',
    avatar_area_take_photo_option_text: 'Tomar nueva fotografía',
    avatar_area_choose_from_library_option_text: 'Seleccionar de la galería',
    avatar_area_edit_photo_title: 'Recortar fotografía',
    avatar_area_take_photo_camera_request_description:
      'Se requiere este permiso para que pueda sacarse una fotografía y establecerla como su imagen de perfil',
    avatar_area_choose_from_library_request_description:
      'Se requiere este permiso para que pueda seleccionar una imagen y establecerla como su imagen de perfil',
    avatar_area_edit_photo_choose_button: 'Seleccionar imagen',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'es'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'es'),
    about_me_gender_label: shared('Gender', 'es'),
    about_me_gender_other_label: 'Otro género',
    about_me_birthday_label: shared('Birth Date', 'es'),
    about_me_height_label: shared('Height', 'es'),
    about_me_feet_label: 'Pies',
    about_me_inches_label: 'Pulgadas',
    about_me_height_units_label: shared('Units', 'es'),
    about_me_weight_label: shared('Weight', 'es'),
    about_me_weight_units_label: shared('Units', 'es'),
    about_me_country_label: 'País de residencia',
    about_me_country_not_set_label: '(No establecido)',
    about_me_gender_m: 'Masculino',
    about_me_gender_f: 'Femenino',
    about_me_gender_o: 'Otro',
    about_me_height_units_c: 'centímetros',
    about_me_height_units_i: 'Pies/Pulgadas',
    about_me_weight_units_k: 'kilogramos',
    about_me_weight_units_p: 'libras',
    about_me_weight_units_s: 'piedras',
  },

  fr: {
    delete_account_warning_text:
      'Vous demandez la suppression de votre compte. Une fois supprimées, vos informations seront définitivement effacées et ne pourront pas être récupérées. Si vous souhaitez poursuivre, tapez "SUPPRIMER" ci-dessous et appuyez sur "Supprimer le compte".',
    delete_account_delete_field: 'Tapez "SUPPRIMER" ici',
    delete_account_delete_text: 'SUPPRIMER',
    delete_account_delete_button: 'Supprimer le compte',
    delete_account_cancel_button: shared('Cancel', 'fr'),
    delete_account_deleted_toast:
      'Votre compte est en cours de suppression. Vous recevrez un e-mail de confirmation une fois cette opération terminée.',
    delete_account_processing_text: shared('Loading...', 'fr'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: 'Enregistrer le profil',
    my_profile_subscription_plan_section_header:
      "Plans et crédits d'abonnement",
    my_profile_account_information_section_header: 'Informations du Compte',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'fr'),
    my_profile_medications_section_header: shared('Medications', 'fr'),
    my_profile_about_me_section_header: 'À propos de moi',
    my_profile_login_button_header: "Connectez-vous pour voir plus d'options",
    my_profile_login_button_text: shared('Log In', 'fr'),
    my_profile_logout_button_text: shared('Log Out', 'fr'),
    my_profile_screen_header: shared('My Profile', 'fr'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'fr',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'fr',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'fr'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'fr'),
    my_profile_save_success_toast_text: 'Profil sauvegardé avec succès',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'fr'),
    my_profile_overlay_saving_profile_text: 'Sauvegarde de profil en cours…',
    my_profile_overlay_loading_profile_text: 'Chargement de profil en cours...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: 'Informations du Compte',
    account_management_access_and_information: 'Accès et informations',
    account_management_others: 'Autres',
    account_information_loading_credits_placeholder:
      'Chargement de crédits en cours…',
    account_information_username_label: "Nom d'utilisateur",
    account_information_email_label: shared('Email address', 'fr'),
    account_information_password_label: shared('Password', 'fr'),
    account_information_set_password_label: "Le mot de passe n'a pas été créé",
    account_information_set_password_button_text: 'Créer',
    account_information_set_password_success_text:
      'Réinitialisation du mot de passe demandée. Vérifiez votre boîte de réception',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'fr',
    ),
    account_information_reset_password_ok_button: shared('OK', 'fr'),
    account_information_email_subscriptions_label: "Préférences d'abonnement",

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      "Le chargement de l'avatar a échoué - la taille de fichier maximale est de 2 Mo.",
    avatar_area_refresh_photo_from_facebook_option_text:
      'Actualiser la photo depuis Facebook ',
    avatar_area_select_photo_from_facebook_option_text:
      'Sélectionner la photo depuis Facebook',
    avatar_area_select_photo_from_google_option_text:
      'Sélectionner la photo depuis Google',
    avatar_area_refresh_photo_from_google_option_text:
      'Actualiser la photo depuis Google',
    avatar_area_remove_photo_option_text: 'Supprimer la photo',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'fr'),
    avatar_area_no_connected_account_toast_text:
      'Vous devez connecter le compte à Facebook ou Google',
    avatar_area_take_photo_option_text: 'Prendre une nouvelle photo',
    avatar_area_choose_from_library_option_text:
      'Choisir à partir de la galerie',
    avatar_area_edit_photo_title: 'Recadrer la photo',
    avatar_area_take_photo_camera_request_description:
      'Cette permission est requise pour prendre une photo et la définir comme image de profil',
    avatar_area_choose_from_library_request_description:
      'Cette permission est requise pour choisir une image et la définir comme image de profil',
    avatar_area_edit_photo_choose_button: 'Choisir une image',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'fr'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'fr'),
    about_me_gender_label: shared('Gender', 'fr'),
    about_me_gender_other_label: 'Outre genre',
    about_me_birthday_label: shared('Birth Date', 'fr'),
    about_me_height_label: shared('Height', 'fr'),
    about_me_feet_label: 'Pieds',
    about_me_inches_label: 'Pouces',
    about_me_height_units_label: shared('Units', 'fr'),
    about_me_weight_label: shared('Weight', 'fr'),
    about_me_weight_units_label: shared('Units', 'fr'),
    about_me_country_label: 'Pays de Résidence',
    about_me_country_not_set_label: '(Non défini)',
    about_me_gender_m: 'homme',
    about_me_gender_f: 'femme',
    about_me_gender_o: 'Autre',
    about_me_height_units_c: 'centimètres',
    about_me_height_units_i: 'pieds/pouces',
    about_me_weight_units_k: 'kilogrammes',
    about_me_weight_units_p: 'livres',
    about_me_weight_units_s: 'stones',
  },

  ko: {
    delete_account_warning_text:
      '계정 삭제를 요청하고 있습니다. 삭제되면 정보가 영구적으로 제거되고 검색할 수 없습니다. 계속하려면 아래에 삭제"를 입력하고 "계정삭제"를 누르십시오.',
    delete_account_delete_field: '여기에 "삭제"를 입력합니다.',
    delete_account_delete_text: '삭제',
    delete_account_delete_button: '계정삭제',
    delete_account_cancel_button: shared('Cancel', 'ko'),
    delete_account_deleted_toast:
      '계정이 삭제되고 있습니다. 완료되면 전자 메일 확인 메시지를 받게 됩니다.',
    delete_account_processing_text: shared('Loading...', 'ko'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: '프로필 저장',
    my_profile_subscription_plan_section_header: '구독 플랜 & 크레딧',
    my_profile_account_information_section_header: '계정 정보',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'ko'),
    my_profile_medications_section_header: shared('Medications', 'ko'),
    my_profile_about_me_section_header: '내 소개',
    my_profile_login_button_header: '로그인하여 더 많은 옵션을 확인하세요',
    my_profile_login_button_text: shared('Log In', 'ko'),
    my_profile_logout_button_text: shared('Log Out', 'ko'),
    my_profile_screen_header: shared('My Profile', 'ko'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'ko',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'ko',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'ko'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'ko'),
    my_profile_save_success_toast_text: '프로필이 성공적으로 저장됨',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'ko'),
    my_profile_overlay_saving_profile_text: '프로필을 저장하는 중...',
    my_profile_overlay_loading_profile_text: '프로필을 로드하는 중...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: '계정 정보',
    account_management_access_and_information: '가입 정보',
    account_management_others: '기타',
    account_information_loading_credits_placeholder: '크레딧 로드 중...',
    account_information_username_label: '사용자 이름',
    account_information_email_label: shared('Email address', 'ko'),
    account_information_password_label: shared('Password', 'ko'),
    account_information_set_password_label: '암호가 설정되지 않음',
    account_information_set_password_button_text: '세트',
    account_information_set_password_success_text:
      '암호 재설정이 요청되었습니다 - 이메일을 확인하세요',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'ko',
    ),
    account_information_reset_password_ok_button: shared('OK', 'ko'),
    account_information_email_subscriptions_label: '구속 설정',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      '아바타 업로드 실패 - 최대 파일 크기 2MB',
    avatar_area_refresh_photo_from_facebook_option_text:
      '페이스북에서 사진 새로 고침',
    avatar_area_select_photo_from_facebook_option_text:
      '페이스북에서 사진 선택',
    avatar_area_select_photo_from_google_option_text: '구글에서 사진 선택',
    avatar_area_refresh_photo_from_google_option_text:
      '구글에서서 사진 새로 고침',
    avatar_area_remove_photo_option_text: '사진 제거',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'ko'),
    avatar_area_no_connected_account_toast_text:
      '계정을 페이스북 또는 구글에 연결해야 합니다',
    avatar_area_take_photo_option_text: '사진 촬영',
    avatar_area_choose_from_library_option_text: '갤러리에서 선택',
    avatar_area_edit_photo_title: '사진 자르기',
    avatar_area_take_photo_camera_request_description:
      '사진을 찍어 프로필 이미지로 설정하려면 이 권한이 필요합니다.',
    avatar_area_choose_from_library_request_description:
      '이미지를 선택하고 프로필 이미지로 설정하려면 이 권한이 필요합니다.',
    avatar_area_edit_photo_choose_button: '이미지 선택',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'ko'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'ko'),
    about_me_gender_label: shared('Gender', 'ko'),
    about_me_gender_other_label: '다른 성별',
    about_me_birthday_label: shared('Birth Date', 'ko'),
    about_me_height_label: shared('Height', 'ko'),
    about_me_feet_label: '피트',
    about_me_inches_label: '인치',
    about_me_height_units_label: shared('Units', 'ko'),
    about_me_weight_label: shared('Weight', 'ko'),
    about_me_weight_units_label: shared('Units', 'ko'),
    about_me_country_label: '거주 국가',
    about_me_country_not_set_label: '(설정 안 됨)',
    about_me_gender_m: '남성',
    about_me_gender_f: '여성',
    about_me_gender_o: '기타',
    about_me_height_units_c: '센치미터',
    about_me_height_units_i: '피트/인치',
    about_me_weight_units_k: '킬로그램',
    about_me_weight_units_p: '파운드',
    about_me_weight_units_s: '스톤',
  },

  ru: {
    delete_account_warning_text:
      'Вы просите удалить ваш аккаунт. После удаления аккаунта ваша информация будет уничтожена навсегда и не подлежит восстановлению. Если вы хотите продолжить, введите слово «УДАЛИТЬ» ниже и нажмите «Удалить аккаунт».',
    delete_account_delete_field: 'Введите здесь слово «УДАЛИТЬ»',
    delete_account_delete_text: 'УДАЛИТЬ',
    delete_account_delete_button: 'Удалить аккаунт',
    delete_account_cancel_button: shared('Cancel', 'ru'),
    delete_account_deleted_toast:
      'Аккаунт удаляется; после завершения процесса вы получите подтверждение на свой email.',
    delete_account_processing_text: shared('Loading...', 'ru'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: 'Сохранить профиль',
    my_profile_subscription_plan_section_header: 'План подписки и кредиты',
    my_profile_account_information_section_header: 'Информация об аккаунте',
    my_profile_pain_conditions_section_header: shared('Pain Conditions', 'ru'),
    my_profile_medications_section_header: shared('Medications', 'ru'),
    my_profile_about_me_section_header: 'Обо мне',
    my_profile_login_button_header:
      'Для дополнительных возможностей войдите в аккаунт',
    my_profile_login_button_text: shared('Log In', 'ru'),
    my_profile_logout_button_text: shared('Log Out', 'ru'),
    my_profile_screen_header: shared('My Profile', 'ru'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'ru',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'ru',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared('Save', 'ru'),
    my_profile_back_without_save_alert_no_button_text: shared('Discard', 'ru'),
    my_profile_save_success_toast_text: 'Профиль сохранен',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'ru'),
    my_profile_overlay_saving_profile_text: 'Сохранение профиля...',
    my_profile_overlay_loading_profile_text: 'Загрузка профиля...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: 'Информация об аккаунте',
    account_management_access_and_information: 'Учетные данные доступа',
    account_management_others: 'Прочее',
    account_information_loading_credits_placeholder: 'Загрузка кредитов...',
    account_information_username_label: 'Имя пользователя',
    account_information_email_label: shared('Email address', 'ru'),
    account_information_password_label: shared('Password', 'ru'),
    account_information_set_password_label: 'Пароль не задан',
    account_information_set_password_button_text: 'Задать',
    account_information_set_password_success_text:
      'Запрошен сброс пароля — проверьте свою почту',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'ru',
    ),
    account_information_reset_password_ok_button: shared('OK', 'ru'),
    account_information_email_subscriptions_label: 'Настройки рассылки',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast:
      'Не удалось загрузить аватар — файл должен быть меньше 2 МБ.',
    avatar_area_refresh_photo_from_facebook_option_text:
      'Обновить фото с Facebook',
    avatar_area_select_photo_from_facebook_option_text:
      'Выбрать фото с Facebook',
    avatar_area_select_photo_from_google_option_text: 'Выбрать фото с Google',
    avatar_area_refresh_photo_from_google_option_text: 'Обновить фото с Google',
    avatar_area_remove_photo_option_text: 'Удалить фото',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'ru'),
    avatar_area_no_connected_account_toast_text:
      'Нужно привязать аккаунт Facebook или Google',
    avatar_area_take_photo_option_text: 'Сделать фото',
    avatar_area_choose_from_library_option_text: 'Выбрать из галереи',
    avatar_area_edit_photo_title: 'Обрезать фото',
    avatar_area_take_photo_camera_request_description:
      'Разрешение нужно для съемки фото и его установки в качестве фото профиля',
    avatar_area_choose_from_library_request_description:
      'Разрешение нужно для выбора изображения и его установки в качестве фото профиля',
    avatar_area_edit_photo_choose_button: 'Выбрать изображение',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'ru'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'ru'),
    about_me_gender_label: shared('Gender', 'ru'),
    about_me_gender_other_label: 'Укажите нужное значение',
    about_me_birthday_label: shared('Birth Date', 'ru'),
    about_me_height_label: shared('Height', 'ru'),
    about_me_feet_label: 'Ф.',
    about_me_inches_label: 'Д.',
    about_me_height_units_label: shared('Units', 'ru'),
    about_me_weight_label: shared('Weight', 'ru'),
    about_me_weight_units_label: shared('Units', 'ru'),
    about_me_country_label: 'Страна проживания',
    about_me_country_not_set_label: '(Не указано)',
    about_me_gender_m: 'Муж',
    about_me_gender_f: 'Жен',
    about_me_gender_o: 'Другой',
    about_me_height_units_c: 'сантиметры',
    about_me_height_units_i: 'Ф. / Д.',
    about_me_weight_units_k: 'килограммов',
    about_me_weight_units_p: 'фунт',
    about_me_weight_units_s: 'стоун',
  },

  'zh-rCN': {
    delete_account_warning_text:
      '您请求删除帐户。一旦删除，所有信息将被永久删除，无法恢复。如要继续，请在下方输入"删除"，然后按 "删除帐户"',
    delete_account_delete_field: '在此处输入 "删除"',
    delete_account_delete_text: '删除',
    delete_account_delete_button: '删除帐户',
    delete_account_cancel_button: shared('Cancel', 'zh-rCN'),
    delete_account_deleted_toast:
      '您的帐户正在被删除 - 完成后，您会收到一封电子邮件确认。',
    delete_account_processing_text: shared('Loading...', 'zh-rCN'),
    // My profile screen,
    // components/MyProfileDrawer.js, scenes/MyProfile.js
    my_profile_save_profile_button_text: '保存个人档案',
    my_profile_subscription_plan_section_header: '订阅计划和点数',
    my_profile_account_information_section_header: '账户信息',
    my_profile_pain_conditions_section_header: shared(
      'Pain Conditions',
      'zh-rCN',
    ),
    my_profile_medications_section_header: shared('Medications', 'zh-rCN'),
    my_profile_about_me_section_header: '关于我自己',
    my_profile_login_button_header: '请登录查看更多选项',
    my_profile_login_button_text: shared('Log In', 'zh-rCN'),
    my_profile_logout_button_text: shared('Log Out', 'zh-rCN'),
    my_profile_screen_header: shared('My Profile', 'zh-rCN'),
    my_profile_back_without_save_alert_title: shared(
      'Would you like to save your changes?',
      'zh-rCN',
    ),
    my_profile_back_without_save_alert_cancel_button_text: shared(
      'Cancel',
      'zh-rCN',
    ),
    my_profile_back_without_save_alert_yes_button_text: shared(
      'Save',
      'zh-rCN',
    ),
    my_profile_back_without_save_alert_no_button_text: shared(
      'Discard',
      'zh-rCN',
    ),
    my_profile_save_success_toast_text: '档案已成功保存',
    my_profile_save_success_toast_ok_button_text: shared('OK', 'zh-rCN'),
    my_profile_overlay_saving_profile_text: '正在保存档案...',
    my_profile_overlay_loading_profile_text: '加载档案...',

    // AccountInformation section
    // containers/AccountInformation.js
    account_management: '账户信息',
    account_management_access_and_information: '访问 & 信息',
    account_management_others: '其他',
    account_information_loading_credits_placeholder: '加载点数...',
    account_information_username_label: '用户名',
    account_information_email_label: shared('Email address', 'zh-rCN'),
    account_information_password_label: shared('Password', 'zh-rCN'),
    account_information_set_password_label: '尚未设置密码',
    account_information_set_password_button_text: '设置',
    account_information_set_password_success_text:
      '已请求重置密码 - 请查收电子邮件',
    account_information_reset_password_success_text: shared(
      'reset_password_success',
      'zh-rCN',
    ),
    account_information_reset_password_ok_button: shared('OK', 'zh-rCN'),
    account_information_email_subscriptions_label: '订阅首选项',

    // My profile screen
    // containers/AvatarArea.js
    avatar_area_file_size_limit_toast: '上传头像失败 - 文件上限为 2Mb',
    avatar_area_refresh_photo_from_facebook_option_text: '从Facebook刷新照片',
    avatar_area_select_photo_from_facebook_option_text: '从Facebook选择照片',
    avatar_area_select_photo_from_google_option_text: '从Google选择照片',
    avatar_area_refresh_photo_from_google_option_text: '从Google刷新照片',
    avatar_area_remove_photo_option_text: '移除照片',
    avatar_area_cancel_photo_selection_option_text: shared('Cancel', 'zh-rCN'),
    avatar_area_no_connected_account_toast_text:
      '您必须将账户连接到Facebook或Google',
    avatar_area_take_photo_option_text: '拍张新照片',
    avatar_area_choose_from_library_option_text: '从图库中选择',
    avatar_area_edit_photo_title: '裁剪照片',
    avatar_area_take_photo_camera_request_description:
      '需要此权限才能拍照并将其设置为用戶头像',
    avatar_area_choose_from_library_request_description:
      '需要此权限才能选择图片并将其设置为用戶头像',
    avatar_area_edit_photo_choose_button: '选择图片',
    avatar_area_edit_photo_cancel_button: shared('Cancel', 'zh-rCN'),

    // My profile screen
    // components/AboutMeSectionContent
    about_me_name_label: shared('Name', 'zh-rCN'),
    about_me_gender_label: shared('Gender', 'zh-rCN'),
    about_me_gender_other_label: '其他性别',
    about_me_birthday_label: shared('Birth Date', 'zh-rCN'),
    about_me_height_label: shared('Height', 'zh-rCN'),
    about_me_feet_label: '英尺',
    about_me_inches_label: '英寸',
    about_me_height_units_label: shared('Units', 'zh-rCN'),
    about_me_weight_label: shared('Weight', 'zh-rCN'),
    about_me_weight_units_label: shared('Units', 'zh-rCN'),
    about_me_country_label: '常住国家',
    about_me_country_not_set_label: '未设置',
    about_me_gender_m: '男性',
    about_me_gender_f: '女性',
    about_me_gender_o: '其它',
    about_me_height_units_c: '厘米',
    about_me_height_units_i: '英尺/英寸',
    about_me_weight_units_k: '千克',
    about_me_weight_units_p: '磅',
    about_me_weight_units_s: '英石',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
