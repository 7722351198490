import React, { useEffect } from 'react';
import { NativeModules } from 'react-native';
import { designConstants } from '../../../design/styles';
import getConfig from '../../../shared/utilities/getConfig';
import { appOpen } from '../../Intro/actions';
import { setDateRange } from '../../Filter/actions';
import { useDispatch, useSelector } from 'react-redux';
import SplashView from '../components/SplashView';
import { webTokenOutdated } from '../../Api/actions';
import moment from 'moment';
import crashlytics from '@react-native-firebase/crashlytics';
import { trackUserProperties } from '../../../shared/utilities/answers';
import { retrieveQuestionnaires } from '../../Questionnaires/actions';
import * as MMPApi from 'apiclient';

const { SettingsModule } = NativeModules;

// Needed for notifications
export const tasksAfterStoreCheckerInit = [];
export const storeCheckerReplacedInitScreen = [false];

export default function SplashScene(props) {
  console.log('SLOAD', 12, new Date().getTime());
  //Configure hooks
  const dispatch = useDispatch();
  // Dirty hack which allows us to set api server dynamically
  console.log('MMPAPI', MMPApi);
  let api = new MMPApi.RecordCallsApi();
  api.apiClient.basePath = getConfig('MMP_API_SERVER');

  //Enable fabric report if we not on web
  const report_usage = useSelector((state) => state.settings.report_usage);
  if (!designConstants.isWeb) {
    if (report_usage) {
      crashlytics().setCrashlyticsCollectionEnabled(true);
    } else {
      crashlytics().setCrashlyticsCollectionEnabled(false);
    }
  }

  //Also configure google sign in for native screens
  if (!designConstants.isWeb) {
    require('@react-native-google-signin/google-signin').GoogleSignin.configure(
      {
        scopes: [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile',
        ], // what API you want to access on behalf of the user, default is email and profile
        iosClientId: getConfig('MMP_GOOGLE_SIGNIN_IOS_CLIENT_ID'), // only for iOS
        webClientId: getConfig('MMP_GOOGLE_SIGNIN_WEB_CLIENT_ID'), // client ID of type WEB for your server (needed to verify user ID and offline access)
        offlineAccess: true, // if you want to access Google API on behalf of the user FROM YOUR SERVER
        forceConsentPrompt: true, // [Android] if you want to show the authorization prompt at each login
        accountName: '', // [Android] specifies an account name on the device that should be used
      },
    );
  }

  dispatch(appOpen());
  const isInstitutionMember = useSelector(
    (state) => !!state.auth?.user?.is_institution_member,
  );

  //Set proper "login_status" user property
  const loggedIn = useSelector((state) => !!state.auth?.user);
  const is_institution_member = useSelector(
    (state) => !!state.auth.user?.is_institution_member || 0,
  );
  trackUserProperties(loggedIn, is_institution_member);

  if (isInstitutionMember) {
    console.log('retrieve questionnaires');
    dispatch(retrieveQuestionnaires());
  }

  let dateRange = useSelector(
    (state) => state.filter?.current?.dateRange ?? '30days',
  );
  let is_pro = useSelector((state) => state.auth?.is_pro);
  let is_subscription = useSelector((state) => state.auth?.is_subscription);
  let keep_alive_timeout = useSelector(
    (state) => state.api?.keep_alive_timeout,
  );
  console.log('TIMEOUT KEEPALIVE', keep_alive_timeout, moment().valueOf());
  if (
    designConstants.isWeb &&
    keep_alive_timeout > 0 &&
    moment().valueOf() > keep_alive_timeout
  ) {
    console.log(
      'TIMEOUT KEEPALIVE LAUNCH',
      keep_alive_timeout,
      moment().valueOf(),
    );
    dispatch(webTokenOutdated());
  }
  useEffect(() => {
    if (!is_pro && !is_subscription && dateRange !== '30days') {
      dispatch(setDateRange('30days', true));
    }
  }, [dispatch, dateRange, is_pro, is_subscription]);

  // setTimeout(() => {
  console.log('SLOAD', 13, new Date().getTime());
  storeCheckerReplacedInitScreen[0] = true;
  props?.onInitialized();
  // }, 200);

  return <SplashView />;
}
