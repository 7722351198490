//report types
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const REPORT_CHECK_SECTION = 'REPORT_CHECK_SECTION';
export const REPORT_CHECK_FIELD = 'REPORT_CHECK_FIELD';
export const REPORT_SET_SORT = 'REPORT_SET_SORT';
export const REPORT_SET_SORT_ORDER = 'REPORT_SET_SORT_ORDER';
export const GET_PREVIOUS_REPORTS = 'GET_PREVIOUS_REPORTS';
export const GET_PREVIOUS_REPORTS_SUCCESS = 'GET_PREVIOUS_REPORTS_SUCCESS';
export const FILTER_APPLY_REPORT = 'FILTER_APPLY_REPORT';
export const API_ERROR = 'API_ERROR';
