export const PAIN_GUIDE_GET_CATEGORIES = 'PAIN_GUIDE_GET_CATEGORIES';
export const PAIN_GUIDE_GET_CATEGORIES_SUCCESS =
  'PAIN_GUIDE_GET_CATEGORIES_SUCCESS';
export const PAIN_GUIDE_GET_BOOKMARKED = 'PAIN_GUIDE_GET_BOOKMARKED';
export const PAIN_GUIDE_GET_BOOKMARKED_SUCCESS =
  'PAIN_GUIDE_GET_BOOKMARKED_SUCCESS';
export const PAIN_GUIDE_GET_CONTENT = 'PAIN_GUIDE_GET_CONTENT';
export const PAIN_GUIDE_GET_CONTENT_SUCCESS = 'PAIN_GUIDE_GET_CONTENT_SUCCESS';
export const PAIN_GUIDE_GET_FEEDBACK = 'PAIN_GUIDE_GET_FEEDBACK';
export const PAIN_GUIDE_GET_FEEDBACK_SUCCESS =
  'PAIN_GUIDE_GET_FEEDBACK_SUCCESS';
export const PAIN_GUIDE_SEND_FEEDBACK = 'PAIN_GUIDE_GET_FEEDBACK';
export const PAIN_GUIDE_SEND_FEEDBACK_SUCCESS =
  'PAIN_GUIDE_GET_FEEDBACK_SUCCESS';
export const PAIN_GUIDE_GET_CONTENT_BODY = 'PAIN_GUIDE_GET_CONTENT_BODY';
export const PAIN_GUIDE_GET_CONTENT_BODY_SUCCESS =
  'PAIN_GUIDE_GET_CONTENT_BODY_SUCCESS';
export const PAIN_GUIDE_GET_RECOMMENDED = 'PAIN_GUIDE_GET_RECOMMENDED';
export const PAIN_GUIDE_GET_RECOMMENDED_SUCCESS =
  'PAIN_GUIDE_GET_RECOMMENDED_SUCCESS';
export const PAIN_GUIDE_GET_PROGRAMS = 'PAIN_GUIDE_GET_PROGRAMS';
export const PAIN_GUIDE_GET_PROGRAMS_SUCCESS =
  'PAIN_GUIDE_GET_PROGRAMS_SUCCESS';
export const PAIN_GUIDE_GET_PROGRAM_BODY = 'PAIN_GUIDE_GET_PROGRAM_BODY';
export const PAIN_GUIDE_GET_PROGRAM_BODY_SUCCESS =
  'PAIN_GUIDE_GET_PROGRAM_BODY_SUCCESS';
export const PAIN_GUIDE_GET_PROGRAM_PROGRESS =
  'PAIN_GUIDE_GET_PROGRAM_PROGRESS';
export const PAIN_GUIDE_UPDATE_PROGRAM_PROGRESS =
  'PAIN_GUIDE_UPDATE_PROGRAM_PROGRESS';
export const PAIN_GUIDE_UPDATE_PROGRAM_PROGRESS_SUCCESS =
  'PAIN_GUIDE_UPDATE_PROGRAM_PROGRESS_SUCCESS';
export const PAIN_GUIDE_GET_PROGRAM_BODY_PROGRESS_SUCCESS =
  'PAIN_GUIDE_GET_PROGRAM_BODY_PROGRESS_SUCCESS';
export const PAIN_GUIDE_LIST_CONTENT_BY_CATEGORY =
  'PAIN_GUIDE_LIST_CONTENT_BY_CATEGORY';
export const PAIN_GUIDE_LIST_CONTENT_BY_CATEGORY_SUCCESS =
  'PAIN_GUIDE_LIST_CONTENT_BY_CATEGORY_SUCCESS';
export const PAIN_GUIDE_ADD_TO_BOOKMARK = 'PAIN_GUIDE_ADD_TO_BOOKMARK';
export const PAIN_GUIDE_ADD_TO_BOOKMARK_SUCCESS =
  'PAIN_GUIDE_ADD_TO_BOOKMARK_SUCCESS';
export const PAIN_GUIDE_REMOVE_FROM_BOOKMARK =
  'PAIN_GUIDE_REMOVE_FROM_BOOKMARK';
export const PAIN_GUIDE_REMOVE_FROM_BOOKMARK_SUCCESS =
  'PAIN_GUIDE_REMOVE_FROM_BOOKMARK_SUCCESS';
export const PAIN_GUIDE_SET_INTERACTION_STATUS =
  'PAIN_GUIDE_SET_INTERACTION_STATUS';
