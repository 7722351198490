import Moment from 'moment';
import { getOffset } from '../../shared/utilities/timeutils';

export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

function isEmpty(filter) {
  return (
    filter.pain_records &&
    filter.daily_reflections &&
    filter.severity === 0 &&
    Object.keys(filter.medications).length > 0 &&
    filter.severityEnd === 10 &&
    filter.score === 0 &&
    filter.scoreEnd === 0 &&
    filter.time_mode === 'all' &&
    filter.duration_mode === 'all' &&
    filter.type_of_pain.length === 0 &&
    filter.dateRange === 'alltime'
  );
}

function reduceFields(filter) {
  let result = filter.fields.slice();
  console.log('filter reduce fields', filter.severityEnd, filter.scoreEnd);
  if (filter.severity > 0 || filter.severityEnd < 10) {
    result.push({
      field: 'severity',
      compare: severityComparator(filter.severityEnd),
      value: filter.severity,
    });
  }
  if (filter.score > 0 || filter.scoreEnd < 10) {
    result.push({
      field: 'score',
      compare: severityComparator(filter.scoreEnd),
      value: filter.score,
    });
  }
  // Date range now moved to separate "filtering"
  // if(filter.date_mode !== 'all') {
  //     result.push({field: 'recordTime', compare: dateComparator(filter.date_mode, filter.date_end), value: filter.date})
  // }
  if (filter.time_mode !== 'all') {
    result.push({
      field: 'recordTime',
      compare: timeComparator(filter.time_mode),
      value: filter.time,
    });
  }
  if (filter.duration_mode !== 'all') {
    result.push({
      field: 'lengthOfPainValue',
      compare: comparator(filter.duration_mode),
      value: filter.duration,
    });
  }
  if (filter.type_of_pain.length > 0) {
    result.push({
      field: 'lengthOfPainType',
      compare: typeOfPainComparator(),
      value: filter.type_of_pain,
    });
  }
  if (filter.factors && Object.keys(filter.factors).length > 0) {
    Object.keys(filter.factors).forEach((id) => {
      filter.factors[id].type.forEach((type) => {
        let field = type + '_factor';
        result.push({ field, id: filter.factors[id].id });
      });
    });
  }
  if (filter.medications && Object.keys(filter.medications).length > 0) {
    Object.keys(filter.medications).forEach((nid) => {
      result.push({
        field: 'medications',
        compare: medicationsComparator(),
        value: filter.medications[nid],
      });
    });
  }
  return result;
}

function typeOfPainComparator() {
  return (a, b) => b.includes(a);
}

function medicationsComparator() {
  return (recordMeds, filterMed) => {
    return (
      recordMeds.hasOwnProperty(filterMed.id) &&
      filterMed.type.includes(recordMeds[filterMed.id].typeOfMedication)
    );
  };
}

function dateComparator(comp, date_end) {
  switch (comp) {
    case '<':
      return (a, b) => a <= b;
    case '>':
      return (a, b) => a >= b;
    case '<>':
      return (a, b) => a >= b && a <= date_end;
    default:
      return (a, b) => Moment(a).isSame(Moment(b), 'day');
  }
}

function timeComparator(comp) {
  switch (comp) {
    case '<':
      return (a, b) => a % MILLISECONDS_IN_DAY < b;
    case '>':
      return (a, b) => (a % MILLISECONDS_IN_DAY) - (a % 60000) > b; // a%60000 needed to also allow records with "seconds" returned also
    default:
      return (a, b) => {
        return (
          Math.floor((a % MILLISECONDS_IN_DAY) / 1000 / 60) ==
          Math.floor(b / 1000 / 60)
        );
      };
  }
}

function severityComparator(end) {
  return (a, b) => a >= b && a <= end;
}
function comparator(comp) {
  switch (comp) {
    case '<':
      return (a, b) => a < b;
    case '>':
      return (a, b) => a > b;
    default:
      return (a, b) => a == b;
  }
}

export function dateFromDateRange(filter, dateRange) {
  let date;
  dateRange = dateRange || '30days';
  if (dateRange === '7days') {
    date = Moment().add(-7, 'day').startOf('day').valueOf();
  } else if (dateRange === '30days') {
    date = Moment().add(-30, 'day').startOf('day').valueOf();
  } else if (dateRange === 'alltime') {
    date = filter.first_day;
  } else {
    date = filter.current.date;
  }
  return date;
}

export function applyDateRange(records, filter) {
  let comparator;
  let date;
  let dateRange = filter.dateRange || '30days';
  if (dateRange === '7days') {
    comparator = dateComparator('<>', Moment().endOf('day').valueOf());
    date = Moment().add(-7, 'day').startOf('day').valueOf();
  } else if (dateRange === '30days') {
    comparator = dateComparator('<>', Moment().endOf('day').valueOf());
    date = Moment().add(-30, 'day').startOf('day').valueOf();
  } else if (dateRange === 'alltime') {
    return records;
  } else {
    // custom
    comparator = dateComparator('<>', filter.date_end);
    date = filter.date;
  }
  let offset = getOffset(Moment().valueOf());
  return records.filter((record) => {
    return comparator(record.recordTime + record.timeOffset - offset, date);
  });
}

export function filterRecords(records, filter) {
  let func = filter.mode === 'any' ? 'some' : 'every';
  let none =
    filter.mode === 'none'
      ? (val) => {
          return !val;
        }
      : (val) => {
          return val;
        };

  let fields = reduceFields(filter);
  console.log('filter fields', fields);
  let result = records.filter((record) => {
    if (!filter.pain_records && record.type === 'PainRecord') {
      return false;
    }
    if (!filter.daily_reflections && record.type === 'DailyReflection') {
      return false;
    }
    if (!fields || fields.length === 0) {
      // Empty filter now
      return true;
    }

    let filterReflections = fields.some(
      (f) => f.field === 'meaningful_activities' || f.field === 'score',
    );
    let filterRecords = fields.some(
      (f) => f.field !== 'meaningful_activities' && f.field !== 'score',
    );

    return fields[func]((fil) => {
      if (fil.field === 'meaningful_activities' || fil.field === 'score') {
        if (record.type !== 'DailyReflection') {
          return filterRecords ? func !== 'some' : true;
        } else {
          if (fil.compare) {
            return none(fil.compare(record[fil.field], fil.value));
          }
          return none(
            record.fields[fil.field] &&
              record.fields[fil.field].includes(fil.id),
          );
        }
      }
      if (record.type === 'DailyReflection') {
        return filterReflections ? func !== 'some' : true;
      }

      let field =
        fil.field !== 'recordTime'
          ? record[fil.field]
          : record.recordTime + record.timeOffset;
      if (fil.compare) {
        return none(fil.compare(field, fil.value));
      }
      return none(
        record.fields[fil.field] && record.fields[fil.field].includes(fil.id),
      );
    });
  });
  return result;
}

export function mapToUserSettings(filter, fields) {
  if (isEmpty(filter)) {
    return null;
  }
  let result = {};
  result.record_type = 'user_settings_record';
  result.pref_type = 'filter_settings';
  result.filter_mode = filter.mode;
  result.pain_type = filter.type_of_pain;
  if (filter.pain_records && (filter.severity > 0 || filter.severityEnd < 10)) {
    result.severity_range = { start: filter.severity, end: filter.severityEnd };
  }
  if (filter.pain_records && filter.daily_reflections) {
    result.record_types = '= PainRecord,DailyReflection';
  } else if (filter.pain_records) {
    result.record_types = '= PainRecord';
  } else if (filter.daily_reflections) {
    result.record_types = '= DailyReflection';
  }

  if (filter.daily_reflections && (filter.score > 0 || filter.scoreEnd < 10)) {
    result.reflection_score_range = {
      start: filter.score,
      end: filter.scoreEnd,
    };
  }

  if (filter.time_mode !== 'all') {
    result.timing_h_m_s = filter.time_mode + ' ' + filter.time;
  }
  if (filter.duration_mode !== 'all') {
    result.pain_duration = filter.duration_mode + ' ' + filter.duration;
    result.pain_duration_unit = filter.duration_units || 'Minutes';
  }
  let dateRange = filter.dateRange || '30days';

  if (dateRange === '7days') {
    let date = Moment().add(-7, 'day').startOf('day').valueOf();
    let date_end = Moment().endOf('day').valueOf();
    result.start_date = date;
    result.end_date = date_end;
    result.timing = ['>= ' + date, '<= ' + date_end];
  } else if (dateRange === '30days') {
    let date = Moment().add(-30, 'day').startOf('day').valueOf();
    let date_end = Moment().endOf('day').valueOf();
    result.start_date = date;
    result.end_date = date_end;
    result.timing = ['>= ' + date, '<= ' + date_end];
  } else if (dateRange === 'custom') {
    result.start_date = filter.date;
    result.end_date = filter.date_end;
    result.timing = ['>= ' + filter.date, '<= ' + filter.date_end];
  }
  // Outdated now
  // if(filter.date_mode!=='all') {
  //     if(filter.date_mode==='<>') {
  //         result.timing = filter.date_mode + ' ' + filter.date+","+filter.date_end;
  //     } else {
  //         result.timing = filter.date_mode + ' ' + filter.date;
  //     }
  // }
  if (filter.fields) {
    filter.fields.forEach((f) => {
      if (
        !f ||
        !fields[f.field] ||
        !fields[f.field][f.id] ||
        !fields[f.field][f.id].name
      ) {
        return;
      }
      if (!result[f.field]) {
        result[f.field] = [];
      }
      result[f.field].push(fields[f.field][f.id].name);
    });
  }
  filter.factors &&
    Object.keys(filter.factors).forEach((key) => {
      let id = filter.factors[key].id;
      filter.factors[key].type.forEach((t) => {
        let factor = t + '_factor';
        if (!result.hasOwnProperty(factor)) {
          result[factor] = [];
        }
        let field = fields[factor].find((f) => !!f && f.id === id);
        if (field) {
          result[factor].push(field.name);
        }
      });
    });
  filter.medications &&
    Object.keys(filter.medications).forEach((nid) => {
      filter.medications[nid].type.forEach((t) => {
        let factor = t + '_factor';
        if (!result.hasOwnProperty(factor)) {
          result[factor] = [];
        }
        let field = fields[factor].find(
          (f) =>
            f &&
            f.medication &&
            f.medication.some((med) => !!med && med.nid == nid),
        );
        if (field) {
          result[factor].push(field.name);
        }
      });
    });
  return result;
}
