import * as types from './actiontypes';
import { GENERATE_REPORT_SUCCESS } from '../Report/actiontypes';
import * as authTypes from '../Auth/actiontypes';
import * as apiTypes from '../Api/actiontypes';

function ProfileReducer(
  state = { content: { condition: [], medications: [] } },
  action,
) {
  switch (action.type) {
    case GENERATE_REPORT_SUCCESS:
      if (action.payload && state.content) {
        return {
          ...state,
          content: {
            ...state.content,
            credits_count: state.content.credits_count - 1,
          },
        };
      }
      return state;
    case types.ACCOUNT_DELETED:
    //This code allows us to keep local medications unsynced from server on account deletion
    // return {
    //   content: {
    //     medications: state.content?.medications,
    //     condition: state.content?.condition
    //   },
    // }
    case types.LOGOUT:
      //On logout-just clear my profile
      return {
        content: {
          medications: [],
          condition: [],
        },
      };
    case types.GET_MY_PROFILE:
      state = { ...state };
      delete state.should_save;
      delete state.changed;
      state.loading = true;
      return state;
    case types.GET_MY_PROFILE_SUCCESS:
      const eula_version = Math.max(
        action.payload.content.eula_version,
        state.content.eula_version || 0,
      );
      const privacy_notice_version = Math.max(
        action.payload.content.privacy_notice_version,
        state.content.privacy_notice_version || 0,
      );
      return {
        ...action.payload,
        content: {
          ...action.payload.content,
          eula_version,
          privacy_notice_version,
        },
        loading: false,
        should_save: false,
        original: action.payload,
      };
    case types.SAVE_MY_PROFILE:
      return {
        ...state,
        should_save: state.changed,
      };
    case types.SAVE_MY_PROFILE_SUCCESS:
      state = { ...state };
      state.saved = true;
      state.should_save = false;
      return state;
    case types.SET_HEIGHT:
      state = { ...state };
      let height = action.payload;
      state.content.height = height;
      state.changed = true;
      return state;
    case types.SET_WEIGHT:
      state = { ...state };
      let weight = parseFloat(action.payload);
      if (
        state.content &&
        state.content.weight_units &&
        state.content.weight_units === 'p'
      ) {
        weight = weight / 2.20462;
      }
      if (
        state.content &&
        state.content.weight_units &&
        state.content.weight_units === 's'
      ) {
        weight = weight / 0.15747;
      }
      state.content.weight = weight;
      state.changed = true;
      return state;
    case types.SET_BIRTHDAY:
      state = { ...state };
      state.content.birth_date = action.payload;
      state.changed = true;
      return state;
    case types.SET_HEIGHT_UNITS:
      state = { ...state };
      state.content.height_units = action.payload;
      state.changed = true;
      return state;
    case types.SET_WEIGHT_UNITS:
      state = { ...state };
      state.content.weight_units = action.payload;
      state.changed = true;
      return state;
    case types.SET_GENDER:
      state = { ...state };
      state.content.gender = action.payload;
      state.changed = true;
      return state;
    case types.SET_COUNTRY:
      return {
        ...state,
        content: { ...state.content, country: action.payload },
        changed: true,
      };
    case types.SET_DISPLAY_NAME:
      state = { ...state };
      state.content.name = action.payload;
      state.changed = true;
      return state;
    case types.SEARCH_CONDITION_REQUEST: {
      return {
        ...state,
        pain_condition_requesting: true,
        pain_condition_requesting_failure: null,
      };
    }
    case types.SEARCH_CONDITION_SUCCESS:
      const conditions = action.payload.slice();
      return { ...state, conditions, pain_condition_requesting: false };
    case types.SEARCH_CONDITION_FAILURE: {
      return {
        ...state,
        pain_condition_requesting: false,
        pain_condition_requesting_failure: action.payload,
      };
    }

    case types.SEARCH_MEDICATION_REQUEST: {
      return {
        ...state,
        medication_requesting: true,
        medication_requesting_failure: null,
      };
    }
    case types.SEARCH_MEDICATION_SUCCESS:
      return {
        ...state,
        medication_requesting: false,
        medications: [...action.payload],
      };
    case apiTypes.API_ERROR: {
      return action.reason !== types.SEARCH_MEDICATION_FAILURE
        ? state
        : {
            ...state,
            medication_requesting: false,
            medication_requesting_failure: action.error,
          };
    }

    case types.ADD_OFFLINE_PAIN_CONDITION_TO_PROFILE: {
      const _state = { ...state };
      if (!_state.content) {
        _state.content = {};
      }
      if (!_state.content.condition) {
        _state.content.condition = [];
      }
      return {
        ..._state,
        content: {
          ..._state.content,
          condition: [..._state.content.condition, { ...action.payload }],
        },
      };
    }

    case types.EDIT_OFFLINE_PAIN_CONDITION_IN_PROFILE: {
      const _state = { ...state };
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }

      const editedCondition = { ...action.payload };
      const _condition = _state.content.condition.map((painCondition) => {
        if (painCondition.fid === editedCondition.original_fid) {
          return editedCondition;
        } else {
          return painCondition;
        }
      });
      const _medications = _state.content.medications.map((medication) => {
        if (
          medication &&
          medication.purpose &&
          Array.isArray(medication.purpose) &&
          medication.purpose.indexOf(editedCondition.original_fid) > -1
        ) {
          // If there are two purposes with same fid added, this should be handled on sync stage (e.g. if
          // purpose was added without connection)
          const _purpose = medication.purpose.map((p) => {
            if (p === editedCondition.original_fid) {
              return editedCondition.fid > 0
                ? editedCondition.fid
                : editedCondition.original_fid;
            }
            return p;
          });
          return {
            ...medication,
            purpose: _purpose,
            // Do not mark medication as "updated", as it's done on server already
            // flag_updated: true,
          };
        } else {
          return medication;
        }
      });

      return {
        ..._state,
        content: {
          ..._state.content,
          condition: _condition,
          medications: _medications,
        },
      };
    }

    case types.TOGGLE_SHOW_OFFLINE_PAIN_CONDITION_IN_PROFILE: {
      const _state = { ...state };
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }

      const _condition = _state.content.condition.map((painCondition) => {
        if (painCondition.fid !== action.payload) {
          return painCondition;
        }
        return {
          ...painCondition,
          isShow: painCondition.isShow === 0 ? 1 : 0,
          flag_updated: true,
        };
      });

      return {
        ..._state,
        content: {
          ..._state.content,
          condition: _condition,
        },
      };
    }

    case types.DELETE_OFFLINE_PAIN_CONDITION_FROM_PROFILE: {
      const _state = { ...state };
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }

      const _condition = _state.content.condition.filter(
        (painCondition) => painCondition.fid !== action.payload,
      );

      const _medications = _state.content.medications.map((medication) => {
        if (
          medication &&
          medication.purpose &&
          Array.isArray(medication.purpose) &&
          medication.purpose.indexOf(action.payload) > -1
        ) {
          const _purpose = medication.purpose.filter(
            (purpose) => purpose !== action.payload,
          );
          return {
            ...medication,
            purpose: _purpose,
            // Do not mark medication as "updated", as it's done on server already
            // flag_updated: true,
          };
        } else {
          return medication;
        }
      });

      return {
        ..._state,
        content: {
          ..._state.content,
          condition: _condition,
          medications: _medications,
        },
      };
    }

    case types.ADD_OFFLINE_MEDICATION_TO_PROFILE: {
      const _state = { ...state };
      const medication = { ...action.payload };
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }

      console.log(_state.content.medications);

      const _medications = [..._state.content.medications, medication];
      return {
        ..._state,
        content: {
          ..._state.content,
          medications: _medications,
        },
      };
    }

    case types.EDIT_OFFLINE_MEDICATION_IN_PROFILE: {
      const _state = { ...state };
      const medication = { ...action.payload };
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }
      const _medications = _state.content.medications.map((m) => {
        if (m.nid == medication.nid) {
          return { ...medication };
        } else {
          return m;
        }
      });

      return {
        ..._state,
        content: {
          ..._state.content,
          medications: _medications,
        },
      };
    }

    case types.DELETE_OFFLINE_MEDICATION_FROM_PROFILE: {
      const _state = { ...state };
      const nid = action.payload;
      if (!_state.content) {
        _state.content = {};
      }
      if (
        !_state.content.condition ||
        !Array.isArray(_state.content.condition)
      ) {
        _state.content.condition = [];
      }
      if (
        !_state.content.medications ||
        !Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = [];
      }

      const _medications = _state.content.medications.filter(
        (med) => med.nid !== nid,
      );

      return {
        ..._state,
        content: {
          ..._state.content,
          medications: _medications,
        },
      };
    }

    case types.ADD_PAIN_CONDITION_SUCCESS:
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }
      if (!state.content.condition) {
        state.content.condition = [];
      }
      state.content.condition = state.content.condition.slice();
      state.content.condition.push(action.payload);
      return { ...state };
    case types.EDIT_PAIN_CONDITION_SUCCESS:
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }
      const _state = { ...state };
      if (!_state.content.condition) {
        _state.content.condition = [];
      }
      let index = _state.content.condition.findIndex(
        (el) => el.fid === (action.payload.original_fid || action.payload.fid),
      );

      const _conditions = _state.content.condition.slice();
      _conditions[index] = { ...action.payload };
      return {
        ...state,
        content: {
          ...state.content,
          condition: _conditions,
        },
      };

    case types.DELETE_PAIN_CONDITION_SUCCESS: {
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }

      let _state = { ...state };
      if (!state.content.condition) {
        _state = [];
      }
      _state.content.condition = _state.content.condition.filter(
        (cond) => cond.fid !== action.payload.fid,
      );

      if (
        _state.content.medications &&
        Array.isArray(_state.content.medications)
      ) {
        _state.content.medications = _state.content.medications.map(
          (medication) => {
            let _medication = { ...medication };
            if (
              medication &&
              medication.purpose &&
              Array.isArray(medication.purpose)
            ) {
              _medication = {
                ...medication,
                purpose: medication.purpose.filter(
                  (el) => el !== action.payload.fid,
                ),
              };
            }
            return _medication;
          },
        );
      }
      return { ..._state };
    }
    case types.ADD_MEDICATION_SUCCESS:
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }
      if (!state.content.medications) {
        state.content.medications = [];
      }
      state.content.medications = state.content.medications.slice();
      state.content.medications.push(action.payload);
      return { ...state };
    case types.EDIT_MEDICATION_SUCCESS:
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }
      if (!state.content.medications) {
        state.content.medications = [];
      }
      let medIndex = state.content.medications.findIndex(
        (el) => el.nid == action.payload.nid_old,
      );
      //state.content.medications[medIndex] = {...action.payload};
      //state.content.medications = state.content.medications.slice();
      return {
        ...state,
        content: {
          ...state.content,
          medications: state.content.medications.map((med, _index) => {
            if (_index === medIndex) {
              return { ...action.payload };
            } else {
              return med;
            }
          }),
        },
      };
    case types.DELETE_MEDICATION_SUCCESS:
      if (!state.content) {
        //No 'profile' loaded by some reason.
        //TODO: handle this...
        return { ...state };
      }
      if (!state.content.medications) {
        state.content.medications = [];
      }
      state.content.medications = state.content.medications.slice();
      state.content.medications = state.content.medications.filter(
        (cond) => cond.nid !== action.payload,
      );
      return { ...state };
    case types.API_ERROR:
      if (action.reason === types.SAVE_MY_PROFILE) {
        state = { ...state };
        state.error = true;
      }
      return state;
    case types.ADD_UNSYNC_PAIN_CONDITION_TO_PROFILE:
      let content_single_pain_condition;
      if (state.content) {
        if (state.content.condition) {
          content_single_pain_condition = {
            ...state.content,
            condition: [...state.content.condition],
          };
        } else {
          content_single_pain_condition = { ...state.content, condition: [] };
        }
      } else {
        content_single_pain_condition = { condition: [] };
      }
      content_single_pain_condition.condition = [
        ...content_single_pain_condition.condition,
        {
          ...action.payload,
          offline: true,
        },
      ];
      return { ...state, content: content_single_pain_condition };

    case types.ADD_UNSYNC_PAIN_CONDITIONS_ARRAY_TO_PROFILE:
      let content_array_pain_condition;
      if (state.content) {
        if (state.content.condition) {
          content_array_pain_condition = {
            ...state.content,
            condition: [...state.content.condition],
          };
        } else {
          content_array_pain_condition = { ...state.content, condition: [] };
        }
      } else {
        content_array_pain_condition = { condition: [] };
      }
      content_array_pain_condition.condition = [
        ...content_array_pain_condition.condition,
        ...action.payload,
      ];
      return { ...state, content: content_array_pain_condition };

    case types.TOGGLE_SHOW_OF_UNSYNC_PAIN_CONDITION: {
      let condition_for_toggling_show = state.content.condition.map(
        (condition) => {
          if (condition.fid === action.payload) {
            condition.isShow = 1 - condition.isShow;
          }
          return condition;
        },
      );

      return {
        ...state,
        content: {
          ...state.content,
          condition: condition_for_toggling_show,
        },
      };
    }

    case types.DELETE_UNSYNC_PAIN_CONDITION: {
      const fid = action.payload;
      const new_condition = state.content.condition.filter(
        (condition) => condition.fid !== fid,
      );
      const new_medications =
        state.content && state.content.medications
          ? state.content.medications.map((medication) => {
              if (
                Array.isArray(medication.purpose) &&
                medication.purpose.indexOf(fid) >= 0
              ) {
                const new_purpose = medication.purpose.filter(
                  (purpose) => purpose !== fid,
                );
                return { ...medication, purpose: new_purpose };
              }
              return medication;
            })
          : [];

      return {
        ...state,
        content: {
          ...state.content,
          condition: new_condition,
          medications: new_medications,
        },
      };
    }

    case types.ADD_UNSYNC_MEDICATION_TO_PROFILE:
      let content_single_med;
      if (state.content) {
        if (state.content.medications) {
          content_single_med = {
            ...state.content,
            medications: [...state.content.medications],
          };
        } else {
          content_single_med = { ...state.content, medications: [] };
        }
      } else {
        content_single_med = { medications: [] };
      }
      content_single_med.medications = [
        ...content_single_med.medications,
        { ...action.payload, offline: true },
      ];
      return { ...state, content: content_single_med };

    case types.ADD_UNSYNC_MEDICATIONS_ARRAY_TO_PROFILE:
      let content_array_med;
      if (state.content) {
        if (state.content.medications) {
          content_array_med = {
            ...state.content,
            medications: [...state.content.medications],
          };
        } else {
          content_array_med = { ...state.content, medications: [] };
        }
      } else {
        content_array_med = { medications: [] };
      }
      content_array_med.medications = [
        ...content_array_med.medications,
        ...action.payload,
      ];
      return { ...state, content: content_array_med };

    case types.EDIT_UNSYNC_PAIN_CONDITION: {
      let condition = state.content.condition.map((_condition) => {
        if (_condition.fid === action.payload.fid) {
          const { value, first_symptom, diagnosed } = action.payload;
          return {
            ..._condition,
            value,
            first_symptom,
            diagnosed,
            offline: true,
          };
        }
        return _condition;
      });

      return { ...state, content: { ...state.content, condition } };
    }

    case types.EDIT_UNSYNC_MEDICATION_IN_PROFILE: {
      return {
        ...state,
        content: {
          ...state.content,
          medications: state.content.medications.map((med) => {
            if (med.nid == action.payload.nid) {
              return { ...action.payload, offline: true };
            } else {
              return med;
            }
          }),
        },
      };
    }

    case types.DELETE_UNSYNC_MEDICATION_FROM_PROFILE: {
      return {
        ...state,
        content: {
          ...state.content,
          medications: state.content.medications.filter(
            (med) => med.nid !== action.payload,
          ),
        },
      };
    }

    case types.EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID: {
      if (
        !(
          state.content &&
          state.content.medications &&
          Array.isArray(state.content.medications)
        )
      ) {
        return { ...state };
      }
      return {
        ...state,
        content: {
          ...state.content,
          medications: state.content.medications.map((med) => {
            if (med.purpose.indexOf(action.payload.old_fid) >= 0) {
              return {
                ...med,
                purpose: med.purpose.map((p) => {
                  if (p === action.payload.old_fid) {
                    return action.payload.new_fid;
                  }
                  return p;
                }),
              };
            } else {
              return med;
            }
          }),
        },
      };
    }

    case types.REMOVE_NETWORK_ERROR_FOR_PAIN_CONDITIONS_AND_MEDICATIONS: {
      return {
        ...state,
        medication_requesting_failure: undefined,
        pain_condition_requesting_failure: undefined,
      };
    }

    case types.SELECT_PHOTO_FROM_PROVIDER_REQUEST: {
      return {
        ...state,
        avatar_requesting: true,
      };
    }
    case authTypes.DISCONNECT_FROM_PROVIDER: {
      return {
        ...state,
        content: {
          ...state.content,
          providers: {
            ...state.content.providers,
            [action.payload + '_id']: undefined,
          },
        },
      };
    }
    case types.UPLOAD_AVATAR:
      return {
        ...state,
        content: {
          ...state.content,
          avatar: {
            url: action.payload.url,
            provider: 'photo',
          },
        },
        avatar_requesting: false,
      };
    case types.SELECT_PHOTO_FROM_PROVIDER_SUCCESS: {
      return {
        ...state,
        content: {
          ...state.content,
          avatar: {
            ...action.payload,
          },
        },
        avatar_requesting: false,
      };
    }

    case types.SELECT_PHOTO_FROM_PROVIDER_FAILURE: {
      return {
        ...state,
        avatar_requesting: false,
      };
    }

    case types.REMOVE_PHOTO_REQUEST: {
      return {
        ...state,
        avatar_requesting: true,
      };
    }

    case types.REMOVE_PHOTO_SUCCESS: {
      return {
        ...state,
        content: {
          ...state.content,
          avatar: {},
        },
        avatar_requesting: false,
      };
    }

    default:
      return state;
  }
}

export { ProfileReducer };
