import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import { Picker } from '@react-native-picker/picker';

export default class PickerNB extends Component {
  render() {
    return (
      <Picker ref={(c) => (this._root = c)} {...this.props}>
        {this.props.children}
      </Picker>
    );
  }
}

PickerNB.Item = createReactClass({
  render() {
    return <Picker.Item {...this.props} />;
  },
});

PickerNB.propTypes = {
  ...Picker.propTypes,
};

export { PickerNB };
