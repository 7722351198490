import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { designConstants } from '../styles';
import { AppBar, Toolbar } from '@mui/material';
import HeaderIcon from './HeaderIcon';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../../modules/Navigator/actions';
import useCurrentWidth from '../../shared/utilities/useCurrentWidth';
import useThemeContext from '../styles/useThemeContext';

const Header = (props) => {
  let width = useCurrentWidth();
  const dispatch = useDispatch();
  const { colors } = useThemeContext();

  const menuVisible =
    props.allowDrawer &&
    width <= designConstants.webDrawerWidth + designConstants.webMaxWidth &&
    !props.menuHidden;
  const { children } = props;
  return (
    <View style={{ zIndex: 99999 }}>
      <AppBar
        position={'fixed'}
        elevation={typeof props.elevation !== 'undefined' ? props.elevation : 4}
        style={{
          backgroundColor: colors.statusBarBackground,
          zIndex: 99999,
          elevation: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'center',
            alignSelf: 'stretch',
          }}
        >
          <Toolbar
            style={{
              maxWidth: designConstants.webMaxWidth,
              alignSelf: 'stretch',
              flex: 1,
            }}
          >
            {menuVisible && (
              <HeaderIcon menu onPress={() => dispatch(toggleDrawer())} />
            )}
            {children}
          </Toolbar>
        </View>
      </AppBar>
      {/* This second Toolbar needed to properly apply padding */}
      <Toolbar />
    </View>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  transparent: PropTypes.bool,
  transparencyColor: PropTypes.string,
};

Header.defaultProps = {
  children: null,
  transparent: false,
  style: {},
};

export default Header;
