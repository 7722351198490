export const SETTINGS_EDIT_REMINDER = 'SETTINGS_EDIT_REMINDER';
export const SETTINGS_DELETE_REMINDER = 'SETTINGS_DELETE_REMINDER';
export const SETTINGS_SET_LANGUAGE = 'SETTINGS_SET_LANGUAGE';
export const SETTINGS_SWITCH_REMINDER_ENABLED =
  'SETTINGS_SWITCH_REMINDER_ENABLED';
export const SETTINGS_SWITCH_REPORT_USAGE = 'SETTINGS_SWITCH_REPORT_USAGE';
export const SETTINGS_SWITCH_REPORT_CRASH = 'SETTINGS_SWITCH_REPORT_CRASH';
export const SETTINGS_IOS_NOTIFICATIONS_SYSTEM_ALERT_WAS_SHOWN =
  'SETTINGS_IOS_NOTIFICATIONS_SYSTEM_ALERT_WAS_SHOWN';

export const SETTINGS_DEBUG_REMINDERS_TOGGLE =
  'SETTINGS_DEBUG_REMINDERS_TOGGLE';
export const SETTINGS_DEBUG_CHANGE_CREATED_AT =
  'SETTINGS_DEBUG_CHANGE_CREATED_AT';

export const GET_USER_EMAIL_SUBSCRIPTIONS = 'GET_USER_EMAIL_SUBSCRIPTIONS';

export const SETTINGS_DISABLE_IDLE_TIMER = 'SETTINGS_DISABLE_IDLE_TIMER';

export const SETTINGS_SET_DARK_MODE_INTRODUCTION_SHOW =
  'SETTINGS_SET_DARK_MODE_INTRODUCTION_SHOW';
export const INSTITUTION_CONFIG_RETRIEVED = 'INSTITUTION_CONFIG_RETRIEVED';
