// @flow
import * as types from './actiontypes';
import Moment from 'moment';
import { getConsecutiveDays } from '../Settings/middleware';
import { MED_NID_CHANGED } from '../Meds/actiontypes';
import { DELETE_FIELD } from '../Fields/actiontypes';
import { MILLISECONDS_IN_DAY } from '../Filter/filter';
import type { Action } from '../../redux/types';
import * as profileTypes from '../Profile/actiontypes';

type CurrentRecords = {
  record: Record,
  reflection: Record,
};

type State = {
  current: CurrentRecords,
  list: Array<Record>,
  deleted: Array<Record>,
  overlays: any,
  consecutive: number,
};

const INITIAL = {
  current: {},
  list: [],
  deleted: [],
  overlays: [],
  consecutive: 0,
};

function reduceCurrent(state: CurrentRecords, action: Action) {
  let currentRecord = state.record;
  switch (action.type) {
    case types.PREPARE_PAIN_RECORD:
      if (action.payload.record) {
        return {
          ...state,
          record: action.payload.record,
        };
      } else {
        return {
          ...state,
          reflection: action.payload.reflection,
        };
      }
    case types.SET_TIME_OFFSET:
      state = { ...state };
      state.record = { ...state.record };
      state.record.timeOffset = action.payload;
      return state;
    case types.SET_SEVERITY:
      state = { ...state };
      state.record = { ...state.record };
      state.record.severity = action.payload;
      return state;
    case types.SET_ALLDAY_CHECKED:
      state = { ...state };
      state.record = { ...state.record };
      state.record.allDayChecked = action.payload;
      if (action.payload) {
        let time = state.record.recordTime || new Date();
        state.record.recordTime = Moment(time).startOf('day').valueOf();
        state.record.lengthOfPainValue = MILLISECONDS_IN_DAY / 1000; //Moment(state.record.recordTime).clone().add(1, 'day').diff(state.record.recordTime, 'seconds');
      } else {
        state.record.lengthOfPainValue = 0;
      }
      return state;
    case types.SET_RECORD_TIME:
      //When we change time, we need to change timezone as well
      state = { ...state };
      state.record = { ...state.record };
      state.record.recordTime = action.payload.time;
      state.record.allDayChecked = false;
      state.record.time_set = action.payload.time_set;
      state.record.time_draft = action.payload.time_draft;
      return state;
    case types.SET_NOTE:
      state = { ...state };
      state.record = { ...state.record };
      state.record.note = action.payload;
      return {
        ...state,
      };
    case types.SET_REFLECTION_NOTE:
      state = { ...state };
      state.reflection = { ...state.reflection };
      state.reflection.note = action.payload;
      return state;
    case types.SET_REFLECTION_TIME:
      //When we change time, we need to change timezone as well
      state = { ...state };
      state.reflection = { ...state.reflection };
      state.reflection.recordTime = action.payload.time;
      state.reflection.time_set = action.payload.time;
      return state;
    case types.SET_REFLECTION_SCORE:
      state = { ...state };
      state.reflection = { ...state.reflection };
      state.reflection.score = action.payload;
      return state;
    case types.SET_DURATION:
      state = { ...state };
      state.record = { ...state.record };
      state.record.lengthOfPainValue = action.payload;
      state.record.allDayChecked = false;
      return state;
    case types.SET_DURATION_UNITS:
      state = { ...state };
      state.record = { ...state.record };
      state.record.lengthOfPainUnit = action.payload;
      return state;
    case types.SET_DURATION_TYPE:
      state = { ...state };
      state.record = { ...state.record };
      state.record.durationType = action.payload;
      return state;
    case types.SET_PAIN_TYPE:
      state = { ...state };
      state.record = { ...state.record };
      state.record.lengthOfPainType = action.payload;
      return state;
    case types.UNCHECK_FACTOR_FIELD:
      state = { ...state };
      state.record = { ...state.record };
      let name = action.payload.field;
      let factorIndex = state.record.fields[action.payload.fieldType].indexOf(
        action.payload.fieldId,
      );
      if (factorIndex !== -1) {
        state.record.fields = { ...state.record.fields };
        state.record.fields[action.payload.fieldType] =
          state.record.fields[action.payload.fieldType].slice();
        state.record.fields[action.payload.fieldType].splice(factorIndex, 1);
      }
      return state;
    case types.CHECK_NOTHING: {
      state = { ...state };
      let record =
        action.payload.fieldType === 'meaningful_activities'
          ? state.reflection
          : state.record;
      record = { ...record };
      record.fields = { ...record.fields };
      if (!record.fields[action.payload.fieldType]) {
        record.fields[action.payload.fieldType] = [];
      } else {
        record.fields[action.payload.fieldType] = [
          ...record.fields[action.payload.fieldType],
        ];
      }
      let index = record.fields[action.payload.fieldType].indexOf(
        action.payload.fieldId,
      );
      if (index > -1 && !action.payload.checked) {
        record.fields[action.payload.fieldType].splice(index, 1);
      } else if (index === -1 && action.payload.checked) {
        record.fields[action.payload.fieldType] = [];
        if (action.payload.fieldType === 'alleviating_factor') {
          record.fields.ineffective_factor = [];
        }
        record.fields[action.payload.fieldType].push(action.payload.fieldId);
      }
      return record.type === 'DailyReflection'
        ? {
            ...state,
            reflection: record,
          }
        : {
            ...state,
            record: record,
          };
    }
    case types.CHECK_FIELD:
      state = { ...state };
      let record =
        action.payload.fieldType === 'meaningful_activities'
          ? state.reflection
          : state.record;
      record = { ...record };
      record.fields = { ...record.fields };
      if (!record.fields[action.payload.fieldType]) {
        record.fields[action.payload.fieldType] = [];
      } else {
        record.fields[action.payload.fieldType] = [
          ...record.fields[action.payload.fieldType],
        ];
      }
      let index = record.fields[action.payload.fieldType].indexOf(
        action.payload.fieldId,
      );
      if (index > -1 && !action.payload.checked) {
        record.fields[action.payload.fieldType].splice(index, 1);
      } else if (index === -1 && action.payload.checked) {
        record.fields[action.payload.fieldType].push(action.payload.fieldId);
        //Need to uncheck "Nothing" field in this case
        if (action.payload.nothingFieldId >= 0) {
          index = record.fields[action.payload.fieldType].indexOf(
            action.payload.nothingFieldId,
          );
          if (index > -1) {
            record.fields[action.payload.fieldType].splice(index, 1);
          }
        }
      }
      return record.type === 'DailyReflection'
        ? {
            ...state,
            reflection: record,
          }
        : {
            ...state,
            record: record,
          };
    case types.CHECK_FACTOR:
      state = { ...state };
      record = { ...currentRecord };
      record.fields = { ...record.fields };
      if (!record.fields[action.payload.field]) {
        record.fields[action.payload.field] = [];
      } else {
        record.fields[action.payload.field] = [
          ...record.fields[action.payload.field],
        ];
      }
      let factor = record.fields[action.payload.field].find(
        (fac) => fac.id === action.payload.fieldId,
      );
      index = record.fields[action.payload.field].indexOf(factor);

      if (index > -1) {
        record.fields.factors.splice(index, 1);
        if (action.payload.factorType) {
          record.fields.factors.push({
            id: action.payload.fieldId,
            type: action.payload.factorType,
          });
        }
      } else if (index === -1) {
        record.fields.factors.push({
          id: action.payload.fieldId,
          type: action.payload.factorType || 'alleviating',
        });
      }
      return {
        ...state,
        record: record,
      };
    case DELETE_FIELD:
      let deleFieldRecord =
        action.payload.fieldType === 'meaningful_activities'
          ? state.reflection
          : state.record;

      if (deleFieldRecord && deleFieldRecord.fields) {
        deleFieldRecord = { ...deleFieldRecord };
        let index = deleFieldRecord.fields[action.payload.fieldType].indexOf(
          action.payload.fieldId,
        );
        if (index >= 0) {
          deleFieldRecord.fields = { ...deleFieldRecord.fields };
          deleFieldRecord.fields[action.payload.fieldType] = [
            ...deleFieldRecord.fields[action.payload.fieldType],
          ];
          deleFieldRecord.fields[action.payload.fieldType].splice(index, 1);
        }
        return deleFieldRecord.type === 'DailyReflection'
          ? {
              ...state,
              reflection: deleFieldRecord,
            }
          : {
              ...state,
              record: deleFieldRecord,
            };
      }

      return state;
    case types.CHECK_MEDICATION:
      state = { ...state };
      state.record = { ...state.record };

      // At least this change is needed at this place, otherwise nextProps and this.props will be the same object
      // for MedicationListSectionContent
      if (currentRecord?.medications) {
        currentRecord = {
          ...state.record,
          medications: { ...currentRecord.medications },
        };
      } else {
        currentRecord = {
          ...state.record,
          medications: {},
        };
      }

      let hasMed = currentRecord.medications.hasOwnProperty(
        action.payload.medication.nid,
      );
      //if we just checked medication from another type - then just check other type
      if (
        hasMed &&
        action.payload.medtype &&
        currentRecord.medications[action.payload.medication.nid]
          .typeOfMedication !== action.payload.medtype
      ) {
        currentRecord.medications[action.payload.medication.nid] = {
          ...currentRecord.medications[action.payload.nid],
          typeOfMedication: action.payload.medtype || 'alleviating',
        };
      } else if (hasMed) {
        delete currentRecord.medications[action.payload.medication.nid];
      } else {
        currentRecord.medications[action.payload.medication.nid] = {
          typeOfMedication: action.payload.medtype || 'alleviating',
        };
      }

      return { ...state, record: currentRecord };
    case types.CHANGE_CURRENT_MEDICATION_TYPE:
      state = { ...state };
      state.record = { ...state.record };
      currentRecord = state.record;
      if (!currentRecord.medications) {
        currentRecord.medications = {};
      }
      if (currentRecord.medications.hasOwnProperty(action.payload.nid)) {
        currentRecord.medications[action.payload.nid] = {
          ...currentRecord.medications[action.payload.nid],
          typeOfMedication: action.payload.type,
        };
      }
      currentRecord.medications = { ...currentRecord.medications };
      return { ...state, record: currentRecord };
    case types.CHANGE_CURRENT_DOSAGE:
      state = { ...state };
      state.record = { ...state.record };
      currentRecord = state.record;
      if (!currentRecord.medications) {
        currentRecord.medications = {};
      }
      if (currentRecord.medications.hasOwnProperty(action.payload.nid)) {
        currentRecord.medications[action.payload.nid] = {
          ...currentRecord.medications[action.payload.nid],
          dosage: action.payload.dosage,
        };
      }
      currentRecord.medications = { ...currentRecord.medications };
      return { ...state, record: currentRecord };
    case types.CHANGE_CURRENT_DOSAGE_UNITS:
      state = { ...state };
      state.record = { ...state.record };
      currentRecord = state.record;
      if (!currentRecord.medications) {
        currentRecord.medications = {};
      } else {
        currentRecord.medications = { ...currentRecord.medications };
      }
      currentRecord.medications[action.payload.nid] = {
        ...currentRecord.medications[action.payload.old_nid],
      };
      delete currentRecord.medications[action.payload.old_nid];
      return { ...state, record: currentRecord };
  }

  return null;
}

function RecordsReducer(state: State = { ...INITIAL }, action: Action) {
  let current = reduceCurrent(state.current || {}, action);
  if (current) {
    state = { ...state, current };
  }
  let record = {};
  let list = [];
  let deleted = [];
  switch (action.type) {
    case profileTypes.ACCOUNT_DELETED:
    //This code allows us to keep local records unsynced from server on account deletion
    // return {
    //   list: state.list.map(rec => {return {...rec, idOnServer: 0}}),
    //   deleted: []
    // }
    case types.LOGOUT:
      return { ...INITIAL };
    case types.SYNC_RECORDS_CLEAR_FLAGS:
      let toClear = action.payload.flagsToClear;
      let changed = action.payload.changed;
      list = state.list.slice();
      deleted = state.deleted.slice();
      changed &&
        changed.forEach((record) => {
          if (!record.deleted) {
            let index = list.findIndex((rec) => rec.id === record.id);
            list[index] = {
              ...list[index],
              idOnServer: record.idOnServer,
              createDate: record.createDate,
            };
          } else {
            let index = deleted.findIndex((rec) => rec.id === record.id);
            deleted[index] = {
              ...deleted[index],
              idOnServer: record.idOnServer,
              createDate: record.createDate,
            };
          }
        });
      list = list.map((rec) => {
        let changedFlags = toClear.find(
          (recordWithFlag) => recordWithFlag.createDate === rec.createDate,
        );
        if (changedFlags) {
          if (
            rec.flag_updated ||
            rec.flag_deleted ||
            rec.flag_created ||
            rec.flag_update_show
          ) {
            return {
              ...rec,
              flag_updated: changedFlags.flag_updated
                ? false
                : rec.flag_updated,
              flag_deleted: changedFlags.flag_deleted
                ? false
                : rec.flag_deleted,
              flag_created: changedFlags.flag_created
                ? false
                : rec.flag_created,
              flag_update_show: changedFlags.flag_update_show
                ? false
                : rec.flag_update_show,
            };
          }
        }
        return rec;
      });
      deleted = deleted.map((rec) => {
        let changedFlags = toClear.find(
          (recordWithFlag) => recordWithFlag.createDate === rec.createDate,
        );
        if (changedFlags) {
          if (
            rec.flag_updated ||
            rec.flag_deleted ||
            rec.flag_created ||
            rec.flag_update_show
          ) {
            return {
              ...rec,
              flag_updated: changedFlags.flag_updated
                ? false
                : rec.flag_updated,
              flag_deleted: changedFlags.flag_deleted
                ? false
                : rec.flag_deleted,
              flag_created: changedFlags.flag_created
                ? false
                : rec.flag_created,
              flag_update_show: changedFlags.flag_update_show
                ? false
                : rec.flag_update_show,
            };
          }
        }
        return rec;
      });
      return {
        ...state,
        list,
        deleted,
      };
    case types.SYNC_RECORDS_SUCCESS:
      let records = action.payload.records || [];
      list = state.list.slice();
      deleted = state.deleted.slice();
      records.forEach((rec) => {
        if (!rec.deleted) {
          let id = list.findIndex(
            (oldRec) =>
              (rec.idOnServer !== 0 && oldRec.idOnServer === rec.idOnServer) ||
              oldRec.createDate === rec.createDate,
          );
          if (id < 0) {
            //New record
            list.push({ ...rec, id: list.length });
          } else {
            //Updated records
            let existingRecord = list[id];
            // console.log('updated records', existingRecord, action.payload);
            if (
              existingRecord.localUpdated &&
              rec.localUpdated &&
              existingRecord.localUpdated > rec.localUpdated
            ) {
              //Ignore such record - seems that record was updated in sync meantime
              //also we need to set "updated" flag again, since we already clear it on previous step
              list[id] = {
                ...list[id],
                localUpdated: rec.localUpdated,
                flag_updated: true,
              };
            } else {
              list[id] = { ...rec, id };
            }
          }
        } else {
          let id = deleted.findIndex(
            (oldRec) =>
              (rec.idOnServer !== 0 && oldRec.idOnServer === rec.idOnServer) ||
              oldRec.createDate === rec.createDate,
          );
          if (id < 0) {
            //New record
            deleted.push({ ...rec, id: deleted.length });
          } else {
            let existingRecord = deleted[id];
            if (
              existingRecord.localUpdated &&
              rec.localUpdated &&
              existingRecord.localUpdated > rec.localUpdated
            ) {
              //Ignore such record - seems that record was updated in sync meantime
              //also we need to set "updated" and "deleted" flags again, since we already clear it on previous step
              deleted[id] = {
                ...deleted[id],
                localUpdated: rec.localUpdated,
                flag_updated: true,
                flag_deleted: true,
              };
            } else {
              deleted[id] = { ...rec, id };
            }
          }
          list = list.filter(
            (listRec) => listRec.idOnServer !== rec.idOnServer,
          );
        }
      });

      return { ...state, list, deleted, consecutive: getConsecutiveDays(list) };
    case types.SAVE_DAILY_REFLECTION_SUCCESS:
      record = { ...action.payload };
      list = state.list.slice();
      //find last id
      record.flag_updated = true;
      if (record.id === -1 || typeof record.id === 'undefined') {
        record.flag_created = true;
        record.id = list.length;
        while (list.length > 0 && list[list.length - 1].id >= record.id) {
          //make sure our id is unique
          record.id++;
        }
        record.createDate = Moment().valueOf();
        list.push(record);
      } else {
        list[list.findIndex((rec) => rec.createDate === record.createDate)] = {
          ...record,
        };
      }
      state = { ...state };
      current = { ...state.current };
      // Do not delete current.reflection here, as it lead to refreshing of "create reflection"
      // screen and shows "select date" dialog on native app
      // delete current.reflection;
      return {
        ...state,
        current: current,
        list: list,
        consecutive: getConsecutiveDays(list),
      };
    case types.SAVE_RECORD_SUCCESS:
      record = { ...action.payload };
      list = state.list.slice();
      //find last id
      record.flag_updated = true;
      if (record.id === -1 || typeof record.id === 'undefined') {
        record.flag_created = true;
        record.id = list.length;
        while (list.length > 0 && list[list.length - 1].id >= record.id) {
          //Make sure our id is unique
          record.id++;
        }
        record.createDate = Moment().valueOf();
        list.push(record);
      } else {
        list[list.findIndex((rec) => rec.createDate === record.createDate)] = {
          ...record,
        };
      }
      return {
        ...state,
        list: list,
        consecutive: getConsecutiveDays(list),
      };
    case types.SAVE_DELETED_RECORD_SUCCESS:
      record = { ...action.payload };
      deleted = state.deleted.slice();
      deleted[
        deleted.findIndex((rec) => rec.createDate === record.createDate)
      ] = { ...record };
      return {
        ...state,
        deleted: deleted,
      };
    case types.DELETE_RECORD:
      list = state.list.slice();
      record = list.find((rec) => rec.createDate === action.payload.createDate);
      let index = list.indexOf(record);
      record = list.splice(index, 1)[0];
      record.deleted = true;
      record.flag_updated = true;
      record.flag_deleted = true;
      deleted = state.deleted.slice();
      deleted.push(record);
      return {
        ...state,
        list,
        deleted,
        consecutive: getConsecutiveDays(list),
      };
    case DELETE_FIELD:
      list = state.list.slice();
      list.forEach((rec) => {
        let fieldIndex = rec.fields[action.payload.fieldType]
          ? rec.fields[action.payload.fieldType].indexOf(action.payload.fieldId)
          : -1;
        if (fieldIndex >= 0) {
          rec.fields = { ...rec.fields };
          rec.fields[action.payload.fieldType] =
            rec.fields[action.payload.fieldType].slice();
          rec.fields[action.payload.fieldType].splice(fieldIndex, 1);
          rec.flag_updated = true;
        }
      });
      return {
        ...state,
        list,
      };
    case MED_NID_CHANGED:
      if (action.payload.nid !== action.payload.old_nid) {
        state = { ...state };
        state.list = state.list.slice();
        state.list.forEach((rec) => {
          if (
            rec.medications &&
            rec.medications.hasOwnProperty(action.payload.old_nid)
          ) {
            let recIndex = state.list.indexOf(rec);
            rec = { ...rec };
            rec.medications = { ...rec.medications };
            rec.medications[action.payload.nid] = {
              ...rec.medications[action.payload.old_nid],
            };
            delete rec.medications[action.payload.old_nid];
            state.list[recIndex] = rec;
          }
        });
      }
      return state;

    case types.REMOVE_JUST_DELETED_MEDICATION_FROM_CURRENT_RECORD: {
      if (
        state.current &&
        state.current.record &&
        state.current.record.medications &&
        state.current.record.medications[action.payload]
      ) {
        const newMeds = { ...state.current.record.medications };
        delete newMeds[action.payload];
        return {
          ...state,
          current: {
            ...state.current,
            record: {
              ...state.current.record,
              medications: newMeds,
            },
          },
        };
      } else {
        return { ...state };
      }
    }

    case types.DELETE_OFFLINE_MEDICATION_FROM_RECORDS: {
      const nid = action.payload;
      if (state.list && Array.isArray(state.list)) {
        return {
          ...state,
          list: state.list.map((_record) => {
            if (_record.medications && _record.medications[String(nid)]) {
              const newMedications = {};
              Object.keys(_record.medications).forEach((key) => {
                if (String(key) !== String(nid)) {
                  newMedications[key] = _record.medications[key];
                }
              });
              return {
                ..._record,
                medications: newMedications,
                // We do not need to mark this record as updated, as medication
                // will also be deleted on server and we will receive update
                // flag_updated: true,
              };
            } else {
              return _record;
            }
          }),
        };
      } else {
        return { ...state };
      }
    }

    case types.CHECK_OVERLAY:
      state = { ...state };
      //            state.calendar_view_enabled = true;
      let overlay_settings = { ...state.overlays };
      if (!overlay_settings[action.payload.field]) {
        overlay_settings[action.payload.field] = [];
      } else {
        overlay_settings[action.payload.field] = [
          ...overlay_settings[action.payload.field],
        ];
      }
      if (
        overlay_settings[action.payload.field].includes(action.payload.name)
      ) {
        overlay_settings[action.payload.field].splice(
          overlay_settings[action.payload.field].indexOf(action.payload.name),
          1,
        );
      } else {
        overlay_settings[action.payload.field].push(action.payload.name);
      }
      state.overlays = overlay_settings;
      return state;
    case types.REPORT_UPDATE_CALENDAR_OVERLAY:
      const { field, oldName, newName } = action.payload;
      state = { ...state };
      let overlays = { ...state.overlays };

      if (field === 'ineffective_factor' || field === 'alleviating_factor') {
        if (state.overlays.ineffective_factor) {
          const nextInEffectiveOverlays = state.overlays.ineffective_factor.map(
            (overlayName) => {
              if (overlayName === oldName) {
                return newName;
              }
              return overlayName;
            },
          );

          overlays = {
            ...overlays,
            ['ineffective_factor']: nextInEffectiveOverlays,
          };
        }

        if (state.overlays.alleviating_factor) {
          const nextAlleviatingOverlays = state.overlays.alleviating_factor.map(
            (overlayName) => {
              if (overlayName === oldName) {
                return newName;
              }
              return overlayName;
            },
          );

          overlays = {
            ...overlays,
            ['alleviating_factor']: nextAlleviatingOverlays,
          };
        }
      } else if (state.overlays[field]) {
        const nextOverlays = state.overlays[field].map((overlayName) => {
          if (overlayName === oldName) {
            return newName;
          }
          return overlayName;
        });
        overlays = {
          ...overlays,
          [field]: nextOverlays,
        };
      }

      state.overlays = overlays;
      return state;
    case types.RESET_OVERLAY:
      state = { ...state, overlays: [] };
      return state;
    // case fieldsTypes.CHANGE_SHOW:
    //     state = {...state};
    //
    //     return state;
    case types.UPDATE_1806_ADD_LOCALLY_UPDATED:
      state = {
        ...state,
        list: state.list.map((rec) => {
          return { ...rec, localUpdated: rec.updateDate };
        }),
        deleted: state.deleted.map((rec) => {
          return { ...rec, localUpdated: rec.updateDate };
        }),
      };
      return state;
    default:
      return state;
  }
}

export { RecordsReducer };
