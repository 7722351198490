const INITIAL = {
  pain_summary_enabled: 1,
  pain_summary: ['change_values'],
  filter_summary_enabled: 0,
  patient_profile_enabled: 0,
  patient_profile: [],
  charts_view_enabled: 0,
  charts_view: [],
  timeline_view_enabled: 0,
  timeline_view: [],
  pain_details_enabled: 0,
  pain_details: [{ sort_order: 'desc' }, { sort_by: 'timing' }],
  calendar_view_enabled: 0,
  calendar_view: {
    overlay_settings: [],
  },
  previous_reports: null,
};

const FIELDS = {
  pain_summary: ['change_values'],
  patient_profile: [
    'name',
    'gender',
    'birth_date',
    'weight',
    'height',
    'condition',
    'medications',
  ],
  charts_view: [
    'location',
    'symptom',
    'character',
    'aggravating_factor',
    'ineffective_factor',
    'alleviating_factor',
    'environment',
    'meaningful_activities',
  ],
  timeline_view: ['severity', 'reflection_score'],
  pain_details: [
    'location',
    'symptom',
    'character',
    'aggravating_factor',
    'ineffective_factor',
    'alleviating_factor',
    'environment',
    'meaningful_activities',
    'severity',
    'notes',
    'reflection_score',
    'reflection_notes',
    { sort_order: 'desc' },
    { sort_by: 'timing' },
  ],
};

// Unused?
// let TRANSLATIONS = {
//     'name': labels_results_and_reports.name_label_without_colon,
//     'gender': labels_results_and_reports.gender_label_without_colon,
//     'birth_date': labels_results_and_reports.birthday_label_without_colon,
//     'weight': labels_results_and_reports.weight_label_without_colon,
//     'height': labels_results_and_reports.height_label_without_colon,
//     'condition': titles_and_headers.pain_conditions_section_header,
//     'medications': titles_and_headers.medications_section_header,
//     'location': titles_and_headers.location_section_header_plural,
//     'symptom': titles_and_headers.symptoms_section_header_plural,
//     'character': titles_and_headers.character_section_header_plural,
//     'aggravating_factor': titles_and_headers.aggravating_factors_section_header_plural,
//     'ineffective_factor': titles_and_headers.ineffective_factors_section_header_plural,
//     'alleviating_factor': titles_and_headers.aggravating_factors_section_header_plural,
//     'environment': titles_and_headers.environment_section_header_plural,
//     'meaningful_activities': titles_and_headers.meaningful_activities_section_header_plural,
//     'severity': labels_all_others.pain_severity_label,
//     'reflection_score': titles_and_headers.daily_reflection_score_section_header,
//     'notes': titles_and_headers.notes_section_header,
//     'reflection_notes':titles_and_headers.notes_section_header
// };

export { INITIAL as REPORT_INITIAL, FIELDS as REPORT_FIELDS };
