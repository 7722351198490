import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { designConstants } from '../styles';
import Modal from 'react-native-modal';
import Backdrop from '@mui/material/Backdrop';
import { withTheme } from '../styles/useThemeContext';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  textContent: {
    marginTop: 120,
    height: 50,
    fontSize: 20,
  },
});

const ANIMATION = ['none', 'slide', 'fade'];
const SIZES = ['small', 'normal', 'large'];

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: this.props.visible };
  }

  static propTypes = {
    modal: PropTypes.bool,
    visible: PropTypes.bool,
    cancelable: PropTypes.bool,
    showBack: PropTypes.bool,
    textContent: PropTypes.string,
    animation: PropTypes.oneOf(ANIMATION),
    color: PropTypes.string,
    size: PropTypes.oneOf(PropTypes.number, PropTypes.oneOf(SIZES)),
    overlayColor: PropTypes.string,
  };

  static defaultProps = {
    modal: true,
    visible: false,
    cancelable: false,
    textContent: '',
    animation: 'none',
    showBack: true,
    color: '#fff',
    size: Platform.OS === 'ios' ? 'large' : 60, // 'normal',
    overlayColor: 'rgba(0, 0, 0, 0.6)',
  };

  close() {
    this.setState({ visible: false });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { visible } = nextProps;
    this.setState({ visible });
  }

  _handleOnRequestClose() {
    if (this.props.cancelable) {
      this.close();
    }
  }

  _renderDefaultContent() {
    return (
      <View style={styles.background}>
        <ActivityIndicator
          color={this.props.color}
          size={this.props.size}
          style={{ flex: 1 }}
        />
        <View style={styles.textContainer}>
          <Text
            style={[
              styles.textContent,
              { color: 'white' },
              this.props.textStyle,
            ]}
          >
            {this.props.textContent}
          </Text>
        </View>
      </View>
    );
  }

  _renderSpinner() {
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    const spinner = (
      <View style={[styles.container]} key={`spinner_${Date.now()}`}>
        {this.props.children
          ? this.props.children
          : this._renderDefaultContent()}
      </View>
    );

    if (this.props.modal) {
      return (
        <Modal
          onBackdropPress={() => this._handleOnRequestClose()}
          onRequestClose={() => this._handleOnRequestClose()}
          isVisible={visible}
        >
          {spinner}
        </Modal>
      );
    } else {
      if (designConstants.isWeb) {
        return (
          <Backdrop
            style={{ zIndex: 9999, color: '#fff' }}
            open={this.props.visible}
          >
            {spinner}
          </Backdrop>
        );
      }
      return (
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000000B3',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {spinner}
        </View>
      );
    }
  }

  render() {
    return this._renderSpinner();
  }
}

export default LoadingOverlay;
