import { apiError, checkToken } from '../Api/actions';
import {
  ADD_TRANSACTION_RECEIPT,
  COMPLETE_PURCHASE,
  COMPLETE_PURCHASE_SUCCESS,
  PURCHASES_RESTORED,
  UPDATE_SUBSCRIPTION_MANAGED,
} from './actiontypes';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import * as MMPApi from 'apiclient';
import { sync } from '../Sync/actions';
import getConfig from '../../shared/utilities/getConfig';
import { PRO_PRODUCT_ID, SUBSCRIBE_PRODUCT_ID } from './reducer';
import { getLang } from '../../shared/utilities/getLang';

export function addTransaction(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_TRANSACTION_RECEIPT,
      payload: data,
    });
    return Promise.resolve();
  };
}

export function addPurchase(data) {
  return {
    type: COMPLETE_PURCHASE,
    payload: data,
  };
}

export function updateSubscriptionManaged(
  is_subscription_managed,
  active_subscription,
) {
  return {
    type: UPDATE_SUBSCRIPTION_MANAGED,
    payload: {
      is_subscription_managed,
      active_subscription,
    },
  };
}

export function purchasesRestored() {
  return {
    type: PURCHASES_RESTORED,
  };
}
export function completePurchase(data, is_subscription) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.PurchasesApi();
      let market = Platform.OS === 'android' ? 'google' : 'apple';
      let package_name = DeviceInfo.getBundleId(); // Platform.OS === 'android' ? Config.GOOGLE_PACKAGE_NAME : Config.APPLE_PACKAGE_NAME;
      let token =
        Platform.OS === 'ios' ? data.transactionReceipt : data.purchaseToken;
      let params = MMPApi.PurchaseParams.constructFromObject({
        package_name,
        market,
        purchase_token: token,
        product_id: data.productId,
      });
      return api.coreCompletePurchasePost(getLang(), params).then(
        (result) => {
          dispatch({
            type: COMPLETE_PURCHASE_SUCCESS,
            payload: { is_subscription },
          });
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, COMPLETE_PURCHASE));
        },
      );
    });
  };
}

export function getAvailableProducts() {
  return (dispatch) => {
    const availableProducts = [
      getConfig('MMP_PRODUCT_ID_CREDITS_PREFIX') + '1',
      getConfig('MMP_PRODUCT_ID_CREDITS_PREFIX') + '3',
      getConfig('MMP_PRODUCT_ID_CREDITS_PREFIX') + '5',
      PRO_PRODUCT_ID,
      SUBSCRIBE_PRODUCT_ID,
    ];
    return Promise.resolve(availableProducts);
  };
}
