// @flow
import React from 'react';

import { StyleSheet, View } from 'react-native';
import Text from './Text';
import strings from '../../modules/Fields/strings';
import { Link } from '@react-navigation/native';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import useThemeContext from '../styles/useThemeContext';

type Props = {
  to?: string,
  onPress?: Function,
  secondaryText: string,
  touchableText: string,
  white?: boolean,
  small?: boolean,
};

export default function TextTooltip(props: Props) {
  const {
    onPress,
    to,
    touchableText,
    secondaryText,
    style,
    white,
    small,
    caps,
  } = props;
  const { colors } = useThemeContext();
  const LinkElement = to ? Link : Text;
  return (
    <View style={style}>
      <Text
        darkGray
        medium={!small}
        small={small}
        center
        style={[
          toolTipStyles.secondaryText,
          { lineHeight: small ? 20 : 26 },
          white && { color: colors.white },
        ]}
      >
        {strings.formatString(
          secondaryText,
          <LinkElement
            onPress={onPress}
            to={to}
            medium={!small}
            small={small}
            style={[
              {
                height: 24,
                textAlign: 'center',
                textDecorationLine: 'underline',
                fontSize: small ? 12 : 14,
                color: white ? colors.white : colors.linkButton,
              },
            ]}
          >
            {toSentenceCase(touchableText)}
          </LinkElement>,
        )}
      </Text>
    </View>
  );
}

const toolTipStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  secondaryText: {
    marginBottom: 2,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  touchable: {
    marginTop: -10,
  },
});
