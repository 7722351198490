import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [];

const STRINGS = new LocalizedStrings({
  en: {
    redirecting_string_redirecting_title: 'Redirecting...',
    redirecting_string_redirecting_toast: 'Institution is not activated',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: 'Download App',
    activate_account_screen_header: 'Activate Account',
    verify_email_screen_header: 'Verify Email',
    password_reset_screen_header: 'Change Password',
    download_report_screen_header: 'Download Report',
    unsubscribe_email_screen_header: 'Unsubscribe',
    expired_link_screen_header: 'Link is expired',
    subscription_settings_screen_header: 'Subscription settings',

    web_drawer_results_header: 'Results',
    web_drawer_misc_header: 'Misc',
    web_drawer_pain_guide_header: 'Pain Guide',
    web_drawer_pain_guide_home: 'Home',
    web_drawer_pain_guide_categories: 'Categories',
    web_drawer_pain_guide_bookmarked: 'Bookmarked',
    web_drawer_pain_guide_recommended: 'Recommended',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'en'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'en'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'en',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'en'),

    menu_scene_questionnaires: 'Questionnaires',
    menu_scene_login_item: shared('Log In', 'en'),
    menu_scene_logout_item: shared('Log Out', 'en'),
    menu_scene_contact_us_item: 'Contact Us',
    menu_scene_faq_item: 'FAQ',
    menu_scene_user_guide_item: 'User Guide',
    menu_scene_settings_item: shared('Settings', 'en'),

    menu_logout_alert_title: 'Confirmation required',
    menu_logout_alert_message:
      'Are you sure you want to log out from your account?',
    menu_logout_alert_yes_button: shared('Log Out', 'en'),
    menu_logout_alert_cancel_button: shared('Cancel', 'en'),

    contact_us_screen_header: 'Contact Us',
    faq_screen_header: 'FAQ',
    eula_screen_header: shared('End User License Agreement', 'en'),
    user_guide_screen_header: 'User Guide',
    privacy_policy_screen_header: shared('Privacy Policy', 'en'),

    not_logged_in_scene_text: 'Please log in to continue',
    not_logged_in_button_text: shared('Log In', 'en'),
    not_logged_in_signup_header: shared('Not registered yet', 'en'),
    not_logged_in_signup_button_text: shared('Register for Free', 'en'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'en'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'en'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'en'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'en'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'en'),
    app_navigator_bottom_tabbar_painguide_label: 'Pain Guide',
    app_navigator_bottom_tabbar_painguide_categories_label:
      'Pain Guide Categories',
    app_navigator_bottom_tabbar_painguide_content_label: 'Pain Guide',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      'Pain Guide Bookmarked',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      'Pain Guide Recommended',
    app_navigator_bottom_tabbar_painguide_program_label: 'Pain Guide Program',
    app_navigator_pain_guide_content_title: 'Pain Guide Content',

    downloading_report_screen_header: 'Report download',
    downloading_report_interrupted_label: 'Downloading failed',
    downloading_report_retry_button: shared('Retry', 'en'),
    downloading_report_in_progress_label: 'Downloading in progress...',
    downloading_report_not_authorized_toast:
      'You are not authorized to download this report',

    downloading_report_permission_request_title:
      'File storage permission needed',
    downloading_report_permission_request_message:
      'This permission is needed so you can save your report to your device',
    downloading_report_permission_request_buttonPositive: shared('OK', 'en'),

    downloading_report_permission_denied_message:
      'Report cannot be downloaded without file storage permission',
    downloading_report_permission_denied_button: 'Grant permission',
    downloading_report_permission_denied_toast: 'Permission denied',

    downloading_report_save_report_button: 'Save report',
    downloading_report_open_report_button: 'Open report',
    downloading_report_go_into_app_button: 'Return to app',

    questionnaires_screen_header: 'Questionnaire',
    questionnaires_plural_screen_header: 'Questionnaires',
    questionnaires_section_header: 'Questionnaires for {0} at {1}',
    questionnaire_due_date: 'Due date:',
    questionnaire_last_completed: 'Last Completed:',
    questionnaire_completed_by: 'Completed by: {0} on {1}',
    questionnaire_completed_by_update: 'Originally Completed by: {0} on {1}',
    questionnaire_completed_update_response:
      "You have been asked to update your questionnaire's response. Please try to answer as if you were answering on the originally-completed date.",
    questionnaire_submit_in_progress_text:
      'Sending questionnaire result to server...',
    questionnaire_submit_success_text:
      'Thank you for completing questionnaire!',
    questionnaire_submit_success_button: 'Close',
    questionnaire_submit_error_text:
      'Error happened while trying to send results to server',
    questionnaire_submit_error_button: 'Retry',
    questionnaire_completed_text: 'Questionnaires Completed:',
    questionnaire_completed_out_of_text: '{0} out of {1}',
    questionnaire_empty_text:
      'No questionnaires need to be completed at this time.',
    questionnaire_enter_responses_button: 'Enter Responses',
    questionnaire_not_institution_member_text:
      'No questionnaires need to be completed at this time - please make sure you are logged in to the correct account',
    questionnaire_status_text: 'Status:',
    questionnaire_status_to_be_updated: 'To be updated',
    questionnaire_status_to_be_completed: 'To be completed',
    questionnaire_status_recently_completed: 'Recently completed',
    questionnaire_status_incomplete_text: 'Partially completed',

    questionnaire_loading_overlay: shared('Loading...', 'en'),
    app_navigator_pain_guide_no_internet_text:
      'Internet connection cannot be established. Retry when connection is re-established.',
  },

  de: {
    redirecting_string_redirecting_title: 'Sie werden weitergeleitet...',
    redirecting_string_redirecting_toast: 'Praxis ist nicht aktiviert',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: 'App Herunterladen',
    activate_account_screen_header: 'Account aktivieren',
    verify_email_screen_header: 'E-Mail verifizieren',
    password_reset_screen_header: 'Passwort ändern',
    download_report_screen_header: 'Bericht herunterladen',
    unsubscribe_email_screen_header: 'Abmelden',
    expired_link_screen_header: 'Der Link ist abgelaufen',
    subscription_settings_screen_header: 'Abonnement-Einstellungen',

    web_drawer_results_header: 'Ergebnisse',
    web_drawer_misc_header: 'Sonstiges',
    web_drawer_pain_guide_header: 'Schmerz-Leitfaden',
    web_drawer_pain_guide_home: 'Startseite',
    web_drawer_pain_guide_categories: 'Kategorien',
    web_drawer_pain_guide_bookmarked: 'Lesezeichen',
    web_drawer_pain_guide_recommended: 'Empfohlen',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'de'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'de'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'de',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'de'),

    menu_scene_questionnaires: 'Fragebögen',
    menu_scene_login_item: shared('Log In', 'de'),
    menu_scene_logout_item: shared('Log Out', 'de'),
    menu_scene_contact_us_item: 'Kontaktieren Sie uns',
    menu_scene_faq_item: 'Häufig gestellte Fragen',
    menu_scene_user_guide_item: 'Bedienungsanleitung',
    menu_scene_settings_item: shared('Settings', 'de'),

    menu_logout_alert_title: 'Bestätigung erforderlich',
    menu_logout_alert_message:
      'Sind Sie sicher, dass Sie sich von Ihrem Konto abmelden möchten?',
    menu_logout_alert_yes_button: shared('Log Out', 'de'),
    menu_logout_alert_cancel_button: shared('Cancel', 'de'),

    contact_us_screen_header: 'Kontaktieren Sie uns',
    faq_screen_header: 'Häufig gestellte Fragen',
    eula_screen_header: shared('End User License Agreement', 'de'),
    user_guide_screen_header: 'Bedienungsanleitung',
    privacy_policy_screen_header: shared('Privacy Policy', 'de'),

    not_logged_in_scene_text: 'Bitte melden Sie sich an, um fortzufahren',
    not_logged_in_button_text: shared('Log In', 'de'),
    not_logged_in_signup_header: shared('Not registered yet', 'de'),
    not_logged_in_signup_button_text: shared('Register for Free', 'de'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'de'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'de'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'de'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'de'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'de'),
    app_navigator_bottom_tabbar_painguide_label: 'Leitfaden',
    app_navigator_bottom_tabbar_painguide_categories_label:
      'Schmerz-Leitfaden Kategorien',
    app_navigator_bottom_tabbar_painguide_content_label: 'Schmerz-Leitfaden',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      'Schmerz-Leitfaden Lesezeichen',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      'Schmerz-Leitfaden Empfohlenes',
    app_navigator_pain_guide_content_title: 'Schmerz-Leitfaden Inhalt',

    downloading_report_screen_header: 'Bericht herunterladen',
    downloading_report_interrupted_label: 'Herunterladen fehlgeschlagen',
    downloading_report_retry_button: shared('Retry', 'de'),
    downloading_report_in_progress_label: 'Lade herunter…',
    downloading_report_not_authorized_toast:
      'Sie sind nicht berechtigt, diesen Bericht herunterzuladen',

    downloading_report_permission_request_title:
      'Erlaubnis zur Datenspeicherung erforderlich',
    downloading_report_permission_request_message:
      'Diese Erlaubnis ist nötig, damit Sie Ihren Bericht auf Ihrem Gerät speichern können',
    downloading_report_permission_request_buttonPositive: shared('OK', 'de'),

    downloading_report_permission_denied_message:
      'Der Bericht kann ohne die Erlaubnis zur Datenspeicherung nicht heruntergeladen werden',
    downloading_report_permission_denied_button: 'Erlaubnis erteilen',
    downloading_report_permission_denied_toast: 'Erlaubnis verweigert',

    downloading_report_save_report_button: 'Bericht speichern',
    downloading_report_open_report_button: 'Bericht öffnen',
    downloading_report_go_into_app_button: 'Zurück zur App',

    questionnaires_screen_header: 'Fragebogen',
    questionnaires_plural_screen_header: 'Fragebögen',
    questionnaires_section_header: 'Fragebögen für {0} im {1}',
    questionnaire_due_date: 'Fälligkeitsdatum:',
    questionnaire_last_completed: 'Zuletzt Ausgefüllt:',
    questionnaire_completed_by: 'Ausgefüllt von: {0} am {1}',
    questionnaire_completed_by_update:
      'Ursprünglich ausgefüllt von: {0} am {1}',
    questionnaire_completed_update_response:
      'Sie wurden gebeten, Ihre Antworten zu aktualisieren. Bitte antworten Sie so, wie Sie es zum ursprünglichen Zeitpunkt der Antwort getan hätten.',
    questionnaire_submit_in_progress_text:
      'Versende Fragebogen bzw. Fragebögen...',
    questionnaire_submit_success_text:
      'Vielen Dank, dass Sie Ihren Fragebogen bzw. Ihre Fragebögen ausgefüllt haben!',
    questionnaire_submit_success_button: shared('Close', 'de'),
    questionnaire_submit_error_text:
      'Fehler bei der Übermittlung des Fragebogens - bitte wenden Sie sich an den Kundenservice',
    questionnaire_submit_error_button: shared('Retry', 'de'),
    questionnaire_completed_text: 'Fragebögen ausgefüllt:',
    questionnaire_completed_out_of_text: '{0} von {1}',
    questionnaire_empty_text:
      'Zur Zeit müssen keine Fragebögen ausgefüllt werden.',
    questionnaire_enter_responses_button: 'Antworten Eingeben',
    questionnaire_not_institution_member_text:
      'Zu diesem Zeitpunkt müssen keine Fragebögen ausgefüllt werden - bitte stellen Sie sicher, dass Sie im richtigen Account eingeloggt sind',

    questionnaire_status_text: 'Status',
    questionnaire_status_to_be_updated: 'Zu aktualisieren',
    questionnaire_status_to_be_completed: 'Auszufüllen',
    questionnaire_status_recently_completed: 'Kürzlich ausgefüllt',
    questionnaire_status_incomplete_text: 'Teilweise abgeschlossen',

    questionnaire_loading_overlay: shared('Loading...', 'de'),
    app_navigator_pain_guide_no_internet_text:
      'Die Internetverbindung konnte nicht aufgebaut werden. Versuchen Sie es erneut, wenn die Verbindung wiederhergestellt ist.',
  },

  es: {
    redirecting_string_redirecting_title: 'Redirigiendo…',
    redirecting_string_redirecting_toast: 'La institución no está activada',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: 'Descargar app',
    activate_account_screen_header: 'Activar cuenta',
    verify_email_screen_header: 'Verificar correo electrónico',
    password_reset_screen_header: 'Cambiar contraseña',
    download_report_screen_header: 'Descargar informe',
    unsubscribe_email_screen_header: 'Descargar informe',
    expired_link_screen_header: 'El enlace está vencido',
    subscription_settings_screen_header: 'Ajustes de la suscripción',

    web_drawer_results_header: 'Resultados',
    web_drawer_misc_header: 'Misc',
    web_drawer_pain_guide_header: 'Guía de dolor',
    web_drawer_pain_guide_home: 'Inicio',
    web_drawer_pain_guide_categories: 'Categorías',
    web_drawer_pain_guide_bookmarked: 'Favoritos',
    web_drawer_pain_guide_recommended: 'Recomendados',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'es'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'es'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'es',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'es'),

    menu_scene_questionnaires: 'Cuestionarios',
    menu_scene_login_item: shared('Log In', 'es'),
    menu_scene_logout_item: shared('Log Out', 'es'),
    menu_scene_contact_us_item: 'Contáctenos',
    menu_scene_faq_item: 'Preguntas frecuentes',
    menu_scene_user_guide_item: 'Guía del usuario',
    menu_scene_settings_item: shared('Settings', 'es'),

    menu_logout_alert_title: 'Se requiere confirmación',
    menu_logout_alert_message: '¿Está seguro de que quiere salir de su cuenta?',
    menu_logout_alert_yes_button: shared('Log Out', 'es'),
    menu_logout_alert_cancel_button: shared('Cancel', 'es'),

    contact_us_screen_header: 'Contáctenos',
    faq_screen_header: 'Preguntas frecuentes',
    eula_screen_header: shared('End User License Agreement', 'es'),
    user_guide_screen_header: 'Guía del usuario',
    privacy_policy_screen_header: shared('Privacy Policy', 'es'),

    not_logged_in_scene_text: 'Inicie sesión para continuar',
    not_logged_in_button_text: shared('Log In', 'es'),
    not_logged_in_signup_header: shared('Not registered yet', 'es'),
    not_logged_in_signup_button_text: shared('Register for Free', 'es'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'es'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'es'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'es'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'es'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'es'),
    app_navigator_bottom_tabbar_painguide_label: 'Guía de dolor',
    app_navigator_bottom_tabbar_painguide_categories_label:
      'Categorías de la Guía de dolor',
    app_navigator_bottom_tabbar_painguide_content_label: 'Guía de dolor',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      'Favoritos de la Guía de dolor',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      'Recomendados de la Guía de dolor',
    app_navigator_pain_guide_content_title: 'Contenido de la Guía de dolor',

    downloading_report_screen_header: 'Descarga de informe',
    downloading_report_interrupted_label: 'Fallo en la descarga',
    downloading_report_retry_button: shared('Retry', 'es'),
    downloading_report_in_progress_label: 'Descarga en curso…',
    downloading_report_not_authorized_toast:
      'Usted no está autorizado a descargar este informe',

    downloading_report_permission_request_title:
      'Se requieren permisos de acceso a archivos',
    downloading_report_permission_request_message:
      'Este permiso en necesario para guardar el informe en su dispositivo',
    downloading_report_permission_request_buttonPositive: shared('OK', 'es'),

    downloading_report_permission_denied_message:
      'El informe no puede ser descargado sin el permiso de acceso a archivos',
    downloading_report_permission_denied_button: 'Conceder permiso',
    downloading_report_permission_denied_toast: 'Permiso denegado',

    downloading_report_save_report_button: 'Guardar informe',
    downloading_report_open_report_button: 'Abrir informe',
    downloading_report_go_into_app_button: 'Volver a la aplicación',

    questionnaires_screen_header: 'Cuestionario',
    questionnaires_plural_screen_header: 'Cuestionarios',
    questionnaires_section_header: 'Cuestionarios para {0} en {1}',
    questionnaire_due_date: 'Fecha de vencimiento:',
    questionnaire_last_completed: 'Completado el:',
    questionnaire_completed_by: 'Completado por: {0} el {1}',
    questionnaire_completed_by_update:
      'Completado originalmente por: {0} el {1}',
    questionnaire_completed_update_response:
      'Debe actualizar la respuesta de su cuestionario. Trate de responder como si lo estuviese haciendo en la fecha original en que lo completó.',
    questionnaire_submit_in_progress_text: 'Enviando cuestionario(s)…',
    questionnaire_submit_success_text:
      '¡Gracias por completar su cuestionario!',
    questionnaire_submit_success_button: shared('Close', 'es'),
    questionnaire_submit_error_text:
      'Problema al enviar cuestionario(s) - comuníquese con soporte',
    questionnaire_submit_error_button: shared('Retry', 'es'),
    questionnaire_completed_text: 'Cuestionarios completos:',
    questionnaire_completed_out_of_text: '{0} de {1}',
    questionnaire_empty_text:
      'No necesita completar cuestionarios en este momento.',
    questionnaire_enter_responses_button: 'Agregar respuestas',
    questionnaire_not_institution_member_text:
      'No hay cuestionarios a completar en este momento. Asegúrese de haber iniciado sesión en la cuenta correcta.',

    questionnaire_status_text: 'Estado',
    questionnaire_status_to_be_updated: 'Por actualizar',
    questionnaire_status_to_be_completed: 'Por completar',
    questionnaire_status_recently_completed: 'Completado recientemente',
    questionnaire_status_incomplete_text: 'Terminado parcialmente',

    questionnaire_loading_overlay: shared('Loading...', 'es'),
    app_navigator_pain_guide_no_internet_text:
      'No se puede establecer la conexión a Internet. Vuelva a intentarlo cuando se restablezca la conexión.',
  },

  fr: {
    redirecting_string_redirecting_title: 'Redirection en cours...',
    redirecting_string_redirecting_toast: "L'organisme n'a pas été activé",
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: "Télécharger l'application",
    activate_account_screen_header: 'Activer le compte',
    verify_email_screen_header: "Vérifier l'e-mail",
    password_reset_screen_header: 'Modifier le mot de passe',
    download_report_screen_header: 'Télécharger le rapport',
    unsubscribe_email_screen_header: 'Se désabonner',
    expired_link_screen_header: 'Le lien a expiré',
    subscription_settings_screen_header: "Paramètres d'abonnement",

    web_drawer_results_header: 'Résultats',
    web_drawer_misc_header: 'Divers',
    web_drawer_pain_guide_header: 'Guide sur la douleur',
    web_drawer_pain_guide_home: 'Accueil',
    web_drawer_pain_guide_categories: 'Catégories',
    web_drawer_pain_guide_bookmarked: 'Signet ajouté',
    web_drawer_pain_guide_recommended: 'Recommandé',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'fr'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'fr'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'fr',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'fr'),

    menu_scene_questionnaires: 'Questionnaires',
    menu_scene_login_item: shared('Log In', 'fr'),
    menu_scene_logout_item: shared('Log Out', 'fr'),
    menu_scene_contact_us_item: 'Contactez-nous',
    menu_scene_faq_item: 'FAQ',
    menu_scene_user_guide_item: "Guide de l'utilisateur",
    menu_scene_settings_item: shared('Settings', 'fr'),

    menu_logout_alert_title: 'Confirmation requise',
    menu_logout_alert_message:
      'Voulez-vous vraiment vous déconnecter de votre compte?',
    menu_logout_alert_yes_button: shared('Log Out', 'fr'),
    menu_logout_alert_cancel_button: shared('Cancel', 'fr'),

    contact_us_screen_header: 'Contactez-nous',
    faq_screen_header: 'FAQ',
    eula_screen_header: shared('End User License Agreement', 'fr'),
    user_guide_screen_header: "Guide de l'utilisateur",
    privacy_policy_screen_header: shared('Privacy Policy', 'fr'),

    not_logged_in_scene_text: 'Veuillez vous connecter pour continuer',
    not_logged_in_button_text: shared('Log In', 'fr'),
    not_logged_in_signup_header: shared('Not registered yet', 'fr'),
    not_logged_in_signup_button_text: shared('Register for Free', 'fr'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'fr'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'fr'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'fr'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'fr'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'fr'),
    app_navigator_bottom_tabbar_painguide_label: 'Guide',
    app_navigator_bottom_tabbar_painguide_categories_label:
      'Catégories du Guide sur la douleur',
    app_navigator_bottom_tabbar_painguide_content_label: 'Guide sur la douleur',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      'Signet ajouté pour le Guide sur la douleur',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      'Guide sur la douleur recommandé',
    app_navigator_pain_guide_content_title: 'Contenu du Guide sur la douleur',

    downloading_report_screen_header: 'Téléchargement du rapport',
    downloading_report_interrupted_label: 'Le téléchargement a échoué',
    downloading_report_retry_button: shared('Retry', 'fr'),
    downloading_report_in_progress_label: 'Téléchargement en cours…',
    downloading_report_not_authorized_toast:
      "Vous n'êtes pas autorisé(e) à télécharger ce rapport",

    downloading_report_permission_request_title:
      'Autorisation de stockage de fichiers requise',
    downloading_report_permission_request_message:
      'Cette autorisation est nécessaire pour pouvoir enregistrer votre rapport sur votre appareil',
    downloading_report_permission_request_buttonPositive: shared('OK', 'fr'),

    downloading_report_permission_denied_message:
      'Le rapport ne peut être téléchargé sans autorisation de stockage de fichiers',
    downloading_report_permission_denied_button: "Accorder l'autorisation",
    downloading_report_permission_denied_toast: 'Autorisation refusée',

    downloading_report_save_report_button: 'Sauvegarder le rapport',
    downloading_report_open_report_button: 'Ouvrir le rapport',
    downloading_report_go_into_app_button: "Retourner à l'application",

    questionnaires_screen_header: 'Questionnaire',
    questionnaires_plural_screen_header: 'Questionnaires',
    questionnaires_section_header: 'Questionnaires pour {0} dans {1}',
    questionnaire_due_date: "Date d'échéance :",
    questionnaire_last_completed: 'Complété le :',
    questionnaire_completed_by: 'Complété par : {0} le {1}',
    questionnaire_completed_by_update:
      'Complété originalement par : {0} le {1}',
    questionnaire_completed_update_response:
      "Il est nécessaire que vous missiez à jour votre réponse au questionnaire. Veuillez répondre comme si c'était la date originale que vous avez complété le questionnaire.",
    questionnaire_submit_in_progress_text:
      'Soumission du/des questionnaire(s)...',
    questionnaire_submit_success_text:
      "Merci d'avoir répondu à votre/vos questionnaire(s) !",
    questionnaire_submit_success_button: shared('Close', 'fr'),
    questionnaire_submit_error_text:
      "Problème d'envoi du questionnaire(s) - veuillez contacter l'assistance",
    questionnaire_submit_error_button: shared('Retry', 'fr'),
    questionnaire_completed_text: 'Questionnaires complétés :',
    questionnaire_completed_out_of_text: '{0} sur {1}',
    questionnaire_empty_text:
      'Pas besoin de compléter des questionnaires cette fois.',
    questionnaire_enter_responses_button: 'Entrez des réponses',
    questionnaire_not_institution_member_text:
      "Aucun questionnaire ne nécessite d'être rempli pour l'instant - veuillez vous assurer d'être connecté(e) au bon compte.",

    questionnaire_status_text: 'Statut',
    questionnaire_status_to_be_updated: 'À mettre',
    questionnaire_status_to_be_completed: 'À compléter',
    questionnaire_status_recently_completed: 'Complété récemment',
    questionnaire_status_incomplete_text: 'Partiellement complétés',

    questionnaire_loading_overlay: shared('Loading...', 'fr'),
    app_navigator_pain_guide_no_internet_text:
      'La connexion à Internet ne peut être établie. Veuillez réessayer une fois la connexion rétablie.',
  },

  ko: {
    redirecting_string_redirecting_title: '재연결 중...',
    redirecting_string_redirecting_toast: '기관이 활성화되지 않았습니다',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: '앱 다운로드',
    activate_account_screen_header: '계정 활성화',
    verify_email_screen_header: '이메일 확인',
    password_reset_screen_header: '암호 변경',
    download_report_screen_header: '보고서 다운로드',
    unsubscribe_email_screen_header: '구독 취소',
    expired_link_screen_header: '링크가 만료됐습니다',
    subscription_settings_screen_header: '구독 설정',

    web_drawer_results_header: '결과',
    web_drawer_misc_header: '기타',
    web_drawer_pain_guide_header: '통증 가이드',
    web_drawer_pain_guide_home: '홈',
    web_drawer_pain_guide_categories: '카테고리',
    web_drawer_pain_guide_bookmarked: '북마크 설정됨',
    web_drawer_pain_guide_recommended: '권장됨',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'ko'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'ko'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'ko',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'ko'),

    menu_scene_questionnaires: '설문지',
    menu_scene_login_item: shared('Log In', 'ko'),
    menu_scene_logout_item: shared('Log Out', 'ko'),
    menu_scene_contact_us_item: '문의',
    menu_scene_faq_item: 'FAQ',
    menu_scene_user_guide_item: '사용자 가이드',
    menu_scene_settings_item: shared('Settings', 'ko'),

    menu_logout_alert_title: '확인이 필요합니다.',
    menu_logout_alert_message: '정말 계좌에서 로그아웃 하시겠어요?',
    menu_logout_alert_yes_button: shared('Log Out', 'ko'),
    menu_logout_alert_cancel_button: shared('Cancel', 'ko'),

    contact_us_screen_header: '문의',
    faq_screen_header: 'FAQ',
    eula_screen_header: shared('End User License Agreement', 'ko'),
    user_guide_screen_header: '사용자 가이드',
    privacy_policy_screen_header: shared('Privacy Policy', 'ko'),

    not_logged_in_scene_text: '계속하려면 로그인하세요',
    not_logged_in_button_text: shared('Log In', 'ko'),
    not_logged_in_signup_header: shared('Not registered yet', 'ko'),
    not_logged_in_signup_button_text: shared('Register for Free', 'ko'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'ko'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'ko'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'ko'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'ko'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'ko'),
    app_navigator_bottom_tabbar_painguide_label: '통증 가이드',
    app_navigator_bottom_tabbar_painguide_categories_label:
      '통증 가이드 카테고리',
    app_navigator_bottom_tabbar_painguide_content_label: '통증 가이드',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      '통증 가이드 북마크 설정됨',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      '통증 가이드 권장됨',
    app_navigator_pain_guide_content_title: '통증 가이드 콘텐츠',

    downloading_report_screen_header: '보고서 다운로드',
    downloading_report_interrupted_label: '다운로드 실패',
    downloading_report_retry_button: shared('Retry', 'ko'),
    downloading_report_in_progress_label: '다운로드 진행 중…',
    downloading_report_not_authorized_toast:
      '이 보고서를 다운로드할 권한이 없습니다',

    downloading_report_permission_request_title:
      '파일 저장소에 대한 권한이 필요합니다',
    downloading_report_permission_request_message:
      '이 권한을 허가해야만 장치에 보고서를 저장할 수 있습니다',
    downloading_report_permission_request_buttonPositive: shared('OK', 'ko'),

    downloading_report_permission_denied_message:
      '보고서는 파일 저장소에 대한 허가 없이 다운로드할 수 없습니다',
    downloading_report_permission_denied_button: '권한 허가',
    downloading_report_permission_denied_toast: '권한 거절',

    downloading_report_save_report_button: '보고서 저장',
    downloading_report_open_report_button: '보고서 열기',
    downloading_report_go_into_app_button: '앱으로 돌아가기',

    questionnaires_screen_header: '질문 사항',
    questionnaires_plural_screen_header: '설문지',
    questionnaires_section_header: '{1} 중 {0}의 질문 사항',
    questionnaire_due_date: '만기일:',
    questionnaire_last_completed: '마지막 완료:',
    questionnaire_completed_by: '완료자: {1}의 {0}',
    questionnaire_completed_by_update: '원래 작성자: {1}의 {0}',
    questionnaire_completed_update_response:
      '설문지의 응답을 업데이트하라는 요청을 받았습니다. 마치 원래 완료된 날짜에 답하는 것처럼 대답하세요.',
    questionnaire_submit_in_progress_text: '설문지를 제출하는 중입니다...',
    questionnaire_submit_success_text: '설문지를 작성해 주셔서 감사합니다!',
    questionnaire_submit_success_button: shared('Close', 'ko'),
    questionnaire_submit_error_text:
      '설문지 제출 문제 - 지원팀에게 문의하세요 ',
    questionnaire_submit_error_button: shared('Retry', 'ko'),
    questionnaire_completed_text: '질문 사항:',
    questionnaire_completed_out_of_text: '{1} 중 {0}',
    questionnaire_empty_text: '현재 설문지를 작성하지 않아도됩니다.',
    questionnaire_enter_responses_button: '응답 입력',
    questionnaire_not_institution_member_text:
      '지금은 설문지를 작성할 필요가 없습니다. 올바른 계정으로 로그인했는지 확인하세요.',

    questionnaire_status_text: '상태',
    questionnaire_status_to_be_updated: '업데이트할 것',
    questionnaire_status_to_be_completed: '완료할 것',
    questionnaire_status_recently_completed: '최근에 완료함',
    questionnaire_status_incomplete_text: '일부 완료됨',

    questionnaire_loading_overlay: shared('Loading...', 'ko'),
    app_navigator_pain_guide_no_internet_text:
      '인터넷 연결이 설정되지 않았습니다. 연결이 재설정되면 다시 시도하세요.',
  },

  ru: {
    redirecting_string_redirecting_title: 'Перенаправляем...',
    redirecting_string_redirecting_toast: 'Учреждение неактивно',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: 'Загрузить приложение',
    activate_account_screen_header: 'Активировать аккаунт',
    verify_email_screen_header: 'Подтвердить email',
    password_reset_screen_header: 'Изменить пароль',
    download_report_screen_header: 'Скачать отчёт',
    unsubscribe_email_screen_header: 'Отказаться от подписки',
    expired_link_screen_header: 'Срок действия ссылки истёк',
    subscription_settings_screen_header: 'Настройки рассылки',

    web_drawer_results_header: 'Результаты',
    web_drawer_misc_header: 'Разное',
    web_drawer_pain_guide_header: 'Всё о боли',
    web_drawer_pain_guide_home: 'Главная',
    web_drawer_pain_guide_categories: 'Категории',
    web_drawer_pain_guide_bookmarked: 'Закладки',
    web_drawer_pain_guide_recommended: 'Рекомендовано',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'ru'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'ru'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'ru',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'ru'),

    menu_scene_questionnaires: 'Опросники',
    menu_scene_login_item: shared('Log In', 'ru'),
    menu_scene_logout_item: shared('Log Out', 'ru'),
    menu_scene_contact_us_item: 'Связаться с нами',
    menu_scene_faq_item: 'ЧАВО',
    menu_scene_user_guide_item: 'Руководство пользователя',
    menu_scene_settings_item: shared('Settings', 'ru'),

    menu_logout_alert_title: 'Требуется подтверждение.',
    menu_logout_alert_message: 'Уверены, что хотите выйти из аккаунта?',
    menu_logout_alert_yes_button: shared('Log Out', 'ru'),
    menu_logout_alert_cancel_button: shared('Cancel', 'ru'),

    contact_us_screen_header: 'Связаться с нами',
    faq_screen_header: 'ЧАВО',
    eula_screen_header: shared('End User License Agreement', 'ru'),
    user_guide_screen_header: 'Руководство пользователя',
    privacy_policy_screen_header: shared('Privacy Policy', 'ru'),

    not_logged_in_scene_text: 'Пожалуйста, войдите, чтобы продолжить.',
    not_logged_in_button_text: shared('Log In', 'ru'),
    not_logged_in_signup_header: shared('Not registered yet', 'ru'),
    not_logged_in_signup_button_text: shared('Register for Free', 'ru'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'ru'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'ru'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'ru'),
    app_navigator_bottom_tabbar_my_profile_label: shared('My Profile', 'ru'),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'ru'),
    app_navigator_bottom_tabbar_painguide_label: 'Всё о боли',
    app_navigator_bottom_tabbar_painguide_categories_label:
      'Категории «Всё о боли»',
    app_navigator_bottom_tabbar_painguide_content_label: 'Всё о боли',
    app_navigator_bottom_tabbar_painguide_bookmarked_label:
      'Закладки во «Всё о боли»',
    app_navigator_bottom_tabbar_painguide_recommended_label:
      'Рекомендации «Всё о боли»',
    app_navigator_pain_guide_content_title: 'Содержание «Всё о боли»',

    downloading_report_screen_header: 'Загрузка отчёта',
    downloading_report_interrupted_label: 'Ошибка загрузки',
    downloading_report_retry_button: shared('Retry', 'ru'),
    downloading_report_in_progress_label: 'Идёт загрузка...',
    downloading_report_not_authorized_toast:
      'У вас нет прав на загрузку этого отчёта',

    downloading_report_permission_request_title:
      'Нужно разрешение на доступ к хранилищу файлов',
    downloading_report_permission_request_message:
      'Разрешение требуется для сохранения отчёта на вашем устройстве',
    downloading_report_permission_request_buttonPositive: shared('OK', 'ru'),

    downloading_report_permission_denied_message:
      'Загрузка отчёта без доступа к хранилищу файлов невозможна',
    downloading_report_permission_denied_button: 'Предоставить разрешение',
    downloading_report_permission_denied_toast: 'В доступе отказано',

    downloading_report_save_report_button: 'Скачать отчет',
    downloading_report_open_report_button: 'Открыть отчет',
    downloading_report_go_into_app_button: 'Назад в приложение',

    questionnaires_screen_header: 'Опросник',
    questionnaires_plural_screen_header: 'Опросники',
    questionnaires_section_header: 'Опросники для {0} из {1}',
    questionnaire_due_date: 'Завершить до:',
    questionnaire_last_completed: 'Последнее прохождение:',
    questionnaire_completed_by: 'Заполнено: {0} {1}',
    questionnaire_completed_by_update: 'Изначально заполнено: {0} {1}',
    questionnaire_completed_update_response:
      'Вас просят обновить ваши ответы в опроснике. Пожалуйста, попытайтесь отвечать так, будто вы отвечаете в момент первичного заполнения.',
    questionnaire_submit_in_progress_text: 'Отправка опросников...',
    questionnaire_submit_success_text: 'Спасибо, что заполнили опросники!',
    questionnaire_submit_success_button: shared('Close', 'ru'),
    questionnaire_submit_error_text:
      'Ошибка при отправке опросника(-ов) - обратитесь в тех. поддержку',
    questionnaire_submit_error_button: shared('Retry', 'ru'),
    questionnaire_completed_text: 'Опросники пройдены:',
    questionnaire_completed_out_of_text: '{0} из {1}',
    questionnaire_empty_text:
      'На данный момент заполнение опросников не требуется.',
    questionnaire_enter_responses_button: 'Введите ответы',
    questionnaire_not_institution_member_text:
      'Незаполненные опросники сейчас отсутствуют. Пожалуйста, убедитесь, что вы вошли в нужный аккаунт.',

    questionnaire_status_text: 'Статус',
    questionnaire_status_to_be_updated: 'Необходимо обновить',
    questionnaire_status_to_be_completed: 'Необходимо заполнить',
    questionnaire_status_recently_completed: 'Заполнен недавно',
    questionnaire_status_incomplete_text: 'Частично завершено',

    questionnaire_loading_overlay: shared('Loading...', 'ru'),
    app_navigator_pain_guide_no_internet_text:
      'Не удается подключиться к интернету. Повторите попытку, когда связь восстановится.',
  },

  'zh-rCN': {
    redirecting_string_redirecting_title: '正在跳转...',
    redirecting_string_redirecting_toast: '机构服务尚未启动',
    web_screen_header: '{0} | Manage My Pain',
    download_app_screen_header: '下载应用程序',
    activate_account_screen_header: '启动帐户',
    verify_email_screen_header: '验证电子邮件',
    password_reset_screen_header: '更改密码',
    download_report_screen_header: '下载报告',
    unsubscribe_email_screen_header: '取消订阅',
    expired_link_screen_header: '链接已过期',
    subscription_settings_screen_header: '订阅设置',

    web_drawer_results_header: '结果',
    web_drawer_misc_header: '其他',
    web_drawer_pain_guide_header: '疼痛指南',
    web_drawer_pain_guide_home: '主页',
    web_drawer_pain_guide_categories: '类别',
    web_drawer_pain_guide_bookmarked: '收藏',
    web_drawer_pain_guide_recommended: '推荐',

    //Report screen
    lite_bottom_dialog_text: shared('lite_version_warning', 'zh-rCN'),
    lite_bottom_dialog_button: shared('Upgrade to Pro', 'zh-rCN'),
    lite_bottom_not_logged_dialog_text: shared(
      'lite_version_warning_login',
      'zh-rCN',
    ),
    lite_bottom_not_logged_dialog_button: shared('Log In', 'zh-rCN'),

    menu_scene_questionnaires: '调查问卷',
    menu_scene_login_item: shared('Log In', 'zh-rCN'),
    menu_scene_logout_item: shared('Log Out', 'zh-rCN'),
    menu_scene_contact_us_item: '联系我们',
    menu_scene_faq_item: '常见问题',
    menu_scene_user_guide_item: '用户指南',
    menu_scene_settings_item: shared('Settings', 'zh-rCN'),

    menu_logout_alert_title: '需要确认',
    menu_logout_alert_message: '您确定要退出账户吗？',
    menu_logout_alert_yes_button: shared('Log Out', 'zh-rCN'),
    menu_logout_alert_cancel_button: shared('Cancel', 'zh-rCN'),

    contact_us_screen_header: '联系我们',
    faq_screen_header: '常见问题',
    eula_screen_header: shared('End User License Agreement', 'zh-rCN'),
    user_guide_screen_header: '用户指南',
    privacy_policy_screen_header: shared('Privacy Policy', 'zh-rCN'),

    not_logged_in_scene_text: '请登录以继续操作',
    not_logged_in_button_text: shared('Log In', 'zh-rCN'),
    not_logged_in_signup_header: shared('Not registered yet', 'zh-rCN'),
    not_logged_in_signup_button_text: shared('Register for Free', 'zh-rCN'),

    contactus_email: 'mailto:{0}?subject={1}&body={2}',
    contactus_email_contact: 'contact@managinglife.com',
    contactus_email_subject: '{0} Device Contact',

    // components/AppNavigator
    app_navigator_bottom_tabbar_home_label: shared('Home', 'zh-rCN'),
    app_navigator_bottom_tabbar_results_label: shared('Results', 'zh-rCN'),
    app_navigator_bottom_tabbar_reports_label: shared('Reports', 'zh-rCN'),
    app_navigator_bottom_tabbar_my_profile_label: shared(
      'My Profile',
      'zh-rCN',
    ),
    app_navigator_bottom_tabbar_menu_label: shared('Menu', 'zh-rCN'),
    app_navigator_bottom_tabbar_painguide_label: '疼痛指南',
    app_navigator_bottom_tabbar_painguide_categories_label: '疼痛指南类别',
    app_navigator_bottom_tabbar_painguide_content_label: '疼痛指南',
    app_navigator_bottom_tabbar_painguide_bookmarked_label: '收藏的疼痛指南',
    app_navigator_bottom_tabbar_painguide_recommended_label: '推荐的疼痛指南',
    app_navigator_pain_guide_content_title: '疼痛指南内容',

    downloading_report_screen_header: '报告下载',
    downloading_report_interrupted_label: '下载失败',
    downloading_report_retry_button: shared('Retry', 'zh-rCN'),
    downloading_report_in_progress_label: '正在下载…',
    downloading_report_not_authorized_toast: '你无权限下载此报告',

    downloading_report_permission_request_title: '需要文件存储权限',
    downloading_report_permission_request_message:
      '你需要此权限, 才能将报告保存到你的设备上',
    downloading_report_permission_request_buttonPositive: shared(
      'OK',
      'zh-rCN',
    ),

    downloading_report_permission_denied_message:
      '没有文件存储权限, 报告无法下载',
    downloading_report_permission_denied_button: '授予权限',
    downloading_report_permission_denied_toast: '没有权限',

    downloading_report_save_report_button: '保存报告',
    downloading_report_open_report_button: '打开报告',
    downloading_report_go_into_app_button: '返回应用',

    questionnaires_screen_header: '调查问卷',
    questionnaires_plural_screen_header: '调查问卷',
    questionnaires_section_header: '来自{1}的{0}的调查问卷',
    questionnaire_due_date: '截止日期:',
    questionnaire_last_completed: '上次完成日期:',
    questionnaire_completed_by: '{0}完成于{1}',
    questionnaire_completed_by_update: '最初由{0}完成于{1}',
    questionnaire_completed_update_response:
      '您被要求更新您在问卷中的回答。回答时请假定您是在最初的完成日期。',
    questionnaire_submit_in_progress_text: '正在提交问卷...',
    questionnaire_submit_success_text: '感谢您完成问卷！',
    questionnaire_submit_success_button: shared('Close', 'zh-rCN'),
    questionnaire_submit_error_text: '问卷提交出错——请联系客服',
    questionnaire_submit_error_button: shared('Retry', 'zh-rCN'),
    questionnaire_completed_text: '调查问卷已完成：',
    questionnaire_completed_out_of_text: '{0}份，共{1}份',
    questionnaire_empty_text: '目前没有需要完成的问卷。',
    questionnaire_enter_responses_button: '填写回答',
    questionnaire_not_institution_member_text:
      '不需要填写问卷，请确保您已登录到正确的帐户',

    questionnaire_status_text: '状态',
    questionnaire_status_to_be_updated: '需要更新',
    questionnaire_status_to_be_completed: '尚未完成',
    questionnaire_status_recently_completed: '最近完成',
    questionnaire_status_incomplete_text: '部分完成',

    questionnaire_loading_overlay: shared('Loading...', 'zh-rCN'),
    app_navigator_pain_guide_no_internet_text:
      '无法建立互联网连接。 重新建立连接时重试。',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
