import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import OutlinedButton from './OutlinedButton';
import FooterButton from './FooterButton';
import MediumEmphasisButton from './MediumEmphasisButton';
import LowEmphasisButton from './LowEmphasisButton';
import HighEmphasisButton from './HighEmphasisButton';
import useThemeContext from '../styles/useThemeContext';

const Button = (props) => {
  const {
    caps,
    disabled,
    style,
    text,
    outlined,
    primary,
    reverse,
    noBorder,
    transparent,

    low,
    medium,
    high,
    footer,
    noDelay,
    tag,

    long,
    small,

    textColor,
    ignoreSentenceCase,
  } = props;

  const { colors } = useThemeContext();
  const Touch = disabled ? View : TouchableNative;

  const onPress = (event) => {
    if (props.disabled) {
      return;
    }
    trackButtonPress('Button ' + (props.tag || props.text));
    props.onPress(event);
  };
  if (props.medium) {
    return <MediumEmphasisButton {...props} onPress={onPress} />;
  }

  if (props.high) {
    return <HighEmphasisButton {...props} onPress={onPress} />;
  }

  if (props.footer) {
    return <FooterButton {...props} onPress={onPress} />;
  }

  if (props.low) {
    return <LowEmphasisButton {...props} onPress={onPress} />;
  }

  if (text || props.children) {
    return (
      <Touch
        {...props}
        onPress={onPress}
        {...setTestID(props.tag || props.text)}
        style={[
          styles.root,
          {
            backgroundColor:
              outlined || transparent
                ? 'none'
                : primary
                  ? colors.lightGreen
                  : colors.buttonBlue,
            borderWidth: noBorder ? 0 : 1,
            borderColor: primary
              ? colors.lightGreen
              : reverse
                ? colors.defaultBackground
                : colors.buttonBlue,
          },
          disabled && {
            backgroundColor:
              outlined || transparent ? 'none' : colors.lightGray,
            borderColor: colors.lightGray,
          },
          style,
        ]}
      >
        {text ? (
          <Text
            style={{
              textAlign: 'center',
              color:
                textColor ||
                (outlined && !reverse
                  ? disabled
                    ? colors.lightGray
                    : colors.buttonBlue
                  : colors.white),
              fontSize: 14,
            }}
          >
            {ignoreSentenceCase ? text : toSentenceCase(text)}
          </Text>
        ) : (
          props.children
        )}
      </Touch>
    );
  }

  if (props.outlined) {
    return <OutlinedButton {...props} onPress={onPress} />;
  }
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 6,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});

Button.propTypes = {
  long: PropTypes.bool,
  small: PropTypes.bool,
  caps: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outlined: PropTypes.bool,
  primary: PropTypes.bool,
  reverse: PropTypes.bool,
  noBorder: PropTypes.bool,

  low: PropTypes.bool,
  medium: PropTypes.bool,
  high: PropTypes.bool,
  footer: PropTypes.bool,
  noDelay: PropTypes.bool,
  tag: PropTypes.string,
  ignoreSentenceCase: PropTypes.bool,
};

Button.defaultProps = {
  long: false,
  small: false,
  disabled: false,
  caps: false,
  style: {},
  onPress: () => null,
  text: '',
  outlined: false,
  primary: false,
  reverse: false,
  noBorder: false,
  low: false,
  medium: false,
  high: false,
  footer: false,
  noDelay: false,
  ignoreSentenceCase: false,
};

export default Button;
