import Moment from 'moment-timezone';
import { NativeModules, Platform } from 'react-native';
//We are using authStrings, because anyway we have to load them for user login
import authStrings from '../../modules/Auth/strings';
import { dateFormats, getDateFormat } from '../../design/styles/dateformats';

const { SettingsModule } = NativeModules;

let timeZone = '';

function getLanguage() {
  let language = authStrings.getLanguage();
  if (language === 'zh-rCN' || language === 'zh') {
    language = 'zh-cn';
  }
  return language;
}
export function shouldShowDailyReflection(time) {
  let start = Moment('18:45:00', 'hh:mm:ss');
  let compare = start.clone();
  compare = compare.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
    second: time.get('second'),
  });
  if (compare.isBefore(Moment('12:00:00', 'hh:mm:ss'))) {
    compare = compare.add(24, 'hour');
  }
  let end = Moment('02:00:00', 'hh:mm:ss').add(1, 'day');
  let result = compare.isBetween(start, end);
  //    console.log('Should show reflection', compare, start, end, result);
  return result;
}
export function getOffset(timestamp) {
  return new Date(timestamp).getTimezoneOffset() * 1000 * 60 * -1;
}
export function localeMoment(moment) {
  return moment.locale(getLanguage());
}
export function formatMoment(timestamp, recordOffset = 0) {
  if (timestamp && typeof timestamp !== 'undefined') {
    if (typeof timestamp === 'string') {
      timestamp = parseInt(timestamp);
    }
    let offset = getOffset(timestamp);
    return Moment(timestamp + recordOffset - offset).locale(getLanguage());
  } else {
    return null;
  }
}
export function isAllDay(timestamp, recordOffset, duration) {
  let moment = formatMoment(timestamp, recordOffset);
  return moment.format('HH:mm') === '00:00' && duration === 24 * 60 * 60;
}
export function formatDateTime(
  timestamp,
  recordOffset,
  type,
  format,
  duration,
) {
  if (timestamp && typeof timestamp !== 'undefined') {
    let moment = formatMoment(timestamp, recordOffset);
    let allDay =
      moment.format('HH:mm') === '00:00' && duration === 24 * 60 * 60;
    if (allDay || type === 'DailyReflection') {
      return moment.format(format || getDateFormat('medium'));
    } else {
      return moment.format(format || getDateFormat('long'));
    }
  } else {
    return 'Wrong date';
  }
}

export function updateTimeZone() {
  if (Platform.OS !== 'web') {
    SettingsModule.getTimeZone().then((result) => {
      timeZone = result;
    });
  }
}

export function timezoneMoment(...args) {
  if (Platform.OS === 'web') {
    return Moment(...args);
  } else {
    return Moment.tz(...args, timeZone);
  }
}
