import React, { Component } from 'react';
import { Keyboard, Platform } from 'react-native';

export default function KeyboardNotifyingHOC(WrappedComponent) {
  return class KeyboardNotifyingView extends Component {
    state = {
      keyboardIsShown: false,
    };

    __mounted = false;
    keyboardDidShowListener = () => {};
    keyboardDidHideListener = () => {};

    componentDidMount() {
      this.__mounted = true;
      if (Platform.OS === 'ios') {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardWillShow',
          this._keyboardDidShow,
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardWillHide',
          this._keyboardDidHide,
        );
      } else {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardDidShow',
          this._keyboardDidShow,
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide,
        );
      }
    }

    componentWillUnmount() {
      this.__mounted = false;
      this.keyboardDidShowListener.remove();
      this.keyboardDidHideListener.remove();
      Keyboard.dismiss();
    }

    _keyboardDidShow = () => {
      this.setState({ keyboardIsShown: true });
    };

    _keyboardDidHide = () => {
      this.setState({ keyboardIsShown: false });
    };

    render() {
      const { keyboardIsShown } = this.state;
      return (
        <WrappedComponent {...this.props} keyboardIsShown={keyboardIsShown} />
      );
    }
  };
}
