// @flow
import defaults from './constants';

export type FieldPlural = 'singular' | 'plural';

export function isNothingNamedField(name: string): boolean {
  return name === 'Nothing';
}

export function getDefaultFields(): void {
  let fields: any = {};
  for (let field in defaults) {
    var id = 0;
    fields[field] = defaults[field].map((f) => {
      let currentId = id;
      id = id + 1;
      return {
        ...f,
        id: currentId,
        position: 0,
        icon_color: '#000',
        systemDefault: true,
        type: field,
      };
    });
  }

  return { ...fields };
}

export const FIELDS_ALL = [
  'location',
  'character',
  'alleviating_factor',
  'aggravating_factor',
  'ineffective_factor',
  'symptom',
  'environment',
  'meaningful_activities',
];

export const FIELDS = [
  'location',
  'character',
  'alleviating_factor',
  'aggravating_factor',
  'ineffective_factor',
  'symptom',
  'environment',
];

export const defaultFields = {
  location: [
    {
      show: true,
      icon_code: 'locations-abdomen',
      name: 'Abdomen',
    },
    {
      show: true,
      icon_code: 'locations-back',
      name: 'Back',
    },
    {
      show: false,
      icon_code: 'locations-back-lower',
      name: 'Back (Lower)',
    },
    {
      show: false,
      icon_code: 'locations-back-mid',
      name: 'Back (Mid)',
    },
    {
      show: false,
      icon_code: 'locations-back-upper',
      name: 'Back (Upper)',
    },
    {
      show: true,
      icon_code: 'locations-chest',
      name: 'Chest',
    },
    {
      show: false,
      icon_code: 'locations-feet',
      name: 'Feet',
    },
    {
      show: false,
      icon_code: 'locations-foot-left',
      name: 'Foot (Left)',
    },
    {
      show: false,
      icon_code: 'locations-foot-right',
      name: 'Foot (Right)',
    },
    {
      show: true,
      icon_code: 'locations-head',
      name: 'Head',
    },
    {
      show: false,
      icon_code: 'locations-head-left',
      name: 'Head (Left)',
    },
    {
      show: false,
      icon_code: 'locations-head-right',
      name: 'Head (Right)',
    },
    {
      show: false,
      icon_code: 'locations-hips',
      name: 'Hips',
    },
    {
      show: false,
      icon_code: 'locations-hip-left',
      name: 'Hip (Left)',
    },
    {
      show: false,
      icon_code: 'locations-hip-right',
      name: 'Hip (Right)',
    },
    {
      show: false,
      icon_code: 'locations-joints',
      name: 'Joints',
    },
    {
      show: false,
      icon_code: 'locations-knees',
      name: 'Knees',
    },
    {
      show: false,
      icon_code: 'locations-knee-left',
      name: 'Knee (Left)',
    },
    {
      show: false,
      icon_code: 'locations-knee-right',
      name: 'Knee (Right)',
    },
    {
      show: false,
      icon_code: 'locations-legs',
      name: 'Legs',
    },
    {
      show: false,
      icon_code: 'locations-leg-left',
      name: 'Leg (Left)',
    },
    {
      show: false,
      icon_code: 'locations-leg-right',
      name: 'Leg (Right)',
    },
    {
      show: true,
      icon_code: 'locations-neck',
      name: 'Neck',
    },
    {
      show: false,
      icon_code: 'locations-shoulders',
      name: 'Shoulders',
    },
    {
      show: false,
      icon_code: 'locations-shoulder-left',
      name: 'Shoulder (Left)',
    },
    {
      show: false,
      icon_code: 'locations-shoulder-right',
      name: 'Shoulder (Right)',
    },
    {
      show: false,
      icon_code: 'locations-arms',
      name: 'Arms',
    },
    {
      show: false,
      icon_code: 'locations-arm-left',
      name: 'Arm (Left)',
    },
    {
      show: false,
      icon_code: 'locations-arm-right',
      name: 'Arm (Right)',
    },
    {
      show: false,
      icon_code: 'locations-hands',
      name: 'Hands',
    },
    {
      show: false,
      icon_code: 'locations-hand-left',
      name: 'Hand (Left)',
    },
    {
      show: false,
      icon_code: 'locations-hand-right',
      name: 'Hand (Right)',
    },
  ],
  symptom: [
    {
      show: true,
      icon_code: 'none',
      name: 'Nothing',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-anxiety',
      name: 'Anxiety',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-bleeding',
      name: 'Bleeding',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-constipation',
      name: 'Constipation',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-dehydration',
      name: 'Dehydration',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-depression',
      name: 'Depression',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-diarrhea',
      name: 'Diarrhea',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-dizziness',
      name: 'Dizziness',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-drowziness',
      name: 'Drowsiness',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-fainting',
      name: 'Fainting',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-fever',
      name: 'Fever',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-hallucination',
      name: 'Hallucination',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-insomnia',
      name: 'Insomnia',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-irregular-breathing',
      name: 'Irregular Breathing',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-irregular-pulse',
      name: 'Irregular Pulse',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-itchiness',
      name: 'Itchiness',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-lethargy',
      name: 'Lethargy',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-mental-fogginess',
      name: 'Mental Fogginess',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-nausea',
      name: 'Nausea',
    },
    {
      show: false,
      icon_code: 'other-associated-symptoms-unconciousness',
      name: 'Unconsciousness',
    },
    {
      show: true,
      icon_code: 'other-associated-symptoms-vomiting',
      name: 'Vomiting',
    },
  ],
  character: [
    {
      show: true,
      icon_code: 'characteristics-aching',
      name: 'Aching',
    },
    {
      show: true,
      icon_code: 'characteristics-burning',
      name: 'Burning',
    },
    {
      show: false,
      icon_code: 'characteristics-cold',
      name: 'Cold',
    },
    {
      show: false,
      icon_code: 'characteristics-cramping',
      name: 'Cramping',
    },
    {
      show: true,
      icon_code: 'characteristics-dull',
      name: 'Dull',
    },
    {
      show: true,
      icon_code: 'characteristics-electric-shocks',
      name: 'Electric Shocks',
    },
    {
      show: false,
      icon_code: 'characteristics-freezing',
      name: 'Freezing',
    },
    {
      show: false,
      icon_code: 'characteristics-heavy',
      name: 'Heavy',
    },
    {
      show: false,
      icon_code: 'characteristics-hot',
      name: 'Hot',
    },
    {
      show: false,
      icon_code: 'characteristics-numbness',
      name: 'Numbness',
    },
    {
      show: false,
      icon_code: 'characteristics-pinching',
      name: 'Pinching',
    },
    {
      show: true,
      icon_code: 'characteristics-pins-needles',
      name: 'Pins and Needles',
    },
    {
      show: false,
      icon_code: 'characteristics-pounding',
      name: 'Pounding',
    },
    {
      show: false,
      icon_code: 'characteristics-pressure',
      name: 'Pressure',
    },
    {
      show: true,
      icon_code: 'characteristics-sharp',
      name: 'Sharp',
    },
    {
      show: false,
      icon_code: 'characteristics-shooting',
      name: 'Shooting',
    },
    {
      show: false,
      icon_code: 'characteristics-stabbing',
      name: 'Stabbing',
    },
    {
      show: false,
      icon_code: 'characteristics-stiff',
      name: 'Stiff',
    },
    {
      show: true,
      icon_code: 'characteristics-throbbing',
      name: 'Throbbing',
    },
    {
      show: false,
      icon_code: 'characteristics-tingling',
      name: 'Tingling',
    },
  ],
  aggravating_factor: [
    {
      show: true,
      icon_code: 'none',
      name: 'Nothing',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-bowel-movement',
      name: 'Bowel Movement',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-exercise',
      name: 'Exercise',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-food',
      name: 'Food',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-inactivity',
      name: 'Inactivity',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-lack-of-sleep',
      name: 'Lack of Sleep',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-light-touch-or-clothing',
      name: 'Light Touch / Clothing',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-negative-mood',
      name: 'Negative mood',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-sitting',
      name: 'Sitting',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-sleep',
      name: 'Sleep',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-smoking',
      name: 'Smoking',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-standing',
      name: 'Standing',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-stress',
      name: 'Stress',
    },
    {
      show: false,
      icon_code: 'aggravating-factors-urination',
      name: 'Urination',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-walking',
      name: 'Walking',
    },
    {
      show: true,
      icon_code: 'aggravating-factors-weather',
      name: 'Weather',
    },
  ],
  alleviating_factor: [
    {
      show: true,
      icon_code: 'none',
      name: 'Nothing',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-bowel-movement',
      name: 'Bowel Movement',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-distraction',
      name: 'Distraction',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-exercise',
      name: 'Exercise',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-food',
      name: 'Food',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-heat',
      name: 'Heat',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-ice',
      name: 'Ice',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-inactivity',
      name: 'Inactivity',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-massage',
      name: 'Massage',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-repositioning',
      name: 'Repositioning',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-rest',
      name: 'Rest',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-sleep',
      name: 'Sleep',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-stretching',
      name: 'Stretching',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-talking-to-someone',
      name: 'Talking to Someone',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-urination',
      name: 'Urination',
    },
  ],
  ineffective_factor: [
    {
      show: true,
      icon_code: 'none',
      name: 'Nothing',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-bowel-movement',
      name: 'Bowel Movement',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-distraction',
      name: 'Distraction',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-exercise',
      name: 'Exercise',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-food',
      name: 'Food',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-heat',
      name: 'Heat',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-ice',
      name: 'Ice',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-inactivity',
      name: 'Inactivity',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-massage',
      name: 'Massage',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-repositioning',
      name: 'Repositioning',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-rest',
      name: 'Rest',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-sleep',
      name: 'Sleep',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-stretching',
      name: 'Stretching',
    },
    {
      show: true,
      icon_code: 'alleviating-factors-talking-to-someone',
      name: 'Talking to Someone',
    },
    {
      show: false,
      icon_code: 'alleviating-factors-urination',
      name: 'Urination',
    },
  ],
  environment: [
    {
      show: true,
      icon_code: 'environment-bed',
      name: 'Bed',
    },
    {
      show: false,
      icon_code: 'environment-car',
      name: 'Car',
    },
    {
      show: true,
      icon_code: 'environment-home',
      name: 'Home',
    },
    {
      show: false,
      icon_code: 'environment-outdoors',
      name: 'Outdoors',
    },
    {
      show: false,
      icon_code: 'environment-school',
      name: 'School',
    },
    {
      show: true,
      icon_code: 'environment-shopping',
      name: 'Shopping',
    },
    {
      show: true,
      icon_code: 'environment-work',
      name: 'Work',
    },
  ],
  meaningful_activities: [
    {
      show: true,
      icon_code: 'meaningful-activities-household-chores',
      name: 'Household chores',
    },
    {
      show: true,
      icon_code: 'meaningful-activities-exercised',
      name: 'Exercised',
    },
    {
      show: true,
      icon_code: 'meaningful-activities-errands-outside-the-home',
      name: 'Errands outside the home',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-bathed-showered',
      name: 'Bathed / Showered',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-prepared-a-meal',
      name: 'Prepared a meal',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-enjoyed-the-outdoors',
      name: 'Enjoyed the outdoors',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-got-in-and-out-of-bed-on-my-own',
      name: 'Got in and out of bed on my own',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-slept-restfully',
      name: 'Slept restfully',
    },
    {
      show: true,
      icon_code: 'meaningful-activities-spent-time-with-friends-or-family',
      name: 'Spent time with friends or family',
    },
    {
      show: true,
      icon_code: 'meaningful-activities-did-an-activity-that-i-enjoyed',
      name: 'Did an activity that I enjoyed',
    },
    {
      show: true,
      icon_code:
        'meaningful-activities-connected-with-supportive-people-online',
      name: 'Connected with supportive people online or through text',
    },
    {
      show: false,
      icon_code:
        'meaningful-activities-attended-a-community-event-or-support-group',
      name: 'Attended a community event / support group',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-worked-studied-volunteered',
      name: 'Worked / studied / volunteered',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-connected-with-spirituality',
      name: 'Connected with my spirituality',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-learned-something-new',
      name: 'Learned something new',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-rest-and-relaxation',
      name: 'Rest and relaxation',
    },
    {
      show: false,
      icon_code: 'meaningful-activities-time-with-pets',
      name: 'Spent time with my pet(s)',
    },
  ],
};

export default defaultFields;
