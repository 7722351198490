import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import Modal from '@mui/material/Modal';
// import ModalWeb from 'modal-enhanced-react-native-web';
import PropTypes from 'prop-types';
import { designConstants } from '../styles';

export default class ModalWrapper extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      androidBarColorWhileModalIsOpened,
      androidInitialBarColor,
      androidInitialBarStyle,
      isVisible,
      currentScene,
      closeModal,
    } = this.props;

    if (nextProps.currentScene !== currentScene && isVisible) {
      closeModal && closeModal();
    }
  }

  render() {
    const { children, closeModal, isVisible, ...otherProps } = this.props;
    return (
      <Modal
        open={isVisible}
        onClose={closeModal}
        {...otherProps}
        style={modalWrapperStyles.modal}
      >
        <View style={modalWrapperStyles.contentWrapper}>{children}</View>
      </Modal>
    );
  }
}

const modalWrapperStyles = StyleSheet.create({
  modal: {
    margin: 0,
    bottom: 0,
    marginTop: designConstants.windowHeight * 0.1, //Set max height to 80% of screen
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  backdropInsideModal: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  contentWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'green',
    justifyContent: 'flex-end',
  },
});

ModalWrapper.propTypes = {
  // Modal props
  isVisible: PropTypes.bool,
  backdropColor: PropTypes.string,
  backdropOpacity: PropTypes.number,
  avoidKeyboard: PropTypes.bool,
  onModalHide: PropTypes.func,
  // CurrentScene have to be changed properly with taking into account the render specification
  // One of the ways is to pass the currentScene from redux state - any changes of
  // currentScene in state will trigger re-rendering and update nested child's props
  currentScene: PropTypes.string,

  // ModalWrapper props
  closeModal: PropTypes.func,
  androidInitialBarColor: PropTypes.string,
  androidBarColorWhileModalIsOpened: PropTypes.string,
  androidInitialBarStyle: PropTypes.oneOf([
    'default',
    'light-content',
    'dark-content',
  ]),
};
ModalWrapper.defaultProps = {
  // Modal props
  isVisible: false,
  backdropColor: '#000',
  backdropOpacity: designConstants.backgroundOverlayOpacity,
  avoidKeyboard: true,
  onModalHide: () => null,
  currentScene: null,

  // ModalWrapper props
  closeModal: () => null,
  androidInitialBarColor: '#0C74AD',
  androidBarColorWhileModalIsOpened: null,
  androidInitialBarStyle: 'default',
};
