import * as types from './actiontypes';
import {
  ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION,
  UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH,
  UPDATE_UNSYNC_MEDICATION_BRAND,
  UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS,
  UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH,
} from './actiontypes';
import {
  addUnsyncMedicationsArrayToProfile,
  addUnsyncMedicationToProfile,
  addUnsyncPainConditionsArrayToProfile,
  addUnsyncPainConditionToProfile,
  deleteUnsyncMedicationFromProfile,
  editUnsyncMedicationInProfile,
} from '../Profile/actions';
import {
  addOfflineMedicationsArrayToFields,
  addOfflineMedicationToFields,
  deleteOfflineMedicationFromFields,
  editOfflineMedicationInFields,
} from '../Fields/actions';
import { setOfflineIdFidNid } from '../Meds/actions';
import { deleteOfflineMedicationFromRecords } from '../Records/actions';
import { trackTutorialComplete } from '../../shared/utilities/answers';
import getConfig from '../../shared/utilities/getConfig';

export function appOpen() {
  return {
    type: types.APP_OPEN,
    shouldFilter: true,
    applyDateRange: true,
  };
}

export function introViewed() {
  return (dispatch, getState) => {
    dispatch({
      type: types.APP_UPDATE_SETTINGS,
      payload: {
        introViewed: true,
      },
    });

    const { current_id, current_fid, current_nid } = getState().intro;

    dispatch(
      setOfflineIdFidNid({
        id: current_id,
        fid: current_fid,
        nid: current_nid,
      }),
    );
    return Promise.resolve();
  };
}

export function eulaAccepted() {
  const eulaVersion = parseInt(getConfig('MMP_EULA_VERSION'));

  return (dispatch) => {
    dispatch({
      type: types.APP_UPDATE_SETTINGS,
      payload: {
        eulaAccepted: eulaVersion,
      },
    });
    return Promise.resolve();
  };
}

export function greetingViewed() {
  return {
    type: types.APP_UPDATE_SETTINGS,
    payload: {
      greetingViewed: true,
    },
  };
}

export function notificationsViewed() {
  return {
    type: types.APP_UPDATE_SETTINGS,
    payload: {
      notificationsViewed: true,
    },
  };
}

export function wizardFirstStepViewed() {
  return {
    type: types.APP_UPDATE_SETTINGS,
    payload: {
      wizardFirstStepViewed: true,
    },
  };
}

export function wizardSecondStepGoBack() {
  return {
    type: types.APP_UPDATE_SETTINGS,
    payload: {
      wizardFirstStepViewed: false,
    },
  };
}

export function wizardSecondStepViewed() {
  return {
    type: types.APP_UPDATE_SETTINGS,
    payload: {
      wizardSecondStepViewed: true,
    },
  };
}

export function wizardViewed() {
  return (dispatch, getState) => {
    trackTutorialComplete('pass');
    dispatch({
      type: types.APP_UPDATE_SETTINGS,
      payload: {
        wizardViewed: true,
      },
    });

    const { painConditions, medications } = getState().intro;

    let painConditionsProfile = painConditions
      .filter((condition) => condition.checked)
      .map((condition) => {
        // Deleting checked and display_value from target object via spread syntax
        const { checked, display_value, ...new_condition } = condition;
        return { ...new_condition };
      });

    let _medicationsProfile = medications
      .filter((medication) => medication.checked)
      .map((medication) => {
        // Deleting checked and display_value from target object via spread syntax
        const { checked, display_value, ...new_medication } = medication;
        return { ...new_medication };
      });

    let medicationsProfile = [];
    let medicationsFields = [];
    _medicationsProfile.forEach((medication) => {
      const selected_strength = medication.added_strengths.filter(
        (strength) => !!strength.selected,
      );
      if (selected_strength.length === 0) {
        // Deleting added_strengths from target object via spread syntax
        const {
          added_strengths,
          default_brands,
          add_moment,
          strength_units_in_order,
          dosage_units_in_order,
          ...new_medication
        } = medication;
        !new_medication.brand_name && (new_medication.brand_name = 'Generic');
        !new_medication.dosage_units && (new_medication.dosage_units = 'units');

        new_medication.display_value =
          new_medication.brand_name === 'Generic'
            ? new_medication.common_name
            : new_medication.brand_name;

        medicationsProfile.push(new_medication);
        medicationsFields.push({ ...new_medication });
      }
      selected_strength.forEach((dose) => {
        // Deleting added_strengths from target object via spread syntax
        const {
          added_strengths,
          default_brands,
          add_moment,
          strength_units_in_order,
          dosage_units_in_order,
          ...new_medication
        } = medication;
        !new_medication.brand_name && (new_medication.brand_name = 'Generic');
        !new_medication.dosage_units && (new_medication.dosage_units = 'units');
        new_medication.strength_units = dose.strength_units;
        new_medication.strength = dose.strength;
        new_medication.id = dose.ids.id;
        new_medication.fid = dose.ids.fid;
        new_medication.nid = dose.ids.nid;

        const strength_in_display_value = new_medication.strength
          ? ' ' +
            new_medication.strength +
            (new_medication.strength_units || 'mg')
          : '';

        new_medication.display_value =
          new_medication.brand_name === 'Generic'
            ? new_medication.common_name + strength_in_display_value
            : new_medication.brand_name + strength_in_display_value;

        medicationsProfile.push(new_medication);
        medicationsFields.push({ ...new_medication });
      });
    });

    // let medicationsFields = medications
    //     .filter(medication => medication.checked)
    //     .map(medication => {
    //         // Deleting checked and display_value from target object via spread syntax
    //         const {checked, display_value, ...new_medication} = medication;
    //         return new_medication;
    //     });

    console.log('meds profile', medicationsProfile);
    let unique = [];

    medicationsProfile = medicationsProfile.forEach((left) => {
      if (
        !unique.some(
          (right) =>
            left.brand_name === right.brand_name &&
            left.common_name === right.common_name &&
            left.dosage_units === right.dosage_units &&
            left.strength === right.strength &&
            left.strength_units === right.strength_units,
        )
      ) {
        unique.push(left);
      }
    });
    medicationsProfile = unique;
    dispatch(addUnsyncPainConditionsArrayToProfile(painConditionsProfile));
    dispatch(addUnsyncMedicationsArrayToProfile(medicationsProfile));
    dispatch(addOfflineMedicationsArrayToFields(medicationsFields));

    dispatch(cleanIntroEntries());

    return Promise.resolve();
  };
}

export function hideUpgrade() {
  return (dispatch) => {
    dispatch({
      type: types.APP_UPDATE_SETTINGS,
      payload: {
        hideUpgrade: true,
      },
    });
    return Promise.resolve();
  };
}

export function addUnsyncPainCondition(value) {
  return {
    type: types.ADD_UNSYNC_PAIN_CONDITION,
    payload: value,
  };
}

export function addUnsyncPainConditionToApp(value) {
  return (dispatch, getState) => {
    dispatch({
      type: types.ADD_UNSYNC_PAIN_CONDITION,
      payload: value,
    });
    // Dispatching for Profile reducer, cause it is needed to update id, fid, nid counters for intro reducer and
    // then update Profile state
    const painConditions = getState().intro.painConditions;
    const { display_value, checked, ...new_pain_condition } =
      painConditions[painConditions.length - 1];
    dispatch(addUnsyncPainConditionToProfile(new_pain_condition));
    dispatch(cleanIntroEntries());
  };
}

export function updateUnsyncPainCondition(fid) {
  return {
    type: types.UPDATE_UNSYNC_PAIN_CONDITION,
    payload: fid,
  };
}

export function addUnsyncMedication(med) {
  return {
    type: types.ADD_UNSYNC_MEDICATION,
    payload: med,
  };
}

export function addUnsyncMedicationToApp(med) {
  return (dispatch) => {
    dispatch(addUnsyncMedicationToProfile(med));
    dispatch(addOfflineMedicationToFields(med));
  };
}

export function editUnsyncMedicationInApp(medication) {
  return (dispatch) => {
    dispatch(editOfflineMedicationInFields(medication));
    dispatch(editUnsyncMedicationInProfile(medication));
  };
}

export function deleteUnsyncMedicationFromApp(nid) {
  return (dispatch) => {
    dispatch(deleteOfflineMedicationFromFields(nid));
    dispatch(deleteUnsyncMedicationFromProfile(nid));
    dispatch(deleteOfflineMedicationFromRecords(nid));
  };
}

// export function addUnsyncMedicationToTemporaryMedicationInfo({med, scene_key_to_return_after_save}) {
//     return (dispatch, getState) => {
//         // Needed for updating unsync id, fid, nid
//         dispatch({
//             type: types.ADD_UNSYNC_MEDICATION,
//             payload: med
//         });
//         const medications = getState().intro.medications;
//         // Deleting checked and display_value from target object via spread syntax
//         const {
//             checked,
//             display_value,
//             added_strengths,
//             strength_units_in_order,
//             dosage_units_in_order,
//             ...new_medication
//         } = medications[medications.length - 1];
//         dispatch(addTemporaryMedicationInfo({
//             data: new_medication,
//             type_of_action: 'add',
//             scene_key_to_return_after_save
//         }));
//         dispatch(cleanIntroEntries());
//     };
// }

export function updateMedicationDosageUnits(id, dosage_units) {
  return {
    type: UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS,
    payload: { id, dosage_units },
  };
}

export function updateMeditationBrandIntro(nid, brand_name) {
  return {
    type: UPDATE_UNSYNC_MEDICATION_BRAND,
    payload: { nid, brand_name },
  };
}

export function addMedicationStrength(id, strength) {
  return {
    type: UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH,
    payload: { id, strength },
  };
}

export function selectMedicationStrength(id, strength_id) {
  return {
    type: UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH,
    payload: { id, strength_id },
  };
}

export function addBrandToMedicationIntro(nid, brand_name) {
  return {
    type: ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION,
    payload: { nid, brand_name },
  };
}

export function clearUnsyncMedications() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_UNSYNC_MEDICATION,
    });
    return Promise.resolve();
  };
}
export function updateUnsyncMedication(id) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_UNSYNC_MEDICATION,
      payload: id,
    });
    return Promise.resolve();
  };
}

export function loginFromIntro() {
  trackTutorialComplete('login');
  return {
    type: types.LOGIN_FROM_INTRO,
  };
}

export function cleanIntroEntries() {
  return {
    type: types.CLEAN_INTRO_ENTRIES,
  };
}
