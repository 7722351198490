import React, { Component } from 'react';
import { Animated, Easing, View } from 'react-native';
import PropTypes from 'prop-types';
import Collapsible from 'react-native-collapsible';

export default class SimpleCollapsible extends Component {
  height;
  animation;
  state = { animating: false };

  constructor(props) {
    super(props);
    this.zeroHeight = 0.000001;
    this.height = this.zeroHeight;
    this.animation = new Animated.Value(this.zeroHeight);
  }

  UNSAFE_componentWillMount() {
    const { collapsed } = this.props;
    this.animate(collapsed ? this.zeroHeight : this.height);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { collapsed } = this.props;
    collapsed !== nextProps.collapsed &&
      this.animate(nextProps.collapsed ? this.zeroHeight : this.height);
  }

  setMaxHeight = (event) => {
    const height = event.nativeEvent.layout.height;
    const { collapsed } = this.props;
    const { animating } = this.state;
    height > 0 && (this.height = height);

    if (!collapsed && !animating) {
      //this.animation.setValue(height);
      this.setState({ animating: true }, this.animateToFullHeight);
    }
  };

  animate = (toValue) => {
    const { animationVelocityPixelsPerSecond } = this.props;
    const knownHeight = this.height;
    this.setState({ animating: true });

    const calculatedDuration =
      (1000 * knownHeight) / animationVelocityPixelsPerSecond;

    Animated.timing(this.animation, {
      duration: calculatedDuration,
      toValue,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start(() => {
      const { collapsed } = this.props;

      if (!collapsed && knownHeight !== this.height) {
        this.animateToFullHeight();
      } else {
        this.setState({ animating: false });
      }
    });
  };

  animateToFullHeight = () => {
    const { animationVelocityPixelsPerSecond } = this.props;
    const knownHeight = this.height;

    const calculatedDuration =
      (1000 * Math.abs(this.animation._value - knownHeight)) /
      animationVelocityPixelsPerSecond;

    Animated.timing(this.animation, {
      duration: calculatedDuration,
      easing: Easing.linear,
      toValue: knownHeight,
      useNativeDriver: false,
    }).start(() => {
      if (knownHeight !== this.height) {
        this.animateToFullHeight();
      } else {
        this.setState({ animating: false });
      }
    });
  };

  render() {
    const { children, itemsToShowCollapsed, collapsed } = this.props;
    const { animating } = this.state;

    return (
      <View pointerEvents={animating ? 'none' : 'auto'}>
        <View>
          {Array.isArray(children) &&
            children.slice(0, itemsToShowCollapsed).map((item, index) =>
              React.cloneElement(item, {
                ...item.props,
                key: 'key_' + index,
              }),
            )}
        </View>
        <Collapsible collapsed={collapsed}>
          <View>
            {Array.isArray(children)
              ? children.slice(itemsToShowCollapsed).map((item, index) =>
                  React.cloneElement(item, {
                    ...item.props,
                    key: 'key_' + index,
                  }),
                )
              : children}
          </View>
        </Collapsible>
      </View>
    );
  }
}

SimpleCollapsible.propTypes = {
  itemsToShowCollapsed: PropTypes.number,
  collapsed: PropTypes.bool,
  animationVelocityPixelsPerSecond: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

SimpleCollapsible.defaultProps = {
  itemsToShowCollapsed: 0,
  collapsed: true,
  animationVelocityPixelsPerSecond: 1100,
  children: null,
};
