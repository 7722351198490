// @flow
import authStrings from '../../modules/Auth/strings';

export function getLang(): string {
  let lang = authStrings.getLanguage();
  if (lang === 'zh-rCN') {
    return 'zh-hans';
  }
  return lang;
}
