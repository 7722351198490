import React, { Component } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Collapsible from 'react-native-collapsible';

export default class CollapsibleWrapper extends Component {
  render() {
    const {
      children,
      itemsToShowCollapsed,
      collapsed,
      duration,
      easing,
      onAnimationEnd,
    } = this.props;

    if (
      !children ||
      !Array.isArray(children) ||
      children.length <= itemsToShowCollapsed
    ) {
      return null;
    }

    return (
      <View>
        <View>
          {Array.isArray(children) &&
            children.slice(0, itemsToShowCollapsed).map((item, index) =>
              React.cloneElement(item, {
                ...item.props,
                key: 'key_' + index,
              }),
            )}
        </View>
        <Collapsible
          collapsed={collapsed}
          duration={duration}
          easing={easing}
          onAnimationEnd={onAnimationEnd}
        >
          {children
            .slice(itemsToShowCollapsed)
            .map((item, index) =>
              React.cloneElement(item, { ...item.props, key: 'key_' + index }),
            )}
        </Collapsible>
      </View>
    );
  }
}

CollapsibleWrapper.propTypes = {
  itemsToShowCollapsed: PropTypes.number,
  collapsed: PropTypes.bool,
  id: PropTypes.string,

  duration: PropTypes.number,
  easing: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onAnimationEnd: PropTypes.func,
  children: PropTypes.node,
};

CollapsibleWrapper.defaultProps = {
  itemsToShowCollapsed: 0,
  collapsed: true,
  id: null,

  duration: 300,
  easing: 'easeOutCubic',
  children: null,
  onAnimationEnd: () => null,
};
