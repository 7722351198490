import { getStoredState } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

//Workaround for error https://redmine.managinglife.com/issues/6265 - we should upgrade from "AsyncStorage" to "Filesystem Storage" on android
//So we try to get reducer from "filesystem-storage", and if it's empty, then we should get state from "Async" storage instead to get old data

export default async (config) => {
  return getStoredState(config).catch((err) => {
    return getStoredState({ ...config, storage: AsyncStorage });
  });
};
