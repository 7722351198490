// This file serves every action which could be performed by both Views and Logic
export const CHECK_TOKEN = 'CHECK_TOKEN';

//Api types
export const API_ERROR = 'API_ERROR';

//Auth types
export const LOGOUT = 'LOGOUT';

//Profile types
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';

//Sync types
export const SYNC = 'SYNC';
export const SYNC_SUCCESS = 'SYNC_SUCCESS';
export const SYNC_SUCCESS_CLOSE = 'SYNC_SUCCESS_CLOSE';
export const SYNC_CUSTOM_LISTS = 'SYNC_CUSTOM_LISTS';
export const SYNC_OFFLINE_MEDICATIONS = 'SYNC_OFFLINE_MEDICATIONS';
export const SYNC_CUSTOM_LISTS_SUCCESS = 'SYNC_CUSTOM_LISTS_SUCCESS';
export const SYNC_CUSTOM_LISTS_CLEAR_FLAGS = 'SYNC_CUSTOM_LISTS_CLEAR_FLAGS';
export const SYNC_RECORDS_SUCCESS = 'SYNC_RECORDS_SUCCESS';
export const SYNC_RECORDS_CLEAR_FLAGS = 'SYNC_RECORDS_CLEAR_FLAGS';
export const SYNC_SYNC_IN_PROCESS_FLAG_ON = 'SYNC_SYNC_IN_PROCESS_FLAG_ON';
export const SYNC_SYNC_IN_PROCESS_FLAG_OFF = 'SYNC_SYNC_IN_PROCESS_FLAG_OFF';
