import {
  COMPLETE_PURCHASE,
  COMPLETE_PURCHASE_SUCCESS,
  PURCHASES_RESTORED,
  TOGGLE_PRO,
  TOGGLE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_MANAGED,
} from './actiontypes';
import * as types from '../Auth/actiontypes';
import DeviceInfo from 'react-native-device-info';
import getConfig from '../../shared/utilities/getConfig';
import * as profileTypes from '../Profile/actiontypes';

export const PRO_PRODUCT_ID = getConfig('MMP_PRODUCT_ID_PRO');
export const SUBSCRIBE_PRODUCT_ID = getConfig('MMP_PRODUCT_ID_SUBSCRIPTION');
export const SUBSCRIBE_ANNUAL_PRODUCT_ID = getConfig(
  'MMP_PRODUCT_ID_SUBSCRIPTION_ANNUAL',
);

const IS_ANDROID_PRO = global.__REMOTEDEV__
  ? false
  : DeviceInfo.getBundleId().startsWith('com.lcs.mmp.full');

const initialState = {
  receipts: [],
  is_pro: IS_ANDROID_PRO,
  is_pro_managed: IS_ANDROID_PRO,
};

export default function IapReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_MANAGED:
      return {
        ...state,
        is_subscription_managed: action.payload.is_subscription_managed,
        active_subscription: action.payload.active_subscription,
      };
    case COMPLETE_PURCHASE:
      //When we processed purchase successfuly on server, we can remove it locally
      state = { ...state };
      if (!state.receipts) {
        state.receipts = [];
      }
      state.receipts = state.receipts.filter(
        (product) => product.productId !== action.payload.productId,
      );
      state.receipts.push(action.payload);

      if (action.payload.productId === PRO_PRODUCT_ID) {
        state.is_pro_managed = true;
      }
      if (
        action.payload.productId === SUBSCRIBE_PRODUCT_ID ||
        action.payload.productId === SUBSCRIBE_ANNUAL_PRODUCT_ID
      ) {
        state.is_subscription_managed = true;
        state.active_subscription = action.payload.productId;
      }
      return state;
    case COMPLETE_PURCHASE_SUCCESS:
      if (action.payload.is_subscription) {
        return {
          ...state,
          is_subscription: true,
          is_subscription_managed: true,
          is_subscription_expired: false,
        };
      }
      return state;
    case types.LOGIN_SUCCESS:
      // __proto__ in payload leads to app failure
      const { __proto__, ...user } = action.payload;
      const login_pro =
        state.is_pro ||
        state.is_pro_managed ||
        state.is_subscription_managed ||
        action.payload.enable_pro == '1' ||
        action.payload.institution_benefits === 'pro';
      const login_local_subscription =
        state.is_subscription_managed && !state.is_subscription_expired;
      const login_subscribe =
        state.is_subscription ||
        login_local_subscription ||
        action.payload.is_subscriber == '1' ||
        action.payload.institution_benefits === 'subscriber';
      return {
        ...state,
        user: user,
        is_pro: login_pro || IS_ANDROID_PRO,
        is_subscription: login_subscribe,
        is_institution:
          state.is_institution || action.payload.is_institution_member == '1',
        is_pro_managed: state.is_pro_managed || IS_ANDROID_PRO,
        is_subscription_managed: state.is_subscription_managed || false,
        server_subscription: action.payload.is_subscriber == '1',
        subscription_market: action.payload.subscription_market,
        institution_benefits: action.payload.institution_benefits,
      };
    case types.SET_USER: {
      // Excluding fields which should not be in user object
      const { is_pro, is_subscription, is_institution, ..._payload } =
        action.payload;
      const local_subscription =
        state.is_subscription_managed && !state.is_subscription_expired;
      const pro =
        state.is_pro_managed ||
        state.is_subscription_managed ||
        action.payload.enable_pro == '1' ||
        action.payload.institution_benefits === 'pro';
      const subscribe =
        local_subscription ||
        action.payload.is_subscriber == '1' ||
        action.payload.institution_benefits === 'subscriber';
      return {
        ...state,
        is_pro: pro || IS_ANDROID_PRO,
        is_subscription: subscribe,
        is_institution:
          state.is_institution || action.payload.is_institution_member == '1',
        is_pro_managed: state.is_pro_managed || IS_ANDROID_PRO,
        is_subscription_expired:
          state.is_subscription_expired && !action.payload.is_subscriber,
        is_subscription_managed: state.is_subscription_managed || false,
        server_subscription: action.payload.is_subscriber == '1',
        subscription_market: action.payload.subscription_market,
        institution_benefits: action.payload.institution_benefits,
      };
    }
    case TOGGLE_PRO:
      return {
        ...state,
        is_pro: action.payload.value,
        is_pro_managed: action.payload.managed || IS_ANDROID_PRO,
      };
    case TOGGLE_SUBSCRIPTION:
      return {
        ...state,
        is_subscription: action.payload.value,
        is_subscription_managed: action.payload.managed || false,
        is_subscription_expired: action.payload.is_expired,
      };
    case PURCHASES_RESTORED:
      return {
        ...state,
        purchases_restored: true,
      };
    case profileTypes.ACCOUNT_DELETED:
    case types.SYNC_FAILED_LOGOUT:
    case types.LOGOUT:
      return {
        ...state,
        is_pro: state.is_pro_managed || IS_ANDROID_PRO,
        is_subscription:
          state.is_subscription_managed && !state.is_subscription_expired,
        is_institution: false,
        institution_benefits: null,
        user: null,
      };
    default:
      return state;
  }
}

export { IapReducer };
