import { capitalize } from '../../shared/utilities/stringsutils';

import * as MMPApi from 'apiclient';
import * as types from './actiontypes';
import { apiError, checkToken } from '../Api/actions';
import medsConstants, { medicationParser } from './constants';
import { trackGoal } from '../../shared/utilities/gatracker';
import { sync } from '../Sync/actions';
import {
  addOfflineMedication,
  addOfflinePainCondition,
  deleteOfflineMedication,
  deleteOfflinePainCondition,
  editOfflineMedication,
  editOfflinePainCondition,
  toggleShowOfflinePainCondition,
} from '../Profile/actions';
import {
  addOfflineMedicationToFields,
  deleteOfflineMedicationFromFields,
  editOfflineMedicationInFields,
} from '../Fields/actions';
import {
  deleteOfflineMedicationFromRecords,
  removeJustDeletedMedicationFromCurrentRecord,
} from '../Records/actions';
import { performFilter } from '../Filter/actions';
import strings from '../Api/strings';
import {
  trackAddMedication,
  trackAddPainCondition,
} from '../../shared/utilities/answers';
import { getLang } from '../../shared/utilities/getLang';
import { getMixtNames } from '../Records/utils';

export function checkPurpose(fid) {
  return {
    type: types.MED_CHECK_PURPOSE,
    payload: fid,
  };
}

export function setCurrentMedication(med) {
  return (dispatch) => {
    dispatch({
      type: types.MED_SET_CURRENT,
      payload: med,
    });
    return Promise.resolve();
  };
}

export function selectMedication(brand, generic) {
  return (dispatch) => {
    dispatch({
      type: types.MED_SET_CURRENT,
      payload: {
        brand_name: brand,
        common_name: generic,
      },
    });
    return Promise.resolve();
  };
}

export function setStrength(strength) {
  return {
    type: types.MED_SET_STRENGTH,
    payload: strength,
  };
}

export function setStrengthUnits(units) {
  return {
    type: types.MED_SET_STRENGTH_UNITS,
    payload: units,
  };
}

export function clearMedicationName() {
  return {
    type: types.MED_CLEAR,
  };
}

export function setStartDate(startDate) {
  return {
    type: types.MED_SET_START_DATE,
    payload: startDate,
  };
}

export function setEndDate(endDate) {
  return {
    type: types.MED_SET_END_DATE,
    payload: endDate,
  };
}

export function setTakeAs(takeAs) {
  return {
    type: types.MED_SET_TAKE_AS,
    payload: takeAs,
  };
}

export function setForm(form) {
  return {
    type: types.MED_SET_FORM,
    payload: form,
  };
}

export function setSpecialInstructions(instructions) {
  return {
    type: types.MED_SET_SPECIAL_INSTRUCTIONS,
    payload: instructions,
  };
}

export function setNote(note) {
  return {
    type: types.MED_SET_NOTE,
    payload: note,
  };
}

export function prepareNewMedication() {
  return {
    type: types.PREPARE_NEW_MEDICATION,
  };
}

export function deleteDosage(dosage) {
  return (dispatch) => {
    dispatch({
      type: types.MED_DELETE_DOSAGE,
      payload: dosage,
    });
    // if(dosage.medication_reminder) {
    //     return dispatch(deleteReminderById(dosage.reminder_id));
    // }
    return Promise.resolve();
  };
}

const days_positions = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};

export function addDosage(dosage, position) {
  dosage = { ...dosage };
  if (dosage.frequency === 'weekly') {
    dosage.frequency_value = Object.keys(dosage.repeat)
      .sort((a, b) => {
        return days_positions[a] - days_positions[b];
      })
      .toString();
  } else if (dosage.frequency === 'monthly') {
    dosage.frequency_value = dosage.day_of_month;
  }
  delete dosage.repeat;
  delete dosage.day_of_month;
  return (dispatch) => {
    dispatch({
      type: types.MED_ADD_DOSAGE,
      payload: {
        dosage: dosage,
        position: position,
      },
    });
    // if (dosage.medication_reminder) {
    //     return dispatch(editReminder(dosage, -1, true)).then(reminder => {
    //         dosage.reminder_id = reminder.id;
    //     });
    // }
    return Promise.resolve();
  };
}

export const addTemporaryPainConditionInfo = ({
  data,
  new_created,
  type_of_action,
  scene_key_to_return_after_save,
}) => {
  // data = {fid, value, first_symptom, diagnosed, preferred_language};
  return {
    type: types.ADD_TEMPORARY_PAIN_CONDITION_INFO,
    payload: {
      data: {
        fid: data.fid ? data.fid : null,
        original_fid: data.fid ? data.fid : null,
        value: data.value,
        translated_value: data.translated_value,
        first_symptom: data.first_symptom,
        diagnosed: data.diagnosed,
        preferred_language: data.preferred_language,
        isShow: data.isShow,
      },
      new_created,
      type_of_action,
      scene_key_to_return_after_save,
      was_changed: false,
      saving: false,
    },
  };
};

export const editTemporaryPainConditionInfoSubstituteFidAndValue = ({
  fid,
  value,
  translated_value,
}) => {
  return {
    type: types.EDIT_TEMPORARY_PAIN_CONDITION_INFO_SUBSTITUTE_FID_AND_VALUE,
    payload: { fid, value, translated_value },
  };
};

export const editTemporaryPainConditionInfo = ({
  first_symptom,
  diagnosed,
}) => {
  return {
    type: types.EDIT_TEMPORARY_PAIN_CONDITION_INFO,
    payload: { first_symptom, diagnosed },
  };
};

export const editTemporaryPainConditionSaving = (saving, was_changed) => {
  return {
    type: types.EDIT_TEMPORARY_PAIN_CONDITION_INFO_SAVING,
    payload: {
      saving,
      was_changed,
    },
  };
};

export const deleteTemporaryPainConditionInfo = () => {
  return {
    type: types.DELETE_TEMPORARY_PAIN_CONDITION_INFO,
  };
};

export function setOfflineIdFidNid({ id, fid, nid }) {
  return {
    type: types.SET_OFFLINE_ID_FID_NID,
    payload: { id, fid, nid },
  };
}

export function removeDeletedMeds(condition = true, medications = true) {
  return {
    type: types.REMOVE_DELETED_MEDS,
    payload: {
      condition,
      medications,
    },
  };
}

export function resetOfflineMeds() {
  return (dispatch) => {
    dispatch(setOfflineIdFidNid({ id: 1, fid: -1, nid: -1 }));
    dispatch(removeDeletedMeds());
  };
}

// This action will be catched in Profile Reducer
export function addPainConditionOfflineMeds({
  fid,
  original_fid,
  value,
  translated_value,
  first_symptom,
  diagnosed,
  preferred_language,
  isShow,
}) {
  let condition = {
    value: value,
    isShow: isShow,
    fid: fid,
    original_fid: original_fid,
    first_symptom: first_symptom,
    diagnosed: diagnosed,
    preferred_language: preferred_language,
    translated_value: translated_value,

    flag_created: true,
    flag_updated: true,
    flag_deleted: false,
  };
  return (dispatch, getState) => {
    if (!condition.fid) {
      const { offline_id, offline_fid, offline_nid } = getState().meds;
      condition.fid = offline_fid;
      condition.original_fid = null;
      dispatch(
        setOfflineIdFidNid({
          id: offline_id,
          fid: offline_fid - 1,
          nid: offline_nid,
        }),
      );
    }
    // This will be handled by Profile reducer
    dispatch(addOfflinePainCondition(condition));
    return Promise.resolve();
  };
}

export function editPainConditionOfflineMeds({
  fid,
  original_fid,
  value,
  translated_value,
  first_symptom,
  diagnosed,
  isShow,
  preferred_language,
  flag_created,
}) {
  let condition = {
    value: value,
    isShow: isShow,
    fid: fid,
    original_fid: original_fid,
    first_symptom: first_symptom,
    diagnosed: diagnosed,
    preferred_language: preferred_language,
    translated_value: translated_value,

    flag_created: !!flag_created,
    flag_updated: true,
    flag_deleted: false,
  };
  return (dispatch, getState) => {
    if (!condition.fid) {
      const { offline_id, offline_fid, offline_nid } = getState().meds;
      condition.fid = offline_fid;
      dispatch(
        setOfflineIdFidNid({
          id: offline_id,
          fid: offline_fid - 1,
          nid: offline_nid,
        }),
      );
    }
    if (original_fid !== fid) {
      const conditionToDelete = { ...condition, fid: original_fid };
      dispatch(markConditionAsDeleted(conditionToDelete));
    }

    // Toggles purpose in temporary medication, if edited pain condition was selected
    if (fid > 0 && original_fid > 0) {
      dispatch(editTemporaryMedicationPurpose(original_fid));
      dispatch(editTemporaryMedicationPurpose(fid));
    }

    dispatch(editOfflinePainCondition(condition));
    dispatch(performFilter());
    return Promise.resolve();
  };
}

export function markConditionAsDeleted(condition) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_OFFLINE_PAIN_CONDITION_MEDS,
      payload: { ...condition, flag_deleted: true },
    });
  };
}

export function toggleShowPainConditionOfflineMeds(fid) {
  return toggleShowOfflinePainCondition(fid);
}

export function deletePainConditionOfflineMeds(fid, pushToDeleted = true) {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state &&
      state.profile &&
      state.profile.content &&
      state.profile.content.condition &&
      Array.isArray(state.profile.content.condition)
    ) {
      const deletedCondition = state.profile.content.condition.find(
        (condition) => condition.fid === fid,
      );
      if (deletedCondition) {
        pushToDeleted &&
          dispatch({
            type: types.DELETE_OFFLINE_PAIN_CONDITION_MEDS,
            payload: { ...deletedCondition, flag_deleted: true },
          });
        dispatch({
          type: types.EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE,
          payload: fid,
        });
        dispatch(deleteOfflinePainCondition(fid));
      }
    }
    dispatch(performFilter());
    return Promise.resolve();
  };
}

export function addMedicationOfflineMeds(medication) {
  return (dispatch, getState) => {
    // if (!medication.nid) {
    //     const {offline_id, offline_fid, offline_nid} = getState().meds;
    //     medication.nid = offline_nid;
    //     dispatch(setOfflineIdFidNid({
    //         id: offline_id,
    //         fid: offline_fid,
    //         nid: offline_nid - 1
    //     }));
    // }
    medication.flag_created = true;
    medication.flag_updated = true;
    medication.flag_deleted = false;

    const currentMedications = getState().profile.content?.medications;
    if (
      currentMedications?.some((med) => {
        return (
          med.brand_name === medication.brand_name &&
          med.common_name === medication.common_name &&
          med.dosage_units === medication.dosage_units &&
          med.strength === medication.strength &&
          med.strength_units === medication.strength_units
        );
      })
    ) {
      return Promise.reject();
    }
    dispatch(addOfflineMedicationToFields(medication));
    dispatch(addOfflineMedication(medication));
    return Promise.resolve();
  };
}

export function editMedicationOfflineMeds(medication) {
  return (dispatch, getState) => {
    medication.flag_created = !!medication.flag_created;
    medication.flag_updated = true;
    medication.flag_deleted = false;
    const currentMedications = getState().profile.content?.medications;
    if (
      currentMedications?.some((med) => {
        if (med.id === medication.id) {
          return false;
        }
        return (
          med.brand_name === medication.brand_name &&
          med.common_name === medication.common_name &&
          med.dosage_units === medication.dosage_units &&
          med.strength === medication.strength &&
          med.strength_units === medication.strength_units
        );
      })
    ) {
      return Promise.reject();
    }

    dispatch(editOfflineMedication(medication));
    dispatch(editOfflineMedicationInFields(medication));
    dispatch(performFilter());
    return Promise.resolve();
  };
}

export function deleteMedicationOfflineMeds(nid, pushToDeleted = true) {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state &&
      state.profile &&
      state.profile.content &&
      state.profile.content.medications &&
      Array.isArray(state.profile.content.medications)
    ) {
      const deletedMedication = state.profile.content.medications.find(
        (medication) => medication.nid == nid,
      );
      if (deletedMedication) {
        pushToDeleted &&
          dispatch({
            type: types.DELETE_OFFLINE_MEDICATION_MEDS,
            payload: { ...deletedMedication, flag_deleted: true },
          });
        // This will be handled by Profile reducer
        dispatch(deleteOfflineMedication(nid));
      }
    }

    // This will be handled by Fields reducer
    dispatch(deleteOfflineMedicationFromFields(nid));

    // This will be handled by Records reducer
    dispatch(deleteOfflineMedicationFromRecords(nid));

    // This will be handled by Records reducer
    dispatch(removeJustDeletedMedicationFromCurrentRecord(nid));

    // This will be handled by Filter reducer
    dispatch(performFilter());

    return Promise.resolve();
  };
}

export function addPainCondition(
  value,
  first_symptom,
  diagnosed,
  preferred_language,
) {
  let condition = {
    value: value,
    isShow: 1,
    fid: 0,
    first_symptom: first_symptom,
    diagnosed: diagnosed,
    preferred_language: preferred_language,
  };
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.ADD_PAIN_CONDITION,
      });
      let api = new MMPApi.ConditionsApi();
      let params = MMPApi.ApiPainConditionObject.constructFromObject(condition);

      return api.syncAddConditionPost(getLang(), { condition: params }).then(
        (result) => {
          trackGoal('Add Condition');
          trackAddPainCondition();
          condition.fid = result.fid;
          dispatch({
            type: types.ADD_PAIN_CONDITION_SUCCESS,
            payload: condition,
          });
          // return Promise.resolve(result);
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.ADD_PAIN_CONDITION));
        },
      );
    });
  };
}

export function editPainCondition(
  value,
  first_symptom,
  diagnosed,
  isShow,
  fid,
  preferred_language,
) {
  let condition = {
    value: value,
    isShow: isShow,
    fid: fid,
    first_symptom: first_symptom,
    diagnosed: diagnosed,
    preferred_language: preferred_language,
  };
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.EDIT_PAIN_CONDITION,
      });
      let api = new MMPApi.ConditionsApi();
      let params = MMPApi.ApiPainConditionObject.constructFromObject(condition);

      return api.syncEditConditionPost(getLang(), { condition: params }).then(
        (result) => {
          if (result.fid) {
            condition.original_fid = condition.fid;
            condition.fid = result.fid;
          }
          dispatch({
            type: types.EDIT_PAIN_CONDITION_SUCCESS,
            payload: condition,
          });
          // return Promise.resolve(result);
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.EDIT_PAIN_CONDITION));
        },
      );
    });
  };
}

export function changeShowPainCondition(cond, isShow) {
  let condition = { ...cond, isShow: isShow };

  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.EDIT_PAIN_CONDITION,
      });
      let api = new MMPApi.ConditionsApi();
      let params = MMPApi.ApiPainConditionObject.constructFromObject(condition);

      return api.syncEditConditionPost(getLang(), { condition: params }).then(
        (result) => {
          if (result.fid) {
            condition.original_fid = condition.fid;
            condition.fid = result.fid;
          }
          dispatch({
            type: types.EDIT_PAIN_CONDITION_SUCCESS,
            payload: condition,
          });
          // return Promise.resolve(result);
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.EDIT_PAIN_CONDITION));
        },
      );
    });
  };
}

export function deletePainCondition(fid) {
  let condition = { fid: fid };
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.DELETE_PAIN_CONDITION,
      });
      let api = new MMPApi.ConditionsApi();
      let params = MMPApi.ApiPainConditionObject.constructFromObject(condition);
      return api.syncDeleteConditionPost(getLang(), { condition: params }).then(
        (result) => {
          dispatch({
            type: types.DELETE_PAIN_CONDITION_SUCCESS,
            payload: condition,
          });
          dispatch({
            type: types.EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE,
            payload: condition.fid,
          });
          return Promise.resolve(result);
        },
        (error) => {
          return dispatch(apiError(error, types.DELETE_PAIN_CONDITION));
        },
      );
    });
  };
}

export function addTemporaryMedicationFromSearch({
  med,
  scene_key_to_return_after_save,
}) {
  return (dispatch, getState) => {
    const {
      common_name,
      brand_name,
      common_name_translation,
      take_as_value,
      top_dosage_units,
      top_strength,
    } = med;

    const { strength_units_in_order, dosage_units_in_order } =
      medicationParser(med);
    const { offline_id, offline_fid, offline_nid } = getState().meds;

    dispatch(
      setOfflineIdFidNid({
        id: offline_id + 1,
        fid: offline_fid - 1,
        nid: offline_nid - 1,
      }),
    );

    const temporaryMedication = {
      id: offline_id,
      fid: offline_fid,
      nid: offline_nid,
      common_name: common_name,
      brand_name: brand_name,
      common_name_translation: common_name_translation,
      strength: null,
      take_as_value: take_as_value || null,
      purpose: [],
      strength_units: strength_units_in_order[0].value,
      dosage_units: dosage_units_in_order[0].value,
      start_date: null,
      end_date: null,
      med_notes: null,
      special_instructions: null,
      dosage: [],
      is_default: false,
      top_dosage_units,
      top_strength,
      default_brands: [],
      add_moment: Date.now(),
    };

    dispatch(
      addTemporaryMedicationInfo({
        data: temporaryMedication,
        type_of_action: 'add',
        scene_key_to_return_after_save,
      }),
    );
  };
}

export const clearTemporaryMedicationInfo = () => {
  return {
    type: types.CLEAR_TEMPORARY_MEDICATION,
  };
};
export const addTemporaryMedicationInfo = ({
  data,
  type_of_action,
  scene_key_to_return_after_save,
}) => {
  const processedData = { ...data };
  let medicationNames = [];
  let common_name = processedData.common_name.trim();
  const translation =
    data.common_name_translation ||
    data.common_name_translations?.find(
      (translation) => translation.language === getLang(),
    )?.value;
  if (common_name.indexOf('mixt') >= 0) {
    let _medicationNames =
      (translation && getMixtNames(translation)) ||
      common_name.substr(0, common_name.lastIndexOf('mixt')).split(' - ');
    _medicationNames.forEach((name) => {
      medicationNames.push(capitalize(name.trim()));
    });
  } else {
    medicationNames.push(processedData.common_name);
  }
  let strengthsArray = processedData.strength
    ? processedData.strength.split('/')
    : [null];

  let _strength = strengthsArray[0];
  medicationNames.forEach((name, index) => {
    if (index > 0) {
      _strength = _strength + '/' + strengthsArray[index];
    }
  });
  processedData.strength = _strength;

  if (medicationNames.length > strengthsArray.length) {
    strengthsArray = medicationNames.map((name, index) =>
      strengthsArray[index] ? strengthsArray[index] : null,
    );
  }

  if (medicationNames.length < strengthsArray.length) {
    strengthsArray = strengthsArray.slice(0, medicationNames.length);
  }

  strengthsArray = strengthsArray.map((strength) => {
    return !isNaN(strength) && !isNaN(parseFloat(strength))
      ? String(parseFloat(strength))
      : null;
  });

  const strengthUnits =
    processedData.strength_units ||
    medsConstants.strengthUnits[medsConstants.strengthUnits.length - 1].value;
  const dosageUnits =
    processedData.dosage_units ||
    medsConstants.medicationsDosageUnits[
      medsConstants.medicationsDosageUnits.length - 1
    ].value;

  processedData.strength_units = strengthUnits;
  processedData.dosage_units = dosageUnits;
  processedData.strengths_array = strengthsArray;
  processedData.medication_names = medicationNames;

  // Temporary thing, while backend is not set up
  // !!!DOSAGE_TEMPORARY!!!
  // processedData.dosage = [];

  return {
    type: types.ADD_TEMPORARY_MEDICATION_INFO,
    payload: {
      data: processedData,
      type_of_action,
      scene_key_to_return_after_save,
      new_created: false, // it will be false every time
      was_changed: false,
      saving: false,
    },
  };
};

export function editMedicationName() {
  return (dispatch) => {
    dispatch({
      type: types.EDIT_MEDICATION_NAME,
    });
  };
}

export function searchBrand(text, common_name) {
  return (dispatch) => {
    let api = new MMPApi.SearchApi();
    let params = MMPApi.FindMedicationBrandParams.constructFromObject({
      value: text,
      common_name: common_name,
    });

    dispatch({
      type: types.SEARCH_BRANDS_REQUEST,
    });
    if (text === null) {
      dispatch({
        type: types.SEARCH_BRANDS_SUCCESS,
        payload: [],
      });
      return Promise.resolve();
    } else {
      return api
        .coreFindMedicationBrandPostWithHttpInfo(getLang(), params)
        .then(
          (result) => {
            dispatch({
              type: types.SEARCH_BRANDS_SUCCESS,
              payload: result.data,
            });
            return Promise.resolve();
          },
          (error) => {
            let errText =
              error && error.response
                ? error.response.body || error.response.text
                : strings.error_in_data_connection;
            dispatch({
              type: types.SEARCH_BRANDS_FAILURE,
              payload: errText,
            });
            return dispatch(apiError(error, types.SEARCH_BRANDS_REQUEST));
          },
        );
    }
  };
}

export const editTemporaryMedicationSelectBrand = (brand_name) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_SELECT_BRAND,
    payload: brand_name,
  };
};

export const editTemporaryMedicationSelectMedication = (item) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.EDIT_TEMPORARY_MEDICATION_SELECT_MEDICATION,
      payload: item,
    });
    const { data, type_of_action, scene_key_to_return_after_save } =
      getState().meds.temporary_medication_info;
    dispatch(
      addTemporaryMedicationInfo({
        data,
        type_of_action,
        scene_key_to_return_after_save,
      }),
    );
    dispatch(editTemporaryMedicationSaving(false, true));
  };
};

export const editTemporaryMedicationStrength = (index, strength) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_STRENGTH,
    payload: {
      index,
      strength,
    },
  };
};

export const editTemporaryMedicationStrengthUnits = (strength_units) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_STRENGTH_UNITS,
    payload: strength_units,
  };
};

export const editTemporaryMedicationDosageUnits = (dosage_unit) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_DOSAGE_UNITS,
    payload: dosage_unit,
  };
};

export const editTemporaryMedicationPurpose = (fid) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_PURPOSE,
    payload: fid,
  };
};

export const editTemporaryMedicationStartDate = (start_date) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_START_DATE,
    payload: start_date,
  };
};

export const editTemporaryMedicationEndDate = (end_date) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_END_DATE,
    payload: end_date,
  };
};

export const editTemporaryMedicationTakeAsValue = (take_as_value) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_TAKE_AS_VALUE,
    payload: take_as_value,
  };
};

export const editTemporaryMedicationMedNotes = (med_notes) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_MED_NOTES,
    payload: med_notes,
  };
};

export const editTemporaryMedicationSpecialInstructions = (
  special_instructions,
) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_SPECIAL_INSTRUCTIONS,
    payload: special_instructions,
  };
};

export const editTemporaryMedicationAddDosage = (dosage) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_ADD_DOSAGE,
    payload: dosage,
  };
};

export const editTemporaryMedicationEditDosage = (dosage_index, dosage) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_EDIT_DOSAGE,
    payload: { dosage_index, dosage },
  };
};

export const editTemporaryMedicationDeleteDosage = (dosage_index) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_DELETE_DOSAGE,
    payload: dosage_index,
  };
};

export const editTemporaryMedicationSaving = (saving, was_changed) => {
  return {
    type: types.EDIT_TEMPORARY_MEDICATION_INFO_SAVING,
    payload: {
      saving,
      was_changed,
    },
  };
};

export const deleteTemporaryMedicationInfo = () => {
  return {
    type: types.DELETE_TEMPORARY_MEDICATION_INFO,
  };
};

export function addMedication(medication) {
  if (
    typeof medication.take_as_value === 'undefined' ||
    medication.take_as_value === ''
  ) {
    medication.take_as_value = null;
  }

  if (typeof medication.strength_units === 'undefined') {
    medication.strength_units = 'mg';
  }

  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.ADD_MEDICATION,
      });
      let api = new MMPApi.MedicationsApi();
      let params = MMPApi.ApiMedicationObject.constructFromObject(medication);

      return api.syncAddMedicationPost(getLang(), params).then(
        (result) => {
          trackGoal('Add Medication');
          trackAddMedication();
          medication.nid = result.nid;
          dispatch({
            type: types.ADD_MEDICATION_SUCCESS,
            payload: medication,
          });
          return Promise.resolve();
        },
        (error) => {
          return dispatch(apiError(error, types.ADD_MEDICATION));
        },
      );
    });
  };
}

export function editMedication(medication) {
  if (
    typeof medication.take_as_value === 'undefined' ||
    medication.take_as_value === ''
  ) {
    medication.take_as_value = null;
  }

  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.EDIT_MEDICATION,
      });
      let api = new MMPApi.MedicationsApi();
      let params = MMPApi.ApiMedicationObject.constructFromObject(medication);

      return api.syncEditMedicationPost(getLang(), params).then(
        (result) => {
          medication.nid_old = medication.nid;
          medication.nid = result.nid;
          dispatch({
            type: types.EDIT_MEDICATION_SUCCESS,
            payload: medication,
          });
          // return Promise.resolve();
          return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.EDIT_MEDICATION));
        },
      );
    });
  };
}

export function removeNetworkErrorForMedicationsBrands() {
  return {
    type: types.REMOVE_NETWORK_ERROR_FOR_MEDICATIONS_BRANDS,
  };
}

export function deleteMedication(medication) {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.DELETE_MEDICATION,
      });
      let api = new MMPApi.MedicationsApi();
      let params = MMPApi.DeleteMedicationParams.constructFromObject({
        nid: medication,
      });

      return api.syncDeleteMedicationPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.DELETE_MEDICATION_SUCCESS,
            payload: medication,
          });
          return Promise.resolve();
          // return dispatch(sync());
        },
        (error) => {
          return dispatch(apiError(error, types.DELETE_MEDICATION));
        },
      );
    });
  };
}

export function isSameCondition(left, right) {
  if (right.fid < 0 && right.fid < 0) {
    return false;
  }
  if (left.fid === right.fid) {
    return true;
  }
  return left.value === right.value;
}

export function isSameMedication(left, right) {
  if (left.nid < 0 && right.nid < 0) {
    return false;
  }
  if (left.nid == right.nid) {
    return true;
  }
  return (
    left.brand_name === right.brand_name &&
    left.common_name === right.common_name &&
    left.dosage_units === right.dosage_units &&
    left.strength === right.strength &&
    left.strength_units === right.strength_units
  );
}

export function mergeDosages(dosagesHighPriority, dosagesLowPriority) {
  if (
    !Array.isArray(dosagesHighPriority) &&
    !Array.isArray(dosagesLowPriority)
  ) {
    return [];
  }
  if (
    Array.isArray(dosagesHighPriority) &&
    !Array.isArray(dosagesLowPriority)
  ) {
    return dosagesHighPriority;
  }
  if (
    !Array.isArray(dosagesHighPriority) &&
    Array.isArray(dosagesLowPriority)
  ) {
    return dosagesLowPriority;
  }

  const dosages = [...dosagesHighPriority];

  // Since the stringify takes into account order order of keys, JSON.stringify comparison would not work correctly
  dosagesLowPriority.forEach((dosage) => {
    let result = false;
    dosages.map((_dosage) => {
      if (
        _dosage.value == dosage.value &&
        _dosage.frequency_info &&
        dosage.frequency_info &&
        _dosage.frequency_info.frequency == dosage.frequency_info.frequency &&
        _dosage.frequency_info.repeat_every ==
          dosage.frequency_info.repeat_every &&
        _dosage.frequency_info.time == dosage.frequency_info.time &&
        _dosage.frequency_info.time_start == dosage.frequency_info.time_start &&
        _dosage.frequency_info.time_end == dosage.frequency_info.time_end &&
        // Week days always have similar order
        JSON.stringify(_dosage.frequency_info.days_of_week) ==
          JSON.stringify(dosage.frequency_info.days_of_week) &&
        _dosage.frequency_info.day_of_month ==
          dosage.frequency_info.day_of_month
      ) {
        result = true;
      }
    });
    if (!result) {
      dosages.push(dosage);
    }
  });

  return dosages;
}

export function mergePurposes(purposes1, purposes2) {
  if (!Array.isArray(purposes1) && !Array.isArray(purposes2)) {
    return [];
  }
  if (Array.isArray(purposes1) && !Array.isArray(purposes2)) {
    return purposes1;
  }
  if (!Array.isArray(purposes1) && Array.isArray(purposes2)) {
    return purposes2;
  }
  const _purposes = [...purposes1, ...purposes2];
  const purposes = [];

  _purposes.forEach((purpose) => {
    if (purposes.indexOf(purpose) === -1) {
      purposes.push(purpose);
    }
  });

  return purposes;
}
