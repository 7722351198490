// @flow
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ColorSchemeName, useColorScheme } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector } from 'react-redux';
import { designConstants } from './constants';

const THEME_ASYNC_STORAGE_KEY = 'THEME_STATE';

type Props = {
  children: ReactNode,
  splash: Boolean, //When theme provider in splash screen
};

export type ThemeContextState = {
  theme: ColorSchemeName,
  systemColorScheme: ColorSchemeName,
  setTheme: React.Dispatch<React.SetStateAction<ColorSchemeName>>,
  loading: boolean,
  forceLight: boolean,
};

export const ThemeContext = React.createContext<ThemeContextState | undefined>(
  undefined,
);

export default function ThemeProvider({ children, splash }: Props) {
  const [theme, setTheme] = useState<ColorSchemeName>();
  const introViewed = splash
    ? false
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSelector((state) => !splash && state.intro.wizardSecondStepViewed);

  const loggedIn = splash
    ? false
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSelector((state) => !!state.auth?.navigate_to_main_screen);
  const web_token_outdated = splash
    ? false
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useSelector((state) => !!state.auth?.web_token_outdated);

  const [forceLight, setForceLight] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  const systemColorScheme = useColorScheme();

  // load the preference from AsyncStorage on app launch
  useEffect(() => {
    const load = async () => {
      const storedTheme = await AsyncStorage.getItem(THEME_ASYNC_STORAGE_KEY);

      setTheme(storedTheme);
      setLoading(false);
    };

    void load();
  }, []);

  // update AsyncStorage when the theme preference changes
  useEffect(() => {
    if (theme) {
      AsyncStorage.setItem(THEME_ASYNC_STORAGE_KEY, theme);
    } else {
      AsyncStorage.removeItem(THEME_ASYNC_STORAGE_KEY);
    }
  }, [theme]);

  useEffect(() => {
    setForceLight(
      !introViewed ||
        (designConstants.isWeb && (!loggedIn || web_token_outdated)),
    );
  }, [introViewed, loggedIn, web_token_outdated]);

  const contextState = useMemo(
    () => ({ loading, setTheme, theme, systemColorScheme, forceLight }),
    [theme, loading, forceLight, systemColorScheme],
  );

  if (loading) {
    return null;
  }

  return (
    <ThemeContext.Provider value={contextState}>
      {children}
    </ThemeContext.Provider>
  );
}
