import React, { useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Platform, StyleSheet, View } from 'react-native';
import { designConstants } from '../styles';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Edit from '@mui/icons-material/Edit';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { localeMoment } from '../../shared/utilities/timeutils';
import Moment from 'moment';
import { AccessTimeOutlined, InsertInvitation } from '@mui/icons-material';
import useThemeContext from '../styles/useThemeContext';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { SharedStrings } from '../../strings/strings';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    '& .MuiFilledInput-root': {
      border: `${props.disabled ? 0 : 1}px solid ${
        props.error
          ? props.colors.redDanger
          : props.grayBackground
            ? props.colors.gray
            : props.colors.liteGray
      }`,
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: props.grayBackground
        ? props.colors.defaultBackground
        : 'transparent',
      marginBottom: props.error ? -3 : 3,
      transition: theme.transitions.create(['border-color']),
      '& .MuiSelect-select:focus': {
        backgroundColor: props.grayBackground
          ? props.colors.defaultBackground
          : 'transparent',
      },
    },
    '& .MuiFilledInput-root.Mui-focused': (props) => ({
      backgroundColor: props.grayBackground
        ? props.colors.defaultBackground
        : 'transparent',
      borderColor: props.colors.buttonBlue,
    }),
    '& .MuiFilledInput-root:hover': {
      backgroundColor: props.disabled
        ? undefined
        : props.grayBackground
          ? props.colors.defaultBackground
          : 'transparent',
    },
    '& .MuiInputLabel-filled': {
      color: props.colors.gray,
    },
    '& label.Mui-focused': {
      color: props.colors.textSecondary,
    },
    '& .MuiSelect-icon': {
      fill: props.colors.buttonBlue,
    },
    '& .MuiInputLabel-outlined': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props.grayBackground
        ? props.colors.gray
        : props.colors.liteGray,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props.grayBackground
        ? props.colors.gray
        : props.colors.liteGray,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props.colors.liteGray,
      borderWidth: 1,
    },
    '& .MuiInputBase-input': {
      color: props.colors.textPrimary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiSvgIcon-root': {
      color: props.colors.textSecondary,
    },
  }),
  selectRoot: (colors) => ({
    color: colors.buttonBlue,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  }),
  menuRoot: (colors) => ({
    backgroundColor: colors.defaultBackground,
  }),
  menuItem: (colors) => ({
    color: colors.textPrimary,
    '&.Mui-selected': {
      color: colors.buttonBlue,
    },
  }),
  helperText: {
    marginLeft: 0,
    letterSpacing: 0.3,
  },
}));

export default function FloatingInput(props) {
  const { style, grayBackground, editIcon, onEditIconPress, ...otherProps } =
    props;
  const { colors, colorTheme } = useThemeContext();
  let initialValue = props.value || '';
  if (props.datePicker) {
    initialValue = props.value;
  }
  const [value, setValue] = useState(initialValue);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const newValue = props.pickerSelectedId || props.value;
    if (newValue !== value) {
      setValue(newValue);
    }
  }, [props.value, props.pickerSelectedId, value]);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorTheme,
          primary: {
            main: colors.buttonBlue,
          },
        },
      }),
    [colorTheme, colors],
  );

  let type = 'text';
  let endAdornment = null;
  const error = !!props.errorText;
  const helperText = (error ? props.errorText : props.helperText) || '';
  const classes = useStyles({
    colors,
    grayBackground,
    disabled: props.disabled,
    error,
  });

  if (props.secureTextEntry) {
    type = showPassword ? 'text' : 'password';
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          onMouseDown={(event) => event.preventDefault()}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }
  if (editIcon) {
    endAdornment = (
      <InputAdornment
        position="end"
        style={{
          marginBottom: props.multiline ? 16 : 0,
        }}
      >
        <IconButton
          aria-label="toggle password visibility"
          onClick={onEditIconPress}
          onMouseDown={(event) => event.preventDefault()}
        >
          <Edit />
        </IconButton>
      </InputAdornment>
    );
  }
  if (props.keyboardType === 'email-address') {
    type = 'email';
  }

  let onChange = props.onChangeText;

  if (props.datePicker) {
    const Element = props.datePickerMode === 'time' ? TimePicker : DatePicker;

    onChange = props.onDateChange;

    const endAdornment = (
      <InputAdornment position="end">
        <IconButton
          onMouseDown={(event) => {
            event.preventDefault();
            setDatePickerOpen(true);
          }}
        >
          {props.datePickerMode === 'time' ? (
            <AccessTimeOutlined />
          ) : (
            <InsertInvitation />
          )}
        </IconButton>
      </InputAdornment>
    );

    return (
      <ThemeProvider theme={theme}>
        <View
          style={[styles.element, style]}
          pointerEvents={props.disablePointerEvents ? 'none' : 'auto'}
        >
          <Element
            {...otherProps}
            open={datePickerOpen}
            onClose={() => setDatePickerOpen(false)}
            format={props.datePickerMode === 'time' ? 'LT' : 'LL'}
            value={!!value && value.toDate ? Moment(value.toDate()) : null}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            // desktopModeMediaQuery={'none'}
            openTo={
              props.openTo ||
              (props.datePickerMode === 'date' ? 'day' : 'hours')
            }
            slotProps={{
              field: { clearable: props.clearable, inputVariant: 'filled' },
              textField: {
                variant: 'filled',
                InputProps: {
                  disableUnderline: true,
                  endAdornment,
                },
                onClick: () => setDatePickerOpen(true),
                error: error,
                helperText: helperText,
                FormHelperTextProps: {
                  classes: {
                    root: classes.helperText,
                  },
                },
              },
              actionBar: ({ wrapperVariant }) => ({
                actions:
                  wrapperVariant === 'desktop'
                    ? []
                    : [props.clearable && 'clear', 'cancel', 'accept'],
              }),
            }}
            style={{ flex: 1 }}
            className={classes.root}
            // onClear={(event) => {
            //   setValue(null);
            //   props.onClearPress && props.onClearPress();
            // }}
            localeText={{
              cancelButtonLabel: toSentenceCase(
                SharedStrings.datepicker_cancel_button_text,
              ),
              okButtonLabel: toSentenceCase(
                SharedStrings.datepicker_ok_button_text,
              ),
              clearButtonLabel: toSentenceCase(
                SharedStrings.datepicker_clear_button_text,
              ),
            }}
            onChange={(date) => {
              if (date === null && props.onClearPress) {
                setValue(null);
                props.onClearPress();
              } else {
                const newValue = localeMoment(Moment(date));
                setValue(date);
                onChange && onChange(newValue.format('YYYY-MM-DD'), date);
              }
            }}
          />
        </View>
      </ThemeProvider>
    );
  }

  return (
    <View
      style={[styles.element, style]}
      pointerEvents={props.disablePointerEvents ? 'none' : 'auto'}
    >
      <TextField
        {...otherProps}
        variant="filled"
        select={props.picker && !!props.pickerItems}
        type={type}
        value={value}
        className={classes.root}
        style={{ flex: 1, borderWidth: 1 }}
        InputProps={{ endAdornment, disableUnderline: true }}
        error={error}
        helperText={helperText}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            props.onSubmitEditing && props.onSubmitEditing();
          }
        }}
        onChange={(event) => {
          const newValue = event.target.value;
          setValue(newValue);
          if (props.picker) {
            props.pickerOnItemPress(newValue);
          } else {
            onChange && onChange(newValue);
          }
        }}
        SelectProps={{
          MenuProps: {
            PopoverClasses: {
              paper: classes.menuRoot,
            },
          },
        }}
        MenuProps={{
          PopoverClasses: {
            paper: classes.menuRoot,
          },
        }}
      >
        {!!props.pickerItems &&
          props.pickerItems.map((item) => {
            return (
              <MenuItem
                value={item.id}
                key={item.id}
                classes={{ root: classes.menuItem }}
              >
                {item.text}
              </MenuItem>
            );
          })}
      </TextField>
      {props.customInjectedElement}
    </View>
  );
}

const styles = StyleSheet.create({
  element: {
    alignSelf: 'stretch',
    minHeight: 78,
    justifyContent: 'space-between',
  },
  input: {
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 16,
    paddingHorizontal: 12,
    marginTop: designConstants.isWeb ? 0 : 25, // !!! Before there was 16 for Android, now it is changed to
    paddingTop: designConstants.isWeb ? 25 : 0,
    // avoid bugs with aligning !!!
    marginBottom: designConstants.isWeb || Platform.OS === 'android' ? 0 : 5,
  },
});
