import { checkToken } from '../../Api/actions';
import AllRecords from '../../Sync/records';
import { bugsnag } from '../../Settings/components/CrashUsageController';
import { fixDeletedRecord, fixRecord } from '../../Records/actions';

import * as MMPApi from 'apiclient';
import { getLang } from '../../../shared/utilities/getLang';

export default function FindRecordsWithMissingID(dispatch, getState) {
  console.log('FINDING MISSING RECORDS!!');
  let localRecords = getState().records.list;
  let deletedRecords = getState().records.deleted;
  let loggedIn = !!getState().auth.user;
  if (!loggedIn) {
    return Promise.resolve();
  }

  let api = new MMPApi.RecordCallsApi();

  let params = MMPApi.GetAllRecordsParams.constructFromObject({
    last_updated: 0,
  });

  return checkToken().then(() => {
    return api
      .syncGetAllRecordsPost(getLang(), params)
      .then((result) => {
        // Parse get-all-records result

        //                        return Promise.reject({response: {text: '{"result_code": "143", "result_msg": "Simulated error"}'}});
        let server = new AllRecords(result);
        return server;
      })
      .then((server) => {
        console.log('FINDING MISSING RECORDS checking records!!');
        let serverRecords = server.recordsList.slice();
        let serverIds = serverRecords.map((record) => record.record_id);
        let missingRecords = [];
        let missingDeletedRecords = [];
        localRecords.forEach((record) => {
          if (record.idOnServer) {
            if (!serverIds.includes(record.idOnServer)) {
              //Missing record found!!
              missingRecords.push(record);
            }
          }
        });
        deletedRecords.forEach((record) => {
          if (record.idOnServer) {
            if (!serverIds.includes(record.idOnServer)) {
              missingDeletedRecords.push(record);
            }
          }
        });
        if (missingRecords.length > 0) {
          bugsnag &&
            bugsnag.notify(
              new Error(
                'Found ' +
                  missingRecords.length +
                  ' missing records with IDs ' +
                  missingRecords.map((rec) => rec.idOnServer),
              ),
            );
        }
        if (missingDeletedRecords.length > 0) {
          bugsnag &&
            bugsnag.notify(
              new Error(
                'Found DELETED ' +
                  missingDeletedRecords.length +
                  ' missing records with IDs ' +
                  missingDeletedRecords.map((rec) => rec.idOnServer),
              ),
            );
        }
        return Promise.all(
          missingRecords.map((record) => {
            return dispatch(fixRecord(record));
          }),
        ).then(() => {
          return Promise.all(
            missingDeletedRecords.map((record) => {
              return dispatch(fixDeletedRecord(record));
            }),
          );
        });
      });
  });
}
