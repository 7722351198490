// @flow
import * as types from './actiontypes';
import { API_ERROR, WEB_TOKEN_OUTDATED } from '../Api/actiontypes';
import { APP_OPEN } from '../Intro/actiontypes';
import {
  CHECK_TOKEN,
  SYNC_SUCCESS_CLOSE,
  SYNC_SYNC_IN_PROCESS_FLAG_OFF,
} from '../Sync/actiontypes';
import { NAVIGATOR_WEB_INITIALIZED } from '../Navigator/actiontypes';
import { designConstants } from '../../design/styles';
import * as profileTypes from '../Profile/actiontypes';

const INITIAL_STATE = {
  user: null,
  navigate_to_main_screen: null,
};

type State = {};

function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOGIN:
    case types.LOGIN_WITH_PROVIDER:
      return {
        ...state,
        //Immediately set this token to false if user perform usual log in
        //If web token was outdated before, then we need to initialize other values and set it to false only after webInitialized call is performed
        web_token_outdated: !state.user ? false : state.web_token_outdated,
        error: false,
        loading: true,
      };
    case types.AUTH_FLUSH:
      return {
        ...state,
        user: null,
        error: false,
        loading: false,
      };
    case types.LOGIN_CLEAR:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case types.CONNECT_WITH_PROVIDER:
      if (action.payload.provider === 'google-auth') {
        return {
          ...state,
          user: { ...state.user, google_token: action.payload.token },
        };
      }
      return state;
    case types.LOGIN_SUCCESS:
      // __proto__ in payload leads to app failure
      const { __proto__, ...user } = action.payload;
      let sync_in_progress = {};
      if (designConstants.isWeb) {
        sync_in_progress = { loading: false, web_token_outdated: false };
      }
      return {
        ...state,
        ...sync_in_progress,
        user: {
          ...user,
          enable_pro: state.user?.enable_pro || user.enable_pro,
          is_subscriber: state.user?.is_subscriber || user.is_subscriber,
          subscription_market:
            state.user?.subscription_market || user.subscription_market,
          password_set: state.user?.password_set || user.password_set,
          pain_guide_available:
            state.user?.pain_guide_available || user.pain_guide_available,
        },
      };
    case types.SET_USER: {
      // Excluding fields which should not be in user object
      const { is_pro, is_subscription, is_institution, ..._payload } =
        action.payload;
      return {
        ...state,
        full_user_received: true,
        user: { ...state.user, ..._payload },
      };
    }
    case SYNC_SUCCESS_CLOSE:
      return {
        ...state,
        loading: false,
        synced: true,
      };
    case SYNC_SYNC_IN_PROCESS_FLAG_OFF:
      return {
        ...state,
        sync_in_progress: false,
      };
    case APP_OPEN:
      return {
        ...state,
        logout_attempt_flag: false,
      };
    case profileTypes.ACCOUNT_DELETED:
    case types.SYNC_FAILED_LOGOUT:
    case types.LOGOUT:
      return {
        ...state,
        user: null,
        navigate_to_main_screen: null,
        web_token_outdated: false,
        logged_out: true,
        loading: false,
        questionnaires: [],
        questionnairesRetrieved: false,
        questionnairesToBeCompleted: 0,
      };

    case types.SET_LOGOUT_ATTEMPT_FLAG: {
      return {
        ...state,
        logout_attempt_flag: action.payload,
      };
    }

    case API_ERROR:
      switch (action.reason) {
        case types.LOGIN:
        case types.LOGIN_WITH_PROVIDER:
        case CHECK_TOKEN:
          state.loading = false;
          state.error = true;
          break;
      }

      return {
        ...state,
      };

    case types.GET_IS_PASSWORD_SET_REQUEST: {
      return {
        ...state,
        requesting_password_set: true,
      };
    }
    case types.GET_IS_PASSWORD_SET_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.content,
        },
        requesting_password_set: false,
      };
    }
    case types.GET_IS_PASSWORD_SET_FAILURE: {
      return {
        ...state,
        requesting_password_set: false,
      };
    }
    case NAVIGATOR_WEB_INITIALIZED: {
      return {
        ...state,
        loading: false,
        web_token_outdated: false,
      };
    }
    case WEB_TOKEN_OUTDATED: {
      return {
        ...state,
        user: state.user && { ...state.user, token: null },
        web_token_outdated: true,
        web_token_path: action.payload,
      };
    }
    /*
     *  When URL is associated with an Institution - add corresponding fields to state
     */
    case types.SET_INSTITUTION: {
      return {
        ...state,
        institution_landing_page: true,
        institution: action.payload.institution,
        skip_consent: action.payload.skip_consent,
        join_institution_if_no_consent:
          action.payload.join_institution_if_no_consent,
      };
    }
    /*
     * If URL contains any UTM parameter (campaign, medium or/and source) - add them to state
     */
    case types.SET_UTM_PARAMETERS: {
      return {
        ...state,
        utm_campaign: action.payload.utm_campaign,
        utm_medium: action.payload.utm_medium,
        utm_source: action.payload.utm_source,
      };
    }
    case types.NAVIGATE_TO_MAIN_SCREEN: {
      return {
        ...state,
        navigate_to_main_screen: true,
        web_token_outdated: false,
      };
    }
    case types.JOIN_INSTITUTION_SUCCESS: {
      return {
        ...state,
        user: { ...state.user, is_institution_member: 1 },
      };
    }
    default:
      return state;
  }
}

export { AuthReducer };
