import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { designConstants } from '../styles';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import useThemeContext from '../styles/useThemeContext';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

let open = false;
let listener = null;

const Alert = {};
Alert.alert = (title, message, buttons, options) => {
  if (listener) {
    listener({
      title,
      message,
      buttons,
      options,
    });
  }
};
export default Alert;

const useStyles = makeStyles({
  root: (colors) => ({
    color: colors.textPrimary,
  }),
});

export const AlertDialog = (props) => {
  const webTokenOutdated = useSelector(
    (state) => state.auth.web_token_outdated,
  );

  const { colors } = useThemeContext();
  const classes = useStyles(colors);
  const [state, setState] = useState({
    open: false,
    title: null,
    message: null,
    buttons: null,
  });

  const handleClose = (event, reason) => {
    if (state.options?.cancelable === false && reason === 'backdropClick') {
      return;
    }
    setState({
      open: false,
    });
  };

  useEffect(() => {
    const alertShowListener = (obj) => {
      if (state.open) {
        return;
      }
      setState({
        open: true,
        ...obj,
      });
    };

    listener = alertShowListener;
    return () => {
      listener = null;
    };
  }, [state.open]);

  const isTablet = designConstants.useIsTablet();
  return (
    <Dialog
      open={(!webTokenOutdated && state.open) || state.options?.forceOpen}
      style={{ zIndex: 10000 }}
      onClose={handleClose}
      PaperProps={{ style: { backgroundColor: colors.defaultBackground } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {state.title && (
        <DialogTitle className={classes.root} id="alert-dialog-title">
          {state.title}
        </DialogTitle>
      )}
      {state.message && (
        <DialogContent>
          <Typography classes={classes} id="alert-dialog-description">
            {state.message}
          </Typography>
        </DialogContent>
      )}
      {state.buttons && (
        <DialogActions style={{ flexDirection: isTablet ? 'row' : 'column' }}>
          {state.buttons.map((button) => {
            const buttonStyle = {
              alignSelf: 'stretch',
              marginTop: isTablet ? 0 : 8,
              marginLeft: isTablet ? 8 : 0,
              backgroundColor:
                button.style == 'positive' ? colors.lightGreen : undefined,
              color:
                button.style == 'positive' ? colors.white : colors.buttonBlue,
              textTransform: 'none',
            };

            return (
              <Button
                style={buttonStyle}
                onClick={() => {
                  handleClose();
                  button.onPress();
                }}
              >
                {toSentenceCase(button.text)}
              </Button>
            );
          })}
        </DialogActions>
      )}
    </Dialog>
  );
};
