import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

const getWidth = () => Dimensions.get('window').width;

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = (e) => {
      // change width from the state object
      setWidth(e.window.width);
    };
    // set resize listener
    Dimensions.addEventListener('change', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      Dimensions.addEventListener('change', resizeListener);
    };
  }, []);

  return width;
}

export default useCurrentWidth;
export { useCurrentWidth };
