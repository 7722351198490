import * as types from './actiontypes';
import {
  ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION,
  UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH,
  UPDATE_UNSYNC_MEDICATION_BRAND,
  UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS,
  UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH,
} from './actiontypes';
import { FID, getDefaultValues, ID, NID, predefinedData } from './constants';
import { Platform } from 'react-native';
import { medicationParser } from '../Meds/constants';
import { designConstants } from '../../design/styles';

const initialState = {
  introViewed: designConstants.isWeb,
  eulaAccepted: designConstants.isWeb,
  greetingViewed: designConstants.isWeb,
  notificationsViewed: Platform.OS !== 'ios' && Platform.Version < 33,
  wizardFirstStepViewed: designConstants.isWeb,
  wizardSecondStepViewed: designConstants.isWeb,
  hideUpgrade: false,
  upgradeSliderShown: false,
  painConditions: getDefaultValues(predefinedData.conditions, 'painConditions'),
  medications: getDefaultValues(predefinedData.medications, 'medications'),
  current_id: ID,
  current_fid: FID,
  current_nid: NID,
};

function IntroReducer(state = { ...initialState }, action) {
  const payload = action.payload;
  switch (action.type) {
    case types.APP_UPDATE_SETTINGS:
      return { ...state, ...action.payload };

    case types.ADD_UNSYNC_PAIN_CONDITION:
      const _painConditions = [...state.painConditions];
      let fid = state.current_fid;
      let index = -1;
      const condition =
        !payload.new_created &&
        _painConditions.find((val) => {
          ++index;
          return val.fid === payload.fid;
        });
      if (!condition) {
        const _condition = {
          fid: payload.new_created ? fid : payload.fid,
          value: payload.value,
          isShow: 1,
          first_symptom: payload.first_symptom,
          diagnosed: payload.diagnosed,

          checked: true,
          display_value: payload.new_created
            ? payload.value
            : payload.matched_value,
          translated_value: payload.translated_value,
          flag_created: true,
        };
        if (payload.preferred_language) {
          _condition.preferred_language = payload.preferred_language;
        }
        _painConditions.unshift(_condition);
        payload.new_created && --fid;
      } else {
        _painConditions[index] = { ..._painConditions[index], checked: true };
      }
      return { ...state, painConditions: _painConditions, current_fid: fid };

    case types.UPDATE_UNSYNC_PAIN_CONDITION:
      return {
        ...state,
        painConditions: state.painConditions.map((val) => {
          if (val.fid === payload) {
            return { ...val, checked: !val.checked };
          }
          return val;
        }),
      };
    case types.CLEAR_UNSYNC_MEDICATION: {
      return {
        ...state,
        medications: state.medications.map((med) => {
          return { ...med, checked: false };
        }),
      };
    }
    case types.ADD_UNSYNC_MEDICATION:
      let { current_id, current_fid, current_nid } = state;
      const {
        common_name,
        common_name_translation,
        brand_name,
        take_as_value,
        top_dosage_units,
        top_strength,
      } = payload;

      const newMedications = [...state.medications];
      const { dosages, strength_units_in_order, dosage_units_in_order } =
        medicationParser(payload);

      const added_strengths = [];
      dosages.forEach((dosage, _index) => {
        const new_dosage = {
          id: _index + 1,
          strength_units: dosage.strength_unit,
          strength: dosage.strengths_concatenated,
          selected: false, // _index === 0,
          ids: { id: current_id, fid: current_fid, nid: current_nid },
        };
        ++current_id;
        --current_fid;
        --current_nid;
        added_strengths.push(new_dosage);
      });

      newMedications.unshift({
        id: current_id,
        fid: current_fid,
        nid: current_nid,
        common_name: common_name,
        common_name_translation: common_name_translation,
        brand_name: brand_name,
        strength: null,
        take_as_value: take_as_value || null,
        purpose: [],

        // strength_units: strength_units || 'mg',
        // dosage_units: dosage_units || null,

        strength_units: strength_units_in_order[0].value,
        dosage_units: dosage_units_in_order[0].value,

        start_date: null,
        end_date: null,
        med_notes: null,
        special_instructions: null,
        dosage: [],
        display_value: null,
        checked: true,
        is_default: false,

        top_dosage_units,
        top_strength,

        default_brands: [],

        added_strengths,

        add_moment: Date.now(),
        flag_created: true,

        strength_units_in_order,
        dosage_units_in_order,
      });

      ++current_id;
      --current_fid;
      --current_nid;

      return {
        ...state,
        current_id,
        current_fid,
        current_nid,
        medications: newMedications,
      };

    case types.UPDATE_UNSYNC_MEDICATION:
      const medications = state.medications.map((val) => {
        if (val.id === payload) {
          return { ...val, checked: !val.checked };
        }
        return val;
      });
      return { ...state, medications };

    case UPDATE_UNSYNC_MEDICATION_BRAND:
      return {
        ...state,
        medications: state.medications.map((val) => {
          if (val.nid == payload.nid) {
            return { ...val, brand_name: payload.brand_name };
          }
          return val;
        }),
      };

    case UPDATE_UNSYNC_MEDICATION_DOSAGE_UNITS:
      return {
        ...state,
        medications: state.medications.map((val) => {
          if (val.id === payload.id) {
            return { ...val, dosage_units: payload.dosage_units };
          }
          return val;
        }),
      };

    case UPDATE_UNSYNC_SELECT_MEDICATION_STRENGTH:
      return {
        ...state,
        medications: state.medications.map((val) => {
          if (val.id === payload.id) {
            return {
              ...val,
              added_strengths: val.added_strengths.map((_strength) => {
                if (_strength.id === payload.strength_id) {
                  return { ..._strength, selected: !_strength.selected };
                } else {
                  return _strength;
                }
              }),
            };
          }
          return val;
        }),
      };
    case UPDATE_UNSYNC_ADD_MEDICATION_STRENGTH: {
      let { current_id, current_fid, current_nid } = state;
      return {
        ...state,
        medications: state.medications.map((val) => {
          if (val.id === payload.id) {
            const next_id = val.added_strengths.length + 1;

            const ids = { id: current_id, fid: current_fid, nid: current_nid };

            const new_strengths = [
              ...val.added_strengths,
              { ...payload.strength, id: next_id, ids },
            ];

            ++current_id;
            --current_fid;
            --current_nid;

            return {
              ...val,
              added_strengths: new_strengths,
            };
          }
          return val;
        }),
        current_id,
        current_fid,
        current_nid,
      };
    }

    case ADD_BRAND_TO_UNSYNC_DEFAULT_MEDICATION:
      return {
        ...state,
        medications: state.medications.map((val) => {
          if (val.nid == payload.nid) {
            return {
              ...val,
              default_brands:
                val.default_brands.indexOf(payload.brand_name) >= 0
                  ? val.default_brands
                  : [...val.default_brands, payload.brand_name],
              brand_name: payload.brand_name,
            };
          } else {
            return val;
          }
        }),
      };

    case types.LOGIN_FROM_INTRO: {
      return {
        ...state,
        greetingViewed: true,
        wizardFirstStepViewed: true,
        wizardSecondStepViewed: true,
        wizardViewed: true,
        painConditions: [],
        medications: [],
        brandsFound: [],
      };
    }
    case types.CLEAR_INTRO_STATE: {
      return {
        ...initialState,
      };
    }
    case types.CLEAN_INTRO_ENTRIES: {
      return {
        ...state,
        painConditions: [],
        medications: [],
        brandsFound: [],
      };
    }

    default:
      return state;
  }
}
export { IntroReducer };
