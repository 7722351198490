import strings from './strings';
import { getLang } from '../../shared/utilities/getLang';
import { designConstants } from '../../design/styles';

export const MIXT_SUFFIX = {
  en: 'mixt',
  de: 'Kombi',
  es: 'combinados',
  fr: 'Combi',
  ko: '혼합',
  ru: 'комб.',
  'zh-hans': '的联合用药',
};

export function getMixtNames(med) {
  const lang = getLang();
  return med
    .replace(MIXT_SUFFIX[lang], '')
    .trim()
    .split(lang === 'zh-hans' ? '、' : '-')
    .map((name) => name.trim());
}

export function formatMedicationField(field) {
  if (!field) {
    return null;
  }
  if (!field.medication || field.medication.length < 0) {
    return field?.name;
  }

  const medication = field.medication[0];
  if (medication?.brand === 'Generic') {
    const translation = field.translations?.find(
      (item) => item.language === getLang(),
    )?.value;

    return (translation || field?.name)?.replace(
      'Generic',
      strings.medications_section_generic_medication,
    );
  }
  const { translatedName, translatedBrand, translatedStrength } =
    getMedicationNameAndBrand(field, medication);
  return (
    translatedBrand +
    ' (' +
    translatedName +
    ')' +
    (translatedStrength ? ' -' + translatedStrength : '')
  );
}

export function getMedicationNameAndBrand(med, medication) {
  let translatedStrength =
    medication.strength &&
    medication.strength.length > 0 &&
    !isNaN(medication.strength.substring(0, 1))
      ? ' ' + medication.strength
      : '';

  const regex = /\((.*?)\)/g;
  const translation = med.translations?.find(
    (item) => item.language === getLang(),
  )?.value;
  const brandReg = regex.exec(translation);

  const strengthRegex = /\) -( .*)/;
  const strengthReg = strengthRegex.exec(translation);

  strengthReg && (translatedStrength = strengthReg[1]);
  const translatedBrand = brandReg?.[1] || medication.brand;
  const translatedName =
    translation?.substring(0, translation?.indexOf(brandReg?.[0]))?.trim() ||
    medication.medication;

  let name =
    (medication.brand === 'Generic' ? translatedName : translatedBrand) +
    translatedStrength;
  let brand =
    medication.brand === 'Generic'
      ? strings.medications_section_generic_medication
      : translatedName;

  return { name, brand, translatedName, translatedBrand, translatedStrength };
}

export function getWebViewStyleInjection(
  styleCss,
  themeStyleId = 'themeStyle',
  documentElement = 'document',
) {
  return `
      (() => {let style = ${documentElement}.getElementById('${themeStyleId}');
      if(!style) {
        style = ${documentElement}.createElement('style');
        style.setAttribute('id', '${themeStyleId}');
        ${documentElement}.head.append(style);
      }
      style.textContent = \`${styleCss}\`;})()
    `;
}

export function injectWebViewStyle(
  styleCss,
  webViewRef,
  themeStyleId = 'themeStyle',
  documentElement = 'document',
) {
  const injectStyle = getWebViewStyleInjection(
    styleCss,
    themeStyleId,
    documentElement,
  );
  setTimeout(() => {
    if (designConstants.isWeb) {
      eval(injectStyle);
    } else {
      webViewRef?.current?.injectJavaScript(injectStyle);
    }
  }, 100);
}
