import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [
  'records_card_days_without_record',
  'records_card_since_days_without_record',
  'records_card_button',
  'medication_card_button',
  'conditions_card_button',
  'questionnaires_card_title',
];

const STRINGS = new LocalizedStrings({
  en: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'en',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'en'),
    main_screen_tutorial_step_1_label:
      'Move the slider to rate your pain and add your first record',
    main_screen_tutorial_step_2_label:
      'Now click Add Pain Record to describe your pain',
    main_screen_sync_success_toast_message: shared('sync_success', 'en'),
    main_screen_record_your_pain: 'Record your pain!',
    main_screen_click_add_pain_record:
      'Click "{0}" to describe your pain in more detail.',

    // PainGuide card
    pain_guide_card_title: 'Pain Guide',
    pain_guide_card_no_internet_text:
      'Internet connection cannot be established. Retry when connection is re-established.',
    pain_guide_card_retry_button: shared('Retry', 'en'),
    pain_guide_card_register_or_login:
      'Register an account or login to safeguard your data, generate reports, and access our guide to pain management.',
    pain_guide_card_register_button: 'Register',
    pain_guide_login_button: 'Login',
    pain_guide_card_featured_content_title: 'Recommended Content',
    pain_guide_card_from_home: shared('Home', 'en'),

    pain_guide_card_introduction_title: 'Introducing the Pain Guide',
    pain_guide_card_introduction_message:
      'Our Pain Guide was created by pain specialists to provide you with helpful information about pain and ways to manage it. Access it directly from the home screen.',
    pain_guide_card_introduction_open_button: 'Explore pain guide now',
    pain_guide_card_introduction_later_button: 'Explore later',

    pain_guide_card_only_english_text:
      'This content is only available in English. Would you like to hide this section when the app is being used in a different language?',
    pain_guide_card_explore_more_button: 'Explore More',
    pain_guide_card_show_anyway: 'Show Anyway',
    pain_guide_card_hide: 'Hide',
    pain_guide_modal_no_internet_connection:
      'Internet connection cannot be established. Retry when connection is re-established.',

    // Recent Record card
    records_card_title: 'Recent Records',
    records_card_button_one: 'View record',
    records_card_button_other: 'View all {0} records',
    records_card_no_locations_text: 'No locations entered',
    records_card_no_activities_text: 'No meaningful activities entered',
    records_card_days_without_record_one: '{0} day without a record',
    records_card_days_without_record_other: '{0} days without a record',
    records_card_since_days_without_record_one:
      '{0} day since your last record',
    records_card_since_days_without_record_other:
      '{0} days since your last record',
    records_card_to_time_format: '{0} to {1}',
    records_card_to_date_format: '{0}\nto\n{1}',
    records_card_all_day_label: shared('All Day', 'en'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'en'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'en',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'en'),
    records_card_severity: 'Severity',
    records_card_no_daily_reflection: 'No daily reflection',
    records_no_pain_record: 'No pain record',
    records_card_number_of_pain_record: '{0} pain record',
    records_card_number_of_pain_records: '{0} pain records',
    records_card_no_recent_records:
      'No records have been added. Add your first record through the slider.',
    records_card_sync_button_text: 'Sync',
    records_card_factor_alleviating: shared('Better', 'en'),
    records_card_medication_generic: shared('Generic', 'en'),
    records_card_factor_ineffective: shared('No Change', 'en'),
    records_card_factor_aggravating: shared('Worse', 'en'),

    // Medications card
    medication_card_title: shared('Medications', 'en'),
    medication_card_generic: shared('Generic', 'en'),
    medication_card_button_one: 'View medication',
    medication_card_button_other: 'View all {0} medications',
    medication_card_better: shared('Better', 'en') + ' {0}%',
    medication_card_neutral: shared('No Change', 'en') + ' {0}%',
    medication_card_worse: shared('Worse', 'en') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'en') + ':',
    medications_card_end_date: shared('End Date', 'en') + ':',
    medications_card_no_medications:
      'No medications have been added. If you take medications, adding them will allow you to track their impact.',
    medications_card_add_medication: 'Add Medication',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'en'),
    conditions_card_button_one: 'View pain condition',
    conditions_card_button_other: 'View all {0} pain conditions',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'en') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'en') + ':',
    conditions_card_no_conditions:
      'No conditions have been added. Adding conditions will improve the completeness of reports for your care team.',
    conditions_card_add_pain_condition: 'Add Pain Condition',

    // Rate Me card
    rate_me_title: 'Are you liking Manage My Pain?',
    rate_me_step_yes: 'Would you mind leaving a rating?',
    rate_me_step_no: 'Would you mind giving us some feedback to improve?',
    rate_me_card_yes_button: 'Yes!',
    rate_me_card_no_button: 'Not really',
    rate_me_step_yes_card_yes_button: 'Okay, sure',
    rate_me_step_yes_card_no_button: 'No, thanks',
    rate_me_step_no_card_yes_button: 'Okay, sure',
    rate_me_step_no_card_no_button: 'No, thanks',

    // Sign Up Card
    sign_up_card_title:
      'Register an account to safeguard your data and create reports',
    sign_up_card_yes: 'Okay, sure',
    sign_up_card_no: 'No, thanks',

    // Questionnaires Card
    questionnaires_card_title_one:
      'You have a questionnaire that needs to be completed. Complete it now?',
    questionnaires_card_title_other:
      'You have {0} questionnaires that need to be completed. Complete them now?',
    questionnaires_card_yes: 'Yes, Complete Now',
    questionnaires_card_no: 'No, Remind Me Later',

    dark_mode_introduction_title: 'You asked, we listened. Dark Mode is here.',
    dark_mode_introduction_subtitle:
      'You can now switch to dark mode. Dark mode is easier on your eyes, better for your battery, and good for your sleep.',
    dark_mode_introduction_dark_mode_button: 'Use dark theme',
    dark_mode_introduction_light_mode_button: 'Use light theme',
    dark_mode_introduction_system_mode_button: 'Keep device default',
  },
  de: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'de',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'de'),
    main_screen_tutorial_step_1_label:
      'Bewegen Sie den Schieberegler, um Ihren Schmerz zu berwerten und Ihren ersten Eintrag hinzuzufügen',
    main_screen_tutorial_step_2_label:
      'Klicken Sie nun auf Schmerzeintrag hinzufügen, um Ihre Schmerzen zu bewerten',
    main_screen_sync_success_toast_message: shared('sync_success', 'de'),
    main_screen_record_your_pain: 'Schmerzen erfassen!',
    main_screen_click_add_pain_record:
      'Klicken Sie auf "{0}", um Ihre Schmerzen genauer zu beschreiben.',

    // PainGuide card
    pain_guide_card_title: 'Schmerz-Leitfaden',
    pain_guide_card_no_internet_text:
      'Die Internetverbindung konnte nicht aufgebaut werden. Versuchen Sie es erneut, wenn die Verbindung wiederhergestellt ist.',
    pain_guide_card_retry_button: shared('Retry', 'de'),
    pain_guide_card_register_or_login:
      'Registrieren Sie ein Konto oder melden Sie sich an, um Ihre Daten zu schützen, Berichte zu erstellen und auf unseren Leitfaden zur Schmerzbehandlung zuzugreifen.',
    pain_guide_card_register_button: 'Kostenlos registrieren',
    pain_guide_login_button: 'Anmelden',
    pain_guide_card_featured_content_title: 'Empfohlene Inhalte',
    pain_guide_card_from_home: shared('Home', 'de'),

    pain_guide_card_introduction_title: 'Der neue Schmerz-Leitfaden',
    pain_guide_card_introduction_message:
      'Unser Schmerz-Leitfaden wurde von Schmerzspezialisten erstellt, um Ihnen hilfreiche Informationen über Schmerzen und deren Bewältigung zur Verfügung zu stellen. Sie können ihn direkt von der Startseite aus aufrufen.',
    pain_guide_card_introduction_open_button:
      'Den Schmerz-Leitfaden jetzt erkunden',
    pain_guide_card_introduction_later_button: 'Später erkunden',

    pain_guide_card_only_english_text:
      'Dieser Inhalt ist nur auf Englisch verfügbar. Möchten Sie diesen Bereich ausblenden, wenn die App in einer anderen Sprache verwendet wird?',
    pain_guide_card_explore_more_button: 'Mehr erkunden',
    pain_guide_card_show_anyway: 'Mehr erkunden',
    pain_guide_card_hide: 'Ausblenden',
    pain_guide_modal_no_internet_connection:
      'Die Internetverbindung konnte nicht aufgebaut werden. Versuchen Sie es erneut, wenn die Verbindung wiederhergestellt ist.',

    // Recent Record card
    records_card_title: 'Letzte Einträge',
    records_card_button_one: 'Eintrag anzeigen',
    records_card_button_other: 'Alle {0} Einträge enzeigen',
    records_card_no_locations_text: 'Keine Bereiche angegeben',
    records_card_no_activities_text: 'Keine bedeutsamen Aktivitäten angegeben',
    records_card_days_without_record_one: '{0} Tag ohne Eintrag',
    records_card_days_without_record_other: '{0} Tage ohne Eintrag',
    records_card_since_days_without_record_one:
      '{0} Tag seit Ihrem letzten Eintrag',
    records_card_since_days_without_record_other:
      '{0} Tage seit Ihrem letzten Eintrag',
    records_card_to_time_format: '{0} bis {1}',
    records_card_to_date_format: '{0}\nbis\n{1}',
    records_card_all_day_label: shared('All Day', 'de'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'de'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'de',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'de'),
    records_card_severity: 'Schmerzstärke',
    records_card_no_daily_reflection: 'Keine tägliche Betrachtung',
    records_no_pain_record: 'Kein Schmerzeintrag',
    records_card_number_of_pain_record: '{0} Schmerzeintrag',
    records_card_number_of_pain_records: '{0} Schmerzeinträge',
    records_card_no_recent_records:
      'Es wurden noch keine Einträge erstellt. Fügen Sie Ihren ersten Eintrag über den Schieberegler oben hinzu.',
    records_card_sync_button_text: 'Sync',
    records_card_factor_alleviating: shared('Better', 'de'),
    records_card_medication_generic: shared('Generic', 'de'),
    records_card_factor_ineffective: shared('No Change', 'de'),
    records_card_factor_aggravating: shared('Worse', 'de'),

    // Medications card
    medication_card_title: shared('Medications', 'de'),
    medication_card_generic: shared('Generic', 'de'),
    medication_card_button_one: 'Medikament anzeigen',
    medication_card_button_other: 'Alle {0} Medikamente anzeigen',
    medication_card_better: shared('Better', 'de') + ' {0}%',
    medication_card_neutral: shared('No Change', 'de') + ' {0}%',
    medication_card_worse: shared('Worse', 'de') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'de') + ':',
    medications_card_end_date: shared('End Date', 'de') + ':',
    medications_card_no_medications:
      'Es wurden noch keine Medikamente hinzugefügt. Falls Sie Medikamente einnehmen, können Sie deren Wirkung mitverfolgen.',
    medications_card_add_medication: 'Medikament hinzufügen',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'de'),
    conditions_card_button_one: 'Schmerzzustand anzeigen',
    conditions_card_button_other: 'Alle {0} Schmerzzustände anzeigen',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'de') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'de') + ':',
    conditions_card_no_conditions:
      'Es wurden noch keine Beschwerden hinzugefügt. Wenn Sie Beschwerden hinzufügen, wird die Vollständigkeit der Berichte für Ihre Betreuer verbessert.',
    conditions_card_add_pain_condition: 'Schmerzzustand hinzufügen',

    // Rate Me card
    rate_me_title: 'Gefällt Ihnen Manage My Pain?',
    rate_me_step_yes: 'Wir würden uns sehr über eine Bewertung freuen!',
    rate_me_step_no:
      'Möchten Sie uns mitteilen, wie wir die App verbessern können?',
    rate_me_card_yes_button: 'Ja!',
    rate_me_card_no_button: 'Nicht wirklich',
    rate_me_step_yes_card_yes_button: 'Klar, mach ich',
    rate_me_step_yes_card_no_button: 'Danke, kein Bedarf',
    rate_me_step_no_card_yes_button: 'Klar, mach ich',
    rate_me_step_no_card_no_button: 'Danke, kein Bedarf',

    // Sign Up Card
    sign_up_card_title:
      'Registrieren Sie sich mit einem Benutzerkonto, um Ihre Daten zu sichern und Berichte zu erstellen',
    sign_up_card_yes: 'Klar, mach ich',
    sign_up_card_no: 'Danke, kein Bedarf',

    // Questionnaires Card
    questionnaires_card_title_one:
      'Sie haben einen Fragebogen zum Ausfüllen. Möchten Sie diesen nun ausfüllen?',
    questionnaires_card_title_other:
      'Sie haben {0} Fragebögen zum Ausfüllen. Möchten Sie diese nun ausfüllen?',
    questionnaires_card_yes: 'Ja, Jetzt Ausfüllen',
    questionnaires_card_no: 'Nein, Später Erinneren',

    dark_mode_introduction_title:
      'Sie fragten, wir hörten zu. Der Dunkelmodus ist da.',
    dark_mode_introduction_subtitle:
      'Sie können jetzt in den Dunkelmodus wechseln. Der Dunkelmodus ist besser für Ihre Augen, schont Ihre Batterie und fördert Ihren Schlaf.',
    dark_mode_introduction_dark_mode_button: 'Dunkles Design verwenden',
    dark_mode_introduction_light_mode_button: 'Helles Design verwenden',
    dark_mode_introduction_system_mode_button: 'Gerätestandard beibehalten',
  },

  es: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'es',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'es'),
    main_screen_tutorial_step_1_label:
      'Desplace el cursor para calificar su dolor y agregue su primer registro',
    main_screen_tutorial_step_2_label:
      'Ahora haga clic en Agregar registro de dolor para describir su dolor',
    main_screen_sync_success_toast_message: shared('sync_success', 'es'),
    main_screen_record_your_pain: 'Registrar su dolor!',
    main_screen_click_add_pain_record:
      'Haga clic en "{0}" para describir su dolor con más detalle',

    // PainGuide card
    pain_guide_card_title: 'Guía de dolor',
    pain_guide_card_no_internet_text:
      'No se puede establecer la conexión a Internet. Vuelva a intentarlo cuando se restablezca la conexión.',
    pain_guide_card_retry_button: shared('Retry', 'es'),
    pain_guide_card_register_or_login:
      'Registre una cuenta o inicie sesión para salvaguardar sus datos, generar informes y acceder a nuestra guía para el tratamiento del dolor.',
    pain_guide_card_register_button: 'Regístrese gratis',
    pain_guide_login_button: 'Ingresar',
    pain_guide_card_featured_content_title: 'Contenido recomendado',
    pain_guide_card_from_home: shared('Home', 'es'),

    pain_guide_card_introduction_title: 'Introducción a la Guía de dolor',
    pain_guide_card_introduction_message:
      'Nuestra Guía del dolor ha sido creada por especialistas en dolor para proporcionarle información útil sobre el dolor y las formas de controlarlo. Acceda a ella directamente desde la pantalla de inicio.',
    pain_guide_card_introduction_open_button: 'Explore la guía del dolor ahora',
    pain_guide_card_introduction_later_button: 'Explorar más tarde',

    pain_guide_card_only_english_text:
      'Este contenido sólo está disponible en inglés. ¿Desea ocultar esta sección cuando la aplicación se utiliza en un idioma diferente?',
    pain_guide_card_explore_more_button: 'Explorar más',
    pain_guide_card_show_anyway: 'Mostrar de todas formas',
    pain_guide_card_hide: 'Ocultar',
    pain_guide_modal_no_internet_connection:
      'No se puede establecer la conexión a Internet. Vuelva a intentarlo cuando se restablezca la conexión.',

    // Recent Record card
    records_card_title: 'Registros recientes',
    records_card_button_one: 'Ver registro',
    records_card_button_other: 'Ver todos los {0} registros',
    records_card_no_locations_text: 'No se agregaron lugares',
    records_card_no_activities_text:
      'No se agregaron actividades significativas',
    records_card_days_without_record_one: '{0} día sin registros',
    records_card_days_without_record_other: '{0} día desde su último registro',
    records_card_since_days_without_record_one:
      '{0} día desde su último registro',
    records_card_since_days_without_record_other:
      '{0} días desde su último registro',
    records_card_to_time_format: 'de {0} a {1}',
    records_card_to_date_format: 'del {0}\nal {1}',
    records_card_all_day_label: shared('All Day', 'es'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'es'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'es',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'es'),
    records_card_severity: 'Gravedad',
    records_card_no_daily_reflection: 'Sin reflexión diaria',
    records_no_pain_record: 'Sin registros de dolor',
    records_card_number_of_pain_record: '{0} registro de dolor',
    records_card_number_of_pain_records: '{0} registros de dolor',
    records_card_no_recent_records:
      'No se ha añadido ningún registro. Añada su primer registro a través del deslizador.',
    records_card_sync_button_text: 'Sincronizar',
    records_card_factor_alleviating: shared('Better', 'es'),
    records_card_medication_generic: shared('Generic', 'es'),
    records_card_factor_ineffective: shared('No Change', 'es'),
    records_card_factor_aggravating: shared('Worse', 'es'),

    // Medications card
    medication_card_title: shared('Medications', 'es'),
    medication_card_generic: shared('Generic', 'es'),
    medication_card_button_one: 'Ver medicamento',
    medication_card_button_other: 'Ver todos los {0} medicamentos',
    medication_card_better: shared('Better', 'es') + ' {0}%',
    medication_card_neutral: shared('No Change', 'es') + ' {0}%',
    medication_card_worse: shared('Worse', 'es') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'es') + ':',
    medications_card_end_date: shared('End Date', 'es') + ':',
    medications_card_no_medications:
      'No se han añadido medicamentos. Si toma medicamentos, añadirlos le permitirá hacer un seguimiento de su impacto.',
    medications_card_add_medication:
      'No se han añadido medicamentos. Si toma medicamentos, añadirlos le permitirá hacer un seguimiento de su impacto.',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'es'),
    conditions_card_button_one: 'Ver la condición desencadenante de dolor',
    conditions_card_button_other:
      'Ver todos las {0} condiciones desencadenantes de dolor',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'es') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'es') + ':',
    conditions_card_no_conditions:
      'No se ha añadido ninguna condición. La adición de condiciones mejorará la exhaustividad de los informes para su equipo de atención.',
    conditions_card_add_pain_condition:
      'Agregar condición desencadenante de dolor',

    // Rate Me card
    rate_me_title: '¿Le gusta Manage My Pain?',
    rate_me_step_yes: '¿Le gustaría calificarnos?',
    rate_me_step_no: '¿Compartiría sus opiniones con nosotros para mejorar?',
    rate_me_card_yes_button: '¡Sí!',
    rate_me_card_no_button: 'En realidad no',
    rate_me_step_yes_card_yes_button: 'De acuerdo',
    rate_me_step_yes_card_no_button: 'No, gracias',
    rate_me_step_no_card_yes_button: 'De acuerdo',
    rate_me_step_no_card_no_button: 'No, gracias',

    // Sign Up Card
    sign_up_card_title:
      'Registre una cuenta para salvaguardar sus datos y crear informes',
    sign_up_card_yes: 'De acuerdo',
    sign_up_card_no: 'No, gracias',

    // Questionnaires Card
    questionnaires_card_title_one:
      'Hay un cuestionario pendiente que debe completar. ¿Desea completarlo ahora?',
    questionnaires_card_title_other:
      'Hay {0} cuestionarios pendientes que debe completar. ¿Desea completarlos ahora?',
    questionnaires_card_yes: 'Sí, completar ahora',
    questionnaires_card_no: 'No, recordarme más tarde',

    dark_mode_introduction_title:
      'Ustedes lo pidieron, los escuchamos. El Modo oscuro está aquí.',
    dark_mode_introduction_subtitle:
      'Ahora puede cambiar al modo oscuro. El modo oscuro es más cómodo para sus ojos, mejor para la batería y beneficioso para su sueño.',
    dark_mode_introduction_dark_mode_button: 'Usar el tema oscuro',
    dark_mode_introduction_light_mode_button: 'Usar el tema claro',
    dark_mode_introduction_system_mode_button: 'Conservar el predeterminado',
  },

  fr: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'fr',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'fr'),
    main_screen_tutorial_step_1_label:
      'Déplacez le curseur pour évaluer votre douleur et ajoutez votre premier enregistrement',
    main_screen_tutorial_step_2_label:
      'Maintenant, cliquez sur Ajouter un Enregistrement pour décrire votre douleur',
    main_screen_sync_success_toast_message: shared('sync_success', 'fr'),
    main_screen_record_your_pain: 'Enregistrez votre douleur!',
    main_screen_click_add_pain_record:
      'Cliquez sur "{0}" de la douleur pour décrire votre douleur de manière plus détaillée.',

    // PainGuide card
    pain_guide_card_title: 'Guide sur la douleur',
    pain_guide_card_no_internet_text:
      'La connexion à Internet ne peut être établie. Veuillez réessayer une fois la connexion rétablie.',
    pain_guide_card_retry_button: shared('Retry', 'fr'),
    pain_guide_card_register_or_login:
      'Créez un compte ou connectez-vous pour protéger vos données, générer des rapports et accéder à notre guide sur la gestion de la douleur.',
    pain_guide_card_register_button: 'Inscrivez-vous gratuitement',
    pain_guide_login_button: 'Connexion',
    pain_guide_card_featured_content_title: 'Contenu recommandé',
    pain_guide_card_from_home: shared('Home', 'fr'),

    pain_guide_card_introduction_title: 'Présentation du Guide sur la douleur',
    pain_guide_card_introduction_message:
      "Notre Guide sur la douleur a été créé par des spécialistes de la douleur dans le but de vous fournir des informations utiles sur la douleur et les moyens de la gérer. Vous pouvez y accéder directement depuis l'écran d'accueil.",
    pain_guide_card_introduction_open_button:
      'Découvrir le guide sur la douleur dès à présent',
    pain_guide_card_introduction_later_button: 'Le découvrir plus tard',

    pain_guide_card_only_english_text:
      "Ce contenu est uniquement disponible en anglais. Souhaitez-vous masquer cette section lorsque l'application est utilisée dans une autre langue ?",
    pain_guide_card_explore_more_button: 'En découvrir davantage',
    pain_guide_card_show_anyway: 'Afficher quand même',
    pain_guide_card_hide: 'Masquer',
    pain_guide_modal_no_internet_connection: 'Masquer',

    // Recent Record card
    records_card_title: 'Enregistrements récents',
    records_card_button_one: 'Afficher enregistrement',
    records_card_button_other: 'Afficher les {0} enregistrements',
    records_card_no_locations_text: 'Aucun endroit ajouté',
    records_card_no_activities_text: 'Aucune activité significative ajoutée',
    records_card_days_without_record_one: '{0} jour sans un enregistrement',
    records_card_days_without_record_other:
      '{0} jours depuis votre dernier enregistrement',
    records_card_since_days_without_record_one:
      '{0} jour depuis votre dernier enregistrement',
    records_card_since_days_without_record_other:
      '{0} jours depuis votre dernier enregistrement',
    records_card_to_time_format: "de {0}  jusqu'à {1}",
    records_card_to_date_format: "depuis le\n{0}\njusqu'à\n{1}",
    records_card_all_day_label: shared('All Day', 'fr'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'fr'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'fr',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'fr'),
    records_card_severity: 'Gravité',
    records_card_no_daily_reflection: 'Aucune réflexion quotidienne',
    records_no_pain_record: 'Aucun enregistrement de la douleur',
    records_card_number_of_pain_record: '{0} enregistrement de la douleur',
    records_card_number_of_pain_records: '{0} enregistrements de la douleur',
    records_card_no_recent_records:
      "Aucun enregistrement n'a été ajouté. Ajoutez votre premier enregistrement à l'aide du curseur.",
    records_card_sync_button_text: 'Sync',
    records_card_factor_alleviating: shared('Better', 'fr'),
    records_card_medication_generic: shared('Generic', 'fr'),
    records_card_factor_ineffective: shared('No Change', 'fr'),
    records_card_factor_aggravating: shared('Worse', 'fr'),

    // Medications card
    medication_card_title: shared('Medications', 'fr'),
    medication_card_generic: shared('Generic', 'fr'),
    medication_card_button_one: 'Afficher médicament',
    medication_card_button_other: 'Afficher les {0} médicaments',
    medication_card_better: shared('Better', 'fr') + ' {0}%',
    medication_card_neutral: shared('No Change', 'fr') + ' {0}%',
    medication_card_worse: shared('Worse', 'fr') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'fr') + ':',
    medications_card_end_date: shared('End Date', 'fr') + ':',
    medications_card_no_medications:
      "Aucun médicament n'a été ajouté. Si vous prenez des médicaments, le fait de les ajouter vous permettra de suivre leurs effets.",
    medications_card_add_medication: 'Ajouter un médicament',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'fr'),
    conditions_card_button_one: 'Afficher condition douloureuse',
    conditions_card_button_other: 'Afficher les {0} conditions douloureuses',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'fr') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'fr') + ':',
    conditions_card_no_conditions:
      "Aucune condition n'a été ajoutée. L'ajout de conditions améliorera l'exhaustivité des rapports destinés à votre équipe soignante.",
    conditions_card_add_pain_condition: 'Ajouter une condition douloureuse',

    // Rate Me card
    rate_me_title: 'Aimez-vous Manage My Pain ?',
    rate_me_step_yes: "Voudrais-vous évaluer l'appli ?",
    rate_me_step_no:
      'Voudrais-vous partager vôtres impressions pour améliorer ?',
    rate_me_card_yes_button: 'Oui!',
    rate_me_card_no_button: 'Pas vraiment',
    rate_me_step_yes_card_yes_button: "D'accord",
    rate_me_step_yes_card_no_button: 'Non, merci',
    rate_me_step_no_card_yes_button: "D'accord",
    rate_me_step_no_card_no_button: 'Non, merci',

    // Sign Up Card
    sign_up_card_title:
      'Créez un compte pour sauvegarder vos données et créer des rapports',
    sign_up_card_yes: "D'accord",
    sign_up_card_no: 'Non, merci',

    // Questionnaires Card
    questionnaires_card_title_one:
      'Un questionnaire est en attente de réponse. Y répondre maintenant?',
    questionnaires_card_title_other:
      '{0} questionnaires sont en attente de réponse. Y répondre maintenant?',
    questionnaires_card_yes: 'Oui, procéder au remplissage',
    questionnaires_card_no: 'Non, me le rappeler plus tard',

    dark_mode_introduction_title:
      'Nous avons pris en compte votre demande. Le mode sombre est désormais disponible.',
    dark_mode_introduction_subtitle:
      'Vous pouvez à présent passer en mode sombre. Le mode sombre est plus doux pour les yeux, plus avantageux pour la batterie et meilleur pour le sommeil.',
    dark_mode_introduction_dark_mode_button: 'Utiliser le thème sombre',
    dark_mode_introduction_light_mode_button: 'Utiliser le thème clair',
    dark_mode_introduction_system_mode_button:
      "Conserver le réglage par défaut de l'appareil",
  },

  ko: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'ko',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'ko'),
    main_screen_tutorial_step_1_label:
      '통증을 평가하기 위해 슬라이더를 이동하고 첫 번째 기록을 추가합니다',
    main_screen_tutorial_step_2_label:
      '이제 통증 기록 추가 버튼을 클릭하여 통증을 설명하세요',
    main_screen_sync_success_toast_message: shared('sync_success', 'ko'),
    main_screen_record_your_pain: '통증 기록하기',
    main_screen_click_add_pain_record:
      '"{0}" 클릭하여 통증을 더 자세히 설명합니다',

    // PainGuide card
    pain_guide_card_title: '통증 가이드',
    pain_guide_card_no_internet_text:
      '인터넷 연결이 설정되지 않았습니다. 연결이 재설정되면 다시 시도하세요.',
    pain_guide_card_retry_button: shared('Retry', 'ko'),
    pain_guide_card_register_or_login:
      '계정에 가입하거나 로그인하여 데이터를 안전하게 보호하고, 보고서를 생성하고, 통증 관리 가이드를 조회하세요.',
    pain_guide_card_register_button: '무료 회원가입',
    pain_guide_login_button: '로그인',
    pain_guide_card_featured_content_title: '추천 콘텐츠',
    pain_guide_card_from_home: shared('Home', 'ko'),

    pain_guide_card_introduction_title: '통증 가이드 소개',
    pain_guide_card_introduction_message:
      '저희 통증 가이드는 통증에 대한 유용한 정보를 전해드리고 통증 관리 방법을 설명하기 위해 통증 전문가가 작성하였습니다. 홈 화면에서 직접 조회하세요.',
    pain_guide_card_introduction_open_button: '지금 통증 가이드를 탐색하세요.',
    pain_guide_card_introduction_later_button: '나중에 탐색하기',

    pain_guide_card_only_english_text:
      '이 내용은 영어로만 조회할 수 있습니다. 다른 언어로 앱을 이용하고 있을 때 이 섹션을 숨기겠습니까?',
    pain_guide_card_explore_more_button: '더 탐색하기',
    pain_guide_card_show_anyway: '나중에 보여주기',
    pain_guide_card_hide: '숨기기',
    pain_guide_modal_no_internet_connection:
      '인터넷 연결이 설정되지 않았습니다. 연결이 재설정되면 다시 시도하세요.',

    // Recent Record card
    records_card_title: '최근 기록',
    records_card_button_one: '기록 보기',
    records_card_button_other: '전체 {0}개의 기록 보기',
    records_card_no_locations_text: '입력된 위치 없음',
    records_card_no_activities_text: '입력된 의미 있는 활동 없음',
    records_card_days_without_record_one: '기록 없는 {0}일',
    records_card_days_without_record_other: '기록 없는 {0}일',
    records_card_since_days_without_record_one: '마지막 기록 이후 {0}일',
    records_card_since_days_without_record_other: '마지막 기록 이후 {0}일',
    records_card_to_time_format: '{0} ~ {1}',
    records_card_to_date_format: '{0}\n~\n{1}',
    records_card_all_day_label: shared('All Day', 'ko'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'ko'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'ko',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'ko'),
    records_card_severity: '심각도',
    records_card_no_daily_reflection: '일일 반성',
    records_no_pain_record: '통증 기록 없음',
    records_card_number_of_pain_record: '{0}건의 통증 기록',
    records_card_number_of_pain_records: '{0}건의 통증 기록',
    records_card_no_recent_records:
      '추가된 기록이 없습니다. 슬라이드를 통해 첫 기록을 추가하세요.',
    records_card_sync_button_text: '동기화',
    records_card_factor_alleviating: shared('Better', 'ko'),
    records_card_medication_generic: shared('Generic', 'ko'),
    records_card_factor_ineffective: shared('No Change', 'ko'),
    records_card_factor_aggravating: shared('Worse', 'ko'),

    // Medications card
    medication_card_title: shared('Medications', 'ko'),
    medication_card_generic: shared('Generic', 'ko'),
    medication_card_button_one: '의약품 보기',
    medication_card_button_other: '전체 {0}개의 약품 보기',
    medication_card_better: shared('Better', 'ko') + ' {0}%',
    medication_card_neutral: shared('No Change', 'ko') + ' {0}%',
    medication_card_worse: shared('Worse', 'ko') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'ko') + ':',
    medications_card_end_date: shared('End Date', 'ko') + ':',
    medications_card_no_medications:
      '추가된 약물이 없습니다. 약물을 복용한다면, 해당 약물을 추가하여 약물의 영향을 추적하세요.',
    medications_card_add_medication: '약 추가',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'ko'),
    conditions_card_button_one: '통증 상태 보기',
    conditions_card_button_other: '{0}개의 모든 통증 상태 보기',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'ko') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'ko') + ':',
    conditions_card_no_conditions:
      '추가된 질환이 없습니다. 질환을 추가하면 진료팀의 보고 완성도를 개선할 것입니다.',
    conditions_card_add_pain_condition: '통증 상태를 추가합니다',

    // Rate Me card
    rate_me_title: 'Manage My Pain을 좋아하십니까?',
    rate_me_step_yes: '평점을 남겨 주시겠습니까?',
    rate_me_step_no: '개선할 수 있는 피드백을 주시겠습니까?',
    rate_me_card_yes_button: '네!',
    rate_me_card_no_button: '별로요',
    rate_me_step_yes_card_yes_button: '물론이죠',
    rate_me_step_yes_card_no_button: '아니요, 됐습니다',
    rate_me_step_no_card_yes_button: '물론이죠',
    rate_me_step_no_card_no_button: '아니요, 됐습니다',

    // Sign Up Card
    sign_up_card_title: '계정을 등록하여 데이터를 보호하고 보고서를 만듭니다.',
    sign_up_card_yes: '물론이죠',
    sign_up_card_no: '아니요, 됐습니다',

    // Questionnaires Card
    questionnaires_card_title_one:
      '완료해야 할 설문지가 있습니다. 지금 완료하겠습니까?',
    questionnaires_card_title_other:
      '완료해야 할 {0}개의 설문지가 있습니다. 지금 완료하겠습니까?',
    questionnaires_card_yes: '네, 지금 완료합니다',
    questionnaires_card_no: '아니요, 나중에 하겠습니다',

    dark_mode_introduction_title:
      '모두의 성원에 힘입어 다크 모드를 도입했습니다.',
    dark_mode_introduction_subtitle:
      '이제 앱 테마를 다크 모드로 전환할 수 있습니다. 눈을 편안하게 해주며 배터리 수명을 늘려주고 숙면에도 좋은 다크 모드를 사용해 보세요.',
    dark_mode_introduction_dark_mode_button: '다크 테마 사용하기',
    dark_mode_introduction_light_mode_button: '라이트 테마 사용하기',
    dark_mode_introduction_system_mode_button: '기기 기본 설정 유지하기',
  },

  ru: {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'ru',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'ru'),
    main_screen_tutorial_step_1_label:
      'Передвиньте слайдер, чтобы оценить силу боли и добавить первую запись',
    main_screen_tutorial_step_2_label:
      'Нажмите «Добавить запись о боли», чтобы описать свою боль',
    main_screen_sync_success_toast_message: shared('sync_success', 'ru'),
    main_screen_record_your_pain: 'Запишите боль',
    main_screen_click_add_pain_record:
      'Нажмите "{0}", чтобы подробнее описать свою боль',

    // PainGuide card
    pain_guide_card_title: 'Всё о боли',
    pain_guide_card_no_internet_text:
      'Не удается подключиться к интернету. Повторите попытку, когда связь восстановится.',
    pain_guide_card_retry_button: shared('Retry', 'ru'),
    pain_guide_card_register_or_login:
      'Зарегистрируйте аккаунт или войдите в него, чтобы защитить свои данные, создавать отчеты и получить доступ к разделу «Всё о боли».',
    pain_guide_card_register_button: 'Регистрация',
    pain_guide_login_button: 'Войти',
    pain_guide_card_featured_content_title: 'Рекомендованные материалы',
    pain_guide_card_from_home: shared('Home', 'ru'),

    pain_guide_card_introduction_title: 'Представляем раздел «Всё о боли».',
    pain_guide_card_introduction_message:
      'Раздел «Всё о боли» создан специалистами и содержит полезную информацию о боли и способах борьбы с ней. Зайти в него можно прямо с главного экрана.',
    pain_guide_card_introduction_open_button: 'Посмотреть «Всё о боли»',
    pain_guide_card_introduction_later_button: 'Посмотреть позже',

    pain_guide_card_only_english_text:
      'Этот материал доступен только на английском языке. Хотите скрыть этот раздел, если приложение используется на другом языке?',
    pain_guide_card_explore_more_button: 'Подробнее',
    pain_guide_card_show_anyway: 'Показывать всегда',
    pain_guide_card_hide: 'Скрыть',
    pain_guide_modal_no_internet_connection:
      'Не удается подключиться к интернету. Повторите попытку, когда связь восстановится.',

    // Recent Record card
    records_card_title: 'Последние записи',
    records_card_button_one: 'Просмотреть запись',
    records_card_button_other: 'Просмотреть все записи: {0}',
    records_card_no_locations_text: 'Не указана локализация',
    records_card_no_activities_text: 'Значимые действия не указаны',
    records_card_days_without_record_one: 'Записей не было {0} день',
    records_card_days_without_record_other: 'Записей не было {0} дн.',
    records_card_since_days_without_record_one: '{0} дн. с прошлой записи',
    records_card_since_days_without_record_other: '{0} дн. с прошлой записи',
    records_card_to_time_format: 'с {0} до {1}',
    records_card_to_date_format: 'c {0}\nпо {1}',
    records_card_all_day_label: shared('All Day', 'ru'),
    records_card_daily_reflection_score: shared('Daily Reflection Score', 'ru'),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'ru',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'ru'),
    records_card_severity: 'Интенсивность',
    records_card_no_daily_reflection: 'Рефлексии за день нет',
    records_no_pain_record: 'Записи о боли нет',
    records_card_number_of_pain_record: '{0} запись о боли',
    records_card_number_of_pain_records: 'Записей о боли: {0}',
    records_card_no_recent_records:
      'Записи не добавлены. Добавьте свою первую запись с помощью ползунка.',
    records_card_sync_button_text: 'Синхрон',
    records_card_factor_alleviating: shared('Better', 'ru'),
    records_card_medication_generic: shared('Generic', 'ru'),
    records_card_factor_ineffective: shared('No Change', 'ru'),
    records_card_factor_aggravating: shared('Worse', 'ru'),

    // Medications card
    medication_card_title: shared('Medications', 'ru'),
    medication_card_generic: shared('Generic', 'ru'),
    medication_card_button_one: 'Показать препарат',
    medication_card_button_other: 'Показать все препараты: {0}',
    medication_card_better: shared('Better', 'ru') + ' {0}%',
    medication_card_neutral: shared('No Change', 'ru') + ' {0}%',
    medication_card_worse: shared('Worse', 'ru') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'ru') + ':',
    medications_card_end_date: shared('End Date', 'ru') + ':',
    medications_card_no_medications:
      'Препараты не добавлены. Если вы принимаете лекарства, добавив их, можно отслеживать их воздействие.',
    medications_card_add_medication: 'Добавить препарат',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'ru'),
    conditions_card_button_one: 'Показать болевой синдром',
    conditions_card_button_other: 'Показать все болевые синдромы: {0}',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'ru') + ':',
    conditions_card_first_symptom: shared('Date of First Symptom', 'ru') + ':',
    conditions_card_no_conditions:
      'Болевые синдромы не добавлены. Добавление синдромов повысит точность и полезность отчетов для ваших врачей.',
    conditions_card_add_pain_condition: 'Добавить болевой синдром',

    // Rate Me card
    rate_me_title: 'Вам нравится приложение Manage My Pain?',
    rate_me_step_yes: 'Хотите оценить наше приложение?',
    rate_me_step_no:
      'Хотите написать отзыв и подсказать, как мы можем стать лучше?',
    rate_me_card_yes_button: 'Да!',
    rate_me_card_no_button: 'Не очень',
    rate_me_step_yes_card_yes_button: 'Да, без проблем',
    rate_me_step_yes_card_no_button: 'Не сейчас',
    rate_me_step_no_card_yes_button: 'Да, без проблем',
    rate_me_step_no_card_no_button: 'Не сейчас',

    // Sign Up Card
    sign_up_card_title:
      'Зарегистрируйте аккаунт, чтобы сохранить свои данные и создавать отчеты',
    sign_up_card_yes: 'Да, без проблем',
    sign_up_card_no: 'Не сейчас',

    // Questionnaires Card
    questionnaires_card_title_one:
      'У вас есть опросник, который следует заполнить. Хотите сделать это сейчас?',
    questionnaires_card_title_other:
      'У вас есть опросники ({0}), которые следует заполнить. Хотите сделать это сейчас?',
    questionnaires_card_yes: 'Да, заполнить сейчас',
    questionnaires_card_no: 'Нет, напомнить позже',

    dark_mode_introduction_title:
      'Вы просили и мы прислушались: встречайте темную тему!',
    dark_mode_introduction_subtitle:
      'Можете переключиться на темный режим. Использование темной темы снижает нагрузку на глаза, экономит заряд батареи и улучшает сон.',
    dark_mode_introduction_dark_mode_button: 'Включить темную тему',
    dark_mode_introduction_light_mode_button: 'Включить светлую тему',
    dark_mode_introduction_system_mode_button: 'Оставить системную',
  },

  'zh-rCN': {
    // Slider
    main_screen_add_daily_reflection_button: shared(
      'Add Daily Reflection',
      'zh-rCN',
    ),
    main_screen_add_record_button: shared('Add Pain Record', 'zh-rCN'),
    main_screen_tutorial_step_1_label:
      '移动滑块以评定您的痛苦并添加您的第一条记录',
    main_screen_tutorial_step_2_label: '现在单击“添加疼痛记录”来描述您的疼痛',
    main_screen_sync_success_toast_message: shared('sync_success', 'zh-rCN'),
    main_screen_record_your_pain: '记录您的痛苦病情',
    main_screen_click_add_pain_record: '点击"{0}"，详细描述您的病情',

    // PainGuide card
    pain_guide_card_title: '疼痛指南',
    pain_guide_card_no_internet_text:
      '无法建立互联网连接。 重新建立连接时重试。',
    pain_guide_card_retry_button: shared('Retry', 'zh-rCN'),
    pain_guide_card_register_or_login:
      '注册帐户或登录后，即可保护您的数据、生成报告并查看我们的疼痛管理指南。',
    pain_guide_card_register_button: '免费注册',
    pain_guide_login_button: '登录',
    pain_guide_card_featured_content_title: '推荐内容',
    pain_guide_card_from_home: shared('Home', 'zh-rCN'),

    pain_guide_card_introduction_title: '介绍疼痛指南',
    pain_guide_card_introduction_message:
      '我们的疼痛指南由疼痛专家创建，为您提供有关疼痛及其管理方法的有用信息。您可直接从主屏幕查看疼痛指南。',
    pain_guide_card_introduction_open_button: '立即查看疼痛指南',
    pain_guide_card_introduction_later_button: '稍后查看',

    pain_guide_card_only_english_text:
      '此内容仅提供英文版本。当应用程序以不同的语言使用时，您想隐藏此部分吗？',
    pain_guide_card_explore_more_button: '查看更多',
    pain_guide_card_show_anyway: '显示',
    pain_guide_card_hide: '隐藏',
    pain_guide_modal_no_internet_connection:
      '无法建立互联网连接。 重新建立连接时重试。',

    // Recent Record card
    records_card_title: '近期记录',
    records_card_button_one: '查看记录',
    records_card_button_other: '查看所有{0}条记录',
    records_card_no_locations_text: '没有输入位置',
    records_card_no_activities_text: '没有输入有意义的活动',
    records_card_days_without_record_one: '{0}天没有记录',
    records_card_days_without_record_other: '{0}天没有记录',
    records_card_since_days_without_record_one: '自上次记录以来{0}天',
    records_card_since_days_without_record_other: '自上次记录以来{0}天',
    records_card_to_time_format: '{0}到{1}',
    records_card_to_date_format: '{0}\n到\n{1}',
    records_card_all_day_label: shared('All Day', 'zh-rCN'),
    records_card_daily_reflection_score: shared(
      'Daily Reflection Score',
      'zh-rCN',
    ),
    records_card_meaningful_activities_section_header: shared(
      'Meaningful Activities',
      'zh-rCN',
    ),
    records_card_pain_records_section_header: shared('Pain Records', 'zh-rCN'),
    records_card_severity: '严重程度',
    records_card_no_daily_reflection: '没有每日回顾',
    records_no_pain_record: '尚无疼痛记录',
    records_card_number_of_pain_record: '{0} 条疼痛记录',
    records_card_number_of_pain_records: '{0} 条疼痛记录',
    records_card_no_recent_records:
      '尚未添加任何记录。请使用滑块，添加第一条记录。',
    records_card_sync_button_text: '同步',
    records_card_factor_alleviating: shared('Better', 'zh-rCN'),
    records_card_medication_generic: shared('Generic', 'zh-rCN'),
    records_card_factor_ineffective: shared('No Change', 'zh-rCN'),
    records_card_factor_aggravating: shared('Worse', 'zh-rCN'),

    // Medications card
    medication_card_title: shared('Medications', 'zh-rCN'),
    medication_card_generic: shared('Generic', 'zh-rCN'),
    medication_card_button_one: '查看药物',
    medication_card_button_other: '查看所有{0}种药物',
    medication_card_better: shared('Better', 'zh-rCN') + ' {0}%',
    medication_card_neutral: shared('No Change', 'zh-rCN') + ' {0}%',
    medication_card_worse: shared('Worse', 'zh-rCN') + ' {0}%',
    medications_card_start_date: shared('Start Date', 'zh-rCN') + ':',
    medications_card_end_date: shared('End Date', 'zh-rCN') + ':',
    medications_card_no_medications:
      '尚未添加任何药物。如果您服用药物，添加记录可以让您跟踪药物的效力。',
    medications_card_add_medication: '添加药物',

    // Conditions card
    conditions_card_title: shared('Pain Conditions', 'zh-rCN'),
    conditions_card_button_one: '查看疼痛病情',
    conditions_card_button_other: '查看所有{0}项疼痛病情',
    conditions_card_diagnosed: shared('Date of Diagnosis', 'zh-rCN') + ':',
    conditions_card_first_symptom:
      shared('Date of First Symptom', 'zh-rCN') + ':',
    conditions_card_no_conditions:
      '尚未添加任何疼痛病情。添加疼痛病情将提高护理团队报告的完整性。',
    conditions_card_add_pain_condition: '添加疼痛病情',

    // Rate Me card
    rate_me_title: '您喜欢Manage My Pain吗？',
    rate_me_step_yes: '能请您给我们评分吗？',
    rate_me_step_no: '能请您给我们一些反馈意见以便我们改进吗？',
    rate_me_card_yes_button: '喜欢!',
    rate_me_card_no_button: '不喜欢',
    rate_me_step_yes_card_yes_button: '好，没问题',
    rate_me_step_yes_card_no_button: '不用了，谢谢',
    rate_me_step_no_card_yes_button: '好，没问题',
    rate_me_step_no_card_no_button: '不用了，谢谢',

    // Sign Up Card
    sign_up_card_title: '马上注册帐户，即可保护数据和创建报告',
    sign_up_card_yes: '好，没问题',
    sign_up_card_no: '不用了，谢谢',

    // Questionnaires Card
    questionnaires_card_title_one: '你需要填写一份问卷。你现在要填写吗?',
    questionnaires_card_title_other: '你需要填写 {0} 份问卷。你现在要填写吗?',
    questionnaires_card_yes: '是，立即完成',
    questionnaires_card_no: '否，稍后提醒我',

    dark_mode_introduction_title: '我们聆听您的需求，并带来了深色模式',
    dark_mode_introduction_subtitle:
      '您现在可以切换为深色模式。深色模式更容易护眼，更节省电量，并且有利于入睡。',
    dark_mode_introduction_dark_mode_button: '使用深色模式',
    dark_mode_introduction_light_mode_button: '使用浅色模式',
    dark_mode_introduction_system_mode_button: '保持设备默认设置',
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
