// @flow
import * as types from './actiontypes';
import { trackGoal } from '../../shared/utilities/gatracker';
import { sync } from '../Sync/actions';
import { trackAddCustomValue } from '../../shared/utilities/answers';
import { apiError, checkToken } from '../Api/actions';
import * as MMPApi from 'apiclient';
import type { FieldTypes } from './types';
import strings from './strings';
import medsStrings from '../Meds/strings';
import type { FieldPlural } from './constants';
import { getLang } from '../../shared/utilities/getLang';
import { getStrengthUnits } from '../Meds/utils';

export const getSectionHeader = (
  field: FieldTypes,
  fieldType: FieldPlural,
): string => {
  // field is in FIELDS_ALL, or also can be 'timing' and 'notes'
  // field type is one of 'plural', 'singular', 'secondary' or undefined
  if (fieldType) {
    return (
      strings['constants_' + field + '_section_header_' + fieldType] ||
      strings['constants_' + field + 's_section_header_' + fieldType] ||
      field
    );
  }
  return (
    strings['constants_' + field + '_section_header'] ||
    strings['constants_' + field + 's_section_header'] ||
    field
  );
};

export function updateCustomLists() {
  return (dispatch) => {
    return checkToken().then(() => {
      dispatch({
        type: types.SYNC_CUSTOM_LISTS,
      });
      var api = new MMPApi.RecordCallsApi();
      var params = MMPApi.GetRecordParams.constructFromObject({
        type: 'user_preferences_record',
      });

      return api.syncGetRecordPost(getLang(), params).then(
        (result) => {
          dispatch({
            type: types.SYNC_CUSTOM_LISTS_SUCCESS,
            custom_lists: result.content,
          });
          return Promise.resolve(result);
        },
        (error) => {
          let err = apiError(error, types.SYNC_CUSTOM_LISTS, updateCustomLists);
          return dispatch(err);
        },
      );
    });
  };
}

export function changePositions(fieldType, fields) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_POSITIONS,
      payload: {
        fieldType: fieldType,
        fields: fields,
      },
    });
  };
}

export function changeShow(fieldType, fieldId, show) {
  return (dispatch) => {
    dispatch({
      type: types.CHANGE_SHOW,
      payload: {
        fieldType: fieldType,
        fieldId: fieldId,
        show: show,
      },
    });
    return Promise.resolve();
  };
}

export function addField(fieldType, fieldName) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_FIELD,
      payload: {
        fieldType: fieldType,
        fieldName: fieldName,
      },
    });
    trackGoal('Add Field', fieldType);
    trackAddCustomValue(fieldType);
    dispatch(sync());
    return Promise.resolve();
  };
}

export function editField(fieldType, fieldId, fieldName) {
  return (dispatch) => {
    dispatch({
      type: types.EDIT_FIELD,
      payload: {
        fieldType: fieldType,
        fieldId: fieldId,
        fieldName: fieldName,
      },
    });
    dispatch(sync());
    return Promise.resolve();
  };
}

export function deleteField(fieldType, fieldId) {
  return (dispatch) => {
    dispatch({
      type: types.DELETE_FIELD,
      payload: {
        fieldType: fieldType,
        fieldId: fieldId,
      },
    });
    if (fieldType === 'alleviating_factor') {
      dispatch({
        type: types.DELETE_FIELD,
        payload: {
          fieldType: 'ineffective_factor',
          fieldId: fieldId,
        },
      });
    }
    dispatch(sync());
    return Promise.resolve();
  };
}

// Not for export
const prepareFactor = (medication, factor_name) => {
  let translations;
  if (medication.common_name_translations) {
    translations = medication.common_name_translations?.map((translation) => {
      return {
        language: translation.language,
        value:
          translation.value +
          ' (' +
          (medication.brand_name === 'Generic'
            ? medsStrings.medication_list_generic_label
            : medication.brand_name) +
          ')' +
          (medication.strength
            ? ' - ' +
              medication.strength +
              getStrengthUnits(medication.strength_units)
            : ''),
      };
    });
  } else if (medication.common_name_translation) {
    translations = [
      {
        language: getLang(),
        value:
          medication.common_name_translation +
          ' (' +
          (medication.brand_name === 'Generic'
            ? medsStrings.medication_list_generic_label
            : medication.brand_name) +
          ')' +
          (medication.strength
            ? ' - ' +
              medication.strength +
              getStrengthUnits(medication.strength_units)
            : ''),
      },
    ];
  }
  return {
    name:
      medication.common_name +
      ' (' +
      medication.brand_name +
      ')' +
      (medication.strength
        ? ' - ' + medication.strength + medication.strength_units
        : ''),
    translations: translations,
    fid: medication.fid,
    id: medication.id,
    show: 1,
    type: factor_name,
    systemDefault: false,
    isMedication: true,
    position: 0,
    medication: [
      {
        medication: medication.common_name,
        brand: medication.brand_name,
        strength: medication.strength
          ? medication.strength + getStrengthUnits(medication.strength_units)
          : getStrengthUnits(medication.strength_units),
        units: medication.dosage_units,
        dosages: medication.dosage.map((dosage) => dosage.value),
        nid: medication.nid,
        icon_code: null,
        icon_colour: null,
        offline: medication.offline,
      },
    ],
  };
};

export function addOfflineMedicationToFields(medication) {
  const alleviating_med = prepareFactor(medication, 'alleviating_factor');
  const aggravating_med = prepareFactor(medication, 'aggravating_factor');
  const ineffective_med = prepareFactor(medication, 'ineffective_factor');

  return {
    type: types.ADD_OFFLINE_MEDICATION_TO_FIELDS,
    payload: { alleviating_med, aggravating_med, ineffective_med },
  };
}

export function editOfflineMedicationInFields(medication) {
  const alleviating_med = prepareFactor(medication, 'alleviating_factor');
  const aggravating_med = prepareFactor(medication, 'aggravating_factor');
  const ineffective_med = prepareFactor(medication, 'ineffective_factor');

  return {
    type: types.EDIT_OFFLINE_MEDICATION_IN_FIELDS,
    payload: {
      nid: medication.nid,
      alleviating_med,
      aggravating_med,
      ineffective_med,
    },
  };
}

export function deleteOfflineMedicationFromFields(nid) {
  return {
    type: types.DELETE_OFFLINE_MEDICATION_FROM_FIELDS,
    payload: nid,
  };
}

export function addOfflineMedicationsArrayToFields(medications) {
  const alleviating_meds = [];
  const aggravating_meds = [];
  const ineffective_meds = [];

  medications.forEach((med) => {
    alleviating_meds.push(prepareFactor(med, 'alleviating_factor'));
    aggravating_meds.push(prepareFactor(med, 'aggravating_factor'));
    ineffective_meds.push(prepareFactor(med, 'ineffective_factor'));
  });

  return {
    type: types.ADD_OFFLINE_MEDICATIONS_ARRAY_TO_FIELDS,
    payload: { alleviating_meds, aggravating_meds, ineffective_meds },
  };
}

export function addNewDefaultField(fieldType, field) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_NEW_DEFAULT_FIELD,
      payload: {
        fieldType,
        field,
      },
    });
  };
}
