import * as types from './actiontypes';
import { INSTITUTION_CONFIG_RETRIEVED } from './actiontypes';
import { setAppLanguage } from '../../shared/utilities/languageutils';
import { cancelAllNotifications, refreshAllReminders } from './notifications';
import { apiError, checkToken } from '../Api/actions';
import { updateMyProfile } from '../Profile/actions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { designConstants } from '../../design/styles';
import getConfig from '../../shared/utilities/getConfig';
import * as MMPApi from 'apiclient';
import { getLang } from '../../shared/utilities/getLang';

export function disableIdleTimer(disableState) {
  return {
    type: types.SETTINGS_DISABLE_IDLE_TIMER,
    payload: disableState,
  };
}

export function updateEmailSubscriptions(subscriptions) {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    const params = subscriptions.map((setting) =>
      MMPApi.UpdateEmailSubscriptionsParams.constructFromObject(setting),
    );
    return api
      .coreUpdateEmailSubscriptionsPost(getLang(), params)
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        console.log('APIERR???', error);
        return dispatch(apiError(error, types.GET_USER_EMAIL_SUBSCRIPTIONS));
      });
  };
}

export function loadSubscriptionSettings() {
  return (dispatch) => {
    let api = new MMPApi.AuthCallsApi();
    dispatch({ type: types.GET_USER_EMAIL_SUBSCRIPTIONS });
    return api
      .coreGetUserEmailSubscriptionsPost(getLang())
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return dispatch(apiError(error, types.GET_USER_EMAIL_SUBSCRIPTIONS));
      });
  };
}

export function getConfigForInstitution(institution_id) {
  return (dispatch) => {
    let api = new MMPApi.GetInstitutionDataApi();
    api.apiClient.basePath = getConfig('MMP_API_SERVER');
    const params = MMPApi.GetInstitutionConfigParams.constructFromObject({
      nid: institution_id,
    });
    return api
      .institutionsGetInstitutionConfigPost(getLang(), params)
      .then((result) => {
        dispatch({
          type: INSTITUTION_CONFIG_RETRIEVED,
          payload: result,
        });
        return Promise.resolve(result);
      });
  };
}

export function getTranslationsForInstitution(institution_id) {
  let api = new MMPApi.AuthCallsApi();
  api.apiClient.basePath = getConfig('MMP_API_SERVER');
  const params = {
    lang: getLang(),
    institution: institution_id,
  };

  return api.coreGetTranslationGet('webapp', params).then((result) => {
    // result = { ...result, settings_scene_header: 'BLABLABLA' + getLang() };
    console.log('translations got', result);
    return Promise.resolve(result);
  });
}

export function useLocale() {
  const currentLang = useSelector((state) => state.settings.language);
  let lang = currentLang;
  if (lang === 'default') {
    if (designConstants.isWeb) {
      lang = navigator.language || navigator.userLanguage || 'en-US';
    } else {
      lang = require('react-native-localize').getLocales()[0].languageTag;
    }
  }
  const [locale, setLocale] = useState(lang);

  useEffect(() => {
    let langToSet = currentLang;
    if (langToSet === 'default') {
      if (designConstants.isWeb) {
        langToSet = navigator.language || navigator.userLanguage || 'en-US';
      } else {
        langToSet = require('react-native-localize').getLocales()[0]
          .languageTag;
      }
    }
    setLocale(langToSet);
  }, [currentLang]);
  return locale;
}

export function setLanguage(language) {
  setAppLanguage(language);
  return (dispatch, getState) => {
    dispatch({
      type: types.SETTINGS_SET_LANGUAGE,
      payload: language,
    });
    //Change Pain COnditions names
    checkToken()
      .then(() => {
        return dispatch(updateMyProfile());
      })
      .catch((e) => {
        //Don't need to update profile if already logged out.
      });

    return Promise.resolve();
  };
}

export function switchReportUsage() {
  return {
    type: types.SETTINGS_SWITCH_REPORT_USAGE,
  };
}

export function switchReportCrash() {
  return {
    type: types.SETTINGS_SWITCH_REPORT_CRASH,
  };
}

export function switchReminderEnabled(reminder_id, enabled) {
  return (dispatch) => {
    dispatch({
      type: types.SETTINGS_SWITCH_REMINDER_ENABLED,
      payload: {
        id: reminder_id,
        enabled,
      },
    });

    refreshAllReminders();
  };
}

export function editReminder(reminderToEdit) {
  return (dispatch, getState) => {
    const reminders = getState().settings.reminders;
    let reminder = null;
    if (reminderToEdit.id === null) {
      let id = 1;
      reminders.forEach((_reminder) => {
        if (_reminder.id >= id) {
          id = _reminder.id + 1;
        }
      });
      reminder = { ...reminderToEdit, id };
    } else {
      reminder = { ...reminderToEdit };
    }
    dispatch({
      type: types.SETTINGS_EDIT_REMINDER,
      payload: { ...reminder },
    });
    refreshAllReminders();
    return Promise.resolve(reminder);
  };
}

export function deleteReminder(reminder_id) {
  return (dispatch) => {
    cancelAllNotifications();
    dispatch({ type: types.SETTINGS_DELETE_REMINDER, payload: reminder_id });
    refreshAllReminders();
    return Promise.resolve();
  };
}

export function debugRemindersToggle() {
  return {
    type: types.SETTINGS_DEBUG_REMINDERS_TOGGLE,
  };
}

export function debugChangeReminderCreatedAt(payload) {
  return {
    type: types.SETTINGS_DEBUG_CHANGE_CREATED_AT,
    payload,
  };
}

export function iOSNotificationsAlertWasShown() {
  return { type: types.SETTINGS_IOS_NOTIFICATIONS_SYSTEM_ALERT_WAS_SHOWN };
}

export function setDarkModeIntroductionShow(value) {
  return {
    type: types.SETTINGS_SET_DARK_MODE_INTRODUCTION_SHOW,
    payload: value,
  };
}
