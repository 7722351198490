import { QUESTIONNAIRES_CARD } from '../../modules/Feed/reducer';

export default (state) => {
  const cards = state.feed.cards;
  if (!cards.some((card) => card.type === QUESTIONNAIRES_CARD)) {
    const newCards = [
      ...cards,
      { type: QUESTIONNAIRES_CARD, priority: 12, canHide: true },
    ];
    state = { ...state, feed: { ...state.feed, cards: newCards } };
    return state;
  }
  return state;
};
