import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { View } from 'react-native';
import { useLocale } from '../../Settings/actions';
import Moment from 'moment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertDialog } from '../../../design/components/Alert';

export default (props) => {
  const locale = useLocale();
  Moment.locale(locale);
  return (
    <View style={{ flex: 1, alignSelf: 'stretch' }}>
      <LocalizationProvider dateAdapter={AdapterMoment} {...props} />
      <ToastContainer position={'bottom-center'} />
      <AlertDialog />
    </View>
  );
};
