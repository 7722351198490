let dateFormats = {
  full: 'LLLL',
  long: 'LLL',
  medium: 'LL',
  short: 'L',
};

let timeFormats = {
  full: 'LTS',
  long: 'LTS',
  medium: 'LT',
  short: 'LT',
};

function setFormats(date, time) {
  dateFormats = date;
  timeFormats = time;
  Object.keys(dateFormats).forEach((key) => {
    dateFormats[key] = dateFormats[key].toUpperCase();
  });
}

function getDateFormat(type) {
  return dateFormats[type] || dateFormats.medium;
}

function getTimeFormat(type) {
  return timeFormats[type] || timeFormats.medium;
}

function getDateAndMonthFormat() {
  if (dateFormats.medium.indexOf('M') > dateFormats.medium.indexOf('D')) {
    return 'DD MMM';
  } else {
    return 'MMM DD';
  }
}
export { getDateFormat, getTimeFormat, getDateAndMonthFormat, setFormats };
