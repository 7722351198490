import React from 'react';
import { View } from 'react-native';
import { Text } from '../../../design/components';
import getConfig from '../../../shared/utilities/getConfig';
import Toast from '../../../design/components/Toast';
import strings from '../strings';

export default function RedirectingScreen(props) {
  setTimeout(() => {
    window.location.href = getConfig('MMP_LINK_HOME_PAGE');
  }, 5000);
  Toast.show({
    text: strings.redirecting_string_redirecting_toast,
  });
  return (
    <View
      style={{
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text>{strings.redirecting_string_redirecting_title}</Text>
    </View>
  );
}
