// These services are created to prepare data stored in state for using in data parser from supporting library
import Moment from 'moment/moment';
import { getOffset } from '../../shared/utilities/timeutils';
import strings from './strings';
import { FIELDS_ALL } from '../Fields/constants';
import { designConstants } from '../../design/styles';
import parser from 'data-parser';
import { formatMedicationField } from './utils';

const offline_user_id = 'offline_user';

export const recordsDataTransformer = (records, fields, patient_id) => {
  const appFields = {};
  const language = strings.getLanguage();
  FIELDS_ALL.forEach((field) => {
    appFields[field] = {};
    fields[field] &&
      fields[field].forEach((f) => {
        if (f) {
          appFields[field][f.id] = f;
        }
      });
  });

  const mapper = (record, name) => {
    return record && record.fields && record.fields[name]
      ? record.fields[name]
          .map((id) => {
            return appFields[name] && appFields[name][id];
          })
          .filter((found) => !!found)
      : [];
  };

  return records.map((record) => {
    const processed_location = mapper(record, 'location').map(
      (found) => found.name,
    );
    const processed_symptom = mapper(record, 'symptom').map(
      (found) => found.name,
    );
    const processed_character = mapper(record, 'character').map(
      (found) => found.name,
    );
    const processed_environment = mapper(record, 'environment').map(
      (found) => found.name,
    );
    const processed_meaningful_activities = mapper(
      record,
      'meaningful_activities',
    ).map((found) => found.name);

    let processed_aggravating_factor_without_medications = mapper(
      record,
      'aggravating_factor',
    ).map((found) => {
      // This array does not contain medications, medications are stored in other place of record
      return {
        value: found.name,
        value_type: found.systemDefault ? 'default' : 'custom',
        fid: found.fid,
        dosage: null,
        dosage_units: null,
        effectiveness: 1,
      };
    });
    let processed_alleviating_factor_without_medications = mapper(
      record,
      'alleviating_factor',
    ).map((found) => {
      // This array does not contain medications, medications are stored in other place of record
      return {
        value: found.name,
        value_type: found.systemDefault ? 'default' : 'custom',
        fid: found.fid,
        dosage: null,
        dosage_units: null,
        effectiveness: 1,
        effective: true,
      };
    });
    // Ineffective factors are the subset of alleviating factors with zero effectivness
    let processed_ineffective_factor_without_medications = mapper(
      record,
      'ineffective_factor',
    ).map((found) => {
      // This array does not contain medications, medications are stored in other place of record
      return {
        value: found.name,
        value_type: found.systemDefault ? 'default' : 'custom',
        fid: found.fid,
        dosage: null,
        dosage_units: null,
        effectiveness: 0,
        effective: false,
      };
    });

    let aggravating_medications = [];
    let alleviating_medications = [];
    let ineffective_medications = [];

    if (record.medications) {
      Object.keys(record.medications).forEach((key) => {
        const medication = record.medications[key];
        if (
          medication &&
          medication.typeOfMedication &&
          fields[medication.typeOfMedication + '_factor']
        ) {
          let medication_found = fields[medication.typeOfMedication + '_factor']
            .filter(
              (field) => field && !!field.isMedication && !!field.medication,
            )
            .find((field) => {
              return field.medication.find(
                (med) => String(med.nid) === String(key),
              );
            });

          if (medication_found) {
            let medication_to_push = {
              value:
                formatMedicationField(medication_found) ||
                medication_found.name,
              value_type: 'medication',
              fid: medication_found.fid,
              dosage: record.medications[key].dosage, // TODO check if this correct, if it is undefined, where should it be taken?
              dosage_units: medication_found.units,
              effectiveness:
                medication.typeOfMedication === 'ineffective' ? 0 : 1,
              effective: medication.typeOfMedication !== 'ineffective',
              dosage_units_translated: medication_found.units, // TODO try to find translate
            };
            medication.typeOfMedication === 'aggravating' &&
              aggravating_medications.push(medication_to_push);
            medication.typeOfMedication === 'alleviating' &&
              alleviating_medications.push(medication_to_push);
            medication.typeOfMedication === 'ineffective' &&
              ineffective_medications.push(medication_to_push);
          }
        }
      });
    }

    const aggravating_factors = [
      ...processed_aggravating_factor_without_medications,
      ...aggravating_medications,
    ];
    const alleviating_factors = [
      ...processed_alleviating_factor_without_medications,
      ...alleviating_medications,
      ...processed_ineffective_factor_without_medications,
      ...ineffective_medications,
    ];

    return {
      pain_record_type: record.type,
      pain_duration_unit: record.lengthOfPainUnit,
      pain_duration: record.lengthOfPainValue,
      severity: record.severity !== undefined ? record.severity : record.score,
      time_offset: record.timeOffset,
      timing: record.recordTime,
      pain_type: record.lengthOfPainType,
      record_id: record.idOnServer || record.id + 1, // TODO check this: or record.id COMPARE
      record_type: record.type,
      patient_id: patient_id || offline_user_id,

      location: processed_location,
      symptom: processed_symptom,
      character: processed_character,
      environment: processed_environment,

      aggravating_factor: aggravating_factors,
      alleviating_factor: alleviating_factors,
      alleviating: {
        effective: alleviating_factors
          .filter((factor) => factor.effectiveness)
          .map((factor) => factor.value),
        ineffective: alleviating_factors
          .filter((factor) => !factor.effectiveness)
          .map((factor) => factor.value),
      },
      aggravating: aggravating_factors.map((factor) => factor.value),
      meaningful_activities: processed_meaningful_activities,

      // These fields are calculated in parser
      final_timing: null,
      date: null,
      days_ago: null,
      ineffective_factor_for_calculation: [],
      ineffective_factor_displayed: [],
      alleviating_factor_for_calculation: [],
      alleviating_factor_displayed: [],
    };
  });
};

// Init parser
//TODO: replace this thenBy with direct dependency in data-parser
const thenBy = require('./graph/dependencies/thenBy');
parser.initDependencies(Moment, thenBy);

export const parserInvocation = (prepared_records, user_id, date, overlays) => {
  const sections = [
    {
      id: 'pain_duration',
    },
    {
      id: 'location',
      value: strings.services_pain_locations_value,
    },
    {
      id: 'character',
      value: strings.services_characteristics_value,
    },
    {
      id: 'pain_type',
      value: strings.services_type_of_pain_value,
    },
    {
      id: 'symptom',
      value: strings.services_other_associated_symptoms_value,
    },
    {
      id: 'alleviating_factor_for_calculation',
      value: strings.services_alleviating_factors_value,
    },
    {
      id: 'aggravating_factor_for_calculation',
      value: strings.services_aggravating_factors_value,
    },
    {
      id: 'ineffective_factor_for_calculation',
      value: strings.services_ineffective_factors_value,
    },
    {
      id: 'environment',
      value: strings.services_environment_value,
    },
    {
      id: 'meaningful_activities',
      value: strings.services_meaningful_activities_value,
    },
  ];
  const participants = [{ uid: user_id || offline_user_id + '' }];
  const follow_up_list_mode = false;
  const calculate_by = 'records';
  const status = undefined;

  let pain_records = [];
  let dates = [];
  const offset = getOffset(Moment().valueOf());
  prepared_records.map((record) => {
    pain_records.push(record);
    dates.push(record.timing + record.time_offset - offset);
  });
  dates.sort();
  const _now = Date.now();
  let _start_date_for_alltime = null;
  let _end_date_for_alltime = null;
  const shiftInMilliseconds = 30 * 24 * 60 * 60 * 1000;
  //    timeCheck(1);

  const defineDate = function (date_milliseconds) {
    const date_object = new Date(date_milliseconds);
    return (
      date_object.getFullYear() +
      '-' +
      ('0' + (date_object.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date_object.getDate()).slice(-2)
    );
  };

  if (date.date_mode === 'alltime') {
    if (dates.length === 0) {
      _start_date_for_alltime = _now - shiftInMilliseconds;
      _end_date_for_alltime = _now;
    } else {
      if (dates[0] >= _now) {
        _start_date_for_alltime = _now;
      } else {
        _start_date_for_alltime = dates[0];
      }
      if (dates[dates.length - 1] <= _now) {
        _end_date_for_alltime = dates[dates.length - 1];
      } else {
        _end_date_for_alltime = _now;
      }
    }
    // It is possible if it is _now date
    if (
      defineDate(_start_date_for_alltime) === defineDate(_end_date_for_alltime)
    ) {
      _start_date_for_alltime = _start_date_for_alltime - shiftInMilliseconds;
    }
  }
  // _start_date_ms is start of day
  const _start_date_ms =
    date.date_mode !== 'alltime' ? date.date : _start_date_for_alltime;
  // _end_date_ms is end of day
  const _end_date_ms =
    date.date_mode !== 'alltime'
      ? date.date_end
        ? date.date_end
        : _now
      : _end_date_for_alltime;

  const start_date = defineDate(_start_date_ms);
  const end_date = defineDate(_end_date_ms);
  // timeCheck(2);

  let compare_to_start_date = null;
  let compare_to_end_date = null;

  if (date.date_mode === '7days' || date.date_mode === '30days') {
    // In case of 7days and 30 days it is needed to take into account additional day
    compare_to_start_date = defineDate(
      Moment(_start_date_ms)
        .add(date.date_mode === '7days' ? -7 : -30, 'days')
        .valueOf(),
    );
    compare_to_end_date = defineDate(_start_date_ms - 1000);
  } else if (date.date_mode === 'alltime') {
    compare_to_start_date = defineDate(_start_date_ms);
    compare_to_end_date = defineDate(_end_date_ms);
  } else {
    compare_to_start_date = defineDate(
      Moment(_start_date_ms)
        .add(
          -Math.round((_end_date_ms - _start_date_ms) / (1000 * 60 * 60 * 24)),
          'days',
        )
        .valueOf(),
    );
    compare_to_end_date = defineDate(_start_date_ms - 1000);
  }
  console.log(
    'PARSERDATE',
    compare_to_start_date,
    compare_to_end_date,
    start_date,
    end_date,
    _start_date_ms,
    _end_date_ms,
  );
  // console.log({
  //     compare_to_start_date,
  //     compare_to_end_date,
  //     divider: '#####',
  //     start_date,
  //     end_date
  // });

  const daterange = date
    ? {
        start_date: start_date,
        end_date: end_date,
        compare_to_start_date: compare_to_start_date,
        compare_to_end_date: compare_to_end_date,
      }
    : {
        start_date: start_date,
        end_date: end_date,
        compare_to_start_date: start_date,
        compare_to_end_date: end_date,
      };
  const calendarDaterange = {
    start_date: date.first_day,
    last: date.last_day,
  };

  console.log('PARSING start');
  const processedData = parser.parseAllRecords(
    pain_records,
    participants,
    status,
    daterange,
    follow_up_list_mode,
    sections,
    calculate_by,
    { overlaySettings: overlays },
    calendarDaterange,
  );
  console.log('parsing finished');
  //    timeCheck(6);

  const severityAndFunctionGraphConfig = {
    id: 'severity', // required!
    disableZoom: true,
    hideLegend: true,
    minY: 0, // min value for Y-axis, if not provided - set to 0
    maxY: 10, // max value for Y-axis, if not provided - set to 10
    numberOfSelectedParticipants: 1,
    dataPointSize: 2,
    lineWidth: 1,
    tooltipTrigger: 'hover', // trigger for a tooltip
    margin: designConstants.isWeb
      ? { top: 20, right: 25, bottom: 40, left: 25 }
      : { top: 20, right: 38, bottom: 40, left: 20 },
    content_Constant: strings.timing_section_type_constant,
    content_Intermittent: strings.timing_section_type_intermittent,
    content_Breakthrough: strings.timing_section_type_breakthrough,
  };

  return {
    processedData,
    daterange,
    severityAndFunctionGraphConfig,
  };
};
