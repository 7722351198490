import React from 'react';
import { designConstants } from '../styles';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import useThemeContext from '../styles/useThemeContext';

const SettingsSection = (props) => {
  const { colors } = useThemeContext();
  return [
    <View style={settingsSectionStyles.content} key={'section'}>
      {props.children}
      {props.withSpacer ? <View style={settingsSectionStyles.spacer} /> : null}
    </View>,
    props.withDivider ? (
      <View
        style={{ borderTopWidth: 1, borderTopColor: colors.lightGray }}
        key={'divider'}
      />
    ) : null,
  ];
};

SettingsSection.propTypes = {
  children: PropTypes.node,
  withSpacer: PropTypes.bool,
  withDivider: PropTypes.bool,
};

SettingsSection.defaultProps = {
  children: null,
  withSpacer: true,
  withDivider: false,
};

const settingsSectionStyles = StyleSheet.create({
  content: {
    paddingVertical: designConstants.paddings.paddingBetweenElementsMedium,
    paddingHorizontal: designConstants.paddings.paddingBetweenElementsLarge,
  },
  sectionLabel: {
    marginTop: designConstants.paddings.paddingBetweenElementsLarge,
    marginBottom: designConstants.paddings.paddingBetweenElementsMedium,
  },
  spacer: {
    height: designConstants.paddings.paddingBetweenElementsLarge,
  },
});

export default SettingsSection;
