// @flow
import { forwardRef, useCallback, useContext } from 'react';
import { ColorSchemeName, useColorScheme } from 'react-native';
import { ThemeContext } from './ThemeProvider';
import lightModeColors from './colors';
import darkModeColors from './colorsDark';

function withTheme(WrappedComponent) {
  return forwardRef((props, ref) => {
    const theme = useThemeContext();
    return <WrappedComponent {...props} ref={ref} theme={theme} />;
  });
}

function useThemeContext() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be within ThemeProvider');
  }

  const { theme, systemColorScheme, loading, setTheme, forceLight } = context;

  if (loading) {
    throw new Error('Tried to use ThemeContext before initialized');
  }

  // if theme is set, use that, otherwise, use system theme
  const colorTheme: NonNullable<ColorSchemeName> = forceLight
    ? 'light'
    : theme ?? systemColorScheme ?? 'light';

  return {
    colors: colorTheme === 'dark' ? darkModeColors : lightModeColors,
    colorTheme,
    theme: theme,
    isSystemTheme: !theme,
    isDark: theme === 'dark',
    systemTheme: systemColorScheme,
    setColorTheme: useCallback(
      (themeName: ColorSchemeName) => setTheme(themeName),
      [setTheme],
    ),
  };
}

export { withTheme, useThemeContext };
export default useThemeContext;
