import React from 'react';
import { createIconSet } from 'react-native-vector-icons';

import glyphMap from '../utilities/mmpGlyphs.json';
import { Text, View } from 'react-native';
import { isNothingNamedField } from '../../modules/Fields/constants';
import { withTheme } from '../../design/styles/useThemeContext';

const iconSet = createIconSet(glyphMap, 'manage-my-pain', 'manage-my-pain.ttf');

export const IconSet = iconSet;

export const Button = iconSet.Button;
export const TabBarItem = iconSet.TabBarItem;
export const TabBarItemIOS = iconSet.TabBarItemIOS;
export const ToolbarAndroid = iconSet.ToolbarAndroid;
export const getImageSource = iconSet.getImageSource;

class MMPIcon extends React.Component {
  renderCircle = (label) => {
    label = label ? label.charAt(0).toUpperCase() : '';
    const color = this.props.color;
    const { colors } = this.props.theme;
    const invertedColor =
      this.props.color === colors.white ? colors.blue : colors.white;
    const size = this.props.size;
    const border = size / 2;
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
            backgroundColor: color,
            width: size,
            height: size,
            borderRadius: border,
          },
          this.props.style,
        ]}
      >
        <Text
          style={{
            fontSize: size / 1.5,
            color: invertedColor,
            width: size,
            textAlign: 'center',
          }}
        >
          {label}
        </Text>
      </View>
    );
  };

  render() {
    if (isNothingNamedField(this.props.label)) {
      return <IconSet {...this.props} name={'none'} />;
    }
    if (!this.props.name) {
      return this.renderCircle(this.props.label);
    }
    return <IconSet {...this.props} />;
  }
}

export default withTheme(MMPIcon);
