export default {
  cardHeader: '#3F3F46',
  cardContainer: '#27272A',
  cardInternalContent: '#3F3F46',
  cardBorder: '#56595C',
  cardLightBorder: '#47474A',

  navItemActive: '#3F3F46',

  textPrimary: '#F1F5F9',
  textSecondary: '#CBD5E1',
  textLink: '#35B2F8',

  buttonBlue: '#0EA5E9',
  buttonFooterBackground: '#57575A',

  pageBackground: '#121212',
  defaultWindowBg: '#121212',
  defaultBackground: '#27272A',
  disabledBackground: '#18181B',
  activeItemBackground: '#3F3F46',

  statusBarBackground: '#303033',
  statusBarBackgroundAndroid: '#303033',
  bottomBarBackground: '#303033',
  menuPopupBackground: '#303033',

  calendarWeekdayHeader: '#57575A',
  calendarDayHeader: '#3F3F46',

  loginButtonBorder: '#0EA5E9',
  facebookButtonColor: '#F1F5F9',

  redResults: '#FF8282',
  greenResults: '#52C975',

  blue: '#137CC3',
  opacityBlue: '#137CC380',
  darkBlue: '#0C74AD',
  darkestBlue: '#0C71AA',
  lightBlue: '#35B2F8',
  skyBlue: '#00C0FA',
  brightBlue: '#036DED',
  red: '#b00020',
  linkButton: '#2F80ED',
  bgDarkBlue: '#0049AC',
  bgLightBlue: '#C5DCFA',
  bgLightGray: '#EEF7FF',

  redDanger: '#FF2929',
  redSoft: '#FF8282',

  // The following colours are used only on the website
  webBgBlue: '#F4F7F9', // - used for background in download section at the homepage
  bgBlue: '#F4F7F9',
  orange: '#F28D10',

  white: '#ffffff',
  whiteBackground: '#F2F2F2',
  black: '#000000',
  lightGreen: '#52C975',

  gray: '#818181', // Usual gray color for texts
  lightGray: '#A5A5A5', // For placeholders and cancel button
  liteGray: '#C6C6C6', // For placeholders and cancel button
  darkGray: '#6F6F6F', // For icon-buttons or long text on white background
  darkGrayRecords: '#56595C',
  grayLine: '#EAEAEA', // Light gray border color,
  grayBackgroundForItem: '#E3E3E3',

  inputsBackground: '#EAEAEA',
  inputsBackgroundLight: '#FAF8F8',
  nativeTouchFeedbackAndroid: '#DFF2FD',

  helpersGray600: '#4B5563',
};
