// Initialize Firebase
import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent as analyticsLogEvent,
  setUserProperties as analyticsSetUserProperties,
} from 'firebase/analytics';
import getConfig from './getConfig';

const firebaseConfig = {
  apiKey: getConfig('MMP_FIREBASE_apiKey'),
  authDomain: getConfig('MMP_FIREBASE_authDomain'),
  databaseURL: getConfig('MMP_FIREBASE_databaseURL'),
  projectId: getConfig('MMP_FIREBASE_projectId'),
  storageBucket: getConfig('MMP_FIREBASE_storageBucket'),
  messagingSenderId: getConfig('MMP_FIREBASE_messagingSenderId'),
  appId: getConfig('MMP_FIREBASE_appId'),
  measurementId: getConfig('MMP_FIREBASE_measurementId'),
};

const isServiceApp = window.location.hostname == 'service.managinglife.com';
let firebase = null;
let analytics = null;
if (!isServiceApp) {
  firebase = initializeApp(firebaseConfig);
  analytics = getAnalytics();
  console.log('FIREBASEINIT', firebase, analytics);
}
export default () => {
  return () => {
    return {
      setUserProperties: (...rest) => {
        if (isServiceApp) {
          return null;
        }
        return analyticsSetUserProperties(analytics, ...rest);
      },
      logEvent: (...rest) => {
        if (isServiceApp) {
          return null;
        }
        return analyticsLogEvent(analytics, ...rest);
      },
    };
  };
};
