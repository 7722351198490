import DeviceInfo from 'react-native-device-info';

let moment = require('moment');
import { formatMoment } from '../../shared/utilities/timeutils';
import { FIELDS } from '../Fields/constants';
import { getDeviceId } from '../Auth/actions';

export function createEmptyReflection() {
  let currentTime = moment();
  let offset = new Date().getTimezoneOffset() * 1000 * 60 * -1;
  let end = currentTime.clone().set({ hour: 12, minute: 0, second: 0 });
  if (currentTime.isBefore(end)) {
    currentTime = currentTime.add(-12, 'hour');
  }
  currentTime = currentTime.valueOf();

  let empty = {
    id: -1,
    score: 0,
    //        createDate: currentTime,
    timeOffset: offset,
    note: '',
    recordTime: currentTime,
    updateDate: moment().valueOf(),
    localUpdated: moment().valueOf(),
    deletedFlag: false,
    device_id: getDeviceId(),
    idOnServer: 0,
    updatedLocally: false,
    type: 'DailyReflection',
    fields: {
      meaningful_activities: [],
    },
  };
  return empty;
}

export function createEmpty() {
  var currentTime = moment().valueOf();
  let empty = {
    id: -1,
    lengthOfPainValue: 0,
    lengthOfPainType: null,
    severity: 0,
    //			createDate: currentTime,
    lengthOfPainUnit: 'Minutes',
    createdAppVersion: 999,
    timeOffset: 55,
    note: '',
    updateDate: currentTime,
    localUpdated: currentTime,
    deletedFlag: false,
    deviceId: global.__REMOTEDEV__ ? '00001' : getDeviceId(),
    idOnServer: 0,
    updatedLocally: false,
    type: 'PainRecord',
    fields: {},
    medications: {},
  };
  FIELDS.forEach((field) => {
    empty.fields[field] = [];
  });
  return empty;
}

export function isAllDayChecked(record) {
  if (!record || !record.recordTime) {
    return false;
  }
  let moment = formatMoment(record.recordTime, record.timeOffset);
  if (!moment || moment == null) {
    return false;
  }
  return (
    moment.format('HH:mm') === '00:00' &&
    record.lengthOfPainValue === 24 * 60 * 60
  );
}

export function isEmpty(record) {
  if (!record) {
    return true;
  }
  if (record.type === 'DailyReflection') {
    let filled =
      record.score > 0 ||
      record.time_set ||
      (record.note && record.note.length > 0) ||
      record.fields.meaningful_activities.length > 0;
    return !filled;
  } else if (record.type === 'PainRecord') {
    let filled =
      record.lengthOfPainValue > 0 ||
      record.time_set ||
      (record.lengthOfPainType && record.lengthOfPainType !== 'none') ||
      record.severity / 10 > 0 ||
      (record.note && record.note.length > 0) > 0 ||
      (record.medications && Object.keys(record.medications).length) > 0;

    FIELDS.forEach((field) => {
      filled = filled || record.fields[field].length > 0;
    });
    return !filled;
  }
  return false;
}

export function isSame(a, b) {
  if (!a || !b) {
    return false;
  }
  if (a.type === 'DailyReflection') {
    let differs =
      a.score != b.score ||
      a.time_set != b.time_set ||
      a.note != b.note ||
      (a.fields.meaningful_activities &&
        (a.fields.meaningful_activities?.length !=
          b.fields.meaningful_activities?.length ||
          a.fields.meaningful_activities?.some(
            (rec, index) => rec != b.fields.meaningful_activities[index],
          )));
    return !differs;
  } else if (a.type === 'PainRecord') {
    let differs =
      a.lengthOfPainValue !== b.lengthOfPainValue ||
      a.time_set != b.time_set ||
      a.lengthOfPainType !== b.lengthOfPainType ||
      a.severity / 10 != b.severity / 10 ||
      a.note != b.note ||
      (a.medications &&
        (Object.keys(a.medications).length !==
          Object.keys(b.medications).length ||
          Object.keys(a.medications).some((med, index) => {
            return a.medications[med] != b.medications[med];
          })));

    if (!differs) {
      FIELDS.forEach((field) => {
        differs =
          differs ||
          (a.fields[field] &&
            (a.fields[field].length !== b.fields[field].length ||
              a.fields[field].some(
                (rec, index) => rec !== b.fields[field][index],
              )));
      });
    }
    return !differs;
  }
  return false;
}

// class Record {
// }
//
// class Field {
//
// }
// Field.schema = {
//     name: 'Field',
//     primaryKey: 'id',
//     properties: {
//         id: {type: 'int'},
//         name: {type: 'string'},
//         show: {type: 'bool'}
//     }
// };
//
// class FieldList {
//
// }
//
// FieldList.schema = {
//     name: 'FieldList',
//     primaryKey: 'type',
//     properties: {
//         type: {type: 'string'},
//         fields: {type: 'list', objectType: 'Field'}
//     }
// };
//
// Record.schema = {
//     name: 'Record',
//     primaryKey: 'id',
//     properties: {
//         id: {type: 'int'},
//         lengthOfPainValue: {type: 'int'},
//         lengthOfPainType: {type: 'string'},
//         severity: {type: 'int'},
//         createDate: {type: 'int'},
//         lengthOfPainUnit: {type: 'string'},
//         createdAppVersion: {type: 'int'},
//         timeOffset: {type: 'int'},
//         recordTime: {type: 'int'},
//         note: {type: 'string'},
//         updateDate: {type: 'int'},
//         deletedFlag: {type: 'bool'},
//         deviceId: {type: 'int'},
//         idOnServer: {type: 'int'},
//         updatedLocally: {type: 'bool'},
//         type: {type: 'string'},
//         fields: {type: 'list', objectType:'FieldList'}
//     }
// };
