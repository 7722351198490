const i18n = require('i18n-js');
export const pluralizeString = (strings, str) => {
  return {
    zero: strings[str + '_zero'],
    one: strings[str + '_one'],
    few: strings[str + '_few'],
    many: strings[str + '_many'],
    other: strings[str + '_other'],
  };
};

export const pluralizeMap = (strings, plurals, translations) => {
  let originalLang = strings.getLanguage();
  const map = strings.getAvailableLanguages().map((lang) => {
    strings.setLanguage(lang);
    return {
      lang,
      values: Object.assign(
        {},
        ...plurals.map((plural) => ({
          [plural]: pluralizeString(strings, plural),
        })),
      ),
    };
  });
  strings.setLanguage(originalLang);
  return Object.assign(
    {},
    ...map.map((item) => ({
      [item.lang]: { ...item.values, ...translations[item.lang] },
    })),
  );
};

export const pluralFunc = (strings, plurals) => {
  i18n.translations = {
    ...i18n.translations,
    ...pluralizeMap(strings, plurals, i18n.translations),
  };
  if (!i18n.pluralization.ru) {
    i18n.pluralization.ru = function (count) {
      var key =
        count % 10 == 1 && count % 100 != 11
          ? ['one']
          : [2, 3, 4].indexOf(count % 10) >= 0 &&
              [12, 13, 14].indexOf(count % 100) < 0
            ? ['few', 'other']
            : count % 10 == 0 ||
                [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
                [11, 12, 13, 14].indexOf(count % 100) >= 0
              ? ['many', 'other']
              : ['other'];
      return key;
    };
  }
  return Object.assign(
    {},
    ...plurals.map((key) => ({
      [key.toString()]: (count, ...args) =>
        strings.formatString(
          i18n.t(key, { count, locale: strings.getLanguage() }),
          ...args,
        ),
    })),
  );
};
