import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import { withTheme } from '../styles/useThemeContext';

// Default button look is short button with capital text
class HighEmphasisButton extends Component {
  onPress = () => {
    if (this.props.disabled) {
      return;
    }
    trackButtonPress('Button ' + (this.props.tag || this.props.text));
    this.props.onPress();
  };

  render() {
    let { long, small, disabled, style, text, ignoreSentenceCase } = this.props;
    const { colors } = this.props.theme;

    let _style = highEmphasisButtonStyles.lightGreenButtonShort;
    small && (_style = highEmphasisButtonStyles.lightGreenButtonSmall);
    long && (_style = highEmphasisButtonStyles.lightGreenButtonLong);

    const Touch = disabled ? View : TouchableNative;
    return (
      <Touch
        onPress={this.onPress}
        {...setTestID(this.props.tag || this.props.text)}
        style={[
          _style,
          { backgroundColor: colors.lightGreen },
          disabled && { backgroundColor: colors.lightGray },
          style,
        ]}
      >
        <Text white style={{ textAlign: 'center' }}>
          {ignoreSentenceCase ? text : toSentenceCase(text)}
        </Text>
      </Touch>
    );
  }
}

const highEmphasisButtonStyles = StyleSheet.create({
  lightGreenButtonShort: {
    width: 125,
    minHeight: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    elevation: 2,
  },
  lightGreenButtonLong: {
    width: 240,
    minHeight: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    elevation: 2,
  },
  lightGreenButtonSmall: {
    width: 109,
    minHeight: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    elevation: 2,
  },
});

HighEmphasisButton.propTypes = {
  long: PropTypes.bool,
  small: PropTypes.bool,
  caps: PropTypes.bool,
  disabled: PropTypes.bool,
  // style: ViewPropTypes.style,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ignoreSentenceCase: PropTypes.bool,
};

HighEmphasisButton.defaultProps = {
  long: false,
  small: false,
  disabled: false,
  caps: false,
  style: {},
  onPress: () => null,
  text: '',
  ignoreSentenceCase: false,
};

export default withTheme(HighEmphasisButton);
