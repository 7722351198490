import {
  DELETE_RECORD,
  SAVE_DAILY_REFLECTION_SUCCESS,
  SAVE_RECORD_SUCCESS,
  SYNC_RECORDS_SUCCESS,
} from '../Records/actiontypes';
import { Platform } from 'react-native';
import { refreshAllReminders } from './notifications';
import Moment from 'moment';
import { formatMoment, getOffset } from '../../shared/utilities/timeutils';
import { designConstants } from '../../design/styles/constants';

export const getConsecutiveDays = (records) => {
  let today = Moment().endOf('day');
  let offset = getOffset(today.valueOf());

  let dayDiffMap = records.map((record) => {
    return today.diff(
      Moment(record.recordTime + record.timeOffset - offset),
      'days',
    );
  });

  //    console.log('diffs', dayDiffMap)
  dayDiffMap = dayDiffMap.sort((a, b) => a - b);
  //    console.log('sorted', dayDiffMap)
  let current = 0;
  for (let day of dayDiffMap) {
    if (day - current > 1) {
      return current;
    }
    current = day;
  }
  return current;
};

const remindersMiddleware = (store) => (next) => (action) => {
  next(action);
  if (!designConstants.isWeb) {
    switch (action.type) {
      case SAVE_RECORD_SUCCESS:
      case DELETE_RECORD:
      case SAVE_DAILY_REFLECTION_SUCCESS:
      case SYNC_RECORDS_SUCCESS:
        refreshAllReminders();
        break;
      default:
        break;
    }
  }
};

export default remindersMiddleware;
