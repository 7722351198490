// This file serves every action which could be performed by both Views and Logic

//Api types
export const API_ERROR = 'API_ERROR';

//Auth types
export const LOGOUT = 'LOGOUT';

//Profile types
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const SAVE_MY_PROFILE = 'SAVE_MY_PROFILE';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const SAVE_MY_PROFILE_SUCCESS = 'SAVE_MY_PROFILE_SUCCESS';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_GENDER = 'SET_GENDER';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_HEIGHT = 'SET_HEIGHT';
export const SET_WEIGHT = 'SET_WEIGHT';
export const SET_BIRTHDAY = 'SET_BIRTHDAY';
export const SET_HEIGHT_UNITS = 'SET_HEIGHT_UNITS';
export const SET_WEIGHT_UNITS = 'SET_WEIGHT_UNITS';

export const SELECT_PHOTO_FROM_PROVIDER_REQUEST =
  'SELECT_PHOTO_FROM_PROVIDER_REQUEST';
export const SELECT_PHOTO_FROM_PROVIDER_SUCCESS =
  'SELECT_PHOTO_FROM_PROVIDER_SUCCESS';
export const SELECT_PHOTO_FROM_PROVIDER_FAILURE =
  'SELECT_PHOTO_FROM_PROVIDER_FAILURE';
export const REMOVE_PHOTO_REQUEST = 'REMOVE_PHOTO_REQUEST';
export const REMOVE_PHOTO_SUCCESS = 'REMOVE_PHOTO_SUCCESS';
export const REMOVE_PHOTO_FAILURE = 'REMOVE_PHOTO_FAILURE';

export const SEARCH_MEDICATION_REQUEST = 'SEARCH_MEDICATION_REQUEST';
export const SEARCH_MEDICATION_SUCCESS = 'SEARCH_MEDICATION_SUCCESS';
export const SEARCH_MEDICATION_FAILURE = 'SEARCH_MEDICATION_FAILURE';

export const SEARCH_CONDITION_REQUEST = 'SEARCH_CONDITION_REQUEST';
export const SEARCH_CONDITION_SUCCESS = 'SEARCH_CONDITION_SUCCESS';
export const SEARCH_CONDITION_FAILURE = 'SEARCH_CONDITION_FAILURE';

export const ADD_UNSYNC_MEDICATION_TO_PROFILE =
  'ADD_UNSYNC_MEDICATION_TO_PROFILE';
export const ADD_UNSYNC_MEDICATIONS_ARRAY_TO_PROFILE =
  'ADD_UNSYNC_MEDICATIONS_ARRAY_TO_PROFILE';
export const EDIT_UNSYNC_MEDICATION_IN_PROFILE =
  'EDIT_UNSYNC_MEDICATION_IN_PROFILE';
export const DELETE_UNSYNC_MEDICATION_FROM_PROFILE =
  'DELETE_UNSYNC_MEDICATION_FROM_PROFILE';

// ??? Rename UNSYNC to Intro
export const ADD_UNSYNC_PAIN_CONDITION_TO_PROFILE =
  'ADD_UNSYNC_PAIN_CONDITION_TO_PROFILE';
export const ADD_UNSYNC_PAIN_CONDITIONS_ARRAY_TO_PROFILE =
  'ADD_UNSYNC_PAIN_CONDITIONS_ARRAY_TO_PROFILE';
export const EDIT_UNSYNC_PAIN_CONDITION = 'EDIT_UNSYNC_PAIN_CONDITION';
export const DELETE_UNSYNC_PAIN_CONDITION = 'DELETE_UNSYNC_PAIN_CONDITION';
export const TOGGLE_SHOW_OF_UNSYNC_PAIN_CONDITION =
  'TOGGLE_SHOW_OF_UNSYNC_PAIN_CONDITION';

export const ADD_OFFLINE_PAIN_CONDITION_TO_PROFILE =
  'ADD_OFFLINE_PAIN_CONDITION_TO_PROFILE';
export const EDIT_OFFLINE_PAIN_CONDITION_IN_PROFILE =
  'EDIT_OFFLINE_PAIN_CONDITION_IN_PROFILE';
export const TOGGLE_SHOW_OFFLINE_PAIN_CONDITION_IN_PROFILE =
  'TOGGLE_SHOW_OFFLINE_PAIN_CONDITION_IN_PROFILE';
export const DELETE_OFFLINE_PAIN_CONDITION_FROM_PROFILE =
  'DELETE_OFFLINE_PAIN_CONDITION_FROM_PROFILE';

export const ADD_OFFLINE_MEDICATION_TO_PROFILE =
  'ADD_OFFLINE_MEDICATION_TO_PROFILE';
export const EDIT_OFFLINE_MEDICATION_IN_PROFILE =
  'EDIT_OFFLINE_MEDICATION_IN_PROFILE';
export const DELETE_OFFLINE_MEDICATION_FROM_PROFILE =
  'DELETE_OFFLINE_MEDICATION_FROM_PROFILE';

export const REMOVE_NETWORK_ERROR_FOR_PAIN_CONDITIONS_AND_MEDICATIONS =
  'REMOVE_NETWORK_ERROR_FOR_PAIN_CONDITIONS_AND_MEDICATIONS';

//medication types
export const ADD_PAIN_CONDITION_SUCCESS = 'ADD_PAIN_CONDITION_SUCCESS';
export const EDIT_PAIN_CONDITION_SUCCESS = 'EDIT_PAIN_CONDITION_SUCCESS';
export const DELETE_PAIN_CONDITION_SUCCESS = 'DELETE_PAIN_CONDITION_SUCCESS';
export const ADD_MEDICATION_SUCCESS = 'ADD_MEDICATION_SUCCESS';
export const EDIT_MEDICATION_SUCCESS = 'EDIT_MEDICATION_SUCCESS';
export const DELETE_MEDICATION_SUCCESS = 'DELETE_MEDICATION_SUCCESS';
export const EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID =
  'EDIT_MEDICATIONS_SUBSTITUTE_PURPOSE_FID';

export const DELETE_ACCOUNT_PENDING = 'DELETE_ACCOUNT_PENDING';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
