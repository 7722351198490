export const DISCONNECT_FROM_PROVIDER = 'DISCONNECT_FROM_PROVIDER';
export const CONNECT_WITH_PROVIDER = 'CONNECT_WITH_PROVIDER';
//Auth types
export const LOGIN = 'LOGIN';
export const LOGIN_WITH_PROVIDER = 'LOGIN_WITH_PROVIDER';
export const AUTH_FLUSH = 'AUTH_FLUSH';
export const LOGIN_CLEAR = 'LOGIN_CLEAR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_WITH_PROVIDER_SUCCESS = 'LOGIN_WITH_PROVIDER_SUCCESS';
export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';
export const LOGOUT = 'LOGOUT';
export const SYNC_FAILED_LOGOUT = 'SYNC_FAILED_LOGOUT';
export const SET_LOGOUT_ATTEMPT_FLAG = 'SET_LOGOUT_ATTEMPT_FLAG';
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const SET_USER = 'SET_USER';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UNSUBSCRIBE_EMAIL = 'UNSUBSCRIBE_EMAIL';

export const SET_INSTITUTION = 'SET_INSTITUTION';
export const SET_UTM_PARAMETERS = 'SET_UTM_PARAMETERS';
export const NAVIGATE_TO_MAIN_SCREEN = 'NAVIGATE_TO_MAIN_SCREEN';

export const GET_IS_PASSWORD_SET_REQUEST = 'GET_IS_PASSWORD_SET_REQUEST';
export const GET_IS_PASSWORD_SET_SUCCESS = 'GET_IS_PASSWORD_SET_SUCCESS';
export const GET_IS_PASSWORD_SET_FAILURE = 'GET_IS_PASSWORD_SET_FAILURE';

export const JOIN_INSTITUTION_SUCCESS = 'JOIN_INSTITUTION_SUCCESS';

export const UPDATE_EULA = 'UPDATE_EULA';
export const GET_INSTITUTION_ID = 'GET_INSTITUTION_ID';
