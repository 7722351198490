import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = {};
Toast.show = (options) => {
  toast[options.type || 'info'](options.text);
};

export default Toast;
export { Toast };
