// import SessionStorage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { Platform } from 'react-native';
import { createMigrate, persistReducer } from 'redux-persist';

import { RecordsReducer } from '../modules/Records/reducer';
import { FieldsReducer } from '../modules/Fields/reducer';
import { AuthReducer } from '../modules/Auth/reducer';
import { ApiReducer } from '../modules/Api/reducer';
import { ProfileReducer } from '../modules/Profile/reducer';
import { ReportReducer } from '../modules/Report/reducer';
import { FilterReducer } from '../modules/Filter/reducer';
import { SyncReducer } from '../modules/Sync/reducer';
import { IntroReducer } from '../modules/Intro/reducer';
import { MedsReducer } from '../modules/Meds/reducer';
import { SettingsReducer } from '../modules/Settings/reducer';
import { FeedReducer } from '../modules/Feed/reducer';
import { IapReducer } from '../modules/Iap/reducer';
import { NavigatorReducer } from '../modules/Navigator/reducer';
import { UpgradesReducer } from '../modules/Upgrade/reducer';
import { designConstants } from '../design/styles/constants';
import getStorage from './storage';
import getStoredStateAndroid from './getStoredStateAndroid';
import { QuestionnairesReducer } from '../modules/Questionnaires/reducer';
import { PainGuideReducer } from '../modules/Painguide/reducer';
import {
  authMigrations,
  DATABASE_VERSION,
  fieldsMigrations,
} from './migration';

const buildReducer = async () => {
  const Storage = getStorage();

  let isAndroid = Platform.OS === 'android';
  const filterPersistorConfig = {
    version: 1,
    timeout: 0,
    key: 'filter',
    storage: Storage,
    whitelist: ['current'],
  };
  if (isAndroid) {
    filterPersistorConfig.getStoredState = getStoredStateAndroid;
  }
  const recordsPersistorConfig = {
    timeout: 0,
    key: 'records',
    storage: Storage,
    whitelist: [
      'list',
      'deleted',
      'consecutive',
      'overlays',
      designConstants.isWeb && 'current',
    ],
  };

  const syncPersistorConfig = {
    timeout: 0,
    key: 'sync',
    storage: Storage,
    whitelist: ['last_updated', 'type', 'fail_count', 'synced_first_time'],
  };
  if (isAndroid) {
    syncPersistorConfig.getStoredState = getStoredStateAndroid;
  }
  const authPersistorConfig = {
    timeout: 0,
    key: 'auth',
    storage: Storage,
    version: DATABASE_VERSION,
    migrate: createMigrate(authMigrations, { debug: false }),
    whitelist: [
      'user',
      'requesting_password_set',
      'web_token_outdated',
      'navigate_to_main_screen',
      'full_user_received',
      designConstants.isWeb && 'questionnaires',
      designConstants.isWeb && 'questionnairesToBeCompleted',
    ],
  };
  if (isAndroid) {
    authPersistorConfig.getStoredState = getStoredStateAndroid;
  }
  const fieldsPersistorConfig = {
    version: DATABASE_VERSION,
    timeout: 0,
    key: 'fields',
    storage: Storage,
    migrate: createMigrate(fieldsMigrations, { debug: false }),
  };
  if (isAndroid) {
    fieldsPersistorConfig.getStoredState = getStoredStateAndroid;
  }
  const settingsPersistorConfig = {
    version: 1,
    key: 'settings',
    storage: Storage,
  };

  const questionnairesPersistorConfig = {
    timeout: 0,
    key: 'questionnaires',
    storage: Storage,
    whitelist: [
      designConstants.isWeb && 'questionnaires',
      designConstants.isWeb && 'questionnairesToBeCompleted',
      'incompleteQuestionnaires',
    ],
  };

  const records = persistReducer(recordsPersistorConfig, RecordsReducer);
  const fields = persistReducer(fieldsPersistorConfig, FieldsReducer);
  const sync = persistReducer(syncPersistorConfig, SyncReducer);
  const filter = persistReducer(filterPersistorConfig, FilterReducer);
  const auth = persistReducer(authPersistorConfig, AuthReducer);
  const settings = persistReducer(settingsPersistorConfig, SettingsReducer);
  const questionnaires = persistReducer(
    questionnairesPersistorConfig,
    QuestionnairesReducer,
  );
  const reducer = combineReducers({
    records,
    fields,
    api: ApiReducer,
    profile: ProfileReducer,
    sync,
    meds: MedsReducer,
    report: ReportReducer,
    filter,
    intro: IntroReducer,
    settings,
    feed: FeedReducer,
    iap: IapReducer,
    upgrade: UpgradesReducer,
    auth,
    routes: NavigatorReducer,
    questionnaires,
    guide: PainGuideReducer,
  });
  return reducer;
};

export default async () => {
  return await buildReducer();
};
