import * as React from 'react';
import { designConstants } from '../../../design/styles';
export const navigationRef = React.createRef();

let _navigator;

function setTopLevelNavigator(navigatorRef) {
  console.log('SET TOP nav ', navigatorRef);
  _navigator = navigatorRef;
}

// function menuClick(routeName) {
//   navigationRef.current?.dispatch({
//     ...StackActions.replace('common', { screen: routeName }),
//   });
//   //     NavigationActions.navigate({
//   //         routeName: 'screens',
//   //         action: NavigationActions.navigate({routeName})
//   //     })
//   // );
// }

function navigate(routeName, params) {
  navigationRef.current?.navigate(routeName, params);
}

function reset(routeName) {
  navigationRef.current?.reset(routeName);
}

function goBack(navObject) {
  if (!navObject) {
    navigationRef.current?.goBack();
    return;
  }
  const { navigation, route } = navObject;

  if (navigation.canGoBack()) {
    navigation.goBack();
  } else if (designConstants.isWeb) {
    navigation.replace('home');
  } else {
    navigation.reset({
      index: 0,
      routes: [{ name: 'home' }],
    });
  }
}

function state() {
  return navigationRef.current?.getRootState();
}

// add other navigation functions that you need and export them

export default {
  navigate,
  reset,
  goBack,
  // menuClick,
  state,
  setTopLevelNavigator,
};
