import React, { Component } from 'react';
import {
  Animated,
  Dimensions,
  findNodeHandle,
  Keyboard,
  Platform,
  StyleSheet,
  TextInput,
  UIManager,
  View,
} from 'react-native';
import PropTypes from 'prop-types';

const { width, height } = Dimensions.get('window');
const offsetForKeyboard = Platform.OS === 'ios' ? 49 : 49 + 2 * 19;

// Default KeyboardAvoidingView from react-native was not working correct, thus custom implementation is needed
// TODO place KeyboardAvoidingHOC and Content in one file, because these components are tightly coupled
// Do not use KeyboardAvoidingHOC separately from Content
export default function KeyboardAvoidingHOC(WrappedComponent) {
  // This component solves the issue with keyboard for iOS by adding container below the Content,
  // Android handles it via android:windowSoftInputMode="adjustResize" in manifest
  // Scroll issues are solved for both platforms
  return class KeyboardAvoidingView extends Component {
    keyboardAvoidingMargin = new Animated.Value(0);

    state = {
      keyboardWasShown: false,
    };

    static propTypes = {
      scrollRef: PropTypes.func,
    };

    static defaultProps = {
      scrollRef: () => null,
    };

    __mounted = false;
    keyboardDidShowListener = () => {};
    keyboardDidHideListener = () => {};

    componentDidMount() {
      this.__mounted = true;
      if (Platform.OS === 'ios') {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardWillShow',
          this._keyboardDidShow,
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardWillHide',
          this._keyboardDidHide,
        );
      } else {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardDidShow',
          this._keyboardDidShow,
        );
        this.keyboardDidHideListener = Keyboard.addListener(
          'keyboardDidHide',
          this._keyboardDidHide,
        );
      }
    }

    componentWillUnmount() {
      this.__mounted = false;
      if (true || Platform.OS === 'ios') {
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
      }
      Keyboard.dismiss();
    }

    _previousCurentScroll = null;
    _previousFocusedInput = null;

    _keyboardDidShow = (e) => {
      this.setState({ keyboardWasShown: true });
      Animated.timing(this.keyboardAvoidingMargin, {
        toValue: e.endCoordinates.height,
        useNativeDriver: false,
        // No needed footer height calculation - footer has it's own behaviour
        duration: 0,
      }).start(() => {
        const focusedInput = findNodeHandle(
          TextInput.State.currentlyFocusedInput(),
        );
        this._previousFocusedInput = focusedInput;
        this._previousCurentScroll = this.componentRef.currentScroll;
        focusedInput &&
          UIManager.viewIsDescendantOf(
            focusedInput,
            findNodeHandle(this),
            (result) => {
              result &&
                UIManager.measure(focusedInput, (...args) => {
                  const y = height - args[5];
                  if (y <= e.endCoordinates.height + offsetForKeyboard) {
                    this.componentRef &&
                      this.componentRef.scrollContentTo &&
                      this.componentRef.scrollContentTo({
                        x: 0,
                        y:
                          this.componentRef.currentScroll +
                          e.endCoordinates.height -
                          y +
                          offsetForKeyboard,
                        animated: true,
                      });
                  }
                });
            },
          );
      });
    };

    _keyboardDidHide = () => {
      Animated.timing(this.keyboardAvoidingMargin, {
        toValue: 0,
        useNativeDriver: false,
        duration: 0,
      }).start(() => {
        this._previousFocusedInput &&
          UIManager.viewIsDescendantOf(
            this._previousFocusedInput,
            findNodeHandle(this),
            (result) => {
              result &&
                this.componentRef &&
                this.componentRef.scrollContentTo &&
                this.componentRef.scrollContentTo({
                  x: 0,
                  y: this._previousCurentScroll,
                  animated: true,
                });
              this._previousFocusedInput = null;
              this._previousFocusedInput = null;
            },
          );
      });
    };

    componentRef = null;

    bindRef = (ref) => {
      this.componentRef = ref;
      this.props.scrollRef && this.props.scrollRef(ref);
    };

    render() {
      const { keyboardWasShown } = this.state;
      return (
        <View style={styles.flexOne}>
          <WrappedComponent
            {...this.props}
            ref={this.bindRef}
            keyboardWasShown={keyboardWasShown}
          />
          {Platform.OS === 'ios' && (
            <View style={styles.rowFlexDirection}>
              <Animated.View style={{ height: this.keyboardAvoidingMargin }} />
            </View>
          )}
        </View>
      );
    }
  };
}

const styles = StyleSheet.create({
  flexOne: { flex: 1, flexDirection: 'column' },
  rowFlexDirection: { flexDirection: 'row' },
});
