import * as types from './actiontypes';
import * as authTypes from '../Auth/actiontypes';

const initialState = {
  questionnaires: [],
  incompleteQuestionnaires: {},
  questionnairesRetrieved: false,
  questionnairesToBeCompleted: 0,
};

function QuestionnairesReducer(state = { ...initialState }, action) {
  const payload = action.payload;
  switch (action.type) {
    case authTypes.SYNC_FAILED_LOGOUT:
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case types.QUESTIONNAIRES_CLEAR_PROGRESS:
      if (payload) {
        const incomplete = {
          ...state.incompleteQuestionnaires,
        };
        delete incomplete[payload];
        return {
          ...state,
          incompleteQuestionnaires: incomplete,
        };
      } else {
        return {
          ...state,
          incompleteQuestionnaires: {},
        };
      }
    case types.QUESTIONNAIRES_UPDATE_PROGRESS:
      const questionnaire_id = payload.questionnaire_id;

      const incomplete = {
        [questionnaire_id]: {
          ...state.incompleteQuestionnaires[questionnaire_id],
          ...payload.data,
        },
      };
      console.log('INCOMPLETE', incomplete);
      return {
        ...state,
        incompleteQuestionnaires: {
          ...state.incompleteQuestionnaires,
          [questionnaire_id]: {
            ...state.incompleteQuestionnaires[questionnaire_id],
            [payload.data.name]: payload.data.value,
          },
        },
      };
    case types.QUESTIONNAIRES_RETRIEVE_SUCCESS:
      return {
        ...state,
        questionnaires: action.payload.questionnaires,
        questionnairesRetrieved: true,
        questionnairesToBeCompleted: action.payload.toBeCompleted,
      };
    default:
      return state;
  }
}
export { QuestionnairesReducer };
