import { apiError, checkToken } from '../Api/actions';
import * as MMPApi from 'apiclient';
import * as types from './actiontypes';
import moment from 'moment';
import { getLang } from '../../shared/utilities/getLang';

export function clearQuestionnairesProgress() {
  return {
    type: types.QUESTIONNAIRES_CLEAR_PROGRESS,
  };
}

export function clearIncompleteQuestionnaire(questionnaire_id) {
  return {
    type: types.QUESTIONNAIRES_CLEAR_PROGRESS,
    payload: questionnaire_id,
  };
}
export function updateQuestionnaireProgress(questionnaire_id, data) {
  return {
    type: types.QUESTIONNAIRES_UPDATE_PROGRESS,
    payload: {
      questionnaire_id,
      data,
    },
  };
}
export function retrieveQuestionnaires() {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.UserQuestionnairesApi();

      return api.questionnaireGetQuestionnairesPost().then(
        (result) => {
          //TODO: Rewrite this!
          var questionnairesToComplete = [];
          result.forEach((questionnaires_for_clinic) => {
            questionnaires_for_clinic.questionnaires.forEach(
              (questionnaire) => {
                questionnaire.statuses?.forEach((status) => {
                  if (status.status === 'TO BE COMPLETED') {
                    if (
                      !questionnairesToComplete.find(
                        (item) =>
                          item.questionnaire_nid ===
                            questionnaire.questionnaire_nid &&
                          item.status !== 'TO BE UPDATED',
                      )
                    ) {
                      questionnairesToComplete.push({
                        ...questionnaire,
                        status: status.status,
                      });
                    }
                  }
                  if (status.status === 'TO BE UPDATED') {
                    questionnairesToComplete.push({
                      ...questionnaire,
                      status: status.status,
                    });
                  }
                });
              },
            );
          });
          const toBeCompleted = questionnairesToComplete.length;
          dispatch({
            type: types.QUESTIONNAIRES_RETRIEVE_SUCCESS,
            payload: {
              questionnaires: result,
              toBeCompleted,
            },
          });
          return Promise.resolve();
        },
        (error) => {
          return Promise.resolve();
        },
      );
    });
  };
}

export function getQuestionnaire(institution_id, questionnaire_id) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.UserQuestionnairesApi();

      let params = MMPApi.GetQuestionnaireParams.constructFromObject({
        institution_id,
        questionnaire_id,
      });
      return api.questionnaireGetQuestionnairePost(getLang(), params).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          console.log('questionnaries error', error);
          return dispatch(apiError(error, types.QUESTIONNAIRES_GET));
        },
      );
    });
  };
}

export function submitQuestionnaire(
  institution_id,
  questionnaire_id,
  response,
  duration,
  response_id,
) {
  return (dispatch) => {
    return checkToken().then(() => {
      let api = new MMPApi.UserQuestionnairesApi();

      let timing = moment().valueOf();
      let offset = new Date().getTimezoneOffset() * 1000 * 60 * -1;

      // console.log('submit questionnaire', institution_id, questionnaire_id);
      let params = MMPApi.SubmitQuestionnaireParams.constructFromObject({
        institution_id,
        questionnaire_id,
        response,
        timing,
        offset,
        duration,
        response_id,
      });
      return api.questionnaireSubmitQuestionnairePost(getLang(), params).then(
        (result) => {
          console.log('QUESTIONNARIES RESULT', result);
          return Promise.resolve(result);
        },
        (error) => {
          console.log('questionnaries error', error);
          return dispatch(apiError(error, types.QUESTIONNAIRES_SUBMIT));
        },
      );
    });
  };
}
