import { checkToken } from '../../Api/actions';
import AllRecords from '../../Sync/records';
import { bugsnag } from '../../Settings/components/CrashUsageController';
import { deleteRecord, fixDailyReflection } from '../../Records/actions';
import { formatMoment } from '../../../shared/utilities/timeutils';
import { MILLISECONDS_IN_DAY } from '../../Filter/filter';
import { FIELDS_ALL } from '../../Fields/constants';
import FixDailyReflections from './002FixDailyReflectionsTime';

import * as MMPApi from 'apiclient';
import { getLang } from '../../../shared/utilities/getLang';

const TIME_12_HOURS = MILLISECONDS_IN_DAY / 2;

export default function FixDailyReflectionsFromServer(dispatch, getState) {
  let localRecords = getState().records.list;
  let recordsToUpdate = [];
  let recordsToDelete = [];
  let localUpdated = localRecords.filter(
    (rec) => rec.flag_updated && rec.type === 'DailyReflection',
  );
  let api = new MMPApi.RecordCallsApi();

  let params = MMPApi.GetAllRecordsParams.constructFromObject({
    last_updated: 0,
  });

  let fields = {};

  let customLists = getState().fields;
  //We need map of "field name > field" to faster search fields by name
  FIELDS_ALL.forEach((field) => {
    if (customLists[field]) {
      fields[field] = {};
      customLists[field].forEach((f) => {
        if (f && f.name) {
          fields[field][f.name] = f;
        }
      });
    }
  });

  return checkToken().then(() => {
    return api
      .syncGetAllRecordsPost(getLang(), params)
      .then((result) => {
        // Parse get-all-records result
        let server = new AllRecords(result);
        return server;
      })
      .then((server) => {
        let serverRecords = server.recordsList.slice();
        let serverIds = serverRecords.map((record) => record.record_id);
        let serverCreated = {};
        serverRecords.forEach((rec) => (serverCreated[rec.created] = rec));

        localRecords.forEach((record) => {
          if (!record.deleted && record.type === 'DailyReflection') {
            if (
              serverCreated[record.createDate] &&
              serverCreated[record.createDate].content.pain_record_type ===
                'DailyReflection' &&
              serverCreated[record.createDate].content &&
              serverCreated[record.createDate].content.timing !=
                record.recordTime
            ) {
              //Seems like we found local record which have different time than server record. Need to update local record with server values
              let recToUpdate = { ...record };
              bugsnag &&
                bugsnag.notify(
                  new Error(
                    'Local record with time on server. DEBUG: ' +
                      recToUpdate.recordTime +
                      ' ' +
                      recToUpdate.timeOffset +
                      ' ' +
                      serverCreated[record.createDate].content.timing +
                      ' ' +
                      serverCreated[record.createDate].content.time_offset +
                      ' ' +
                      record.createDate,
                  ),
                );
              recToUpdate.recordTime = parseInt(
                serverCreated[record.createDate].content.timing,
              );
              if (
                serverCreated[record.createDate].content.time_offset !== null
              ) {
                console.log(
                  'RECORD FOUND!',
                  serverCreated[record.createDate],
                  record,
                );
                recToUpdate.timeOffset =
                  serverCreated[record.createDate].content.time_offset;
              }

              recordsToUpdate.push(recToUpdate);
            }
          }
        });

        if (recordsToUpdate.length > 0) {
          // api = new MMPApi.RecordCallsApi();
          // let records = recordsToUpdate.map((rec) => MMPApi.PainRecord.constructFromObject(recordToServer(rec, fields)));
          //WE CANT UPDATE SERVER RECORDS, SINCE THERE WILL BE CONFLICT ALSO =\
          // return api.syncUpdateRecordsPost(getLang(), {params: records}).then(() => {
          //
          // });

          //SO UPDATE LOCAL RECORDS INSTEAD
          return Promise.all(
            recordsToUpdate.map((rec) => {
              return dispatch(fixDailyReflection(rec));
            }),
          ).then(() => {
            return dispatch(FixDailyReflections);
          });
        }

        localUpdated.forEach((record) => {
          let recordLocalMoment = formatMoment(
            record.recordTime,
            record.timeOffset,
          );
          serverRecords.forEach((rec) => {
            if (
              rec.content.pain_record_type === 'DailyReflection' &&
              record.idOnServer !== rec.record_id
            ) {
              let serverMoment = formatMoment(
                parseInt(rec.content.timing),
                rec.content.time_offset,
              );
              if (recordLocalMoment.isSame(serverMoment, 'date')) {
                recordsToDelete.push(record);
              }
            }
          });
        });

        if (recordsToDelete.length > 0) {
          bugsnag &&
            bugsnag.notify(
              new Error(
                'Found ' +
                  recordsToDelete.length +
                  ' records to delete during error 180 fix.',
              ),
            );
          return Promise.all(
            recordsToDelete.map((rec) => {
              return dispatch(deleteRecord(rec));
            }),
          ).then(() => {
            return dispatch(FixDailyReflections);
          });
        }

        bugsnag &&
          bugsnag.notify(
            new Error(
              'Seems like we get error 180, but we didnt found any errors locally or on server. PLEASE INVESTIGATE! ALSO WE DIDNT FOUND ANY RECORDS TO DELETE...',
            ),
          );
        return Promise.resolve();
      });
  });
}
