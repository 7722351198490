import * as strings from '../../strings/strings';
import shared from '../strings';
import { designConstants } from '../../design/styles';
import { getTranslationsForInstitution } from '../../modules/Settings/actions';
import { NativeModules } from 'react-native';
import { setFormats } from '../../design/styles/dateformats';
import { getLang } from './getLang';

const i18n = require('i18n-js');
const { SettingsModule } = NativeModules;
let institutionTranslations = null;

function replaceTranslations(lang, translations) {
  Object.keys(translations).forEach((key) => {
    Object.keys(strings).forEach((file) => {
      if (strings[file]?._props?.[lang]?.[key]) {
        strings[file]._props[lang][key] = translations[key];
        strings[file][key] = translations[key];
      }
    });
  });
}

export function getInstitutionTranslations() {
  return institutionTranslations;
}

export function loadTranslations(institution_id, onLoadEnd) {
  getTranslationsForInstitution(institution_id)
    .then((result) => {
      institutionTranslations = result;
      replaceTranslations(getLang(), result);
      onLoadEnd && onLoadEnd(result);
    })
    .catch((error) => {
      window.__institution_landing_background = undefined;
      window.__institution_landing_logo = undefined;
      window.__institution_id = undefined;
      console.log('LOADING TRANSLATIONS FAILED', error);
      onLoadEnd({ error_translations: true });
    });
}

export function setAppLanguage(language) {
  // if(Config.MMP_BUGSNAG_STAGE==='production') {
  //     language='en';
  // }
  console.log('LANGUAGE??', language);

  if (!designConstants.isWeb) {
    SettingsModule.getDateFormats(language).then((result) => {
      // console.log('FORMATS', result);
      setFormats(result.dateFormats, result.timeFormats);
    });
  }
  if (
    language === 'default' ||
    language.toLowerCase() === 'zh-hans' ||
    language.toLowerCase() === 'zh-rcn' ||
    language.toLocaleString() === 'zh-cn'
  ) {
    let defaultLang = null;
    if (designConstants.isWeb) {
      defaultLang = navigator.language || navigator.userLanguage || 'en-US';
    } else {
      defaultLang = require('react-native-localize').getLocales()[0]
        .languageTag;
    }
    let lang = language === 'default' ? defaultLang : language;
    if (
      lang.toLowerCase().startsWith('zh-hans') ||
      lang.toLowerCase() === 'zh-cn' ||
      lang.toLowerCase() === 'zh-rcn'
    ) {
      lang = 'zh-rCN';
    }
    shared.setLanguage(lang);
    language = shared.getLanguage();
  } else {
    shared.setLanguage(language);
  }
  i18n.defaultLocale = language;
  i18n.locale = language;
  Object.values(strings).forEach((file) => {
    file.setLanguage(language);
  });
}
