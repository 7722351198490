import * as types from './actiontypes';
import { REPORT_FIELDS, REPORT_INITIAL } from './constants';
import * as authTypes from '../Auth/actiontypes';

function ReportReducer(state = { ...REPORT_INITIAL }, action) {
  switch (action.type) {
    case types.GENERATE_REPORT_SUCCESS:
      state = { ...REPORT_INITIAL };
      return state;
    case authTypes.LOGOUT:
      state = { ...REPORT_INITIAL };
      return state;
    case types.FILTER_APPLY_REPORT:
      state.filter = action.payload;
      return {
        ...state,
      };
    case types.REPORT_CHECK_SECTION:
      state = { ...state };
      state[action.payload + '_enabled'] = state[action.payload + '_enabled']
        ? 0
        : 1;
      if (action.payload === 'calendar_view') {
        return state;
      }
      //Check/uncheck all sections inside
      if (typeof state[action.payload] !== 'undefined') {
        if (state[action.payload + '_enabled']) {
          state[action.payload] = REPORT_FIELDS[action.payload]
            .filter((f) => typeof f === 'string')
            .slice()
            .concat(state[action.payload]);
        } else {
          state[action.payload] = state[action.payload].filter(
            (f) => typeof f !== 'string',
          );
        }
      }
      return state;
    case types.REPORT_CHECK_FIELD:
      if (!Array.isArray(state[action.payload.section])) {
        state[action.payload.section] =
          REPORT_INITIAL[action.payload.section].slice();
      }

      let index = state[action.payload.section].indexOf(action.payload.field);
      state[action.payload.section] = state[action.payload.section].slice();
      if (index > -1) {
        state[action.payload.section].splice(index, 1);

        //Uncheck if that's last one
        if (
          action.payload.section !== 'pain_summary' &&
          state[action.payload.section].filter((f) => typeof f === 'string')
            .length === 0
        ) {
          if (state[action.payload.section + '_enabled']) {
            state[action.payload.section + '_enabled'] = 0;
          }
        }
      } else {
        state[action.payload.section].push(action.payload.field);

        //Check section also
        if (!state[action.payload.section + '_enabled']) {
          state[action.payload.section + '_enabled'] = 1;
        }
      }

      return {
        ...state,
      };
    case types.REPORT_SET_SORT:
      let sortBy = state.pain_details.find(
        (f) => typeof f !== 'string' && f.sort_by,
      );
      sortBy.sort_by = action.payload;
      state.pain_details = state.pain_details.slice();
      return {
        ...state,
      };
    case types.REPORT_SET_SORT_ORDER:
      let sortOrder = state.pain_details.find(
        (f) => typeof f !== 'string' && f.sort_order,
      );
      sortOrder.sort_order = action.payload;
      state.pain_details = state.pain_details.slice();
      return {
        ...state,
      };
    case types.API_ERROR:
      switch (action.reason) {
      }

      return {
        ...state,
      };
    case types.GET_PREVIOUS_REPORTS_SUCCESS:
      state.previous_reports = action.payload;
      return {
        ...state,
      };
    default:
      return state;
  }
}

export { ReportReducer };
