import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { designConstants } from '../styles';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import { withTheme } from '../styles/useThemeContext';

// Default button look is gray text with sentence case
class LowEmphasisButton extends Component {
  onPress = () => {
    trackButtonPress('Button ' + (this.props.tag || this.props.text));
    this.props.onPress();
  };

  render() {
    let {
      lightBlue,
      lightGray,
      lightGreen,
      white,
      gray,
      blue,
      style,
      text,
      medium,
      small,
      ignoreSentenceCase,
    } = this.props;
    const { colors } = this.props.theme;

    let textColor = colors.gray;
    if (lightBlue) {
      textColor = colors.lightBlue;
    }
    if (lightGreen) {
      textColor = colors.lightGreen;
    }
    if (blue) {
      textColor = colors.buttonBlue;
    }
    if (lightGray) {
      textColor = colors.lightGray;
    }
    if (white) {
      textColor = colors.white;
    }
    if (gray) {
      textColor = colors.gray;
    }

    return (
      <TouchableNative
        onPress={this.onPress}
        {...setTestID(this.props.tag || this.props.text)}
        accesible={true}
        style={[lowEmphasisButtonStyles.rippleWrapper, style]}
      >
        <Text
          center
          style={[
            {
              color: textColor,
              textAlign: 'center',
              textAlignVertical: 'center',
              padding: designConstants.paddings.buttonMediumEmphasisPadding,
            },
            medium && { fontSize: designConstants.fontSizes.medium },
            small && { fontSize: designConstants.fontSizes.small },
          ]}
        >
          {ignoreSentenceCase ? text : toSentenceCase(text)}
        </Text>
      </TouchableNative>
    );
  }
}

const lowEmphasisButtonStyles = StyleSheet.create({
  rippleWrapper: {
    minHeight: designConstants.heights.touchableMinHeight,
    borderRadius: 15,
    borderWidth: designConstants.onePixel,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

LowEmphasisButton.propTypes = {
  lightBlue: PropTypes.bool,
  lightGray: PropTypes.bool,
  gray: PropTypes.bool,
  caps: PropTypes.bool,
  // style: ViewPropTypes.style,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  minTextHeight: PropTypes.number,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ignoreSentenceCase: PropTypes.bool,
};

LowEmphasisButton.defaultProps = {
  lightBlue: false,
  lightGray: false,
  gray: false,
  medium: false,
  small: false,
  caps: false,
  minTextHeight: 0,
  style: {},
  onPress: () => null,
  text: '',
  ignoreSentenceCase: false,
};

export default withTheme(LowEmphasisButton);
