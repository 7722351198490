const sharedStrings = {
  // Warning! Case sensitive
  en: {
    // Section headers
    Locations: 'Locations',
    Location: 'Location',
    locations_question: 'Where does it hurt?',
    'Other Associated Symptoms': 'Other Associated Symptoms',
    'Other Associated Symptom': 'Other Associated Symptom',
    other_associated_symptoms_question: 'What besides pain are you feeling?',
    Characteristics: 'Characteristics',
    Characteristic: 'Characteristic',
    characteristics_question: 'What words best describe your pain?',
    'Alleviating Factors': 'Alleviating Factors',
    'Alleviating Factor': 'Alleviating Factor',
    alleviating_factors_question: 'What helped make your pain better?',
    'Aggravating Factors': 'Aggravating Factors',
    'Aggravating Factor': 'Aggravating Factor',
    aggravating_factors_question: 'What made your pain worse?',
    'Ineffective Factors': 'Ineffective Factors',
    'Ineffective Factor': 'Ineffective Factor',
    ineffective_factors_question: "What didn't have an effect on your pain?",
    Medications: 'Medications',
    Medication: 'Medication',
    medications_question: 'What medication did you take for your pain?',
    Intervention: 'Intervention',
    Interventions: 'Interventions',
    interventions_question:
      'What besides medication did you try for your pain?',
    Timing: 'Timing',
    timing_question: 'How long did your pain last?',
    'Type of Pain': 'Type of Pain',
    type_of_pain_question: 'What type of pain is it?',
    Environments: 'Environments',
    Environment: 'Environment',
    environment_question: 'Where were you during your pain?',
    'Meaningful Activities': 'Meaningful Activities',
    'Meaningful Activity': 'Meaningful Activity',
    meaningful_activities_question: 'What did you do?',
    'Daily Reflection Score': 'Daily Reflection Score',
    Function: 'Function',
    'Pain Intensity': 'Pain Severity',
    'Pain Severity': 'Pain Severity',
    Notes: 'Notes',
    notes_hint_label: 'Record any other thoughts',
    'Daily Average': 'Daily Average',

    // Field names
    Duration: 'Duration',
    Seconds: 'Seconds',
    Minutes: 'Minutes',
    Hours: 'Hours',
    Units: 'Units',
    'End Date': 'End Date',
    'End Time': 'End Time',
    'Start Date': 'Start Date',
    'Start Time': 'Start Time',
    'Time of Day': 'Time of Day',
    'Last 7 days': 'Last 7 days',
    'Last 30 days': 'Last 30 days',
    'All Time': 'All Time',
    'Custom Range': 'Custom Range',
    Name: 'Name',
    'Birth Date': 'Birth Date',
    Height: 'Height',
    Weight: 'Weight',
    Gender: 'Gender',
    Breakthrough: 'Breakthrough',
    Constant: 'Constant',
    Intermittent: 'Intermittent',
    Dosage: 'Dosage',
    'Medication Form': 'Medication Form',
    'Date of Diagnosis': 'Date of Diagnosis',
    'Date of First Symptom': 'Date of First Symptom',
    Score: 'Score',
    Trend: 'Trend',
    'Daily High': 'Daily High',
    'Daily Low': 'Daily Low',

    // App Sections
    Calendar: 'Calendar',
    Charts: 'Charts',
    'Daily Reflection': 'Daily Reflection',
    'Daily Reflections': 'Daily Reflections',
    'End User License Agreement': 'End User License Agreement',
    Home: 'Home',
    Insights: 'Insights',
    Menu: 'Menu',
    'My Profile': 'My Profile',
    'Pain Condition': 'Pain Condition',
    'Pain Conditions': 'Pain Conditions',
    'Pain Record': 'Pain Record',
    'Pain Records': 'Pain Records',
    'Privacy Policy': 'Privacy Policy',
    Records: 'Records',
    Reports: 'Reports',
    Results: 'Results',
    Settings: 'Settings',
    Timeline: 'Timeline',

    // Common toast messages
    date_range_changed: 'Date range has been changed to last 30 days',
    date_range_error: 'End Date must be equal to or later than Start Date',
    reset_password_success:
      'Password reset requested. If you correctly entered the email address associated with your account, a link to reset your password has been sent to you by email (please also check Spam/Junk folder)',
    sync_success: 'Synchronization successful',
    lite_version_warning:
      'The Lite version limits insights to the last 30 days',
    lite_version_warning_login:
      'The Lite version limits insights to the last 30 days. To upgrade, you must log in.',
    mail_app_error: 'Unable to open mail app',

    // Common prompts
    already_registered: 'Already have an account? {0}',
    cant_find_medication: "Can't find your medication?\n{0}",
    cant_find_condition: "Can't find your pain condition?\n{0}",
    'Would you like to save your changes?':
      'Would you like to save or discard your changes?',

    // Simple shared strings
    Add: 'Add',
    'Add Daily Reflection': 'Add Daily Reflection',
    'Add New': 'Add New',
    'Add Pain Record': 'Add Pain Record',
    'Add Record': 'Add Record',
    'All Day': 'All day',
    Apply: 'Apply',
    'Apply changes': 'Apply Changes',
    Ascending: 'Ascending',
    Back: 'Back',
    Better: 'Better',
    Descending: 'Descending',
    Done: 'Done',
    Cancel: 'Cancel',
    Close: 'Close',
    Continue: 'Continue',
    "Can't be empty": 'This field cannot be empty',
    Disable: 'Disable',
    Discard: 'Discard',
    Disconnect: 'Disconnect',
    Delete: 'Delete',
    Edit: 'Edit',
    'Edit List': 'Edit List',
    'Email address': 'Email Address',
    'Filter Records': 'Filter Records',
    Generic: 'Generic',
    'Got It': 'Got It',
    'Log In': 'Log In',
    'Log Out': 'Log Out',
    'Loading...': 'Loading...',
    Less: 'Less',
    'Manage Subscription': 'Manage Subscription',
    More: 'More',
    No: 'No',
    'No Change': 'No change',
    'Not registered yet': 'Not registered?',
    OK: 'OK',
    'Order Credits': 'Order Credits',
    'Password Not Set': 'Account has no password',
    Password: 'Password',
    'Register For Free': 'Register For Free',
    'Request It': 'Request It',
    'Reset Password': 'Reset Password',
    Retry: 'Retry',
    Revert: 'Revert',
    Save: 'Save',
    'Search again': 'Search Again',
    'Set Password': 'Set Password',
    Skip: 'Skip',
    'Sort By': 'Sort By',
    'Sort Order': 'Sort Order',
    'Swipe to Continue': 'Swipe to Continue',
    Worse: 'Worse',
    'Type three or more letters': 'Type three or more letters',
    Upgrade: 'Upgrade',
    'Upgrade to Pro': 'Upgrade',
    Yes: 'Yes',
    save_record_and_close: 'Save Record And Close',
    save_and_close: 'Save and close',
    close_without_saving: 'Close without saving',
    back_to_editing: 'Back to editing',
    login_request_new_link: 'Log in and request new link',
    contact_support: 'Contact support',
  },

  de: {
    // Section headers
    Locations: 'Bereiche',
    Location: 'Bereich',
    locations_question: 'Wo tut es weh?',
    'Other Associated Symptoms': 'Weitere Begleitsymptome',
    'Other Associated Symptom': 'Weiteres Begleitsymptom',
    other_associated_symptoms_question:
      'Was fühlen Sie außer den Schmerzen noch?',
    Characteristics: 'Merkmale',
    Characteristic: 'Merkmal',
    characteristics_question:
      'Welche Worte beschreiben Ihre Schmerzen am besten?',
    'Alleviating Factors': 'Linderungsfaktoren',
    'Alleviating Factor': 'Linderungsfaktor',
    alleviating_factors_question: 'Was hat Ihre Schmerzen gelindert?',
    'Aggravating Factors': 'Reizfaktoren',
    'Aggravating Factor': 'Reizfaktor',
    aggravating_factors_question: 'Was hat Ihre Schmerzen verschlimmert?',
    'Ineffective Factors': 'Unwirksame Faktoren',
    'Ineffective Factor': 'Unwirksamer Faktor',
    ineffective_factors_question:
      'Was hatte keinen Einfluss auf Ihre Schmerzen?',
    Medications: 'Medikamente',
    Medication: 'Medikament',
    medications_question:
      'Welche Medikamente haben Sie gegen Ihren Schmerz eingenommen?',
    Intervention: 'Intervention',
    Interventions:
      'Was haben Sie zusätzlich zur Einnahme von Medikamenten gegen Ihren Schmerz unternommen?',
    interventions_question:
      'Was haben Sie zusätzlich zur Einnahme von Medikamenten gegen Ihren Schmerz unternommen?',
    Timing: 'Dauer',
    timing_question: 'Wie lange haben Ihre Schmerzen angedauert?',
    'Type of Pain': 'Art des Schmerzes',
    type_of_pain_question: 'Um welche Art Schmerz hat es sich gehandelt?',
    Environments: 'Umgebungen',
    Environment: 'Umgebung',
    environment_question: 'Wo befanden Sie sich, als die Schmerzen eintraten?',
    'Meaningful Activities': 'Bedeutende Aktivitäten',
    'Meaningful Activity': 'Bedeutende Aktivität',
    meaningful_activities_question: 'Was haben Sie getan?',
    'Daily Reflection Score': 'Bewertung der Tagesübersicht',
    Function: 'Funktionsfähigkeit',
    'Pain Intensity': 'Schmerzstärke',
    'Pain Severity': 'Schmerzstärke',
    Notes: 'Notizen',
    notes_hint_label: 'Notieren Sie weitere Gedanken',
    'Daily Average': 'Tagesdurchschnitt',

    // Field names
    Duration: 'Dauer',
    Seconds: 'Sekunden',
    Minutes: 'Minuten',
    Hours: 'Stunden',
    Units: 'Einheiten',
    'End Date': 'Enddatum',
    'End Time': 'Endzeit',
    'Start Date': 'Startdatum',
    'Start Time': 'Startzeit',
    'Time of Day': 'Tageszeit',
    'Last 7 days': 'Letzte 7 Tage',
    'Last 30 days': 'Letzte 30 Tage',
    'All Time': 'Insgesamt',
    'Custom Range': 'Individueller Zeitraum',
    Name: 'Name',
    'Birth Date': 'Geburtsdatum',
    Height: 'Größe',
    Weight: 'Gewicht',
    Gender: 'Geschlecht',
    Breakthrough: 'Durchbruch',
    Constant: 'Konstant',
    Intermittent: 'Unterbrochen',
    Dosage: 'Dosierung',
    'Medication Form': 'Darreichungsform des Medikaments',
    'Date of Diagnosis': 'Datum der Diagnose',
    'Date of First Symptom': 'Datum des ersten Symptoms',
    Score: 'Bewertung',
    Trend: 'Tendenz',
    'Daily High': 'Tageshoch',
    'Daily Low': 'Tagestief',

    // App Sections
    Calendar: 'Kalender',
    Charts: 'Diagramme',
    'Daily Reflection': 'Tagesübersicht',
    'Daily Reflections': 'Tagesübersichten',
    'End User License Agreement': 'Endbenutzer-Lizenzvereinbarung',
    Home: 'Startseite',
    Insights: 'Datendetails',
    Menu: 'Menü',
    'My Profile': 'Mein Profil',
    'Pain Condition': 'Schmerzzustand',
    'Pain Conditions': 'Schmerzzustände',
    'Pain Record': 'Schmerzeintrag',
    'Pain Records': 'Schmerzeinträge',
    'Privacy Policy': 'Datenschutzerklärung',
    Records: 'Einträge',
    Reports: 'Berichte',
    Results: 'Ergebnisse',
    Settings: 'Einstellungen',
    Timeline: 'Zeitstrahl',

    // Common toast messages
    date_range_changed: 'Der Zeitraum wurde auf die letzten 30 tage geändert',
    date_range_error:
      'Endatum muss mit dem Startdatum identisch sein oder nach diesem liegen',
    reset_password_success:
      'Passwort zurücksetzen angefordert. Falls Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse korrekt eingegeben haben, wurde Ihnen per E-Mail ein Link zum Zurücksetzen Ihres Passworts zugesandt (bitte überprüfen Sie auch den Spam/Junk-Ordner)',
    sync_success: 'Synchronisation erfolgreich',
    lite_version_warning:
      'In der Lite-Version können Sie nur die letzten 30 Tage einsehen',
    lite_version_warning_login:
      'In der Lite-Version können Sie nur die letzten 30 Tage einsehen. Für ein Upgrade auf die Pro-Version müssen Sie sich anmelden.',
    mail_app_error: 'Öffnen der E-Mail-App fehlgeschlagen',

    // Common prompts
    already_registered: 'Haben Sie bereits ein Benutzerkonto? {0}',
    cant_find_medication: 'Sie können Ihr Medikament nicht finden?\n{0}',
    cant_find_condition: 'Sie können Ihren Schmerzzustand nicht finden?\n{0}',
    'Would you like to save your changes?':
      'Möchten Sie Ihre Änderungen verwerfen oder speichern?',

    // Simple shared strings
    Add: 'Hinzufügen',
    'Add Daily Reflection': 'Tagesübersicht hinzufügen',
    'Add New': 'Neu hinzufügen',
    'Add Pain Record': 'Schmerzeintrag hinzufügen',
    'Add Record': 'Eintrag hinzufügen',
    'All Day': 'Ganztägig',
    Apply: 'Anwenden',
    'Apply changes': 'Änderungen anwenden',
    Ascending: 'Aufsteigend',
    Back: 'Zurück',
    Better: 'Besser',
    Descending: 'Absteigend',
    Done: 'Fertig',
    Cancel: 'Beenden',
    Close: 'Schließen',
    Continue: 'Fortfahren',
    "Can't be empty": 'Dieses Feld muss ausgefüllt werden',
    Disable: 'Deaktivieren',
    Discard: 'Verwerfen',
    Disconnect: 'Trennen',
    Delete: 'Löschen',
    Edit: 'Bearbeiten',
    'Edit List': 'Liste bearbeiten',
    'Email address': 'E-Mail-Adresse',
    'Filter Records': 'Einträge filtern',
    Generic: 'Generikum',
    'Got It': 'Verstanden',
    'Log In': 'Anmelden',
    'Log Out': 'Abmelden',
    'Loading...': 'Laden...',
    Less: 'Weniger',
    'Manage Subscription': 'Abonnement verwalten',
    More: 'Mehr',
    No: 'Nein',
    'No Change': 'Keine Änderung',
    'Not registered yet': 'Noch nicht registriert?',
    OK: 'Ok',
    'Order Credits': 'Guthabenpunkte aufladen',
    'Password Not Set': 'Konto hat kein Passwort',
    Password: 'Passwort',
    'Register For Free': 'Kostenlos registrieren',
    'Request It': 'Anfordern',
    'Reset Password': 'Passwort Zurücksetzen',
    Retry: 'Erneut versuchen',
    Revert: 'Rückgängig',
    Save: 'Speichern',
    'Search again': 'Erneut suchen',
    'Set Password': 'Passwort festlegen',
    Skip: 'Überspringen',
    'Sort By': 'Sortieren nach',
    'Sort Order': 'Sortiert nach',
    'Swipe to Continue': 'Zum Fortfahren wischen',
    Worse: 'Schlechter',
    'Type three or more letters': 'Geben Sie drei oder mehr Buchstaben ein',
    Upgrade: 'Upgraden',
    'Upgrade to Pro': 'Auf Pro upgraden',
    Yes: 'Ja',
    save_record_and_close: 'Eintrag speichern und schließen',
    save_and_close: 'Speichern und schließen',
    close_without_saving: 'Schließen ohne Speichern',
    back_to_editing: 'Zurück zur Bearbeitung',
    login_request_new_link: 'Anmelden und neuen Link anfordern',
    contact_support: 'Support kontaktieren',
  },

  es: {
    // Section headers
    Locations: 'Lugares',
    Location: 'Lugar',
    locations_question: '¿En dónde le duele?',
    'Other Associated Symptoms': 'Otros síntomas asociados',
    'Other Associated Symptom': 'Otro síntoma asociado',
    other_associated_symptoms_question: '¿Qué siente además del dolor?',
    Characteristics: 'Características',
    Characteristic: 'Carácter',
    characteristics_question: '¿Qué palabras describen mejor su dolor?',
    'Alleviating Factors': 'Factores aliviantes',
    'Alleviating Factor': 'Factor aliviante',
    alleviating_factors_question: '¿Qué ayudo a aliviar su dolor?',
    'Aggravating Factors': 'Factores agravantes',
    'Aggravating Factor': 'Factor agravante',
    aggravating_factors_question: '¿Qué hizo que empeorara su dolor?',
    'Ineffective Factors': 'Facores ineficaces',
    'Ineffective Factor': 'Factor ineficaz',
    ineffective_factors_question: '¿Qué no tuvo un efecto en su dolor?',
    Medications: 'Medicamentos',
    Medication: 'Medicamento',
    medications_question: '¿Qué medicamento tomó para su dolor?',
    Intervention: 'Intervención',
    Interventions: 'Intervenciones',
    interventions_question:
      'Además de medicamentos, ¿qué otra cosa intentó para aliviar su dolor?',
    Timing: 'Momento',
    timing_question: '¿Cuánto tiempo duró su dolor?',
    'Type of Pain': 'Tipo de dolor',
    type_of_pain_question: '¿Qué tipo de dolor tenía?',
    Environments: 'Ambientes',
    Environment: 'Ambiente',
    environment_question: '¿En dónde se encontraba al sentir el dolor?',
    'Meaningful Activities': 'Actividades significativas',
    'Meaningful Activity': 'Actividad significativa',
    meaningful_activities_question: '¿Qué hizo?',
    'Daily Reflection Score': 'Puntaje de reflexión diaria',
    Function: 'Función',
    'Pain Intensity': 'Gravedad del dolor',
    'Pain Severity': 'Gravedad del dolor',
    Notes: 'Notas',
    notes_hint_label: 'Registre cualquier otra observación',
    'Daily Average': 'Promedio Diario',

    // Field names
    Duration: 'Duración',
    Seconds: 'Segundos',
    Minutes: 'minutos',
    Hours: 'Horas',
    Units: 'Unidades',
    'End Date': 'Fecha de fin',
    'End Time': 'Hora de fin',
    'Start Date': 'Fecha de inicio',
    'Start Time': 'Hora de inicio',
    'Time of Day': 'Hora del día',
    'Last 7 days': 'Últimos 7 días',
    'Last 30 days': 'Últimos 30 días',
    'All Time': 'Todo el tiempo',
    'Custom Range': 'Rango personalizado',
    Name: 'Nombre',
    'Birth Date': 'Fecha de nacimiento',
    Height: 'Altuta',
    Weight: 'Peso',
    Gender: 'Sexo',
    Breakthrough: 'Irruptivo',
    Constant: 'Constante',
    Intermittent: 'Intermitente',
    Dosage: 'Dosis',
    'Medication Form': 'Presentación del medicamento',
    'Date of Diagnosis': 'Fecha de diagnóstico',
    'Date of First Symptom': 'Fecha de primer síntoma',
    Score: 'Puntuación',
    Trend: 'Tendencia',
    'Daily High': 'Máximo diario',
    'Daily Low': 'Mínimo diario',

    // App Sections
    Calendar: 'Calendario',
    Charts: 'Diagramas',
    'Daily Reflection': 'Reflexión diaria',
    'Daily Reflections': 'Reflexiones diarias',
    'End User License Agreement': 'Acuerdo de licencia de usuario final',
    Home: 'Inicio',
    Insights: 'Perspectivas',
    Menu: 'Menú',
    'My Profile': 'Mi perfil',
    'Pain Condition': 'Condición desencadenante de dolor',
    'Pain Conditions': 'Condiciones desencadenantes de dolor',
    'Pain Record': 'Registro de dolor',
    'Pain Records': 'Registros de dolor',
    'Privacy Policy': 'Política de privacidad',
    Records: 'Registros',
    Reports: 'Informes',
    Results: 'Resultados',
    Settings: 'Ajustes',
    Timeline: 'Cronología',

    // Common toast messages
    date_range_changed: 'Rango de fecha cambió a los últimos 30 días',
    date_range_error:
      'La fecha de fin debe ser igual o posterior a la fecha de inicio',
    reset_password_success:
      'Restablecimiento de contraseña solicitado. Si introdujo la dirección asociada a su cuenta de forma correcta, se ha enviado un enlace para restablecer su contraseña a su bandeja de correo (verifique también la carpeta de correo no deseado)',
    sync_success: 'Sincronización satisfactoria',
    lite_version_warning:
      'La versión Lite limita las perspectivas a los últimos 30 días',
    lite_version_warning_login:
      'La versión Lite limita las reflexiones a los últimos 30 días. Para actualizarla debe iniciar sesión.',
    mail_app_error: 'No es posible abrir aplicación de correo',

    // Common prompts
    already_registered: '¿Ya tiene una cuenta? {0}',
    cant_find_medication: '¿No encuentra su medicamento?\n{0}',
    cant_find_condition: '¿No encuentra la condición desencadenante?\n{0}',
    'Would you like to save your changes?':
      '¿Desea guardar o descartar sus cambios?',

    // Simple shared strings
    Add: 'Agregar',
    'Add Daily Reflection': 'Agregar reflexión diaria',
    'Add New': 'Agregar',
    'Add Pain Record': 'Agregar registro de dolor',
    'Add Record': 'Agregar registro',
    'All Day': 'Todo el Día',
    Apply: 'Aplicar',
    'Apply changes': 'Aplicar cambios',
    Ascending: 'Ascendente',
    Back: 'Regresar',
    Better: 'Mejor',
    Descending: 'Descendente',
    Done: 'Listo',
    Cancel: 'Cancelar',
    Close: 'Cerrar',
    Continue: 'Continuar',
    "Can't be empty": 'Debe llenar este campo',
    Disable: 'Deshabilitar',
    Discard: 'Descartar',
    Disconnect: 'Desconectar',
    Delete: 'Suprimir',
    Edit: 'Editar',
    'Edit List': 'Editar lista',
    'Email address': 'Correo',
    'Filter Records': 'Filtrar registros',
    Generic: 'Genérico',
    'Got It': 'Entiendo',
    'Log In': 'Ingresar',
    'Log Out': 'Cerrar Sesión',
    'Loading...': 'Cargando...',
    Less: 'Menos',
    'Manage Subscription': 'Gestionar suscripción',
    More: 'Más',
    No: 'No',
    'No Change': 'Sin cambios',
    'Not registered yet': '¿No está registrado?',
    OK: 'OK',
    'Order Credits': 'Comprar créditos',
    'Password Not Set': 'La cuenta no tiene una contraseña',
    Password: 'Contraseña',
    'Register For Free': 'Regístrese gratis',
    'Request It': 'Solicitar',
    'Reset Password': 'Restablecer contraseña',
    Retry: 'Reintentar',
    Revert: 'Revertir',
    Save: 'Guardar',
    'Search again': 'Buscar de nuevo',
    'Set Password': 'Establecer contraseña',
    Skip: 'Saltar',
    'Sort By': 'Clasificar por',
    'Sort Order': 'Orden de Clasificación',
    'Swipe to Continue': 'Deslizar para continuar',
    Worse: 'Peor',
    'Type three or more letters': 'Escriba tres o más letras',
    Upgrade: 'Actualizar',
    'Upgrade to Pro': 'Actualizar',
    Yes: 'Sí',
    save_record_and_close: 'Guardar registro y cerrar',
    save_and_close: 'Guardar y cerrar',
    close_without_saving: 'Cerrar sin guardar',
    back_to_editing: 'Volver a editar',
    login_request_new_link: 'Iniciar sesión y solicitar un nuevo enlace',
    contact_support: 'Contactar a soporte',
  },

  fr: {
    // Section headers
    Locations: 'Endroits',
    Location: 'Endroit',
    locations_question: 'Où avez-vous mal ?',
    'Other Associated Symptoms': 'Autres Symptômes Associés',
    'Other Associated Symptom': 'Autre Symptôme Associé',
    other_associated_symptoms_question:
      "Outre la douleur, qu'est-ce que vous ressentez ?",
    Characteristics: 'Caractéristiques',
    Characteristic: 'Caractère',
    characteristics_question: 'Quels mots décrivent votre douleur le mieux ?',
    'Alleviating Factors': 'Facteurs Soulageants',
    'Alleviating Factor': 'Facteur Soulageant',
    alleviating_factors_question:
      'Quelle chose a aidé à soulager votre douleur ?',
    'Aggravating Factors': 'Facteurs Aggravants',
    'Aggravating Factor': 'Facteur Aggravant',
    aggravating_factors_question: 'Quelle chose a aggravé votre douleur ?',
    'Ineffective Factors': 'Facteurs Inefficaces',
    'Ineffective Factor': 'Facteur Inefficace',
    ineffective_factors_question:
      'Quelle chose n’a pas eu d’effet sur votre douleur ?',
    Medications: 'Médicaments',
    Medication: 'Médicament',
    medications_question: 'Quel médicament avez-vous pris pour votre douleur ?',
    Intervention: 'Intervention',
    Interventions: 'Interventions',
    interventions_question:
      "Qu'est-ce que vous avez pris en dehors des médicaments pour soulager votre douleur ?",
    Timing: 'Moment',
    timing_question: 'Combien de temps a duré votre douleur ?',
    'Type of Pain': 'Type de Douleur',
    type_of_pain_question: 'Quel type de douleur vous avais ?',
    Environments: 'Environnements',
    Environment: 'Environnement',
    environment_question: 'Où étiez-vous quand vous avez ressenti la douleur ?',
    'Meaningful Activities': 'Activités Significatives',
    'Meaningful Activity': 'Activité Significative',
    meaningful_activities_question: "Qu'est-ce que vous avez fait?",
    'Daily Reflection Score': 'Pointage de la Réflexion Quotidienne',
    Function: 'Fonction',
    'Pain Intensity': 'Gravité de la Douleur',
    'Pain Severity': 'Gravité de la Douleur',
    Notes: 'Remarques',
    notes_hint_label: "Enregistrez d'autres observations",
    'Daily Average': 'Moyenne Quotidienne',

    // Field names
    Duration: 'Durée',
    Seconds: 'Secondes',
    Minutes: 'minutes',
    Hours: 'Heurs',
    Units: 'Unités',
    'End Date': 'Date de fin',
    'End Time': 'Heure de fin',
    'Start Date': 'Date de début',
    'Start Time': 'Heure de début',
    'Time of Day': 'Heure du jour',
    'Last 7 days': '7 derniers jours',
    'Last 30 days': '30 derniers jours',
    'All Time': 'Historique',
    'Custom Range': 'Éventail personnalisé',
    Name: 'Nom',
    'Birth Date': 'Date de naissance',
    Height: 'Hauteur',
    Weight: 'Poids',
    Gender: 'Sexe',
    Breakthrough: 'Aiguë',
    Constant: 'Constante',
    Intermittent: 'Intermittente',
    Dosage: 'Dosage',
    'Medication Form': 'Forme du médicament',
    'Date of Diagnosis': 'Date du diagnostic',
    'Date of First Symptom': 'Date du premier symptôme',
    Score: 'Pointage',
    Trend: 'Tendance',
    'Daily High': 'Maximum du Jour',
    'Daily Low': 'Minimum du Jour',

    // App Sections
    Calendar: 'Calendrier',
    Charts: 'Diagrammes',
    'Daily Reflection': 'Réflexion Quotidienne',
    'Daily Reflections': 'Réflexions Quotidiennes',
    'End User License Agreement': "Accord de Licence d'Utilisateur Final",
    Home: 'Accueil',
    Insights: 'Perspectives',
    Menu: 'Menu',
    'My Profile': 'Mon Profil',
    'Pain Condition': 'Condition douloureuse',
    'Pain Conditions': 'Conditions douloureuses',
    'Pain Record': 'Enregistrement de la douleur',
    'Pain Records': 'Enregistrements de la douleur',
    'Privacy Policy': 'Politique de Confidentialité',
    Records: 'Enregistrements',
    Reports: 'Rapports',
    Results: 'Résultats',
    Settings: 'Paramètres',
    Timeline: 'Chronologie',

    // Common toast messages
    date_range_changed:
      "L'intervalle de date a été modifié pour les 30 derniers jours",
    date_range_error:
      'La date de fin doit être égal ou postérieure à la date de début',
    reset_password_success:
      "Réinitialisation du mot de passe demandée. Si vous avez correctement saisi l'adresse e-mail associée à votre compte, vous recevrez par e-mail un lien pour réinitialiser votre mot de passe (Veuillez également vérifier le dossier Spam / Courrier indésirable).",
    sync_success: 'Syncronisation réussie',
    lite_version_warning:
      'La version Lite limite les perspectives aux 30 derniers jours',
    lite_version_warning_login:
      "La version Lite limite les perspectives aux 30 derniers jours. Pour mettre à jour l'appli, vous devez vous connecter.",
    mail_app_error: "Impossible d'ouvrir appli de courriel",

    // Common prompts
    already_registered: 'Avez-vous déjà un compte? {0}',
    cant_find_medication: 'Vous ne trouvez-vous pas votre médicament ?\n{0}',
    cant_find_condition:
      'Vous ne trouvez pas votre condition  douloureuse ?\n{0}',
    'Would you like to save your changes?':
      'Voulez-vous sauvegarder ou annuler les changements ?',

    // Simple shared strings
    Add: 'Ajouter',
    'Add Daily Reflection': 'Ajouter une Réflexion',
    'Add New': 'Ajouter',
    'Add Pain Record': 'Ajouter un Enregistrement',
    'Add Record': 'Ajouter un Enregistrement',
    'All Day': 'Toute la journée',
    Apply: 'Appliquer',
    'Apply changes': 'Appliquer les changements',
    Ascending: 'Ascendant',
    Back: 'Retourner',
    Better: 'Meilleure',
    Descending: 'Descendant',
    Done: 'Terminé',
    Cancel: 'Annuler',
    Close: 'Fermer',
    Continue: 'Continuer',
    "Can't be empty": 'Ce champ ne peut pas être vide',
    Disable: 'Désactiver',
    Discard: 'Ignorer',
    Disconnect: 'Déconnecter',
    Delete: 'Supprimer',
    Edit: 'Modifier',
    'Edit List': 'Modifier la Liste',
    'Email address': 'Courriel',
    'Filter Records': 'Filtrer les Enregistrements',
    Generic: 'Générique',
    'Got It': 'Je comprends',
    'Log In': 'Connexion',
    'Log Out': 'Déconnexion',
    'Loading...': 'Chargement en cours...',
    Less: 'Moins',
    'Manage Subscription': "Gérer l'abonnement",
    More: 'Plus',
    No: 'Non',
    'No Change': 'Aucun changement',
    'Not registered yet': "Vous n'êtes pas inscrit ?",
    OK: 'OK',
    'Order Credits': 'Acheter des crédits',
    'Password Not Set': "Aucun mot de passe n'est associé au compte",
    Password: 'Mot de Passe',
    'Register For Free': 'Inscrivez-vous gratuitement',
    'Request It': 'Demander',
    'Reset Password': 'Rétablir un mot de passe',
    Retry: 'Réessayer',
    Revert: 'Rétablir',
    Save: 'Sauvegarder',
    'Search again': 'Chercher à nouveau',
    'Set Password': 'Créer un mot de passe',
    Skip: 'Ignorer',
    'Sort By': 'Trier Par',
    'Sort Order': 'Ordre de Tri',
    'Swipe to Continue': 'Balayez pour continuer',
    Worse: 'Pire',
    'Type three or more letters': 'Écrivez trois lettres ou plus',
    Upgrade: 'Mettre à jour',
    'Upgrade to Pro': 'Mettre à jour',
    Yes: 'Oui',
    save_record_and_close: "Sauvegarder l'enregistrement et fermer",
    save_and_close: 'Sauvegarder et fermer',
    close_without_saving: 'Fermer sans sauvegarder',
    back_to_editing: "Retour à l'édition",
    login_request_new_link: 'Se connecter et demander un nouveau lien',
    contact_support: "Contacter l'assistance",
  },

  ko: {
    // Section headers
    Locations: '위치',
    Location: '위치',
    locations_question: '어디가 아프십니까?',
    'Other Associated Symptoms': '기타 관련 증상',
    'Other Associated Symptom': '기타 관련 증상',
    other_associated_symptoms_question: '통증 이외에 어떤 느낌이 있습니까?',
    Characteristics: '특징',
    Characteristic: '특성',
    characteristics_question: '통증을 설명하는 데 어떤 단어가 가장 알맞습니까?',
    'Alleviating Factors': '완화 요인들',
    'Alleviating Factor': '완화 요인',
    alleviating_factors_question: '통증을 더 나아지게 만들어주는 것은?',
    'Aggravating Factors': '악화 요인',
    'Aggravating Factor': '악화 요인',
    aggravating_factors_question: '무엇이 통증을 더 심하게 합니까?',
    'Ineffective Factors': '비효과 요인',
    'Ineffective Factor': '비효과 요인',
    ineffective_factors_question: '통증에 영향을 주지 않은 것은 무엇인가요?',
    Medications: '약품   ',
    Medication: '약품   ',
    medications_question: '통증을 위해 어떤 약물을 드셨습니까?',
    Intervention: '개입',
    Interventions: '개입',
    interventions_question: '통증을 위해 약물 이외에 어떤 시도를 하셨나요?',
    Timing: '타이밍',
    timing_question: '통증이 얼마나 지속됐나요?',
    'Type of Pain': '통증의 종류',
    type_of_pain_question: '어떤 종류의 통증인가요?',
    Environments: '환경',
    Environment: '환경',
    environment_question: '통증이 발생할 때 당신은 어디에 있었습니까?',
    'Meaningful Activities': '의미있는 활동',
    'Meaningful Activity': '의미있는 활동',
    meaningful_activities_question: '무엇을 하셨나요?',
    'Daily Reflection Score': '일일 반영 점수',
    Function: '기능',
    'Pain Intensity': '통증의 심각성',
    'Pain Severity': '통증의 심각성',
    Notes: '참고',
    notes_hint_label: '추가 정보를 기록하세요',
    'Daily Average': '일간 평균',

    // Field names
    Duration: '지속 시간',
    Seconds: '초',
    Minutes: '분',
    Hours: '시간',
    Units: '단위',
    'End Date': '종료일',
    'End Time': '종료 시간',
    'Start Date': '시작일',
    'Start Time': '시작 시간',
    'Time of Day': '일일 시간',
    'Last 7 days': '지난 7 일',
    'Last 30 days': '지난 30 일',
    'All Time': '모든 시간',
    'Custom Range': '범위 사용자 설정',
    Name: '이름',
    'Birth Date': '생년월일',
    Height: '키',
    Weight: '체중',
    Gender: '성별',
    Breakthrough: '갑작스럽게',
    Constant: '상시적으로',
    Intermittent: '간헐적으로',
    Dosage: '복용량',
    'Medication Form': '약물 양식',
    'Date of Diagnosis': '진단일',
    'Date of First Symptom': '첫 증상일',
    Score: '점수',
    Trend: '추세선',
    'Daily High': '일간 최고',
    'Daily Low': '일간 최저',

    // App Sections
    Calendar: '달력',
    Charts: '차트',
    'Daily Reflection': '일일 반영',
    'Daily Reflections': '일일 반영',
    'End User License Agreement': '최종 사용자 라이센스 계약',
    Home: '홈',
    Insights: '통찰력',
    Menu: '메뉴',
    'My Profile': '내 프로필',
    'Pain Condition': '통증 상태',
    'Pain Conditions': '통증 상태',
    'Pain Record': '통증 기록',
    'Pain Records': '통증 기록',
    'Privacy Policy': '개인 정보 보호 정책',
    Records: '기록',
    Reports: '보고서',
    Results: '결과',
    Settings: '설정',
    Timeline: '타임 라인',

    // Common toast messages
    date_range_changed: '날짜 범위가 지난 30일로 변경됨',
    date_range_error: '종료일은 시작 날짜와 같거나 이후여야 함',
    reset_password_success:
      '암호 재설정이 요청되었습니다. 계정에 연결된 전자 메일 주소를 올바르게 입력한 경우 암호를 재설정할 수 있는 링크가 전자 메일로 전송됩니다(스팸/정크 폴더도 확인하십시오).',
    sync_success: '동기화 성공',
    lite_version_warning: '라이트 버전은 통찰력을 지난 30일로 제한합니다.',
    lite_version_warning_login:
      '라이트는 버전은 통찰력을 지난 30일로 제한합니다. 업그레이드하려면 로그인해야 합니다.',
    mail_app_error: '메일 앱을 열 수 없음',

    // Common prompts
    already_registered: '계정이 이미 있습니까? {0}',
    cant_find_medication: '약을 찾을 수 없나요?\n{0}',
    cant_find_condition: '당신의 통증 상태를 찾을 수 없나요?\n{0}',
    'Would you like to save your changes?':
      '변경 내용을 저장하시겠습니까, 아니면 삭제하시겠습니까?',

    // Simple shared strings
    Add: '추가',
    'Add Daily Reflection': '일일 반영 추가',
    'Add New': '새로 추가',
    'Add Pain Record': '통증 기록 추가',
    'Add Record': '기록 추가',
    'All Day': '온종일',
    Apply: '적용',
    'Apply changes': '변경사항 적용',
    Ascending: '상승',
    Back: '돌아 가기',
    Better: '더 좋아짐',
    Descending: '내림차순',
    Done: '완료',
    Cancel: '취소',
    Close: '닫기',
    Continue: '계속 ',
    "Can't be empty": '필수항목입니다',
    Disable: '사용 안 함',
    Discard: '버리기',
    Disconnect: '연결해제',
    Delete: '삭제',
    Edit: '편집',
    'Edit List': '목록 편집',
    'Email address': '이메일 주소',
    'Filter Records': '필터 기록',
    Generic: '일반',
    'Got It': '알겠습니다',
    'Log In': '로그인',
    'Log Out': '로그 오프',
    'Loading...': '로드 중입니다...',
    Less: '줄이기',
    'Manage Subscription': '구독 관리',
    More: '더보기',
    No: '아니오',
    'No Change': '변화 없음',
    'Not registered yet': '가입하지 않으셨나요?',
    OK: '확인',
    'Order Credits': '크레딧 주문',
    'Password Not Set': '계정에 암호가 없습니다',
    Password: '비밀번호',
    'Register For Free': '무료 회원가입',
    'Request It': '요청하기',
    'Reset Password': '암호 재설정',
    Retry: '재시도',
    Revert: '취소',
    Save: '저장',
    'Search again': '다시 검색',
    'Set Password': '암호를 설정합니다',
    Skip: '건너뛰기',
    'Sort By': '정렬 기준',
    'Sort Order': '정렬 순서',
    'Swipe to Continue': '계속하기',
    Worse: '더 나빠짐',
    'Type three or more letters': '3자 이상의 글자를 입력하세요',
    Upgrade: '업그레이드',
    'Upgrade to Pro': '업그레이드',
    Yes: '예',
    save_record_and_close: '기록 저장 및 닫기',
    save_and_close: '저장 및 닫기',
    close_without_saving: '저장 없이 닫기',
    back_to_editing: '편집으로 돌아가기',
    login_request_new_link: '로그인 및 새로운 링크 요청',
    contact_support: '연락처 지원',
  },

  ru: {
    // Section headers
    Locations: 'Локализация',
    Location: 'Локализация',
    locations_question: 'Где болит?',
    'Other Associated Symptoms': 'Сопровождающие симптомы',
    'Other Associated Symptom': 'Сопровождающий симптом',
    other_associated_symptoms_question: 'Что, кроме боли, вы ощущаете?',
    Characteristics: 'Характер боли',
    Characteristic: 'Характер боли',
    characteristics_question: 'Какими словами вы бы описали свою боль?',
    'Alleviating Factors': 'Облегчающие факторы',
    'Alleviating Factor': 'Облегчающий фактор',
    alleviating_factors_question: 'Что помогло вам ослабить вашу боль?',
    'Aggravating Factors': 'Обостряющие факторы',
    'Aggravating Factor': 'Обостряющий фактор',
    aggravating_factors_question: 'Что делает вашу боль сильнее?',
    'Ineffective Factors': 'Нейтральные факторы',
    'Ineffective Factor': 'Нейтральный фактор',
    ineffective_factors_question: 'Что не повлияло на вашу боль?',
    Medications: 'Препараты',
    Medication: 'Препарат',
    medications_question: 'Какой препарат от боли вы приняли?',
    Intervention: 'Вмешательство',
    Interventions: 'Вмешательства',
    interventions_question:
      'Какие меры против боли вы предприняли, помимо препаратов?',
    Timing: 'Время',
    timing_question: 'Как долго длилась ваша боль?',
    'Type of Pain': 'Тип боли',
    type_of_pain_question: 'Какого рода была боль?',
    Environments: 'Обстановка',
    Environment: 'Обстановка',
    environment_question: 'Где вы находились в это время?',
    'Meaningful Activities': 'Значимая деятельность',
    'Meaningful Activity': 'Значимая деятельность',
    meaningful_activities_question: 'Чем вы занимались?',
    'Daily Reflection Score': 'Оценка дневной рефлексии',
    Function: 'Работоспособность',
    'Pain Intensity': 'Интенсивность боли',
    'Pain Severity': 'Интенсивность боли',
    Notes: 'Заметки',
    notes_hint_label: 'Запишите все ваши замечания',
    'Daily Average': 'Дневное среднее',

    // Field names
    Duration: 'Длительность',
    Seconds: 'сек.',
    Minutes: 'мин.',
    Hours: 'ч.',
    Units: 'Ед. измер.',
    'End Date': 'Дата окончания',
    'End Time': 'Время окончания',
    'Start Date': 'Дата начала',
    'Start Time': 'Время начала',
    'Time of Day': 'Время дня',
    'Last 7 days': 'Посл. 7 дней',
    'Last 30 days': 'Посл. 30 дней',
    'All Time': 'Всё время',
    'Custom Range': 'Свой временной отрезок',
    Name: 'Имя',
    'Birth Date': 'Дата рождения',
    Height: 'Рост',
    Weight: 'Вес',
    Gender: 'Пол',
    Breakthrough: 'Острая',
    Constant: 'Непрерывная',
    Intermittent: 'Периодическая',
    Dosage: 'Дозировка',
    'Medication Form': 'Лекарственная форма',
    'Date of Diagnosis': 'Дата постановки диагноза',
    'Date of First Symptom': 'Дата появления симптомов',
    Score: 'Оценка',
    Trend: 'Тенденция',
    'Daily High': 'Дневное максимальное',
    'Daily Low': 'Дневное минимальное',

    // App Sections
    Calendar: 'Календарь',
    Charts: 'Графики',
    'Daily Reflection': 'Дневная рефлексия',
    'Daily Reflections': 'Ежедневные рефлексии',
    'End User License Agreement': 'Пользовательское соглашение',
    Home: 'Главная',
    Insights: 'Аналитика',
    Menu: 'Меню',
    'My Profile': 'Мой профиль',
    'Pain Condition': 'Болевой синдром',
    'Pain Conditions': 'Болевые синдромы',
    'Pain Record': 'Запись о боли',
    'Pain Records': 'Записи о боли',
    'Privacy Policy': 'Политика конфиденциальности',
    Records: 'Записи',
    Reports: 'Отчеты',
    Results: 'Результаты',
    Settings: 'Настройки',
    Timeline: 'Хронология',

    // Common toast messages
    date_range_changed: 'Срок изменен на прошлые 30 дней',
    date_range_error: 'Дата окончания должна быть позже даты начала',
    reset_password_success:
      'Запрошен сброс пароля. Если вы верно указали адрес email, связанный с вашим аккаунтом, ссылка для сброса пароля была отправлена вам по эл. почте (советуем проверить папку «Спам»).',
    sync_success: 'Синхронизация прошла успешно',
    lite_version_warning: 'В версии Lite анализ ограничен сроком 30 дней',
    lite_version_warning_login:
      'В версии Lite анализ ограничен сроком 30 дней. Войдите в аккаунт для изменения плана.',
    mail_app_error: 'Не удалось запустить почтовое приложение',

    // Common prompts
    already_registered: 'Уже регистрировались? {0}',
    cant_find_medication: 'Не нашли свой препарат?\n{0}',
    cant_find_condition: 'Не нашли свой болевой синдром?\n{0}',
    'Would you like to save your changes?':
      'Хотите сохранить или отменить изменения?',

    // Simple shared strings
    Add: 'Добавить',
    'Add Daily Reflection': 'Добавить дневную рефлексию',
    'Add New': 'Добавить',
    'Add Pain Record': 'Добавить запись о боли',
    'Add Record': 'Добавить запись',
    'All Day': 'Весь день',
    Apply: 'Применить',
    'Apply changes': 'Применить изменения',
    Ascending: 'По возрастанию',
    Back: 'Назад',
    Better: 'Лучше',
    Descending: 'По убыванию',
    Done: 'Готово',
    Cancel: 'Отменить',
    Close: 'Закрыть',
    Continue: 'Дальше',
    "Can't be empty": 'Это поле не может быть пустым',
    Disable: 'Отключить',
    Discard: 'Не сохранять',
    Disconnect: 'Отключить',
    Delete: 'Удалить',
    Edit: 'Изменить',
    'Edit List': 'Изменить список',
    'Email address': 'Адрес email',
    'Filter Records': 'Фильтровать записи',
    Generic: 'Дженерик',
    'Got It': 'Понятно',
    'Log In': 'Войти',
    'Log Out': 'Выйти',
    'Loading...': 'Загрузка...',
    Less: 'Меньше',
    'Manage Subscription': 'Управление подпиской',
    More: 'Больше',
    No: 'Нет',
    'No Change': 'Без изменений',
    'Not registered yet': 'Не зарегистрированы?',
    OK: 'ОК',
    'Order Credits': 'Купить кредиты',
    'Password Not Set': 'Не задан пароль для аккаунта',
    Password: 'Пароль',
    'Register For Free': 'Регистрация',
    'Request It': 'Запросить добавление',
    'Reset Password': 'Сбросить пароль',
    Retry: 'Повторить',
    Revert: 'Отменить изменения',
    Save: 'Сохранить',
    'Search again': 'Искать ещё раз',
    'Set Password': 'Установить пароль',
    Skip: 'Пропустить',
    'Sort By': 'Сортировать по',
    'Sort Order': 'Порядок сортировки',
    'Swipe to Continue': 'Для продолжения проведите пальцем',
    Worse: 'Хуже',
    'Type three or more letters': 'Введите не менее трёх букв',
    Upgrade: 'Перейти на платную версию',
    'Upgrade to Pro': 'Перейти на платную версию',
    Yes: 'Да',
    save_record_and_close: 'Сохранить и закрыть запись',
    save_and_close: 'Сохранить и закрыть',
    close_without_saving: 'Закрыть без сохранения',
    back_to_editing: 'Вернуться к редактированию',
    login_request_new_link: 'Войти и запросить новую ссылку',
    contact_support: 'Связаться с поддержкой',
  },

  'zh-rCN': {
    // Section headers
    Locations: '位置',
    Location: '位置',
    locations_question: '哪里疼痛？',
    'Other Associated Symptoms': '其它相关症状',
    'Other Associated Symptom': '其它相关症状',
    other_associated_symptoms_question: '除了疼痛之外,您还有哪些感觉？',
    Characteristics: '特征',
    Characteristic: '特征',
    characteristics_question: '哪些词语可以最好地描述您的疼痛？',
    'Alleviating Factors': '缓解因素',
    'Alleviating Factor': '缓解因素',
    alleviating_factors_question: '哪些情况可以减轻您的疼痛？',
    'Aggravating Factors': '加重因素',
    'Aggravating Factor': '加重因素',
    aggravating_factors_question: '哪些情况会让您的疼痛加剧？',
    'Ineffective Factors': '无效因素',
    'Ineffective Factor': '无效因素',
    ineffective_factors_question: '哪些情况对您的疼痛没有影响？',
    Medications: '药物',
    Medication: '药物',
    medications_question: '您为疼痛服用了什么药物？',
    Intervention: '干预',
    Interventions: '干预',
    interventions_question: '除了药物之外您还尝试了哪些方法？',
    Timing: '时间',
    timing_question: '您的疼痛持续多久？',
    'Type of Pain': '疼痛的类型',
    type_of_pain_question: '什么类型的疼痛？',
    Environments: '环境',
    Environment: '环境',
    environment_question: '疼痛发作时您在哪里？',
    'Meaningful Activities': '有意义的活动',
    'Meaningful Activity': '有意义的活动',
    meaningful_activities_question: '您做了什么？',
    'Daily Reflection Score': '每日回顾评分',
    Function: '功能',
    'Pain Intensity': '疼痛严重程度',
    'Pain Severity': '疼痛严重程度',
    Notes: '备注',
    notes_hint_label: '请记录您的任何其它想法',
    'Daily Average': '当日平均',

    // Field names
    Duration: '时长',
    Seconds: '秒',
    Minutes: '分钟',
    Hours: '小时',
    Units: '单位',
    'End Date': '结束日期',
    'End Time': '结束时间',
    'Start Date': '开始日期',
    'Start Time': '开始时间',
    'Time of Day': '时间',
    'Last 7 days': '最近7天',
    'Last 30 days': '最近30天',
    'All Time': '全部时间段',
    'Custom Range': '自定义时间段',
    Name: '姓名',
    'Birth Date': '出生日期',
    Height: '身高',
    Weight: '体重',
    Gender: '性别',
    Breakthrough: '突发的',
    Constant: '持续的',
    Intermittent: '间歇性的',
    Dosage: '剂量',
    'Medication Form': '药物的剂型',
    'Date of Diagnosis': '诊断日期',
    'Date of First Symptom': '第一次出现症状的日期',
    Score: '评分',
    Trend: '趋势',
    'Daily High': '当日高点',
    'Daily Low': '当日低点',

    // App Sections
    Calendar: '日历',
    Charts: '图表',
    'Daily Reflection': '每日回顾',
    'Daily Reflections': '每日回顾',
    'End User License Agreement': '最终用户许可协议',
    Home: '主页',
    Insights: '数据分析',
    Menu: '菜单',
    'My Profile': '我的档案',
    'Pain Condition': '疼痛病情',
    'Pain Conditions': '疼痛病情',
    'Pain Record': '疼痛记录',
    'Pain Records': '疼痛记录',
    'Privacy Policy': '隐私政策',
    Records: '记录',
    Reports: '报告',
    Results: '结果',
    Settings: '设置',
    Timeline: '时间线',

    // Common toast messages
    date_range_changed: '日期范围已更改为最近30天',
    date_range_error: '结束日期必须等于或晚于开始日期',
    reset_password_success:
      '已请求重置密码。如果您正确输入了与您的帐户关联的电子邮件地址，重置密码的链接已通过电子邮件发送给您（请检查垃圾邮件 / 垃圾文件夹）',
    sync_success: '同步成功',
    lite_version_warning: '精简版的数据分析仅限于最近 30 天',
    lite_version_warning_login:
      '精简版的数据分析仅限于最近 30 天。要升级，您必须登录。',
    mail_app_error: '无法打开邮件应用程序',

    // Common prompts
    already_registered: '已经有一个账户？{0}',
    cant_find_medication: '找不到药物？\n{0}',
    cant_find_condition: '找不到您的疼痛病情？\n{0}',
    'Would you like to save your changes?': '您要保存还是放弃更改？',

    // Simple shared strings
    Add: '添加',
    'Add Daily Reflection': '添加每日回顾',
    'Add New': '添加新记录',
    'Add Pain Record': '添加疼痛记录',
    'Add Record': '添加记录',
    'All Day': '全天',
    Apply: '应用',
    'Apply changes': '应用更改',
    Ascending: '升序',
    Back: '返回',
    Better: '改善',
    Descending: '降序',
    Done: '完成',
    Cancel: '取消',
    Close: '关闭',
    Continue: '继续',
    "Can't be empty": '该字段不得为空',
    Disable: '禁用',
    Discard: '放弃',
    Disconnect: '断开',
    Delete: '删除',
    Edit: '编辑',
    'Edit List': '编辑列表',
    'Email address': '电子邮件地址',
    'Filter Records': '过滤记录',
    Generic: '通用药物',
    'Got It': '好的',
    'Log In': '登录',
    'Log Out': '登出',
    'Loading...': '正在加载…',
    Less: '更少',
    'Manage Subscription': '管理订阅',
    More: '更多',
    No: '否',
    'No Change': '没有变化',
    'Not registered yet': '没有注册？',
    OK: '确定',
    'Order Credits': '购买点数',
    'Password Not Set': '帐户没有密码',
    Password: '密码',
    'Register For Free': '免费注册',
    'Request It': '请求添加',
    'Reset Password': '重置密码',
    Retry: '重试',
    Revert: '还原',
    Save: '保存',
    'Search again': '再次搜索',
    'Set Password': '设置密码',
    Skip: '跳过',
    'Sort By': '排序关键字',
    'Sort Order': '排序',
    'Swipe to Continue': '滑动即可继续',
    Worse: '恶化',
    'Type three or more letters': '请输入三个或更多字母 ',
    Upgrade: '升级',
    'Upgrade to Pro': '升级',
    Yes: '是',
    save_record_and_close: '保存记录并关闭',
    save_and_close: '保存并关闭',
    close_without_saving: '关闭，但不保存',
    back_to_editing: '返回编辑',
    login_request_new_link: '登录帐户，请求新的启动链接',
    contact_support: '联络支持',
  },
};

const shared = (string_name, language) => {
  // Working with auto fallback to English
  if (sharedStrings[language] && sharedStrings[language][string_name]) {
    return sharedStrings[language][string_name];
  } else if (sharedStrings.en && sharedStrings.en[string_name]) {
    return sharedStrings.en[string_name];
  } else {
    console.warn('Not found shared string: ' + string_name);
    return string_name;
  }
};

export { sharedStrings };
export default shared;
