// @flow
import * as types from './actiontypes';
import { getDefaultFields } from '../Fields/constants';
import { MED_NID_CHANGED } from '../Meds/actiontypes';
import { customServerToLocal } from '../Sync/SyncService';
import { LOGOUT } from '../Auth/actiontypes';
import type { FieldList } from './types';
import type { Action } from '../../redux/types';
import { FIELDS_ALL } from './constants';

//State type
type State = FieldList;

function reduceServer(local, serverFields) {
  let localMap = {};
  local = { ...local };
  //We need map of "field name > field" to faster search fields by name
  Object.keys(local).forEach((field) => {
    //ignore ineffective factors
    localMap[field] = {};
    local[field].forEach((f) => {
      if (f && f.name) {
        localMap[field][f.name] = f;
      }
    });
  });

  //We check each field separately
  FIELDS_ALL.forEach((field) => {
    //Find next available id for specific field type
    let nextId = 0;
    local[field].forEach((field) => {
      if (field) {
        nextId = Math.max(nextId, field.id);
      }
    });
    nextId++;

    let serverField =
      field === 'ineffective_factor' ? 'alleviating_factor' : field;
    //Delete local fields which we didn't found in server list
    Object.keys(localMap[field]).forEach((key) => {
      let found = serverFields[serverField].some((f) => f.value === key);
      if (!found) {
        delete localMap[field][key];
      }
    });

    //If we didn't sent anything to server, then retrieve server copy
    serverFields[serverField].forEach((f) => {
      if (localMap[field][f.value]) {
        //We found field in local db, just update it
        localMap[field][f.value] = customServerToLocal(
          f,
          localMap[field][f.value].id,
          field,
          localMap[field][f.value].icon_code,
          localMap[field][f.value].icon_colour,
        );
      } else {
        //we didn't found local field, need to create new one
        localMap[field][f.value] = customServerToLocal(f, nextId, field);
        nextId++;
      }
    });

    //We need to delete local fields, which is not exists on server
    Object.keys(localMap[field]).forEach((name) => {
      if (name && !serverFields[serverField].some((f) => f.value === name)) {
        localMap[field][name].deleted = true;
      }
    });
  });

  Object.keys(localMap).forEach((field) => {
    local[field] = [];
    Object.keys(localMap[field]).forEach((f) => {
      let obj = localMap[field][f];
      local[field][obj.id] = obj;
    });
  });
  return local;
}

function FieldsReducer(state: State = getDefaultFields(), action: Action) {
  switch (action.type) {
    case LOGOUT:
      state = getDefaultFields();
      return state;
    case types.ADD_NEW_DEFAULT_FIELD:
      state = { ...state };
      const fieldType = action.payload.fieldType;
      const newField = action.payload.field;
      if (!newField) {
        return state;
      }
      state[fieldType] = state[fieldType].slice();
      let exists = state[fieldType].find(
        (f) => !!f && f.name === newField.name,
      );
      if (exists) {
        exists = { ...exists };
        exists.icon_code = newField.icon_code;
        exists.icon_color = newField.icon_color;
        exists.systemDefault = true;
        state[fieldType][exists.id] = exists;
      } else {
        let position = 0;
        // state[action.payload.fieldType].forEach(f => {
        //     if (f && f.position > position) {
        //         position = f.position;
        //     }
        // });
        // position++;

        let field = {
          show: false,
          systemDefault: true,
          id: state[action.payload.fieldType].length,
          position: position,
          name: newField.name,
          icon_code: newField.icon_code,
          icon_color: newField.icon_color,
          type: fieldType,
        };
        state[action.payload.fieldType][field.id] = field;
      }
      return state;
    case types.CHANGE_POSITIONS:
      state = { ...state };
      state[action.payload.fieldType] = state[action.payload.fieldType].slice();
      state[action.payload.fieldType].forEach((field) => {
        if (field && action.payload.fields) {
          let newField = action.payload.fields.find(
            (f) => !!f && f.id === field.id,
          );
          if (newField) {
            let newPosition = newField.position;
            if (field.position !== newPosition) {
              field.position = newPosition;
              field.flag_update_show = true;
            }
          }
        }
      });

      if (action.payload.fieldType === 'alleviating_factor') {
        state.ineffective_factor = state.ineffective_factor.slice();
        state.ineffective_factor.forEach((field) => {
          if (field && action.payload.fields) {
            let newField = action.payload.fields.find(
              (f) => !!f && f.id === field.id,
            );
            if (newField) {
              let newPosition = newField.position;
              if (field.position !== newPosition) {
                field.position = newPosition;
                field.flag_update_show = true;
              }
            }
          }
        });
      } else if (action.payload.fieldType === 'ineffective_factor') {
        state.alleviating_factor = state.alleviating_factor.slice();
        state.alleviating_factor.forEach((field) => {
          if (field && action.payload.fields) {
            let newField = action.payload.fields.find(
              (f) => !!f && f.id === field.id,
            );
            if (newField) {
              let newPosition = newField.position;
              if (field.position !== newPosition) {
                field.position = newPosition;
                field.flag_update_show = true;
              }
            }
          }
        });
      }

      return state;
    case types.CHANGE_SHOW:
      state = { ...state };
      state[action.payload.fieldType] = state[action.payload.fieldType].slice();
      state[action.payload.fieldType][action.payload.fieldId] = {
        ...state[action.payload.fieldType][action.payload.fieldId],
      };
      state[action.payload.fieldType][action.payload.fieldId].show = action
        .payload.show
        ? 1
        : 0;
      state[action.payload.fieldType][action.payload.fieldId].flag_update_show =
        true;
      if (
        action.payload.fieldType === 'alleviating_factor' &&
        state.ineffective_factor[action.payload.fieldId]
      ) {
        state.ineffective_factor[action.payload.fieldId].flag_update_show =
          true;
        state.ineffective_factor[action.payload.fieldId].show =
          action.payload.show;
        state.ineffective_factor = state.ineffective_factor.slice();
      } else if (
        action.payload.fieldType === 'ineffective_factor' &&
        state.alleviating_factor[action.payload.fieldId]
      ) {
        state.alleviating_factor[action.payload.fieldId].flag_update_show =
          true;
        state.alleviating_factor[action.payload.fieldId].show =
          action.payload.show;
        state.alleviating_factor = state.alleviating_factor.slice();
      }
      return state;
    case types.DEFAULT_FIELDS_GENERATED:
      return {
        ...state,
        ...action.payload,
      };
    case types.ADD_FIELD:
      let position = 0;
      state[action.payload.fieldType].forEach((f) => {
        if (f && f.position > position) {
          position = f.position;
        }
      });
      position++;

      let field = {
        show: true,
        systemDefault: false,
        id: state[action.payload.fieldType].length,
        position: position,
        name: action.payload.fieldName,
        type: action.payload.fieldType,
        flag_created: true,
      };

      //If we have same value with same name, but 'deleted', we should really delete it from list
      state[action.payload.fieldType].forEach((field) => {
        if (
          field &&
          field.flag_deleted &&
          field.name === action.payload.fieldName
        ) {
          clearExisting(field);
        }
      });
      state[action.payload.fieldType][field.id] = field;
      state[action.payload.fieldType] = state[action.payload.fieldType].slice();
      if (action.payload.fieldType === 'alleviating_factor') {
        state.ineffective_factor[field.id] = {
          ...field,
          type: 'ineffective_factor',
        };
        state.ineffective_factor.forEach((field) => {
          if (
            field &&
            field.flag_deleted &&
            field.name === action.payload.fieldName
          ) {
            clearExisting(field);
          }
        });
        state.ineffective_factor = state.ineffective_factor.slice();
      } else if (action.payload.fieldType === 'ineffective_factor') {
        state.alleviating_factor[field.id] = {
          ...field,
          type: 'alleviating_factor',
        };
        state.alleviating_factor.forEach((field) => {
          if (
            field &&
            field.flag_deleted &&
            field.name === action.payload.fieldName
          ) {
            clearExisting(field);
          }
        });
        state.alleviating_factor = state.alleviating_factor.slice();
      }

      return {
        ...state,
      };
    case types.EDIT_FIELD:
      let editField = state[action.payload.fieldType][action.payload.fieldId];
      if (!editField) {
        return {
          ...state,
        };
      }
      editField = { ...editField };
      editField.name = action.payload.fieldName;

      //We should set flag_updated, only if field already exists on server. Otherwise, if it's still not created, we just need to create it
      if (!editField.flag_created) {
        editField.flag_updated = true;
      }
      //If we have same value with same name, but 'deleted', we should really delete it from list
      state[action.payload.fieldType].forEach((field) => {
        if (
          field &&
          field.flag_deleted &&
          field.name === action.payload.fieldName
        ) {
          clearExisting(field);
        }
      });

      state[action.payload.fieldType][action.payload.fieldId] = editField;
      state[action.payload.fieldType] = state[action.payload.fieldType].slice();
      if (action.payload.fieldType === 'alleviating_factor') {
        state.ineffective_factor[action.payload.fieldId] = {
          ...editField,
          type: 'ineffective_factor',
        };
        state.ineffective_factor.forEach((field) => {
          if (
            field &&
            field.flag_deleted &&
            field.name === action.payload.fieldName
          ) {
            clearExisting(field);
          }
        });
        state.ineffective_factor = state.ineffective_factor.slice();
      } else if (action.payload.fieldType === 'ineffective_factor') {
        state.alleviating_factor[action.payload.fieldId] = {
          ...editField,
          type: 'alleviating_factor',
        };
        state.alleviating_factor.forEach((field) => {
          if (
            field &&
            field.flag_deleted &&
            field.name === action.payload.fieldName
          ) {
            clearExisting(field);
          }
        });
        state.alleviating_factor = state.alleviating_factor.slice();
      }

      return {
        ...state,
      };
    case types.DELETE_FIELD:
      if (!state[action.payload.fieldType][action.payload.fieldId]) {
        return state;
      }
      if (
        state[action.payload.fieldType][action.payload.fieldId].flag_created
      ) {
        //If we just created this field - just delete it
        clearExisting(state[action.payload.fieldType][action.payload.fieldId]);
      } else {
        state[action.payload.fieldType][action.payload.fieldId].flag_deleted =
          true;
        state[action.payload.fieldType][action.payload.fieldId].deleted = true;
      }
      state[action.payload.fieldType] = state[action.payload.fieldType].slice();
      if (action.payload.fieldType === 'alleviating_factor') {
        if (
          state[action.payload.fieldType][action.payload.fieldId].flag_created
        ) {
          //If we just created this field - just delete it
          clearExisting(
            state[action.payload.fieldType][action.payload.fieldId],
          );
        } else {
          state.ineffective_factor[action.payload.fieldId].flag_deleted = true;
          state.ineffective_factor[action.payload.fieldId].deleted = true;
        }
        state.ineffective_factor = state[action.payload.fieldType].slice();
      } else if (action.payload.fieldType === 'ineffective_factor') {
        if (
          state[action.payload.fieldType][action.payload.fieldId].flag_created
        ) {
          //If we just created this field - just delete it
          clearExisting(
            state[action.payload.fieldType][action.payload.fieldId],
          );
        } else {
          state.alleviating_factor[action.payload.fieldId].flag_deleted = true;
          state.alleviating_factor[action.payload.fieldId].deleted = true;
        }
        state.alleviating_factor = state[action.payload.fieldType].slice();
      }
      return {
        ...state,
      };
    case types.SYNC_CUSTOM_LISTS_CLEAR_FLAGS:
      let newState = { ...state };
      //Then clear all flags
      Object.keys(newState).forEach((field) => {
        newState[field] = newState[field].map((f) => {
          if (f) {
            if (
              f.flag_created ||
              f.flag_deleted ||
              f.flag_updated ||
              f.flag_update_show
            ) {
              return {
                ...f,
                flag_updated: false,
                flag_deleted: false,
                flag_created: false,
                flag_update_show: false,
              };
            } else {
              return f;
            }
          }
        });
      });

      return newState;
    case types.SYNC_CUSTOM_LISTS_SUCCESS:
      if (action.custom_lists) {
        return reduceServer(state, action.custom_lists);
      }
      return state;
    case types.SYNC_CUSTOM_LISTS_UPGRADE:
      if (action.custom_lists) {
        return { ...action.custom_lists };
      }
      return state;
    case types.ADD_OFFLINE_MEDICATION_TO_FIELDS:
      const alleviating = { ...action.payload.alleviating_med };
      const aggravating = { ...action.payload.aggravating_med };
      const ineffective = { ...action.payload.ineffective_med };
      alleviating.id = state.alleviating_factor.length;
      aggravating.id = state.aggravating_factor.length;
      ineffective.id = state.ineffective_factor.length;
      return {
        ...state,
        alleviating_factor: [...state.alleviating_factor, alleviating],
        aggravating_factor: [...state.aggravating_factor, aggravating],
        ineffective_factor: [...state.ineffective_factor, ineffective],
      };

    case types.EDIT_OFFLINE_MEDICATION_IN_FIELDS: {
      const nid = action.payload.nid;
      const _alleviating = { ...action.payload.alleviating_med };
      const _aggravating = { ...action.payload.aggravating_med };
      const _ineffective = { ...action.payload.ineffective_med };

      const new_alleviating_factor = state.alleviating_factor.map((factor) => {
        if (factor?.medication?.[0]?.nid == nid) {
          return {
            ...factor,
            medication: _alleviating.medication,
            name: _alleviating.name,
          };
        } else {
          return factor;
        }
      });

      const new_aggravating_factor = state.aggravating_factor.map((factor) => {
        if (factor?.medication?.[0]?.nid == nid) {
          return {
            ...factor,
            medication: _aggravating.medication,
            name: _aggravating.name,
          };
        } else {
          return factor;
        }
      });

      const new_ineffective_factor = state.ineffective_factor.map((factor) => {
        if (factor?.medication?.[0]?.nid == nid) {
          return {
            ...factor,
            medication: _ineffective.medication,
            name: _ineffective.name,
          };
        } else {
          return factor;
        }
      });

      return {
        ...state,
        alleviating_factor: new_alleviating_factor,
        aggravating_factor: new_aggravating_factor,
        ineffective_factor: new_ineffective_factor,
      };
    }

    case types.DELETE_OFFLINE_MEDICATION_FROM_FIELDS: {
      // For some reason there are null factors
      return {
        ...state,
        alleviating_factor: state.alleviating_factor.filter(
          (factor) =>
            !factor ||
            !factor.isMedication ||
            factor.medication?.[0]?.nid !== action.payload,
        ),
        aggravating_factor: state.aggravating_factor.filter(
          (factor) =>
            !factor ||
            !factor.isMedication ||
            factor.medication?.[0]?.nid !== action.payload,
        ),
        ineffective_factor: state.ineffective_factor.filter(
          (factor) =>
            !factor ||
            !factor.isMedication ||
            factor.medication?.[0]?.nid !== action.payload,
        ),
      };
    }

    case types.ADD_OFFLINE_MEDICATIONS_ARRAY_TO_FIELDS:
      const alleviating_meds = [];
      const aggravating_meds = [];
      const ineffective_meds = [];
      for (let i = 0; i < action.payload.alleviating_meds.length; i++) {
        alleviating_meds.push({
          ...action.payload.alleviating_meds[i],
          id: state.alleviating_factor.length + i,
        });
      }
      for (let i = 0; i < action.payload.aggravating_meds.length; i++) {
        aggravating_meds.push({
          ...action.payload.aggravating_meds[i],
          id: state.aggravating_factor.length + i,
        });
      }
      for (let i = 0; i < action.payload.ineffective_meds.length; i++) {
        ineffective_meds.push({
          ...action.payload.ineffective_meds[i],
          id: state.ineffective_factor.length + i,
        });
      }
      return {
        ...state,
        alleviating_factor: [...state.alleviating_factor, ...alleviating_meds],
        aggravating_factor: [...state.aggravating_factor, ...aggravating_meds],
        ineffective_factor: [...state.ineffective_factor, ...ineffective_meds],
      };

    case MED_NID_CHANGED:
      state = { ...state };
      [
        'alleviating_factor',
        'aggravating_factor',
        'ineffective_factor',
      ].forEach((factor) => {
        state[factor] = state[factor].slice();
        state[factor].forEach((rec) => {
          if (
            rec?.medication?.find((med) => med.nid == action.payload.old_nid)
          ) {
            let recIndex = state[factor].indexOf(rec);
            rec = { ...rec };
            //                        rec.medication = rec.medication.slice();
            rec.medication = rec.medication.map((m) => {
              return {
                ...m,
                nid:
                  m.nid == action.payload.old_nid ? action.payload.nid : m.nid,
              };
            });
            state[factor][recIndex] = rec;
          }
        });
      });
      return state;

    default:
      return state;
  }
}

export { FieldsReducer };

function clearExisting(existing) {
  if (existing) {
    delete existing.flag_created;
    delete existing.flag_update_show;
    delete existing.flag_updated;
    delete existing.flag_deleted;
    existing.deleted = true;
  }
}
