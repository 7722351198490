export default {
  cardHeader: '#F4F7F9',
  cardContainer: '#FFFFFF',
  cardInternalContent: '#FFFFFF',
  cardLightBorder: '#EAEAEA',
  cardBorder: '#9CA3AF',

  navItemActive: '#DFF2FD',

  textPrimary: '#000000',
  textSecondary: '#56595C',
  textLink: '#0EA5E9',

  buttonBlue: '#137CC3',
  buttonFooterBackground: '#A5A5A5',

  pageBackground: '#FFFFFF',
  defaultWindowBg: '#EAEAEA',
  defaultBackground: '#FFFFFF',
  disabledBackground: '#E3E3E3',
  activeItemBackground: '#EAEAEA',

  statusBarBackground: '#137CC3',
  statusBarBackgroundAndroid: '#0C74AD',
  bottomBarBackground: '#FFFFFF',
  menuPopupBackground: '#EAEAEA',

  calendarWeekdayHeader: '#137CC3',
  calendarDayHeader: '#bbd9e9',

  loginButtonBorder: '#000000',
  facebookButtonColor: '#4267B2',

  redResults: '#a93636',
  greenResults: '#38761d',

  blue: '#137CC3',
  opacityBlue: '#137CC380',
  darkBlue: '#0C74AD',
  darkestBlue: '#0C71AA',
  lightBlue: '#35B2F8',
  skyBlue: '#00C0FA',
  brightBlue: '#036DED',
  red: '#b00020',
  linkButton: '#2F80ED',
  bgDarkBlue: '#0049AC',
  bgLightBlue: '#C5DCFA',
  bgLightGray: '#EEF7FF',

  redDanger: '#FF2929',
  redSoft: '#FF8282',

  // The following colours are used only on the website
  webBgBlue: '#F4F7F9', // - used for background in download section at the homepage
  bgBlue: '#F4F7F9',
  orange: '#F28D10',

  white: '#FFFFFF',
  whiteBackground: '#F2F2F2',
  black: '#000000',
  lightGreen: '#52C975',

  gray: '#818181', // Usual gray color for texts
  lightGray: '#A5A5A5', // For placeholders and cancel button
  liteGray: '#C6C6C6', // For placeholders and cancel button
  darkGray: '#6F6F6F', // For icon-buttons or long text on white background
  darkGrayRecords: '#56595C',
  grayLine: '#EAEAEA', // Light gray border color,
  grayBackgroundForItem: '#E3E3E3',

  inputsBackground: '#EAEAEA',
  inputsBackgroundLight: '#FAF8F8',
  nativeTouchFeedbackAndroid: '#DFF2FD',

  helpersGray600: '#4B5563',
};
