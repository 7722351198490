import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import TouchableNative from './TouchableNative';
import Text from './Text';
import { designConstants } from '../styles';
import { toSentenceCase } from '../../shared/utilities/stringsutils';
import { trackButtonPress } from '../../shared/utilities/gatracker';
import setTestID from '../../shared/utilities/setTestID';
import { withTheme } from '../styles/useThemeContext';

// Default button look is gray text with sentence case
class OutlinedButton extends Component {
  onPress = () => {
    trackButtonPress('Button ' + (this.props.tag || this.props.text));
    this.props.onPress();
  };

  render() {
    const { colors } = this.props.theme;
    let {
      lightBlue,
      lightGray,
      white,
      gray,
      lightGreen,
      red,
      style,
      text,
      medium,
      small,
      disabled,
      ignoreSentenceCase,
    } = this.props;

    let textColor = colors.gray;
    if (lightBlue) {
      textColor = colors.lightBlue;
    }
    if (lightGray) {
      textColor = colors.lightGray;
    }
    if (white) {
      textColor = colors.white;
    }
    if (gray) {
      textColor = colors.gray;
    }
    if (lightGreen) {
      textColor = colors.lightGreen;
    }
    if (red) {
      textColor = colors.redSoft;
    }
    return (
      <TouchableNative
        onPress={this.onPress}
        {...setTestID(this.props.tag || this.props.text)}
        accesible={true}
        style={[
          lowEmphasisButtonStyles.rippleWrapper,
          disabled && {
            borderColor: colors.lightGray,
            color: colors.lightGray,
          },
          style,
        ]}
      >
        <Text
          center
          style={[
            {
              color: textColor,
              textAlign: 'center',
              textAlignVertical: 'center',
              padding: designConstants.paddings.buttonMediumEmphasisPadding,
            },
            medium && { fontSize: designConstants.fontSizes.medium },
            small && { fontSize: designConstants.fontSizes.small },
            disabled && {
              borderColor: colors.lightGray,
              color: colors.lightGray,
            },
          ]}
        >
          {ignoreSentenceCase ? text : toSentenceCase(text)}
        </Text>
      </TouchableNative>
    );
  }
}

const lowEmphasisButtonStyles = StyleSheet.create({
  rippleWrapper: {
    minHeight: designConstants.heights.touchableMinHeight,
    borderRadius: 15,
    borderWidth: designConstants.onePixel,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

OutlinedButton.propTypes = {
  lightBlue: PropTypes.bool,
  lightGray: PropTypes.bool,
  gray: PropTypes.bool,
  lightGreen: PropTypes.bool,
  caps: PropTypes.bool,
  // style: ViewPropTypes.style,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  minTextHeight: PropTypes.number,
  onPress: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ignoreSentenceCase: PropTypes.bool,
};

OutlinedButton.defaultProps = {
  lightBlue: false,
  lightGray: false,
  gray: false,
  lightGreen: false,
  medium: false,
  small: false,
  caps: false,
  minTextHeight: 0,
  style: {},
  onPress: () => null,
  text: '',
  toSentenceCase: false,
};

export default withTheme(OutlinedButton);
