import LocalizedStrings from 'react-native-localization';
import { pluralFunc } from '../../strings/plurals';
import shared from '../../strings/shared';

const PLURALS = [];

const STRINGS = new LocalizedStrings({
  en: {
    upgrade_controller_performing_upgrade: 'Updating app...',

    upgrade_slider_step1_header: 'Manage My Pain\nhas been re-designed!',
    upgrade_slider_step1_description:
      'The look and feel of the app has been modernized.',
    upgrade_slider_step2_header: 'Track effectiveness better',
    upgrade_slider_step2_description:
      'Record the impact of medications and other interventions more intuitively.',
    upgrade_slider_step3_header: 'Better visuals for your pain',
    upgrade_slider_step3_description:
      'Icons now make it even easier to find the most appropriate values.',
    upgrade_slider_step4_header: 'More powerful insights',
    upgrade_slider_step4_description:
      'Analyze your pain through relevant stats and graphs (limited to Last 30 days for Lite users)',
    upgrade_slider_got_it_button: shared('Got It', 'en'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'en'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'en'),
  },
  de: {
    upgrade_controller_performing_upgrade: 'Aktualisiere App…',

    upgrade_slider_step1_header: 'Manage My Pain\n hat ein neues Design!',
    upgrade_slider_step1_description:
      'Der Look und die Bedienung der App wurden modernisiert.',
    upgrade_slider_step2_header: 'Wirksamkeit besser nachhalten',
    upgrade_slider_step2_description:
      'Halten Sie die Wirkung von Medikamenten und anderen Interventionen auf intuitivere Weise fest.',
    upgrade_slider_step3_header:
      'Verbesserte visuelle Darstellung Ihrer Schmerzen',
    upgrade_slider_step3_description:
      'Symbole erleichtern das Finden der passendsten Werte.',
    upgrade_slider_step4_header: 'Aussagekräftigere Datendetails',
    upgrade_slider_step4_description:
      'Analysieren Sie Ihre Schmerzen mithilfe von relevanten Statistiken und Diagrammen (für Nutzer der Lite-Version auf die letzten 30 Tage beschränkt)',
    upgrade_slider_got_it_button: shared('Got It', 'de'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'de'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'de'),
  },
  es: {
    upgrade_controller_performing_upgrade: 'Actualizando aplicación…',

    upgrade_slider_step1_header: '¡Manage My Pain tiene un nuevo diseño!',
    upgrade_slider_step1_description:
      'La apariencia y la experiencia de la aplicación se han modernizado.',
    upgrade_slider_step2_header: 'Lleve un mejor registro de la efectividad',
    upgrade_slider_step2_description:
      'Registre el impacto de los medicamentos y otras intervenciones de forma más intuitiva.',
    upgrade_slider_step3_header: 'Podrá visualizar mejor su dolor',
    upgrade_slider_step3_description:
      'Los íconos ahora le hacen más fácil encontrar los valores más adecuados.',
    upgrade_slider_step4_header: 'Perspectivas más potentes',
    upgrade_slider_step4_description:
      'Analice su dolor con estados y gráficos relevantes (limitado a los últimos 30 días para usuarios de la versión Lite)',
    upgrade_slider_got_it_button: shared('Got It', 'es'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'es'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'es'),
  },
  fr: {
    upgrade_controller_performing_upgrade: 'Mise à jour en cours…',

    upgrade_slider_step1_header: 'Manage My Pain a été reconçu!',
    upgrade_slider_step1_description:
      "L'aspect et sensation sont maintenant modernisés.",
    upgrade_slider_step2_header: "Suivez mieux l'efficacité",
    upgrade_slider_step2_description:
      "Enregistrez l'impact des médicaments et d'autres interventions plus intuitivement.",
    upgrade_slider_step3_header: 'Une meilleure visualisation de votre douleur',
    upgrade_slider_step3_description:
      'Les icônes facilitent désormais la recherche des valeurs les plus appropriées.',
    upgrade_slider_step4_header: 'Des perspectives plus puissantes',
    upgrade_slider_step4_description:
      'Analysez votre douleur avec des états et des graphiques (limité aux 30 derniers jours pour les utilisateurs Lite)',
    upgrade_slider_got_it_button: shared('Got It', 'fr'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'fr'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'fr'),
  },
  ko: {
    upgrade_controller_performing_upgrade: '앱 업데이트 중…',

    upgrade_slider_step1_header: 'Manage My Pain이\n 재설계되었습니다!',
    upgrade_slider_step1_description: '앱의 모양과 느낌이 현대화됐습니다.',
    upgrade_slider_step2_header: '효과를 더 잘 추적합니다',
    upgrade_slider_step2_description:
      '약물과 다른 개입의 영향을 좀 더 직관적으로 기록하세요.',
    upgrade_slider_step3_header: '당신의 통증에 대해 더 잘 볼 수 있습니다',
    upgrade_slider_step3_description:
      '아이콘 덕분에 이제 가장 적절한 값을 찾기가 훨씬 더 쉬워졌습니다.',
    upgrade_slider_step4_header: '보다 강력한 통찰력을 제공합니다',
    upgrade_slider_step4_description:
      '관련 통계 및 그래프를 통해 통증을 분석합니다(라이트 사용자의 경우 최근 30일로 제한됨).',
    upgrade_slider_got_it_button: shared('Got It', 'ko'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'ko'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'ko'),
  },

  ru: {
    upgrade_controller_performing_upgrade: 'Обновление приложения...',

    upgrade_slider_step1_header:
      'Приложение Manage My Pain получило новый дизайн!',
    upgrade_slider_step1_description:
      'Усовершенствованный интерфейс и управление.',
    upgrade_slider_step2_header: 'Улучшено отслеживание эффективности',
    upgrade_slider_step2_description:
      'Интуитивная запись влияния препаратов и других вмешательств.',
    upgrade_slider_step3_header: 'Доработанная визуализация боли',
    upgrade_slider_step3_description:
      'Иконки позволяют проще находить нужные значения.',
    upgrade_slider_step4_header: 'Улучшенные возможности анализа',
    upgrade_slider_step4_description:
      'Анализируйте свою боль при помощи статистики и графиков (для пользователей версии Lite срок ограничен 30 днями).',
    upgrade_slider_got_it_button: shared('Got It', 'ru'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'ru'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'ru'),
  },
  'zh-rCN': {
    upgrade_controller_performing_upgrade: '正在更新应用...',

    upgrade_slider_step1_header: 'Manage My Pain\n有了全新的设计！',
    upgrade_slider_step1_description: '应用程序有了更加现代的外观和体验。',
    upgrade_slider_step2_header: '更好地跟踪有效性',
    upgrade_slider_step2_description: '更直观地记录药物和其他干预措施的影响。',
    upgrade_slider_step3_header: '为您的疼痛提供更好的视觉效果',
    upgrade_slider_step3_description:
      '现在，通过图标可以更轻松地找到最合适的值。',
    upgrade_slider_step4_header: '更强大的洞察',
    upgrade_slider_step4_description:
      '通过相关的统计数据和图表分析您的疼痛（精简版用户仅限最近30天）',
    upgrade_slider_got_it_button: shared('Got It', 'zh-rCN'),
    upgrade_slider_skip_introduction_button: shared('Skip', 'zh-rCN'),
    upgrade_slider_swipe_to_continue: shared('Swipe to Continue', 'zh-rCN'),
  },
});

STRINGS.plurals = pluralFunc(STRINGS, PLURALS);
export default STRINGS;
