export const FILTER_CLEAR = 'FILTER_CLEAR';
export const FILTER_RECORDS_START = 'FILTER_RECORDS_START';
export const FILTER_RECORDS_DATERANGE_END = 'FILTER_RECORDS_DATERANGE_END';
export const FILTER_RECORDS_RANGE_CHANGED = 'FILTER_RECORDS_RANGE_CHANGED';
export const FILTER_RECORDS_END = 'FILTER_RECORDS_END';
export const FILTER_RECORDS_FINISHED = 'FILTER_RECORDS_FINISHED';
export const FILTER_APPLY_REPORT = 'FILTER_APPLY_REPORT';
export const FILTER_APPLY_RESULTS = 'FILTER_APPLY_RESULTS';
export const FILTER_LOAD = 'FILTER_LOAD';
export const FILTER_CHECK_FIELD = 'FILTER_CHECK_FIELD';
export const FILTER_CHECK_FACTOR = 'FILTER_CHECK_FACTOR';
export const FILTER_CHECK_MEDICATION = 'FILTER_CHECK_MEDICATION';
export const FILTER_SET_MODE = 'FILTER_SET_MODE';
export const FILTER_CHECK_TYPE = 'FILTER_CHECK_TYPE';
export const FILTER_SET_SEVERITY = 'FILTER_SET_SEVERITY';
export const FILTER_SET_SCORE = 'FILTER_SET_SCORE';
export const FILTER_SET_DATE = 'FILTER_SET_DATE';
export const FILTER_SET_DATE_RANGE = 'FILTER_SET_DATE_RANGE';
export const FILTER_SET_END_DATE = 'FILTER_SET_END_DATE';
export const FILTER_SET_TIME = 'FILTER_SET_TIME';
export const FILTER_SET_DURATION = 'FILTER_SET_DURATION';
export const FILTER_SET_DATE_MODE = 'FILTER_SET_DATE_MODE';
export const FILTER_SET_TIME_MODE = 'FILTER_SET_TIME_MODE';
export const FILTER_SET_DURATION_UNITS = 'FILTER_SET_DURATION_UNITS';
export const FILTER_CHECK_TYPE_OF_PAIN = 'FILTER_CHECK_TYPE_OF_PAIN';
export const FILTER_SET_DURATION_MODE = 'FILTER_SET_DURATION_MODE';
export const FILTER_RECORDS_WITHOUT_DATERANGE =
  'FILTER_RECORDS_WITHOUT_DATERANGE';

export const FILTER_UPDATE_DATE_AND_DATE_END_VALUES =
  'FILTER_UPDATE_DATE_AND_DATE_END_VALUES';

export const FILTER_RECORDS_PARSER_FINISHED = 'FILTER_RECORDS_PARSER_FINISHED';
