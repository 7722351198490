export default function allSettled(promises) {
  let settled = promises.map((p) =>
    p.then(
      (v) => ({ status: 'fulfilled', value: v }),
      (r) => ({ status: 'rejected', reason: r }),
    ),
  );
  return Promise.all(settled);
}

export async function allSettledSeq(promisesFunc) {
  const settled = [];
  for (const promise of promisesFunc) {
    try {
      const result = await promise();
      settled.push({ status: 'fulfilled', value: result });
    } catch (err) {
      settled.push({ status: 'rejected', reason: err });
    }
  }
  return Promise.resolve(settled);
}
