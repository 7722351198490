const predefinedData = {
  painConditions: [
    {
      fid: 78,
      value: 'Arthritis',
      localized_values: {
        en: 'Arthritis',
        fr: 'arthrite',
        de: 'Gelenkentzündung',
        es: 'Artritis',
        ru: 'Артрит',
        ko: '관절염',
        'zh-rCN': '关节炎',
      },
    },

    {
      fid: 79,
      value: 'Back Pain',
      localized_values: {
        en: 'Back pain',
        fr: 'mal de dos',
        de: 'Rückenschmerzen',
        es: 'Dolor de espalda',
        ru: 'Боль в спине',
        ko: '요통',
        'zh-rCN': '背痛',
      },
    },

    {
      fid: 96,
      value: 'Fibromyalgia (FM)',
      localized_values: {
        en: 'Fibromyalgia (FM)',
        fr: 'fibromyalgie',
        de: 'Fibromyalgie',
        es: 'Fibromialgia',
        ru: 'Фибромиалгия',
        ko: '섬유근육통',
        'zh-rCN': '纤维肌痛',
      },
    },

    {
      fid: 97,
      value: 'Headaches',
      localized_values: {
        en: 'Headaches',
        fr: 'mal de tête',
        de: 'Kopfschmerzen',
        es: 'Dolor de cabeza',
        ru: 'Головная боль',
        ko: '두통',
        'zh-rCN': '头痛',
      },
    },
    {
      fid: 104,
      value: 'Neck (Cervical) pain',
      localized_values: {
        en: 'Neck (Cervical) pain',
        fr: 'cervicalgie',
        de: 'Nackenschmerzen',
        es: 'Dolor de cuello',
        ru: 'Боль в шее',
        ko: '경부통',
        'zh-rCN': '颈痛',
      },
    },
    {
      fid: 261971,
      value: 'Unknown / Undiagnosed',
      localized_values: {
        en: 'Unknown / Undiagnosed',
        fr: 'Inconnue / non diagnostiquée',
        de: 'Unbekannt / Nicht diagnostiziert',
        es: 'Desconocido / No diagnosticado',
        ru: 'Неизвестно/Диагноз отсутствует',
        ko: '알 수 없음 / 진단되지 않음',
        'zh-rCN': '未知 / 未确诊',
      },
    },
  ],

  medications: [
    {
      common_name: 'Duloxetine hydrochloride',
      take_as_value: 'scheduled & needed',
      dosage_units: null,
      common_name_translations: [
        { language: 'en', value: 'Duloxetine hydrochloride' },
        { language: 'de', value: 'Duloxetinhydrochlorid' },
        { language: 'es', value: 'Duloxetina hidrocloruro' },
        { language: 'fr', value: 'Chlorhydrate de duloxétine' },
        { language: 'ko', value: '둘록세틴 염산염' },
        { language: 'ru', value: 'Дулоксетина гидрохлорид' },
        { language: 'zh-hans', value: '盐酸度洛西汀' },
      ],
      top_dosage_units: ['tablets'],
      top_strengths: ['325mg', '60mg', '7.5mg'],
      default_brands: ['Generic', 'Cymbalta'],
    },

    {
      common_name: 'Gabapentin',
      take_as_value: 'scheduled & needed',
      dosage_units: null,
      common_name_translations: [
        { language: 'en', value: 'Gabapentin' },
        { language: 'de', value: 'Gabapentin' },
        { language: 'es', value: 'Gabapentina' },
        { language: 'fr', value: 'Gabapentine' },
        { language: 'ko', value: '가바펜틴' },
        { language: 'ru', value: 'Габапентин' },
        { language: 'zh-hans', value: '加巴喷丁' },
      ],
      top_dosage_units: ['capsules'],
      top_strengths: ['100mg', '300mg', '600mg'],
      default_brands: ['Generic', 'Gralise', 'Neurontin'],
    },

    {
      common_name: 'Hydrocodone bitartrate - acetaminophen mixt',
      take_as_value: 'scheduled & needed',
      dosage_units: null,
      common_name_translations: [
        {
          language: 'en',
          value: 'Hydrocodone bitartrate - acetaminophen mixt',
        },
        { language: 'de', value: 'Hydrocodonbitartrat - Acetaminophen Kombi' },
        {
          language: 'es',
          value: 'Hidrocodona bitartrato - acetaminofén combinados',
        },
        {
          language: 'fr',
          value: "Combi Bitartrate d'hydrocodone - acétaminophène",
        },
        { language: 'ko', value: '히드로코돈타르타르산염-아세트아미노펜 혼합' },
        { language: 'ru', value: 'Гидрокодона битартрат - Ацетаминофен комб.' },
        { language: 'zh-hans', value: '酒石酸氢可酮、对乙酰氨基酚的联合用药' },
      ],
      top_dosage_units: ['tablets'],
      top_strengths: ['5/325mg', '10/325mg'],
      default_brands: ['Generic', 'Norco', 'Vicodin'],
    },

    {
      common_name: 'Ibuprofen',
      take_as_value: 'scheduled & needed',
      dosage_units: null,
      common_name_translations: [
        { language: 'en', value: 'Ibuprofen' },
        { language: 'de', value: 'Ibuprofen' },
        { language: 'es', value: 'Ibuprofeno' },
        { language: 'fr', value: 'Ibuprofène' },
        { language: 'ko', value: '이부프로펜' },
        { language: 'ru', value: 'Ибупрофен' },
        { language: 'zh-hans', value: '布洛芬' },
      ],
      top_dosage_units: ['tablets'],
      top_strengths: ['200mg', '400mg', '800mg'],
      default_brands: ['Generic', 'Advil', 'Ibuflam', 'Motrin'],
    },

    {
      common_name: 'Pregabalin',
      take_as_value: 'scheduled & needed',
      dosage_units: null,
      common_name_translations: [
        { language: 'en', value: 'Pregabalin' },
        { language: 'de', value: 'Pregabalin' },
        { language: 'es', value: 'Pregabalina' },
        { language: 'fr', value: 'Prégabaline' },
        { language: 'ko', value: '프레가발린' },
        { language: 'ru', value: 'Прегабалин' },
        { language: 'zh-hans', value: '普瑞巴林' },
      ],
      top_dosage_units: ['capsules'],
      top_strengths: ['50mg', '100mg', '150mg', '200mg', '300mg'],
      default_brands: ['Generic', 'Lyrica'],
    },
  ],
};

export default predefinedData;
