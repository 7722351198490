// This file serves every action which could be performed by both Views and Logic

export const ADD_TEMPORARY_PAIN_CONDITION_INFO =
  'ADD_TEMPORARY_PAIN_CONDITION_INFO';
export const EDIT_TEMPORARY_PAIN_CONDITION_INFO_SUBSTITUTE_FID_AND_VALUE =
  'EDIT_TEMPORARY_PAIN_CONDITION_INFO_SUBSTITUTE_FID_AND_VALUE';
export const EDIT_TEMPORARY_PAIN_CONDITION_INFO =
  'EDIT_TEMPORARY_PAIN_CONDITION_INFO';
export const EDIT_TEMPORARY_PAIN_CONDITION_INFO_SAVING =
  'EDIT_TEMPORARY_PAIN_CONDITION_INFO_SAVING';
export const DELETE_TEMPORARY_PAIN_CONDITION_INFO =
  'DELETE_TEMPORARY_PAIN_CONDITION_INFO';
export const SET_OFFLINE_ID_FID_NID = 'SET_OFFLINE_ID_FID_NID';
export const DELETE_OFFLINE_PAIN_CONDITION_MEDS =
  'DELETE_OFFLINE_PAIN_CONDITION_MEDS';
export const REMOVE_DELETED_MEDS = 'REMOVE_DELETED_MEDS';

export const ADD_TEMPORARY_MEDICATION_INFO = 'ADD_TEMPORARY_MEDICATION_INFO';
export const EDIT_TEMPORARY_MEDICATION_STRENGTH =
  'EDIT_TEMPORARY_MEDICATION_STRENGTH';
export const EDIT_TEMPORARY_MEDICATION_DOSAGE_UNITS =
  'EDIT_TEMPORARY_MEDICATION_DOSAGE_UNITS';
export const EDIT_TEMPORARY_MEDICATION_STRENGTH_UNITS =
  'EDIT_TEMPORARY_MEDICATION_STRENGTH_UNITS';
export const EDIT_TEMPORARY_MEDICATION_INFO = 'EDIT_TEMPORARY_MEDICATION_INFO';
export const EDIT_TEMPORARY_MEDICATION_INFO_SAVING =
  'EDIT_TEMPORARY_MEDICATION_INFO_SAVING';
export const DELETE_TEMPORARY_MEDICATION_INFO =
  'DELETE_TEMPORARY_MEDICATION_INFO';
export const EDIT_TEMPORARY_MEDICATION_PURPOSE =
  'EDIT_TEMPORARY_MEDICATION_PURPOSE';
export const EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE =
  'EDIT_TEMPORARY_MEDICATION_DELETE_PURPOSE';
export const EDIT_TEMPORARY_MEDICATION_START_DATE =
  'EDIT_TEMPORARY_MEDICATION_START_DATE';
export const EDIT_TEMPORARY_MEDICATION_END_DATE =
  'EDIT_TEMPORARY_MEDICATION_END_DATE';
export const EDIT_TEMPORARY_MEDICATION_TAKE_AS_VALUE =
  'EDIT_TEMPORARY_MEDICATION_TAKE_AS_VALUE';
export const EDIT_TEMPORARY_MEDICATION_MED_NOTES =
  'EDIT_TEMPORARY_MEDICATION_MED_NOTES';
export const EDIT_TEMPORARY_MEDICATION_SPECIAL_INSTRUCTIONS =
  'EDIT_TEMPORARY_MEDICATION_SPECIAL_INSTRUCTIONS';
export const EDIT_TEMPORARY_MEDICATION_ADD_DOSAGE =
  'EDIT_TEMPORARY_MEDICATION_ADD_DOSAGE';
export const EDIT_TEMPORARY_MEDICATION_EDIT_DOSAGE =
  'EDIT_TEMPORARY_MEDICATION_EDIT_DOSAGE';
export const EDIT_TEMPORARY_MEDICATION_DELETE_DOSAGE =
  'EDIT_TEMPORARY_MEDICATION_DELETE_DOSAGE';
export const EDIT_TEMPORARY_MEDICATION_SELECT_BRAND =
  'EDIT_TEMPORARY_MEDICATION_SELECT_BRAND';
export const EDIT_TEMPORARY_MEDICATION_SELECT_MEDICATION =
  'EDIT_TEMPORARY_MEDICATION_SELECT_MEDICATION';
export const CLEAR_TEMPORARY_MEDICATION = 'CLEAR_TEMPORARY_MEDICATION';
export const SEARCH_BRANDS_REQUEST = 'SEARCH_BRANDS_REQUEST';
export const SEARCH_BRANDS_SUCCESS = 'SEARCH_BRANDS_SUCCESS';
export const SEARCH_BRANDS_FAILURE = 'SEARCH_BRANDS_FAILURE';
export const DELETE_OFFLINE_MEDICATION_MEDS = 'DELETE_OFFLINE_MEDICATION_MEDS';

export const REMOVE_NETWORK_ERROR_FOR_MEDICATIONS_BRANDS =
  'REMOVE_NETWORK_ERROR_FOR_MEDICATIONS_BRANDS';

//medication types
export const PREPARE_NEW_MEDICATION = 'PREPARE_NEW_MEDICATION';
export const ADD_PAIN_CONDITION = 'ADD_PAIN_CONDITION';
export const EDIT_PAIN_CONDITION = 'EDIT_PAIN_CONDITION';
export const DELETE_PAIN_CONDITION = 'DELETE_PAIN_CONDITION';
export const ADD_MEDICATION = 'ADD_MEDICATION';
export const EDIT_MEDICATION = 'EDIT_MEDICATION';
export const EDIT_MEDICATION_NAME = 'EDIT_MEDICATION_NAME';
export const DELETE_MEDICATION = 'DELETE_MEDICATION';
export const ADD_PAIN_CONDITION_SUCCESS = 'ADD_PAIN_CONDITION_SUCCESS';
export const EDIT_PAIN_CONDITION_SUCCESS = 'EDIT_PAIN_CONDITION_SUCCESS';
export const DELETE_PAIN_CONDITION_SUCCESS = 'DELETE_PAIN_CONDITION_SUCCESS';
export const ADD_MEDICATION_SUCCESS = 'ADD_MEDICATION_SUCCESS';
export const EDIT_MEDICATION_SUCCESS = 'EDIT_MEDICATION_SUCCESS';
export const DELETE_MEDICATION_SUCCESS = 'DELETE_MEDICATION_SUCCESS';
export const MED_NID_CHANGED = 'MED_NID_CHANGED';

//current med clear
export const MED_SET_CURRENT = 'MED_SET_CURRENT';
export const MED_SET_STRENGTH = 'MED_SET_STRENGTH';
export const MED_SET_STRENGTH_UNITS = 'MED_SET_STRENGTH_UNITS';
export const MED_CLEAR = 'MED_CLEAR';
export const MED_SET_START_DATE = 'MED_SET_START_DATE';
export const MED_SET_END_DATE = 'MED_SET_END_DATE';
export const MED_SET_TAKE_AS = 'MED_SET_TAKE_AS';
export const MED_SET_FORM = 'MED_SET_FORM';
export const MED_SET_SPECIAL_INSTRUCTIONS = 'MED_SET_SPECIAL_INSTRUCTIONS';
export const MED_ADD_DOSAGE = 'MED_ADD_DOSAGE';
export const MED_EDIT_DOSAGE = 'MED_EDIT_DOSAGE';
export const MED_DELETE_DOSAGE = 'MED_DELETE_DOSAGE';
export const MED_ADD_PURPOSE = 'MED_ADD_PURPOSE';
export const MED_DELETE_PURPOSE = 'MED_DELETE_PURPOSE';
export const MED_SET_NOTE = 'MED_SET_NOTE';
export const MED_CHECK_PURPOSE = 'MED_CHECK_PURPOSE';
