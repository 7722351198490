import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Entypo from 'react-native-vector-icons/Entypo';
// import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
// import Foundation from 'react-native-vector-icons/Foundation';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
// import Octicons from 'react-native-vector-icons/Octicons';
// import Zocial from 'react-native-vector-icons/Zocial';
// import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
import MMPIcon from '../../shared/components/MMPIcon';
import { designConstants } from '../styles';

// This component is almost copy of native-base icon, excluding theme using
export default class Icon extends Component {
  Icon = null;

  setIcon(iconType) {
    if (iconType === undefined && this.context.theme) {
      iconType = 'MaterialCommunityIcons';
    }
    switch (iconType) {
      case 'Entypo':
        this.Icon = Entypo;
        break;
      // case 'EvilIcons':
      //   this.Icon = EvilIcons;
      //   break;
      // case 'Feather':
      //   this.Icon = Feather;
      //   break;
      case 'FontAwesome':
        this.Icon = FontAwesome;
        break;
      // case 'Foundation':
      //   this.Icon = Foundation;
      //   break;
      case 'Ionicons':
        this.Icon = Ionicons;
        break;
      case 'MaterialCommunityIcons':
        this.Icon = MaterialCommunityIcons;
        break;
      case 'MaterialIcons':
        this.Icon = MaterialIcons;
        break;
      // case 'Octicons':
      //   this.Icon = Octicons;
      //   break;
      // case 'SimpleLineIcons':
      //   this.Icon = SimpleLineIcons;
      //   break;
      // case 'Zocial':
      //   this.Icon = Zocial;
      //   break;
      case 'MMPIcon':
        this.Icon = MMPIcon;
        break;
      default:
        this.Icon = Ionicons;
    }
  }

  UNSAFE_componentWillMount() {
    this.setIcon(this.props.type);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.type && this.props.type !== nextProps.type) {
      this.setIcon(nextProps.type);
    }
  }

  render() {
    const { size } = this.props;
    const { style, ...otherProps } = this.props;
    return (
      <this.Icon
        {...otherProps}
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textAlignVertical: 'center',
            height: size,
            width: size,
          },
          style,
        ]}
      />
    );
  }
}

Icon.propTypes = {
  // style: ViewPropTypes.style,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf([
    'Entypo',
    'EvilIcons',
    'Feather',
    'FontAwesome',
    'Foundation',
    'Ionicons',
    'MaterialCommunityIcons',
    'MaterialIcons',
    'Octicons',
    'SimpleLineIcons',
    'Zocial',
    'MMPIcon',
  ]),
};

Icon.defaultProps = {
  style: {},
  color: '#000',
  name: null,
  size: designConstants.icons.size,
  type: 'MaterialCommunityIcons',
};
