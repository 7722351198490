import React from 'react';
import { View, StyleSheet } from 'react-native';

export default class VerticalForm extends React.Component {
  render() {
    return (
      <View style={[styles.content, this.props.style]}>
        {this.props.children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
  },
});
