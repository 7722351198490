import LocalizedStrings from 'react-native-localization';
import shared from '../../strings/shared';

export default new LocalizedStrings({
  en: {
    eula_updated_form_title_text: 'End User License Agreement has been updated',
    eula_updated_message_1_text:
      "It's our responsibility to let you know when we update these terms. In this update:",
    eula_updated_message_2_text:
      "• we've re-written the terms to make them easier to read and understand",
    eula_updated_message_3_text:
      '• we commit to using and protecting your personal information in accordance with our {0}',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'en'),

    eula_updated_message_4_text:
      '• we reinforce that we will never disclose your personal information without explicit consent',
    eula_updated_link_message: 'By registering, you agree to the\n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'en'),
    eula_updated_continue_button: 'Ok, Continue',

    eula_updated_cancel_dialog_title: 'Are you sure?',
    eula_updated_cancel_dialog_text:
      'You must accept the updated EULA to continue using Manage My Pain. Are you sure you want to exit?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'en'),
    eula_updated_cancel_dialog_no_button: shared('No', 'en'),

    data_security_title: 'You are in control.',
    data_security_registering_with_provider:
      'You are registering with {0} with {1}',
    data_security_privacy_policy_message:
      'We use the information you choose to enter into Manage My Pain in accordance with our {0}. Your data is always stored and transmitted securely with AES-256 encryption.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'en'),
    data_security_consent_web:
      "I consent to ManagingLife storing and processing the information I choose to enter into Manage My Pain as necessary to provide the app's web-based functionality. I can withdraw this consent at any time by deleting my account.",

    data_security_consent_native:
      'I consent to ManagingLife storing and processing the information I choose to enter into Manage My Pain as necessary to provide the functionality of an account. I can withdraw this consent at any time by deleting my account.',
    data_security_consent_not_provided_toast:
      "Your consent is needed to continue. ManagingLife cannot provide the app's functionality without storing and processing the information you choose to enter into Manage My Pain.",
    data_security_submit_button: 'Submit and Continue',
    data_security_web_eula_text: 'By registering, you agree to the {0}.',
    data_security_web_eula_link: shared('End User License Agreement', 'en'),

    welcome_container_no_email_text:
      'You are registering with {0} and we need a valid email address to continue.',

    signup_success_form_title: 'Registration Successful',
    signup_success_form_text:
      'Before you can log in to Manage My Pain, you need to activate your account. Click the activation link sent to your account. Can’t find it? Check your spam folder or request a new one.',
    signup_success_form_login_button: shared('Log In', 'en'),
    signup_success_form_send_link_again_button: 'Send the link again',
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'en',
    ),

    expired_link_message:
      'Link is expired. Please try to generate new link from app or contact support',
    expired_link_open_app_button: 'Open App',
    expired_link_contact_support_button: shared('contact_support', 'en'),

    unsubscribe_message:
      'Are you sure you want to unsubscribe {0} from emails sent by ManagingLife?  It may take up to 24 hours for the changes to take effect.',
    unsubscribe_yes_button: 'Yes, unsubscribe',
    unsubscribe_no_button: 'No, I want to stay subscribed',
    unsubscribe_invalid_token:
      'Link is expired or invalid. Please contact support',

    unsubscribe_try_again_button: 'Try again',
    unsubscribe_contact_support_button: shared('contact_support', 'en'),
    unsubscribe_loading: shared('Loading...', 'en'),
    unsubscribe_activating: 'Unsubscribing...',
    unsubscribe_success_msg: 'You have been successfully unsubscribed.',
    unsubscribe_error_msg:
      'Problem unsubscribing user - please try again or contact support.',

    password_checker_score_0: ' ',
    password_checker_score_1: 'Poor',
    password_checker_score_2: 'Fair',
    password_checker_score_3: 'Good',
    password_checker_score_4: 'Excellent',
    password_checker_at_least_8_chars: 'Must contain at least 8 characters',
    password_checker_warning_straightRow:
      'Straight rows of keys on your keyboard are easy to guess.',
    password_checker_warning_keyPattern:
      'Short keyboard patterns are easy to guess.',
    password_checker_warning_simpleRepeat:
      'Repeated characters like "aaa" are easy to guess.',
    password_checker_warning_extendedRepeat:
      'Repeated character patterns like "abcabcabc" are easy to guess.',
    password_checker_warning_sequences:
      'Common character sequences like "abc" are easy to guess.',
    password_checker_warning_recentYears: 'Recent years are easy to guess.',
    password_checker_warning_dates: 'Dates are easy to guess.',
    password_checker_warning_topTen: 'This is a heavily used password.',
    password_checker_warning_topHundred: 'This is a frequently used password.',
    password_checker_warning_common: 'This is a commonly used password.',
    password_checker_warning_similarToCommon:
      'This is similar to a commonly used password.',
    password_checker_warning_wordByItself: 'Single words are easy to guess.',
    password_checker_warning_namesByThemselves:
      'Single names or surnames are easy to guess.',
    password_checker_warning_commonNames:
      'Common names and surnames are easy to guess.',
    password_checker_warning_userInputs:
      'There should not be any personal or page related data.',
    password_checker_strength_label: 'Strength: {0}',

    download_app_message:
      'Your account has been successfully created. You may now log in to Manage My Pain.',
    join_institution_error:
      'Could not join the institution due to the following error: ',
    login_success_consent_provided:
      'Consent successfully provided - please continue using Manage My Pain',
    login_timeout_top_label:
      'Hey, {0}\nWe signed you out for security reasons - please log in to continue where you were.',
    login_header_in_association_with: 'in partnership with',
    login_header_app_name: 'Manage My Pain',

    // Facebook Button
    facebook_button_email_differs:
      'You are trying to log in using a different account - please try again with your account',
    facebook_button_continue_text: 'Continue with Facebook',
    facebook_button_connect_text: 'Connect with Facebook',
    facebook_button_disconnect_text: 'Disconnect from Facebook',
    facebook_button_logging_in_loading_overlay: 'Logging in with Facebook...',
    facebook_button_connecting_loading_overlay: 'Connecting with Facebook...',
    facebook_button_disconnecting_loading_overlay:
      'Disconnecting from Facebook...',
    facebook_button_disconnecting_without_password_popup_header:
      'Disconnect without password?',
    facebook_button_disconnecting_without_password_popup_text:
      'You will be unable to log in until you set a password.',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'en'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'en',
    ),

    // Google Button
    google_button_email_differs:
      'You are trying to log in using a different account - please try again with your account',
    google_button_continue_text: 'Sign in with Google',
    google_button_connect_text: 'Connect with Google',
    google_button_disconnect_text: 'Disconnect from Google',
    google_button_logging_in_loading_overlay: 'Logging in with Google...',
    google_button_connecting_loading_overlay: 'Connecting with Google...',
    google_button_disconnecting_loading_overlay: 'Disconnecting from Google...',
    google_button_disconnecting_without_password_popup_header:
      'Disconnect without password?',
    google_button_disconnecting_without_password_popup_text:
      'You will be unable to log in until you set a password.',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'en'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'en',
    ),

    // Apple Button
    apple_button_email_differs:
      'You are trying to log in using a different account - please try again with your account',
    apple_button_hidden_email: 'Hidden Email',
    apple_button_continue_text: 'Sign in with Apple',
    apple_button_connect_text: 'Connect with Apple',
    apple_button_disconnect_text: 'Disconnect from Apple',
    apple_button_logging_in_loading_overlay: 'Logging in with Apple...',
    apple_button_connecting_loading_overlay: 'Connecting with Apple...',
    apple_button_disconnecting_loading_overlay: 'Disconnecting from Apple...',
    apple_button_disconnecting_without_password_popup_header:
      'Disconnect without password?',
    apple_button_disconnecting_without_password_popup_text:
      'You will be unable to log in until you set a password.',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'en',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'en',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: 'Welcome {0}',
    facebook_welcome_email_header: 'You are connected to Facebook with:',
    facebook_welcome_email_login_header: 'You are logging in with Facebook to',
    facebook_welcome_email_signup_header:
      'You are signing up with Facebook using',
    facebook_welcome_policy_start_label: 'I accept and acknowledge the {0}',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'en'),
    facebook_welcome_continue_button: shared('Continue', 'en'),
    facebook_welcome_loading_text: 'Logging in...',
    facebook_welcome_policy_not_accepted_error:
      'You must accept the Privacy Policy to continue',
    facebook_welcome_email_label: shared('Email address', 'en'),
    facebook_welcome_email_helper: 'An email address is needed to continue',
    facebook_signup_success_text:
      'Account created - click the activation link sent to your email and then log in (please also check Spam/Junk folder)',
    facebook_signup_ok_button_text: shared('OK', 'en'),

    // Apple Welcome screen
    apple_welcome_welcome_label: 'Welcome {0}',
    apple_welcome_email_header: 'You are connected to Apple with:',
    apple_welcome_email_login_header: 'You are logging in with Apple to',
    apple_welcome_email_signup_header: 'You are signing up with Apple using',
    apple_welcome_policy_start_label: 'I accept and acknowledge the {0}',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'en'),
    apple_welcome_continue_button: shared('Continue', 'en'),
    apple_welcome_loading_text: 'Logging in...',
    apple_welcome_policy_not_accepted_error:
      'You must accept the Privacy Policy to continue',
    apple_welcome_email_label: shared('Email address', 'en'),
    apple_welcome_email_helper: 'An email address is needed to continue',
    apple_signup_success_text:
      'Account created - click the activation link sent to your email and then log in (please also check Spam/Junk folder)',
    apple_signup_ok_button_text: shared('OK', 'en'),

    // Google Welcome screen
    google_welcome_welcome_label: 'Welcome {0}',
    google_welcome_email_header: 'You are connected to Google with:',
    google_welcome_email_login_header: 'You are logging in with Google to',
    google_welcome_email_signup_header: 'You are signing up with Google using',
    google_welcome_policy_start_label: 'I accept and acknowledge the {0}',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'en'),
    google_welcome_continue_button: shared('Continue', 'en'),
    google_welcome_loading_text: 'Logging in...',
    google_welcome_policy_not_accepted_error:
      'You must accept the Privacy Policy to continue',
    google_welcome_error_189:
      'Too much time has elapsed - please register again',

    // Log In screen
    login_screen_header: shared('Log In', 'en'),
    login_below_social_buttons: 'or log in using your email address',
    login_email_label: shared('Email address', 'en'),
    login_email_helper: null,
    login_password_label: shared('Password', 'en'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'en'),
    login_sign_up_header: shared('Not registered yet', 'en') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'en'),
    login_reset_password_button: shared('Reset Password', 'en'),
    login_loading_text: 'Logging in...',
    login_logged_out_warning_text:
      'As a security measure, you have been logged out - please log in again to continue',
    login_force_logout_header:
      'or continue to log out and discard unsaved changes',
    login_force_logout_button: shared('Log Out', 'en'),
    login_force_logout_warning:
      'You have unsynced records that will be lost.  Do you wish to proceed?',
    login_force_logout_warning_log_out: shared('Log Out', 'en'),
    login_force_logout_warning_cancel: shared('Cancel', 'en'),
    login_web_header: 'Manage My Pain by ',
    login_web_institution_header: 'in partnership with ',
    login_invited_with_email_text:
      'You were invited with this email. You can change it later.',

    // Log In error handling (Activation)
    activation_required_title: 'Activation required',
    activation_required_message:
      "Click the activation link sent to your email. Can't find it? Check your spam folder or request a new one.",
    activation_resend_success:
      'Link sent - click the activation link sent to your email and then log in',
    activation_ok_button_text: shared('OK', 'en'),
    activation_resend_button_text: 'Request New Link',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'en'),
    password_needs_to_be_set_message:
      'Try logging in with Facebook or Google, or set a password.',
    password_needs_to_be_set_ios_message:
      'Try logging in with Apple, Facebook, or Google, or set a password.',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'en'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'en'),
    password_needs_to_be_set_success_message:
      'Password can now be set – check your email',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: 'Cannot log in',
    password_needs_to_be_set_not_activated_message:
      'Your account must be activated and a password set. Try logging in with Facebook instead.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      'Continue with Facebook',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'en',
    ),

    // Register screen
    signup_screen_header: 'Register',
    signup_below_social_buttons: 'or register using your email address',
    signup_email_label: shared('Email address', 'en'),
    signup_email_helper: null,
    signup_email_invalid_error: 'Email address is invalid',
    signup_password_label: shared('Password', 'en'),
    signup_password_helper: 'Must contain at least 6 characters',
    signup_password_too_short_error:
      'Too short - must contain at least 6 characters',
    signup_login_button_header: shared('already_registered', 'en'),
    signup_login_button: shared('Log In', 'en'),
    signup_policy_start_label: 'I accept and acknowledge the {0}',
    signup_policy_clickable_label: shared('Privacy Policy', 'en'),
    signup_policy_not_accepted_error:
      'You must accept the Privacy Policy to continue',
    signup_signup_button: 'Register',
    signup_loading_text: 'Registering...',
    signup_email_exists_error_start:
      'Email address is already in use. Log in or reset your password to proceed.',
    signup_email_exists_error_login: shared('Log In', 'en'),
    signup_email_exists_error_recover: shared('Reset Password', 'en'),
    signup_email_exists_error_alert_title:
      'Specified email address is not valid',
    signup_email_exists_error_alert_message:
      'Email address is already in use. Log in or reset your password to proceed.',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'en'),
    signup_email_exists_error_alert_login: shared('Log In', 'en'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'en'),
    signup_success_text:
      'Account created - click the activation link sent to your email and then log in (please also check Spam/Junk folder)',
    signup_success_activation_code_text:
      'Your account has been successfully created. You may now log in to Manage My Pain.',
    signup_ok_button_text: shared('OK', 'en'),
    signup_account_needs_to_be_activated_toast:
      'Account needs to be activated - check your email for link',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'en'),
    reset_password_top_label: 'Enter your registered email address',
    reset_password_email_label: shared('Email address', 'en'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: 'Email address is invalid',
    reset_password_reset_password_button: 'Request Password Reset',
    reset_password_bottom_label: null,
    reset_password_loading_text: 'Requesting password reset...',
    reset_password_success_text: shared('reset_password_success', 'en'),
    reset_password_ok_button_text: shared('OK', 'en'),
    reset_password_top_new_label:
      'Forgot password?\n' + "Don't worry, we'll help you reset it",
    reset_password_bottom_new_label:
      'Enter the email address associated with your account - an email will be sent with further instructions.',

    reset_form_password_screen_header: 'Change Password',
    reset_form_password_top_label: 'Change Password',
    reset_form_password_new_password_label: 'New Password',
    reset_form_password_new_password_helper:
      'Must contain at least 6 characters',
    reset_form_password_new_password_invalid_error: 'Email address is invalid',
    reset_form_password_reset_password_button: 'Change Password',
    reset_form_password_bottom_label: null,
    reset_form_password_loading_text: 'Requesting password reset...',
    reset_form_password_success_text: shared('reset_password_success', 'en'),
    reset_form_password_ok_button_text: shared('OK', 'en'),
    reset_form_password_top_new_label: 'Change Password',
    reset_form_password_bottom_new_label:
      'Choose a new password for the account associated with {0}',

    // Change password screen
    change_password_screen_header: 'Change Password',
    change_password_old_password_label: 'Old password',
    change_password_old_password_helper: null,
    change_password_new_password_label: 'New password',
    change_password_new_password_helper: 'Must contain at least 6 characters',
    change_password_new_password_too_short_error:
      'Too short - must contain at least 6 characters',
    change_password_button_text: 'Change Password',
    change_password_loading_text: 'Changing password...',
    change_password_success_text: 'Password changed successfully',
    change_password_ok_button_text: shared('OK', 'en'),

    // Change email screen
    change_email_screen_header: 'Change Email Address',
    change_email_old_email_label: 'Old email address',
    change_email_old_email_helper: null,
    change_email_new_email_label: 'New email address',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: 'Email address is invalid',
    change_email_password_label: shared('Password', 'en'),
    change_email_password_helper: null,
    change_email_button_text: 'Change Email Address',
    change_email_loading_text: 'Changing Email Address...',
    change_email_success_text:
      'Change email request is sent successfully, check your new email box (please also check Spam/Junk folder)',
    change_email_ok_button_text: shared('OK', 'en'),

    // Logout message
    logout_logging_out_message_for_overlay: 'Logging out...',
    logout_sync_failed_title: 'Sync failed during logout',
    logout_sync_failed_message:
      'You have unsynced data which will be lost if you will force logout.',
    logout_sync_failed_force_logout_button: 'Logout and lose data',
    logout_sync_failed_try_again_button: 'Retry sync',
    logout_sync_failed_cancel_button: shared('Cancel', 'en'),

    consent_screen_header: 'Consent required to share your data with',
    consent_screen_dialog_header: 'Consent Required',
    consent_screen_primary_id_regexp_error:
      'Please enter {0} in the correct format',
    consent_screen_empty_field_error: 'This field cannot be empty',
    consent_screen_primary_id_label: 'Enter Full Name',
    consent_screen_birth_date_label: 'Birth Date',
    consent_screen_registration_code_label: 'Registration Code',
    consent_screen_decline_button: 'Do Not Accept',
    consent_screen_accept_button: 'I Accept',

    // Landing page
    powered_by: 'powered by',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: 'lets you take control',
    landing_blue_card_sub_header:
      "Don't let chronic pain stop you from living your life and doing the things you love.",
    landing_blue_card_string1: 'Track your pain & activity',
    landing_blue_card_string2: 'Analyze your pain',
    landing_blue_card_string3: 'Share your pain',
    landing_blue_card_string4: 'Learn from pain experts',
    landing_blue_card_background: '#0049AC',

    landing_card1_header: 'Track your pain & activity',
    landing_card1_content:
      "45 seconds to reflect on your day is all that's needed to start",
    landing_card2_header: 'Analyze your pain',
    landing_card2_content:
      'Graphs and charts make it easy to figure out what influences your pain',
    landing_card3_header: 'Share your pain',
    landing_card3_content:
      'Our reports, created by doctors for doctors, will help you tell your story',
    landing_footer_string1: 'Use Manage My Pain on the go!',
    landing_footer_string2:
      'Manage My Pain app is available in Android and iOS too.',
    landing_promo_card_header: "Record how you're feeling",
    landing_promo_card_sub_header:
      'Reflect on your day and easily describe your pain in less than 45 seconds',
    landing_promo_card_item1_primary: 'Easy & Quick',
    landing_promo_card_item1_secondary:
      'Simple sliders and checkboxes make it a breeze to record',
    landing_promo_card_item2_primary: 'Customizable',
    landing_promo_card_item2_secondary:
      'Every section comes pre-populated, but you can personalize it to fit your specific needs',
    landing_promo_card_item3_primary: 'Once a Day',
    landing_promo_card_item3_secondary:
      'A reminder will ask you how your day has been and what your pain is like',
    landing_in_association_with: 'In partnership with',
    landing_scroll_to: 'Scroll to learn more',
    landing_register_now: 'Register Now',
    landing_logo_header_text: 'Track, analyze & share your pain',

    intro_about_app_point_1_title: 'Track your pain & activity',
    intro_about_app_point_1_subtitle:
      'Reflect on your day in less than 60 seconds to see patterns and trends',
    intro_about_app_point_2_title: 'Analyze your pain',
    intro_about_app_point_2_subtitle:
      'Graphs and charts make it easy to figure out what makes your pain better or worse',
    intro_about_app_point_3_title: 'Share your pain',
    intro_about_app_point_3_subtitle:
      'Our reports, created by doctors for doctors, will help you tell your story',
    intro_about_app_point_3_button: 'Download Sample Report',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-en.pdf',
    intro_about_app_point_4_title: 'Learn from pain experts',
    intro_about_app_point_4_subtitle:
      'Explore content about how pain works and strategies to manage it',
    intro_about_app_point_4_subtitle_for_subscribers: '(for subscribers only)',

    reset_password_activating: 'Resetting password...',
    reset_password_success_msg:
      "Congratulations - your account's password has been successfully changed.",
    reset_password_error_msg:
      'Problem resetting password - please try again or contact support.',
    reset_password_login_button: shared('Log In', 'en'),
    reset_password_request_new_link: 'Request new link',
    reset_password_contact_support: shared('contact_support', 'en'),
    reset_password_invalid_token:
      'Link is expired or invalid - please request a new link by logging in or contacting support',

    verify_email_activating: 'Verifying email address...',
    verify_email_success_msg:
      "Congratulations - your account's email address has been successfully changed. Download the app and log in.",
    verify_email_error_msg:
      'Problem verifying email address - please request a new link by logging in or contacting support',
    verify_email_request_new_link: shared('login_request_new_link', 'en'),
    verify_email_contact_support: shared('contact_support', 'en'),

    activate_account_activating: 'Activating account...',
    activate_account_success_msg:
      'Congratulations - your account has been successfully activated. Download the app and log in.',
    activate_account_error_msg:
      'Problem activating account - please request a new activation link by logging in or contacting support.',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'en',
    ),
    activate_account_contact_support: shared('contact_support', 'en'),
  },

  de: {
    eula_updated_form_title_text:
      'Die Endnutzer-Lizenzvertrag wurde aktualisiert',
    eula_updated_message_1_text:
      'Es liegt in unserer Verantwortung, Sie zu informieren, wenn wir diese Bedingungen aktualisieren. In dieser Aktualisierung:',
    eula_updated_message_2_text:
      '• wir haben die Begriffe umgeschrieben, damit sie leichter zu lesen und zu verstehen sind',
    eula_updated_message_3_text:
      '• wir haben uns dazu verpflichtet, Ihre persönlichen Daten in Übereinstimmung mit unserer {0} zu bearbeiten und zu schützen',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'de'),

    eula_updated_message_4_text:
      '• wir versichern, dass wir Ihre persönlichen Daten niemals ohne ausdrückliche Zustimmung weitergeben werden',
    eula_updated_link_message:
      'Durch die Registrierung erklären Sie sich mit den \n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'de'),
    eula_updated_continue_button: 'Ok, weiter',

    eula_updated_cancel_dialog_title: 'Sind Sie sicher?',
    eula_updated_cancel_dialog_text:
      'Sie müssen die aktualisierte EULA akzeptieren, um Manage My Pain weiter nutzen zu können. Möchten Sie wirklich beenden?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'de'),
    eula_updated_cancel_dialog_no_button: shared('No', 'de'),

    data_security_title: 'Sie behalten die Kontrolle.',
    data_security_registering_with_provider:
      'Sie registrieren sich mit {0} mit {1}',
    data_security_privacy_policy_message:
      'Wir verwenden die Daten, die Sie in Manage My Pain eingeben, in Übereinstimmung mit unseren {0}. Ihre Daten werden immer sicher mit AES-256-Verschlüsselung gespeichert und übertragen.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'de'),
    data_security_consent_web:
      'Ich bin damit einverstanden, dass ManagingLife die von mir in Manage My Pain eingegebenen Daten speichert und verarbeitet, soweit dies für die Bereitstellung der webbasierten Funktionen der App erforderlich ist. Ich kann diese Zustimmung jederzeit widerrufen, indem ich mein Konto lösche.',

    data_security_consent_native:
      'Ich erkläre mich damit einverstanden, dass ManagingLife die von mir in Manage My Pain eingegebenen Daten speichert und verarbeitet, soweit dies für die Bereitstellung der Funktionen eines Kontos erforderlich ist. Ich kann diese Zustimmung jederzeit widerrufen, indem ich mein Konto lösche.',
    data_security_consent_not_provided_toast:
      'Ihre Zustimmung ist erforderlich, um fortzufahren. ManagingLife kann die Funktionen der App nicht bereitstellen, ohne die Daten, die Sie in Manage My Pain eingeben, zu speichern und zu verarbeiten.',
    data_security_submit_button: 'Absenden und Fortfahren',
    data_security_web_eula_text:
      'Durch die Registrierung erklären Sie sich mit den {0}',
    data_security_web_eula_link: shared('End User License Agreement', 'de'),

    welcome_container_no_email_text:
      'Sie registrieren sich mit {0} und wir benötigen eine gültige E-Mail-Adresse, um fortzufahren.',

    signup_success_form_title: 'Registrierung erfolgreich',
    signup_success_form_text:
      'Bevor Sie sich in Manage My Pain einloggen können, müssen Sie Ihr Konto aktivieren. Klicken Sie auf den Aktivierungslink, der an Ihr Konto geschickt wurde. Sie können ihn nicht finden? Sehen Sie in Ihrem Spam-Ordner nach oder fordern Sie einen neuen Link an.',
    signup_success_form_login_button: shared('Log In', 'de'),
    signup_success_form_send_link_again_button: 'Den Link erneut senden',

    expired_link_message:
      'Der Link ist abgelaufen. Bitte versuchen Sie, einen neuen Link aus der App zu generieren oder kontaktieren Sie den Support',
    expired_link_open_app_button: 'App öffnen',
    expired_link_contact_support_button: shared('contact_support', 'de'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'de',
    ),

    unsubscribe_message:
      'Sind Sie sicher, dass Sie {0} von den ManagingLife E-Mails abmelden möchten? Es kann bis zu 24 Stunden dauern, bis die Änderungen wirksam werden.',
    unsubscribe_yes_button: 'Ja, abmelden',
    unsubscribe_no_button: 'Nein, ich möchte angemeldet bleiben',
    unsubscribe_invalid_token:
      'Der Link ist abgelaufen oder ungültig - bitte kontaktieren Sie den Support',

    unsubscribe_try_again_button: 'Erneut versuchen',
    unsubscribe_contact_support_button: shared('contact_support', 'de'),
    unsubscribe_loading: shared('Loading...', 'de'),
    unsubscribe_activating: 'Abmelden...',
    unsubscribe_success_msg: 'Sie wurden erfolgreich abgemeldet',
    unsubscribe_error_msg:
      'Problem beim Abmelden des Benutzers - bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',

    password_checker_score_0: ' ',
    password_checker_score_1: 'Schlecht',
    password_checker_score_2: 'Ausreichend',
    password_checker_score_3: 'Gut',
    password_checker_score_4: 'Ausgezeichnet',
    password_checker_at_least_8_chars: 'Muss mindestens 8 Zeichen enthalten',
    password_checker_warning_straightRow:
      'Gerade Linien von Tasten auf der Tastatur sind leicht zu erraten.',
    password_checker_warning_keyPattern:
      'Kurze Tastaturmuster sind leicht zu erraten.',
    password_checker_warning_simpleRepeat:
      'Sich wiederholende Zeichen wie "aaa" sind leicht zu erraten.',
    password_checker_warning_extendedRepeat:
      'Sich wiederholende Zeichenmuster wie "abcabcabc" sind leicht zu erraten.',
    password_checker_warning_sequences:
      'Häufige Zeichenfolgen wie "abc" sind leicht zu erraten.',
    password_checker_warning_recentYears:
      'Die jüngsten Jahreszahlen sind leicht zu erraten.',
    password_checker_warning_dates: 'Ein Datum ist leicht zu erraten.',
    password_checker_warning_topTen:
      'Dies ist ein sehr häufig verwendetes Passwort.',
    password_checker_warning_topHundred:
      'Dies ist ein häufig verwendetes Passwort.',
    password_checker_warning_common: 'Dies ist ein oft verwendetes Passwort.',
    password_checker_warning_similarToCommon:
      'Dies weist Ähnlichkeit zu anderen oft verwendeten Passwörtern auf.',
    password_checker_warning_wordByItself:
      'Einzelne Wörter sind leicht zu erraten.',
    password_checker_warning_namesByThemselves:
      'Einzelne Namen oder Nachnamen sind leicht zu erraten.',
    password_checker_warning_commonNames:
      'Vornamen und Nachnamen sind leicht zu erraten.',
    password_checker_warning_userInputs:
      'Es sollten keine persönlichen oder Seiten relevanten Daten vorkommen.',
    password_checker_strength_label: 'Stärke: {0}',

    reset_form_password_screen_header: 'Passwort ändern',
    reset_form_password_top_label: 'Passwort ändern',
    reset_form_password_new_password_label: 'Neues Passwort',
    reset_form_password_new_password_helper:
      'Muss mindestens 6 Zeichen enthalten',
    reset_form_password_new_password_invalid_error:
      'E-Mail Adresse ist ungültig',
    reset_form_password_reset_password_button: 'Passwort ändern',
    reset_form_password_loading_text: 'Passwort zurücksetzen...',
    reset_form_password_success_text: shared('reset_password_success', 'de'),
    reset_form_password_ok_button_text: shared('OK', 'de'),
    reset_form_password_top_new_label: 'Passwort ändern',
    reset_form_password_bottom_new_label:
      'Wählen Sie ein neues Passwort für das mit {0} verbundene Konto.',

    reset_password_activating: 'Passwort wird geändert...',
    reset_password_success_msg:
      'Glückwunsch - das Passwort für Ihr Konto wurde erfolgreich geändert.',
    reset_password_error_msg:
      'Problem beim Zurücksetzen des Passworts - bitte fordern Sie ein neues Passwort an oder kontaktieren Sie den Support',
    reset_password_login_button: shared('Log In', 'de'),
    reset_password_request_new_link: 'Erneut versuchen',
    reset_password_contact_support: shared('contact_support', 'de'),
    reset_password_invalid_token:
      'Der Link ist abgelaufen oder ungültig - bitte fordern Sie durch Anmelden einen neuen Link an oder kontaktieren Sie den Support',

    verify_email_activating: 'E-Mail-Adresse wird verifiziert...',
    verify_email_success_msg:
      'Glückwunsch - die E-Mail-Adresse Ihres Kontos wurde erfolgreich geändert. ',
    verify_email_error_msg:
      'Problem bei der Verifizierung der E-Mail-Adresse - bitte fordern Sie durch Anmelden einen neuen Link an oder kontaktieren Sie den Support',
    verify_email_request_new_link: shared('login_request_new_link', 'de'),
    verify_email_contact_support: shared('contact_support', 'de'),

    // Facebook Button
    facebook_button_email_differs:
      'Sie versuchen, sich mit einem anderen Konto anzumelden - bitte versuchen Sie es mit Ihrem Konto erneut',
    facebook_button_continue_text: 'Mit Facebook fortfahren',
    facebook_button_connect_text: 'Mit Facebook verknüpfen',
    facebook_button_disconnect_text: 'Verknüpfung mit Facebook trennen',
    facebook_button_logging_in_loading_overlay: 'Melde mit Facebook an…',
    facebook_button_connecting_loading_overlay: 'Verknüpfe mit Facebook…',
    facebook_button_disconnecting_loading_overlay:
      'Trenne Verknüpfung mit Facebook…',
    facebook_button_disconnecting_without_password_popup_header:
      'Verknüpfung trennen, ohne ein Passwort festzulegen?',
    facebook_button_disconnecting_without_password_popup_text:
      'Sie können sich nicht erneut anmelden, bevor Sie ein Passwort festlegen.',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'de'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'de',
    ),

    // Google Button
    google_button_email_differs:
      'Sie versuchen, sich mit einem anderen Konto anzumelden - bitte versuchen Sie es mit Ihrem Konto erneut',
    google_button_continue_text: 'Mit Google anmelden',
    google_button_connect_text: 'Mit Google verknüpfen',
    google_button_disconnect_text: 'Verknüpfung mit Google trennen',
    google_button_logging_in_loading_overlay: 'Melde mit Google an…',
    google_button_connecting_loading_overlay: 'Verknüpfe mit Google…',
    google_button_disconnecting_loading_overlay:
      'Trenne Verknüpfung mit Google…',
    google_button_disconnecting_without_password_popup_header:
      'Verknüpfung trennen, ohne ein Passwort festzulegen?',
    google_button_disconnecting_without_password_popup_text:
      'Sie können sich nicht erneut anmelden, bevor Sie ein Passwort festlegen.',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'de'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'de',
    ),

    // Apple Button
    apple_button_email_differs:
      'Sie versuchen, sich mit einem anderen Konto anzumelden - bitte versuchen Sie es mit Ihrem Konto erneut',
    apple_button_continue_text: 'Mit Apple fortfahren',
    apple_button_connect_text: 'Mit Apple verknüpfen',
    apple_button_disconnect_text: 'Verknüpfung mit Apple trennen',
    apple_button_logging_in_loading_overlay: 'Melde mit Apple an…',
    apple_button_connecting_loading_overlay: 'Verknüpfe mit Apple…',
    apple_button_disconnecting_loading_overlay: 'Trenne Verknüpfung mit Apple…',
    apple_button_disconnecting_without_password_popup_header:
      'Verknüpfung trennen, ohne ein Passwort festzulegen?',
    apple_button_disconnecting_without_password_popup_text:
      'Sie können sich nicht erneut anmelden, bevor Sie ein Passwort festlegen.',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'de',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'de',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: 'Willkommen {0}',
    facebook_welcome_email_header:
      'Sie sind mit folgendem Facebook-Konto angemeldet:',
    facebook_welcome_email_login_header: 'Sie melden sich über Facebook an bei',
    facebook_welcome_email_signup_header:
      'Sie registrieren sich über Facebook mit',
    facebook_welcome_policy_start_label:
      'Ich habe die {0} gelesen und akzeptiere sie',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'de'),
    facebook_welcome_continue_button: shared('Continue', 'de'),
    facebook_welcome_loading_text: 'Anmeldung läuft…',
    facebook_welcome_policy_not_accepted_error:
      'Sie müssen die Datenschutzerklärung akzeptieren, um fortzufahren',
    facebook_welcome_email_label: shared('Email address', 'de'),
    facebook_welcome_email_helper:
      'Eine E-Mail-Adresse wird zum Fortfahren benötigt',
    facebook_signup_success_text:
      'Benutzerkonto erstellt - klicken Sie auf den Aktivierungs-Link, der an Ihre E-Mail-Adresse versendet wurde und melden Sie sich anschließend an (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    facebook_signup_ok_button_text: shared('OK', 'de'),

    // Apple Welcome screen
    apple_welcome_welcome_label: 'Willkommen {0}',
    apple_welcome_email_header:
      'Sie sind mit folgendem Apple-Konto angemeldet:',
    apple_welcome_email_login_header: 'Sie melden sich über Apple an bei',
    apple_welcome_email_signup_header: 'Sie registrieren sich über Apple mit',
    apple_welcome_policy_start_label:
      'Ich habe die {0} gelesen und akzeptiere sie',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'de'),
    apple_welcome_continue_button: shared('Continue', 'de'),
    apple_welcome_loading_text: 'Anmeldung läuft…',
    apple_welcome_policy_not_accepted_error:
      'Sie müssen die Datenschutzerklärung akzeptieren, um fortzufahren',
    apple_welcome_email_label: shared('Email address', 'de'),
    apple_welcome_email_helper:
      'Eine E-Mail-Adresse wird zum Fortfahren benötigt',
    apple_signup_success_text:
      'Benutzerkonto erstellt - klicken Sie auf den Aktivierungs-Link, der an Ihre E-Mail-Adresse versendet wurde und melden Sie sich anschließend an (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    apple_signup_ok_button_text: shared('OK', 'de'),

    // Google Welcome screen
    google_welcome_welcome_label: 'Willkommen {0}',
    google_welcome_email_header:
      'Sie sind mit folgendem Google-Konto angemeldet:',
    google_welcome_email_login_header: 'Sie melden sich über Google an bei',
    google_welcome_email_signup_header: 'Sie registrieren sich über Google mit',
    google_welcome_policy_start_label:
      'Ich habe die {0} gelesen und akzeptiere sie',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'de'),
    google_welcome_continue_button: shared('Continue', 'de'),
    google_welcome_loading_text: 'Anmeldung läuft…',
    google_welcome_policy_not_accepted_error:
      'Sie müssen die Datenschutzerklärung akzeptieren, um fortzufahren',
    google_welcome_error_189:
      'Es ist zu viel Zeit vergangen - bitte registrieren Sie sich erneut',

    // Log In screen
    login_screen_header: shared('Log In', 'de'),
    login_below_social_buttons:
      'oder melden Sie sich mit Ihrer E-Mail-Adresse an',
    login_email_label: shared('Email address', 'de'),
    login_email_helper: null,
    login_password_label: shared('Password', 'de'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'de'),
    login_sign_up_header: shared('Not registered yet', 'de') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'de'),
    login_reset_password_button: shared('Reset Password', 'de'),
    login_loading_text: 'Anmeldung läuft…',
    login_logged_out_warning_text:
      'Aus Sicherheitsgründen wurden Sie abgemeldet - bitte melden Sie sich erneut an, um fortzufahren',
    login_force_logout_header:
      'oder mit der Abmeldung fortfahren und nicht gespeicherte Änderungen verwerfen',
    login_force_logout_button: shared('Log Out', 'de'),
    login_force_logout_warning:
      'Sie haben Datensätze, die nicht gespeichert wurden. Möchten Sie fortfahren?',
    login_force_logout_warning_log_out: shared('Log Out', 'de'),
    login_force_logout_warning_cancel: shared('Cancel', 'de'),

    // Log In error handling (Activation)
    activation_required_title: 'Aktivierung Erforderlich',
    activation_required_message:
      'Klicken Sie auf den Aktivierungs-Link, der an Ihre E-Mail-Adresse versendet wurde. Sie können ihn nicht finden? Sehen Sie in Ihrem Spam-Ordner nach oder fordern Sie einen neuen Link an',
    activation_resend_success:
      'Link versendet - klicken Sie auf den Aktivierungs-Link, der an Ihre E-Mail-Adresse versendet wurde und melden Sie sich anschließend an',
    activation_ok_button_text: shared('OK', 'de'),
    activation_resend_button_text: 'Neuen Link anfordern',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'de'),
    password_needs_to_be_set_message:
      'Melden Sie sich mit Ihrem Facebook- oder Google-Konto an oder legen Sie ein neues Passwort fest.',
    password_needs_to_be_set_ios_message:
      'Melden Sie sich mit Ihrem Apple, Facebook- oder Google-Konto an oder legen Sie ein neues Passwort fest.',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'de'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'de'),
    password_needs_to_be_set_success_message:
      'Passwort kann jetzt festgelegt werden - überprüfen Sie Ihren Posteingang',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: 'Anmeldung nicht möglich',
    password_needs_to_be_set_not_activated_message:
      'Ihr Konto muss aktiviert und ein Passwort festgelegt werden. Melden Sie sich stattdessen mit Ihrem Facebook-Konto an.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      'Mit Facebook fortfahren',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'de',
    ),

    // Register screen
    signup_screen_header: 'Registrieren',
    signup_below_social_buttons:
      'oder registrieren Sie sich mit Ihrer E-Mail-Adresse',
    signup_email_label: shared('Email address', 'de'),
    signup_email_helper: null,
    signup_email_invalid_error: 'E-Mail-Adresse ist ungültig',
    signup_password_label: shared('Password', 'de'),
    signup_password_helper: 'Muss mindestens 6 Zeichen enthalten',
    signup_password_too_short_error:
      'Zu kurz - muss mindestens 6 Zeichen enthalten',
    signup_login_button_header: shared('already_registered', 'de'),
    signup_login_button: shared('Log In', 'de'),
    signup_policy_start_label: 'Ich habe die {0} gelesen und akzeptiere sie',
    signup_policy_clickable_label: shared('Privacy Policy', 'de'),
    signup_policy_not_accepted_error:
      'Sie müssen die Datenschutzerklärung akzeptieren, um fortzufahren',
    signup_signup_button: 'Registrieren',
    signup_loading_text: 'Registrierung läuft…',
    signup_email_exists_error_start:
      'E-Mail-Adresse wird bereits verwendet. Melden Sie sich an oder setzen Sie Ihr Passwort zurück, um fortzufahren.',
    signup_email_exists_error_login: shared('Log In', 'de'),
    signup_email_exists_error_recover: shared('Reset Password', 'de'),
    signup_email_exists_error_alert_title:
      'Angegebene E-Mail-Adresse ist nicht gültig',
    signup_email_exists_error_alert_message:
      'E-Mail-Adresse wird bereits verwendet. Melden Sie sich an oder setzen Sie Ihr Passwort zurück, um fortzufahren.',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'de'),
    signup_email_exists_error_alert_login: shared('Log In', 'de'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'de'),
    signup_success_text:
      'Benutzerkonto erstellt - klicken Sie auf den Aktivierungs-Link, der an Ihre E-Mail-Adresse versendet wurde und melden Sie sich anschließend an (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    signup_ok_button_text: shared('OK', 'de'),
    signup_account_needs_to_be_activated_toast:
      'Konto muss aktiviert werden - suchen Sie in Ihrem Posteingang nach dem Link',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'de'),
    reset_password_top_label: 'Geben Sie Ihre registrierte E-Mail-Adresse ein',
    reset_password_email_label: shared('Email address', 'de'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: 'E-Mail-Adresse ist ungültig',
    reset_password_reset_password_button: 'Passwortzurücksetzung anfordern',
    reset_password_bottom_label: null,
    reset_password_loading_text: 'Fordere Link an…',
    reset_password_success_text: shared('reset_password_success', 'de'),
    reset_password_ok_button_text: shared('OK', 'de'),

    // Change password screen
    change_password_screen_header: 'Passwort ändern',
    change_password_old_password_label: 'Altes Passwort',
    change_password_old_password_helper: null,
    change_password_new_password_label: 'Neues Passwort',
    change_password_new_password_helper: 'Muss mindestens 6 Zeichen enthalten',
    change_password_new_password_too_short_error:
      'Zu kurz - muss mindestens 6 Zeichen enthalten',
    change_password_button_text: 'Passwort ändern',
    change_password_loading_text: 'Ändere Passwort…',
    change_password_success_text: 'Passwort erfolgreich geändert',
    change_password_ok_button_text: shared('OK', 'de'),

    // Change email screen
    change_email_screen_header: 'E-Mail-Adresse ändern',
    change_email_old_email_label: 'Alte E-Mail-Adresse',
    change_email_old_email_helper: null,
    change_email_new_email_label: 'Neue E-Mail-Adresse',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: ' E-Mail-Adresse ist ungültig',
    change_email_password_label: shared('Password', 'de'),
    change_email_password_helper: null,
    change_email_button_text: 'E-Mail-Adresse ändern',
    change_email_loading_text: 'Ändere E-Mail-Adresse…',
    change_email_success_text:
      'Anfrage zur Änderung erfolgreich versendet - überprüfen Sie den Posteingang Ihrer neuen E-Mail-Adresse (Bitte überprüfen Sie auch Ihren Spam-Ordner)',
    change_email_ok_button_text: shared('OK', 'de'),

    // Logout message
    logout_logging_out_message_for_overlay: 'Melde ab…',
    logout_sync_failed_title: 'Sync während der Abmeldung fehlgeschlagen',
    logout_sync_failed_message:
      'Sie haben unsynchronisierte Daten, die verloren gehen, wenn Sie sich abmelden',
    logout_sync_failed_force_logout_button:
      'Abmelden und unsynchronisierte Daten verlieren',
    logout_sync_failed_try_again_button: 'Sync erneut versuchen',
    logout_sync_failed_cancel_button: shared('Cancel', 'de'),

    // Landing page
    download_app_message:
      'Ihr Konto wurde erfolgreich erstellt. Sie können sich jetzt bei Manage My Pain anmelden.',
    join_institution_error:
      'Konnte der Einrichtung aufgrund folgenden Fehlers nicht beitreten: ',
    login_success_consent_provided:
      'Einverständniserklärung abgegeben - bitte fahren SIe mit der Nutzung von Manage My Pain fort',
    login_timeout_top_label:
      'Hallo, {0}\nWir haben Sie aus Sicherheitsgründen abgemeldet - bitte melden Sie sich an, um fortzufahren.',
    login_header_in_association_with: 'in Zusammenarbeit mit',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: 'Versteckte E-Mail',
    login_web_institution_header: 'in Zusammenarbeit mit',
    login_invited_with_email_text:
      'Sie wurden mit dieser E-Mail eingeladen. Sie können sie später ändern.',
    signup_success_activation_code_text:
      'Ihr Konto wurde erfolgreich erstellt. Sie können sich jetzt bei Manage My Pain anmelden.',
    reset_password_top_new_label:
      'Passwort vergessen?\nKeine Sorge, wir helfen Ihnen beim Zurücksetzen',
    reset_password_bottom_new_label:
      'Geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verknüpft ist - es wird eine E-Mail mit weiteren Anweisungen zugeschickt.',
    consent_screen_header:
      'Einverständnis erforderlich für die Weitergabe Ihrer Daten an',
    consent_screen_dialog_header: 'Einverständniserklärung erforderlich',
    consent_screen_primary_id_regexp_error:
      'Bitte geben Sie {0} im richtigen Format ein',
    consent_screen_empty_field_error: 'Dieses Feld darf nicht leer sein',
    consent_screen_primary_id_label: 'Geben Sie Ihren vollständigen Namen ein',
    consent_screen_birth_date_label: 'Geburtsdatum',
    consent_screen_decline_button: 'Nicht akzeptieren',
    consent_screen_accept_button: 'Ich akzeptiere',
    powered_by: 'betrieben von',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: 'lässt Sie die Kontrolle übernehmen',
    landing_blue_card_sub_header:
      'Lassen Sie sich von chronischen Schmerzen nicht davon abhalten, Ihr Leben zu leben und die Dinge zu tun, die Sie lieben.',
    landing_blue_card_string1: 'Erfassen Sie Ihren Schmerz & Ihre Aktivitäten',
    landing_blue_card_string2: 'Analysieren Sie Ihren Schmerz',
    landing_blue_card_string3: 'Teilen Sie Ihren Schmerz',
    landing_blue_card_string4: 'Lernen Sie von Schmerzexperten',
    landing_blue_card_background: '#0049AC',
    landing_card1_header: 'Erfassen Sie Ihren Schmerz & Ihre Aktivitäten',
    landing_card1_content:
      'Sie müssen nur 45 Sekunden über Ihren Tag nachdenken, um anzufangen',
    landing_card2_header: 'Analysieren Sie Ihren Schmerz',
    landing_card2_content:
      'Diagramme und Tabellen machen es einfach, herauszufinden, was Ihren Schmerz beeinflusst',
    landing_card3_header: 'Teilen Sie Ihren Schmerz',
    landing_card3_content:
      'Unsere Berichte, erstellt von Ärzten für Ärzte, helfen Ihnen, Ihre Geschichte zu erzählen',
    landing_footer_string1: 'Benutzen Sie Manage My Pain auch unterwegs!',
    landing_footer_string2:
      'Die Manage My Pain-App ist auch für Android und iOS erhältlich.',
    landing_promo_card_header: 'Halten Sie fest, wie Sie sich fühlen',
    landing_promo_card_sub_header:
      'Denken Sie über Ihren Tag nach und beschreiben Sie Ihre Schmerzen in weniger als 45 Sekunden',
    landing_promo_card_item1_primary: 'Einfach & Schnell',
    landing_promo_card_item1_secondary:
      'Einfache Schieberegler und Kästchen machen die Eingabe zum Kinderspiel',
    landing_promo_card_item2_primary: 'Anpassbar',
    landing_promo_card_item2_secondary:
      'Jeder Abschnitt ist bereits vorausgefüllt, aber Sie können ihn an Ihre speziellen Bedürfnisse anpassen',
    landing_promo_card_item3_primary: 'Einmal täglich',
    landing_promo_card_item3_secondary:
      'Eine Erinnerung wird Sie fragen, wie Ihr Tag war und wie Ihre Schmerzen sind',
    landing_in_association_with: 'In Zusammenarbeit mit',
    landing_scroll_to: 'Scrollen Sie, um mehr zu erfahren',
    landing_register_now: 'Jetzt Registrieren',
    landing_logo_header_text:
      'Verfolgen, analysieren und teilen Sie Ihre Schmerzen',

    intro_about_app_point_1_title:
      'Erfassen Sie Ihren Schmerz & Ihre Aktivitäten',
    intro_about_app_point_1_subtitle:
      'Reflektieren Sie den Tag in weniger als 60 Sekunden, um Muster und Trends zu erkennen',
    intro_about_app_point_2_title: 'Analysieren Sie Ihren Schmerz',
    intro_about_app_point_2_subtitle:
      'Grafiken und Diagramme machen es einfach, herauszufinden, was Ihre Schmerzen besser oder schlechter macht',
    intro_about_app_point_3_title: 'Teilen Sie Ihren Schmerz',
    intro_about_app_point_3_subtitle:
      'Unsere Berichte, erstellt von Ärzten für Ärzte, helfen Ihnen, Ihre Geschichte zu erzählen',
    intro_about_app_point_3_button: 'Beispielbericht herunterladen',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-de.pdf',
    intro_about_app_point_4_title: 'Lernen Sie von Schmerzexperten',
    intro_about_app_point_4_subtitle:
      'Entdecken Sie Inhalte darüber, wie Schmerzen funktionieren und wie man mit ihnen umgehen kann',
    intro_about_app_point_4_subtitle_for_subscribers: '(nur für Abonnenten)',

    activate_account_activating: 'Account wird aktiviert...',
    activate_account_success_msg:
      'Herzlichen Glückwunsch - Ihr Account wurde erfolgreich aktiviert. Laden Sie die App herunter und melden Sie sich an.',
    activate_account_error_msg:
      'Problem bei der Aktivierung des Accounts - bitte fordern Sie einen neuen Aktivierungslink an, indem Sie sich anmelden oder den Support kontaktieren.',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'de',
    ),
    activate_account_contact_support: shared('contact_support', 'de'),
  },

  es: {
    eula_updated_form_title_text:
      'El Acuerdo de Licencia del Usuario Final ha sido actualizado',
    eula_updated_message_1_text:
      'Es nuestra responsabilidad avisarle cuando actualizamos estos términos. En esta actualización:',
    eula_updated_message_2_text:
      '• hemos rescrito estos términos para que sean más fáciles de leer y comprender',
    eula_updated_message_3_text:
      '• nos comprometemos a utilizar y proteger sus datos personales de acuerdo con nuestra {0}',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'es'),

    eula_updated_message_4_text:
      '• reforzamos que nunca revelaremos su información personal sin un consentimiento explícito',
    eula_updated_link_message: 'Al registrarse, usted acepta el \n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'es'),
    eula_updated_continue_button: 'OK, continuar',

    eula_updated_cancel_dialog_title: '¿Está seguro?',
    eula_updated_cancel_dialog_text:
      'Debe aceptar el CLUF actualizado para seguir utilizando Manage My Pain. ¿Está seguro de que quiere salir?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'es'),
    eula_updated_cancel_dialog_no_button: shared('No', 'es'),

    data_security_title: 'Usted tiene el control.',
    data_security_registering_with_provider:
      'Se está registrando mediante {0} con {1}',
    data_security_privacy_policy_message:
      'Utilizamos la información que decide introducir en Manage My Pain de acuerdo con nuestra {0}. Sus datos se almacenan y transmiten siempre de forma segura con encriptación AES-256.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'es'),
    data_security_consent_web:
      'Doy mi consentimiento para que ManagingLife almacene y procese la información que decido introducir en Manage My Pain según sea necesario para proporcionar la funcionalidad web de la aplicación. Puedo retirar este consentimiento en cualquier momento borrando mi cuenta.',

    data_security_consent_native:
      'Doy mi consentimiento para que ManagingLife almacene y procese la información que elijo introducir en Manage My Pain según sea necesario para proporcionar la funcionalidad de una cuenta. Puedo retirar este consentimiento en cualquier momento borrando mi cuenta.',
    data_security_consent_not_provided_toast:
      'Debe proporcionar su consentimiento para continuar. ManagingLife no puede proporcionar la funcionalidad de la aplicación sin almacenar y procesar la información que usted decide introducir en Manage My Pain.',
    data_security_submit_button: 'Enviar y continuar',
    data_security_web_eula_text: 'Al registrarse, usted acepta el {0}',
    data_security_web_eula_link: shared('End User License Agreement', 'es'),

    welcome_container_no_email_text:
      'Se está registrando con {0} y necesitamos una dirección de correo electrónico válida para continuar.',

    signup_success_form_title: 'Registro satisfactorio',
    signup_success_form_text:
      'Antes de poder entrar en Manage My Pain, tiene que activar su cuenta. Haga clic en el enlace de activación enviado a su cuenta. ¿No lo encuentra? Compruebe su carpeta de correo no deseado o solicite un enlace nuevo.',
    signup_success_form_login_button: shared('Log In', 'es'),
    signup_success_form_send_link_again_button: 'Enviar el enlace de nuevo',

    expired_link_message:
      'El enlace está vencido. Intente generar un nuevo enlace en la aplicación o contacte a soporte',
    expired_link_open_app_button: 'Abrir la aplicación',
    expired_link_contact_support_button: shared('contact_support', 'es'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'es',
    ),

    unsubscribe_message:
      '¿Está seguro que desea dar de baja a {0} de la lista de correos electrónicos de ManagingLife? Puede tardar hasta 24 horas para que el cambio tenga efecto.',
    unsubscribe_yes_button: 'Sí, dar de baja',
    unsubscribe_no_button: 'No, quiero permanecer suscrito',
    unsubscribe_invalid_token:
      'El enlace ha expirado o no es válido. Póngase en contacto con soporte',

    unsubscribe_try_again_button: 'Intentar otra vez',
    unsubscribe_contact_support_button: shared('contact_support', 'es'),
    unsubscribe_loading: shared('Loading...', 'es'),
    unsubscribe_activating: 'Dando de baja…',
    unsubscribe_success_msg: 'Ha sido dado de baja satisfactoriamente',
    unsubscribe_error_msg:
      'Hubo un problema al dar de baja el usuario. Inténtelo nuevamente o póngase en contacto con soporte.',

    password_checker_score_0: ' ',
    password_checker_score_1: 'Pobre',
    password_checker_score_2: 'Regular',
    password_checker_score_3: 'Bueno',
    password_checker_score_4: 'Excelente',
    password_checker_at_least_8_chars: 'Debe contener al menos 8 caracteres',
    password_checker_warning_straightRow:
      'Teclas consecutivas de su teclado son fáciles de adivinar.',
    password_checker_warning_keyPattern:
      'Los patrones cortos del teclado son fáciles de adivinar.',
    password_checker_warning_simpleRepeat:
      'Caracteres repetidos como “aaa” son fáciles de adivinar.',
    password_checker_warning_extendedRepeat:
      'Los patrones de caracteres repetidos, como “abcabcabc”, son fáciles de adivinar.',
    password_checker_warning_sequences:
      'Las secuencias de caracteres comunes como “abc” son fáciles de adivinar.',
    password_checker_warning_recentYears:
      'Años recientes son fáciles de adivinar.',
    password_checker_warning_dates: 'Las fechas son fáciles de adivinar.',
    password_checker_warning_topTen: 'Esta contraseña es de uso muy frecuente.',
    password_checker_warning_topHundred: 'Esta contraseña es de uso frecuente.',
    password_checker_warning_common: 'Esta contraseña es de uso común.',
    password_checker_warning_similarToCommon:
      'Esta contraseña es similar a una contraseña de uso común.',
    password_checker_warning_wordByItself:
      'Las palabras individuales son fáciles de adivinar.',
    password_checker_warning_namesByThemselves:
      'Los nombres o apellidos individuales son fáciles de adivinar.',
    password_checker_warning_commonNames:
      'Los nombres o apellidos comunes son fáciles de adivinar.',
    password_checker_warning_userInputs:
      'No debe haber datos personales ni relacionados con la página.',
    password_checker_strength_label: 'Seguridad: {0}',

    reset_form_password_screen_header: 'Cambiar contraseña',
    reset_form_password_top_label: 'Cambiar contraseña',
    reset_form_password_new_password_label: 'Contraseña nueva',
    reset_form_password_new_password_helper:
      'Debe contener al menos 6 caracteres',
    reset_form_password_new_password_invalid_error:
      'La dirección de correo electrónico no es válida',
    reset_form_password_reset_password_button: 'Cambiar contraseña',
    reset_form_password_loading_text:
      'Solicitando restablecimiento de contraseña…',
    reset_form_password_success_text: shared('reset_password_success', 'es'),
    reset_form_password_ok_button_text: shared('OK', 'es'),
    reset_form_password_top_new_label: 'Cambiar contraseña',
    reset_form_password_bottom_new_label:
      'Elija una contraseña nueva para la cuenta asociada con {0}',

    reset_password_activating: 'Cambiando su contraseña…',
    reset_password_success_msg:
      'La contraseña de su cuenta ha sido cambiada satisfactoriamente.',
    reset_password_error_msg:
      'Hubo un problema al restablecer su contraseña. Solicite otro restablecimiento de contraseña o póngase en contacto con soporte',
    reset_password_login_button: shared('Log In', 'es'),
    reset_password_request_new_link: 'Intentar otra vez',
    reset_password_contact_support: shared('contact_support', 'es'),
    reset_password_invalid_token:
      'El enlace ha expirado o no es válido. Solicite un nuevo enlace iniciando sesión o poniéndose en contacto con soporte',

    verify_email_activating: 'Verificando dirección de correo electrónico…',
    verify_email_success_msg:
      'La dirección de correo electrónico de su cuenta ha sido modificada satisfactoriamente.',
    verify_email_error_msg: '',
    verify_email_request_new_link: shared('login_request_new_link', 'es'),
    verify_email_contact_support: shared('contact_support', 'es'),

    // Facebook Button
    facebook_button_email_differs:
      'Estás intentando iniciar sesión con una cuenta diferente - vuelva a intentarlo con su cuenta',
    facebook_button_continue_text: 'Continuar con Facebook',
    facebook_button_connect_text: 'Conectar con Facebook',
    facebook_button_disconnect_text: 'Desconectar de Facebook',
    facebook_button_logging_in_loading_overlay:
      'Iniciando sesión con Facebook…',
    facebook_button_connecting_loading_overlay: 'Conectando con Facebook…',
    facebook_button_disconnecting_loading_overlay: 'Desconectando de Facebook…',
    facebook_button_disconnecting_without_password_popup_header:
      '¿Desconectar sin contraseña?',
    facebook_button_disconnecting_without_password_popup_text:
      'No podrá iniciar sesión si no crea una contraseña',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'es'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'es',
    ),

    // Google Button
    google_button_email_differs:
      'Estás intentando iniciar sesión con una cuenta diferente - vuelva a intentarlo con su cuenta',
    google_button_continue_text: 'Iniciar sesión con Google',
    google_button_connect_text: 'Conectar con Google',
    google_button_disconnect_text: 'Desconectar de Google',
    google_button_logging_in_loading_overlay: 'Iniciando sesión con Google',
    google_button_connecting_loading_overlay: 'Conectando con Google…',
    google_button_disconnecting_loading_overlay: 'Desconectando de Google…',
    google_button_disconnecting_without_password_popup_header:
      '¿Desconectar sin contraseña?',
    google_button_disconnecting_without_password_popup_text:
      'No podrá iniciar sesión si no crea una contraseña',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'es'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'es',
    ),

    // Apple Button
    apple_button_email_differs:
      'Estás intentando iniciar sesión con una cuenta diferente - vuelva a intentarlo con su cuenta',
    apple_button_continue_text: 'Continuar con Apple',
    apple_button_connect_text: 'Conectar con Apple',
    apple_button_disconnect_text: 'Desconectar de Apple',
    apple_button_logging_in_loading_overlay: 'Iniciando sesión con Apple…',
    apple_button_connecting_loading_overlay: 'Conectando con Apple…',
    apple_button_disconnecting_loading_overlay: 'Desconectando de Apple…',
    apple_button_disconnecting_without_password_popup_header:
      '¿Desconectar sin contraseña?',
    apple_button_disconnecting_without_password_popup_text:
      'No podrá iniciar sesión si no crea una contraseña',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'es',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'es',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: 'Bienvenido(a) {0}',
    facebook_welcome_email_header: 'Usted se conectó a Facebook con:',
    facebook_welcome_email_login_header:
      'Está iniciando sesión vía Facebook en',
    facebook_welcome_email_signup_header:
      'Está registrándose vía Facebook usando',
    facebook_welcome_policy_start_label: 'Acepto y reconozco la {0}',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'es'),
    facebook_welcome_continue_button: shared('Continue', 'es'),
    facebook_welcome_loading_text: 'Iniciando sesión…',
    facebook_welcome_policy_not_accepted_error:
      'Debe aceptar la Política de Confidencialidad para continuar',
    facebook_welcome_email_label: shared('Email address', 'es'),
    facebook_welcome_email_helper:
      'Debe ingresar un correo electrónico para continuar',
    facebook_signup_success_text:
      'Cuenta creada. Haga clic en el link de activación que recibió en su correo electrónico y luego inicie sesión (por favor verifique también su carpeta de correo no deseado)',
    facebook_signup_ok_button_text: shared('OK', 'es'),

    // Apple Welcome screen
    apple_welcome_welcome_label: 'Bienvenido(a) {0}',
    apple_welcome_email_header: 'Usted se conectó a Apple con:',
    apple_welcome_email_login_header: 'Está iniciando sesión vía Apple en',
    apple_welcome_email_signup_header: 'Está registrándose vía Apple usando',
    apple_welcome_policy_start_label: 'Acepto y reconozco la {0}',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'es'),
    apple_welcome_continue_button: shared('Continue', 'es'),
    apple_welcome_loading_text: 'Iniciando sesión…',
    apple_welcome_policy_not_accepted_error:
      'Debe aceptar la Política de Confidencialidad para continuar',
    apple_welcome_email_label: shared('Email address', 'es'),
    apple_welcome_email_helper:
      'Debe ingresar un correo electrónico para continuar',
    apple_signup_success_text:
      'Cuenta creada. Haga clic en el link de activación que recibió en su correo electrónico y luego inicie sesión (por favor verifique también su carpeta de correo no deseado)',
    apple_signup_ok_button_text: shared('OK', 'es'),

    // Google Welcome screen
    google_welcome_welcome_label: 'Bienvenido(a) {0}',
    google_welcome_email_header: 'Usted se conectó a Google con:',
    google_welcome_email_login_header: 'Está iniciando sesión vía Google en',
    google_welcome_email_signup_header: 'Está registrándose vía Google usando',
    google_welcome_policy_start_label: 'Acepto y reconozco la {0}',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'es'),
    google_welcome_continue_button: shared('Continue', 'es'),
    google_welcome_loading_text: 'Iniciando sesión…',
    google_welcome_policy_not_accepted_error:
      'Debe aceptar la Política de Confidencialidad para continuar',
    google_welcome_error_189:
      'Ha transcurrido demasiado tiempo – regístrese otra vez',

    // Log In screen
    login_screen_header: shared('Log In', 'es'),
    login_below_social_buttons: 'o inicie sesión usando su correo electrónico',
    login_email_label: shared('Email address', 'es'),
    login_email_helper: null,
    login_password_label: shared('Password', 'es'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'es'),
    login_sign_up_header: shared('Not registered yet', 'es') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'es'),
    login_reset_password_button: shared('Reset Password', 'es'),
    login_loading_text: 'Iniciando sesión…',
    login_logged_out_warning_text:
      'Como medida de seguridad, se ha cerrado la sesión - vuelva a iniciar sesión para continuar',
    login_force_logout_header:
      'o cierre sesión para descartar cambios no guardados',
    login_force_logout_button: shared('Log Out', 'es'),
    login_force_logout_warning:
      'Tiene registros no sincronizados que se perderán. ¿Desea continuar?',
    login_force_logout_warning_log_out: shared('Log Out', 'es'),
    login_force_logout_warning_cancel: shared('Cancel', 'es'),

    // Log In error handling (Activation)
    activation_required_title: 'Activación necesaria',
    activation_required_message:
      'Haga clic en el vínculo de activación que enviamos a su correo electrónico. Si no lo ve, revise la carpeta de correo no deseado o solicite uno nuevo.',
    activation_resend_success:
      'Vínculo enviado. Haga clic en el vínculo de activación que enviamos a su correo y luego inicie sesión',
    activation_ok_button_text: shared('OK', 'es'),
    activation_resend_button_text: 'Solicitar nuevo vínculo',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'es'),
    password_needs_to_be_set_message:
      'Trate de iniciar sesión con Facebook, Google o establezca una contraseña',
    password_needs_to_be_set_ios_message:
      'Trate de iniciar sesión con Apple, Facebook, o Google o establezca una contraseña',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'es'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'es'),
    password_needs_to_be_set_success_message:
      'Ahora puede crear una contraseña. Revise su correo',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: 'No puede iniciar sesión',
    password_needs_to_be_set_not_activated_message:
      'Debe activar su cuenta y establecer una contraseña. Trate de iniciar sesión con Facebook.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      'Continuar con Facebook',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'es',
    ),

    // Register screen
    signup_screen_header: 'Registrarse',
    signup_below_social_buttons:
      'o regístrese con su dirección de correo electrónico',
    signup_email_label: shared('Email address', 'es'),
    signup_email_helper: null,
    signup_email_invalid_error: 'Correo electrónico inválido',
    signup_password_label: shared('Password', 'es'),
    signup_password_helper: 'Debe contener al menos 6 caracteres',
    signup_password_too_short_error:
      'Muy corta. Debe contener al menos 6 caracteres',
    signup_login_button_header: shared('already_registered', 'es'),
    signup_login_button: shared('Log In', 'es'),
    signup_policy_start_label: 'Acepto y reconozco la {0}',
    signup_policy_clickable_label: shared('Privacy Policy', 'es'),
    signup_policy_not_accepted_error:
      'Debe aceptar la Política de Confidencialidad para continuar',
    signup_signup_button: 'Registrarse',
    signup_loading_text: 'Registrando…',
    signup_email_exists_error_start:
      'La dirección de correo ya está en uso. Inicie sesión o restablezca su contraseña para proceder.',
    signup_email_exists_error_login: shared('Log In', 'es'),
    signup_email_exists_error_recover: shared('Reset Password', 'es'),
    signup_email_exists_error_alert_title:
      'La dirección de correo electrónico no es válida',
    signup_email_exists_error_alert_message:
      'La dirección de correo ya está en uso. Inicie sesión o restablezca su contraseña para proceder.',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'es'),
    signup_email_exists_error_alert_login: shared('Log In', 'es'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'es'),
    signup_success_text:
      'Cuenta creada. Haga clic en el link de activación que recibió en su correo electrónico y luego inicie sesión (por favor verifique también su carpeta de correo no deseado)',
    signup_ok_button_text: shared('OK', 'es'),
    signup_account_needs_to_be_activated_toast:
      'Debe activar la cuenta. Busque el vínculo en su bandeja de entrada',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'es'),
    reset_password_top_label: 'Escriba la dirección de correo que registró',
    reset_password_email_label: shared('Email address', 'es'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: 'Dirección de correo inválida',
    reset_password_reset_password_button:
      'Solicitar restablecimiento de contraseña',
    reset_password_bottom_label: null,
    reset_password_loading_text: 'Solicitando restablecimiento de contraseña…',
    reset_password_success_text: shared('reset_password_success', 'es'),
    reset_password_ok_button_text: shared('OK', 'es'),

    // Change password screen
    change_password_screen_header: 'Cambiar contraseña',
    change_password_old_password_label: 'Contraseña anterior',
    change_password_old_password_helper: null,
    change_password_new_password_label: 'Nueva contraseña',
    change_password_new_password_helper: 'Debe contener al menos 6 caracteres',
    change_password_new_password_too_short_error:
      'Muy corta. Debe contener al menos 6 caracteres',
    change_password_button_text: 'Cambiar contraseña',
    change_password_loading_text: 'Cambiando contraseña…',
    change_password_success_text: 'Contraseña cambiada satisfactoriamente',
    change_password_ok_button_text: shared('OK', 'es'),

    // Change email screen
    change_email_screen_header: 'Cambiar dirección de correo electrónico',
    change_email_old_email_label: 'Dirección de correo anterior',
    change_email_old_email_helper: null,
    change_email_new_email_label: 'Nueva dirección de correo',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: 'Dirección de correo inválida',
    change_email_password_label: shared('Password', 'es'),
    change_email_password_helper: null,
    change_email_button_text: 'Cambiar dirección de correo electrónico',
    change_email_loading_text: 'Cambiando dirección de correo…',
    change_email_success_text:
      'La solicitud de cambio de correo se envió correctamente. Revise su bandeja de correo nuevo (por favor verifique también su carpeta de correo no deseado)',
    change_email_ok_button_text: shared('OK', 'es'),

    // Logout message
    logout_logging_out_message_for_overlay: 'Cerrando sesión…',
    logout_sync_failed_title:
      'La sincronización falló durante el cierre de sesión',
    logout_sync_failed_message:
      'Los datos sin sincronizar se perderán si cierra su sesión',
    logout_sync_failed_force_logout_button:
      'Cerrar sesión y perder datos no sincronizados',
    logout_sync_failed_try_again_button: 'Reintentar sincronización',
    logout_sync_failed_cancel_button: shared('Cancel', 'es'),

    // Landing page
    download_app_message:
      'Su cuenta ha sido creada con éxito. Ahora puede iniciar sesión en Manage My Pain.',
    join_institution_error:
      'No se pudo unir la institución debido al siguiente error: ',
    login_success_consent_provided:
      'Consentimiento enviado satisfactoriamente - continúe usando Manage My Pain',
    login_timeout_top_label:
      'Hola, {0}\nHemos cerrado su sesión por motivos de seguridad. Por favor, inicie sesión para continuar donde estaba.',
    login_header_in_association_with: 'en asociación con',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: 'Dirección de correo oculta',
    login_web_institution_header: 'en asociación con',
    login_invited_with_email_text:
      'Se le invitó con este correo electrónico. Lo puede cambiar después.',
    signup_success_activation_code_text:
      'Su cuenta ha sido creada con éxito. Ahora puede iniciar sesión en Manage My Pain.',
    reset_password_top_new_label:
      '¿Olvidó su contraseña?\nNo se preocupe, le ayudaremos a restablecerla',
    reset_password_bottom_new_label:
      'Introduzca la dirección de correo electrónico asociada con su cuenta. Se le enviará un correo con instrucciones adicionales.',
    consent_screen_header:
      'Se requiere su consentimiento para compartir sus datos con',

    consent_screen_dialog_header: 'Se requiere consentimiento',
    consent_screen_primary_id_regexp_error:
      'Introduzca {0} en el formato correcto',
    consent_screen_empty_field_error: 'Este campo no puede estar vacío',
    consent_screen_primary_id_label: 'Introduzca su nombre completo',
    consent_screen_birth_date_label: 'Fecha de nacimiento',
    consent_screen_decline_button: 'No acepto',
    consent_screen_accept_button: 'Acepto',
    powered_by: 'powered by',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: 'le permite tener el control',
    landing_blue_card_sub_header:
      'No deje que su dolor crónico le impida vivir su vida y hacer las cosas que le gustan.',
    landing_blue_card_string1: 'Haga un seguimiento de su dolor y su actividad',
    landing_blue_card_string2: 'Analice su dolor',
    landing_blue_card_string3: 'Comparta su dolor',
    landing_blue_card_string4: 'Aprenda de expertos en dolor',
    landing_blue_card_background: '#0049AC',
    landing_card1_header: 'Haga un seguimiento de su dolor y su actividad',
    landing_card1_content:
      'Para comenzar, solo hacen falta 45 segundos para reflexionar sobre su día',
    landing_card2_header: 'Analice su dolor',
    landing_card2_content:
      'Los gráficos y tablas hacen que sea fácil determinar qué está influyendo en su dolor',
    landing_card3_header: 'Comparta su dolor',
    landing_card3_content:
      'Nuestros informes, creados por médicos para médicos, le ayudarán a contar su historia',
    landing_footer_string1: 'Utilice Manage My Pain desde cualquier parte',
    landing_footer_string2:
      'La app Manage My Pain está disponible para Android y iOS.',
    landing_promo_card_header: 'Registre cómo se siente',
    landing_promo_card_sub_header:
      'Reflexione sobre su día y describa su dolor de forma sencilla en menos de 45 segundos',
    landing_promo_card_item1_primary: 'Fácil y rápido',
    landing_promo_card_item1_secondary:
      'Indicadores y casillas hacen que sea muy fácil registrar su día',
    landing_promo_card_item2_primary: 'Personalizable',
    landing_promo_card_item2_secondary:
      'Todas las secciones vienen completadas con anterioridad, pero usted puede personalizarlas para que se adecúen a sus necesidades específicas',
    landing_promo_card_item3_primary: 'Una vez por día',
    landing_promo_card_item3_secondary:
      'Un recordatorio le preguntará cómo ha sido su día y cómo está su dolor',
    landing_in_association_with: 'En asociación con',
    landing_scroll_to: 'Desplácese para más información',
    landing_register_now: 'Registrarse ahora',
    landing_logo_header_text:
      'Supervise, analice y comparta los detalles de su dolor',

    intro_about_app_point_1_title:
      'Haga un seguimiento de su dolor y su actividad',
    intro_about_app_point_1_subtitle:
      'Reflexione sobre su día en menos de 60 segundos e identifique patrones y tendencias.',
    intro_about_app_point_2_title: 'Analice su dolor',
    intro_about_app_point_2_subtitle:
      'Los gráficos y diagramas facilitan entender lo que mejora o empeora su dolor.',
    intro_about_app_point_3_title: 'Comparta su dolor',
    intro_about_app_point_3_subtitle:
      'Nuestros informes, creados por médicos para médicos, le ayudarán a contar su historia',
    intro_about_app_point_3_button: 'Descargue un informe de muestra',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-es.pdf',
    intro_about_app_point_4_title: 'Aprenda de expertos en dolor',
    intro_about_app_point_4_subtitle:
      'Explore contenido acerca de cómo funciona el dolor y estrategias para manejarlo.',
    intro_about_app_point_4_subtitle_for_subscribers:
      '(solo para suscriptores)',

    activate_account_activating: 'Activando cuenta…',
    activate_account_success_msg:
      'Su cuenta ha sido activada satisfactoriamente. Descargue la app e inicie sesión.',
    activate_account_error_msg:
      'Hubo un problema al activar su cuenta. Solicite un nuevo enlace de activación iniciando sesión o contactando a soporte.',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'es',
    ),
    activate_account_contact_support: shared('contact_support', 'es'),
  },

  fr: {
    eula_updated_form_title_text:
      "Le contrat de licence d'utilisateur final a été mis à jour",
    eula_updated_message_1_text:
      'Il nous incombe de vous informer de toute mise à jour de ces conditions. Dans le cadre de la présente mise à jour :',
    eula_updated_message_2_text:
      '• nous avons reformulé les conditions pour en faciliter la lecture et la compréhension',
    eula_updated_message_3_text:
      '• nous nous engageons à utiliser et à protéger vos informations personnelles conformément à notre {0}',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'fr'),

    eula_updated_message_4_text:
      '• nous réaffirmons que nous ne divulguerons jamais vos informations personnelles sans votre consentement explicite',
    eula_updated_link_message: 'En vous inscrivant, vous acceptez ceci : \n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'fr'),
    eula_updated_continue_button: 'Ok, Continuer',

    eula_updated_cancel_dialog_title: 'Êtes-vous sûr(e) ?',
    eula_updated_cancel_dialog_text:
      'Vous devez accepter le CLUF mis à jour pour continuer à utiliser Manage My Pain. Êtes-vous sûr(e) de vouloir abandonner ?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'fr'),
    eula_updated_cancel_dialog_no_button: shared('No', 'fr'),

    data_security_title: 'Vous gardez le contrôle.',
    data_security_registering_with_provider:
      'Vous vous inscrivez à {0} avec {1}',
    data_security_privacy_policy_message:
      'Nous utilisons les informations que vous choisissez de renseigner dans Manage My Pain conformément à notre {0}. Vos données sont toujours stockées et transmises de manière sécurisée, grâce au cryptage AES-256.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'fr'),
    data_security_consent_web:
      "Je consens à ce que ManagingLife stocke et traite les informations que je choisis de renseigner dans Manage My Pain, dans la mesure où cela est nécessaire à la fourniture de fonctionnalités web relatives à l'application. Je peux retirer ce consentement à tout moment en supprimant mon compte.",

    data_security_consent_native:
      'Je consens à ce que ManagingLife stocke et traite les informations que je choisis de renseigner dans Manage My Pain dans la mesure où cela est nécessaire à la fourniture de fonctionnalités relatives à un compte. Je peux retirer ce consentement à tout moment en supprimant mon compte.',
    data_security_consent_not_provided_toast:
      "Votre consentement est nécessaire pour continuer. ManagingLife ne peut pas fournir les fonctionnalités relatives à l'application sans stocker et traiter les informations que vous choisissez de renseigner dans Manage My Pain.",
    data_security_submit_button: 'Soumettre et continuer',
    data_security_web_eula_text: 'En vous inscrivant, vous acceptez ceci : {0}',
    data_security_web_eula_link: shared('End User License Agreement', 'fr'),

    welcome_container_no_email_text:
      "Vous vous inscrivez avec {0} et nous avons besoin d'une adresse e-mail valide pour continuer.",

    signup_success_form_title: 'Inscription réussie',
    signup_success_form_text:
      "Avant de pouvoir vous connecter à Manage My Pain, vous devez activer votre compte. Cliquez sur le lien d'activation envoyé sur votre compte. Vous ne le trouvez pas ? Vérifiez votre dossier de courrier indésirable ou effectuez une nouvelle demande.",
    signup_success_form_login_button: shared('Log In', 'fr'),
    signup_success_form_send_link_again_button: 'Renvoyer le lien',

    expired_link_message:
      "Le lien a expiré. Veuillez essayer de générer un nouveau lien à partir de l'application ou contacter notre service d'assistance.",
    expired_link_open_app_button: "Ouvrir l'application",
    expired_link_contact_support_button: shared('contact_support', 'fr'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'fr',
    ),

    unsubscribe_message:
      "Êtes-vous sûr(e) de vouloir désabonner l'adresse {0} des emails envoyés par ManagingLife ? La prise en compte de ces changements peut nécessiter jusqu'à 24 heures.",
    unsubscribe_yes_button: 'Oui, me désabonner',
    unsubscribe_no_button: 'Non, je veux rester abonné(e)',
    unsubscribe_invalid_token:
      "Le lien a expiré ou n'est pas valide. Veuillez contacter le service d'assistance",

    unsubscribe_try_again_button: 'Réessayer',
    unsubscribe_contact_support_button: shared('contact_support', 'fr'),
    unsubscribe_loading: shared('Loading...', 'fr'),
    unsubscribe_activating: 'Désabonnement...',
    unsubscribe_success_msg: 'Votre désabonnement a été effectué avec succès',
    unsubscribe_error_msg:
      "Problème de désabonnement de l'utilisateur. Veuillez réessayer ou contacter le service d'assistance.",

    password_checker_score_0: ' ',
    password_checker_score_1: 'Faible',
    password_checker_score_2: 'Moyen',
    password_checker_score_3: 'Bon',
    password_checker_score_4: 'Excellent',
    password_checker_at_least_8_chars: 'Il doit contenir au moins 8 caractères',
    password_checker_warning_straightRow:
      'Les lignes droites de touches de votre clavier sont faciles à deviner',
    password_checker_warning_keyPattern:
      'Des motifs de clavier courts sont faciles à deviner',
    password_checker_warning_simpleRepeat:
      "Les caractères répétés comme 'aaa' sont faciles à deviner",
    password_checker_warning_extendedRepeat:
      "Les caractères répétés comme 'abcabcabc' sont faciles à deviner",
    password_checker_warning_sequences:
      "Des séquences de caractères communs comme 'abc' sont faciles à deviner",
    password_checker_warning_recentYears:
      'Les années récentes sont faciles à deviner.',
    password_checker_warning_dates: 'Les dates sont faciles à deviner.',
    password_checker_warning_topTen: 'Ce mot de passe est très utilisé.',
    password_checker_warning_topHundred:
      'Ce mot de passe est fréquemment utilisé.',
    password_checker_warning_common: 'Ce mot de passe est couramment utilisé.',
    password_checker_warning_similarToCommon:
      'Ce mot de passe est similaire à un mot de passe couramment utilisé.',
    password_checker_warning_wordByItself:
      'Les mots simples sont faciles à deviner.',
    password_checker_warning_namesByThemselves:
      'Des noms ou des prénoms simples sont faciles à deviner.',
    password_checker_warning_commonNames:
      'Les noms communs et les noms de famille sont faciles à deviner.',
    password_checker_warning_userInputs:
      'Le mot de passe ne doit pas comporter de données personelles.',
    password_checker_strength_label: 'Force : {0}',

    reset_form_password_screen_header: 'Modifier le mot de passe',
    reset_form_password_top_label: 'Modifier le mot de passe',
    reset_form_password_new_password_label: 'Nouveau mot de passe',
    reset_form_password_new_password_helper:
      'Il doit contenir au moins 6 caractères',
    reset_form_password_new_password_invalid_error: 'Adresse e-mail non valide',
    reset_form_password_reset_password_button: 'Adresse e-mail non valide',
    reset_form_password_loading_text:
      'Demande de réinitialisation du mot de passe...',
    reset_form_password_success_text: shared('reset_password_success', 'fr'),
    reset_form_password_ok_button_text: shared('OK', 'fr'),
    reset_form_password_top_new_label: 'Modifier le mot de passe',
    reset_form_password_bottom_new_label:
      "Veuillez choisir un nouveau mot de passe pour le compte associé à l'adresse {0}",

    reset_password_activating: 'Modification de votre mot de passe...',
    reset_password_success_msg:
      'Félicitations! Le mot de passe associé à votre compte a été modifié avec succès.',
    reset_password_error_msg:
      "Problème de réinitialisation du mot de passe. Veuillez demander une nouvelle réinitialisation du mot de passe ou contacter le service d'assistance.",
    reset_password_login_button: shared('Log In', 'fr'),
    reset_password_request_new_link: 'Réessayer',
    reset_password_contact_support: shared('contact_support', 'fr'),
    reset_password_invalid_token:
      "Le lien a expiré ou n'est pas valide. Veuillez demander un nouveau lien en vous connectant ou en contactant le service d'assistance.",

    verify_email_activating: "Vérification de l'adresse e-mail...",
    verify_email_success_msg:
      "Félicitations! L'adresse e-mail associée à votre compte a été modifiée avec succès.",
    verify_email_error_msg:
      "Problème de vérification de l'adresse e-mail. Veuillez demander un nouveau lien en vous connectant ou en contactant le service d'assistance.",
    verify_email_request_new_link: shared('login_request_new_link', 'fr'),
    verify_email_contact_support: shared('contact_support', 'fr'),

    // Facebook Button
    facebook_button_email_differs:
      'Vous essayez de vous connecter avec un autre compte - veuillez réessayer avec votre compte',
    facebook_button_continue_text: 'Continuer avec Facebook',
    facebook_button_connect_text: 'Se connecter avec Facebook',
    facebook_button_disconnect_text: 'Se déconnecter de Facebook',
    facebook_button_logging_in_loading_overlay:
      'Connexion avec Facebook en cours…',
    facebook_button_connecting_loading_overlay:
      'Connexion avec Facebook en cours…',
    facebook_button_disconnecting_loading_overlay:
      'Déconnexion de Facebook en cours...',
    facebook_button_disconnecting_without_password_popup_header:
      'Se déconnecter sans mot de passe ?',
    facebook_button_disconnecting_without_password_popup_text:
      'Vous ne pouvez pas vous connecter sans avoir créé un mot de passe',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'fr'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'fr',
    ),

    // Google Button
    google_button_email_differs:
      'Vous essayez de vous connecter avec un autre compte - veuillez réessayer avec votre compte',
    google_button_continue_text: 'Se connecter avec Google',
    google_button_connect_text: 'Se connecter avec Google',
    google_button_disconnect_text: 'Se déconnecter de Google',
    google_button_logging_in_loading_overlay: 'Connexion avec Google en cours…',
    google_button_connecting_loading_overlay: 'Connexion avec Google en cours…',
    google_button_disconnecting_loading_overlay:
      'Déconnexion de Google en cours…',
    google_button_disconnecting_without_password_popup_header:
      'Se déconnecter sans mot de passe ?',
    google_button_disconnecting_without_password_popup_text:
      'Vous ne pouvez pas vous connecter sans avoir créé un mot de passe',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'fr'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'fr',
    ),

    // Apple Button
    apple_button_email_differs:
      'Vous essayez de vous connecter avec un autre compte - veuillez réessayer avec votre compte',
    apple_button_continue_text: 'Continuer avec Apple',
    apple_button_connect_text: 'Se connecter avec Apple',
    apple_button_disconnect_text: 'Se déconnecter de Apple',
    apple_button_logging_in_loading_overlay: 'Connexion avec Apple en cours…',
    apple_button_connecting_loading_overlay: 'Connexion avec Apple en cours…',
    apple_button_disconnecting_loading_overlay:
      'Déconnexion de Apple en cours...',
    apple_button_disconnecting_without_password_popup_header:
      'Se déconnecter sans mot de passe ?',
    apple_button_disconnecting_without_password_popup_text:
      'Vous ne pouvez pas vous connecter sans avoir créé un mot de passe',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'fr',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'fr',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: 'Bienvenue {0}',
    facebook_welcome_email_header: 'Vous êtes connecté à Facebook avec:',
    facebook_welcome_email_login_header: 'Vous vous connectez avec Facebook à',
    facebook_welcome_email_signup_header:
      'Vous vous inscrivez avec Facebook en utilisant',
    facebook_welcome_policy_start_label: "J'accepte et reconnais la {0}",
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'fr'),
    facebook_welcome_continue_button: shared('Continue', 'fr'),
    facebook_welcome_loading_text: 'Connexion en cours…',
    facebook_welcome_policy_not_accepted_error:
      'Vous devez accepter la Politique de Confidentialité pour continuer',
    facebook_welcome_email_label: shared('Email address', 'fr'),
    facebook_welcome_email_helper:
      'Vous devez ajouter une adresse e-mail pour continuer',
    facebook_signup_success_text:
      "Compte créé - cliquez sur le lien d'activation qui vous a été envoyé par e-mail, puis connectez-vous (veuillez également vérifier le dossier Spam / Courrier indésirable)",
    facebook_signup_ok_button_text: shared('OK', 'fr'),

    // Apple Welcome screen
    apple_welcome_welcome_label: 'Bienvenue {0}',
    apple_welcome_email_header: 'Vous êtes connecté à Apple avec:',
    apple_welcome_email_login_header: 'Vous vous connectez avec Apple à',
    apple_welcome_email_signup_header:
      'Vous vous inscrivez avec Apple en utilisant',
    apple_welcome_policy_start_label: "J'accepte et reconnais la {0}",
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'fr'),
    apple_welcome_continue_button: shared('Continue', 'fr'),
    apple_welcome_loading_text: 'Connexion en cours…',
    apple_welcome_policy_not_accepted_error:
      'Vous devez accepter la Politique de Confidentialité pour continuer',
    apple_welcome_email_label: shared('Email address', 'fr'),
    apple_welcome_email_helper:
      'Vous devez ajouter une adresse e-mail pour continuer',
    apple_signup_success_text:
      "Compte créé - cliquez sur le lien d'activation qui vous a été envoyé par e-mail, puis connectez-vous (veuillez également vérifier le dossier Spam / Courrier indésirable)",
    apple_signup_ok_button_text: shared('OK', 'fr'),

    // Google Welcome screen
    google_welcome_welcome_label: 'Bienvenue {0}',
    google_welcome_email_header: 'Vous êtes connecté à Google avec:',
    google_welcome_email_login_header: 'Vous vous connectez avec Google à',
    google_welcome_email_signup_header:
      'Vous vous inscrivez avec Google en utilisant',
    google_welcome_policy_start_label: "J'accepte et reconnais la {0}",
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'fr'),
    google_welcome_continue_button: shared('Continue', 'fr'),
    google_welcome_loading_text: 'Connexion en cours…',
    google_welcome_policy_not_accepted_error:
      'Vous devez accepter la Politique de Confidentialité pour continuer',
    google_welcome_error_189:
      "Trop de temps s'est écoulé - veuillez vous inscrire à nouveau",

    // Log In screen
    login_screen_header: shared('Log In', 'fr'),
    login_below_social_buttons:
      'Ou connectez-vous en utilisant votre adresse e-mail',
    login_email_label: shared('Email address', 'fr'),
    login_email_helper: null,
    login_password_label: shared('Password', 'fr'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'fr'),
    login_sign_up_header: shared('Not registered yet', 'fr') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'fr'),
    login_reset_password_button: shared('Reset Password', 'fr'),
    login_loading_text: 'Connexion en cours…',
    login_logged_out_warning_text:
      'Par mesure de sécurité, vous avez été déconnecté - veuillez vous reconnecter pour continuer',
    login_force_logout_header:
      'ou continuer à vous déconnecter et à ignorer les modifications non enregistrées',
    login_force_logout_button: shared('Log Out', 'fr'),
    login_force_logout_warning:
      'Vous avez des enregistrements non synchronisés qui seront perdus. Voulez-vous continuer ?',
    login_force_logout_warning_log_out: shared('Log Out', 'fr'),
    login_force_logout_warning_cancel: shared('Cancel', 'fr'),

    // Log In error handling (Activation)
    activation_required_title: 'Activation nécessaire',
    activation_required_message:
      "Cliquez sur le lien d'activation que nous avons envoyé à votre courriel électronique. Si vous ne le trouvez pas, vérifiez le dossier de pourriel ou demandez un nouvel lien.",
    activation_resend_success:
      "Lien envoyé - cliquez sur le lien d'activation qui vous a été envoyé par e-mail, puis connectez-vous",
    activation_ok_button_text: shared('OK', 'fr'),
    activation_resend_button_text: 'Demander un nouveau lien',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'fr'),
    password_needs_to_be_set_message:
      'Essayez de vous connecter avec Facebook, Google ou créez un mot de passe',
    password_needs_to_be_set_ios_message:
      'Essayez de vous connecter avec Apple, Facebook, ou Google ou créez un mot de passe',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'fr'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'fr'),
    password_needs_to_be_set_success_message:
      'Maintenant vous pouvez créer un mot de passe. Vérifiez votre boîte de réception',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title:
      'Vous ne pouvez pas vous connecter',
    password_needs_to_be_set_not_activated_message:
      'Vous devez activer votre compte et créer un mot de passe. Essayez de vous connecter avec Facebook.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      'Continuer avec Facebook',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'fr',
    ),

    // Register screen
    signup_screen_header: "S'enregistrer",
    signup_below_social_buttons:
      'ou enregistrez-vous avec votre adresse e-mail',
    signup_email_label: shared('Email address', 'fr'),
    signup_email_helper: null,
    signup_email_invalid_error: 'Adresse e-mail pas valide',
    signup_password_label: shared('Password', 'fr'),
    signup_password_helper: 'Il doit contenir au moins 6 caractères',
    signup_password_too_short_error:
      'Trop court. Le mot de passe doit contenir au moins 5 caractères',
    signup_login_button_header: shared('already_registered', 'fr'),
    signup_login_button: shared('Log In', 'fr'),
    signup_policy_start_label: "J'accepte et reconnais la {0}",
    signup_policy_clickable_label: shared('Privacy Policy', 'fr'),
    signup_policy_not_accepted_error:
      'Vous devez accepter la Politique de Confidentialité pour continuer',
    signup_signup_button: "S'enregistrer",
    signup_loading_text: 'Enregistrement en cours…',
    signup_email_exists_error_start:
      "L'adresse e-mail est déjà utilisé. Veuillez vous connecter ou rétablir votre mot de passe pour procéder.",
    signup_email_exists_error_login: shared('Log In', 'fr'),
    signup_email_exists_error_recover: shared('Reset Password', 'fr'),
    signup_email_exists_error_alert_title:
      "Adresse e-mail spécifiée n'est pas valide",
    signup_email_exists_error_alert_message:
      "L'adresse e-mail est déjà utilisé. Veuillez vous connecter ou rétablir votre mot de passe pour procéder.",
    signup_email_exists_error_alert_cancel: shared('Cancel', 'fr'),
    signup_email_exists_error_alert_login: shared('Log In', 'fr'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'fr'),
    signup_success_text:
      "Compte créé - cliquez sur le lien d'activation qui vous a été envoyé par e-mail, puis connectez-vous (veuillez également vérifier le dossier Spam / Courrier indésirable)",
    signup_ok_button_text: shared('OK', 'fr'),
    signup_account_needs_to_be_activated_toast:
      'Le compte doit être activé. Cherchez le lien dans votre boîte de réception',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'fr'),
    reset_password_top_label:
      "Écrivez l'adresse e-mail que vous avez enregistrée",
    reset_password_email_label: shared('Email address', 'fr'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: 'Adresse e-mail pas valide',
    reset_password_reset_password_button: 'Demander la réinitialisation',
    reset_password_bottom_label: null,
    reset_password_loading_text: 'Demande de réinitialisation en cours...',
    reset_password_success_text: shared('reset_password_success', 'fr'),
    reset_password_ok_button_text: shared('OK', 'fr'),

    // Change password screen
    change_password_screen_header: 'Changer le mot de passe',
    change_password_old_password_label: 'Ancien mot de passe',
    change_password_old_password_helper: null,
    change_password_new_password_label: 'Nouveau mot de passe',
    change_password_new_password_helper:
      'Il doit contenir au moins 6 caractères',
    change_password_new_password_too_short_error:
      'Trop court. Le mot de passe doit contenir au moins 5 caractères',
    change_password_button_text: 'Changer le mot de passe',
    change_password_loading_text: 'Changement de mot de passe en cours…',
    change_password_success_text: 'Mot de passe changé avec succès',
    change_password_ok_button_text: shared('OK', 'fr'),

    // Change email screen
    change_email_screen_header: "Changer l'adresse e-mail",
    change_email_old_email_label: 'Ancienne adresse e-mail',
    change_email_old_email_helper: null,
    change_email_new_email_label: 'Nouvelle adresse e-mail',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: 'Adresse e-mail pas valide',
    change_email_password_label: shared('Password', 'fr'),
    change_email_password_helper: null,
    change_email_button_text: "Changer l'adresse e-mail",
    change_email_loading_text: "Changement d'adresse e-mail en cours…",
    change_email_success_text:
      "Envoi de demande de changement d'adresse e-mail réussi. Vérifiez votre boîte de réception (veuillez également vérifier le dossier Spam / Courrier indésirable)",
    change_email_ok_button_text: shared('OK', 'fr'),

    // Logout message
    logout_logging_out_message_for_overlay: 'Déconnexion en cours…',
    logout_sync_failed_title:
      'Echec de la synchronisation lors de la déconnexion',
    logout_sync_failed_message:
      'Des données non synchronisées seront perdues si vous poursuivez votre déconnexion',
    logout_sync_failed_force_logout_button:
      'Se déconnecter et perdre les données non synchronisées',
    logout_sync_failed_try_again_button: 'Retenter la synchronisation',
    logout_sync_failed_cancel_button: shared('Cancel', 'fr'),

    // Landing page
    download_app_message:
      'Votre compte a été créé avec succès. Vous pouvez désormais vous connecter à Manage My Pain.',
    join_institution_error:
      "Impossible de joindre l'institution à cause de l'erreur suivant: ",
    login_success_consent_provided:
      "Consentement donné avec succès - veuillez continuer à l'utiliser Manage My Pain",
    login_timeout_top_label:
      '{0}\nNous vous avons déconnecté(e) pour des raisons de sécurité. Veuillez vous connecter pour reprendre là où vous en étiez.',
    login_header_in_association_with: 'en partenariat avec',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: 'E-mail Masqué',
    login_web_institution_header: 'en partenariat avec',
    login_invited_with_email_text:
      'Vous avez reçu une invitation par le biais de cette adresse e-mail. Vous pourrez modifier cette dernière ultérieurement.',
    signup_success_activation_code_text:
      'Votre compte a été créé avec succès. Vous pouvez désormais vous connecter à Manage My Pain.',
    reset_password_top_new_label:
      "Vous avez oublié votre mot de passe ?\nPas d'inquiétude, nous vous aiderons à le réinitialiser.",
    reset_password_bottom_new_label:
      "Indiquez l'adresse e-mail associée à votre compte - un e-mail vous sera envoyé avec de plus amples instructions.",
    consent_screen_header: 'Consentement requis pour partager vos données avec',
    consent_screen_dialog_header: 'Consentement est nécessaire',
    consent_screen_primary_id_regexp_error:
      'Veuillez saisir {0} dans le bon format',
    consent_screen_empty_field_error: 'Ce champ ne peut pas être vide',
    consent_screen_primary_id_label: 'Saisissez votre nom complet',
    consent_screen_birth_date_label: 'Date de naissance',
    consent_screen_decline_button: "Je n'accepte pas",
    consent_screen_accept_button: "J'accepte",
    powered_by: 'réalisé par',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: 'vous permet de prendre le contrôle',
    landing_blue_card_sub_header:
      'Ne laissez pas la douleur chronique vous empêcher de vivre votre vie et de faire les choses qui vous plaisent.',
    landing_blue_card_string1:
      'Effectuez un suivi de votre douleur et de votre activité',
    landing_blue_card_string2: 'Analysez votre douleur',
    landing_blue_card_string3: 'Partagez votre douleur',
    landing_blue_card_string4: "Apprenez auprès d'experts en douleur",
    landing_blue_card_background: '#0049AC',
    landing_card1_header:
      'Effectuez un suivi de votre douleur et de votre activité',
    landing_card1_content:
      'Pour commencer, il suffit de prendre 45 secondes pour repenser à votre journée.',
    landing_card2_header: 'Analysez votre douleur',
    landing_card2_content:
      'Les graphiques et les diagrammes facilitent la compréhension des facteurs qui influencent votre douleur',
    landing_card3_header: 'Partagez votre douleur',
    landing_card3_content:
      'Nos rapports, créés par des médecins pour des médecins, vous aideront à raconter votre histoire.',
    landing_footer_string1: 'Utilisez Manage My Pain lors de vos déplacements!',
    landing_footer_string2:
      "L'application Manage My Pain est disponible pour Android et iOS également.",
    landing_promo_card_header: 'Enregistrez comment vous vous sentez',
    landing_promo_card_sub_header:
      'Repensez à votre journée et décrivez facilement votre douleur en moins de 45 secondes.',
    landing_promo_card_item1_primary: 'Facile et rapide',
    landing_promo_card_item1_secondary:
      'Des curseurs et des cases à cocher simples assurent un enregistrement rapide',
    landing_promo_card_item2_primary: 'Personnalisable',
    landing_promo_card_item2_secondary:
      'Chaque rubrique est préremplie, mais vous pouvez la personnaliser pour satisfaire vos besoins spécifiques',
    landing_promo_card_item3_primary: 'Une fois par jour',
    landing_promo_card_item3_secondary:
      "Un rappel vous demandera comment s'est passée votre journée et vos impressions sur la douleur.",
    landing_in_association_with: 'En partenariat avec',
    landing_scroll_to: 'Faites défiler pour en savoir plus',
    landing_register_now: 'Inscrivez-vous maintenant',
    landing_logo_header_text: 'Suivre, analyser, et partager votre douleur',

    intro_about_app_point_1_title:
      'Effectuez un suivi de votre douleur et de votre activité',
    intro_about_app_point_1_subtitle:
      "Passez en revue votre journée en moins de 60 secondes afin d'identifier des tendances",
    intro_about_app_point_2_title: 'Analysez votre douleur',
    intro_about_app_point_2_subtitle:
      'Des graphiques et diagrammes facilitent la détermination des facteurs qui atténuent ou aggravent la douleur',
    intro_about_app_point_3_title: 'Partagez votre douleur',
    intro_about_app_point_3_subtitle:
      'Nos rapports, créés par des médecins pour des médecins, vous aideront à raconter votre histoire',
    intro_about_app_point_3_button: 'Télécharger un exemple de rapport',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-fr.pdf',
    intro_about_app_point_4_title: "Apprenez auprès d'experts en douleur",
    intro_about_app_point_4_subtitle:
      'Découvrez du contenu sur le mécanisme de la douleur et les stratégies pour la gérer',
    intro_about_app_point_4_subtitle_for_subscribers:
      '(uniquement pour les abonnés)',

    activate_account_activating: 'Activation du compte...',
    activate_account_success_msg:
      "Félicitations - votre compte a été activé avec succès. Téléchargez l'application et connectez-vous.",
    activate_account_error_msg:
      "Un problème est survenu lors de l'activation du compte - veuillez demander un nouveau lien d'activation en vous connectant ou en contactant l'assistance.",
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'fr',
    ),
    activate_account_contact_support: shared('contact_support', 'fr'),
  },

  ko: {
    eula_updated_form_title_text:
      '최종 이용자 라이선스 계약이 업데이트되었습니다.',
    eula_updated_message_1_text:
      '이러한 약관 업데이트 시 귀하에게 알려드리는 것이 당사의 책임입니다. 본 업데이트에서',
    eula_updated_message_2_text:
      '• 당사는 약관을 다시 작성하여 보다 쉽게 읽고 이해하도록 하였습니다.',
    eula_updated_message_3_text:
      '• 당사는 당사 {0}에 따라 귀하의 개인정보를 이용하고 보호하는 데 전념합니다.',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'ko'),

    eula_updated_message_4_text:
      '• 당사는 당사에서 명시적 동의 없이 귀하의 개인정보를 절대 공개하지 않을 거라는 입장을 강화합니다.',
    eula_updated_link_message: '귀하는 가입함으로써 {0}에 동의하게 됩니다.',
    eula_updated_link_part: shared('End User License Agreement', 'ko'),
    eula_updated_continue_button: '확인하고 계속하기',

    eula_updated_cancel_dialog_title: '계속하시겠습니까?',
    eula_updated_cancel_dialog_text:
      '귀하는 Manage My Pain을 계속 이용하려면 업데이트된 EULA를 수락해야 합니다. 정말 끝내시겠습니까?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'ko'),
    eula_updated_cancel_dialog_no_button: shared('No', 'ko'),

    data_security_title: '귀하는 본 약관의 지배를 받고 있습니다.',
    data_security_registering_with_provider:
      '귀하는 {1}의 주소를 이용하여 {0} 계정으로 가입하고 있습니다.',
    data_security_privacy_policy_message:
      '당사는 귀하가 당사의 {0}에 따라 Manage My Pain에 입력하기로 선택한 정보를 이용합니다. 귀하의 데이터는 항상 AES-256 암호화로 안전하게 저장되고 전송됩니다.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'ko'),
    data_security_consent_web:
      '본인은 귀하가 앱의 웹 기반 기능 제공에 필요함에 따라 본인이 Manage My Pain에 입력하기로 선택한 정보를 ManagingLife에서 저장하고 처리하는 데 동의합니다. 본인은 언제든지 본인의 계정을 해지하여 동의를 철회할 수 있습니다.',

    data_security_consent_native:
      '본인은 귀하가 앱의 계정 기능 제공에 필요함에 따라 본인이 Manage My Pain에 입력하기로 선택한 정보를 ManagingLife에서 저장하고 처리하는 데 동의합니다. 본인은 언제든지 본인의 계정을 해지하여 동의를 철회할 수 있습니다.',
    data_security_consent_not_provided_toast:
      '계속하려면 귀하의 동의가 필요합니다. ManagingLife는 귀하가 Manage My Pain에 입력하기로 선택한 정보를 저장 및 처리하지 않고 앱의 기능을 제공할 수 없습니다.',
    data_security_submit_button: '제출하고 계속하기',
    data_security_web_eula_text: '귀하는 가입함으로써 {0}에 동의하게 됩니다.',
    data_security_web_eula_link: shared('End User License Agreement', 'ko'),

    welcome_container_no_email_text:
      '귀하는 {0} 계정으로 가입하고 있으며 계속 진행하려면 올바른 이메일 주소가 필요합니다.',

    signup_success_form_title: '가입 성공',
    signup_success_form_text:
      '계정을 활성화해야 Manage My Pain에 로그인할 수 있습니다. 계정으로 전송된 활성화 링크를 클릭하세요. 링크를 찾을 수 없으신가요? 스팸 메일함을 확인하거나 새 링크를 요청하세요.',
    signup_success_form_login_button: shared('Log In', 'ko'),
    signup_success_form_send_link_again_button: '링크 재전송',

    expired_link_message:
      '링크가 만료됐습니다. 앱에서 새 링크를 생성하거나 지원팀에 문의해주세요.',
    expired_link_open_app_button: '앱 열기',
    expired_link_contact_support_button: shared('contact_support', 'ko'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'ko',
    ),

    unsubscribe_message:
      'ManagingLife에서 보낸 이메일에서 {0}의 가입을 취소하시겠습니까? 변경사항이 적용되는 데 최대 24시간이 걸릴 수 있습니다.',
    unsubscribe_yes_button: '예, 구독을 취소합니다.',
    unsubscribe_no_button: '아니요, 구독 상태를 유지하고 싶습니다.',
    unsubscribe_invalid_token:
      '링크가 만료되었거나 잘못되었습니다. 지원부에 문의하십시오.',

    unsubscribe_try_again_button: '다시 시도하십시오.',
    unsubscribe_contact_support_button: shared('contact_support', 'ko'),
    unsubscribe_loading: shared('Loading...', 'ko'),
    unsubscribe_activating: '구독을 취소하는 중입니다...',
    unsubscribe_success_msg: '구독이 취소되었습니다.',
    unsubscribe_error_msg:
      '사용자 등록을 취소하는 동안 문제가 발생했습니다. 다시 시도하거나 지원부에 문의하십시오.',

    password_checker_score_0: ' ',
    password_checker_score_1: '안좋음',
    password_checker_score_2: '괜찮음',
    password_checker_score_3: '좋아요',
    password_checker_score_4: '훌륭합니다',
    password_checker_at_least_8_chars: '8자 이상이어야 합니다',
    password_checker_warning_straightRow:
      '키보드의 일직선 키는 추측하기 쉽습니다.',
    password_checker_warning_keyPattern:
      '짧은 키보드 패턴은 추측하기 쉽습니다.',
    password_checker_warning_simpleRepeat:
      'aa와 같이 반복되는 문자는 추측하기 쉽습니다.',
    password_checker_warning_extendedRepeat:
      "abcabcabc'와 같은 반복되는 문자 패턴은 추측하기 쉽습니다.",
    password_checker_warning_sequences:
      'abc"와 같은 일반적인 문자 시퀀스는 추측하기 쉽습니다.',
    password_checker_warning_recentYears: '최근 몇 년은 추측하기 쉽습니다.',
    password_checker_warning_dates: '날짜는 추측하기 쉽습니다.',
    password_checker_warning_topTen: '이것은 많이 사용되는 비밀번호입니다.',
    password_checker_warning_topHundred: '이것은 자주 사용되는 비밀번호입니다.',
    password_checker_warning_common: '일반적으로 사용되는 암호입니다.',
    password_checker_warning_similarToCommon:
      '이것은 일반적으로 사용되는 암호와 유사합니다.',
    password_checker_warning_wordByItself: '단어 한 개는 추측하기 쉽습니다.',
    password_checker_warning_namesByThemselves:
      '단일 이름이나 성은 추측하기 쉽습니다.',
    password_checker_warning_commonNames: '흔한 이름과 성은 추측하기 쉽습니다.',
    password_checker_warning_userInputs:
      '개인 데이터나 페이지 관련 데이터가 있으면 안 됩니다.',
    password_checker_strength_label: '비밀번호 보안 강도: {0}',

    reset_form_password_screen_header: '암호 변경',
    reset_form_password_top_label: '암호 변경',
    reset_form_password_new_password_label: '새 암호',
    reset_form_password_new_password_helper: '6자 이상이어야 합니다',
    reset_form_password_new_password_invalid_error:
      '이메일 주소가 잘못되었습니다.',
    reset_form_password_reset_password_button: '암호 변경',
    reset_form_password_loading_text: '암호 재설정을 요청하는 중입니다...',
    reset_form_password_success_text: shared('reset_password_success', 'ko'),
    reset_form_password_ok_button_text: shared('OK', 'ko'),
    reset_form_password_top_new_label: '암호 변경',
    reset_form_password_bottom_new_label:
      '{0}과(와) 연결된 계정의 새 암호를 선택하십시오.',

    reset_password_activating: '암호를 변경하는 중입니다...',
    reset_password_success_msg:
      '축하합니다. 계정의 암호가 성공적으로 변경되었습니다.',
    reset_password_error_msg:
      '암호 재설정 문제 - 새 암호 재설정을 요청하거나 지원부에 문의하십시오.',
    reset_password_login_button: shared('Log In', 'ko'),
    reset_password_request_new_link: '다시 시도하십시오.',
    reset_password_contact_support: shared('contact_support', 'ko'),
    reset_password_invalid_token:
      '링크가 만료되었거나 잘못되었습니다 - 로그인하거나 지원부에 문의하여 새 링크를 요청하십시오.',

    verify_email_activating: '전자 메일 주소를 확인하는 중입니다...',
    verify_email_success_msg:
      '축하합니다. 계정의 전자 메일 주소가 성공적으로 변경되었습니다. 앱을 다운로드하고 로그인하세요.',
    verify_email_error_msg:
      '전자 메일 주소 확인 문제 - 로그인하거나 지원부에 문의하여 새 링크를 요청하십시오.',
    verify_email_request_new_link: shared('login_request_new_link', 'ko'),
    verify_email_contact_support: shared('contact_support', 'ko'),

    // Facebook Button
    facebook_button_email_differs:
      '다른 계정으로 로그인을 시도합니다. 귀하의 계정으로 다시 시도하세요',
    facebook_button_continue_text: '페이스북으로 계속하기',
    facebook_button_connect_text: '페이스북으로 연결',
    facebook_button_disconnect_text: '페이스북에서 연결 끊기',
    facebook_button_logging_in_loading_overlay: '페이스북으로 로그인하는 중...',
    facebook_button_connecting_loading_overlay: '페이스북과 연결 중...',
    facebook_button_disconnecting_loading_overlay:
      '페이스북에서 연결을 끊는 중...',
    facebook_button_disconnecting_without_password_popup_header:
      '암호 없이 연결을 끊으시겠습니까?',
    facebook_button_disconnecting_without_password_popup_text:
      '암호를 설정할 때까지 로그인할 수 없습니다.',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'ko'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ko',
    ),

    // Google Button
    google_button_email_differs:
      '다른 계정으로 로그인을 시도합니다. 귀하의 계정으로 다시 시도하세요',
    google_button_continue_text: '구글로 로그인',
    google_button_connect_text: '구글과 연결',
    google_button_disconnect_text: '구글에서 연결 끊기',
    google_button_logging_in_loading_overlay: '구글로 로그인하는 중...',
    google_button_connecting_loading_overlay: '구글과 연결하는 중...',
    google_button_disconnecting_loading_overlay: '구글에서 연결을 끊는 중...',
    google_button_disconnecting_without_password_popup_header:
      '암호 없이 연결을 끊으시겠습니까?',
    google_button_disconnecting_without_password_popup_text:
      '암호를 설정할 때까지 로그인할 수 없습니다.',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'ko'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ko',
    ),

    // Apple Button
    apple_button_email_differs:
      '다른 계정으로 로그인을 시도합니다. 귀하의 계정으로 다시 시도하세요',
    apple_button_continue_text: 'Apple으로 계속하기',
    apple_button_connect_text: 'Apple으로 연결',
    apple_button_disconnect_text: 'Apple에서 연결 끊기',
    apple_button_logging_in_loading_overlay: 'Apple으로 로그인하는 중...',
    apple_button_connecting_loading_overlay: 'Apple과 연결 중...',
    apple_button_disconnecting_loading_overlay: 'Apple에서 연결을 끊는 중...',
    apple_button_disconnecting_without_password_popup_header:
      '암호 없이 연결을 끊으시겠습니까?',
    apple_button_disconnecting_without_password_popup_text:
      '암호를 설정할 때까지 로그인할 수 없습니다.',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'ko',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ko',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: '{0}에 오신 걸 환영합니다',
    facebook_welcome_email_header: '다음을 사용하여 페이스북에 연결됩니다:',
    facebook_welcome_email_login_header:
      '페이스북으로 다음에 로그인하고 있습니다:',
    facebook_welcome_email_signup_header:
      '다음을 사용하여 페이스북으로 가입하고 있습니다:',
    facebook_welcome_policy_start_label: '{0}을/를 수락했으며 동의했습니다',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'ko'),
    facebook_welcome_continue_button: shared('Continue', 'ko'),
    facebook_welcome_loading_text: '로그인 중입니다...',
    facebook_welcome_policy_not_accepted_error:
      '계속하려면 개인 정보 보호 정책에 동의해야 합니다',
    facebook_welcome_email_label: shared('Email address', 'ko'),
    facebook_welcome_email_helper: '계속하려면 이메일 주소가 필요합니다',
    facebook_signup_success_text:
      '계정이 생성됨 - 이메일로 전송된 활성화 링크를 클릭한 다음 로그인합니다 (스팸/휴지통을 확인하세요)',
    facebook_signup_ok_button_text: shared('OK', 'ko'),

    // Apple Welcome screen
    apple_welcome_welcome_label: '{0}에 오신 걸 환영합니다',
    apple_welcome_email_header: '다음을 사용하여 Apple에 연결됩니다:',
    apple_welcome_email_login_header: 'Apple 로 다음에 로그인하고 있습니다:',
    apple_welcome_email_signup_header:
      '다음을 사용하여 Apple 로 가입하고 있습니다:',
    apple_welcome_policy_start_label: '{0}을/를 수락했으며 동의했습니다',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'ko'),
    apple_welcome_continue_button: shared('Continue', 'ko'),
    apple_welcome_loading_text: '로그인 중입니다...',
    apple_welcome_policy_not_accepted_error:
      '계속하려면 개인 정보 보호 정책에 동의해야 합니다',
    apple_welcome_email_label: shared('Email address', 'ko'),
    apple_welcome_email_helper: '계속하려면 이메일 주소가 필요합니다',
    apple_signup_success_text:
      '계정이 생성됨 - 이메일로 전송된 활성화 링크를 클릭한 다음 로그인합니다 (스팸/휴지통을 확인하세요)',
    apple_signup_ok_button_text: shared('OK', 'ko'),

    // Google Welcome screen
    google_welcome_welcome_label: '{0}에 오신 걸 환영합니다',
    google_welcome_email_header: '다음 기능을 사용하여 구글에 연결되었습니다:',
    google_welcome_email_login_header:
      '구글을 사용하여 다음에 로그인하고 있습니다:',
    google_welcome_email_signup_header:
      '다음을 사용하여 구글로 가입하고 있습니다:',
    google_welcome_policy_start_label: '{0}을/를 수락했으며 동의했습니다',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'ko'),
    google_welcome_continue_button: shared('Continue', 'ko'),
    google_welcome_loading_text: '로그인 중입니다...',
    google_welcome_policy_not_accepted_error:
      '계속하려면 개인 정보 보호 정책에 동의해야 합니다',
    google_welcome_error_189:
      '너무 많은 시간이 경과했습니다. 다시 등록해주세요.',

    // Log In screen
    login_screen_header: shared('Log In', 'ko'),
    login_below_social_buttons: '또는 이메일 주소를 사용하여 로그인합니다',
    login_email_label: shared('Email address', 'ko'),
    login_email_helper: null,
    login_password_label: shared('Password', 'ko'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'ko'),
    login_sign_up_header: shared('Not registered yet', 'ko') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'ko'),
    login_reset_password_button: shared('Reset Password', 'ko'),
    login_loading_text: '로그인 중입니다...',
    login_logged_out_warning_text:
      '보안 조치로 로그아웃 되셨습니다. 계속하려면 다시 로그인하세요.',
    login_force_logout_header:
      '또는 로그아웃하여 저장하지 않은 변경 사항을 삭제합니다',
    login_force_logout_button: shared('Log Out', 'ko'),
    login_force_logout_warning:
      '저장되지 않은 기록이 있습니다. 계속 하시겠습니까?',
    login_force_logout_warning_log_out: shared('Log Out', 'ko'),
    login_force_logout_warning_cancel: shared('Cancel', 'ko'),

    // Log In error handling (Activation)
    activation_required_title: '활성화해야 합니다',
    activation_required_message:
      '이메일로 전송된 활성화 링크를 클릭합니다. 찾을 수 없나요? 스팸 폴더를 확인하거나 새 활성화 링크를 요청하세요.',
    activation_resend_success:
      '링크 전송 - 이메일에 전송된 활성화 링크를 클릭하세요 그리고나서 로그인하세요',
    activation_ok_button_text: shared('OK', 'ko'),
    activation_resend_button_text: '새로운 링크 요청',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'ko'),
    password_needs_to_be_set_message:
      '페이스북 또는 구글로 로그인하거나 암호를 설정합니다.',
    password_needs_to_be_set_ios_message:
      'Apple 페이스북 또는 구글로 로그인하거나 암호를 설정합니다.',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'ko'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'ko'),
    password_needs_to_be_set_success_message:
      '이제 암호를 설정할 수 있습니다. 이메일을 확인하세요',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: '로그인할 수 없습니다',
    password_needs_to_be_set_not_activated_message:
      '계정을 활성화하고 암호를 설정해야 합니다. 대신 페이스북으로 로그인해 보세요.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      '페이스북으로 계속하기',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'ko',
    ),

    // Register screen
    signup_screen_header: '등록하세요',
    signup_below_social_buttons: '또는 이메일 주소를 사용하여 등록하세요',
    signup_email_label: shared('Email address', 'ko'),
    signup_email_helper: null,
    signup_email_invalid_error: '이메일 주소 추가가 잘못되었습니다',
    signup_password_label: shared('Password', 'ko'),
    signup_password_helper: '6자 이상이어야 합니다',
    signup_password_too_short_error: '너무 짧습니다. 6자 이상이어야 합니다',
    signup_login_button_header: shared('already_registered', 'ko'),
    signup_login_button: shared('Log In', 'ko'),
    signup_policy_start_label: '{0}을/를 수락했으며 동의했습니다',
    signup_policy_clickable_label: shared('Privacy Policy', 'ko'),
    signup_policy_not_accepted_error:
      '계속하려면 개인 정보 보호 정책에 동의해야 합니다',
    signup_signup_button: '등록하세요',
    signup_loading_text: '등록하는 중입니다...',
    signup_email_exists_error_start:
      '이메일 주소가 이미 사용 중입니다. 계속하려면 로그인하거나 암호를 재설정합니다.',
    signup_email_exists_error_login: shared('Log In', 'ko'),
    signup_email_exists_error_recover: shared('Reset Password', 'ko'),
    signup_email_exists_error_alert_title:
      '지정된 이메일 주소가 유효하지 않습니다!',
    signup_email_exists_error_alert_message:
      '이메일 주소가 이미 사용 중입니다. 계속하려면 로그인하거나 암호를 재설정합니다.',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'ko'),
    signup_email_exists_error_alert_login: shared('Log In', 'ko'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'ko'),
    signup_success_text:
      '계정이 생성됨 - 이메일로 전송된 활성화 링크를 클릭한 다음 로그인합니다 (스팸/휴지통을 확인하세요)',
    signup_ok_button_text: shared('OK', 'ko'),
    signup_account_needs_to_be_activated_toast:
      '계정을 활성화해야 합니다. 이메일에 링크가 있는지 확인하십시오',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'ko'),
    reset_password_top_label: '등록된 이메일 주소를 입력합니다',
    reset_password_email_label: shared('Email address', 'ko'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: '이메일 주소 추가가 잘못되었습니다',
    reset_password_reset_password_button: '비밀번호 재설정을 요청합니다',
    reset_password_bottom_label: null,
    reset_password_loading_text: '암호 재설정을 요청하는 중입니다...',
    reset_password_success_text: shared('reset_password_success', 'ko'),
    reset_password_ok_button_text: shared('OK', 'ko'),

    // Change password screen
    change_password_screen_header: '암호 변경',
    change_password_old_password_label: '이전 암호',
    change_password_old_password_helper: null,
    change_password_new_password_label: '새 암호',
    change_password_new_password_helper: '6자 이상이어야 합니다',
    change_password_new_password_too_short_error:
      '너무 짧습니다. 6자 이상이어야 합니다',
    change_password_button_text: '암호 변경',
    change_password_loading_text: '암호를 변경하는 중입니다...',
    change_password_success_text: '암호가 변경되었습니다',
    change_password_ok_button_text: shared('OK', 'ko'),

    // Change email screen
    change_email_screen_header: '이메일 주소를 변경합니다',
    change_email_old_email_label: '이전 이메일 주소',
    change_email_old_email_helper: null,
    change_email_new_email_label: '새 이메일 주소',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: '이메일 주소가 잘못되었습니다',
    change_email_password_label: shared('Password', 'ko'),
    change_email_password_helper: null,
    change_email_button_text: '이메일 주소를 변경합니다',
    change_email_loading_text: '이메일 주소를 변경합니다...',
    change_email_success_text:
      '이메일 변경 요청이 전송되었습니다. 새 이메일 상자를 확인하십시오 (스팸/휴지통을 확인하세요)',
    change_email_ok_button_text: shared('OK', 'ko'),

    // Logout message
    logout_logging_out_message_for_overlay: '로그아웃 합니다…',
    logout_sync_failed_title: '로그아웃 하는 동안 동기화 실패',
    logout_sync_failed_message:
      '동기화되지 않은 데이터가 있습니다. 로그아웃을 계속하면 손실됩니다.',
    logout_sync_failed_force_logout_button:
      '로그아웃하면 동기화되지 않은 데이터가 손실됩니다.',
    logout_sync_failed_try_again_button: '동기화 재시도',
    logout_sync_failed_cancel_button: shared('Cancel', 'ko'),

    // Landing page

    download_app_message:
      '계정이 성공적으로 생성되었습니다. Manage My Pain에 로그인하실 수 있습니다.',
    join_institution_error:
      '다음과 같은 오류로 인해 기관에 가입할 수 없습니다 : ',
    login_success_consent_provided:
      '콘텐츠가 성공적으로 제공되었습니다 - Manage My Pain을 계속 사용하세요',
    login_timeout_top_label:
      '안녕하세요, {0}\n우리는 보안상의 이유로 귀하를 로그아웃했습니다 - 현재 귀하가 있는 페이지에서 계속하려면 로그인해주세요.',
    login_header_in_association_with: '협력',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: '숨긴 이메일',
    login_web_institution_header: '협력',
    login_invited_with_email_text:
      '본 이메일 주소로 초대를 받았습니다. 이메일 주소는 추후 변경할 수 있습니다.',
    signup_success_activation_code_text:
      '계정이 성공적으로 생성되었습니다. Manage My Pain에 로그인하실 수 있습니다.',
    reset_password_top_new_label:
      '암호를 잊으셨나요?\n걱정하지 마세요. 재설정하도록 도와드리겠습니다',
    reset_password_bottom_new_label:
      '계정에 연결된 전자 메일 주소를 입력하십시오. 추가 지침과 함께 전자 메일이 전송됩니다.',
    consent_screen_header:
      '귀하의 데이터를 와(과) 공유하려면 동의가 필요합니다',
    consent_screen_dialog_header: '필수 동의서',
    consent_screen_primary_id_regexp_error:
      '올바른 형식으로 {0}을(를) 입력하십시오.',
    consent_screen_empty_field_error: '이 항목은 비워 둘 수 없습니다.',
    consent_screen_primary_id_label: '전체 이름을 입력하십시오.',
    consent_screen_birth_date_label: '생년월일',
    consent_screen_decline_button: '허가하지 않습니다',
    consent_screen_accept_button: '허가합니다',
    powered_by: '지원',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: '은 사용자가 스스로 통제하게 합니다',
    landing_blue_card_sub_header:
      '만성적인 고통으로 인해 당신이 사랑하는 일을 하지 못하게 하지 마세요.',
    landing_blue_card_string1: '통증 및 활동을 추적합니다',
    landing_blue_card_string2: '당신의 통증을 분석하세요',
    landing_blue_card_string3: '아픔을 함께 나누세요',
    landing_blue_card_string4: '통증 전문가로부터 배우세요',
    landing_blue_card_background: '#0049AC',
    landing_card1_header: '통증 및 활동을 추적합니다',
    landing_card1_content: '하루를 시작하는 데 필요한 시간은 45초뿐입니다',
    landing_card2_header: '당신의 통증을 분석하세요',
    landing_card2_content:
      '그래프와 차트를 통해 통증에 영향을 미치는 요소를 쉽게 파악할 수 있습니다',
    landing_card3_header: '아픔을 함께 나누세요',
    landing_card3_content:
      '의사들이 자신들을 위해 만든 우리의 보고서로 당신의 이야기를 적으세요.',
    landing_footer_string1: '이동 중에도 Manage My Pain을 사용하세요!',
    landing_footer_string2:
      'Manage My Pain은 안드로이드 및 iOS에도 사용할 수 있습니다.',
    landing_promo_card_header: '어떤 기분인지 기록하세요',
    landing_promo_card_sub_header:
      '자신의 하루를 되돌아보고 45초 이내에 자신의 통증을 쉽게 설명하세요.',
    landing_promo_card_item1_primary: '쉽고 빠릅니다',
    landing_promo_card_item1_secondary:
      '간단한 슬라이더와 확인란으로 간편하게 녹화할 수 있습니다',
    landing_promo_card_item2_primary: '맞춤형',
    landing_promo_card_item2_secondary:
      '모든 섹션이 미리 채워져 있지만 특정 요구에 맞게 맞춤 설정할 수 있습니다.',
    landing_promo_card_item3_primary: '하루에 한 번',
    landing_promo_card_item3_secondary:
      '하루 동안 어떻게 지냈는지, 통증은 어땠는지 상기시켜줄 것입니다.',
    landing_in_association_with: '협력',
    landing_scroll_to: '스크롤하여 더 배우세요',
    landing_register_now: '지금 등록하세요',
    landing_logo_header_text: '통증에 대한 추적, 통계 및 공유.',

    intro_about_app_point_1_title: '통증 및 활동을 추적합니다',
    intro_about_app_point_1_subtitle:
      '60초만에 하루를 돌아보고 통증 패턴과 경향을 파악하세요',
    intro_about_app_point_2_title: '당신의 통증을 분석하세요',
    intro_about_app_point_2_subtitle:
      '그래프와 차트로 통증을 완화 또는 악화하는 요인을 쉽게 확인할 수 있습니다',
    intro_about_app_point_3_title: '아픔을 함께 나누세요',
    intro_about_app_point_3_subtitle:
      '의사들이 자신들을 위해 만든 우리의 보고서로 당신의 이야기를 적으세요',
    intro_about_app_point_3_button: '샘플 보고서 다운로드하기',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-ko.pdf',
    intro_about_app_point_4_title: '통증 전문가로부터 배우세요',
    intro_about_app_point_4_subtitle:
      '통증이 발현하는 방식과 그 관리법에 관한 콘텐츠를 확인하세요',
    intro_about_app_point_4_subtitle_for_subscribers: '(구독자 전용)',

    activate_account_activating: '계정 활성화 중...',
    activate_account_success_msg:
      '축하합니다 - 계정이 성공적으로 활성화 되었습니다. 앱을 다운로드하고 로그인하세요.',
    activate_account_error_msg:
      '계정 활성화 문제 - 로그인하거나 지원팀에 문의하여 새 활성화 링크를 요청하세요.',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'ko',
    ),
    activate_account_contact_support: shared('contact_support', 'ko'),
  },

  ru: {
    eula_updated_form_title_text:
      'Обновление Лицензионного соглашения с конечным пользователем',
    eula_updated_message_1_text:
      'Мы обязаны сообщать вам в случае изменения условий обслуживания. Что изменилось:',
    eula_updated_message_2_text:
      '• мы переработали текст условий, чтобы их было легче читать и понимать;',
    eula_updated_message_3_text:
      '• мы подтверждаем свои обязательства в отношении защиты и использования ваших персональных данных в соответствии с нашей {0}',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'ru'),

    eula_updated_message_4_text:
      '• мы гарантируем, что ни в коем случае не станем раскрывать ваши персональные данные без явного согласия;',
    eula_updated_link_message:
      'Регистрируясь, вы соглашаетесь выполнять наше \n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'ru'),
    eula_updated_continue_button: 'Хорошо, продолжить',

    eula_updated_cancel_dialog_title: 'Вы уверены?',
    eula_updated_cancel_dialog_text:
      'Вы должны принять обновлённое лицензионное соглашение с конечным пользователем, чтобы и дальше пользоваться Manage My Pain. Уверены, что хотите выйти?',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'ru'),
    eula_updated_cancel_dialog_no_button: shared('No', 'ru'),

    data_security_title: 'Всё в ваших руках.',
    data_security_registering_with_provider:
      'Вы регистрируетесь через адрес {1} от {0}',
    data_security_privacy_policy_message:
      'Мы используем информацию, которую вы укажете в Manage My Pain, в соответствии с нашей {0}. Ваши данные хранятся и передаются в безопасном режиме с использованием шифрования AES-256.',
    data_security_privacy_policy_link: shared('Privacy Policy', 'ru'),
    data_security_consent_web:
      'Я даю своё согласие на хранение и обработку компанией ManagingLife информации, указанной мной в приложении Manage My Pain, в объёме, необходимом для работы веб-части приложения. Я могу отозвать данное согласие в любой момент путём удаления своего аккаунта.',

    data_security_consent_native:
      'Я даю своё согласие на хранение и обработку компанией ManagingLife информации, указанной мной в приложении Manage My Pain, в объёме, необходимом для функциональности аккаунта. Я могу отозвать данное согласие в любой момент путём удаления своего аккаунта.',
    data_security_consent_not_provided_toast:
      'Для продолжения требуется ваше согласие. ManagingLife не может обеспечить работу приложения без хранения и обработки информации, которую вы вводите в Manage My Pain.',
    data_security_submit_button: 'Отправить и продолжить',
    data_security_web_eula_text:
      'Регистрируясь, вы соглашаетесь выполнять наше {0}',
    data_security_web_eula_link: shared('End User License Agreement', 'ru'),

    welcome_container_no_email_text:
      'Вы регистрируетесь с помощью {0}. Для продолжения укажите действующий email.',

    signup_success_form_title: 'Регистрация прошла успешно',
    signup_success_form_text:
      'Прежде чем войти в Manage My Pain, необходимо активировать аккаунт. Нажмите ссылку для активации, высланную на ваш email. Не можете найти? Проверьте папку СПАМ или запросите новое письмо.',
    signup_success_form_login_button: shared('Log In', 'ru'),
    signup_success_form_send_link_again_button: 'Отправить ссылку повторно',

    expired_link_message:
      'Срок действия ссылки истёк. Попробуйте создать новую ссылку в приложении или обратитесь в службу поддержки',
    expired_link_open_app_button: 'Открыть приложение',
    expired_link_contact_support_button: shared('contact_support', 'ru'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'ru',
    ),

    unsubscribe_message:
      'Уверены, что хотите отменить подписку на рассылку ManagingLife для адреса {0}? Изменения вступят в силу не ранее чем через 24 часа.',
    unsubscribe_yes_button: 'Да, отменить подписку',
    unsubscribe_no_button: 'Нет, сохранить подписку',
    unsubscribe_invalid_token:
      'Срок действия ссылки истёк или она недействительна, обратитесь в службу поддержки',

    unsubscribe_try_again_button: 'Повторить попытку',
    unsubscribe_contact_support_button: shared('contact_support', 'ru'),
    unsubscribe_loading: shared('Loading...', 'ru'),
    unsubscribe_activating: 'Отмена подписки...',
    unsubscribe_success_msg: 'Вы успешно отписались',
    unsubscribe_error_msg:
      'Проблема с отменой подписки пользователя. Пожалуйста, повторите попытку или обратитесь в службу поддержки.',

    password_checker_score_0: ' ',
    password_checker_score_1: 'Слабый',
    password_checker_score_2: 'Средний',
    password_checker_score_3: 'Хороший',
    password_checker_score_4: 'Отличный',
    password_checker_at_least_8_chars: 'Должен содержать не менее 8 символов',
    password_checker_warning_straightRow:
      'Ряд соседних клавиш на клавиатуре легко угадать.',
    password_checker_warning_keyPattern:
      'Короткие комбинации клавиш легко угадать.',
    password_checker_warning_simpleRepeat:
      'Серии повторяющихся символов, например, «ааа», легко угадать.',
    password_checker_warning_extendedRepeat:
      'Повторяющиеся комбинации символов, например, «abcabcabc», легко угадать.',
    password_checker_warning_sequences:
      'Распространённые комбинации символов, например, «abc», легко угадать.',
    password_checker_warning_recentYears: 'Недавние годы легко угадать.',
    password_checker_warning_dates: 'Даты легко угадать.',
    password_checker_warning_topTen: 'Этот пароль используется очень часто.',
    password_checker_warning_topHundred: 'Это часто используемый пароль.',
    password_checker_warning_common: 'Это довольно распространённый пароль.',
    password_checker_warning_similarToCommon:
      'Этот пароль похож на часто используемый.',
    password_checker_warning_wordByItself: 'Одиночные слова легко угадать.',
    password_checker_warning_namesByThemselves:
      'Отдельные имена или фамилии легко угадать.',
    password_checker_warning_commonNames:
      'Распространённые имена и фамилии легко угадать.',
    password_checker_warning_userInputs:
      'Нельзя указывать личные данные или информацию, связанную с вашей страницей.',
    password_checker_strength_label: 'Надёжность: {0}',

    reset_form_password_screen_header: 'Изменить пароль',
    reset_form_password_top_label: 'Изменить пароль',
    reset_form_password_new_password_label: 'Новый пароль',
    reset_form_password_new_password_helper:
      'Должен содержать не менее 6 символов',
    reset_form_password_new_password_invalid_error: 'Неправильный адрес',
    reset_form_password_reset_password_button: 'Изменить пароль',
    reset_form_password_loading_text: 'Запрос сброса пароля...',
    reset_form_password_success_text: shared('reset_password_success', 'ru'),
    reset_form_password_ok_button_text: shared('OK', 'ru'),
    reset_form_password_top_new_label: 'Изменить пароль',
    reset_form_password_bottom_new_label:
      'Выберите новый пароль для аккаунта, связанного с адресом {0}',

    reset_password_activating: 'Изменение пароля...',
    reset_password_success_msg:
      'Поздравляем, пароль вашего аккаунта успешно изменён.',
    reset_password_error_msg:
      'Возникла проблема при сбросе пароля. Пожалуйста, запросите новый пароль или обратитесь в службу поддержки',
    reset_password_login_button: shared('Log In', 'ru'),
    reset_password_request_new_link: 'Повторить попытку',
    reset_password_contact_support: shared('contact_support', 'ru'),
    reset_password_invalid_token:
      'Срок действия ссылки истёк или она недействительна - пожалуйста, запросите новую ссылку, войдя в аккаунт или обратившись в службу поддержки',

    verify_email_activating: 'Подтверждение адреса email...',
    verify_email_success_msg:
      'Поздравляем, адрес email вашего аккаунта успешно изменён.',
    verify_email_error_msg:
      'Возникла проблема при подтверждении адреса email. Пожалуйста, запросите новую ссылку, войдя в аккаунт или обратившись в службу поддержки',
    verify_email_request_new_link: shared('login_request_new_link', 'ru'),
    verify_email_contact_support: shared('contact_support', 'ru'),

    // Facebook Button
    facebook_button_email_differs:
      'Вы пытаетесь войти в систему при помощи другого аккаунта. Пожалуйста, попробуйте еще раз, используя свою учетную запись',
    facebook_button_continue_text: 'Продолжить с Facebook',
    facebook_button_connect_text: 'Связать с Facebook',
    facebook_button_disconnect_text: 'Отвязать от Facebook',
    facebook_button_logging_in_loading_overlay:
      'Вход через аккаунт Facebook...',
    facebook_button_connecting_loading_overlay: 'Подключение к Facebook...',
    facebook_button_disconnecting_loading_overlay: 'Отключение от Facebook...',
    facebook_button_disconnecting_without_password_popup_header:
      'Отключиться без пароля?',
    facebook_button_disconnecting_without_password_popup_text:
      'Вы не сможете войти в аккаунт, пока не установите пароль.',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'ru'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ru',
    ),

    // Google Button
    google_button_email_differs:
      'Вы пытаетесь войти в систему при помощи другого аккаунта. Пожалуйста, попробуйте еще раз, используя свою учетную запись',
    google_button_continue_text: 'Войти через Google',
    google_button_connect_text: 'Связать с Google',
    google_button_disconnect_text: 'Отвязать от Google',
    google_button_logging_in_loading_overlay: 'Вход через Google...',
    google_button_connecting_loading_overlay: 'Подключение к Google...',
    google_button_disconnecting_loading_overlay: 'Отключение от Google...',
    google_button_disconnecting_without_password_popup_header:
      'Отключиться без пароля?',
    google_button_disconnecting_without_password_popup_text:
      'Вы не сможете войти в аккаунт, пока не установите пароль.',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'ru'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ru',
    ),

    // Apple Button
    apple_button_email_differs:
      'Вы пытаетесь войти в систему при помощи другого аккаунта. Пожалуйста, попробуйте еще раз, используя свою учетную запись',
    apple_button_continue_text: 'Продолжить с Apple',
    apple_button_connect_text: 'Связать с Apple',
    apple_button_disconnect_text: 'Отвязать от Apple',
    apple_button_logging_in_loading_overlay: 'Вход через аккаунт Apple...',
    apple_button_connecting_loading_overlay: 'Подключение к Apple...',
    apple_button_disconnecting_loading_overlay: 'Отключение от Apple...',
    apple_button_disconnecting_without_password_popup_header:
      'Отключиться без пароля?',
    apple_button_disconnecting_without_password_popup_text:
      'Вы не сможете войти в аккаунт, пока не установите пароль.',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'ru',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'ru',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: 'Приветствуем, {0}',
    facebook_welcome_email_header: 'Вы подключены к Facebook при помощи:',
    facebook_welcome_email_login_header:
      'Вы входите при помощи аккаунта Facebook в',
    facebook_welcome_email_signup_header:
      'Вы регистрируетесь при помощи аккаунта Facebook, используя',
    facebook_welcome_policy_start_label: 'Я принимаю и соглашаюсь с {0}',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'ru'),
    facebook_welcome_continue_button: shared('Continue', 'ru'),
    facebook_welcome_loading_text: 'Вход...',
    facebook_welcome_policy_not_accepted_error:
      'Для продолжения необходимо принять Политику конфиденциальности',
    facebook_welcome_email_label: shared('Email address', 'ru'),
    facebook_welcome_email_helper: 'Для продолжения требуется адрес email',
    facebook_signup_success_text:
      'Аккаунт создан — нажмите на ссылку для активации, которая придет вам на почту, затем войдите в систему (на всякий случай загляните в папку СПАМ)',
    facebook_signup_ok_button_text: shared('OK', 'ru'),

    // Apple Welcome screen
    apple_welcome_welcome_label: 'Приветствуем, {0}',
    apple_welcome_email_header: 'Вы подключены к Apple при помощи:',
    apple_welcome_email_login_header: 'Вы входите при помощи аккаунта Apple в',
    apple_welcome_email_signup_header:
      'Вы регистрируетесь при помощи аккаунта Apple, используя',
    apple_welcome_policy_start_label: 'Я принимаю и соглашаюсь с {0}',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'ru'),
    apple_welcome_continue_button: shared('Continue', 'ru'),
    apple_welcome_loading_text: 'Вход...',
    apple_welcome_policy_not_accepted_error:
      'Для продолжения необходимо принять Политику конфиденциальности',
    apple_welcome_email_label: shared('Email address', 'ru'),
    apple_welcome_email_helper: 'Для продолжения требуется адрес email',
    apple_signup_success_text:
      'Аккаунт создан — нажмите на ссылку для активации, которая придет вам на почту, затем войдите в систему (на всякий случай загляните в папку СПАМ)',
    apple_signup_ok_button_text: shared('OK', 'ru'),

    // Google Welcome screen
    google_welcome_welcome_label: 'Приветствуем, {0}',
    google_welcome_email_header: 'Вы подключены к Google при помощи:',
    google_welcome_email_login_header:
      'Вы входите при помощи аккаунта Google в',
    google_welcome_email_signup_header:
      'Вы регистрируетесь при помощи аккаунта Google, используя',
    google_welcome_policy_start_label: 'Я принимаю и соглашаюсь с {0}',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'ru'),
    google_welcome_continue_button: shared('Continue', 'ru'),
    google_welcome_loading_text: 'Вход...',
    google_welcome_policy_not_accepted_error:
      'Для продолжения необходимо принять Политику конфиденциальности',
    google_welcome_error_189:
      'Прошло слишком много времени, зарегистрируйтесь повторно',

    // Log In screen
    login_screen_header: shared('Log In', 'ru'),
    login_below_social_buttons: 'или войдите при помощи адреса email',
    login_email_label: shared('Email address', 'ru'),
    login_email_helper: null,
    login_password_label: shared('Password', 'ru'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'ru'),
    login_sign_up_header: shared('Not registered yet', 'ru') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'ru'),
    login_reset_password_button: shared('Reset Password', 'ru'),
    login_loading_text: 'Вход...',
    login_logged_out_warning_text:
      'В целях безопасности ваш сеанс был завершен. Для продолжения войдите в систему повторно',
    login_force_logout_header:
      'или выйдите из системы. Все несохранённые изменения будут отменены',
    login_force_logout_button: shared('Log Out', 'ru'),
    login_force_logout_warning:
      'У вас есть несинхронизированные записи, которые будут потеряны. Хотите продолжить?',
    login_force_logout_warning_log_out: shared('Log Out', 'ru'),
    login_force_logout_warning_cancel: shared('Cancel', 'ru'),

    // Log In error handling (Activation)
    activation_required_title: 'Требуется активация',
    activation_required_message:
      'Нажмите ссылку для активации, высланную вам на почту. Не можете найти? Проверьте папку СПАМ или запросите новое письмо.',
    activation_resend_success:
      'Ссылка отправлена — нажмите на ссылку для активации, которая придет вам на почту, затем войдите в систему',
    activation_ok_button_text: shared('OK', 'ru'),
    activation_resend_button_text: 'Запросить новую ссылку',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'ru'),
    password_needs_to_be_set_message:
      'Попробуйте войти при помощи Facebook или Google или задайте пароль.',
    password_needs_to_be_set_ios_message:
      'Попробуйте войти при помощи Apple, Facebook или Google или задайте пароль.',
    password_needs_to_be_set_set_password_button: shared('Set Password', 'ru'),
    password_needs_to_be_set_login_with_provider_button: shared('Log In', 'ru'),
    password_needs_to_be_set_success_message:
      'Можно задать пароль — проверьте свою почту',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: 'Вход невозможен',
    password_needs_to_be_set_not_activated_message:
      'Аккаунт должен быть активирован, а пароль задан. Попробуйте войти через аккаунт Facebook.',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      'Продолжить с Facebook',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'ru',
    ),

    // Register screen
    signup_screen_header: 'Регистрация',
    signup_below_social_buttons:
      'или зарегистрируйтесь при помощи адреса эл. почты',
    signup_email_label: shared('Email address', 'ru'),
    signup_email_helper: null,
    signup_email_invalid_error: 'Неправильный адрес email',
    signup_password_label: shared('Password', 'ru'),
    signup_password_helper: 'Должен содержать не менее 6 символов',
    signup_password_too_short_error:
      'Слишком короткий — должен содержать не менее 6 символов',
    signup_login_button_header: shared('already_registered', 'ru'),
    signup_login_button: shared('Log In', 'ru'),
    signup_policy_start_label: 'Я принимаю и соглашаюсь с {0}',
    signup_policy_clickable_label: shared('Privacy Policy', 'ru'),
    signup_policy_not_accepted_error:
      'Для продолжения необходимо принять Политику конфиденциальности',
    signup_signup_button: 'Регистрация',
    signup_loading_text: 'Регистрация...',
    signup_email_exists_error_start:
      'Адрес email уже используется. Для продолжения войдите или сбросьте пароль.',
    signup_email_exists_error_login: shared('Log In', 'ru'),
    signup_email_exists_error_recover: shared('Reset Password', 'ru'),
    signup_email_exists_error_alert_title: 'Данный Email адрес не верен',
    signup_email_exists_error_alert_message:
      'Адрес email уже используется. Для продолжения войдите или сбросьте пароль.',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'ru'),
    signup_email_exists_error_alert_login: shared('Log In', 'ru'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'ru'),
    signup_success_text:
      'Аккаунт создан — нажмите на ссылку для активации, которая придет вам на почту, затем войдите в систему (на всякий случай загляните в папку СПАМ)',
    signup_ok_button_text: shared('OK', 'ru'),
    signup_account_needs_to_be_activated_toast:
      'Требуется активация аккаунта — проверьте свой почтовый ящик',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'ru'),
    reset_password_top_label: 'Введите адрес, указанный при регистрации',
    reset_password_email_label: shared('Email address', 'ru'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: 'Неправильный адрес',
    reset_password_reset_password_button: 'Запросить сброс пароля',
    reset_password_bottom_label: null,
    reset_password_loading_text: 'Запрос сброса пароля...',
    reset_password_success_text: shared('reset_password_success', 'ru'),
    reset_password_ok_button_text: shared('OK', 'ru'),

    // Change password screen
    change_password_screen_header: 'Изменить пароль',
    change_password_old_password_label: 'Прежний пароль',
    change_password_old_password_helper: null,
    change_password_new_password_label: 'Новый пароль',
    change_password_new_password_helper: 'Должен содержать не менее 6 символов',
    change_password_new_password_too_short_error:
      'Слишком короткий — должен содержать не менее 6 символов',
    change_password_button_text: 'Изменить пароль',
    change_password_loading_text: 'Изменение пароля...',
    change_password_success_text: 'Пароль успешно изменен',
    change_password_ok_button_text: shared('OK', 'ru'),

    // Change email screen
    change_email_screen_header: 'Изменить адрес email',
    change_email_old_email_label: 'Старый адрес email',
    change_email_old_email_helper: null,
    change_email_new_email_label: 'Новый адрес email',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: 'Неправильный адрес email',
    change_email_password_label: shared('Password', 'ru'),
    change_email_password_helper: null,
    change_email_button_text: 'Изменить адрес email',
    change_email_loading_text: 'Изменение адреса email',
    change_email_success_text:
      'Запрос на изменение адреса успешно отправлен, проверьте новую почту (на всякий случай загляните в папку СПАМ)',
    change_email_ok_button_text: shared('OK', 'ru'),

    // Logout message
    logout_logging_out_message_for_overlay: 'Выход...',
    logout_sync_failed_title: 'Ошибка синхронизации при завершении сеанса',
    logout_sync_failed_message:
      'У вас есть несинхронизированные данные, которые будут утрачены, если завершить сеанс',
    logout_sync_failed_force_logout_button: 'Завершить сеанс с потерей данных',
    logout_sync_failed_try_again_button: 'Повторить синхронизацию',
    logout_sync_failed_cancel_button: shared('Cancel', 'ru'),

    // Landing page
    download_app_message:
      'Аккаунт успешно создан. Вы можете войти в Manage My Pain.',
    join_institution_error:
      'Невозможно присоединиться к исследованию из-за ошибки: ',
    login_success_consent_provided:
      'Согласие успешно предоставлено - вы можете продолжать использовать Manage My Pain',
    login_timeout_top_label:
      'Приветствуем, {0}\nВаш сеанс был завершен из соображений безопасности. Войдите, чтобы продолжить работу.',
    login_header_in_association_with: 'в партнерстве с',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: 'Скрытый email',
    login_web_institution_header: 'в партнерстве с',
    login_invited_with_email_text:
      'Приглашение получено на этот адрес. Позже вы можете сменить его.',
    signup_success_activation_code_text:
      'Аккаунт успешно создан. Вы можете войти в Manage My Pain.',
    reset_password_top_new_label:
      'Забыли пароль?\nНе переживайте, мы поможем вам сбросить его',
    reset_password_bottom_new_label:
      'Введите адрес эл. почты, связанный с вашим аккаунтом — на него будет выслано письмо с инструкциями.',
    consent_screen_header: 'Чтобы поделиться данными, необходимо ваше согласие',
    consent_screen_dialog_header: 'Необходимо ваше согласие',
    consent_screen_primary_id_regexp_error:
      'Пожалуйста, введите {0} в правильном формате',
    consent_screen_empty_field_error: 'Это поле не может быть пустым',
    consent_screen_primary_id_label: 'Введите полное имя',
    consent_screen_birth_date_label: 'Дата рождения',
    consent_screen_decline_button: 'Отказать',
    consent_screen_accept_button: 'Принять',
    powered_by: 'на платформе',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: 'поможет вернуть контроль',
    landing_blue_card_sub_header:
      'Не позволяйте хронической боли мешать вам жить и заниматься любимыми делами.',
    landing_blue_card_string1: 'Отслеживайте свою боль и активность',
    landing_blue_card_string2: 'Анализируйте болевые ощущения',
    landing_blue_card_string3: 'Делитесь данными о вашей боли',
    landing_blue_card_string4: 'Перенимайте опыт у экспертов по вопросам боли',
    landing_blue_card_background: '#0049AC',
    landing_card1_header: 'Отслеживайте свою боль и активность',
    landing_card1_content:
      'Опишите свой день за 45 секунд — этого хватит для начала',
    landing_card2_header: 'Анализируйте болевые ощущения',
    landing_card2_content:
      'Графики и диаграммы помогут понять, от чего зависит ваша боль',
    landing_card3_header: 'Делитесь данными о вашей боли',
    landing_card3_content:
      'Наши отчеты, созданные медиками для медиков, помогут вам рассказать свою историю',
    landing_footer_string1: 'Пользуйтесь Manage My Pain, где захотите!',
    landing_footer_string2:
      'Приложение Manage My Pain доступно для Android и iOS.',
    landing_promo_card_header: 'Записывайте ваше самочувствие',
    landing_promo_card_sub_header:
      'Вспомните свой день и опишите боль менее чем за 45 секунд',
    landing_promo_card_item1_primary: 'Быстро и легко',
    landing_promo_card_item1_secondary:
      'Благодаря ползункам и флажкам, добавлять записи проще простого',
    landing_promo_card_item2_primary: 'Настройте под себя',
    landing_promo_card_item2_secondary:
      'Мы уже заполнили все разделы, но вы можете персонализировать их в соответствии со своими потребностями',
    landing_promo_card_item3_primary: 'Всего раз в день',
    landing_promo_card_item3_secondary:
      'Напоминание спросит вас, как прошел день и попросит описать вашу боль',
    landing_in_association_with: 'В партнерстве с',
    landing_scroll_to: 'Листайте далее, чтобы узнать больше',
    landing_register_now: 'Зарегистрируйтесь сейчас',
    landing_logo_header_text:
      'Следите, анализируйте и делитесь данными о ваших болях',

    intro_about_app_point_1_title: 'Отслеживайте свою боль и активность',
    intro_about_app_point_1_subtitle:
      'Опишите свой день менее чем за минуту, чтобы увидеть закономерности и тенденции',
    intro_about_app_point_2_title: 'Анализируйте болевые ощущения',
    intro_about_app_point_2_subtitle:
      'Графики и диаграммы помогут понять, что облегчает или усугубляет вашу боль.',
    intro_about_app_point_3_title: 'Делитесь данными о вашей боли',
    intro_about_app_point_3_subtitle:
      'Наши отчеты, созданные медиками для медиков, помогут вам рассказать свою историю',
    intro_about_app_point_3_button: 'Загрузить пример отчета',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-ru.pdf',
    intro_about_app_point_4_title:
      'Перенимайте опыт у экспертов по вопросам боли',
    intro_about_app_point_4_subtitle:
      'Узнайте о причинах боли и стратегиях борьбы с ней',
    intro_about_app_point_4_subtitle_for_subscribers:
      '(только для подписчиков)',

    activate_account_activating: 'Активация аккаунта...',
    activate_account_success_msg:
      'Поздравляем, ваш аккаунт успешно активирован. Загрузите приложение и войдите в аккаунт.',
    activate_account_error_msg:
      'Проблема с активацией аккаунта. Пожалуйста, запросите новую ссылку активации, войдя в аккаунт или обратившись в службу поддержки.',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'ru',
    ),
    activate_account_contact_support: shared('contact_support', 'ru'),
  },

  'zh-rCN': {
    eula_updated_form_title_text: '最终用户许可协议已更新',
    eula_updated_message_1_text:
      '当我们更新这些条约时，我们有责任通知您。本次更新包括：',
    eula_updated_message_2_text:
      '• 我们已经重新撰写了条款，使其更易于阅读和理解',
    eula_updated_message_3_text:
      '• 我们承诺按照我方的{0}，使用和保护您的个人信息',
    eula_updated_message_3_privacy_policy: shared('Privacy Policy', 'zh-rCN'),

    eula_updated_message_4_text:
      '• 我们强调，未经明确同意，我们绝不会披露您的个人信息',
    eula_updated_link_message: '通过注册，即表示您同意\n{0}',
    eula_updated_link_part: shared('End User License Agreement', 'zh-rCN'),
    eula_updated_continue_button: '是，继续',

    eula_updated_cancel_dialog_title: '你确定吗？',
    eula_updated_cancel_dialog_text:
      '您必须接受更新后的最终用户许可协议 (EULA)， 才能继续使用 Manage My Pain。您确定要离开？',
    eula_updated_cancel_dialog_yes_button: shared('Yes', 'zh-rCN'),
    eula_updated_cancel_dialog_no_button: shared('No', 'zh-rCN'),

    data_security_title: '一切由您作主',
    data_security_registering_with_provider: '您正在使用 {1} 在 {0} 注册',
    data_security_privacy_policy_message:
      '我们按照我方的{0} ，使用您选择输入到 Manage My Pain 的信息。我们会始终使用 AES-256 加密技术，安全存储和传输您的数据。',
    data_security_privacy_policy_link: shared('Privacy Policy', 'zh-rCN'),
    data_security_consent_web:
      '我同意 ManagingLife 存储和处理我选择输入到 Manage My Pain 的信息，以提供应用程序的网络功能。我可以随时通过删除帐户来撤销此同意。',

    data_security_consent_native:
      '我同意 ManagingLife 存储和处理我选择输入到 Manage My Pain 的信息，以提供帐户功能。我可以随时通过删除帐户来撤销此同意。',
    data_security_consent_not_provided_toast:
      '您需要同意，才能继续使用本应用程序。如果不存储和处理您选择输入到 Manage My Pain 的信息，ManagingLife 无法提供应用程序的功能。',
    data_security_submit_button: '提交并继续',
    data_security_web_eula_text: '通过注册，即表示您同意{0}',
    data_security_web_eula_link: shared('End User License Agreement', 'zh-rCN'),

    welcome_container_no_email_text:
      '您正在 {0} 注册，我们需要一个有效的电子邮件地址才能继续。',

    signup_success_form_title: '注册成功',
    signup_success_form_text:
      '在您可以登录到 Manage My Pain 之前，您需要激活您的帐户。 系统已将包含激活链接的电子邮件发送到您的邮箱，请单击此链接来激活您的帐户。找不到吗？请查看您的垃圾邮件文件夹或请求一个新的激活链接。',
    signup_success_form_login_button: shared('Log In', 'zh-rCN'),
    signup_success_form_send_link_again_button: '再次发送链接',

    expired_link_message:
      '链接已过期。请尝试从应用或联系支持那里生成新的链接。',
    expired_link_open_app_button: '打开应用',
    expired_link_contact_support_button: shared('contact_support', 'zh-rCN'),
    signup_success_form_send_link_again_loading_message: shared(
      'Loading...',
      'zh-rCN',
    ),

    unsubscribe_message:
      '您确定要取消订阅由 ManagingLife 发送到 {0} 的电子邮件吗？更改可能需要 24 小时才能生效。',
    unsubscribe_yes_button: '是，取消订阅',
    unsubscribe_no_button: '是，取消订阅',
    unsubscribe_invalid_token: '链接已过期或无效 - 请联系支持人员',

    unsubscribe_try_again_button: '链接已过期或无效 - 请联系支持人员',
    unsubscribe_contact_support_button: shared('contact_support', 'zh-rCN'),
    unsubscribe_loading: shared('Loading...', 'zh-rCN'),
    unsubscribe_activating: '正在取消订阅...',
    unsubscribe_success_msg: '您已经成功取消订阅',
    unsubscribe_error_msg: '取消订阅时出现问题 - 请重试或联系支持人员。',

    password_checker_score_0: ' ',
    password_checker_score_1: '差劲',
    password_checker_score_2: '一般',
    password_checker_score_3: '良好',
    password_checker_score_4: '优秀',
    password_checker_at_least_8_chars: '必须至少包含8个字符',
    password_checker_warning_straightRow: '键盘上的直排键很容易被破解。',
    password_checker_warning_keyPattern: '短键盘模式很容易被破解。',
    password_checker_warning_simpleRepeat:
      '像 “aaa” 这样的重复字符很容易被破解。',
    password_checker_warning_extendedRepeat:
      '像 “abcabcabc” 这样的重复字符很容易被破解。',
    password_checker_warning_sequences:
      '像 “abc” 这样的常见字符序列很容易被破解。',
    password_checker_warning_recentYears: '最近年份很容易被破解。',
    password_checker_warning_dates: '日期很容易被破解。',
    password_checker_warning_topTen: '此为频繁使用的密码。',
    password_checker_warning_topHundred: '此为经常使用的密码。',
    password_checker_warning_common: '此为常用密码。',
    password_checker_warning_similarToCommon: '这与常用密码类似。',
    password_checker_warning_wordByItself: '单词很容易被破解。',
    password_checker_warning_namesByThemselves: '单个名字或姓氏很容易被破解。',
    password_checker_warning_commonNames: '常见的姓氏和名字很容易被破解。',
    password_checker_warning_userInputs: '不应包含任何个人或页面相关数据。',
    password_checker_strength_label: '强度：{0}',

    reset_form_password_screen_header: '更改密码',
    reset_form_password_top_label: '更改密码',
    reset_form_password_new_password_label: '新密码',
    reset_form_password_new_password_helper: '必须至少包含6个字符',
    reset_form_password_new_password_invalid_error: '电子邮件地址无效',
    reset_form_password_reset_password_button: '更改密码',
    reset_form_password_loading_text: '请求重置密码...',
    reset_form_password_success_text: shared(
      'reset_password_success',
      'zh-rCN',
    ),
    reset_form_password_ok_button_text: shared('OK', 'zh-rCN'),
    reset_form_password_top_new_label: '更改密码',
    reset_form_password_bottom_new_label: '为与 {0} 关联的帐户选择新密码',

    reset_password_activating: '正在更改密码...',
    reset_password_success_msg: '恭喜 - 您的帐户密码已成功更改。',
    reset_password_error_msg:
      '重置密码时出现问题 - 请申请重置新密码或联系支持人员',
    reset_password_login_button: shared('Log In', 'zh-rCN'),
    reset_password_request_new_link: '再试一次',
    reset_password_contact_support: shared('contact_support', 'zh-rCN'),
    reset_password_invalid_token:
      '链接已过期或无效 - 请登录帐户或联系支持人员来请求新链接',

    verify_email_activating: '正在验证电子邮件地址...',
    verify_email_success_msg: '恭喜 - 您帐户的电子邮件地址已成功更改。',
    verify_email_error_msg:
      '验证电子邮件地址时出现问题 - 请登录帐户或联系支持人员来请求新链接',
    verify_email_request_new_link: shared('login_request_new_link', 'zh-rCN'),
    verify_email_contact_support: shared('contact_support', 'zh-rCN'),

    // Facebook Button
    facebook_button_email_differs:
      '您正在尝试使用其他帐户登录-请使用您的帐户再试一次',
    facebook_button_continue_text: '使用 Facebook 账户继续操作',
    facebook_button_connect_text: '使用 Facebook 帐户登入',
    facebook_button_disconnect_text: '断开与Facebook的关联',
    facebook_button_logging_in_loading_overlay: '使用Facebook账户登录...',
    facebook_button_connecting_loading_overlay: '正在关联 Facebook 账户...',
    facebook_button_disconnecting_loading_overlay:
      '正在断开与 Facebook 的关联...',
    facebook_button_disconnecting_without_password_popup_header:
      '在没有设置密码的情况下断开关联？',
    facebook_button_disconnecting_without_password_popup_text:
      '在设置密码之前，您将无法登录。',
    facebook_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'zh-rCN'),
    facebook_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),

    // Google Button
    google_button_email_differs:
      '您正在尝试使用其他帐户登录-请使用您的帐户再试一次',
    google_button_continue_text: '使用 Google 账户登录',
    google_button_connect_text: '使用 Google 帐户登入',
    google_button_disconnect_text: '断开与 Google 的关联',
    google_button_logging_in_loading_overlay: '使用 Google 账户登录...',
    google_button_connecting_loading_overlay: '正在关联 Google 账户...',
    google_button_disconnecting_loading_overlay: '正在断开与 Google 的关联...',
    google_button_disconnecting_without_password_popup_header:
      '在没有设置密码的情况下断开关联？',
    google_button_disconnecting_without_password_popup_text:
      '在设置密码之前，您将无法登录。',
    google_button_disconnecting_without_password_popup_disconnect_button:
      shared('Disconnect', 'zh-rCN'),
    google_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),

    // Apple Button
    apple_button_email_differs:
      '您正在尝试使用其他帐户登录-请使用您的帐户再试一次',
    apple_button_continue_text: '使用 Apple 账户继续操作',
    apple_button_connect_text: '使用 Apple 帐户登入',
    apple_button_disconnect_text: '断开与 Apple 的关联',
    apple_button_logging_in_loading_overlay: '使用 Apple 账户登录...',
    apple_button_connecting_loading_overlay: '正在关联 Apple 账户...',
    apple_button_disconnecting_loading_overlay: '正在断开与 Apple 的关联...',
    apple_button_disconnecting_without_password_popup_header:
      '在没有设置密码的情况下断开关联？',
    apple_button_disconnecting_without_password_popup_text:
      '在设置密码之前，您将无法登录。',
    apple_button_disconnecting_without_password_popup_disconnect_button: shared(
      'Disconnect',
      'zh-rCN',
    ),
    apple_button_disconnecting_without_password_popup_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),

    // Facebook Welcome screen
    facebook_welcome_welcome_label: '欢迎{0}',
    facebook_welcome_email_header: '您已经关联到 Facebook 账户：',
    facebook_welcome_email_login_header:
      '您正在使用 Facebook 登录，如要继续，请登录与该帐户关联的邮箱',
    facebook_welcome_email_signup_header:
      '您正在使用 Facebook 进行注册，请登录与该帐户关联的邮箱',
    facebook_welcome_policy_start_label: '我同意接受並知悉相关{0}',
    facebook_welcome_policy_clickable_label: shared('Privacy Policy', 'zh-rCN'),
    facebook_welcome_continue_button: shared('Continue', 'zh-rCN'),
    facebook_welcome_loading_text: '正在登录...',
    facebook_welcome_policy_not_accepted_error: '您必须接受隐私政策才能继续',
    facebook_welcome_email_label: shared('Email address', 'zh-rCN'),
    facebook_welcome_email_helper: '需要电子邮件地址才能继续',
    facebook_signup_success_text:
      '创建帐户 — 点击发送到你的电子邮件的激活链接，然后登录 (请同时检查垃圾邮件/垃圾文件夹)',
    facebook_signup_ok_button_text: shared('OK', 'zh-rCN'),

    // Apple Welcome screen
    apple_welcome_welcome_label: '欢迎{0}',
    apple_welcome_email_header: '您已经关联到 Apple 账户：',
    apple_welcome_email_login_header:
      '您正在使用 Apple 登录，如要继续，请登录与该帐户关联的邮箱',
    apple_welcome_email_signup_header:
      '您正在使用 Apple 进行注册，请登录与该帐户关联的邮箱',
    apple_welcome_policy_start_label: '我同意接受並知悉相关{0}',
    apple_welcome_policy_clickable_label: shared('Privacy Policy', 'zh-rCN'),
    apple_welcome_continue_button: shared('Continue', 'zh-rCN'),
    apple_welcome_loading_text: '正在登录...',
    apple_welcome_policy_not_accepted_error: '您必须接受隐私政策才能继续',
    apple_welcome_email_label: shared('Email address', 'zh-rCN'),
    apple_welcome_email_helper: '需要电子邮件地址才能继续',
    apple_signup_success_text:
      '创建帐户 — 点击发送到你的电子邮件的激活链接，然后登录 (请同时检查垃圾邮件/垃圾文件夹)',
    apple_signup_ok_button_text: shared('OK', 'zh-rCN'),

    // Google Welcome screen
    google_welcome_welcome_label: '欢迎{0}',
    google_welcome_email_header: '您已经关联到 Google 账户：',
    google_welcome_email_login_header:
      '您正在使用 Google 登录，如要继续，请登录与该帐户关联的邮箱',
    google_welcome_email_signup_header:
      '您正在使用 Google 进行注册，请登录与该帐户关联的邮箱',
    google_welcome_policy_start_label: '我同意接受並知悉相关{0}',
    google_welcome_policy_clickable_label: shared('Privacy Policy', 'zh-rCN'),
    google_welcome_continue_button: shared('Continue', 'zh-rCN'),
    google_welcome_loading_text: '正在登录...',
    google_welcome_policy_not_accepted_error: '您必须接受隐私政策才能继续',
    google_welcome_error_189: '时间过长 - 请重新注册',

    // Log In screen
    login_screen_header: shared('Log In', 'zh-rCN'),
    login_below_social_buttons: '或使用您的电子邮件地址登录',
    login_email_label: shared('Email address', 'zh-rCN'),
    login_email_helper: null,
    login_password_label: shared('Password', 'zh-rCN'),
    login_password_helper: null,
    login_login_button: shared('Log In', 'zh-rCN'),
    login_sign_up_header: shared('Not registered yet', 'zh-rCN') + ' {0}',
    login_sign_up_button: shared('Register For Free', 'zh-rCN'),
    login_reset_password_button: shared('Reset Password', 'zh-rCN'),
    login_loading_text: '正在登录...',
    login_logged_out_warning_text: '为了安全起见，您已经退出-请再次登录以继续',
    login_force_logout_header: '或继续登出并放弃未保存的更改',
    login_force_logout_button: shared('Log Out', 'zh-rCN'),
    login_force_logout_warning:
      '您有未同步的记录，这些记录将丢失。 您要继续吗？',
    login_force_logout_warning_log_out: shared('Log Out', 'zh-rCN'),
    login_force_logout_warning_cancel: shared('Cancel', 'zh-rCN'),

    // Log In error handling (Activation)
    activation_required_title: '需要激活',
    activation_required_message:
      '请点击发送给您的电子邮件中的激活链接。找不到？请检查您的垃圾邮件文件夹或请求重新发送邮件。',
    activation_resend_success:
      '已经发送了链接 — 点击发送到你的电子邮件的激活链接，然后登录',
    activation_ok_button_text: shared('OK', 'zh-rCN'),
    activation_resend_button_text: '请求新链接',

    // Log In error handling (Password not set)
    password_needs_to_be_set_title: shared('Password Not Set', 'zh-rCN'),
    password_needs_to_be_set_message:
      '请尝试使用 Facebook 或 Google 登录，或设置密码。',
    password_needs_to_be_set_ios_message:
      '请尝试使用 Apple, Facebook 或 Google 登录，或设置密码。',
    password_needs_to_be_set_set_password_button: shared(
      'Set Password',
      'zh-rCN',
    ),
    password_needs_to_be_set_login_with_provider_button: shared(
      'Log In',
      'zh-rCN',
    ),
    password_needs_to_be_set_success_message:
      '现在可以设置密码 - 请查收电子邮件',

    // Log In error handling (Password not set and Account not activated)
    password_needs_to_be_set_not_activated_title: '无法登录',
    password_needs_to_be_set_not_activated_message:
      '必须激活您的账户并设置密码。请尝试使用 Facebook 登录。',
    password_needs_to_be_set_not_activated_login_with_provider_button:
      '使用 Facebook 账户继续操作',
    password_needs_to_be_set_not_activated_cancel_button: shared(
      'Cancel',
      'zh-rCN',
    ),

    // Register screen
    signup_screen_header: '注册',
    signup_below_social_buttons: '或使用您的电子邮件地址注册',
    signup_email_label: shared('Email address', 'zh-rCN'),
    signup_email_helper: null,
    signup_email_invalid_error: '电子邮件地址无效',
    signup_password_label: shared('Password', 'zh-rCN'),
    signup_password_helper: '必须至少包含6个字符',
    signup_password_too_short_error: '太短 - 必须包含至少6个字符',
    signup_login_button_header: shared('already_registered', 'zh-rCN'),
    signup_login_button: shared('Log In', 'zh-rCN'),
    signup_policy_start_label: '我同意接受並知悉相关{0}',
    signup_policy_clickable_label: shared('Privacy Policy', 'zh-rCN'),
    signup_policy_not_accepted_error: '您必须接受隐私政策才能继续',
    signup_signup_button: '注册',
    signup_loading_text: '正在注册...',
    signup_email_exists_error_start:
      '电子邮件地址已被使用。请登录或重置密码以继续。',
    signup_email_exists_error_login: shared('Log In', 'zh-rCN'),
    signup_email_exists_error_recover: shared('Reset Password', 'zh-rCN'),
    signup_email_exists_error_alert_title: '指定的电子邮件地址无效',
    signup_email_exists_error_alert_message:
      '电子邮件地址已被使用。请登录或重置密码以继续。',
    signup_email_exists_error_alert_cancel: shared('Cancel', 'zh-rCN'),
    signup_email_exists_error_alert_login: shared('Log In', 'zh-rCN'),
    signup_email_exists_error_alert_recover: shared('Reset Password', 'zh-rCN'),
    signup_success_text:
      '创建帐户 — 点击发送到你的电子邮件的激活链接，然后登录 (请同时检查垃圾邮件/垃圾文件夹)',
    signup_ok_button_text: shared('OK', 'zh-rCN'),
    signup_account_needs_to_be_activated_toast:
      '账户需要激活 - 请查收电子邮件中的链接',

    // Reset password screen
    reset_password_screen_header: shared('Reset Password', 'zh-rCN'),
    reset_password_top_label: '请输入您的注册电子邮件地址',
    reset_password_email_label: shared('Email address', 'zh-rCN'),
    reset_password_email_helper: null,
    reset_password_email_invalid_error: '电子邮件地址无效',
    reset_password_reset_password_button: '请求密码重置',
    reset_password_bottom_label: null,
    reset_password_loading_text: '正在请求密码重置...',
    reset_password_success_text: shared('reset_password_success', 'zh-rCN'),
    reset_password_ok_button_text: shared('OK', 'zh-rCN'),

    // Change password screen
    change_password_screen_header: '更改密码',
    change_password_old_password_label: '旧密码',
    change_password_old_password_helper: null,
    change_password_new_password_label: '新密码',
    change_password_new_password_helper: '必须至少包含6个字符',
    change_password_new_password_too_short_error: '太短 - 必须包含至少6个字符',
    change_password_button_text: '更改密码',
    change_password_loading_text: '正在更改密码...',
    change_password_success_text: '密码更改成功',
    change_password_ok_button_text: shared('OK', 'zh-rCN'),

    // Change email screen
    change_email_screen_header: '更改电子邮件地址',
    change_email_old_email_label: '旧电子邮件地址',
    change_email_old_email_helper: null,
    change_email_new_email_label: '新电子邮件地址',
    change_email_new_email_helper: null,
    change_email_new_email_invalid_error: '电子邮件地址无效',
    change_email_password_label: shared('Password', 'zh-rCN'),
    change_email_password_helper: null,
    change_email_button_text: '更改电子邮件地址',
    change_email_loading_text: '正在更改电子邮件地址...',
    change_email_success_text:
      '更改电子邮件请求已成功发送，请检查您的新电子邮箱 (请同时检查垃圾邮件/垃圾文件夹)',
    change_email_ok_button_text: shared('OK', 'zh-rCN'),

    // Logout message
    logout_logging_out_message_for_overlay: '正在登出...',
    logout_sync_failed_title: '退出时同步失败',
    logout_sync_failed_message:
      '您有未同步的数据，如果您继续退出，这些数据将会丢失',
    logout_sync_failed_force_logout_button: '退出并丢失未同步的数据',
    logout_sync_failed_try_again_button: '重试同步',
    logout_sync_failed_cancel_button: shared('Cancel', 'zh-rCN'),

    // Landing page
    download_app_message: '您的帐户已成功创建，您可以立即登录 Manage My Pain。',
    join_institution_error: '因为以下错误无法加入该机构：',
    login_success_consent_provided:
      '成功提供了同意书——请继续使用Manage My Pain',
    login_timeout_top_label:
      '您好，{0}\n出于安全原因，您已退出帐户 - 请重新登录以继续操作。',
    login_header_in_association_with: '协同',
    login_header_app_name: 'Manage My Pain',
    apple_button_hidden_email: '隐藏的电邮地址',
    login_web_institution_header: '协同',
    login_invited_with_email_text:
      '您通过这封电子邮件得到了邀请。您可稍后再进行更改。',
    signup_success_activation_code_text:
      '您的帐户已成功创建，您可以立即登录 Manage My Pain。',
    reset_password_top_new_label: '忘记密码？\n别担心，我们会帮您重设',
    reset_password_bottom_new_label:
      '输入与您的帐户关联的电邮地址 - 您将收到一封电子邮件，请细阅当中的进一步指示。',
    consent_screen_header: '共享您的数据需要阁下同意',
    consent_screen_dialog_header: '需要同意书',
    consent_screen_primary_id_regexp_error: '请输入正确格式的{0}',
    consent_screen_empty_field_error: '此字段不能为空',
    consent_screen_primary_id_label: '输入您的全名',
    consent_screen_birth_date_label: '出生日期',
    consent_screen_decline_button: '我不同意',
    consent_screen_accept_button: '我同意',
    powered_by: '技术支持：',
    landing_blue_card_manage_my_pain_header: 'Manage My Pain',
    landing_blue_card_header: '让您掌控一切',
    landing_blue_card_sub_header:
      '不要让慢性疼痛阻碍您的生活，让您无法享受自己喜欢的事情。',
    landing_blue_card_string1: '跟踪您的疼痛病情和活动',
    landing_blue_card_string2: '分析疼痛病情',
    landing_blue_card_string3: '分享疼痛病情',
    landing_blue_card_string4: '向疼痛专家取经',
    landing_blue_card_background: '#0049AC',
    landing_card1_header: '跟踪您的疼痛病情和活动',
    landing_card1_content: '用45秒来回顾您的一天活动',
    landing_card2_header: '分析疼痛病情',
    landing_card2_content: '图表可以轻易找出影响疼痛病情的因素',
    landing_card3_header: '分享疼痛病情',
    landing_card3_content:
      '我们的报告由医生创建，为医生而设，助您概述疼痛病情。',
    landing_footer_string1: '立即使用 Manage My Pain！',
    landing_footer_string2: 'Manage My Pain 应用适用于 Android 和 iOS。',
    landing_promo_card_header: '记录您的感觉',
    landing_promo_card_sub_header:
      '回顾您的一天，并在 45 秒内轻松描述您的疼痛病情',
    landing_promo_card_item1_primary: '简单快捷',
    landing_promo_card_item1_secondary: '简单的滑块和复选框让您轻松进行记录',
    landing_promo_card_item2_primary: '可定制',
    landing_promo_card_item2_secondary:
      '每部分已预先设置，但您可以根据自己的具体需求，进行个性化设置',
    landing_promo_card_item3_primary: '每天一次',
    landing_promo_card_item3_secondary:
      '提醒功能会询问您今天的情况及您的疼痛病情',
    landing_in_association_with: '协同',
    landing_scroll_to: '请向下滚动，即可了解更多',
    landing_register_now: '立即注册',
    landing_logo_header_text: '跟踪、分析并分享您的疼痛',

    intro_about_app_point_1_title: '跟踪您的疼痛病情和活动',
    intro_about_app_point_1_subtitle:
      '用不到60秒时间回顾您的一天，来查看模式及趋势',
    intro_about_app_point_2_title: '分析疼痛病情',
    intro_about_app_point_2_subtitle:
      '图形及图表让您轻松了解哪些因素让您的疼痛减轻或加剧',
    intro_about_app_point_3_title: '分享疼痛病情',
    intro_about_app_point_3_subtitle:
      '我们的报告由医生创建，为医生而设，助您概述疼痛病情',
    intro_about_app_point_3_button: '下载报告样本',
    intro_about_app_point_3_button_link:
      'https://managinglife-documents.s3.ca-central-1.amazonaws.com/Sample-Report-zh-rCN.pdf',
    intro_about_app_point_4_title: '向疼痛专家取经',
    intro_about_app_point_4_subtitle: '探索有关疼痛作用机理和管理策略的内容',
    intro_about_app_point_4_subtitle_for_subscribers: '（仅限订阅者）',

    activate_account_activating: '正在启动帐户…',
    activate_account_success_msg:
      '恭喜！你的帐户已经成功启动。下载应用程序，登录帐户。',
    activate_account_error_msg:
      '启动帐户时遇到问题？请登录帐户或联系技术支持，请求一个新的启动链接。',
    activate_account_login_request_new_link: shared(
      'login_request_new_link',
      'zh-rCN',
    ),
    activate_account_contact_support: shared('contact_support', 'zh-rCN'),
  },
});
