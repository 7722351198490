import LocalizedStrings from 'react-native-localization';
import shared from '../../strings/shared';

export default new LocalizedStrings({
  en: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'en'),
    order_credits_scene_purchasing_overlay: 'Processing purchase...',
    order_credits_scene_pro_header:
      'Most reports do not require credits. Credits are only needed to generate reports that include advanced sections such as charts, timelines, or calendars.',
    order_credits_scene_lite_header:
      'Most reports do not require credits. Credits are only used to generate reports that include records older than 30 days or to include advanced sections such as charts, timelines, or calendars.',
    order_credits_scene_order_now_button: shared('Order Credits', 'en'),
    order_credits_save: 'SAVE {0}%',
    order_credits_title_credits_1: '1 Credit',
    order_credits_title_credits_3: '3 Credits',
    order_credits_title_credits_5: '5 Credits',

    order_credits_card_order_credit_button: 'Order credit',
    order_credits_card_per_credit_label: '{0} per credit',
    order_credits_card_savings_label: '{0}% savings',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: 'Current Plan',
    subscription_plan_current_credits_label: 'Current Credits',
    subscription_plan_section_upgrade_plan_button: 'Change Plan',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'en',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'en'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'PRO SUBSCRIPTION',
    subscription_plan_section_lite_plan: 'LITE',
    subscription_plan_credits_unlimited: 'Unlimited',
    subscription_plan_section_web_upgrade_text:
      'To subscribe, install the iOS or Android app',
    subscription_plan_section_web_order_credits_text:
      'To purchase credits, install the iOS or Android app',
    subscription_plan_current_plan_pain_guide_title: 'Pain Guide',
    subscription_plan_current_plan_pain_guide_sub_title:
      'Helpful information about pain and ways to manage it',

    // Subscription plans Screens
    plans_scene_screen_header: 'Subscription Plans',
    plans_scene_purchasing_overlay: 'Processing purchase...',
    plans_scene_loading_init_inapp: shared('Loading...', 'en'),
    plans_scene_restore_purchases_button: 'Restore purchases',
    plans_scene_restore_purchases_success_toast:
      'Purchases restored successfully',
    plans_scene_itunes_error:
      'Unable to connect to the Apple App Store purchasing service',
    plans_scene_google_play_error:
      'Unable to connect to Google Play purchasing service',

    plans_scene_current_plan_badge: 'Current Plan',
    plans_scene_most_popular_badge: 'Most popular',
    plans_scene_best_value_badge: 'Best value',

    plans_scene_monthly_cost_label: '/ month',
    plans_scene_yearly_cost_label: '/ year',
    plans_scene_billed_yearly_cost_label: 'Billed yearly - {0} / year',

    plans_scene_plans_header: 'Plans',
    plans_scene_one_time_purchases_header: 'One-time purchases',
    plans_scene_subscription_terms_header: 'Subscription Terms',

    plans_scene_free_item_header: 'Free',
    plans_scene_free_item_benefit_1: 'Unlimited records',
    plans_scene_free_item_benefit_2: 'Unlimited basic reports',
    plans_scene_free_item_benefit_3: 'Insights: limited to last 30 days',

    plans_scene_monthly_item_header: 'Monthly',
    plans_scene_monthly_item_benefit_1: 'Unlimited records',
    plans_scene_monthly_item_benefit_2: 'Unlimited advanced reports',
    plans_scene_monthly_item_benefit_3: 'Unlimited insights',
    plans_scene_monthly_item_benefit_4: 'Access to Pain Guide',

    plans_scene_yearly_item_header: 'Yearly',
    plans_scene_yearly_item_benefit_1: 'Unlimited records',
    plans_scene_yearly_item_benefit_2: 'Unlimited advanced reports',
    plans_scene_yearly_item_benefit_3: 'Unlimited insights',
    plans_scene_yearly_item_benefit_4: 'Access to Pain Guide',
    plans_scene_yearly_item_benefit_5: '20% discount',

    plans_scene_pro_purchase_item_header: 'Unlock unlimited insights',
    plans_scene_pro_purchase_item_benefit_1: 'Removes 30-day limit on insights',
    plans_scene_pro_purchase_item_benefit_2:
      '3 report credits for advanced reports',

    plans_scene_credits_item_header: 'Report credits',
    plans_scene_credits_item_benefit_1: 'Removes 30-day limit on insights',
    plans_scene_credits_item_benefit_2: 'Used to generate advanced reports',
    plans_scene_credits_item_price: 'as low as {0}',

    plans_scene_card_purchase_button: 'Purchase',
    plans_scene_card_expired_button: 'Renew',
    plans_scene_card_manage_button: 'Manage',
    plans_scene_card_upgrade_button: 'Upgrade',
    plans_scene_card_renew_button: 'Renew',
    plans_scene_card_downgrade_button: 'Downgrade',
    plans_scene_card_order_credits_button: 'Order Credits',
    plans_scene_card_current_plan: 'Current plan',
    plans_scene_card_expired: 'Expired',
    plans_scene_card_purchased: 'Purchased',
    plans_scene_card_sponsored: 'Sponsored',

    //Subscription plans cards
    card_add_view_records: 'Add and view records',
    card_analytics_and_insights: 'Analytics and insights',
    card_reports: shared('Reports', 'en'),
    card_my_plan: 'Current Plan',
    card_more_details: 'More Details',
    card_manage_subscription: shared('Manage Subscription', 'en'),
    card_institution_pro: 'Your upgrade to Pro was sponsored at no cost to you',
    card_institution_subscription:
      'Your subscription plan is sponsored - you are not being charged.',
    card_subscription_itunes: 'You have subscribed through Apple App Store',
    card_subscription_google: 'You have subscribed through Google Play',
    card_subscription_amazon: 'You have subscribed through Amazon',
    card_subscription_not_managed: 'You have subscribed on another device',
    card_subscription_expired: 'Your subscription is expired.',
    card_pro_other_account:
      'You subscribed for a different Manage My Pain account - all accounts benefit from that subscription on this device.',
    card_subscription_other_account:
      'You subscribed for a different Manage My Pain account - all accounts benefit from that subscription on this device.',
    card_subscription_expired_title: 'Expired',
    card_subscription_resume: 'Resume Subscription',

    card_subscription_rules_button: 'SUBSCRIPTION PRICING AND TERMS',
    card_subscription_rules_apple_text:
      'Manage My Pain offers a single auto-renewing subscription option:\n{0} per month\n\nYour Manage My Pain subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can go to your Apple App Store Account settings to manage your subscription and turn off auto-renew. Your Apple App Store Account will be charged when the purchase is confirmed.\n',
    card_subscription_rules_google_text:
      'Manage My Pain offers a single auto-renewing subscription option:\n{0} per month\n\nYour Manage My Pain subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can go to the Subscription settings in Google Play to manage your subscription and turn off auto-renew. Your Google Play Account will be charged when the purchase is confirmed.\n',
    card_subscription_rules_amazon_text:
      'Manage My Pain offers a single auto-renewing subscription option:\n{0} per month\n\nYour Manage My Pain subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can go to your Amazon Appstore settings to manage your subscription and turn off auto-renew. Your Amazon Appstore Account will be charged when the purchase is confirmed.\n',
    card_subscription_rules_eula_title:
      'You can find our End User License Agreement here:',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      'You can find our Privacy Policy here:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      'Your Manage My Pain subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can go to your Apple App Store Account settings to manage your subscription and turn off auto-renew. Your Apple App Store Account will be charged when the purchase is confirmed.\n',
    card_subscription_rules_google_updated_text:
      'Your Manage My Pain subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end of the current period. You can go to the Subscription settings in Google Play to manage your subscription and turn off auto-renew. Your Google Play Account will be charged when the purchase is confirmed.\n',

    card_institution_plan_includes: 'Your plan includes',
    card_institution_plan_benefit_1: 'Unlimited reports',
    card_institution_plan_benefit_2: 'Unlimited insights',
    card_institution_plan_benefit_3: 'Access to Pain Guide',
    free_plan_title: 'Lite Version',
    free_plan_add_view_records: 'No limits',
    free_plan_analytics: 'Limited to the last 30 days',
    free_plan_reports:
      '1 full report included, then limited to the last 30 days',
    free_plan_price: 'Free',
    free_plan_price_subtitle: '',
    free_plan_description:
      'Additional reports can be bought for as low as {0} each',

    pro_plan_title: 'Pro Version',
    pro_plan_add_view_records: 'No limits',
    pro_plan_analytics: 'No limits',
    pro_plan_reports:
      'No limits for reports with Insights and/or Records sections. 3 credits included to create reports with other sections.',
    pro_plan_price_subtitle: 'one-time',
    pro_plan_description: 'Additional reports can be purchased for {0} each',
    pro_plan_button: shared('Upgrade to Pro', 'en'),

    subscribe_plan_title: 'Pro Subscription',
    subscribe_plan_add_view_records: 'No limits',
    subscribe_plan_analytics: 'No limits',
    subscribe_plan_reports: 'No limits',
    subscribe_plan_price_subtitle: 'per month',
    subscribe_plan_description: 'All reports are free',
    subscription_plan_pain_guide_title: 'Pain Guide',
    subscription_plan_pain_guide_sub_title:
      'Helpful information about pain and ways to manage it',
    subscribe_plan_button: 'Subscribe',
  },
  de: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'de'),
    order_credits_scene_purchasing_overlay: 'Verarbeite Kauf...',
    order_credits_scene_pro_header:
      'Für den Großteil der Berichte wird kein Guthabenpunkte benötigt. Guthabenpunkte wird nur benötigt, um Berichte zu erstellen, die komplexere Inhalte wie Tabellen, Zeitstrahlen oder Kalender enthalten.',
    order_credits_scene_lite_header:
      'Für den Großteil der Berichte wird kein Guthabenpunkte benötigt. Guthabenpunkte wird nur benötigt, um Berichte mit Einträgen, die älter als 30 Tage sind, zu erstellen oder komplexere Inhalte wie Tabellen, Zeitstrahlen oder Kalender in einen Bericht einzubinden.',
    order_credits_scene_order_now_button: shared('Order Credits', 'de'),
    order_credits_save: 'SPAREN SIE {0}%',
    order_credits_title_credits_1: '1 Guthabenpunkt',
    order_credits_title_credits_3: '3 Guthabenpunkte',
    order_credits_title_credits_5: '5 Guthabenpunkte',

    order_credits_card_order_credit_button: 'Guthabenpunkt bestellen',
    order_credits_card_per_credit_label: '{0} pro Guthabenpunkt',
    order_credits_card_savings_label: '{0}% Ersparnis',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: 'Aktuelles Abonnement',
    subscription_plan_current_credits_label: 'Aktuelles Guthabenpunkte',
    subscription_plan_section_upgrade_plan_button: 'Abonnement Ändern',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'de',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'de'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'PRO-ABONNEMENT',
    subscription_plan_section_lite_plan: 'LITE',
    subscription_plan_credits_unlimited: 'Unbegrenzt',
    subscription_plan_section_web_upgrade_text:
      'Zum Abonnieren, installieren Sie die iOS- oder Android-App',
    subscription_plan_section_web_order_credits_text:
      'Um Guthabenpunkte aufzuladen, installieren Sie die iOS- oder Android-App',
    subscription_plan_current_plan_pain_guide_title: 'Schmerz-Leitfaden',
    subscription_plan_current_plan_pain_guide_sub_title:
      'Hilfreiche Informationen über Schmerzen und Möglichkeiten, sie zu bewältigen',

    // Subscription plans Screens
    plans_scene_screen_header: 'Abonnements',
    plans_scene_purchasing_overlay: 'Verarbeite Kauf...',
    plans_scene_loading_init_inapp: shared('Loading...', 'de'),
    plans_scene_restore_purchases_button: 'Käufe wiederherstellen',
    plans_scene_restore_purchases_success_toast:
      'Käufe erfolgreich wiederhergestellt',
    plans_scene_itunes_error:
      'Verbindung mit dem Apple App Store fehlgeschlagen',
    plans_scene_google_play_error: 'Verbindung mit Google Play fehlgeschlagen',

    plans_scene_current_plan_badge: 'Aktueller Plan',
    plans_scene_most_popular_badge: 'Am beliebtesten',
    plans_scene_best_value_badge: 'Bester Wert',

    plans_scene_monthly_cost_label: '/ Monat',
    plans_scene_yearly_cost_label: '/ Jahr',
    plans_scene_billed_yearly_cost_label: 'Jährlich berechnet - {0} / Jahr',

    plans_scene_plans_header: 'Pläne',
    plans_scene_one_time_purchases_header: 'Einmalige Käufe',
    plans_scene_subscription_terms_header: 'Abonnement-Bedingungen',

    plans_scene_free_item_header: 'Gratis',
    plans_scene_free_item_benefit_1: 'Unbegrenzte Einträge',
    plans_scene_free_item_benefit_2: 'Unbegrenzte Grundberichte',
    plans_scene_free_item_benefit_3:
      'Datendetails: begrenzt auf die letzten 30 Tage',

    plans_scene_monthly_item_header: 'Monatlich',
    plans_scene_monthly_item_benefit_1: 'Unbegrenzte Einträge',
    plans_scene_monthly_item_benefit_2: 'Unbegrenzte erweiterte Berichte',
    plans_scene_monthly_item_benefit_3: 'Unbegrenzte Datendetails',
    plans_scene_monthly_item_benefit_4: 'Zugang zum Schmerz-Leitfaden',

    plans_scene_yearly_item_header: 'Jährlich',
    plans_scene_yearly_item_benefit_1: 'Unbegrenzte Einträge',
    plans_scene_yearly_item_benefit_2: 'Unbegrenzte erweiterte Berichte',
    plans_scene_yearly_item_benefit_3: 'Unbegrenzte Datendetails',
    plans_scene_yearly_item_benefit_4: 'Zugang zum Schmerz-Leitfaden',
    plans_scene_yearly_item_benefit_5: '20% Rabatt',

    plans_scene_pro_purchase_item_header:
      'Unbegrenzte Datendetails freischalten',
    plans_scene_pro_purchase_item_benefit_1:
      'Entfernt das 30-Tage-Limit für Datendetails',
    plans_scene_pro_purchase_item_benefit_2:
      '3 Guthabenpunkte für fortgeschrittene Berichte',

    plans_scene_credits_item_header: 'Guthaben für Berichte',
    plans_scene_credits_item_benefit_1:
      'Entfernt das 30-Tage-Limit für Datendetails',
    plans_scene_credits_item_benefit_2:
      'Dient zur Erstellung erweiterter Berichte',
    plans_scene_credits_item_price: 'so niedrig wie {0}',

    plans_scene_card_purchase_button: 'Kaufen',
    plans_scene_card_expired_button: 'Erneuern',
    plans_scene_card_manage_button: 'Verwalten',
    plans_scene_card_upgrade_button: 'Upgraden',
    plans_scene_card_renew_button: 'Erneuern',
    plans_scene_card_downgrade_button: 'Downgraden',
    plans_scene_card_order_credits_button: 'Guthaben bestellen',
    plans_scene_card_current_plan: 'Aktueller Plan',
    plans_scene_card_expired: 'Abgelaufen',
    plans_scene_card_purchased: 'Gekauft',
    plans_scene_card_sponsored: 'Gesponsert',

    //Subscription plans cards
    card_add_view_records: 'Einträge hinzufügen und anzeigen',
    card_analytics_and_insights: 'Analysen und Datendetails',
    card_reports: shared('Reports', 'de'),
    card_my_plan: 'Aktuelles Abonnement',
    card_more_details: 'Mehr Details',
    card_manage_subscription: shared('Manage Subscription', 'de'),
    card_institution_pro:
      'Ihr Upgrade auf die Pro-Version wurde Ihnen kostenlos gesponsert',
    card_institution_subscription:
      'Ihr Abonnement wird gesponsert - Ihnen wird nichts berechnet.',
    card_subscription_itunes: 'Sie haben über den Apple App Store abonniert',
    card_subscription_google: 'Sie haben über Google Play abonniert',
    card_subscription_amazon: 'Sie haben über Amazon abonniert',
    card_subscription_not_managed:
      'Sie haben auf einem anderen Gerät abonniert',
    card_subscription_expired: 'Ihr Abonnement ist abgelaufen.',
    card_pro_other_account:
      'Sie haben mit einem anderen Manage My Pain-Konto abonniert - alle Benutzerkonten profitieren vom Abonnement auf diesem Gerät.',
    card_subscription_other_account:
      'Sie haben mit einem anderen Manage My Pain-Konto abonniert - alle Benutzerkonten profitieren vom Abonnement auf diesem Gerät.',
    card_subscription_expired_title: 'Abgelaufen',
    card_subscription_resume: 'Abonnement Fortsetzen',

    card_subscription_rules_button: 'PREISE UND BEDINGUNGEN VON ABONNEMENTS',
    card_subscription_rules_apple_text:
      'Manage My Pain bietet eine Option zur automatischen Verlängerung des Abonnements an:\n{0} monatlich\n\nIhr Manage My Pain-Abonnement wird automatisch verlängert, es sei denn, Sie deaktivieren die automatische Verlängerung spätestens 24 Stunden vor Ende des aktuellen Abo-Zeitraums. Sie können Ihr Abonnement in den Abonnement-Einstellungen Ihres Apple App Store-Kontos verwalten und dort die automatische Verlängerung deaktivieren. Ihr Apple App Store-Konto wird belastet, wenn der Kauf bestätigt wird.\n',
    card_subscription_rules_google_text:
      'Manage My Pain bietet eine Option zur automatischen Verlängerung des Abonnements an:\n{0} monatlich\n\nIhr Manage My Pain-Abonnement wird automatisch verlängert, es sei denn, Sie deaktivieren die automatische Verlängerung spätestens 24 Stunden vor Ende des aktuellen Abo-Zeitraums. Sie können Ihr Abonnement in den Abonnement-Einstellungen Ihres Google Play-Kontos verwalten und dort die automatische Verlängerung deaktivieren. Ihr Google Play-Konto wird belastet, wenn der Kauf bestätigt wird.\n',
    card_subscription_rules_amazon_text:
      'Manage My Pain bietet eine Option zur automatischen Verlängerung des Abonnements an:\n{0} monatlich\n\nIhr Manage My Pain-Abonnement wird automatisch verlängert, es sei denn, Sie deaktivieren die automatische Verlängerung spätestens 24 Stunden vor Ende des aktuellen Abo-Zeitraums. Sie können Ihr Abonnement in den Abonnement-Einstellungen Ihres Amazon Appstore-Kontos verwalten und dort die automatische Verlängerung deaktivieren. Ihr Amazon Appstore-Konto wird belastet, wenn der Kauf bestätigt wird.\n',
    card_subscription_rules_eula_title:
      'Sie finden unsere Endbenutzer-Lizenzvereinbarung hier:',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      'Sie finden unsere Datenschutzerklärung hier:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      'Ihr Manage My Pain-Abonnement verlängert sich automatisch, wenn Sie die automatische Verlängerung nicht mindestens 24 Stunden vor Ablauf des aktuellen Zeitraums deaktivieren. Sie können Ihr Abonnement in den Einstellungen Ihres Apple App Store Kontos verwalten und die automatische Verlängerung deaktivieren. Ihr Apple App Store-Konto wird belastet, wenn der Kauf bestätigt wird.',
    card_subscription_rules_google_updated_text:
      'Ihr Manage My Pain-Abonnement verlängert sich automatisch, wenn Sie die automatische Verlängerung nicht mindestens 24 Stunden vor Ablauf des aktuellen Zeitraums deaktivieren. Sie können die Abonnementeinstellungen in Google Play aufrufen, um Ihr Abonnement zu verwalten und die automatische Verlängerung zu deaktivieren. Ihr Google Play-Konto wird belastet, wenn der Kauf bestätigt wird.',

    card_institution_plan_includes: 'Ihr Plan beinhaltet',
    card_institution_plan_benefit_1: 'Unbegrenzte Berichte',
    card_institution_plan_benefit_2: 'Unbegrenzte Datendetails',
    card_institution_plan_benefit_3: 'Zugang zum Schmerz-Leitfaden',

    free_plan_title: 'Lite-Version',
    free_plan_add_view_records: 'Keine Einschränkungen',
    free_plan_analytics: 'Beschränkt auf die letzten 30 Tage',
    free_plan_reports:
      '1 vollständiger Bericht inklusive, danach beschränkt auf die letzten 30 Tage',
    free_plan_price: 'Kostenlos',
    free_plan_price_subtitle: '',
    free_plan_description:
      'Zusätzliche Berichte können zum Preis von nur {0} je Bericht gekauft werden',

    pro_plan_title: 'Pro-Version',
    pro_plan_add_view_records: 'Keine Einschränkungen',
    pro_plan_analytics: 'Keine Einschränkungen',
    pro_plan_reports:
      'Keine Einschränkungen für Berichte mit den Abschnitten Einblicke und/oder Einträge. 3 Credits inklusive, um Berichte mit anderen Abschnitten zu erstellen.',
    pro_plan_price_subtitle: 'einmalig',
    pro_plan_description:
      'Zusätzliche Berichte können für je {0} erworben werden',
    pro_plan_button: shared('Upgrade to Pro', 'de'),

    subscribe_plan_title: 'Pro-Abonnement',
    subscribe_plan_add_view_records: 'Keine Einschränkungen',
    subscribe_plan_analytics: 'Keine Einschränkungen',
    subscribe_plan_reports: 'Keine Einschränkungen',
    subscribe_plan_price_subtitle: 'pro Monat',
    subscribe_plan_description: 'Alle Berichte sind kostenlos',
    subscription_plan_pain_guide_title: 'Schmerz-Leitfaden',
    subscription_plan_pain_guide_sub_title:
      'Hilfreiche Informationen über Schmerzen und Möglichkeiten, sie zu bewältigen',
    subscribe_plan_button: 'Abonnieren',
  },

  es: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'es'),
    order_credits_scene_purchasing_overlay: 'Procesando compra…',
    order_credits_scene_pro_header:
      'La mayoría de informes no requieren créditos. Los créditos solo son necesarios para crear informes que incluyen secciones avanzadas como gráficos, cronologías o calendarios.',
    order_credits_scene_lite_header:
      'La mayoría de informes no requieren créditos. Los créditos solo son necesarios para crear informes que incluyen registros de más de 30 días o para incluir secciones avanzadas como gráficos, cronologías o calendarios.',
    order_credits_scene_order_now_button: shared('Order Credits', 'es'),
    order_credits_save: 'AHORRE {0}%',
    order_credits_title_credits_1: '1 crédito',
    order_credits_title_credits_3: '3 créditos',
    order_credits_title_credits_5: '5 créditos',

    order_credits_card_order_credit_button: 'Comprar crédito',
    order_credits_card_per_credit_label: '{0} por crédito',
    order_credits_card_savings_label: 'Ahorra el {0}%',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: 'Plan actual',
    subscription_plan_current_credits_label: 'Créditos actuales',
    subscription_plan_section_upgrade_plan_button: 'Cambiar plan',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'es',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'es'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'SUSCRIPCIÓN PRO',
    subscription_plan_section_lite_plan: 'LITE',
    subscription_plan_credits_unlimited: 'Ilimitada',
    subscription_plan_section_web_upgrade_text:
      'Para suscribirse, instale la app iOS o Android',
    subscription_plan_section_web_order_credits_text:
      'Para comprar créditos, instale la app iOS o Android',
    subscription_plan_current_plan_pain_guide_title: 'Guía de dolor',
    subscription_plan_current_plan_pain_guide_sub_title:
      'Información útil sobre el dolor y las formas de controlarlo',

    // Subscription plans Screens
    plans_scene_screen_header: 'Planes de suscripción',
    plans_scene_purchasing_overlay: 'Procesando compra…',
    plans_scene_loading_init_inapp: shared('Loading...', 'es'),
    plans_scene_restore_purchases_button: 'Restaurar compras',
    plans_scene_restore_purchases_success_toast:
      'Compras restauradas satisfactoriamente',
    plans_scene_itunes_error:
      'No se puede conectar al servicio de compras de Apple App Store',
    plans_scene_google_play_error:
      'No se puede conectar al servicio de compras de Google Play',

    plans_scene_current_plan_badge: 'Plan actual',
    plans_scene_most_popular_badge: 'Más populares',
    plans_scene_best_value_badge: 'Mejor valor',

    plans_scene_monthly_cost_label: '/ mes',
    plans_scene_yearly_cost_label: '/ año',
    plans_scene_billed_yearly_cost_label: 'Facturado anualmente - {0} / anual',

    plans_scene_plans_header: 'Planes',
    plans_scene_one_time_purchases_header: 'Compra única',
    plans_scene_subscription_terms_header: 'Condicions de suscripción',

    plans_scene_free_item_header: 'Gratis',
    plans_scene_free_item_benefit_1: 'Registros ilimitados',
    plans_scene_free_item_benefit_2: 'Registros ilimitados',
    plans_scene_free_item_benefit_3:
      'Perspectivas: limitado a los últimos 30 días',

    plans_scene_monthly_item_header: 'Mensual',
    plans_scene_monthly_item_benefit_1: 'Registros ilimitados',
    plans_scene_monthly_item_benefit_2: 'Informes avanzados ilimitados',
    plans_scene_monthly_item_benefit_3: 'Perspectivas ilimitadas',
    plans_scene_monthly_item_benefit_4: 'Acceso a la Guía de Dolor',

    plans_scene_yearly_item_header: 'Anual',
    plans_scene_yearly_item_benefit_1: 'Registros ilimitados',
    plans_scene_yearly_item_benefit_2: 'Informes avanzados ilimitados',
    plans_scene_yearly_item_benefit_3: 'Perspectivas ilimitadas',
    plans_scene_yearly_item_benefit_4: 'Acceso a la Guía de Dolor',
    plans_scene_yearly_item_benefit_5: '20% de descuento',

    plans_scene_pro_purchase_item_header: 'Desbloquea perspectivas ilimitadas',
    plans_scene_pro_purchase_item_benefit_1:
      'Elimina el límite de 30 días de las perspectivas',
    plans_scene_pro_purchase_item_benefit_2:
      '3 Créditos de informes para informes avanzados',

    plans_scene_credits_item_header: 'Créditos de informes',
    plans_scene_credits_item_benefit_1:
      'Elimina el límite de 30 días de las perspectivas',
    plans_scene_credits_item_benefit_2:
      'Se usan para generas reportes avanzados',
    plans_scene_credits_item_price: 'por tan solo {0}',

    plans_scene_card_purchase_button: 'Comprar',
    plans_scene_card_expired_button: 'Renovar',
    plans_scene_card_manage_button: 'Gestionar',
    plans_scene_card_upgrade_button: 'Actualizar',
    plans_scene_card_renew_button: 'Renovar',
    plans_scene_card_downgrade_button: 'Reducir',
    plans_scene_card_order_credits_button: 'Pedir créditos',
    plans_scene_card_current_plan: 'Plan actual',
    plans_scene_card_expired: 'Vencido',
    plans_scene_card_purchased: 'Comprada',
    plans_scene_card_sponsored: 'Financiado',

    //Subscription plans cards
    card_add_view_records: 'Agregar y ver registros',
    card_analytics_and_insights: 'Análisis y perspectivas',
    card_reports: shared('Reports', 'es'),
    card_my_plan: 'Plan actual',
    card_more_details: 'Más detalles',
    card_manage_subscription: shared('Manage Subscription', 'es'),
    card_institution_pro:
      'Su actualización a Pro fue patrocinada sin costo para usted',
    card_institution_subscription:
      'Su plan de suscripción es patrocinado. No se le cobrará nada.',
    card_subscription_itunes:
      'Usted se ha suscrito a través de Apple App Store',
    card_subscription_google: 'Usted se ha suscrito a través de Google Play',
    card_subscription_amazon: 'Usted se ha suscrito a través de Amazon',
    card_subscription_not_managed: 'Usted se ha suscrito en otro dispositivo',
    card_subscription_expired: 'Su suscripción expiró.',
    card_pro_other_account:
      'Usted se suscribió a otra cuenta Manage My Pain. Todas las cuentas son parte de esa suscripción en este dispositivo.',
    card_subscription_other_account:
      'Usted se suscribió a otra cuenta Manage My Pain. Todas las cuentas son parte de esa suscripción en este dispositivo.',
    card_subscription_expired_title: 'Expiró',
    card_subscription_resume: 'Reanudar suscripción',

    card_subscription_rules_button: 'PRECIO Y TÉRMINOS DE LA SUSCRIPCIÓN',
    card_subscription_rules_apple_text:
      'Manage My Pain ofrece una opción única de autorenovación:\n{0} por mes\n\nSu suscripción de Manage My Pain se renovará automáticamente a menos que desactive la renovación automática al menos 24 horas antes de que termine el periodo actual. Puede ir a los ajustes de su cuenta de la Apple App Store para gestionar su suscripción y desactivar la opción de renovación automática. Se aplicará un cargo en su cuenta de Apple App Store cuando se confirme la compra.\n',
    card_subscription_rules_google_text:
      'Manage My Pain ofrece una opción única de autorenovación:\n{0} por mes\n\nSu suscripción de Manage My Pain se renovará automáticamente a menos que desactive la renovación automática al menos 24 horas antes de que termine el periodo actual. Puede ir a los ajustes de suscripción en Google Play para gestionar su suscripción y desactivar la renovación automática. Se aplicará un cargo en su cuenta de Google Play Store cuando se confirme la compra.\n',
    card_subscription_rules_amazon_text:
      'Manage My Pain ofrece una opción única de autorenovación:\n{0} por mes\n\nSu suscripción de Manage My Pain se renovará automáticamente a menos que desactive la renovación automática al menos 24 horas antes de que termine el periodo actual. Puede ir a los ajustes de su Amazon App Store para gestionar su suscripción y desactivar la renovación automática. Se aplicará un cargo en su cuenta de Amazon App Store cuando se confirme la compra.\n',
    card_subscription_rules_eula_title:
      'Puede encontrar nuestro Acuerdo de licencia de usuario final aquí:',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      'Puede encontrar nuestra Política de privacidad aquí:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      'Su suscripción a Manage My Pain se renovará automáticamente a menos que la renovación automática se desactive al menos 24 horas antes del final del período actual. Puede ir a la configuración de su cuenta de Apple App Store para administrar su suscripción y desactivar la renovación automática. Se hará un cargo a su cuenta de Apple App Store cuando se confirme la compra.',
    card_subscription_rules_google_updated_text:
      'Su suscripción a Manage My Pain se renovará automáticamente a menos que la renovación automática se desactive al menos 24 horas antes del final del período actual. Puede ir a la configuración de Suscripción en Google Play para administrar su suscripción y desactivar la renovación automática. Se hará un cargo a su cuenta de Google Play cuando se confirme la compra.',

    card_institution_plan_includes: 'Su plan incluye',
    card_institution_plan_benefit_1: 'Informes ilimitados',
    card_institution_plan_benefit_2: 'Perspectivas ilimitadas',
    card_institution_plan_benefit_3: 'Acceso a la Guía de Dolor',

    free_plan_title: 'Versión Lite',
    free_plan_add_view_records: 'Ilimitada',
    free_plan_analytics: 'Limitada a los últimos 30 días',
    free_plan_reports:
      'Se incluye un informe completo, luego se limita a los últimos 30 días',
    free_plan_price: 'Gratis',
    free_plan_price_subtitle: '',
    free_plan_description:
      'Puede comprar informes adicionales desde {0} cada uno',

    pro_plan_title: 'Versión Pro',
    pro_plan_add_view_records: 'Ilimitada',
    pro_plan_analytics: 'Ilimitada',
    pro_plan_reports:
      'No hay límites para los informes con las secciones Perpectivas y/o Registros. 3 créditos incluidos para crear informes con otras secciones.',
    pro_plan_price_subtitle: 'una vez',
    pro_plan_description: 'Puede comprar informes adicionales por {0} cada uno',
    pro_plan_button: shared('Upgrade to Pro', 'es'),

    subscribe_plan_title: 'Suscripción Pro',
    subscribe_plan_add_view_records: 'Ilimitada',
    subscribe_plan_analytics: 'Ilimitada',
    subscribe_plan_reports: 'Ilimitada',
    subscribe_plan_price_subtitle: 'por mes',
    subscribe_plan_description: 'Todos los informes son gratuitos',
    subscription_plan_pain_guide_title: 'Guía de dolor',
    subscription_plan_pain_guide_sub_title:
      'Información útil sobre el dolor y las formas de controlarlo',
    subscribe_plan_button: 'Suscríbase',
  },

  fr: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'fr'),
    order_credits_scene_purchasing_overlay: "Traitement d'achat en cours…",
    order_credits_scene_pro_header:
      'Des crédits ne sont pas nécessaires pour la plupart des rapports. Les crédits ne sont nécessaires que pour créer des rapports ayant des sections avancées comme des graphiques, des chronologies ou des calendriers.',
    order_credits_scene_lite_header:
      'Des crédits ne sont pas nécessaires pour la plupart des rapports. Les crédits ne sont nécessaires que pour créer des rapports ayant des enregistrements de plus de 30 jours ou pour ajouter des sections avancées comme des graphiques, des chronologies ou des calendriers.',
    order_credits_scene_order_now_button: shared('Order Credits', 'fr'),
    order_credits_save: 'ÉPARGNEZ {0}%',
    order_credits_title_credits_1: '1 Crédit',
    order_credits_title_credits_3: '3 Crédits',
    order_credits_title_credits_5: '5 Crédits',

    order_credits_card_order_credit_button: 'Acheter du crédit',
    order_credits_card_per_credit_label: '{0} par crédit',
    order_credits_card_savings_label: "{0}% d'économies",

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: 'Plan actuel',
    subscription_plan_current_credits_label: 'Crédits actuels',
    subscription_plan_section_upgrade_plan_button: 'Changer le plan',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'fr',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'fr'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'SOUSCRIPTION PRO',
    subscription_plan_section_lite_plan: 'LITE',
    subscription_plan_credits_unlimited: 'Illimitée',
    subscription_plan_section_web_upgrade_text:
      "Pour vous inscrire, installez l'application pour iOS ou Android.",
    subscription_plan_section_web_order_credits_text:
      "Pour acheter des crédits, installez l'application pour iOS ou Android.",
    subscription_plan_current_plan_pain_guide_title: 'Guide sur la douleur',
    subscription_plan_current_plan_pain_guide_sub_title:
      'Informations utiles sur la douleur et les moyens de la gérer',

    // Subscription plans Screens
    plans_scene_screen_header: 'Plans de souscription',
    plans_scene_purchasing_overlay: "Traitement d'achat en cours…",
    plans_scene_loading_init_inapp: shared('Loading...', 'fr'),
    plans_scene_restore_purchases_button: 'Restaurer des achats',
    plans_scene_restore_purchases_success_toast:
      "Restauration d'achats réussie",
    plans_scene_itunes_error:
      "Impossible de se connecter au service d'achats de Apple App Store ",
    plans_scene_google_play_error:
      "Impossible de se connecter au service d'achats de Google Play",

    plans_scene_current_plan_badge: 'Plan actuel',
    plans_scene_most_popular_badge: 'Le plus populaire',
    plans_scene_best_value_badge: 'Meilleur rapport qualité/prix',

    plans_scene_monthly_cost_label: '/ mois',
    plans_scene_yearly_cost_label: '/ an',
    plans_scene_billed_yearly_cost_label: 'Facturation annuelle - {0} / an',

    plans_scene_plans_header: 'Plans',
    plans_scene_one_time_purchases_header: 'Achats uniques',
    plans_scene_subscription_terms_header: 'Conditions de souscription',

    plans_scene_free_item_header: 'Gratuit',
    plans_scene_free_item_benefit_1: 'Enregistrements illimités',
    plans_scene_free_item_benefit_2: 'Rapports de base illimités',
    plans_scene_free_item_benefit_3:
      'Perspectives : limitées aux 30 derniers jours',

    plans_scene_monthly_item_header: 'Sur une base mensuelle',
    plans_scene_monthly_item_benefit_1: 'Enregistrements illimités',
    plans_scene_monthly_item_benefit_2: 'Rapports avancés illimités',
    plans_scene_monthly_item_benefit_3: 'Des perspectives illimitées',
    plans_scene_monthly_item_benefit_4: 'Accès au Guide sur la douleur',

    plans_scene_yearly_item_header: 'Sur une base annuelle',
    plans_scene_yearly_item_benefit_1: 'Enregistrements illimités',
    plans_scene_yearly_item_benefit_2: 'Rapports avancés illimités',
    plans_scene_yearly_item_benefit_3: 'Des perspectives illimitées',
    plans_scene_yearly_item_benefit_4: 'Accès au Guide sur la douleur',
    plans_scene_yearly_item_benefit_5: '20% de réduction',

    plans_scene_pro_purchase_item_header:
      'Débloquer des perspectives illimitées',
    plans_scene_pro_purchase_item_benefit_1:
      'Suppression de la limite de 30 jours pour les perspectives',
    plans_scene_pro_purchase_item_benefit_2:
      '3 crédits de rapport pour les rapports avancés',

    plans_scene_credits_item_header: 'Crédits de rapport',
    plans_scene_credits_item_benefit_1:
      'Suppression de la limite de 30 jours pour les perspectives',
    plans_scene_credits_item_benefit_2:
      'Permettant de générer des rapports avancés',
    plans_scene_credits_item_price: 'pour seulement {0}',

    plans_scene_card_purchase_button: 'Acheter',
    plans_scene_card_expired_button: 'Renouveler',
    plans_scene_card_manage_button: 'Gérer',
    plans_scene_card_upgrade_button: 'Passer à une catégorie supérieure',
    plans_scene_card_renew_button: 'Renouveler',
    plans_scene_card_downgrade_button: 'Passer à une catégorie inférieure',
    plans_scene_card_order_credits_button: 'Commander des crédits',
    plans_scene_card_current_plan: 'Plan actuel',
    plans_scene_card_expired: 'Expirée',
    plans_scene_card_purchased: 'Achetée',
    plans_scene_card_sponsored: 'Sponsorisée',

    //Subscription plans cards
    card_add_view_records: 'Ajouter et voir des enregistrements',
    card_analytics_and_insights: 'Analyses et perspectives',
    card_reports: shared('Reports', 'fr'),
    card_my_plan: 'Plan actuel',
    card_more_details: 'Plus de détails',
    card_manage_subscription: shared('Manage Subscription', 'fr'),
    card_institution_pro:
      'Votre mis à jours vers PRO a été sponsorisée gratuitement pour vous',
    card_institution_subscription:
      'Votre plan de souscription a été sponsorisé. Vous ne serez pas facturé.',
    card_subscription_itunes: 'Vous êtes abonné depuis Apple App Store',
    card_subscription_google: 'Vous êtes abonné depuis Google Play',
    card_subscription_amazon: 'Vous êtes abonné depuis Amazon',
    card_subscription_not_managed: 'Vous êtes abonné depuis autre dispositif',
    card_subscription_expired: 'Votre abonnement est expiré',
    card_pro_other_account:
      'Vous vous êtes abonné à un autre compte de Manage My Pain. Tous les comptes font partie de cet abonnement dans ce dispositif.',
    card_subscription_other_account:
      'Vous vous êtes abonné à un autre compte de Manage My Pain. Tous les comptes font partie de cet abonnement dans ce dispositif.',
    card_subscription_expired_title: 'Expirée',
    card_subscription_resume: "Reprendre l'abonnement",

    card_subscription_rules_button: "PRIX ET TERMES D'ABONNEMENT ",
    card_subscription_rules_apple_text:
      "Manage My Pain vous offre une option unique d'auto-renouvellement:\n{0} par mois\n\nVotre souscription à Manage My Pain sera renouvelée automatiquement à moins que vous ne désactiviez le renouvellement automatique au moins 24 heures avant la fin de la période actuelle. Vous pouvez accéder à la Configuration de compte dans l'App Store d'Apple pour gérer votre souscription et désactiver l'option de renouvellement automatique. Une fois votre achat confirmé, nous vous facturerons dans votre compte App Store d'Apple.\n",
    card_subscription_rules_google_text:
      "Manage My Pain vous offre une option unique d'auto-renouvellement:\n{0} par mois\n\nVotre souscription à Manage My Pain sera renouvelée automatiquement à moins que vous ne désactiviez le renouvellement automatique au moins 24 heures avant la fin de la période actuelle. Vous pouvez accéder à la Configuration de souscription sur Google Play pour gérer votre souscription et désactiver l'option de renouvellement automatique. Une fois votre achat confirmé, nous vous facturerons dans votre compte Google Play.\n",
    card_subscription_rules_amazon_text:
      "Manage My Pain vous offre une option unique d'auto-renouvellement:\n{0} par mois\n\nVotre souscription à Manage My Pain sera renouvelée automatiquement à moins que vous ne désactiviez le renouvellement automatique au moins 24 heures avant la fin de la période actuelle. Vous pouvez accéder à la Configuration de compte dans l'App Store d'Amazon pour gérer votre souscription et désactiver l'option de renouvellement automatique. Une fois votre achat confirmé, nous vous facturerons dans votre compte App Store d'Amazon.\n",
    card_subscription_rules_eula_title:
      "Vous pouvez trouver notre Contrat de licence de l'utilisateur final ici :",
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      'Vous pouvez trouver notre Politique de confidentialité ici:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      "Votre souscription à Manage My Pain sera automatiquement renouvelée à moins que le renouvellement automatique ne soit désactivé au moins 24 heures avant la fin de la période en cours. Il vous est possible d'accéder aux paramètres de votre compte Apple App Store pour gérer votre souscription et désactiver le renouvellement automatique. Votre compte Apple App Store sera débité une fois l'achat confirmé.",
    card_subscription_rules_google_updated_text:
      "Votre souscription à Manage My Pain sera automatiquement renouvelée à moins que le renouvellement automatique ne soit désactivé au moins 24 heures avant la fin de la période en cours. Il vous est possible d'accéder aux paramètres de souscription dans Google Play pour gérer votre souscription et désactiver le renouvellement automatique. Votre compte Google Play sera débité une fois l'achat confirmé.",

    card_institution_plan_includes: 'Votre plan comprend',
    card_institution_plan_benefit_1: 'Des rapports illimités',
    card_institution_plan_benefit_2: 'Des perspectives illimitées',
    card_institution_plan_benefit_3: 'Accès au Guide sur la douleur',

    free_plan_title: 'Version Lite',
    free_plan_add_view_records: 'Illimité',
    free_plan_analytics: 'Limité aux 30 derniers jours',
    free_plan_reports:
      '1 rapport complet inclus. Après, le compte est limité aux 30 derniers jours',
    free_plan_price: 'Gratuit',
    free_plan_price_subtitle: '',
    free_plan_description:
      'Vous pouvez acheter des rapports additionnels à partir de {0} chacun',

    pro_plan_title: 'Version Pro',
    pro_plan_add_view_records: 'Illimité',
    pro_plan_analytics: 'Illimité',
    pro_plan_reports:
      "Aucune limite pour les rapports relatifs aux rubriques Réflexions et/ou Enregistrements. 3 crédits inclus pour créer des rapports avec d'autres rubriques.",
    pro_plan_price_subtitle: 'une fois',
    pro_plan_description:
      'Vous pouvez acheter des rapports additionnels pour {0} chacun',
    pro_plan_button: shared('Upgrade to Pro', 'fr'),

    subscribe_plan_title: 'Souscription Pro',
    subscribe_plan_add_view_records: 'Illimité',
    subscribe_plan_analytics: 'Illimité',
    subscribe_plan_reports: 'Illimité',
    subscribe_plan_price_subtitle: 'par mois',
    subscribe_plan_description: 'Tous les rapports sont gratuits',
    subscription_plan_pain_guide_title: 'Guide sur la douleur',
    subscription_plan_pain_guide_sub_title:
      'Informations utiles sur la douleur et les moyens de la gérer',
    subscribe_plan_button: 'Abonnez-vous',
  },

  ko: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'ko'),
    order_credits_scene_purchasing_overlay: '구매를 처리하는 중입니다...',
    order_credits_scene_pro_header:
      '대부분의 리포트는 크레딧이 필요하지 않습니다. 크레딧은 차트, 타임라인 또는 캘린더와 같은 고급 섹션을 포함하는 리포트를 생성하는 데만 필요합니다.',
    order_credits_scene_lite_header:
      '대부분의 리포트는 크레딧이 필요하지 않습니다. 크레딧은 30일 이전의 레코드를 포함하는 리포트를 생성하거나 차트, 타임라인 또는 캘린더와 같은 고급 섹션을 포함하는 경우에만 사용됩니다.',
    order_credits_scene_order_now_button: shared('Order Credits', 'ko'),
    order_credits_save: '{0}% 절약',
    order_credits_title_credits_1: '1점',
    order_credits_title_credits_3: '3점',
    order_credits_title_credits_5: '5점',

    order_credits_card_order_credit_button: '크레딧 주문',
    order_credits_card_per_credit_label: '크레딧당 {0}',
    order_credits_card_savings_label: '{0}% 절약',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: '현재 구독',
    subscription_plan_current_credits_label: '현재 크레딧',
    subscription_plan_section_upgrade_plan_button: '구독 변경',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'ko',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'ko'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'PRO 구독',
    subscription_plan_section_lite_plan: '라이트',
    subscription_plan_credits_unlimited: '무제한',
    subscription_plan_section_web_upgrade_text:
      '구독하려면 iOS 또는 안드로이드 앱을 설치하세요',
    subscription_plan_section_web_order_credits_text:
      '크레딧을 구매하려면, iOS 또는 안드로이드 앱을 설치하세요',
    subscription_plan_current_plan_pain_guide_title: '통증 가이드',
    subscription_plan_current_plan_pain_guide_sub_title:
      '통증에 대한 유용한 정보와 통증 관리 방법',

    // Subscription plans Screens
    plans_scene_screen_header: '구독 플랜',
    plans_scene_purchasing_overlay: '구매를 처리하는 중입니다...',
    plans_scene_loading_init_inapp: shared('Loading...', 'ko'),
    plans_scene_restore_purchases_button: '구매 복원',
    plans_scene_restore_purchases_success_toast: '구입이 성공적으로 복구됨',
    plans_scene_itunes_error: 'Apple 앱 스토어 구매 서비스에 연결할 수 없음',
    plans_scene_google_play_error: '구글 플레이 구매 서비스에 연결할 수 없음',

    plans_scene_current_plan_badge: '현재 플랜',
    plans_scene_most_popular_badge: '최고 인기',
    plans_scene_best_value_badge: '최고 가성비',

    plans_scene_monthly_cost_label: '/월',
    plans_scene_yearly_cost_label: '/월',
    plans_scene_billed_yearly_cost_label: '연간 결제 - 매년 {0}',

    plans_scene_plans_header: '플랜',
    plans_scene_one_time_purchases_header: '1회 구매',
    plans_scene_subscription_terms_header: '구독 조건',

    plans_scene_free_item_header: '무료',
    plans_scene_free_item_benefit_1: '무제한 기록',
    plans_scene_free_item_benefit_2: '무제한 기본 보고서',
    plans_scene_free_item_benefit_3: '인사이트: 최근 30일로 제한',

    plans_scene_monthly_item_header: '월간',
    plans_scene_monthly_item_benefit_1: '무제한 기록',
    plans_scene_monthly_item_benefit_2: '무제한 고급 보고서',
    plans_scene_monthly_item_benefit_3: '무제한 인사이트',
    plans_scene_monthly_item_benefit_4: '통증 가이드 기능',

    plans_scene_yearly_item_header: '연간',
    plans_scene_yearly_item_benefit_1: '무제한 기록',
    plans_scene_yearly_item_benefit_2: '무제한 고급 보고서',
    plans_scene_yearly_item_benefit_3: '무제한 인사이트',
    plans_scene_yearly_item_benefit_4: '통증 가이드 기능',
    plans_scene_yearly_item_benefit_5: '20% 할인',

    plans_scene_pro_purchase_item_header: '무제한 인사이트 해제',
    plans_scene_pro_purchase_item_benefit_1: '인사이트 30일 제한 제거',
    plans_scene_pro_purchase_item_benefit_2:
      '고급 보고서 이용 시 보고서 크레딧 3개 필요',

    plans_scene_credits_item_header: '보고서 크레딧',
    plans_scene_credits_item_benefit_1: '인사이트 30일 제한 제거',
    plans_scene_credits_item_benefit_2: '고급 보고서 생성에 이용됨',
    plans_scene_credits_item_price: '최저 {0}',

    plans_scene_card_purchase_button: '구매',
    plans_scene_card_expired_button: '갱신',
    plans_scene_card_manage_button: '관리',
    plans_scene_card_upgrade_button: '업그레이드',
    plans_scene_card_renew_button: '갱신',
    plans_scene_card_downgrade_button: '다운그레이드',
    plans_scene_card_order_credits_button: '크레딧 주문',
    plans_scene_card_current_plan: '현재 플랜',
    plans_scene_card_expired: '만료된 구독',
    plans_scene_card_purchased: '구매한 구독',
    plans_scene_card_sponsored: '후원받은 구독',

    //Subscription plans cards
    card_add_view_records: '기록 추가 및 보기',
    card_analytics_and_insights: '분석 및 통찰력',
    card_reports: shared('Reports', 'ko'),
    card_my_plan: '현재 계획',
    card_more_details: '세부정보 더보기',
    card_manage_subscription: shared('Manage Subscription', 'ko'),
    card_institution_pro: '당신의 프로 업그레이드가 무료로 후원되었습니다',
    card_institution_subscription:
      '귀하의 구독 플랜은 후원되었습니다. 비용이 추가되지 않습니다.',
    card_subscription_itunes: 'Apple 앱 스토어를 통해 가입하셨습니다',
    card_subscription_google: '구글 플레이를 통해 가입하셨습니다',
    card_subscription_amazon: '아마존을 통해 가입하셨습니다',
    card_subscription_not_managed: '다른 장치에 가입하셨습니다',
    card_subscription_expired: '가입이 만료됨.',
    card_pro_other_account:
      '다른 Manage My Pain 계정으로 가입하셨습니다, 모든 계정은 이 장치의 해당 구독에서 혜택을 받게 됩니다.',
    card_subscription_other_account:
      '다른 Manage My Pain 계정으로 가입하셨습니다, 모든 계정은 이 장치의 해당 구독에서 혜택을 받게 됩니다.',
    card_subscription_expired_title: '만료됨',
    card_subscription_resume: '구독 재개',

    card_subscription_rules_button: '구독 정책 및 용어',
    card_subscription_rules_apple_text:
      'Manage My Pain은 단일 자동 갱신 구독 옵션을 제공한다:\n{0} 월\n\nManage My Pain은 현재 기간이 끝나기 최소 24시간 전에 자동 갱신이 해제되지 않는 한 자동으로 갱신된다. Apple 앱 스토어 계정 설정으로 이동하여 헤드라인 등록을 관리하고 자동 갱신을 해제할 수 있다. 구입이 확인되면 Apple 앱 스토어 계정이 청구된다.\n',
    card_subscription_rules_google_text:
      'Manage My Pain은 단일 자동 갱신 구독 옵션을 제공한다:\n{0} 월\n\n당내 통증 관리 구독은 현재 기간이 끝나기 최소 24시간 전에 자동 갱신이 해제되지 않는 한 자동으로 갱신된다. 구글 플레이의 구독 설정으로 이동하여 구독을 관리하고 자동 갱신을 해제할 수 있다. 구입이 확인되면 구글 플레이 계정이 청구된다.\n',
    card_subscription_rules_amazon_text:
      'Manage My Pain은 단일 자동 갱신 구독 옵션을 제공한다:\n{0} 월\n\nManage My Pain 구독은 현재 기간이 끝나기 최소 24시간 전에 자동 갱신이 해제되지 않는 한 자동으로 갱신된다. Amazon 앱 스토어 설정으로 이동하여 구독을 관리하고 자동 갱신을 해제할 수 있다. 구입이 확인되면 아마존 앱스토어 계정이 청구된다.\n',
    card_subscription_rules_eula_title:
      '최종 사용자 라이센스 계약서는 다음 사이트에서 확인하십시오:',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      '개인 정보 보호 정책은 다음 사이트에서 확인하십시오:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      '현재 구독 기간 종료 최소 24시간 이전에 자동 갱신을 해제하지 않을 경우, Manage My Pain 구독이 자동으로 갱신됩니다. 애플 앱스토어 계정 설정에서 구독을 관리하고 자동 갱신을 해제할 수 있습니다. 구매가 확정되면 애플 앱스토어 계정으로 비용이 청구됩니다.',
    card_subscription_rules_google_updated_text:
      '현재 구독 기간 종료 최소 24시간 이전에 자동 갱신을 해제하지 않을 경우, Manage My Pain 구독이 자동으로 갱신됩니다. 구글 플레이 설정에서 구독을 관리하고 자동 갱신을 해제할 수 있습니다. 구매가 확정되면 구글 플레이 계정으로 비용이 청구됩니다.',

    card_institution_plan_includes: '플랜 포함 사항',
    card_institution_plan_benefit_1: '무제한 보고서',
    card_institution_plan_benefit_2: '무제한 인사이트',
    card_institution_plan_benefit_3: '통증 가이드 기능',

    free_plan_title: '라이트 버전',
    free_plan_add_view_records: '제한 없음',
    free_plan_analytics: '최근 30일로 제한됨',
    free_plan_reports: '전체 보고서 1개 포함, 지난 30일로 제한',
    free_plan_price: '무료',
    free_plan_price_subtitle: '',
    free_plan_description:
      '각각 {0} 만큼 낮게 추가 보고서를 구입할 수 있습니다',

    pro_plan_title: 'Pro 버전',
    pro_plan_add_view_records: '제한 없음',
    pro_plan_analytics: '제한 없음',
    pro_plan_reports:
      '인사이트 및/또는 기록 섹션에는 보고서 제한이 없습니다. 다른 섹션으로 보고서를 생성하기 위해 3개의 크레딧이 포함되었습니다.',
    pro_plan_price_subtitle: '일회성',
    pro_plan_description: '추가 보고서를 각각 {0}에 대해 구매할 수 있음',
    pro_plan_button: shared('Upgrade to Pro', 'ko'),

    subscribe_plan_title: 'Pro 구독',
    subscribe_plan_add_view_records: '제한 없음',
    subscribe_plan_analytics: '제한 없음',
    subscribe_plan_reports: '제한 없음',
    subscribe_plan_price_subtitle: '매월',
    subscribe_plan_description: '모든 보고서는 무료입니다',
    subscription_plan_pain_guide_title: '통증 가이드',
    subscription_plan_pain_guide_sub_title:
      '통증에 대한 유용한 정보와 통증 관리 방법',
    subscribe_plan_button: '구독',
  },

  ru: {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'ru'),
    order_credits_scene_purchasing_overlay: 'Обработка покупки...',
    order_credits_scene_pro_header:
      'Для большинства отчетов не нужны кредиты. Кредиты нужны только для создания отчетов, содержащих расширенные разделы: графики, хронологию событий или календари.',
    order_credits_scene_lite_header:
      'Для большинства отчетов не нужны кредиты. Кредиты нужны только для создания отчетов, содержащих записи старше 30 дней или расширенные разделы: графики, хронологию событий или календари.',
    order_credits_scene_order_now_button: shared('Order Credits', 'ru'),
    order_credits_save: 'ЭКОНОМИЯ {0}%',
    order_credits_title_credits_1: '1 кредит',
    order_credits_title_credits_3: '3 кредита',
    order_credits_title_credits_5: '5 кредитов',

    order_credits_card_order_credit_button: 'Купить кредит',
    order_credits_card_per_credit_label: '{0} за кредит',
    order_credits_card_savings_label: 'Экономия {0}%',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: 'Текущий план',
    subscription_plan_current_credits_label: 'Текущие кредиты',
    subscription_plan_section_upgrade_plan_button: 'Изменить план',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'ru',
    ),
    subscription_plan_section_credits_loading_text: shared('Loading...', 'ru'),
    subscription_plan_section_pro_plan: 'PRO',
    subscription_plan_section_subscription_plan: 'ПОДПИСКА PRO',
    subscription_plan_section_lite_plan: 'LITE',
    subscription_plan_credits_unlimited: 'Не ограничено',
    subscription_plan_section_web_upgrade_text:
      'Чтобы оформить подписку, установите приложение для iOS или Android',
    subscription_plan_section_web_order_credits_text:
      'Чтобы купить кредиты, установите приложение для iOS или Android',
    subscription_plan_current_plan_pain_guide_title: 'Всё о боли',
    subscription_plan_current_plan_pain_guide_sub_title:
      'Полезная информация о боли и способах справиться с ней',

    // Subscription plans Screens
    plans_scene_screen_header: 'План подписки',
    plans_scene_purchasing_overlay: 'Обработка покупки...',
    plans_scene_loading_init_inapp: shared('Loading...', 'ru'),
    plans_scene_restore_purchases_button: 'Восстановить покупки',
    plans_scene_restore_purchases_success_toast:
      'Покупки успешно восстановлены',
    plans_scene_itunes_error:
      'Не удалось связаться со службой покупки Apple App Store',
    plans_scene_google_play_error:
      'Не удалось связаться со службой покупки Google Play',

    plans_scene_current_plan_badge: 'Текущий план',
    plans_scene_most_popular_badge: 'Самый популярный',
    plans_scene_best_value_badge: 'Лучшее предложение',

    plans_scene_monthly_cost_label: '/ мес.',
    plans_scene_yearly_cost_label: '/ г.',
    plans_scene_billed_yearly_cost_label: 'Оплата ежегодно — {0} в год',

    plans_scene_plans_header: 'Тарифные планы',
    plans_scene_one_time_purchases_header: 'Единоразовый платёж',
    plans_scene_subscription_terms_header: 'Условия подписки',

    plans_scene_free_item_header: 'Бесплатно',
    plans_scene_free_item_benefit_1: 'Записи без ограничений',
    plans_scene_free_item_benefit_2: 'Простые отчёты без ограничений',
    plans_scene_free_item_benefit_3: 'Аналитика ограничена последними 30 д.',

    plans_scene_monthly_item_header: 'Ежемесячно',
    plans_scene_monthly_item_benefit_1: 'Записи без ограничений',
    plans_scene_monthly_item_benefit_2: 'Расширенные отчёты без ограничений',
    plans_scene_monthly_item_benefit_3: 'Аналитика без ограничений',
    plans_scene_monthly_item_benefit_4: 'Доступ к руководству «Всё о боли»',

    plans_scene_yearly_item_header: 'Ежегодно',
    plans_scene_yearly_item_benefit_1: 'Записи без ограничений',
    plans_scene_yearly_item_benefit_2: 'Расширенные отчёты без ограничений',
    plans_scene_yearly_item_benefit_3: 'Аналитика без ограничений',
    plans_scene_yearly_item_benefit_4: 'Доступ к руководству «Всё о боли»',
    plans_scene_yearly_item_benefit_5: 'Скидка 20%',

    plans_scene_pro_purchase_item_header: 'Неограниченный доступ к аналитике',
    plans_scene_pro_purchase_item_benefit_1:
      'Убирает 30-дневный лимит в аналитике',
    plans_scene_pro_purchase_item_benefit_2:
      '3 кредита для создания расширенных отчётов',

    plans_scene_credits_item_header: 'Кредиты для создания отчётов',
    plans_scene_credits_item_benefit_1: 'Убирает 30-дневный лимит в аналитике',
    plans_scene_credits_item_benefit_2:
      'Используются для создания расширенных отчётов',
    plans_scene_credits_item_price: 'от {0}',

    plans_scene_card_purchase_button: 'Купить',
    plans_scene_card_expired_button: 'Возобновить',
    plans_scene_card_manage_button: 'Управление',
    plans_scene_card_upgrade_button: 'Повысить план',
    plans_scene_card_renew_button: 'Возобновить',
    plans_scene_card_downgrade_button: 'Понизить план',
    plans_scene_card_order_credits_button: 'Купить кредиты',
    plans_scene_card_current_plan: 'Текущий план',
    plans_scene_card_expired: 'Истекла',
    plans_scene_card_purchased: 'Куплена',
    plans_scene_card_sponsored: 'Спонсирована',

    //Subscription plans cards
    card_add_view_records: 'Добавление и просмотр записей',
    card_analytics_and_insights: 'Аналитическая картина',
    card_reports: shared('Reports', 'ru'),
    card_my_plan: 'Текущий план',
    card_more_details: 'Подробнее',
    card_manage_subscription: shared('Manage Subscription', 'ru'),
    card_institution_pro:
      'Ваш переход на тарифный план Pro был оплачен спонсором',
    card_institution_subscription:
      'Ваш план подписки проспонсирован — оплата не требуется.',
    card_subscription_itunes: 'Вы оформили подписку через Apple App Store',
    card_subscription_google: 'Вы оформили подписку через Google Play',
    card_subscription_amazon: 'Вы оформили подписку через Amazon',
    card_subscription_not_managed: 'Вы оформили подписку на другом устройстве',
    card_subscription_expired: 'Срок вашей подписки истек.',
    card_pro_other_account:
      'У вас есть подписка на другой аккаунт Manage My Pain — у всех аккаунтов на этом устройстве есть доступ к подписке.',
    card_subscription_other_account:
      'У вас есть подписка на другой аккаунт Manage My Pain — у всех аккаунтов на этом устройстве есть доступ к подписке.',
    card_subscription_expired_title: 'Срок истек',
    card_subscription_resume: 'Возобновить подписку',

    card_subscription_rules_button: 'УСЛОВИЯ И СТОИМОСТЬ ПОДПИСКИ',
    card_subscription_rules_apple_text:
      'Приложение Manage My Pain предлагает только один вариант подписки:\n{0} в месяц\n\nПодписка на приложение будет продлеваться автоматически, пока автопродление не будет отключено не менее чем за 24 часа до окончания текущего периода. Управление подпиской и отключение автопродления осуществляется в настройках аккаунта Apple App Store. Средства будут списаны с аккаунта Apple App Store после подтверждения подписки.\n',
    card_subscription_rules_google_text:
      'Приложение Manage My Pain предлагает только один вариант подписки:\n{0} в месяц\n\nПодписка на приложение будет продлеваться автоматически, пока автопродление не будет отключено не менее чем за 24 часа до окончания текущего периода. Управление подпиской и отключение автопродления осуществляется в настройках подписки в Google Play. Средства будут списаны с аккаунта Google Play после подтверждения подписки.\n',
    card_subscription_rules_amazon_text:
      'Приложение Manage My Pain предлагает только один вариант подписки:\n{0} в месяц\n\nПодписка на приложение будет продлеваться автоматически, пока автопродление не будет отключено не менее чем за 24 часа до окончания текущего периода. Управление подпиской и отключение автопродления осуществляется в настройках аккаунта Amazon Appstore. Средства будут списаны с аккаунта Amazon Appstore после подтверждения подписки.\n',
    card_subscription_rules_eula_title:
      'Ознакомиться с Пользовательским соглашением можно здесь:',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title:
      'Ознакомиться с Политикой конфиденциальности можно здесь:',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      'Ваша подписка будет продлена автоматически, если автопродление не будет отключено не позднее чем за 24 часа до конца действия текущего периода подписки. Управление подпиской и отключение автопродления осуществляется в настройках аккаунта Apple App Store. Средства будут списаны с аккаунта Apple App Store после подтверждения подписки.',
    card_subscription_rules_google_updated_text:
      'Ваша подписка будет продлена автоматически, если автопродление не будет отключено не позднее чем за 24 часа до конца действия текущего периода подписки. Управление подпиской и отключение автопродления осуществляется в настройках подписки в Google Play. Средства будут списаны с аккаунта Google Play после подтверждения подписки.',

    card_institution_plan_includes: 'Ваш план включает',
    card_institution_plan_benefit_1: 'Отчёты без ограничений',
    card_institution_plan_benefit_2: 'Аналитика без ограничений',
    card_institution_plan_benefit_3: 'Доступ к руководству «Всё о боли»',

    free_plan_title: 'Версия Lite',
    free_plan_add_view_records: 'Без ограничений',
    free_plan_analytics: 'Ограничено последними 30 днями',
    free_plan_reports: 'Доступен 1 полный отчет, затем срок ограничен 30 днями',
    free_plan_price: 'Free',
    free_plan_price_subtitle: '',
    free_plan_description:
      'Дополнительные отчеты можно купить по цене от {0} за каждый',

    pro_plan_title: 'Версия Pro',
    pro_plan_add_view_records: 'Без ограничений',
    pro_plan_analytics: 'Без ограничений',
    pro_plan_reports:
      'Отчеты с разделами «Аналитика» и «Записи» без ограничений. Включены 3 кредита для создания отчетов с другими разделами.',
    pro_plan_price_subtitle: 'одноразовый',
    pro_plan_description: 'Каждый дополнительный отчет можно приобрести за {0}',
    pro_plan_button: shared('Upgrade to Pro', 'ru'),

    subscribe_plan_title: 'Подписка Pro',
    subscribe_plan_add_view_records: 'Без ограничений',
    subscribe_plan_analytics: 'Без ограничений',
    subscribe_plan_reports: 'Без ограничений',
    subscribe_plan_price_subtitle: 'в месяц',
    subscribe_plan_description: 'Все отчеты бесплатные',
    subscription_plan_pain_guide_title: 'Всё о боли',
    subscription_plan_pain_guide_sub_title:
      'Полезная информация о боли и способах справиться с ней',
    subscribe_plan_button: 'Подписаться',
  },

  'zh-rCN': {
    // Order Credits
    order_credits_scene_screen_header: shared('Order Credits', 'zh-rCN'),
    order_credits_scene_purchasing_overlay: '正在处理购买...',
    order_credits_scene_pro_header:
      '大多数报告不需要点数。只有在生成包含图表、时间轴或日历等高级部分的报告时才需要点数。',
    order_credits_scene_lite_header:
      '大多数报告不需要点数。点数仅用于生成包含超过30天的记录的报告，或用于包含图表、时间轴或日历等高级部分。',
    order_credits_scene_order_now_button: shared('Order Credits', 'zh-rCN'),
    order_credits_save: '节省{0}%',
    order_credits_title_credits_1: '1点',
    order_credits_title_credits_3: '3点',
    order_credits_title_credits_5: '5点',

    order_credits_card_order_credit_button: '购买点',
    order_credits_card_per_credit_label: '每点{0}',
    order_credits_card_savings_label: '节省{0}％',

    // My profile subscription plan and credits content
    subscription_plan_current_plan_label: '当前计划',
    subscription_plan_current_credits_label: '当前点数',
    subscription_plan_section_upgrade_plan_button: '变更计划',
    subscription_plan_section_order_credits_button: shared(
      'Order Credits',
      'zh-rCN',
    ),
    subscription_plan_section_credits_loading_text: shared(
      'Loading...',
      'zh-rCN',
    ),
    subscription_plan_section_pro_plan: '专业版',
    subscription_plan_section_subscription_plan: '专业订阅版',
    subscription_plan_section_lite_plan: '精简版',
    subscription_plan_credits_unlimited: '无限',
    subscription_plan_section_web_upgrade_text:
      '如要订阅，请安装 iOS 或 Android 应用程序',
    subscription_plan_section_web_order_credits_text:
      '请安装 iOS 或 Android 应用程序，即可购买点数。',
    subscription_plan_current_plan_pain_guide_title: '疼痛指南',
    subscription_plan_current_plan_pain_guide_sub_title:
      '关于疼痛的有用信息和处理方法',

    // Subscription plans Screens
    plans_scene_screen_header: '订阅计划',
    plans_scene_purchasing_overlay: '正在处理购买...',
    plans_scene_loading_init_inapp: shared('Loading...', 'zh-rCN'),
    plans_scene_restore_purchases_button: '恢复购买',
    plans_scene_restore_purchases_success_toast: '购买成功恢复',
    plans_scene_itunes_error: '无法连接到Apple App Store购买服务',
    plans_scene_google_play_error: '无法连接到Google Play购买服务',

    plans_scene_current_plan_badge: '当前计划',
    plans_scene_most_popular_badge: '最受欢迎',
    plans_scene_best_value_badge: '最佳值',

    plans_scene_monthly_cost_label: '/ 月',
    plans_scene_yearly_cost_label: '/ 年',
    plans_scene_billed_yearly_cost_label: '年度收费 - {0} / 每年',

    plans_scene_plans_header: '计划',
    plans_scene_one_time_purchases_header: '一次性购买',
    plans_scene_subscription_terms_header: '订阅条款',

    plans_scene_free_item_header: '免费',
    plans_scene_free_item_benefit_1: '无限记录',
    plans_scene_free_item_benefit_2: '无限基本报告',
    plans_scene_free_item_benefit_3: '数据分析：限于最近30天',

    plans_scene_monthly_item_header: '每月',
    plans_scene_monthly_item_benefit_1: '无限记录',
    plans_scene_monthly_item_benefit_2: '无限高级报告',
    plans_scene_monthly_item_benefit_3: '无限数据分析',
    plans_scene_monthly_item_benefit_4: '获取疼痛报告',

    plans_scene_yearly_item_header: '每年',
    plans_scene_yearly_item_benefit_1: '无限记录',
    plans_scene_yearly_item_benefit_2: '无限高级报告',
    plans_scene_yearly_item_benefit_3: '无限数据分析',
    plans_scene_yearly_item_benefit_4: '获取疼痛报告',
    plans_scene_yearly_item_benefit_5: '20%折扣',

    plans_scene_pro_purchase_item_header: '解锁无限数据分析',
    plans_scene_pro_purchase_item_benefit_1: '移除数据分析30天限制',
    plans_scene_pro_purchase_item_benefit_2: '高级报告3个报告点',

    plans_scene_credits_item_header: '报告点',
    plans_scene_credits_item_benefit_1: '移除数据分析30天限制',
    plans_scene_credits_item_benefit_2: '用来生成高级报告',
    plans_scene_credits_item_price: '低至{0}',

    plans_scene_card_purchase_button: '购买',
    plans_scene_card_expired_button: '更新',
    plans_scene_card_manage_button: '管理',
    plans_scene_card_upgrade_button: '升级',
    plans_scene_card_renew_button: '更新',
    plans_scene_card_downgrade_button: '降级',
    plans_scene_card_order_credits_button: '订购点',
    plans_scene_card_current_plan: '当前计划',
    plans_scene_card_expired: '已过期',
    plans_scene_card_purchased: '已购买',
    plans_scene_card_sponsored: '已发起',

    //Subscription plans cards
    card_add_view_records: '添加并查看记录',
    card_analytics_and_insights: '分析和洞察',
    card_reports: shared('Reports', 'zh-rCN'),
    card_my_plan: '当前计划',
    card_more_details: '更多详情',
    card_manage_subscription: shared('Manage Subscription', 'zh-rCN'),
    card_institution_pro: '您得到赞助免费升级到专业版',
    card_institution_subscription: '您的订阅计划是免费赞助的 - 您不需要付费。',
    card_subscription_itunes: '您已通过Apple App Store订阅',
    card_subscription_google: '您已通过Google Play订阅',
    card_subscription_amazon: '您已通过亚马逊订阅',
    card_subscription_not_managed: '您已在其他设备上订阅',
    card_subscription_expired: '您的订阅已过期。',
    card_pro_other_account:
      '您订阅了另一个Manage My Pain账户 - 此设备上所有账户都可以从此订阅中受益。',
    card_subscription_other_account:
      '您订阅了另一个Manage My Pain账户 - 此设备上所有账户都可以从此订阅中受益。',
    card_subscription_expired_title: '已过期',
    card_subscription_resume: '恢复订阅',

    card_subscription_rules_button: '订阅定价和条款',
    card_subscription_rules_apple_text:
      'Manage My Pain提供一种自动续订订阅方式：\n每月{0}\n\n您的Manage My Pain订阅将自动续订，除非在当前期间结束前至少24小时关闭自动续订。您可以前往Apple App Store账户设置来管理订阅并关闭自动续订。确认购买后，将从您的Apple App Store帐户收取费用。\n',
    card_subscription_rules_google_text:
      'Manage My Pain提供一种自动续订订阅方式：\n每月{0}\n\n您的Manage My Pain订阅将自动续订，除非在当前期间结束前至少24小时关闭自动续订。您可以前往Google Play的订阅设置来管理订阅并关闭自动续订。确认购买后，将从您的Google Play账户收取费用。\n',
    card_subscription_rules_amazon_text:
      'Manage My Pain提供一种自动续订订阅方式：\n每月{0}\n\n您的Manage My Pain订阅将自动续订，除非在当前期间结束前至少24小时关闭自动续订。您可以前往亚马逊账户设置来管理订阅并关闭自动续订。确认购买后，将从您的亚马逊账户收取费用。\n',
    card_subscription_rules_eula_title:
      '您可以在这里查阅我们的最终用户许可协议：',
    card_subscription_rules_eula_display_link:
      'https://managemypainapp.com/eula',
    card_subscription_rules_policy_title: '您可以在这里查阅我们的隐私政策：',
    card_subscription_rules_policy_display_link:
      'https://managemypainapp.com/privacy-policy',

    card_subscription_rules_apple_updated_text:
      '除非在当前周期结束前至少 24 小时关闭自动续订，否则您的“Manage My Pain”订阅将自动续订。您可以前往 Apple App Store 帐户设置来管理您的订阅并关闭自动续订。确认购买后，您的 Apple App Store 帐户将被扣款。',
    card_subscription_rules_google_updated_text:
      '除非在当前周期结束前至少 24 小时关闭自动续订，否则您的“Manage My Pain”订阅将自动续订。您可以前往 Google Play 中的订阅设置来管理您的订阅并关闭自动续订。确认购买后，您的 Google Play 帐户将被扣款。',

    card_institution_plan_includes: '您的计划包含',
    card_institution_plan_benefit_1: '无限报告',
    card_institution_plan_benefit_2: '无限数据分析',
    card_institution_plan_benefit_3: '获取疼痛报告',

    free_plan_title: '精简版',
    free_plan_add_view_records: '无限制',
    free_plan_analytics: '仅限最近30天',
    free_plan_reports: '包括1份完整报告，此后仅限于最近30天',
    free_plan_price: '免费',
    free_plan_price_subtitle: '',
    free_plan_description: '可以购买更多报告，每份最低仅需{0}',

    pro_plan_title: '专业版',
    pro_plan_add_view_records: '无限制',
    pro_plan_analytics: '无限制',
    pro_plan_reports:
      '数据分析和/或记录部分的报告均没有使用限制。生成其他部分的报告则需要三个点数。',
    pro_plan_price_subtitle: '一次性',
    pro_plan_description: '可以购买更多报告，每份{0}',
    pro_plan_button: shared('Upgrade to Pro', 'zh-rCN'),

    subscribe_plan_title: '专业订阅版',
    subscribe_plan_add_view_records: '无限制',
    subscribe_plan_analytics: '无限制',
    subscribe_plan_reports: '无限制',
    subscribe_plan_price_subtitle: '每月',
    subscribe_plan_description: '所有报告都是免费的',
    subscription_plan_pain_guide_title: '疼痛指南',
    subscription_plan_pain_guide_sub_title: '关于疼痛的有用信息和处理方法',
    subscribe_plan_button: '订阅',
  },
});
