import { Platform } from 'react-native';

export default (state) => {
  let user = state.user;

  if (!user) {
    return state;
  }
  const token_type = user.token_type;
  if (token_type === Platform.OS.toLowerCase()) {
    return {
      ...state,
      user: {
        ...user,
        password_set: 1,
      },
    };
  }

  return state;
};
